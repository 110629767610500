import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Button from "../../../../common/components/button/Button";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { constants } from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import {
  APIMethods,
  adminEndPoints,
  userEndpoints,
} from "../../../../common/constants/urlConstants";
import {
  ICertificate,
  IMetadataVariants,
  IOptions,
  IPriceVariants,
  IProductDetailsWithDiscount,
  IVariants,
} from "../../../../models/IAddIndividualProductForm";
import {
  resetWholeForm,
  setSecondBottomForm,
} from "../../../../reducerSlices/addIndividualProductFormSlice";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";

import { useAxios } from "../../../../services/useAxios";
import styles from "./AddIndividual.module.scss";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import { setToastStatusApproval } from "../../../../reducerSlices/toastStatusSlice";
// import AddAttributes from "../../../../common/components/addAttributes/AddAttrinutes";
import PopUp from "../../../../common/components/PopUp/PopUp";

const AddIndividualPartTwo = ({
  isIncludeWithVAT,
  setIsIncludeWithVAT,
  handleAddRow,
  secondFormShow,
  setSecondFormShow,
  isAdmin,
  navFromProductApproval,
  selectedCertifications,
  setSelectedCertifications,
  isTheProductsData,
  setIsTheProductsData,
  isUpdationMode,
  selectedAttributes,
}: {
  isIncludeWithVAT: boolean;
  setIsIncludeWithVAT: Dispatch<SetStateAction<boolean>>;
  handleAddRow: (e: Event) => void;
  secondFormShow: boolean;
  setSecondFormShow: Dispatch<SetStateAction<boolean>>;
  isAdmin: boolean;
  navFromProductApproval: boolean;
  setIsTheProductsData: any;
  selectedCertifications: any;
  setSelectedCertifications: any;
  isTheProductsData: any;
  isUpdationMode: boolean;
  selectedAttributes: any;
}) => {
  const { productId } = useParams();
  const addIndividualProductForm = useSelector(
    (state: any) => state.addIndividualProductForm
  );
  const singleProductDetails = useSelector(
    (state: any) => state.singleProductDetails
  );
  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [certifications, setCertifications] = useState<ICertificate[]>(
    singleProductDetails ? singleProductDetails?.metadata?.certifications : []
  );

  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [isTheProduct, setIsTheProduct] = useState<ICertificate[]>([]);
  const location = useLocation();
  const [rejectPopup, setRejectPopup] = useState<boolean>(false);
  const rejectReasonRef = useRef<any>("");
  const isNewProduct = location.state?.masterProduct;
  const isOfflineProduct = location.state?.offlineProduct;
  const isLinkProduct = location.state?.linkProduct;
  const linkProductSupplierId = location.state?.supplierId;
  const schema = yup.object().shape({
    certifications: yup
      .mixed()
      .optional()
      .notOneOf([
        "HACCP",
        "FDA",
        "GMP",
        "ISO 22000",
        "Halal",
        "Kosher",
        "BRC",
        "IFS",
      ]),
    isProducts: yup
      .mixed()
      .optional()
      .notOneOf([
        "HACCP",
        "FDA",
        "GMP",
        "ISO 22000",
        "Halal",
        "Kosher",
        "BRC",
        "IFS",
      ]),
  });

  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      certifications: certifications || [],
      isProducts: isTheProduct || [],
    },
  });

  function atleastOneVariantToast() {
    setToastData({
      message: "Please add atleast One Variant",
      status: "Failure",
    });
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 3000);
  }
  const rejectProposedProduct = useAxios({
    axiosParams: {
      url: adminEndPoints.rejectProposedProduct.replace(
        ":product_id",
        productId || ""
      ),
      data: {
        message: rejectReasonRef.current,
      },
    },
    method: APIMethods.patch,
  });

  // get certifications and is the product api call starts
  const certificationsAndIsTheProductApi = useAxios({
    axiosParams: {
      url: "store/product-certificates",
    },
    method: APIMethods.get,
  });
  // update product
  const updateProduct = useAxios({
    axiosParams: {
      url: isNewProduct
        ? `admin/master-product/${productId}`
        : isAdmin
        ? `admin/product/${productId}`
        : `store/product/${productId}`,
    },
    method: APIMethods.put,
  });

  const getCertificationsAndIsTheProduct = useCallback(async () => {
    const response: any = await certificationsAndIsTheProductApi?.fetchData();
    if (response) {
      const updatedCertifications =
        response?.data?.certificates?.certificates?.map(
          (certificate: ICertificate) => ({
            ...certificate,
            value: certificate.name,
          })
        );
      setCertifications(updatedCertifications);
      const updatedIsTheProducts =
        response?.data?.specifications?.specifications?.map(
          (item: ICertificate) => ({
            ...item,
            value: item.name,
          })
        );
      setIsTheProduct(updatedIsTheProducts);
    }
  }, [certificationsAndIsTheProductApi]);

  useEffect(() => {
    getCertificationsAndIsTheProduct();
  }, []);
  // get certifications and is the product api call ends

  const handleCertificationToggle = (certification: string) => {
    const currentCertifications = selectedCertifications;
    if (currentCertifications?.includes(certification)) {
      setSelectedCertifications((prevSelected: any) =>
        prevSelected.filter((selected: any) => selected !== certification)
      );
      const updatedCertifications = currentCertifications.filter(
        (c: string) => c !== certification
      );
      setValue("certifications", updatedCertifications);
    } else {
      if (currentCertifications?.length < 5) {
        setSelectedCertifications((prevSelected: any) => [
          ...prevSelected,
          certification,
        ]);
        const updatedCertifications = [...currentCertifications, certification];
        setValue("certifications", updatedCertifications);
        if (addIndividualProductForm) {
          dispatch(
            setSecondBottomForm({
              certifications: updatedCertifications,
            })
          );
        }
      }
    }
  };
  const handleIsTheProductToggle = (item: string) => {
    const currentIsProducts = isTheProductsData;
    if (currentIsProducts?.includes(item)) {
      setIsTheProductsData((prevSelected: any) =>
        prevSelected.filter((selected: any) => selected !== item)
      );
      const updatedIsProducts = currentIsProducts.filter(
        (i: string) => i !== item
      );
      setValue("isProducts", updatedIsProducts);
    } else {
      if (currentIsProducts && currentIsProducts?.length < 5) {
        setIsTheProductsData((prevSelected: any) => [...prevSelected, item]);
        const updatedIsProducts = [...currentIsProducts, item];
        setValue("isProducts", updatedIsProducts);
        if (addIndividualProductForm) {
          dispatch(
            setSecondBottomForm({
              isProducts: updatedIsProducts,
            })
          );
        }
      }
    }
  };

  const [variants, setVariants] = useState<IVariants[]>([]);
  const getPricesAndMetaDataFromTableData = (
    tableData: IProductDetailsWithDiscount[]
  ) => {
    const prices: IPriceVariants[] = [];
    const metadata: IMetadataVariants[] = [];
    const options: IOptions[] = [];
    if (tableData && tableData?.length > 0) {
      tableData?.forEach((row: IProductDetailsWithDiscount) => {
        prices.push({
          amount: row?.orderAmount,
          currency_code: "aed",
          min_quantity: row?.moq,
        });
        metadata.push({
          selected_unit: row?.equalto?.sub_unit_id,
          selected_quantity: row?.enterNumber,
          offer: row?.off,
          equal_to_unit_name: row?.equalto?.name,
          ordering_option_unit_name: row?.orderingOption?.name,
        });
        options.push({
          value: row?.orderingOption?.id,
        });
      });
      setVariants([
        ...variants,
        {
          title: addIndividualProductForm?.firstForm?.productName,
          inventory_quantity: 10000,
          prices: prices,
          metadata: metadata,
          options: options,
        },
      ]);
    }
  };
  function transformImagePayload(
    defaultImgUrl: { id: string | null; url: string }[],
    productImagesUrl: { id: string | null; url: string }[]
  ) {
    let finalImgPayload = [];
    if (defaultImgUrl?.length > 0) {
      finalImgPayload.push({
        ...defaultImgUrl[0],
        metadata: { default_image: true },
      });
    }
    if (productImagesUrl?.length > 0) {
      finalImgPayload.push(...productImagesUrl);
    }
    finalImgPayload = finalImgPayload.map((el: any) => {
      if (!el.metadata?.default_image) {
        return {
          ...el,
          metadata: {
            default_image: false,
          },
        };
      } else {
        return el;
      }
    });

    if (singleProductDetails?.title) {
      // removing ids due to payload format
      finalImgPayload = finalImgPayload?.map((ele: any) => ({
        url: ele?.url,
        metadata: ele?.metadata,
      }));
      return finalImgPayload;
    } else {
      finalImgPayload.map((el: any) => {
        delete el?.id;
        return el;
      });
      finalImgPayload = finalImgPayload?.map((ele: any) => ({
        url: ele?.url,
        metadata: ele?.metadata,
      }));
      return finalImgPayload;
    }
  }
  const transformVariantPayload = (
    tableData: IProductDetailsWithDiscount[]
  ) => {
    const variants = tableData?.map((row: IProductDetailsWithDiscount) => {
      // const prices: IPriceVariants[] = [];
      // const options: IOptions[] = [];
      // prices.push({
      //   amount: row?.orderAmount,
      //   currency_code: "aed",
      //   min_quantity: row?.moq,
      // });
      // options.push({
      //   value: row?.orderingOption?.id,
      // });
      return {
        id: row?.id ?? null,
        title: row?.variantTitle ?? "title",
        unit_price: Number(row?.orderAmount),
        min_quantity: Number(row?.moq),
        // inventory_quantity: 10000,
        // prices: prices,
        metadata: {
          ordering_option_value: Number(row?.orderingOptionValue),
          ordering_option_id: row?.orderingOption && row?.orderingOption?.id,
          ordering_option_label:
            row?.orderingOption && row?.orderingOption?.name,
          secondary_ordering_option_value: Number(row?.enterNumber),
          secondary_ordering_option_id: row?.equalto?.sub_unit_id,
          secondary_ordering_option_label: row?.equalto?.name,
          offer: row?.off,
        },
        // options: options,
      };
    });
    return variants;
  };

  useEffect(() => {
    if (
      addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
    ) {
      getPricesAndMetaDataFromTableData(
        addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
      );
    }
  }, [
    addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount,
  ]);
  const [finalTags, setFinalTags] = useState<{ value: string }[]>([]);
  const getTagsFromFirstForm = (tags: string) => {
    if (tags) {
      const updatedTags = tags.split(",");
      updatedTags &&
        updatedTags?.length > 0 &&
        updatedTags.map((tag: string) => {
          setFinalTags((prevTags: { value: string }[]) => [
            ...prevTags,
            { value: tag },
          ]);
        });
    }
  };
  useEffect(() => {
    if (addIndividualProductForm?.firstForm?.productTags) {
      getTagsFromFirstForm(addIndividualProductForm?.firstForm?.productTags);
    }
  }, [addIndividualProductForm?.firstForm?.productTags]);

  const validatePayload = (addIndividualProductForm: any) => {
    let finalPayload = {
      title: addIndividualProductForm?.firstForm?.productName,
      is_vat_included:
        addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT ?? false,
      description: addIndividualProductForm?.firstForm?.productDescription,
      brand: addIndividualProductForm?.firstForm?.productBrand,
      metadata: {
        certifications:
          addIndividualProductForm?.secondBottomForm?.certifications &&
          addIndividualProductForm?.secondBottomForm?.certifications?.length > 0
            ? addIndividualProductForm?.secondBottomForm?.certifications
            : [],
        attributes: combinedAttributesObject,
        product_details:
          addIndividualProductForm?.secondBottomForm?.isProducts &&
          addIndividualProductForm?.secondBottomForm?.isProducts?.length > 0
            ? addIndividualProductForm?.secondBottomForm?.isProducts
            : [],
      },
      tags: finalTags,

      category_id: addIndividualProductForm?.firstForm?.productCategory?.id,
      sub_category_id:
        addIndividualProductForm?.firstForm?.productSubCategory?.id,
      sub_sub_category_id:
        addIndividualProductForm?.firstForm?.productSection?.id,
      countries: {
        iso_2: addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
      },
      hs_code: addIndividualProductForm?.firstForm?.hsCode,
      product_code: addIndividualProductForm?.firstForm?.supplierProductCode,
      images: transformImagePayload(
        addIndividualProductForm?.firstForm?.defaultproductImagesUrl,
        addIndividualProductForm?.firstForm?.productImagesUrl
      ),
      variants: transformVariantPayload(
        addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
      ),
    };
    for (const [key, value] of Object.entries(finalPayload)) {
      if (key === "hs_code" && value?.length === 0) {
        delete finalPayload.hs_code;
      } else if (key === "product_code" && value?.length === 0) {
        delete finalPayload.product_code;
      } else if (key === "brand" && value?.length === 0) {
        delete finalPayload.brand;
      }
    }
    return finalPayload;
  };
  const combinedAttributesObject = selectedAttributes?.reduce(
    (result: any, obj: any) => {
      if (obj != null) {
        const { value, typeValue } = obj;
        return { ...result, [value?.toLowerCase()]: typeValue };
      }
      return result;
    },
    {}
  );
  const postData = useAxios({
    axiosParams: {
      url: isAdmin
        ? adminEndPoints.genericaddproducts
        : `${userEndpoints.createIndividualProduct}${
            loggedInUser &&
            loggedInUser?.business_details &&
            loggedInUser?.business_details?.[0] &&
            loggedInUser?.business_details?.[0]?.business_setup_id
          }`,
      data:
        addIndividualProductForm && validatePayload(addIndividualProductForm),
    },
    method: "POST",
  });
  const getCreateProductPayload = () => {
    return {
      title: addIndividualProductForm?.firstForm?.productName,
      is_vat_included:
        addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT ?? false,
      description: addIndividualProductForm?.firstForm?.productDescription,
      brand: addIndividualProductForm?.firstForm?.productBrand,
      brand_id: addIndividualProductForm?.firstForm?.productBrandApiData?.id,
      metadata: {
        certifications:
          addIndividualProductForm?.secondBottomForm?.certifications,
        product_details: addIndividualProductForm?.secondBottomForm?.isProducts,
        attributes: combinedAttributesObject,
      },
      tags: finalTags,
      //old
      // categories: [
      //   {
      //     id: addIndividualProductForm?.firstForm?.productCategory?.id,
      //   },
      // ],
      // sub_category: {
      //   id: addIndividualProductForm?.firstForm?.productSubCategory?.id,
      // },
      // sub_sub_category: {
      //   id: addIndividualProductForm?.firstForm?.productSection?.id,
      // },
      //new
      category_id: addIndividualProductForm?.firstForm?.productCategory?.id,
      sub_category_id:
        addIndividualProductForm?.firstForm?.productSubCategory?.id,
      sub_sub_category_id:
        addIndividualProductForm?.firstForm?.productSection?.id,
      countries: {
        iso_2: addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
      },
      hs_code: addIndividualProductForm?.firstForm?.hsCode,
      product_code: addIndividualProductForm?.firstForm?.supplierProductCode,
      images: transformImagePayload(
        addIndividualProductForm?.firstForm?.defaultproductImagesUrl,
        addIndividualProductForm?.firstForm?.productImagesUrl
      ),
      variants: transformVariantPayload(
        addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
      ),
      // handle: `${addIndividualProductForm?.firstForm?.productName}${loggedInUser?.id}`,
      // ware_house_id:
      //   loggedInUser &&
      //   loggedInUser?.business_details &&
      //   loggedInUser?.business_details?.[0]?.business_setup_id,
      // status: isAdmin ? "published" : "draft",
      // options: [
      //   {
      //     title: "ordering_option",
      //   },
      // ],
      // sales_channels: [],
      // is_deactivated: isAdmin ? undefined : false,
      // supplier: {
      //   id:
      //     loggedInUser &&
      //     loggedInUser?.business_details &&
      //     loggedInUser?.business_details?.[0]?.business_setup_id,
      // },
    };
  };

  //It is used to create master product
  const postNewMasterProduct = useAxios({
    axiosParams: {
      url: `admin/product/${productId}/master-product`,
      data: addIndividualProductForm && getCreateProductPayload(),
    },
    method: APIMethods.post,
  });

  //It is used to create cloned product
  const postClonedProduct = useAxios({
    axiosParams: {
      url: `store/product/${singleProductDetails?.id}/supplier/${
        loggedInUser &&
        loggedInUser?.business_details &&
        loggedInUser?.business_details?.[0] &&
        loggedInUser?.business_details?.[0]?.business_setup_id
      }`,
      data: addIndividualProductForm && getCreateProductPayload(),
    },
    method: APIMethods.post,
  });

  //It is used to link product to a supplier
  const postLinkProduct = useAxios({
    axiosParams: {
      url: `admin/master-product/${singleProductDetails?.id}/supplier/${linkProductSupplierId}`,
      data: addIndividualProductForm && getCreateProductPayload(),
    },
    method: APIMethods.post,
  });

  // images id is not present

  const postFullformData = async () => {
    if (loggedInUser?.id && !productId && addIndividualProductForm) {
      dispatch(updateIsLoading(true));
      const response: any = await postData?.fetchData();
      if (response && response?.status === "SUCCESS") {
        dispatch(resetWholeForm());
        dispatch(
          dispatch(
            setToastStatusApproval({
              message: "Product has been created Sucessfully",
              status: "Success",
              type: "productCreation",
            } as any)
          )
        );
        navigate(
          isAdmin
            ? routeConfig.adminManageCatalogue
            : routeConfig.productListingCatalogue
        );
        dispatch(updateIsLoading(false));
      } else {
        setToastData({
          message: "Oops! Something went wrong. Please retry.",
          status: "Failure",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        dispatch(updateIsLoading(false));
      }
    }
  };

  // update product
  // const updateProductpostData = useAxios({
  //   axiosParams: {
  //     url: `store/catalogue/updateproduct/${productId}`,
  //     data: productId &&
  //       addIndividualProductForm && {
  //         current_user:loggedInUser?.id && loggedInUser?.id,
  //         product_type: singleProductDetails?.productType,
  //         title: addIndividualProductForm?.firstForm?.productName,
  //         brand: {
  //           id: addIndividualProductForm?.firstForm?.productBrandApiData?.id,
  //         },
  //         categories: [
  //           {
  //             id: addIndividualProductForm?.firstForm?.productCategory?.id,
  //           },
  //         ],
  //         sub_category: {
  //           id: addIndividualProductForm?.firstForm?.productSubCategory?.id,
  //         },
  //         sub_sub_category: {
  //           id: addIndividualProductForm?.firstForm?.productSection?.id,
  //         },
  //         countries: {
  //           iso_2:
  //             addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
  //         },
  //         hs_code: addIndividualProductForm?.firstForm?.hsCode,
  //         handle: singleProductDetails && singleProductDetails?.handle,
  //         // not needed for admin
  //         product_code:
  //           addIndividualProductForm?.firstForm?.supplierProductCode,
  //         description:
  //           addIndividualProductForm?.firstForm?.productDescription,
  //         tags: finalTags,
  //         images:
  //           addIndividualProductForm?.firstForm?.productImagesUrl,
  //         status: isAdmin ? "published" : "draft",
  //         options: [
  //           {
  //             title: "ordering_option",
  //           },
  //         ],
  //         variants: transformVariantPayload(
  //           addIndividualProductForm?.secondTopForm
  //             ?.addedProductDetailsWithDiscount
  //         ),
  //         sales_channels: [],
  //         is_deactivated: false, // not needed for admin
  //         supplier: {
  //           id: singleProductDetails?.supplier?.id,
  //         },
  //         metadata: {
  //           certifications: selectedCertifications,
  //           product_details: isTheProductsData,
  //         },
  //       },
  //   },
  //   method: "POST",
  // });

  // const updateProductByIdPostData = async () => {
  //   if (isUpdationMode) {
  //     const response: any = await updateProductpostData?.fetchData();
  //     if (response && response?.status === "SUCCESS") {
  //       isAdmin
  //         ? navigate(`/admin/manage-catalogue`)
  //         : navigate(`/supplier/catalogue/product-details/${productId}`);
  //     }
  //   }
  // };

  // tracking updated fields starts
  function getVariantsFromAPI() {
    const variants = singleProductDetails?.variants.map((variant: any) => {
      return {
        id: variant?.id,
        title: variant.title,
        unit_price: variant?.unitPrice,
        min_quantity: variant?.minQuantity,

        metadata: {
          ordering_option_value: variant.metadata?.orderingOptionValue,
          ordering_option_id: variant.metadata?.orderingOptionId || null,
          ordering_option_label: variant.metadata?.orderingOptionLabel,
          secondary_ordering_option_value:
            variant.metadata?.secondaryOrderingOptionValue,
          secondary_ordering_option_id:
            variant.metadata?.secondaryOrderingOptionId,
          secondary_ordering_option_label:
            variant.metadata?.secondaryOrderingOptionLabel,
          offer: variant.metadata?.offer,
          attributes: variant?.metadata?.attributes,
        },
      };
    });
    return variants;
  }

  // admin update product payload
  function getUpdatePayload(isAdmin: boolean) {
    const variantsToDelete: any = [];
    const newVariants: any = [];
    const modifiedVariants: any = [];
    let adminProductUpdatePayload: any = {};
    adminProductUpdatePayload = {
      title: addIndividualProductForm?.firstForm?.productName,
      is_vat_included:
        addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT ?? false,
      // category_id: addIndividualProductForm?.firstForm?.productCategory?.id,
      // sub_category_id:
      //   addIndividualProductForm?.firstForm?.productSubCategory?.id,
      // sub_sub_category_id:
      //   addIndividualProductForm?.firstForm?.productSection?.id,
      countries: {
        iso_2: addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
      },
      product_code: addIndividualProductForm?.firstForm?.supplierProductCode,
      hs_code: addIndividualProductForm?.firstForm?.hsCode,
      description: addIndividualProductForm?.firstForm?.productDescription,
      tags: finalTags,
      images: transformImagePayload(
        addIndividualProductForm?.firstForm?.defaultproductImagesUrl,
        addIndividualProductForm?.firstForm?.productImagesUrl
      ),
      metadata: {
        certifications: selectedCertifications,
        product_details: isTheProductsData,
        attributes: combinedAttributesObject,
      },
      variants: transformVariantPayload(
        addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
      ),
      brand: addIndividualProductForm?.firstForm?.productBrand,
    };
    const dataInAPI: any = {
      title: singleProductDetails?.title,
      is_vat_included: singleProductDetails?.isVatIncluded ?? false,
      // category_id: singleProductDetails?.category?.id,

      // sub_category_id: singleProductDetails?.subCategory?.id,

      // sub_sub_category_id: singleProductDetails?.subSubCategory?.id,

      countries: {
        iso_2: singleProductDetails?.countries?.iso_2,
      },
      product_code: singleProductDetails?.productCode,
      // hs_code: singleProductDetails?.hsCode,
      description: singleProductDetails?.description,
      tags: singleProductDetails?.tags,
      images: singleProductDetails?.images,
      metadata: {
        certifications: singleProductDetails?.metadata?.certifications,
        product_details: singleProductDetails?.metadata?.productDetails,
        attributes: combinedAttributesObject,
      },

      variants: getVariantsFromAPI(),
    };
    adminProductUpdatePayload["variants"]?.forEach((variant: any) => {
      if (!variant.id) {
        newVariants.push(variant);
      } else if (
        dataInAPI["variants"]?.find((el: any) => el.id === variant.id)
      ) {
        modifiedVariants.push(variant);
      }
    });
    dataInAPI["variants"]?.forEach((variant: any) => {
      if (
        !adminProductUpdatePayload["variants"].find(
          (el: any) => el.id === variant.id
        )
      ) {
        variantsToDelete.push(variant.id);
      }
    });
    if (isAdmin) {
      adminProductUpdatePayload["category_id"] =
        addIndividualProductForm?.firstForm?.productCategory?.id;
      adminProductUpdatePayload["sub_category_id"] =
        addIndividualProductForm?.firstForm?.productSubCategory?.id;
      adminProductUpdatePayload["sub_sub_category_id"] =
        addIndividualProductForm?.firstForm?.productSection?.id;
      adminProductUpdatePayload["hs_code"] =
        addIndividualProductForm?.firstForm?.hsCode;
      adminProductUpdatePayload["brand"] =
        addIndividualProductForm?.firstForm?.productBrand;
    }
    if (newVariants?.length > 0)
      adminProductUpdatePayload["new_variants"] = newVariants;
    if (variantsToDelete?.length > 0)
      adminProductUpdatePayload["deleted_variants"] = variantsToDelete;
    if (modifiedVariants?.length > 0) {
      adminProductUpdatePayload["variants"] = modifiedVariants;
    } else {
      delete adminProductUpdatePayload.variants;
    }

    return {
      payload: adminProductUpdatePayload,
      variantsToDelete: variantsToDelete,
      isDataModified: true,
    };
  }

  async function callUpdateProductAPI(
    variantsToDelete: string[],
    payload: any
  ) {
    if (productId) {
      const response: any = await updateProduct.fetchData({
        axiosParams: {
          data: payload,
          url: isNewProduct
            ? `admin/master-product/${productId}`
            : isAdmin
            ? `admin/product/${productId}`
            : `store/product/${productId}`,
        },
        method: APIMethods.put,
      });
      return response;
    }
  }
  const updateProductByIdPostData = async () => {
    if (productId && isUpdationMode) {
      const { isDataModified, payload, variantsToDelete } =
        getUpdatePayload(isAdmin);
      if (isDataModified) {
        dispatch(updateIsLoading(true));
        const res = await callUpdateProductAPI(variantsToDelete, payload);
        dispatch(updateIsLoading(false));
        if (res && res?.status === "SUCCESS") {
          dispatch(resetWholeForm());
          isAdmin
            ? navigate(`/admin/manage-catalogue`)
            : navigate(`/supplier/product-listinge-catalogue`);

          dispatch(
            setToastStatusApproval({
              message: "Product has been updated successfully",
              status: "Success",
              type: "productCreation",
            } as any)
          );
        } else {
          setToastData({
            message:
              res?.response?.data?.error_msg &&
              res?.response?.data?.error_msg?.includes(
                "attributes must be a non-empty object"
              )
                ? "Attributes must contain at least 1 element"
                : "Product update failed",

            status: "error",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 1000);
        }
      } else {
        isAdmin
          ? navigate(`/admin/manage-catalogue`)
          : navigate(`/supplier/product-listinge-catalogue`);
      }
    }
  };
  // tracking update fields ends
  const submit = (data: any) => {
    const formDataWithSelections = {
      ...data,
      certifications: selectedCertifications,
      isProducts: isTheProductsData,
    };
    const updatedFullData = {
      ...addIndividualProductForm,
      partTwoData: formDataWithSelections,
    };
    dispatch(setSecondBottomForm(updatedFullData));
    // if (productId) {
    //   updateProductByIdPostData();
    // } else {
    //   postFullformData();
    // }
  };

  // const productApprovalPostData = useAxios({
  //   axiosParams: {
  //     url: `store/catalogue/updateproduct/${productId}`,
  //     data: productId &&
  //       addIndividualProductForm && {
  //         variants: transformVariantPayload(
  //           addIndividualProductForm?.secondTopForm
  //             ?.addedProductDetailsWithDiscount
  //         ),
  //         status: "published",
  //         is_deactivated: false,
  //         metadata: {
  //           certifications: selectedCertifications,
  //           product_details: isTheProductsData,
  //         },
  //         product_code: productId,
  //         country_id:
  //           addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
  //       },
  //   },
  //   method: "POST",
  // });
  // approval product api
  const approveProduct = useAxios({
    axiosParams: {
      url: `admin/product/${productId}/approve`,
      data: getUpdatePayload(isAdmin)?.payload,
    },
    method: "POST",
  });
  // const handleProductApproval = async () => {
  //   const response: any = await productApprovalPostData?.fetchData();
  //   dispatch(updateIsLoading(true));
  //   if (response && response?.status === "SUCCESS") {
  //     const productApprovalResponse: any = await approveProduct?.fetchData();
  //     if (productApprovalResponse) {
  //       dispatch(updateIsLoading(false));
  //       productApprovalResponse?.status === "SUCCESS" &&
  //         navigate(routeConfig.adminProductApproval, {
  //           state: { message: "Product has been approved", status: "approved" },
  //         });
  //     }
  //   }
  // };
  const handleProductApproval = async () => {
    if (
      addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
        ?.length > 0
    ) {
      if (isAdmin && isUpdationMode && productId) {
        dispatch(updateIsLoading(true));
        const { isDataModified } = getUpdatePayload(isAdmin);
        if (isDataModified) {
          // await callUpdateProductAPI(variantsToDelete, {
          //   ...payload,
          //   status: "published",
          // });
          const productApprovalResponse: any =
            await approveProduct?.fetchData();
          dispatch(updateIsLoading(false));
          if (
            productApprovalResponse &&
            productApprovalResponse?.status === "SUCCESS"
          ) {
            dispatch(resetWholeForm());
            // navigate(routeConfig.adminProductApproval, {
            //   state: { message: "Product has been approved", status: "approved" },
            // });
            dispatch(
              setToastStatusApproval({
                message: "Product has been approved",
                status: "approved",
                type: "productStatus",
              } as any)
            );
            navigate(routeConfig.adminProductApproval);
          } else {
            setToastData({
              message:
                productApprovalResponse?.response?.data?.error_msg &&
                productApprovalResponse?.response?.data?.error_msg?.includes(
                  "attributes must be a non-empty object"
                )
                  ? "Attributes must contain at least 1 element"
                  : productApprovalResponse?.response?.data?.error_msg,

              status: "error",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 2000);
          }
        } else {
          const productApprovalResponse: any =
            await approveProduct?.fetchData();
          if (
            productApprovalResponse &&
            productApprovalResponse?.status === "SUCCESS"
          ) {
            dispatch(updateIsLoading(false));
            dispatch(resetWholeForm());
            // navigate(routeConfig.adminProductApproval, {
            //   state: { message: "Product has been approved", status: "approved" },
            // });
            dispatch(
              setToastStatusApproval({
                message: "Product has been approved",
                status: "approved",
                type: "productStatus",
              } as any)
            );
            navigate(routeConfig.adminProductApproval);
          } else {
            setToastData({
              message:
                productApprovalResponse?.response?.data?.error_msg &&
                productApprovalResponse?.response?.data?.error_msg?.includes(
                  "attributes must be a non-empty object"
                )
                  ? "Attributes must contain at least 1 element"
                  : productApprovalResponse?.response?.data?.error_msg,

              status: "error",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 2000);
          }
        }
      }
    } else {
      atleastOneVariantToast();
    }
  };

  //reject product  api
  // const productRejectPostData = useAxios({
  //   axiosParams: {
  //     url: `store/catalogue/updateproduct/${productId}`,
  //     data: productId &&
  //       addIndividualProductForm && {
  //         variants: transformVariantPayload(
  //           addIndividualProductForm?.secondTopForm
  //             ?.addedProductDetailsWithDiscount
  //         ),
  //         status: "rejected",
  //         is_deactivated: false,
  //         metadata: {
  //           certifications: selectedCertifications,
  //           product_details: isTheProductsData,
  //         },
  //         product_code: productId,
  //         country_id:
  //           addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
  //       },
  //   },
  //   method: "POST",
  // });
  const rejectProduct = useAxios({
    axiosParams: {
      url: `admin/product/${productId}/reject`,
      data: {
        message: "test",
      },
    },
    method: "PATCH",
  });
  // const handleProductRejection = async () => {
  //   if (productId) {
  //     const resonse: any = await productRejectPostData?.fetchData();
  //     dispatch(updateIsLoading(true));
  //     if (resonse?.status === "SUCCESS") {
  //       dispatch(updateIsLoading(false));
  //       navigate(routeConfig.adminProductApproval, {
  //         state: { message: "Product has been rejected", status: "rejected" },
  //       });
  //     }
  //   }
  // };
  const handleProductRejection = async () => {
    if (isAdmin && isUpdationMode && productId) {
      dispatch(updateIsLoading(true));
      const { isDataModified, payload, variantsToDelete } =
        getUpdatePayload(isAdmin);
      if (isDataModified) {
        const resposne = await callUpdateProductAPI(variantsToDelete, payload);
        if (resposne && resposne?.status === "SUCCESS") {
          const productRejectionResponse: any =
            await rejectProduct?.fetchData();
          if (
            productRejectionResponse &&
            productRejectionResponse?.status === "SUCCESS"
          ) {
            dispatch(resetWholeForm());
            // navigate(routeConfig.adminProductApproval, {
            //   state: { message: "Product has been rejected", status: "rejected" },
            // });
            dispatch(
              setToastStatusApproval({
                message: "Product has been rejected",
                status: "approved",
                type: "productStatus",
              } as any)
            );
            navigate(routeConfig.adminProductApproval);
          }
          dispatch(updateIsLoading(false));
          // dispatch(resetWholeForm());
          // navigate(routeConfig.adminProductApproval, {
          //   state: { message: "Product has been rejected", status: "rejected" },
          // });
          dispatch(
            setToastStatusApproval({
              message: "Product has been rejected",
              status: "approved",
              type: "productStatus",
            } as any)
          );
          navigate(routeConfig.adminProductApproval);
        }
      } else {
        const productRejectionResponse: any = await rejectProduct?.fetchData();
        if (
          productRejectionResponse &&
          productRejectionResponse?.status === "SUCCESS"
        ) {
          dispatch(updateIsLoading(false));
          dispatch(resetWholeForm());
          // navigate(routeConfig.adminProductApproval, {
          //   state: { message: "Product has been rejected", status: "rejected" },
          // });
          dispatch(
            setToastStatusApproval({
              message: "Product has been rejected",
              status: "approved",
              type: "productStatus",
            } as any)
          );
          navigate(routeConfig.adminProductApproval);
        }
      }
    }
  };

  const submitNewMasterProduct = async () => {
    if (
      addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
        ?.length > 0
    ) {
      dispatch(updateIsLoading(true));
      const response: any = await postNewMasterProduct.fetchData();
      dispatch(updateIsLoading(false));
      if (response && response?.status === "SUCCESS") {
        dispatch(resetWholeForm());
        // navigate(routeConfig.adminProductApproval, {
        //   state: { message: "Product has been approved", status: "approved" },
        // });
        dispatch(
          setToastStatusApproval({
            message: "New product has been added to master",
            status: "approved",
            type: "productStatus",
          } as any)
        );
        navigate(routeConfig.adminProductApproval);
      } else {
        setToastData({
          message:
            (response?.response?.data?.error_msg &&
              response?.response?.data?.error_msg?.includes(
                "Product variant metadata attributes fields is not presnet"
              )) ||
            response?.response?.data?.error_msg?.includes(
              "attributes must be a non-empty object"
            )
              ? "Attributes must contain at least 1 element"
              : response?.response?.data?.error_msg,
          status: "error",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 2000);
      }
    } else {
      atleastOneVariantToast();
    }
  };

  const submitClonedProduct = async () => {
    if (
      addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
        ?.length > 0
    ) {
      dispatch(updateIsLoading(true));
      const response: any = await postClonedProduct.fetchData();
      dispatch(updateIsLoading(false));
      if (response && response?.status === "SUCCESS") {
        dispatch(resetWholeForm());
        dispatch(
          setToastStatusApproval({
            message: "Product has been created successfully",
            status: "Success",
            type: "productCreation",
          } as any)
        );
        navigate(routeConfig.productListingCatalogue);
      } else {
        setToastData({
          message:
            (response?.response?.data?.error_msg &&
              response?.response?.data?.error_msg?.includes(
                "Product variant metadata attributes fields is not presnet"
              )) ||
            response?.response?.data?.error_msg?.includes(
              "attributes must be a non-empty object"
            )
              ? "Attributes must contain at least 1 element"
              : "All the fields in variant is mandatory",

          status: "error",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 1000);
      }
    } else {
      atleastOneVariantToast();
    }
  };
  const submitLinkProduct = async () => {
    if (
      addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount
        ?.length > 0
    ) {
      dispatch(updateIsLoading(true));
      const response: any = await postLinkProduct.fetchData();
      dispatch(updateIsLoading(false));
      if (response && response?.status === "SUCCESS") {
        dispatch(resetWholeForm());
        dispatch(
          setToastStatusApproval({
            message: "Product has been linked",
            status: "approved",
            type: "supplier",
          } as any)
        );
        navigate(routeConfig.supplierBusinessApprovalPage);
      } else {
        setToastData({
          message:
            response?.response?.data?.error_msg &&
            response?.response?.data?.error_msg?.includes(
              "attributes must be a non-empty object"
            )
              ? "Attributes must contain at least 1 element"
              : response?.response?.data?.error_msg,
          status: "error",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 2000);
      }
    } else {
      atleastOneVariantToast();
    }
  };

  const handleRejectProduct = async () => {
    dispatch(updateIsLoading(true));
    setRejectPopup(false);
    const response: any = await rejectProposedProduct.fetchData({
      axiosParams: {
        data: { message: rejectReasonRef.current },
      },
    });
    dispatch(updateIsLoading(false));
    if (response && response?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      dispatch(resetWholeForm());
      dispatch(
        setToastStatusApproval({
          message: "Product has been rejected",
          status: "rejected",
          type: "productStatus",
        } as any)
      );
      navigate(routeConfig.adminProductApproval);
    }
  };
  function getCancelNavigationLink() {
    let link = "";
    if (isAdmin) {
      if (navFromProductApproval) {
        if (isOfflineProduct || isNewProduct) {
          if (singleProductDetails?.status === "draft") {
            link = routeConfig.productDetailsPageAdmin?.replace(
              ":productId",
              productId as string
            );
          } else {
            link = routeConfig.adminProductApproval;
          }
        } else {
          link = routeConfig.productDetailsPageAdmin?.replace(
            ":productId",
            productId as string
          );
        }
      } else if (productId) {
        link = routeConfig.productDetailsPageAdminFromCatalogue?.replace(
          ":productId",
          productId as string
        );
      } else {
        link = routeConfig.adminProductAddCards;
      }
    } else {
      link = productId
        ? `/supplier/catalogue/product-details/${productId}`
        : routeConfig.manageSupplierCatalogue;
    }
    return link;
  }
  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={styles.certificateForm}>
        <div className={`${styles.checkInput} ${styles.vatContainer}`}>
          <span style={{ cursor: "pointer", fontFamily: "UrbanistSemiBold" }}>
            VAT
          </span>
          <div className={styles.checkInput}>
            <CheckboxButton
              checked={isIncludeWithVAT}
              handleChange={() => {
                setIsIncludeWithVAT(!isIncludeWithVAT);
              }}
            />
            <span
              onClick={() => {
                setIsIncludeWithVAT(!isIncludeWithVAT);
              }}
              style={{ cursor: "pointer", fontFamily: "UrbanistRegular" }}
            >
              Is VAT included
            </span>
          </div>
        </div>
        <div className={styles.certificationsText}>
          <div className={styles.text1}>Certifications (Optional)</div>
          <div className={styles.text2}>
            {constants.addIndividualProduct.optional}
          </div>
        </div>
        <div className={styles.checkboxFrame}>
          {certifications?.map((certificate) => {
            const isChecked = selectedCertifications?.find(
              (el: any) => certificate.value?.toLowerCase() === el.toLowerCase()
            );
            const isDisabled =
              selectedCertifications?.length === 5 && !isChecked;
            // const isMatching =
            //   singleProductDetails?.metadata?.certifications?.includes(
            //     certificate?.value
            //   );

            return (
              <div
                className={`${styles.checkInput} ${
                  isDisabled ? styles.disabledCheckbox : ""
                }`}
                key={certificate.id}
              >
                <CheckboxButton
                  checked={isChecked}
                  handleChange={() =>
                    handleCertificationToggle(certificate.value)
                  }
                  disabled={isDisabled}
                />
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleCertificationToggle(certificate.value)}
                >
                  {certificate.value}
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.certificationsText}>
          <div className={styles.text1}>Is the product? (Optional)</div>
          <div className={styles.text2}>
            {constants.addIndividualProduct.optionalHeading}
          </div>
        </div>
        <div className={styles.checkboxFrame}>
          {isTheProduct?.map((item) => {
            // const isMatching =
            //   singleProductDetails?.metadata?.productDetails?.includes(
            //     item.value
            //   );

            const isChecked = isTheProductsData?.find(
              (el: any) => item.value?.toLowerCase() === el.toLowerCase()
            );
            const isDisabled = isTheProductsData?.length === 5 && !isChecked;

            return (
              <div
                className={`${styles.checkInput} ${
                  isDisabled ? styles.disabledCheckbox : ""
                }`}
                key={item.id}
              >
                <CheckboxButton
                  checked={isChecked}
                  handleChange={() => handleIsTheProductToggle(item?.value)}
                  disabled={isDisabled}
                />
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleIsTheProductToggle(item.value)}
                >
                  {item.value}
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.buttonContainer2}>
          {isAdmin && navFromProductApproval && (
            <Button
              label={"Cancel"}
              large={true}
              customClass={styles.formButtonCancel}
              handleClick={() => {
                dispatch(resetWholeForm());
                navigate(getCancelNavigationLink(), {
                  state: {
                    masterProduct: isNewProduct,
                    offlineProduct: isOfflineProduct,
                  },
                });
              }}
            />
          )}
          <Button
            label={"Previous"}
            large={true}
            customClass={styles.formButtonCancel}
            handleClick={() => setSecondFormShow(false)}
          />
          {!(isAdmin && navFromProductApproval) &&
            !singleProductDetails?.cloneProduct &&
            !isLinkProduct && (
              <Button
                label={"Submit"}
                large={true}
                customClass={styles.formButton}
                handleClick={(e: Event) => {
                  if (
                    addIndividualProductForm &&
                    addIndividualProductForm?.firstForm &&
                    addIndividualProductForm?.secondTopForm &&
                    addIndividualProductForm?.secondBottomForm &&
                    addIndividualProductForm?.secondTopForm
                      ?.addedProductDetailsWithDiscount?.length > 0
                  ) {
                    if (productId) {
                      updateProductByIdPostData();
                    } else {
                      postFullformData();
                    }
                  } else {
                    atleastOneVariantToast();
                  }
                }}
              />
            )}
          {singleProductDetails?.cloneProduct && !isLinkProduct && (
            <Button
              label={"Submit"}
              large={true}
              customClass={styles.formButton}
              handleClick={handleSubmit(submitClonedProduct)}
            />
          )}
          {isLinkProduct && (
            <Button
              label={"Submit"}
              large={true}
              customClass={styles.formButton}
              handleClick={handleSubmit(submitLinkProduct)}
            />
          )}
          {isAdmin && navFromProductApproval && (
            <Button
              label={constants.BusinessApprovalForm.reject}
              large={true}
              customClass={styles.formButton}
              handleClick={() => {
                setRejectPopup(true);
              }}
            />
          )}
          {(isNewProduct || isOfflineProduct) &&
            navFromProductApproval &&
            singleProductDetails?.status !== "draft" && (
              <Button
                label={"Create"}
                large={true}
                customClass={styles.formButton}
                handleClick={handleSubmit(submitNewMasterProduct)}
              />
            )}
          {/* remove this in next sprint */}
          {false && (
            <Button
              label={constants.BusinessApprovalForm.reject}
              large={true}
              customClass={styles.formButton}
              handleClick={handleProductRejection}
            />
          )}
          {isAdmin &&
            navFromProductApproval &&
            (singleProductDetails?.status === "draft" ||
              singleProductDetails?.status === "rejected") && (
              <Button
                label={constants.BusinessApprovalForm.approval}
                large={true}
                customClass={styles.formButton}
                handleClick={handleProductApproval}
              />
            )}
        </div>
      </form>
      {
        <PopUp
          open={rejectPopup}
          handleClose={() => {
            setRejectPopup(false);
          }}
        >
          <InputField
            label="Reject reason"
            isTextarea
            onChange={(val: string) => {
              rejectReasonRef.current = val;
            }}
            minWidth="450px"
          />
          <div className={styles.rejectBtn}>
            <Button
              label={constants.BusinessApprovalForm.reject}
              large={true}
              customClass={styles.formButton}
              handleClick={handleRejectProduct}
            />
          </div>
        </PopUp>
      }
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={3000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddIndividualPartTwo;
