import styles from "./SupplierProductsApprovalProcessStyles.module.scss";
import { constants } from "../../../common/constants/constants";
import { ProductApprovalTabs } from "./productsApprovalTabs/ProductsApprovalTabs";
export const SupplierProducsApprovalProcess = () => {
  // const [userType, setUserType] = React.useState("supplier");
  // const searchCustomStyles = {
  //   marginRight: "38px",
  //   marginLeft: "40px",
  //   paddingTop: "0px",
  //   paddingBottom: "0px",
  // };
  return (
    <>
      <div className={styles.ordersListing}>
        {/* <div className={styles.sideNav}>
            <SideNav userType={userType} setUserType={setUserType} />
          </div> */}
        <div className={styles.right}>
          {/* <div className={styles.search}>
              <Search
                placeholder={constants.productListing.SEARCHBAR_PLACEHOLDER}
                customStyles={searchCustomStyles}
              />
            </div> */}
          <div className={styles.heading}>
            {constants.productListing.MANAGE_CATALOGUE}
          </div>
          <ProductApprovalTabs />
        </div>
      </div>
    </>
  );
};
