import { createSlice } from "@reduxjs/toolkit";
import fruit from "../assets/images/fruit.svg";

const initialState = {
  cardData: [
    {
      id: 0,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 1,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 2,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 3,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 4,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 5,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 6,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 7,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 8,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
    {
      id: 9,
      businessName: "Sample Business",
      contactName: "John Doe",
      emailID: "sample@example.com",
      phNo: 1234567890,
      licenseNo: 12345,
      vatNo: 12345,
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      vatFile: {
        file: fruit,
        value: fruit,
      },
      countryName: "Sample Country",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      password: "PASSWORD",
      confirmPassword: "CONFIRM PASSWORD",
      userType: "Buyer",
    },
  ],
};
const adminBuyerListSlice = createSlice({
  name: "adminBuyerList",
  initialState: initialState,

  reducers: {
    updateBuyer: (state: any, action) => {
      const { id, newData } = action.payload;

      const buyerIndex = state.cardData.findIndex(
        (buyer: any) => buyer.id === id
      );

      if (buyerIndex !== -1) {
        state.cardData = state.cardData.map((buyer: any, index: number) => {
          if (index === buyerIndex) {
            return {
              ...buyer,
              ...newData,
              emirates: { ...buyer.emirates, ...newData.emirates },
              country: { ...buyer.country, ...newData.country },
            };
          }
          return buyer;
        });
      } else {
        state.cardData.push({
          id: id ? id : Math.floor(Math.random() * 100),
          ...newData,
          emirates: { ...newData.emirates },
          country: { ...newData.country },
        });
      }
    },
  },
});

export const { updateBuyer } = adminBuyerListSlice.actions;

export default adminBuyerListSlice.reducer;
