import React from "react";
import EditCartHeader from "./editCartHeader";
import PaymentSummary from "./paymentSummary";
import styles from "./placeOrder.module.scss";
import DeliveryAddressTime from "./deliveryAddressTime";
import BackNavigationBtn from "../components/backNavigationBtn";
import { useNavigate } from "react-router-dom";

const PlaceOrder = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.placeOrderPageWrapper}>
      <BackNavigationBtn
        handleBack={() => {
          navigate(-1);
        }}
        text="My cart (0 items)"
      />

      <div className={styles.placeOrderWrapper}>
        <EditCartHeader />
        <div className={styles.addressPaymentWrapper}>
          <DeliveryAddressTime />
          <PaymentSummary />
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
