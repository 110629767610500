import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../common/constants/constants";
interface setupState {
    setupState: string,
}
const setupAccountState : setupState = {
    setupState: constants.onboardingDashboardCard.REVIEW
  };
const setupAccountStateSlice = createSlice({
    name: "setupAccountState",
    initialState: setupAccountState,
    reducers: {
        setSetupState: (state, action) => {
            state.setupState = action.payload
        }
    }

});
export const {setSetupState} = setupAccountStateSlice.actions;
export default setupAccountStateSlice.reducer;