import React, { useState } from "react";
import LoginBackground from "../../../../components/login/LoginBackground";
import styles from "./ForgotPassword.module.scss";
import arrowLeft from "../../../../assets/images/Arrow-left.svg";
import lock from "../../../../assets/images/login/lock.svg";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import Button from "../../../../common/components/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { constants } from "../../../../common/constants/constants";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  loginRoutes,
} from "../../../../common/constants/urlConstants";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";

const ForgotPasswordComp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const emailId = location?.state?.emailId ? location?.state?.emailId : "";
  const [isEmailVerification] = useState(
    location.pathname.includes(routeConfig.resendVerification)
  );
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Enter emailId")
      .matches(constants.formaValidations.EMAIL_REGEX, "Invalid Email format"),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: { email: emailId },
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
    },
    [setValue]
  );

  const [toastData, setToastData] = useState(constants.defaultToastData);

  const forgotPassword = useAxios({
    axiosParams: {
      url: loginRoutes.forgotPassword,
      data: {
        email: watch("email"),
      },
    },
    method: APIMethods.post,
  });

  const resendVerification = useAxios({
    axiosParams: {
      url: loginRoutes.resendVerification,
      data: {
        email: watch("email"),
      },
    },
    method: APIMethods.post,
  });

  const onSubmit = async (data: any) => {
    dispatch(updateIsLoading(true));
    if (isEmailVerification) {
      const response: any = await resendVerification?.fetchData();
      if (response?.status === "SUCCESS") {
        navigate(routeConfig.accountVerification, {
          state: {
            email: data.email,
          },
        });
        dispatch(updateIsLoading(false));
      } else {
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        dispatch(updateIsLoading(false));
      }
    } else {
      const response: any = await forgotPassword?.fetchData();

      if (response?.status === "SUCCESS") {
        navigate(routeConfig.resetLink, {
          state: {
            email: data.email,
          },
        });
        dispatch(updateIsLoading(false));
      } else {
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        dispatch(updateIsLoading(false));
      }
    }
  };

  return (
    <>
      <section className={styles.forgotPassword}>
        <div
          className={styles.forgotPassword__goback}
          onClick={() => navigate(-1)}
          id="back"
        >
          <img src={arrowLeft} alt="arrow" />
          <span>{LOGIN_PAGE_STRINGS.BACK}</span>
        </div>

        <div className={styles.forgotPassword__header}>
          <div className="">
            <img src={lock} alt="lock" />
          </div>
          <div className={styles.headerText}>
            {isEmailVerification
              ? LOGIN_PAGE_STRINGS.RESEND_VERIFICATION_LABEL
              : LOGIN_PAGE_STRINGS.FORGOT_PASSWORD_LABEL}
          </div>
          <div className={styles.subheaderText}>
            {isEmailVerification
              ? LOGIN_PAGE_STRINGS.RESEND_VER_LINK_TEXT
              : LOGIN_PAGE_STRINGS.FORGOT_PWD_TEXT}
          </div>
        </div>

        <div className={styles.forgotPassword__form}>
          <form>
            {/* // <!-- email --> email form-field  formField*/}
            <div className={`${styles.email} ${styles.formField}`}>
              <div className={styles.formFieldItem}>
                <div className={styles.label}>{LOGIN_PAGE_STRINGS.EMAILID}</div>

                <InputField
                  placeholder={LOGIN_PAGE_STRINGS.EMAIL_PLACEHOLDER}
                  type="email"
                  onChange={onChangeField("email")}
                  register={register("email")}
                  errorMessage={errors.email?.message}
                />
              </div>
            </div>

            {/* <!-- email --> */}

            <div className={styles.forgotPasswordButton}>
              <Button
                label={
                  isEmailVerification
                    ? LOGIN_PAGE_STRINGS.RESEND_LINK
                    : LOGIN_PAGE_STRINGS.RESET_LINK
                }
                large={true}
                handleClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </section>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "SUCCESS" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export const ForgotPassword = () => {
  return (
    <LoginBackground
      form={
        <>
          <ForgotPasswordComp />
        </>
      }
    ></LoginBackground>
  );
};
