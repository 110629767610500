/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./AddNewSupplier.module.scss";
import { constants } from "../../../common/constants/constants";
import IndividualProductCard from "../../../common/components/individualProductCard/IndividualProductCard";
import CategoryCard from "../../../common/components/categoryCard/CategoryCard";
import deliveryIcon from "../../../assets/images/delayed-deliveries.svg";

const AddNewSupplier = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headingBox}>
        <div className={styles.text}>{constants.manageSuppliers.heading}</div>
        <div className={styles.text2}>
          {constants.manageSuppliers.subHeading}
        </div>
      </div>
      <div className={styles.contentBox}>
        <div className={styles.card1}>
          <IndividualProductCard
            cardType="search"
            imgSrc={deliveryIcon}
            head="Add individual supplier"
            id={2}
            title={<div>{constants.manageSuppliers.cardOneSubHeading}</div>}
            handleCardClick={() =>
              console.log("handleCardClick: Add individual product")
            }
          />
        </div>{" "}
        <div className={styles.card2}>
          <div>
            <label htmlFor="inputNew">
              <input
                className={styles.inputField}
                type="file"
                id="inputNew"
                onChange={() => {}}
                accept=".xls, .xlsx"
              />
              <CategoryCard
                cardType="search"
                imgSrc={"card2"}
                head="Bulk product upload"
                id={3}
                title={<></>}
                handleCardClick={() => {}}
              />
            </label>
          </div>
          <div className={styles.card2Text} onClick={() => {}}>
            <a
              // href={""}
              // download="bulk_template.xlsx"
              className={styles.downloadLink}
            >
              <span className={styles.downloadText}>
                {constants.manageCatalogue.download}
              </span>
            </a>
            <div className={styles.downloadTextSecondary}>
              {constants.manageCatalogue.template}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSupplier;
