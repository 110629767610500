import React from "react";
import styles from "./supplierDetails.module.scss";
import { constants } from "../../../../../../common/constants/constants";
const SupplierDetails = ({
  supplierDetails,
  wareHouseDetails,
  amountDetails,
  productsDetails = [],
}: {
  supplierDetails: any;
  wareHouseDetails: any;
  amountDetails: any;
  productsDetails: any;
}) => {
  const { business_name, min_order_value } = wareHouseDetails;
  const amount = amountDetails.sub_total && String(Number(amountDetails.sub_total).toFixed(2)).split(".");
  return (
    <div className={styles.supplierDetailsWrapper}>
      <div className={styles.supplierNamePaymentDetails}>
        <h2 className={styles.supplierName}>
          {business_name}
          <span className={styles.movText}>MOV: AED {min_order_value} </span>
        </h2>

        <p className={styles.modeOfPaymentText}>
          {constants.myCart.supplierLabel}: Accepts cash payment only.
        </p>
      </div>
      <div className={styles.orderQuantityWrapper}>
        <span className={styles.orderCount}>{productsDetails.length && productsDetails.length > 1 ? `${productsDetails.length} items` : `${productsDetails.length} item`} | Total:</span>
        AED <span className={styles.orerAmount}>{amount[0]}</span>.
        {amount && amount[1] ? amount && amount[1] : "00"}
      </div>
    </div>
  );
};

export default SupplierDetails;
