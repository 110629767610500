// import Search from "../../../../common/components/search/Search";
import styles from "./AdminManageOrdersStyles.module.scss";
import { AdminOrdersListing } from "../../adminOrdersListing/AdminOrdersListing";

export const AdminManageOrders = () => {
  // const searchCustomStyles = {
  //   marginRight: "38px",
  //   marginLeft: "40px",
  //   paddingTop: "0px",
  //   paddingBottom: "0px",
  // };

  return (
    <>
      <div className={styles.ordersListing}>
        <div className={styles.right}>
          {/* <div className={styles.search}>
            <Search
              placeholder={"Search for products, customers, orders, and more!"}
              customStyles={searchCustomStyles}
            />
          </div> */}
          <div className={styles.heading}>Manage orders</div>
          <AdminOrdersListing />
        </div>
      </div>
    </>
  );
};
