import React from "react";
import styles from "./RelatedProducts.module.scss";
import emptyImage from "../../../../assets/images/emptybox.svg";
import { isImgUrlValid } from "../../../../utils/functions";

const RelatedProducts = ({ relatedProducts, handleSelectProduct }: any) => {
  const [imageError, setImageError] = React.useState(Array.from({ length: relatedProducts.length }).fill(false));
  const getImage = (el: any) => {
    const imgUrl = el.images?.find((el: any) => el.metadata.defaultImage)?.url;
    if (isImgUrlValid(imgUrl)) {
      return imgUrl;
    } else {
      return emptyImage;
    }
  };

  return (
    <>
      <div className={styles.heading}>Related Product Variants</div>
      <div className={styles.relatedProductsWrapper}>
        {relatedProducts?.map((el: any, index: any) => {
          return (
            <>
              <div
                className={styles.relatedProduct}
                onClick={() => {
                  handleSelectProduct(el.id);
                }}
              >
                {imageError[index] ? <img src={emptyImage} /> : <img src={getImage(el)} alt="product name" onError={function () {
                  setImageError((prev: any) => {
                    const newArr = [...prev];
                    newArr[index] = true;
                    return newArr;
                  })
                }} />}
                <div className={styles.relatedProductData}>
                  <h3 className={styles.productName} title={el.title}>{el.title}</h3>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default RelatedProducts;
