import { createSlice } from "@reduxjs/toolkit";
interface IModifyOrders {
  showModal: boolean;
}
const modifyOrdersModal: IModifyOrders = {
  showModal: false,
};
const showModifyOrdersModalSlice = createSlice({
  name: "modifyOrdersModal",
  initialState: modifyOrdersModal,
  reducers: {
    setModifyOrdersModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});
export const { setModifyOrdersModal } = showModifyOrdersModalSlice.actions;
export default showModifyOrdersModalSlice.reducer;
