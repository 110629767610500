import cancelToast from "../../../assets/images/cancelToast.svg";
import styles from "../toastNotification/ToastNotification.module.scss";
import { IToastNotification } from "../../../models/IToastNotification";
import { useEffect, useState } from "react";

export const ToastNotification = (props: IToastNotification) => {
  let content = props.content;
  const size = props.content.length > 47 ? "big" : "";
  // if (props.content.length > 80) {
  //   content = props.content.slice(0, 80) + "...";
  // } else {
  //   content = props.content;
  // }
  if (props.linkAlt) {
    content = content + " |";
  } else {
    content = content;
  }
  const [showToast, setShowToast] = useState(true);
  // setTimeout(
  //   closeToast,
  //   props.autoClose !== undefined ? props.autoClose : 3000
  // );
  function closeToast() {
    setShowToast(false);
    props.handleClose && props.handleClose()
  }
  useEffect(() => {
    let timeOut: any;
    //Remove below condition if buyer also logs in
    if (
      !props.content?.includes(
        "Please log in to the mobile app to utilize the Buyer functionalities"
      )
    ) {
      if (showToast) {
        timeOut = setTimeout(closeToast, props.autoClose ?? 5000);
      }
      return () => {
        if (timeOut) {
          closeToast();
          clearTimeout(timeOut);
        }
      };
    }
  }, [showToast]);
  return (
    <>
      {showToast && (
        <div
          className={`${styles.toast} ${
            props.position === "top-right"
              ? styles.topRight
              : props.position === "top-left"
              ? styles.topLeft
              : props.position === "bottom-left"
              ? styles.bottomLeft
              : styles.bottomRight
          } ${size === "big" ? styles.bigToast : ""}`}
          data-testid="toast"
        >
          <div className={styles.toastLeft}>
            <div className={styles.icon}>
              <img src={props.icon} alt="" />
            </div>
            <div
              className={`${styles.content} ${
                size === "big" ? styles.bigContent : ""
              }`}
            >
              <span>{content}</span>
              {props.linkAlt !== undefined && (
                <span>
                  <button className={styles.link} onClick={props.onClick}>
                    {props.linkAlt}
                  </button>
                </span>
              )}
            </div>
          </div>
          <button
            className={`${styles.cancelButton} ${
              size === "big" ? styles.bigCancelButton : ""
            }`}
            onClick={closeToast}
            data-testid="closeToast"
          >
            <img src={cancelToast} alt="" />
          </button>
        </div>
      )}
    </>
  );
};

// eslint-disable-next-line no-lone-blocks
{
  /* <ToastNotification 
icon={bigSuccessToast} 
content="Item added to favourites. Visit your favourite products and order now! |" 
linkAlt="View cart"  
/> */
}
