import review from "../../../assets/images/review.svg";
import alert from "../../../assets/images/alert.svg";
import success from "../../../assets/images/success.svg";
import styles from "../onboardingDashboardCard/OnboardingDashboardCard.module.scss";
import { IOnboardingDashboardCard } from "../../../models/IOnboardingDashboardCard";
import { useState } from "react";
import { constants } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import { OnBoardingIntro } from "../CoachMarks/caochMarksHelper";
import { SideDrawer } from "../SideDrawer/SideDrawer";
import ConfirmContactNo from "../../../pages/supplier/confirmContactNo/ConfirmContactNo";

export const OnboardingDashboardCard = (props: IOnboardingDashboardCard) => {
  const [icon, setIcon] = useState(props.icon);
  const [isdrawer, setIsDrawer] = useState({
    right: false,
  });
  const navigate = useNavigate();

  function handleMouseEnter() {
    setIcon(props.iconOnHover);
  }

  function handleMouseLeave() {
    setIcon(props.icon);
  }
  function onClick() {
    if (props.type === "Setup business") {
      navigate(routeConfig.buyerBusinessSetupForm);
    } else if (props.type === constants.onboardingDashboardCard.TOUR) {
      new OnBoardingIntro(true);
    } else if (props.type === constants.onboardingDashboardCard.ADD_PRODUCTS) {
      navigate(routeConfig.manageSupplierCatalogue);
    } else if (
      props.type === constants.onboardingDashboardCard.EXPLORE_MARKETPLACE
    ) {
      navigate(routeConfig.marketPlace);
    } else if (
      props.type === constants.onboardingDashboardCard.VERIFY_PHN &&
      props.state !== constants.onboardingDashboardCard.SUCCESS
    ) {
      setIsDrawer({ right: true });
    }
  }

  const buyerCards = [
    {
      type: constants.onboardingDashboardCard.SETUP_BUSINESS,
      Default: {
        heading: "Setup business",
        content:
          "Begin your adventure by spilling the beans on your business details!",
        action: "Get started",
      },
      Review: {
        heading: "Review inprogress",
        content:
          "Hold tight!\nYour profile is being carefully assessed by the Watermelon team.",
        action: "Get started",
      },
      Success: {
        heading: "Account approved",
        content:
          "Review complete! Time to shine. Start ordering and enjoy the Marketplace.",
      },
      Error: {
        heading: "Review failed",
        content:
          "Oops! Profile rejected. Let's fix it up by re-uploading documents.",
        action: "Upload documents",
      },
    },
    {
      type: constants.onboardingDashboardCard.ADD_SUPPLIERS,
      Default: {
        heading: "Add suppliers",
        content: "Added your Suppliers? No problem, add them here!",
        action: "Get started",
      },
      Success: {
        heading: "Added suppliers",
        content: "Added your Suppliers? No problem, add them here!",
        action: "View suppliers",
      },
    },
    {
      type: constants.onboardingDashboardCard.VERIFY_PHN,
      Default: {
        heading: "Verify phone number",
        content:
          "WhatsApp's got your back! Verify your number for order alerts.",
        action: "Get started",
      },
      Success: {
        heading: "Verification done",
        content:
          "Phone number confirmed! You're officially in the verified club. Huzzah!",
      },
    },
    {
      type: constants.onboardingDashboardCard.SETUP_INVENTORY,
      Default: {
        heading: "Setup inventory",
        content:
          "Master your ingredient game! Set up inventory for flawless tracking.",
        action: "Get started",
      },
      Success: {
        heading: "Inventory expansion",
        content:
          "Master your ingredient game! Set up inventory for flawless tracking.",
      },
    },
    {
      type: constants.onboardingDashboardCard.EXPLORE_MARKETPLACE,
      Default: {
        heading: "Explore marketplace",
        content: "Get curious and explore the marketplace for marvelous deals.",
        action: "Get started",
      },
    },
    {
      type: constants.onboardingDashboardCard.TOUR,
      Default: {
        heading: "Take a mini tour",
        content:
          "Strap on your adventure goggles! Explore features with a mini tour.",
        action: "Get started",
      },
    },
  ];
  const supplierCards = [
    {
      type: constants.onboardingDashboardCard.TOUR,
      Default: {
        heading: "Take a mini tour",
        content:
          "Strap on your adventure goggles! Explore features with a mini tour.",
        action: "Get started",
      },
    },
    {
      type: constants.onboardingDashboardCard.SETUP_ACCOUNT,
      Default: {
        heading: "Setup organisational account",
        content:
          "Begin your adventure by spilling the beans on your business details!",
        action: "Get started",
      },
      Review: {
        heading: "Review in progress",
        content:
          "Hold tight! Your profile is being carefully assessed by the Watermelon team.",
        // action: "Get started",
      },
      Success: {
        heading: "Account approved",
        content:
          "Congrats, you're all set to supply! Orders incoming - let the adventure begin!",
      },
      Error: {
        heading: "Review failed",
        content:
          "Oops! Account rejected. Let's fix it up by re-uploading documents.",
        action: "Upload documents",
      },
    },
    {
      type: constants.onboardingDashboardCard.ADD_PRODUCTS,
      Default: {
        heading: "Add products to catalogue",
        content: "Haven't added your products? No problem, add them here!",
        action: "Get started",
      },
      Success: {
        heading: "Added products",
        content: "Products added to the Cart",
        action: "View products",
      },
    },
    {
      type: constants.onboardingDashboardCard.VERIFY_PHN,
      Default: {
        heading: "Verify phone number",
        content:
          "WhatsApp's got your back! Verify your number for order alerts.",
        action: "Get started",
      },
      Success: {
        heading: "Verification done",
        content:
          "Phone number confirmed! You're officially in the verified club. Huzzah!",
      },
    },
  ];
  let onboardingDashboardCards = props.isCardSupplier
    ? supplierCards
    : buyerCards;
  let cardIdx = onboardingDashboardCards.findIndex(
    (card) => card.type === props.type
  );
  let card: any;
  if (props.state === constants.onboardingDashboardCard.REVIEW) {
    card = onboardingDashboardCards[cardIdx].Review;
  } else if (props.state === constants.onboardingDashboardCard.ERROR) {
    card = onboardingDashboardCards[cardIdx].Error;
  } else if (props.state === constants.onboardingDashboardCard.SUCCESS) {
    card = onboardingDashboardCards[cardIdx].Success;
  } else {
    card = onboardingDashboardCards[cardIdx].Default;
  }
  return (
    <>
      <div
        style={{
          cursor:
            props.state === constants.onboardingDashboardCard.SUCCESS &&
            (props?.type === constants.onboardingDashboardCard.VERIFY_PHN ||
              props?.type === constants.onboardingDashboardCard.SETUP_ACCOUNT)
              ? //  &&
                // props.cardType === "Supplier"
                "not-allowed"
              : "pointer",
        }}
        className={`${styles.card} ${
          props.isCardSupplier ? styles.supplierCard : ""
        }  ${
          props.state === constants?.onboardingDashboardCard?.ERROR
            ? styles.redBorder
            : props.state === constants?.onboardingDashboardCard?.SUCCESS
            ? styles.greenBorder
            : props.state === constants?.onboardingDashboardCard?.REVIEW
            ? styles.orangeBorder
            : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="card"
        onClick={props.type === "add_products" ? props.onClick : onClick}
      >
        <div className={styles.icon}>
          <img src={icon} alt="icon" data-testId="icon"></img>
        </div>
        <div
          className={`${styles.cardContent} ${
            props.isCardSupplier ? styles.supplierCardContent : ""
          }`}
        >
          <div
            className={`${styles.heading} ${
              props.state === constants.onboardingDashboardCard.REVIEW
                ? styles.reviewHeading
                : props.state === constants.onboardingDashboardCard.ERROR
                ? styles.errorHeading
                : props.state === constants.onboardingDashboardCard.SUCCESS
                ? styles.successHeading
                : ""
            }`}
            data-testId="heading"
          >
            {card?.heading}
          </div>
          <div
            className={`${styles.content} ${
              props.isCardSupplier && styles.supplierContent
            }`}
          >
            {card?.content}
          </div>
          {card?.action && (
            <button
              className={`${styles.link} ${
                props.isCardSupplier ? styles.supplierLink : ""
              }`}
              data-testId="handleClickbutton"
              onClick={props.onClick}
            >
              {card.action}
            </button>
          )}
        </div>
        {props.state === constants.onboardingDashboardCard.ERROR && (
          <div className={`${styles.stateIcon} ${styles.error}`}>
            <img
              className={styles.stateIconImage}
              src={alert}
              alt="error state icon"
            ></img>
          </div>
        )}
        {props.state === constants.onboardingDashboardCard.SUCCESS && (
          <div className={`${styles.stateIcon} ${styles.success}`}>
            <img
              className={styles.stateIconImage}
              src={success}
              alt="success state icon"
            ></img>
          </div>
        )}
        {props.state === constants.onboardingDashboardCard.REVIEW && (
          <div className={`${styles.stateIcon} ${styles.review}`}>
            <img
              className={styles.stateIconImage}
              src={review}
              alt="review state icon"
            ></img>
          </div>
        )}
      </div>
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={constants.confirmContactNo.heading}
          childComponent={<ConfirmContactNo overlayCallback={setIsDrawer} />}
        />
      )}
    </>
  );
};
