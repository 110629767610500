import { createSlice } from "@reduxjs/toolkit";
import cancelled from "../assets/images/orderStatusIcons/CancelledSmall.svg";
import confirmed from "../assets/images/orderStatusIcons/Confirmed.svg";
import delivered from "../assets/images/orderStatusIcons/Delivered.svg";
import packed from "../assets/images/orderStatusIcons/Packed.svg";
import placed from "../assets/images/orderStatusIcons/Placed.svg";
import onProgress from "../assets/images/orderStatusIcons/Progressing.svg";
function formatTimestamp(timestamp: any) {
  const date = new Date(timestamp);
  const year = date.getFullYear().toString().substring(2);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();

  return `${day} ${month} ${year}`;
}
const getIcon = (status: string) => {
  switch (status) {
    case "placed":
      return placed;
    case "delivered":
      return delivered;
    case "confirmed":
      return confirmed;
    case "shipped":
      return packed;
    case "canceled":
      return cancelled;
    default:
      return onProgress;
  }
};

function isDeliveryDelayed(expectedDeliveryTimestamp: string): boolean {
  const expectedDeliveryDate = new Date(expectedDeliveryTimestamp);
  const currentDate = new Date();

  return currentDate > expectedDeliveryDate;
}

const transformKeys = (item: any) => {
  console.log("item", item);

  return {
    icon: getIcon(item?.order_tracking?.status),
    orderId: item?.id,
    orderPONumber: item?.order_number ?? "--",
    // orderStatus: item?.order_tracking?.status ?? "order status",
    paidStatus:
      item?.payment_status == "not_paid" ? "unpaid" : item?.payment_status,
    placedOn: formatTimestamp(item?.order_tracking?.placed_date),
    toBeDelivered:
      item?.order_tracking?.expected_delivery == null
        ? "--"
        : formatTimestamp(item?.order_tracking?.expected_delivery),
    // deliveryStatus: "On time", // in future compare b/w placed date & expected data
    deliveryStatus:
      item?.status === "completed" || item?.status === "canceled"
        ? item?.fulfillment_status
        : item?.order_tracking?.expected_delivery != null
        ? isDeliveryDelayed(item?.order_tracking?.expected_delivery)
          ? "Delayed"
          : "On time"
        : "-",
    buyerName: `${item?.buyer?.business_name}  ` ?? "--",
    deliveredDate:  item.order_tracking?.delivered_date && formatTimestamp(item.order_tracking?.delivered_date),
    orderValue: Number(item?.total)?.toFixed(2) ?? 0,
    orderItems: item?.items?.count || 0,
    type: item?.status,
    actualAmount: Number(item?.actual_amount)?.toFixed(2) ?? 0,
    address:
      `${item?.billing_address?.address_2}, ${item?.billing_address?.address_1}` ??
      "--",
    area: item?.billing_address?.area ?? "--",
    orderType:item?.order_type //Online or Offline
  };
};

const adminManageOrdersListSlice = createSlice({
  name: "adminManageOrdersList",
  initialState: null,
  reducers: {
    adminAddOrdersListPlaced: (state, action) => {
      const transformedPayload = action.payload.map(transformKeys);
      return transformedPayload;
    },
  },
});

export const { adminAddOrdersListPlaced } = adminManageOrdersListSlice.actions;

export default adminManageOrdersListSlice.reducer;
