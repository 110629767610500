import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import blog from "../../../../assets/images/blog.svg";
import addCatalogueHover from "../../../../assets/images/supplierOnboardingDashboard/addCatalogueHover.svg";
import addProductCatalogue from "../../../../assets/images/supplierOnboardingDashboard/addProdCatalogue.svg";
import setupHover from "../../../../assets/images/supplierOnboardingDashboard/setupHover.svg";
import setupaccount from "../../../../assets/images/supplierOnboardingDashboard/setupaccount.svg";
import tour from "../../../../assets/images/supplierOnboardingDashboard/tour.svg";
import tourHover from "../../../../assets/images/supplierOnboardingDashboard/tourHover.svg";
import verifyPhn from "../../../../assets/images/supplierOnboardingDashboard/verifyPhn.svg";
import verifyPhnHover from "../../../../assets/images/supplierOnboardingDashboard/verifyPhnHover.svg";
// import videoImg from "../../../../assets/images/videoImg.svg";
import { OnBoardingIntro } from "../../../../common/components/CoachMarks/caochMarksHelper";
import { SideDrawer } from "../../../../common/components/SideDrawer/SideDrawer";
import BlogsAndArticles from "../../../../common/components/blogsAndArticles/BlogsAndArticles";
import { OnboardingDashboardCard } from "../../../../common/components/onboardingDashboardCard/OnboardingDashboardCard";
// import VideoCard from "../../../../common/components/videoCard/VideoCard";
import { constants } from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import ConfirmContactNo from "../../confirmContactNo/ConfirmContactNo";
import styles from "./OnboardingDashboardStyles.module.scss";
// import { IVideoCard } from "../../../../models/IVideoCard";
// import { IBlogsAndArticles } from "../../../../models/IBlogsAndArticles";
import { useAxios } from "../../../../services/useAxios";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import alertToast from "../../../../assets/images/alertToast.svg";
// import ShareAProduct from "../../../../common/components/shareAProduct/ShareAProduct";  //import ShareAProduct if you want to use it here...
// import PopupView from "../../../../common/components/popupView/PopupView"; //import Popview for displaying the ShareAProduct component here...

export const OnboardingDashboard = () => {
  const navigate = useNavigate();
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );

  // state to show the popup for ShareAProduct component
  // const [showShare, setShowShare] = useState(false)

  const [customerId] = useState(loggedInUser?.business_details?.[0]?.customer_id);
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [fetchedData, setFetchedData] = useState<any>({});
  const fetchCTACardStatus = useAxios({
    axiosParams: {
      url: `store/onboarding/supplier/${customerId}/${businessId}`,
    },
    method: "GET",
  });
  const fetchCTACardStatusAPI = async () => {
    if (customerId && businessId) {
      const response: any = await fetchCTACardStatus.fetchData();
      if (response && response?.status === "SUCCESS") {
        localStorage.setItem("is_approved", response?.data?.is_approved);
        setFetchedData(response?.data);
      }
    }
  };

  useEffect(() => {
    fetchCTACardStatusAPI();
  }, []);

  const [isdrawer, setIsDrawer] = React.useState({
    right: false,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  // function to handle the popup view for ShareAProduct component
  // const handleSharePopup = () =>{
  //   setShowShare(!showShare)
  // }
  const handleOnProductCard = async () => {
    await fetchCTACardStatusAPI();
    if (fetchedData && fetchedData?.business_setup_status === "pending") {
      setToastData({
        message: "Please setup your business to add a Product",
        status: "Fail",
      });
    } else if (fetchedData && fetchedData?.is_approved !== true) {
      setToastData({
        message:
          "Business setup request is under review. Please wait until it gets approved.",
        status: "Fail",
      });
    } else if (
      fetchedData &&
      fetchedData?.business_setup_status === "rejected"
    ) {
      setToastData({
        message: "Business setup is rejected, Please re-upload documents ",
        status: "Fail",
      });
    } else if (
      (fetchedData && fetchedData?.business_setup_status === "approved") ||
      fetchedData?.is_approved === true
    ) {
      navigate(
        fetchedData?.products === true
          ? routeConfig?.productListingCatalogue
          : routeConfig.manageSupplierCatalogue
      );
    }
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 5000);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const getCardValue = () => {
    if (width > 1000 && width < 1290) {
      return 2;
    } else if (width > 1290 && width < 1450) {
      return 3;
    } else if (width > 1450 && width < 2000) {
      return 4;
    } else {
      return 5;
    }
  };

  const handleBlogNavigation = () => {
    if (true) {
      window.open("https://www.watermelon.market/blog/", "_blank");
    }
  };

  // let videoCardsData: IVideoCard[] = [
  //   {
  //     id: 0,
  //     displayImg: videoImg,
  //     videoTitle: "Start your new journey with Watermelon",
  //     videoDuration: "5 mins",
  //     videoSource:
  //       "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  //   },
  //   {
  //     id: 1,
  //     displayImg: videoImg,
  //     videoTitle: "Start your new journey with Watermelon",
  //     videoDuration: "5 mins",
  //     videoSource:
  //       "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  //   },
  //   {
  //     id: 2,
  //     displayImg: videoImg,
  //     videoTitle: "Start your new journey with Watermelon",
  //     videoDuration: "5 mins",
  //     videoSource:
  //       "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  //   },
  //   {
  //     id: 3,
  //     displayImg: videoImg,
  //     videoTitle: "Start your new journey with Watermelon",
  //     videoDuration: "5 mins",
  //     videoSource:
  //       "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  //   },
  //   {
  //     id: 4,
  //     displayImg: videoImg,
  //     videoTitle: "Start your new journey with Watermelon",
  //     videoDuration: "5 mins",
  //     videoSource:
  //       "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  //   },
  // ];
  // const [videosList, setVideoList] = useState<any>([]);

  // useEffect(() => {
  //   const count = getCardValue();
  //   setVideoList(videoCardsData.slice(0, count));
  // }, []);

  const [blogsArticlesData, setBlogsArticlesData] = useState<any>([]);
  const getBlogs = useAxios({
    axiosParams: {
      url: "store/blogs",
    },
    method: "GET",
  });
  const getBlogsApiData = async () => {
    const response: any = await getBlogs.fetchData();
    if (response && response?.status === "SUCCESS") {
      setBlogsArticlesData(response?.data?.result);
    }
  };
  useEffect(() => {
    getBlogsApiData();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.right}>
          <div className={styles.content}>
            <div className={styles.greetings}>
              Hi{" "}
              {loggedInUser?.first_name
                ? `${loggedInUser?.first_name} ${loggedInUser?.last_name}`
                : "User"}
              , Welcome aboard
            </div>
            <div className={styles.contentContainer}>
              {/* remove hide class in future */}
              <div
                className={`${styles.viewDashboard} ${styles.hide}`}
                onClick={() =>
                  navigate(routeConfig.supplierFullyLoadedDashboard)
                }
              >
                View Fully Loaded Dashboard
              </div>
              <div className={styles.introCards}>
                <OnboardingDashboardCard
                  icon={tour}
                  iconOnHover={tourHover}
                  isCardSupplier={true}
                  state={constants.onboardingDashboardCard.DEFAULT}
                  type={constants.onboardingDashboardCard.TOUR}
                  onClick={() => {
                    new OnBoardingIntro(true);
                  }}
                />
                <OnboardingDashboardCard
                  cardType={"Supplier"}
                  icon={setupaccount}
                  iconOnHover={setupHover}
                  isCardSupplier={true}
                  state={
                    fetchedData?.business_setup_status === "approved"
                      ? "Success"
                      : fetchedData?.business_setup_status === "review"
                      ? "Review"
                      : fetchedData?.business_setup_status === "rejected"
                      ? "Error"
                      : ""
                  }
                  type={constants.onboardingDashboardCard.SETUP_ACCOUNT}
                  onClick={() => {
                    if (
                      fetchedData?.business_setup_status !== "approved" &&
                      fetchedData?.business_setup_status !== "review"
                    ) {
                      navigate(routeConfig.businessSetupForm);
                    } else {
                      setToastData({
                        message:
                          fetchedData?.business_setup_status === "approved"
                            ? "Account is Approved"
                            : "Account under Review",
                        status:
                          fetchedData?.business_setup_status === "approved"
                            ? "Success"
                            : "Alert",
                      });
                      setTimeout(() => {
                        setToastData(constants.defaultToastData);
                      }, 5000);
                    }
                  }}
                />
                <OnboardingDashboardCard
                  icon={addProductCatalogue}
                  iconOnHover={addCatalogueHover}
                  isCardSupplier={true}
                  state={fetchedData?.products === true ? "Success" : ""}
                  type={constants.onboardingDashboardCard.ADD_PRODUCTS}
                  onClick={() => handleOnProductCard()}
                />
                <OnboardingDashboardCard
                  icon={verifyPhn}
                  iconOnHover={verifyPhnHover}
                  isCardSupplier={true}
                  state={
                    fetchedData?.customer_is_phone_verified
                      ? constants.onboardingDashboardCard.SUCCESS
                      : constants.onboardingDashboardCard.DEFAULT
                  }
                  type={constants.onboardingDashboardCard.VERIFY_PHN}
                  onClick={() => {
                    setIsDrawer({ right: true });
                  }}
                />
              </div>
              {/* <div className={styles.cards}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>
                    {constants.onboardingDashboard.howTo}
                  </div>
                  <div className={styles.viewAll}>
                    {constants.onboardingDashboard.viewAll}
                  </div>
                </div>
                <div className={styles.cardContent}>
                  {videosList &&
                    videosList.map(
                      (data: IVideoCard, index: number) =>
                        index < getCardValue() && (
                          <VideoCard
                            id={data?.id}
                            displayImg={data?.displayImg}
                            videoTitle={data?.videoTitle}
                            videoDuration={data?.videoDuration}
                            videoSource={data?.videoSource}
                          />
                        )
                    )}
                </div>
              </div> */}
              <div className={`${styles.cards} ${styles.margin}`}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>
                    {constants.onboardingDashboard.blogsAndArticles}
                  </div>
                  <div
                    className={styles.viewAll}
                    onClick={handleBlogNavigation}
                  >
                    {constants.onboardingDashboard.viewAll}
                  </div>
                </div>
                <div className={styles.articleContent}>
                  {blogsArticlesData &&
                    blogsArticlesData.map(
                      (data: any, index: number) =>
                        index < getCardValue() && (
                          <BlogsAndArticles
                            id={data?.id}
                            title={data?.description}
                            author={data?.author_name}
                            img={data?.image}
                            url={data?.url}
                          />
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={constants.confirmContactNo.heading}
          childComponent={<ConfirmContactNo overlayCallback={setIsDrawer} />}
        />
      )}
      {toastData?.message ? (
        <ToastNotification
          icon={
            toastData.status === "Success"
              ? successToast
              : toastData.status === "Alert"
              ? alertToast
              : errorToast
          }
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}

      {/* if you want to use ShareAProduct here using popup view */}
      {/* {
        showShare && <PopupView 
        heading={'Share'}
          childComponent={ShareAProduct}
          height={370}
          width={686}
          handlePopup={handleSharePopup} ></PopupView>
      } */}
    </>
  );
};
