import styles from "./InvoiceCardStyles.module.scss";
import { useEffect, useRef } from "react";
import invoice from "../../../../../assets/images/invoiceDetails.svg";
import download from "../../../../../assets/images/download.svg";
import phone from "../../../../../assets/images/phone-call.svg";
import paid from "../../../../../assets/images/ordersListing/paid.svg";
import unpaid from "../../../../../assets/images/ordersListing/Unpaid.svg";
import { useNavigate } from "react-router";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods } from "../../../../../common/constants/urlConstants";
import PopperComponent from "../../../../../common/components/PopperComponent/PopperComponent";
import RowActions from "../../../../../common/components/rowActions/RowActions";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";

export const InvoiceCard = (props: any) => {
  const {
    uniqueID,
    invoiceNo,
    orderId,
    outlet,
    supplier,
    estAmount,
    actualAmount,
    paidStatus,
    showMenu,
    handleShowMenu,
    id,
    isMigratedInvoices=false,
  } = props;
  const dispatch = useDispatch();
  const onMouseEnter = () => {
    if(!isMigratedInvoices)
    handleShowMenu(uniqueID, true);
  };
  const navigate = useNavigate();
  const userData = localStorage.getItem("loggedInUser");

  const isadmin =
    userData ? JSON.parse(userData)?.typeOfUser.includes("admin") : false
 const isBuyer =  userData ? JSON.parse(userData)?.typeOfUser.includes("buyer") : false
  const actionShow = showMenu?.id === uniqueID && showMenu?.show ? true : false;

  const onMouseLeave = () => {
    // if (!(showMenu.show && showMenu.id === uniqueID)) {
    handleShowMenu(uniqueID, false);

    // }
  };
  const navToInvoiceDetails = () => {
    isadmin
      ? navigate(`/admin/manage-admin-invoices/invoices-details/${id}`)
      : isBuyer ?  navigate(`/buyer/invoices-details/${id}`) : navigate(`/supplier/invoices-details/${id}`);
  };

  //api to download the invoice in pdf
  const downnloadInvoice = useAxios({
    axiosParams: {
      url: `store/invoice/download_invoice/${id}`,
      responseType: "arraybuffer",
    },
    method: APIMethods.get,
  });
  const downloadPDF = (data: ArrayBuffer) => {
    // Create a Blob from the binary data
    const blob = new Blob([data], { type: "application/pdf" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${invoiceNo}.pdf`;

    // Append the link to the document and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const downnloadInvoiceAPI = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await downnloadInvoice?.fetchData();
    dispatch(updateIsLoading(false));

    if (response) {
      downloadPDF(response);
    }
  };
  const actionsList = [
    {
      id: 0,
      icon: invoice,
      label: "View invoice details",
      onClick: () => {
        handleShowMenu(uniqueID, false);

        navToInvoiceDetails();
      },
    },
    {
      id: 1,
      icon: download,
      label: "Download invoice",
      onClick: () => {
        downnloadInvoiceAPI();
        handleShowMenu(uniqueID, false);
      },
    },
    {
      id: 2,
      icon: phone,
      label: "Contact support",
      onClick: () => {
        handleShowMenu(uniqueID, false);
      },
    },
  ];
  const actionsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        actionsContainerRef &&
        actionsContainerRef.current &&
        !actionsContainerRef?.current?.contains(event.target)
      ) {
        handleShowMenu(uniqueID, false);

        handleShowMenu(uniqueID, false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.card} ${isMigratedInvoices && styles.hover}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={isMigratedInvoices?()=>{}: navToInvoiceDetails}
      >
        <div className={styles.details}>
          <div className={styles.orderID}>
            <div className={styles.icon}>
              <img
                src={paidStatus === "not_paid" ? unpaid : paid}
                alt="delivered"
              ></img>
            </div>
            <div className={styles.orderDetails}>
              <div className={styles.id}>
                <div
                  className={styles.idNo}
                  title={invoiceNo.length > 8 ? invoiceNo : ""}
                >
                  {invoiceNo && invoiceNo}
                </div>
                <div
                  className={`${
                    paidStatus === "not_paid" ? styles.unpaid : styles.paid
                  }`}
                >
                  {paidStatus === "not_paid" ? "Unpaid" : "Paid"}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.placedOn} ${styles.deliveryStatus}`}
            title={orderId.length > 10 ? orderId : ""}
          >
            {orderId.length > 10 ? `${orderId?.toUpperCase()?.slice(0, 10)}...` : orderId?.toUpperCase()}
          </div>
         {!isMigratedInvoices&& <div
            className={`${styles.buyerName} ${styles.deliveryStatus}`}
            title={outlet.length > 10 ? outlet : ""}
          >
            {outlet.length > 10 ? `${outlet.slice(0, 10)}...` : outlet}
          </div>}
          <div
            className={`${styles.orderValue} ${styles.deliveryStatus}`}
            title={supplier.length > 10 ? supplier : ""}
          >
            {supplier.length > 10 ? `${supplier.slice(0, 10)}...` : supplier}
          </div>
          <div
            className={`${styles.actualAmount} ${styles.deliveryStatus}`}
            title={estAmount > 99999 ? estAmount : ""}
          >
            AED{" "}
            {estAmount > 99999
              ? `${estAmount.toFixed(2).slice(0, 5)}...`
              : estAmount.toFixed(2)}
          </div>
          <div
            className={`${
              actualAmount ? styles.orderItems : styles.orderItemsMargin
            } ${styles.deliveryStatus}`}
            title={actualAmount > 99999 ? actualAmount : ""}
          >
            AED{" "}
            {actualAmount > 99999
              ? `${actualAmount.toFixed(2).slice(0, 5)}...`
              : actualAmount.toFixed(2)}
          </div>
        </div>
          {actionShow && (
            <PopperComponent>
              <div className={styles.actionItems}>
                {actionsList.length > 0 && <RowActions actions={actionsList} />}
              </div>
            </PopperComponent>
          )}
      </div>
    </>
  );
};
