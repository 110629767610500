import { buyerEndpoints, marketPlaceUrls } from "../common/constants/urlConstants";
import { axiosAPI } from "./axiosInstance";

export const getNewCartID = async (customerID: string, outletID: string) => {
    const url: string = `${marketPlaceUrls?.getNewCartID}${customerID}/${outletID}/me`;
    const body = {
      customer_id: customerID,
    };
    return axiosAPI
      .post(url, body)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error?.response?.data;
      });
  };

  export const addProductToCart = async (
    cartID: string,
    variantID: string,
    quantity: number,
    productImage: string,
  ) => {
    const url: string = `${"/store/my-cart/"}${cartID}/line-items`;
    const body: any = {
      variant_id: variantID,
      quantity: quantity,
      metadata: {
        product_image: productImage ?? "",
      },
    };
    console.log(url, body);
    return axiosAPI
      .post(url, body)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error?.response?.data;
      });
  };
  
  export const updateQuantityOfProduct = async (
    cartID: string,
    lineItemId: string,
    body: any,
  ) => {
    const url: string = `${buyerEndpoints.updateProductQuantity}${cartID}/line-items/${lineItemId}`;
    return axiosAPI
      .post(url, body)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        console.error("==== error was ===", error?.response);
      });
  };
  export const deleteProductFromCart = async (
    cartID: string,
    lineItemId: string,
  ) => {
    const url: string = `${buyerEndpoints.updateProductQuantity}${cartID}/line-items/${lineItemId}`;
    return axiosAPI
      .delete(url)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error?.response?.data;
      });
  };
    