import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../common/components/breadCrumb/BreadCrumb";
import Button from "../../../common/components/button/Button";
import ProductCarouselZoom from "../../../common/components/productCarouselZoom/ProductCarouselZoom";
import ProductEllipsisDescription from "../../../common/components/productEllipsisDescription/ProductEllipsisDescription";
import ProductQuantityPicker from "../../../common/components/productQuantityPicker/ProductQuantityPicker";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import { IProductAccordionItemProps } from "../../../models/IProductAccordionItemProps";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import {
  getSingleProductDetails,
  updateSingleProductStatus,
  resetProductDetails,
} from "../../../reducerSlices/productDetailsSlice";
import { useAxios } from "../../../services/useAxios";
import styles from "./SupplierProductDetailsPage.module.scss";
import successToast from "../../../assets/images/successToast.svg";
// import errorToast from '../../../assets/images/errorToast.svg'
// import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import emptyImage from "../../../assets/images/emptybox.svg";
import { checkImageValid, isImgUrlValid } from "../../../utils/functions";
import { Chip } from "@mui/material";
import RelatedProducts from "./components/RelatedProducts";
import PopperComponent from "../../../common/components/PopperComponent/PopperComponent";
import shareIcon from "../../../assets/images/share.svg";
// import { RWebShare } from "react-web-share";
import PopupView from "../../../common/components/popupView/PopupView";
import ShareAProduct from "../../../common/components/shareAProduct/ShareAProduct";
const SupplierProductDetailsPage = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const location = useLocation();
  const singleProductDetails = useSelector(
    (state: any) => state?.singleProductDetails
  );
  // const [updateProductActionData, setUpdateProductActionData] = useState<any>(
  //   {}
  // );
  const navigate = useNavigate();
  const userData: any = localStorage.getItem("loggedInUser");
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(userData && userData)?.typeOfUser?.includes("admin")
      ? true
      : false
  );
  const [toastData, setToastData] = useState(constants.defaultToastData);

  // const [customerId] = useState(
  //   JSON.parse(userData && userData) &&
  //     JSON.parse(userData && userData)?.typeOfUser[0] !== "admin" &&
  //     JSON.parse(userData && userData)?.business_details?.[0]?.customer_id
  // );
  // const [businessId] = useState(
  //   JSON.parse(userData && userData) &&
  //     JSON.parse(userData && userData)?.typeOfUser[0] !== "admin" &&
  //     JSON.parse(userData && userData)?.business_details?.[0]?.business_setup_id
  // );
  const [productImages, setProductImages] = useState<any>([]);
  const [metadata, setMetadata] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const isMasterProduct = location.state?.masterProduct;
  const isOfflineProduct = location.state?.offlineProduct;
  const btnRef = useRef<any>(null);
  const attributesDisplayCount = 3;
  const getUrl = (id?: string) => {
    const currentPath = window.location.href;
    return currentPath?.includes("share-product")
      ? `store/product/share-product/${id}`
      : isMasterProduct
      ? `store/master-product/${id}`
      : isOfflineProduct
      ? `store/offline-product/${id}`
      : `store/product/${id}`;
  };
  const getProductDetails = useAxios({
    axiosParams: {
      url: getUrl(productId),
    },
    method: "GET",
  });

  const getProductDetailsData = useCallback(
    async (id?: string) => {
      dispatch(updateIsLoading(true));
      const response: any = await getProductDetails.fetchData({
        axiosParams: {
          ...(id && { url: getUrl(id) }),
        },
      });
      if (response && response?.data) {
        dispatch(getSingleProductDetails(response?.data));
        if (response?.data?.images && response?.data?.images?.length > 0) {
          let images = [];
          let newImages = [...response?.data?.images];
          if (
            response?.data?.images?.find((item: any) => item?.metadata != null)
          ) {
            let defImage = response?.data?.images?.find(
              (item: any) => item?.metadata?.default_image === true
            );
            images = newImages?.filter(
              (item: any) =>
                item?.metadata && item?.metadata?.default_image === false
            );
            if (defImage) {
              images?.unshift(defImage);
            }
          } else {
            images = response?.data?.images;
          }
          images.forEach(async (img: any) => {
            if (isImgUrlValid(img?.url)) {
              const contents = await checkImageValid(img?.url);
              if (!contents) {
                img["url"] = emptyImage;
              }
            } else {
              img["url"] = emptyImage;
            }
          });
          let formattedImages = images?.map((image: any) => ({
            id: image.id,
            smallImageSrc: image?.url,
            largeImageSrc: image?.url,
          }));
          console.log("inside5");
          const productMetadata = {
            image: formattedImages?.[0]
              ? formattedImages?.[0]?.smallImageSrc
              : "",
            title: `Take a look at this product, ${response?.data?.title}`,
            description:
              response?.data?.description?.length > 50
                ? response?.data?.response?.data?.substring(0, 50) + "..."
                : response?.data?.description,
            currentUrl: response?.data?.shareUrl,
          };
          setMetadata(productMetadata);
          setProductImages(formattedImages);
        }
      }
      console.log("inside6");

      dispatch(updateIsLoading(false));
    },
    [dispatch, getProductDetails, singleProductDetails]
  );

  useEffect(() => {
    if (productId) {
      getProductDetailsData();
    }
  }, [productId]);

  useEffect(() => {
    return function cleanUp() {
      dispatch(resetProductDetails());
    };
  }, []);

  const attributesList = Object.values(
    singleProductDetails?.metadata?.attributes || {}
  );
  const productDetails = {
    accordionItems: [
      {
        productKey: "Country of origin",
        productDescription: singleProductDetails?.country?.flag && (
          <div style={{ display: "flex", gap: "6px" }}>
            <img
              width={15}
              height={15}
              src={singleProductDetails.country.flag}
              alt={singleProductDetails.country.name}
            />
            <p>{singleProductDetails?.country?.name ?? "-"}</p>
          </div>
        ),
      },
      {
        productKey: "Watermelon product code",
        productDescription:
          singleProductDetails?.watermelonProductCode &&
          (singleProductDetails?.watermelonProductCode.length > 40
            ? singleProductDetails?.watermelonProductCode.slice(0, 40) + "..."
            : singleProductDetails.watermelonProductCode),
      },
      {
        productKey: "Product HS Code",
        productDescription: singleProductDetails?.hsCode
          ? singleProductDetails?.hsCode.length > 40
            ? singleProductDetails?.hsCode
            : singleProductDetails.hsCode
          : "-",
      },
      {
        productKey: "Supplier Product Code",
        productDescription: singleProductDetails?.productCode
          ? singleProductDetails?.productCode
          : "-",
      },
      {
        productKey: "Associated tags",
        productDescription: singleProductDetails?.tags
          ? singleProductDetails?.tags.length > 60
            ? singleProductDetails.tags
                ?.map((tag: any) => tag.value)
                .join(", ")
                .slice(0, 60) + "..."
            : singleProductDetails.tags?.map((tag: any) => tag.value).join(", ")
          : "-",
      },
      {
        productKey: "Product is",
        productDescription:
          singleProductDetails?.metadata?.productDetails?.length > 0
            ? singleProductDetails.metadata.productDetails.join(", ")
            : "-",
      },
      {
        productKey: "Certification",
        productDescription:
          singleProductDetails?.metadata?.certifications?.length > 0
            ? singleProductDetails.metadata.certifications.join(", ")
            : "-",
      },
    ],
  };

  const handleEdit = () => {
    let user = localStorage.getItem("loggedInUser");
    if (user && JSON.parse(user)?.typeOfUser?.includes("admin")) {
      navigate(
        window.location.pathname.includes(routeConfig.adminManageCatalogue)
          ? routeConfig.adminManageCatalogueForm.replace(
              ":productId",
              productId ?? ""
            )
          : routeConfig.adminProductApprovalForm.replace(
              ":productId",
              productId ?? ""
            ),
        {
          state: {
            masterProduct: isMasterProduct,
            offlineProduct: isOfflineProduct,
          },
        }
      );
    } else {
      navigate(`/supplier/catalogue/product-update/${productId}`);
    }
  };
  const ProductAccordionItem = ({
    productKey,
    productDescription,
  }: IProductAccordionItemProps) => {
    return (
      <>
        <div className={styles.accordionItem}>
          <h3 className={styles.productKey}>{productKey}</h3>
          <h3
            className={styles.productDescriptioncolor}
            title={
              productKey === "Associated tags" ||
              productKey === "Product is" ||
              productKey === "Certification" ||
              productKey === "Product HS Code"
                ? productDescription
                : ""
            }
          >
            {productDescription?.length > 150
              ? productDescription.slice(0, 100) + "..."
              : productDescription}
          </h3>
        </div>
      </>
    );
  };

  const breadcrumbItems = [
    {
      label: `${
        window?.location?.pathname.includes("catalogue/share-product")
          ? "Catalogue"
          : window?.location?.pathname.includes("/supplier/catalogue/")
          ? "Manage catalogue"
          : window?.location?.pathname.includes("/admin/manage-catalogue/")
          ? "Manage catalogue"
          : window?.location?.pathname.includes("/admin/products-approval/")
          ? "Products Approval"
          : ""
      }`,
      link: window?.location?.pathname.includes("catalogue/share-product")
        ? isAdmin
          ? routeConfig.adminManageCatalogue
          : routeConfig.productListingCatalogue
        : window?.location?.pathname.includes("/supplier/catalogue/")
        ? routeConfig.productListingCatalogue
        : window?.location?.pathname.includes(
            "/admin/manage-catalogue/product-details/"
          )
        ? routeConfig.adminManageCatalogue
        : window?.location?.pathname.includes("/admin/products-approval/")
        ? routeConfig.adminProductApproval
        : routeConfig.dashboard,
    },
    {
      label:
        singleProductDetails &&
        (singleProductDetails?.title?.length > 120
          ? singleProductDetails?.title
              ?.slice(0, 120)
              ?.split(" ")
              ?.map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              ?.join(" ") + "..."
          : singleProductDetails?.title
        )
          ?.split(" ")
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
    },
  ];
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  const updateProduct = useAxios({
    axiosParams: {
      url: `store/catalogue/updateproduct/${productId}`,
      data: {
        is_deactivated: !singleProductDetails?.isDeactivated,
      },
    },
    method: "POST",
  });

  //API to update the product's status - activate/deactivate, in stock/out of stock
  const updateProductStatus = async (action: string) => {
    // if (Object.keys(updateProductActionData).length > 0) {
    dispatch(updateIsLoading(true));
    const response: any = await updateProduct.fetchData({
      axiosParams: {
        data: {
          ...(action === "availabilty" && {
            avaliabilty_status: !singleProductDetails?.avaliabiltyStatus,
          }),
          ...(action !== "availabilty" && {
            is_deactivated: !singleProductDetails?.isDeactivated,
          }),
        },
      },
    });
    dispatch(updateIsLoading(false));
    if (response && response?.status && response?.status === "SUCCESS") {
      if (action === "availabilty") {
        dispatch(
          updateSingleProductStatus({
            avaliabilty_status: !singleProductDetails?.avaliabiltyStatus,
          } as any)
        );
        handleStockToast();
      } else {
        dispatch(
          updateSingleProductStatus({
            is_deactivated: !singleProductDetails?.isDeactivated,
          } as any)
        );
        handleActivateToast();
      }
    }
    // }
  };

  // useEffect(() => {
  //   // updateProductStatus();
  // }, [updateProductActionData]);

  const handleActivateToast = () => {
    if (singleProductDetails?.isDeactivated) {
      setToastData({
        message: "Product has been activated successfully.",
        status: "active",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
    } else {
      setToastData({
        message: "Product has been deactivated successfully.",
        status: "deactive",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
    }
  };

  const handleStockToast = () => {
    if (singleProductDetails?.avaliabiltyStatus) {
      setToastData({
        message: "Product has been set to Out of Stock",
        status: "outOfStock",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    } else {
      setToastData({
        message: "Product has been set to In Stock",
        status: "inStock",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };
  if (!singleProductDetails?.title) {
    // dispatch(updateIsLoading(true));
  } else {
    // dispatch(updateIsLoading(false));
  }

  const handleSelectProduct = (id: string) => {
    const currPathArr = window.location.pathname?.split("/");
    currPathArr.pop();
    // getProductDetailsData(id);
    navigate(`${currPathArr.join("/")}/${id}`, {
      state: { ...(location.state || {}), masterProduct: false },
    });
  };

  const handleShowPopper = () => {
    setOpen((prev: any) => !prev);
  };
  const isProposedProductRejected = !singleProductDetails?.masterProductId;
  const [showShare, setShowShare] = useState(false);
  const shareProduct = () => {
    setShowShare(true);
  };

  return (
    <>
      <div className={styles.app}>
        <div className={styles.outerContainer}>
          <div className={styles.editButtonContainer}>
            <div className={styles.breadCrumbs}>
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div
              className={`${styles.hideEdit} ${
                (isAdmin &&
                  singleProductDetails?.status &&
                  singleProductDetails?.status !== "published") ||
                window.location.pathname.includes(
                  "/admin/manage-catalogue/product-details/"
                ) ||
                (!isAdmin &&
                  window.location.pathname.includes(
                    "/supplier/catalogue/product-details"
                  ))
                  ? styles.showEdit
                  : null
              }`}
            >
              {window?.location?.pathname?.includes("admin/products-approval")
                ? !isProposedProductRejected && (
                    <Button
                      label="Approve/Reject"
                      width="120px"
                      handleClick={
                        handleEdit
                        // location?.state?.path === routeConfig.adminProductApproval
                        //   ? handleEdit
                        //   : () => {}
                      }
                    />
                  )
                : !isOfflineProduct && (
                    <Button
                      label="Edit details"
                      width="120px"
                      handleClick={
                        handleEdit
                        // location?.state?.path === routeConfig.adminProductApproval
                        //   ? handleEdit
                        //   : () => {}
                      }
                    />
                  )}
            </div>
          </div>
          <div className={styles.container}>
            {singleProductDetails ? (
              <div className={styles.productDetailsContainer}>
                <div className={styles.imagePreview}>
                  {/* Image preview component */}
                  <ProductCarouselZoom
                    imageData={productImages && productImages}
                  />
                </div>
                <div className={styles.productDescription}>
                  <div className={styles.productTitle}>
                    <h1
                      className={styles.productName}
                      title={singleProductDetails?.title}
                    >
                      {singleProductDetails && singleProductDetails?.title}
                    </h1>
                    <div className={styles.productSubTitle}>
                      <p className={styles.subTitle}>
                        {singleProductDetails &&
                          singleProductDetails?.subCategory?.name}
                      </p>
                      <p className={styles.subTitle}>|</p>
                      <p className={styles.subTitle__italic}>
                        {singleProductDetails &&
                          singleProductDetails?.category?.name}
                      </p>
                    </div>
                  </div>
                  <div>
                    {attributesList.length > 0 && (
                      <div className={styles.attributesWrapper}>
                        {attributesList
                          .slice(0, attributesDisplayCount)
                          .map((attribute: any) => {
                            return (
                              attribute && (
                                <Chip
                                  size="small"
                                  label={attribute}
                                  variant="outlined"
                                  className={styles.attributesChip}
                                />
                              )
                            );
                          })}
                        {attributesList.length > attributesDisplayCount && (
                          <div ref={btnRef}>
                            <Button
                              link
                              label={`${
                                attributesList.length - attributesDisplayCount
                              } more`}
                              handleClick={handleShowPopper}
                            />
                          </div>
                        )}
                        {open && (
                          <PopperComponent
                            ref={btnRef.current}
                            arrowClass={styles.popperArrow}
                            paperProps={{ elevation: 4 }}
                            handleClickAwayFromProp={() => {
                              setOpen(false);
                            }}
                            popperArrowStyles={{
                              "&.popperArrow": {
                                zIndex: "-1",
                                "&::before": {
                                  "box-shadow":
                                    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
                                },
                              },
                            }}
                          >
                            <div className={styles.moreAttributesWrapper}>
                              {attributesList
                                .slice(attributesDisplayCount)
                                .map((attribute: any) => {
                                  return (
                                    attribute && (
                                      <Chip
                                        size="small"
                                        label={attribute}
                                        variant="outlined"
                                      />
                                    )
                                  );
                                })}
                            </div>
                          </PopperComponent>
                        )}
                      </div>
                    )}
                  </div>

                  {(isAdmin &&
                    singleProductDetails &&
                    singleProductDetails?.type === "online") ||
                  (!window.location.href.includes("/catalogue/share-product") &&
                    !isAdmin &&
                    singleProductDetails?.status === "published") ? (
                    <div className={styles.buttonContainer}>
                      <div className={styles.line}></div>
                      {singleProductDetails &&
                        singleProductDetails?.status !== "rejected" && (
                          <div className={styles.linkButtons}>
                            <Button
                              link
                              label={
                                singleProductDetails?.isDeactivated
                                  ? "Activate product"
                                  : "Deactivate product"
                              }
                              handleClick={updateProductStatus}
                            />
                            <Button
                              link
                              label={
                                singleProductDetails?.avaliabiltyStatus
                                  ? "Mark Out of stock"
                                  : "Mark in stock"
                              }
                              handleClick={() => {
                                updateProductStatus("availabilty");
                              }}
                            />
                          </div>
                        )}
                    </div>
                  ) : null}

                  <div className={styles.productQuantityGrid}>
                    {singleProductDetails &&
                      singleProductDetails?.variants &&
                      singleProductDetails?.variants?.map((variant: any) => (
                        <ProductQuantityPicker
                          varaint={variant}
                          quantity={`${variant?.metadata?.secondary_ordering_option_value} ${variant?.metadata?.secondary_ordering_option_label}`}
                          discount={variant?.metadata?.offer}
                          moq={variant?.min_quantity}
                          isSupplier={true}
                        />
                      ))}
                  </div>
                  <div className={styles.productDetails}>
                    <ProductEllipsisDescription
                      title={constants?.productDetails?.supplierDetails?.title}
                      details={
                        singleProductDetails?.description == null ||
                        singleProductDetails?.description == undefined ||
                        singleProductDetails?.description == ""
                          ? "-"
                          : singleProductDetails?.description
                              ?.charAt(0)
                              .toUpperCase() +
                            singleProductDetails?.description?.slice(1)
                      }
                      showMore={true}
                    />
                  </div>

                  <div className={styles.productInformationGrid}>
                    {productDetails?.accordionItems?.map((accordionItem) => (
                      <ProductAccordionItem
                        key={`Item-${accordionItem?.productKey}`}
                        productKey={accordionItem?.productKey}
                        productDescription={accordionItem?.productDescription}
                      />
                    ))}
                  </div>
                  {singleProductDetails?.relatedProducts?.supplierProducts
                    .length > 0 && (
                    <>
                      <div className={styles.horizontalLine}></div>

                      <RelatedProducts
                        relatedProducts={
                          singleProductDetails?.relatedProducts
                            ?.supplierProducts
                        }
                        handleSelectProduct={handleSelectProduct}
                      />
                    </>
                  )}
                </div>
                <div className={styles.shareIcon} onClick={shareProduct}>
                  <img src={shareIcon} alt="share" />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={successToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
        {showShare && (
          <PopupView
            heading={"Share"}
            childComponent={() => ShareAProduct({ ...metadata })}
            width={586}
            handlePopup={() => {
              setShowShare(false);
            }}
          ></PopupView>
        )}
      </div>
    </>
  );
};

export default SupplierProductDetailsPage;
