import { IVideoCard } from "../../../models/IVideoCard";
import styles from "./VideoCard.module.scss";
import pause from "../../../assets/images/pause.svg";
import hoverPause from "../../../assets/images/hoverPause.svg";
import leftArrow from "../../../assets/images/leftArrow.svg";
import { useState } from "react";

export default function VideoCard(props: IVideoCard) {
  const [displayVideo, setdisplayVideo] = useState(false);
  const [pauseIcon, setPauseIcon] = useState(pause);
  function openVideo() {
    setdisplayVideo(true);
  }
  function closeVideo() {
    setdisplayVideo(false);
  }
  function changeIconOnMouseEnter() {
    setPauseIcon(hoverPause);
  }
  function changeIconOnMouseLeave() {
    setPauseIcon(pause);
  }
  return (
    <>
      <div
        className={styles.videoCard}
        onMouseEnter={changeIconOnMouseEnter}
        onMouseLeave={changeIconOnMouseLeave}
        onClick={openVideo}
      >
        <div className={styles.videoImage}>
          <img src={props.displayImg} alt="videoImage" />
        </div>
        <div className={styles.videoDetails}>
          <div className={styles.videoTitle}>{props.videoTitle}</div>
          <div className={styles.videoDuration}>
            <div className={styles.pauseIcon}>
              <img src={pauseIcon} alt="pause" />
            </div>
            <div className={styles.duration}>{props.videoDuration}</div>
          </div>
        </div>
      </div>
      {displayVideo && (
        <div className={styles.overlay}>
          <div className={styles.videoHeader}>
            <div className={styles.leftArrow} onClick={closeVideo}>
              <img src={leftArrow} alt="left arrow"></img>
            </div>
            <div className={styles.heading}>{props.videoTitle}</div>
          </div>
          <div className={styles.video}>
            <video width="100%" height="100%" controls autoPlay={true}>
              <source src={props.videoSource} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  );
}