import AE from "../../assets/images/countryFlags/AE.svg";
import BH from "../../assets/images/countryFlags/BH.svg";
import IND from "../../assets/images/countryFlags/IND.svg";
import IQ from "../../assets/images/countryFlags/IQ.svg";
import IR from "../../assets/images/countryFlags/IR.svg";
import JO from "../../assets/images/countryFlags/JO.svg";
import KW from "../../assets/images/countryFlags/KW.svg";
import LB from "../../assets/images/countryFlags/LB.svg";
import OM from "../../assets/images/countryFlags/OM.svg";
import QA from "../../assets/images/countryFlags/QA.svg";
import SA from "../../assets/images/countryFlags/SA.svg";
import SY from "../../assets/images/countryFlags/SY.svg";
import YE from "../../assets/images/countryFlags/YE.svg";
import miniTour from "../../assets/images/buyerOnBoardingDashboardIcons/minitour.svg";
import hoverMiniTour from "../../assets/images/buyerOnBoardingDashboardIcons/hover-minitour.svg";
import setupBusiness from "../../assets/images/buyerOnBoardingDashboardIcons/setup-business.svg";
import hoverSetupBusiness from "../../assets/images/buyerOnBoardingDashboardIcons/hover-setup-business.svg";
import addSuppliers from "../../assets/images/buyerOnBoardingDashboardIcons/add-suppliers.png";
import hoverAddSuppliers from "../../assets/images/buyerOnBoardingDashboardIcons/hover-add-suppliers.svg";
import marketplace from "../../assets/images/buyerOnBoardingDashboardIcons/marketplace.svg";
import hoverMarketplace from "../../assets/images/buyerOnBoardingDashboardIcons/hover-marketplace.svg";
import verifyMobileNo from "../../assets/images/buyerOnBoardingDashboardIcons/verify-mobile.svg";
import hoverVerifyMobileNo from "../../assets/images/buyerOnBoardingDashboardIcons/hover-verify-no.svg";
import setupInventory from "../../assets/images/buyerOnBoardingDashboardIcons/setup-inventory.svg";
import hoverSetupInventory from "../../assets/images/buyerOnBoardingDashboardIcons/hover-setup-inventory.svg";
import videoImg1 from "../../assets/images/staticVideoListImages/video-img1.svg";
import videoImg2 from "../../assets/images/staticVideoListImages/video-img2.svg";
import videoImg3 from "../../assets/images/staticVideoListImages/video-img3.svg";
import { IVideoCard } from "../../models/IVideoCard";
import { IOnboardingDashboardCard } from "../../models/IOnboardingDashboardCard";
import parsleyImage from "../../assets/images/Parsley.jpeg";
import delayedDeliveriesIcon from "../../assets/images/Delayed_DeliveriesKPI.svg";
import overdueAmountIcon from "../../assets/images/overdue_Amount_KPI.svg";
import dueTodayIcon from "../../assets/images/due_Today_KPI.svg";
import onTimeDeliverIcon from "../../assets/images/Ontime_Delivery_KPI.svg";
import outstandingAmountIcon from "../../assets/images/outstanding_Amount_KPI.svg";
import plannedForTodayIcon from "../../assets/images/Planned_for_today_KPI.svg";
import SatisfactionIcon from "../../assets/images/satisfactionRateKPI.svg";
import qualityRateIcon from "../../assets/images/QuallityRate.svg";
import delayedDeliverTruckIcon from "../../assets/images/Delayed_deliveries_truckKPI.svg";
import confirmedOrderIcon from "../../assets/images/Confirmed_orderKPI.svg";
import productImageDummy from "../../assets/images/product-image.png";
export const constants = {
  foodBrands: [
    { id: 1, label: "Fresho", value: "Fresho" },
    { id: 2, label: "HealthyHarvest", value: "HealthyHarvest" },
    { id: 3, label: "FruitFusion", value: "FruitFusion" },
    { id: 4, label: "Vineyard Select", value: "Vineyard Select" },
    { id: 5, label: "DenimStyle", value: "DenimStyle" },
  ],
  tempUnitsData: [
    {
      name: "Cartoon",
      label: "Cartoon",
      value: "Cartoon",
    },
    {
      name: "Kg",
      label: "Kg",
      value: "Kg",
    },
    {
      name: "Gram",
      label: "Gram",
      value: "Gram",
    },
  ],
  tempUnitsData2: [
    {
      name: "Pieces",
      label: "Pieces",
      value: "Pieces",
    },
    {
      name: "Pieces1",
      label: "Pieces1",
      value: "Pieces1",
    },
  ],
  tempCategory: [
    {
      name: "Fruits & Vegetables",
      value: "fruitsandvegetables",
      label: "Fruits & Vegetables",
      subcategories: [
        {
          name: "Fruits",
          value: "fruits",
          label: "Fruits",
          productSection: [
            {
              name: "Fresh Fruits",
              value: "freshfruits",
              label: "Fresh Fruits",
            },
            {
              name: "Organic Fruits",
              value: "organicfruits",
              label: "Organic Fruits",
            },
          ],
        },
        {
          name: "Vegetables",
          value: "vegetables",
          label: "Vegetables",
          productSection: [
            {
              name: "Leafy Vegetables",
              value: "leafyvegetables",
              label: "Leafy Vegetables",
            },
            {
              name: "Root Vegetables",
              value: "rootvegetables",
              label: "Root Vegetables",
            },
          ],
        },
      ],
    },
    {
      name: "Snacks",
      value: "snacks",
      label: "Snacks",
      subcategories: [
        {
          name: "Chips",
          value: "chips",
          label: "Chips",
          productSection: [
            {
              name: "Potato Chips",
              value: "potatochips",
              label: "Potato Chips",
            },
            {
              name: "Tortilla Chips",
              value: "tortillachips",
              label: "Tortilla Chips",
            },
          ],
        },
        {
          name: "Cookies",
          value: "cookies",
          label: "Cookies",
          productSection: [
            {
              name: "Chocolate Cookies",
              value: "chocolatecookies",
              label: "Chocolate Cookies",
            },
            {
              name: "Oatmeal Cookies",
              value: "oatmealcookies",
              label: "Oatmeal Cookies",
            },
          ],
        },
      ],
    },
  ],
  itemListTemp: [
    {
      name: "India",
      value: "+91",
      label: "+91",
      icon: IND,
      validation: /^[6-9]\d{9}$/,
    },
    {
      name: "United Arab Emirates",
      value: "+971",
      label: "+971",
      icon: AE,
      validation: /^(?:00971|\+971|0)?(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/,
    },
    {
      name: "Saudi Arabia",
      value: " +966",
      label: " +966",
      icon: SA,
      validation: /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
    },
    {
      name: "Iran",
      value: "+98",
      label: "+98",
      icon: IR,
      validation: /^((\+98|0)9\d{9})$/gm,
    },
    {
      name: "Iraq",
      value: "+964",
      label: "+964",
      icon: IQ,
      validation: /^(((?:\+|00)964)|(0)*)7\d{9}$/gm,
    },
    {
      name: "Syria",
      value: "+963",
      label: "+963",
      icon: SY,
      validation: /^(93|94|95|96|98|99)([0-9]{7})$/gm,
    },
    {
      name: "Yemen",
      value: "+967",
      label: "+967",
      icon: YE,
      validation: /^(((\+|00)9677|0?7)[01378]\d{7}|((\+|00)967|0)[1-7]\d{6})$/,
    },
    {
      name: "Jordan",
      value: "+962",
      label: "+962",
      icon: JO,
      validation: /^[90][0-9]{2}[12][0-9]{2}[0-9]{5}$/,
    },
    {
      name: "Kuwait",
      value: "+965",
      label: "+965",
      icon: KW,
      validation: /^[569]\d{7}$/,
    },
    {
      name: "Lebanon",
      value: "+961",
      label: "+961",
      icon: LB,
      validation: /^((7(0|1|6))|(0)3)[0-9]{6}$/gm,
    },
    {
      name: "Bahrain",
      value: "+973",
      label: "+973",
      icon: BH,
      validation:
        /^(31|322|33|340|341|343|344|345|353|355|36|377|383|384|388|39|663|666|669)[0-9]{6}$/gm,
    },
    {
      name: "Oman",
      value: "+968",
      label: "+968",
      icon: OM,
      validation: /^((\+|00)?968)?[279]\d{7}$/gm,
    },
    {
      name: "Qatar",
      value: "+974",
      label: "+974",
      icon: QA,
      validation: /^(33|55|66|77)\d{8}$/gm,
    },
  ],
  cityListTemp: [
    {
      label: "Hyderabad",
      value: "Hyderabad",
    },
    {
      label: "Secundrabad",
      value: "Secundrabad",
    },
    {
      label: "Saudi",
      value: "Saudi",
    },
    {
      label: "test city 1",
      value: "test city 1",
    },
    {
      label: "test city 2",
      value: "test city 2",
    },
  ],
  onboardingDashboard: {
    welcomeMsg: "Hi Aadila, Welcome aboard",
    howTo: "How to",
    viewAll: "View all",
    blogsAndArticles: "Blogs & articles",
  },
  blogsAndArticles: {
    by: "by",
    readMore: "Read more",
  },
  BusinessSetupForm: {
    hello: "hello",
    welcome: "Welcome to Watermelon Family.",
    welcomeContent:
      "We are eager to help you manage your procurement in an easy way and help you automate them.",
    supplierWelcomeContent:
      "Let us help you unlock new business opportunities and automate your processes.",
    businessSetup: "Business Setup",
    setupContent: "Let's setup all your business details",
    address: "Business Address",
    adressContent: "Let us know your business address",
    skipButton: "Skip",
    addOutletHeader: "Add outlet",
    editOutletHeader: "Edit outlet",
    addoutletButton: "Add outlet",
    submitButton: "Submit",
    updateButton: "Update",
    cancelButton: "Cancel",
    logoLabel: "Your logo (optional)",
    signedContractLabel: "Signed contract with Watermelon",
    paymentLabel: "Which payment method for the transactions would you prefer?",
    cod: "Cash on delivery",
    onlinePayment: "Online payment",
    onlineAndCod: " Both online & COD",
    emiratesOptions: [
      {
        label: "Abu Dhabi",
        value: "Abu Dhabi",
      },
      {
        label: "Ajman",
        value: "Ajman",
      },
      {
        label: "Dubai",
        value: "Dubai",
      },
      {
        label: "Fujairah",
        value: "Fujairah",
      },
      {
        label: "Ras Al Khaimah",
        value: "Ras Al Khaimah",
      },
      {
        label: "Sharjah",
        value: "Sharjah",
      },
      {
        label: "Umm Al Quwain",
        value: "Umm Al Quwain",
      },
    ],
    countryList: [
      {
        name: "India",
        value: "India",
        label: "India",
        icon: IND,
      },
      {
        name: "United Arab Emirates",
        value: "United Arab Emirates",
        label: "United Arab Emirates",
        icon: AE,
      },
      {
        name: "Saudi Arabia",
        value: "Saudi Arabia",
        label: "Saudi Arabia",
        icon: SA,
      },
      {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: IR,
      },
      {
        name: "Iraq",
        value: "Iraq",
        label: "Iraq",
        icon: IQ,
      },
      {
        name: "Syria",
        value: "Syria",
        label: "Syria",
        icon: SY,
      },
      {
        name: "Yemen",
        value: "Yemen",
        label: "Yemen",
        icon: YE,
      },
      {
        name: "Jordan",
        value: "Jordan",
        label: "Jordan",
        icon: JO,
      },
      {
        name: "Kuwait",
        value: "Kuwait",
        label: "Kuwait",
        icon: KW,
      },
      {
        name: "Lebanon",
        value: "Lebanon",
        label: "Lebanon",
        icon: LB,
      },
      {
        name: "Bahrain",
        value: "Bahrain",
        label: "Bahrain",
        icon: BH,
      },
      {
        name: "Oman",
        value: "Oman",
        label: "Oman",
        icon: OM,
      },
      {
        name: "Qatar",
        value: "Qatar",
        label: "Qatar",
        icon: QA,
      },
    ],
  },
  formaValidations: {
    EMAIL_REGEX:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD_REGEX:
      /^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*[\d])+)(?=(.*[\W])+)(?!.*\s).{8,18}$/,

    VAT_REGEX: /^$|^[a-zA-Z0-9]+$/,
  },
  ordersListing: {
    PAID: "Paid",
    UNPAID: "Unpaid",
    DELIVERED: "Delivered",
    RETURNED: "Returned",
    MODIFIED: "Modified",
    CANCELLED: "Cancelled",
    ORDER_ID: "Order ID",
    PLACED_ON: "Placed on",
    BUYER_NAME: "Buyer name",
    ORDER_VALUE: "Order value",
    ACTUAL_AMOUNT: "Actual amount",
    ORDER_ITEMS: "Order items",
    YET_TO_CONFIRM: "Yet to Confirm",
    PROCESSING: "Processing",
    SHIPPED: "Shipped",
    COMPLETED: "Completed",
    READY_TO_SHIP: "Ready to ship",
    MODIFIEDORDER: "Modified Orders",
    ACCEPT: "Accept",
    REJECT: "Reject",
    orderQuantityDropdown: [
      {
        label: "Less than",
        value: "Less than",
      },
      {
        label: "Greater than",
        value: "Greater than",
      },
    ],
    SORT_BY: "Sort by",
    WHATS_NEW: "What's new",
    SORT_LOW_HIGH: "Sort by amount : Low to high",
    SORT_HIGH_LOW: "Sort by amount : High to low",
    migratedOrders: "Migrated orders",
  },
  productListing: {
    ALL: "All",
    ACTIVE: "Active",
    PENDING: "Pending",
    REJECTED: "Rejected",
    APPROVED: "Approved",
    PROPOSED: "Proposed",
    IN_REVIEW: "In review",
    VIEW_PROD_DETAILS: "View product details",
    OUT_OF_STOCK: "Mark out of stock",
    IN_STOCK: "Mark in stock",
    DEACTIVATE_PROD: "Deactivate product",
    ACTIVATE_PROD: "Activate product",
    REMOVE_PROD: "Remove product",
    MANAGE_CATALOGUE: "Manage catalogue",
    SEARCHBAR_PLACEHOLDER: "Search for products, customers, orders, and more!",
    SORT_A_Z: "Sort by product name : A to Z",
    SORT_Z_A: "Sort by product name : Z to A",
    ADD_NEW_SUPPLIER: "Add new supplier",
    ADD_NEW: "Add new",
    HELP: "Still need help? Please contact Watermelon support",
    HELP_CONTACT: "support@watermelon.market  |  +971-45584208",
    DRAFT: "draft",
    DELETE_PRODUCT: "Delete product",
  },

  contactSupport: {
    CONTACT_SUPPORT_PHONE: "+971-45584208",
    CONTACT_SUPPORT_NUMBER: "+97145584208",
    CONTACT_SUPPORT_MAIL: "support@watermelon.market",
  },
  onboardingDashboardCard: {
    REVIEW: "Review",
    SUCCESS: "Success",
    ERROR: "Error",
    DEFAULT: "Default",
    SETUP_ACCOUNT: "setup_account",
    ADD_SUPPLIERS: "add_suppliers",
    ADD_PRODUCTS: "add_products",
    VERIFY_PHN: "verify_phn",
    SETUP_INVENTORY: "setup_inventory",
    EXPLORE_MARKETPLACE: "Explore_marketplace",
    TOUR: "tour",
    SETUP_BUSINESS: "Setup business",
  },

  productDetails: {
    supplierDetails: {
      title: "Product description",
      details:
        'Quench your thirst for freshness with Mango! This juicy delight bursts with sweet flavour and hydrating goodness. Bite into its vibrant red flesh and experience the perfect summer treat. Refreshing, revitalising, and oh-so-delicious, Watermelon is nature\'s way of saying, "Enjoy the sweet side of life!"',
      moreDetails:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut delectus aperiam, mollitia qui sint fuga ipsam deserunt eaque excepturi, suscipit accusamus officia temporibus a incidunt debitis numquam explicabo omnis? Enim laborum sunt autem recusandae totam laboriosam atque ad. Id, nobis labore totam vel dolores commodi hic facilis perspiciatis nisi ducimus tempora ad autem ratione vitae iste asperiores corrupti cum sint consectetur velit deleniti tenetur officia voluptatum. Alias quod cupiditate minus harum pariatur? Incidunt ab adipisci, ullam quos voluptates quo provident ea similique facilis ipsam aspernatur tenetur sunt. Fugiat molestias accusamus quos, voluptates impedit maiores assumenda ex ad quis expedita quaerat!",
    },
    buyerDetails: {
      title: "Know your product",
      details:
        "Made with Original American Recipe - Pintola Peanut Butter is made with the original American recipe that has been enjoyed by generations of peanut butter lovers. We use only the finest quality roasted peanuts, carefully selected and ground into a smooth and creamy texture that is perfect for spreading on toast or as a dip for fruits and vegetables.",
      moreDetails:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut delectus aperiam, mollitia qui sint fuga ipsam deserunt eaque excepturi, suscipit accusamus officia temporibus a incidunt debitis numquam explicabo omnis? Enim laborum sunt autem recusandae totam laboriosam atque ad. Id, nobis labore totam vel dolores commodi hic facilis perspiciatis nisi ducimus tempora ad autem ratione vitae iste asperiores corrupti cum sint consectetur velit deleniti tenetur officia voluptatum. Alias quod cupiditate minus harum pariatur? Incidunt ab adipisci, ullam quos voluptates quo provident ea similique facilis ipsam aspernatur tenetur sunt. Fugiat molestias accusamus quos, voluptates impedit maiores assumenda ex ad quis expedita quaerat!",
    },
    onboardingDashboardCard: {
      REVIEW: "Review",
      SUCCESS: "Success",
      ERROR: "Error",
      DEFAULT: "Default",
      SETUP_ACCOUNT: "setup_account",
      ADD_SUPPLIERS: "add_suppliers",
      ADD_PRODUCTS: "add_products",
      VERIFY_PHN: "verify_phn",
      SETUP_INVENTORY: "setup_inventory",
      EXPLORE_MARKETPLACE: "Explore_marketplace",
      TOUR: "tour",
    },
  },
  BuyerBusinessApprovalHeader: {
    businessName: "Business Name",
    primaryContactName: "Primary contact Name",
    email: "Email",
    address: "Address",
  },
  BusinessApprovalForm: {
    heading: "Buyer Approval",
    edit: "Edit",
    approval: "Approve",
    pending: "Pending",
    reject: "Reject",
    cancel: "Cancel",
  },
  buyersListHeaders: {
    businessName: "Business name",
    email: "Email",
    primaryContactName: "Contact name",
    phoneNO: "Phone number",
    address: "Address",
    userType: "User type",
    outlets: "Outlets count",
    warehouses: "Warehouses count",
    firstName: "First name",
    lastName: "Last name",
    admin: "Admin",
  },
  suppliersListHeaders: {
    businessName: "Business name",
    email: "Email",
    primaryContactName: "Contact name",
    phoneNO: "Phone number",
    address: "Address",
  },
  navLinksId: {
    dashboard: "dashboard",
    orders: "order",
    invoices: "invoices",
    catalogue: "catalogue",
    notification: "notification",
    preferences: "preferences",
    account_details: "account-details",
    my_profile: "my-profile",
    refer_a_friend: "refer-a-friend",
    help_support: "help-support",
    logout: "logout",
    marketplace: "marketplace",
    suppliers: "suppliers",
    notifications: "notifications",
    more: "more",
    view_profile: "view-profile",
    report: "report",
    special_pricing: "special-pricing",
  },
  accountDetails: {
    businessName: "Business name",
    primaryContactName: "Primary contact name",
    email_id: "Email ID",
    contact_no: "Contact number",
    IBAN: "IBAN",
    MOV: "MOV",
    trade_license: "Trade license",
    watermelon_signed_contract: "Watermelon signed contract",
    pest_control_certification: "Pest control certification",
    signed_contract: "Signed contract",
  },
  loadingScreen: {
    para1: "Oops, you saw us under work in progress",
    para2:
      "Currently, this page is not implemented. It will take some time to make things right.",
    buttonPlaceholder: "Back to dashboard",
  },
  confirmContactNo: {
    heading: "Confirm contact number",
    cancel: "Cancel",
    confirm: "Confirm",
    getOtp: "Get OTP",
    label: "Phone number",
    placeHolder: "Enter your phone number",
    otp: "OTP",
    otpSentDesc: "OTP has been sent on your mobile number",
    resendOtp: "Resend OTP",
    resendOtpDesc: "Didn’t received the OTP?",
    getNewOtp: "Get new OTP",
    getNewOtpDesc: "Please try again.",
    vericationSuccess: "Verification Successful!",
    verificationFailed: "Verification Unsuccessful",
    invalidNo: "Invalid Phone Number",
    emptyField: "Enter valid number",
    validDropdown: "Select valid one",
  },
  adminLevelFormLabels: {
    welcomenMsg: "Add Admin",
    firstName: "First name",
    lastName: "Last name",
    emailId: "Email ID",
    address: "Address",
    selectCountry: "Select country",
    selectCity: "Select city",
    mobileNo: "Mobile No",
    password: "Password",
    confirmPassword: "Confirm password",
    cancel: "Cancel",
    submit: "Submit",
    city: "City",
    area: "Area",
  },
  rolesOptions: [
    {
      name: "Admin",
      checked: false,
    },
    {
      name: "User",
      checked: false,
    },
    {
      name: "Buyer",
      checked: false,
    },
    {
      name: "Supplier",
      checked: false,
    },
    {
      name: "Role1",
      checked: false,
    },
    {
      name: "Role1",
      checked: false,
    },
    {
      name: "Role1",
      checked: false,
    },
  ],
  manageSuppliers: {
    heading: "Manage suppliers",
    subHeading: "You can add the suppliers individually or in bulk.",
    cardOneSubHeading: "Add supplier’s basic details one by one",
  },
  manageCatalogue: {
    email: "support@watermelon.market",
    phoneNo: "+971-45584208",
    help: " Still need help? Please contact Watermelon support",
    template: "the template to upload the product details",
    download: "Download",
    supplier: "Add product’s basic details one by one",
    bulkHeading: "  You can add the products individually or in bulk.",
    manage: "Manage Catalogue",
    modifyBulkProduct: "Modify bulk product",
  },
  addIndividualProduct: {
    optionalHeading:
      " Choose any five of the following. It will be visible on the product card",
    optional: "Pick any five of the certifications for this product.",
  },
  assignRoleHeader: "Assign a role for the user",
  linkProductHeader: "Link Product",
  addProduct: "Add product",

  userTypesListBuyer: [
    {
      name: " Admin",
      label: "Admin",
      value: "Admin",
      payloadValue: "buyer-admin",
      role_key: "buyer-admin",
    },
    {
      name: "Manager",
      label: "Manager",
      value: "Manager",
      payloadValue: "buyer-manager",
      role_key: "buyer-manager",
    },
    {
      name: "Staff",
      label: "Staff",
      value: "Staff",
      payloadValue: "buyer-staff",
      role_key: "buyer-staff",
    },
  ],
  userTypesListSupplier: [
    {
      name: " Admin",
      label: "Admin",
      value: "Admin",
      payloadValue: "supplier-admin",
    },
    {
      name: "Manager",
      label: "Manager",
      value: "Manager",
      payloadValue: "supplier-manager",
    },
    {
      name: "Staff",
      label: "Staff",
      value: "Staff",
      payloadValue: "supplier-staff",
    },
  ],
  outletList: [
    {
      name: "Outlet1",
      label: "Outlet1",
      value: "Outlet1",
      outletAddress: {
        address: "Sample Outlet Addresss1",
        emirates: { label: "Dubai", value: "Dubai" },
        area: "Sample Outlet Area1",
        country: {
          name: "Saudi Arabia",
          value: "Saudi Arabia",
          label: "Saudi Arabia",
          icon: SA,
        },
      },
    },
    {
      name: "Outlet2",
      label: "Outlet2",
      value: "Outlet2",
      outletAddress: {
        address: "Sample Outlet Addresss2",
        emirates: { label: "Dubai", value: "Dubai" },
        area: "Sample Outlet Area1",
        country: {
          name: "Saudi Arabia",
          value: "Saudi Arabia",
          label: "Saudi Arabia",
          icon: SA,
        },
      },
    },
    {
      name: "Outlet3",
      label: "Outlet3",
      value: "Outlet3",
      outletAddress: {
        address: "Sample Outlet Addresss3",
        emirates: { label: "Dubai", value: "Dubai" },
        area: "Sample Outlet Area3",
        country: {
          name: "Saudi Arabia",
          value: "Saudi Arabia",
          label: "Saudi Arabia",
          icon: SA,
        },
      },
    },
    {
      name: "Outlet4",
      label: "Outlet4",
      value: "Outlet4",
      outletAddress: {
        address: "Sample Outlet Addresss4",
        emirates: { label: "Dubai", value: "Dubai" },
        area: "Sample Outlet Area4",
        country: {
          name: "Saudi Arabia",
          value: "Saudi Arabia",
          label: "Saudi Arabia",
          icon: SA,
        },
      },
    },
  ],
  outletAddress: "Outlet Details",
  outletAddressContent: "Let us know your outlet details",
  emiratesOptionsLatest: [
    {
      name: "Abu Dhabi",
      label: "Abu Dhabi",
      value: "Abu Dhabi",
    },
    {
      name: "Ajman",
      label: "Ajman",
      value: "Ajman",
    },
    {
      name: "Dubai",
      label: "Dubai",
      value: "Dubai",
    },
    {
      name: "Fujairah",
      label: "Fujairah",
      value: "Fujairah",
    },
    {
      name: "Ras Al Khaimah",
      label: "Ras Al Khaimah",
      value: "Ras Al Khaimah",
    },
    {
      name: "Sharjah",
      label: "Sharjah",
      value: "Sharjah",
    },
    {
      name: "Umm Al Quwain",
      label: "Umm Al Quwain",
      value: "Umm Al Quwain",
    },
  ],
  defaultToastData: {
    message: "",
    status: "",
  },
  paginationLimitPerPage: 10,
  REVIEW_MESSAGE1: "Which Items do you regret ordering ?",
  REVIEW_MESSAGE2: "Which Items could have been better ?",
  REVIEW_MESSAGE3: " Which Items did you enjoy ?",
  getStarted: "Get started",
  invoiceFilters: {
    estimatedAmountDropdown: [
      {
        name: "Less than",
        value: "less",
        label: "Less than",
        display_name: "Less than",
      },
      {
        name: "Greater than",
        value: "greater",
        label: "Greater than",
        display_name: "Greater than",
      },
      {
        name: "Equal to",
        value: "equal",
        label: "Equal to",
        display_name: "Equal to",
      },
    ],
    actualAmountDropdown: [
      {
        name: "Less than",
        value: "less",
        label: "Less than",
        display_name: "Less than",
      },
      {
        name: "Greater than",
        value: "greater",
        label: "Greater than",
        display_name: "Greater than",
      },
      {
        name: "Equal to",
        value: "equal",
        label: "Equal to",
        display_name: "Equal to",
      },
    ],
    warehouseList: [
      { name: "Warehouse1", value: "Warehouse1", label: "Warehouse1" },
      { name: "Warehouse2", value: "Warehouse2", label: "Warehouse2" },
      { name: "Warehouse3", value: "Warehouse3", label: "Warehouse3" },
    ],
    buyersList: [
      { name: "Buyer1", value: "Buyer1", label: "Buyer1" },
      { name: "Buyer2", value: "Buyer2", label: "Buyer2" },
      { name: "Buyer3", value: "Buyer3", label: "Buyer3" },
    ],
    outletList: [
      { name: "Outlet1", value: "Outlet1", label: "Outlet1" },
      { name: "Outlet2", value: "Outlet2", label: "Outlet2" },
      { name: "Outlet3", value: "Outlet3", label: "Outlet3" },
    ],
    suppliersList: [
      { name: "Supplier1", value: "Supplier1", label: "Buyer1" },
      { name: "Supplier2", value: "Supplier2", label: "Buyer2" },
      { name: "Supplier3", value: "Supplier3", label: "Buyer3" },
    ],
  },
  invoiceSort: {
    SORT_BY: "Sort by",
    WHATS_NEW: "What's new",
    SORT_LOW_HIGH: "Sort by amount : Low to high",
    SORT_HIGH_LOW: "Sort by amount : High to low",
  },
  orderFilters: {
    supplierList: [
      {
        name: "Supplier1",
        label: "Supplier1",
        value: "Supplier1",
      },
      {
        name: "Supplier2",
        label: "Supplier2",
        value: "Supplier2",
      },
      {
        name: "Supplier3",
        label: "Supplier3",
        value: "Supplier3",
      },
    ],
    buyerList: [
      {
        name: "Buyer1",
        label: "Buyer1",
        value: "Buyer1",
      },
      {
        name: "Buyer2",
        label: "Buyer2",
        value: "Buyer2",
      },
      {
        name: "Buyer3",
        label: "Buyer3",
        value: "Buyer3",
      },
    ],
    orderAmountDropdown: [
      {
        name: "Less than",
        value: "Less than",
        label: "Less than",
        display_name: "Less than",
      },
      {
        name: "Greater than",
        value: "Greater than",
        label: "Greater than",
        display_name: "Greater than",
      },
      {
        name: "Equal to",
        value: "Equal to",
        label: "Equal to",
        display_name: "Equal to",
      },
    ],
  },
  specialPricing: {
    tabsList: [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Approved",
        value: "approved",
      },
      {
        label: "Rejected",
        value: "rejected",
      },
    ],
    mockTabsData: [
      {
        id: 1,
        buyerBusinessName:
          "sample Business Name Sample Business NameSample Business NameSample Business NameSample Business Name",
        productName:
          "Tirupathi Rao Pending PendingPendingPendingPendingPending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 2,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 3,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 4,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 5,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 6,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 7,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 8,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 9,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 10,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },
      {
        id: 11,
        buyerBusinessName: "Sample Business Name",
        productName: "Pending",
        variantPrice: 200,
        consumption: 500,
        tabType: "pending",
      },

      {
        id: 12,
        buyerBusinessName: "Sample Business Name",
        productName: "Approved",
        variantPrice: 200,
        consumption: 500,
        tabType: "approved",
      },
      {
        id: 13,
        buyerBusinessName: "Sample Business Name",
        productName: "Approved",
        variantPrice: 200,
        consumption: 500,
        tabType: "approved",
      },
      {
        id: 14,
        buyerBusinessName: "Sample Business Name",
        productName: "Approved",
        variantPrice: 200,
        consumption: 500,
        tabType: "approved",
      },
      {
        id: 15,
        buyerBusinessName: "Sample Business Name",
        productName: "Rejected",
        variantPrice: 200,
        consumption: 500,
        tabType: "rejected",
      },
      {
        id: 16,
        buyerBusinessName: "Sample Business Name",
        productName: "Rejected",
        variantPrice: 200,
        consumption: 500,
        tabType: "rejected",
      },
      {
        id: 17,
        buyerBusinessName: "Sample Business Name",
        productName: "Rejected",
        variantPrice: 200,
        consumption: 500,
        tabType: "rejected",
      },
    ],
    headers: {
      column1: "Business name of the Buyer",
      column2: "Product name",
      column3: "Variant Price",
      column4: "Consumption",
    },
    buyerRequest: {
      buyer: "Buyer Business Name",
      supplier: "Supplier Business Name",
      product: "Product Name",
      variants: [
        {
          variantId: 1,
          orderingOptionValue: 2,
          selectedQuantity: 3,
          equalToUnitName: "kg",
          price: 1016,
          consumption: null,
        },
        {
          variantId: 2,
          orderingOptionValue: 2,
          selectedQuantity: 3,
          equalToUnitName: "kg",
          price: 106,
          consumption: null,
        },
        {
          variantId: 3,
          orderingOptionValue: 2,
          selectedQuantity: 3,
          equalToUnitName: "kg",
          price: 16,
          consumption: null,
        },
        {
          variantId: 4,
          orderingOptionValue: 2,
          selectedQuantity: 3,
          equalToUnitName: "kg",
          price: 10,
          consumption: null,
        },
        {
          variantId: 5,
          orderingOptionValue: 2,
          selectedQuantity: 3,
          equalToUnitName: "kg",
          price: 6,
          consumption: null,
        },
      ],
      suggestedBuyers: [
        {
          title: "Buyer1",
          id: 1,
        },
        {
          title: "Buyer2",
          id: 2,
        },
        {
          title: "Buyer3",
          id: 3,
        },
      ],
      suggestedSuppliers: [
        {
          title: "Supplier1",
          id: 1,
        },
        {
          title: "Supplier2",
          id: 2,
        },
        {
          title: "Supplier3",
          id: 3,
        },
      ],
      suggestedProducts: [
        {
          title: "Product1",
          id: 1,
        },
        {
          title: "Product2",
          id: 2,
        },
        {
          title: "Product3",
          id: 3,
        },
      ],
    },
  },
  myCart: {
    emptyCartHeader: "Empty Cart!",
    emptyCartInfoText:
      " Your cart is empty and a little lonely! Let us fill it up with delicious goodness. Head over to the marketplace to explore all the products!",
    supplierLabel: "Marketplace supplier",
    estimatedSubTotal: "Estimated subtotal",
    deliveryCharges: "Delivery charges",
    vatCharges: "VAT charges",
    estimatedTotal: "Estimated total to pay",
    selectAddress: "Select your address",
    addNewAddress: "Add new address",
    editCartContent: `Once you've confirmed the address and time slot for cash on delivery orders, you can make the payment upon delivery. For online payment orders, simply click "Pay online" to proceed to the online payment portal.`,
  },
};

export const buyerOnboardingCards: IOnboardingDashboardCard[] = [
  {
    icon: miniTour,
    iconOnHover: hoverMiniTour,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default"
    type: constants.onboardingDashboardCard.TOUR,
    onClick: () => {},
  },
  {
    icon: setupBusiness,
    iconOnHover: hoverSetupBusiness,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default", "Success", "Review", "Error"
    type: constants.onboardingDashboardCard.SETUP_BUSINESS,
    onClick: () => {},
  },
  {
    icon: addSuppliers,
    iconOnHover: hoverAddSuppliers,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default", "Success",
    type: constants.onboardingDashboardCard.ADD_SUPPLIERS,
    onClick: () => {},
  },
  {
    icon: marketplace,
    iconOnHover: hoverMarketplace,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default"
    type: constants.onboardingDashboardCard.EXPLORE_MARKETPLACE,
    onClick: () => {},
  },
  {
    icon: verifyMobileNo,
    iconOnHover: hoverVerifyMobileNo,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default", "Success"
    type: constants.onboardingDashboardCard.VERIFY_PHN,
    onClick: () => {},
  },
  {
    icon: setupInventory,
    iconOnHover: hoverSetupInventory,
    isCardSupplier: false,
    state: constants.onboardingDashboardCard.DEFAULT, // "Default", "Success"
    type: constants.onboardingDashboardCard.SETUP_INVENTORY,
    onClick: () => {},
  },
];
export const howtoSectionData: IVideoCard[] = [
  {
    id: 1,
    displayImg: videoImg1,
    videoTitle: "Start your new journey with Watermelon",
    videoDuration: "5",
    videoSource:
      "https://www.pexels.com/video/scenic-view-of-a-forest-5365208/",
  },
  {
    id: 2,
    displayImg: videoImg2,
    videoTitle: "Enhance your marketplace listings",
    videoDuration: "5",
    videoSource:
      "https://www.pexels.com/video/scenic-view-of-a-forest-5365208/",
  },
  {
    id: 3,
    displayImg: videoImg3,
    videoTitle:
      "Seamless way to manage the supply ordering in restaurants that have several branches",
    videoDuration: "5",
    videoSource:
      "https://www.pexels.com/video/scenic-view-of-a-forest-5365208/",
  },
  {
    id: 4,
    displayImg: videoImg3,
    videoTitle:
      "Seamless way to manage the supply ordering in restaurants that have several branches",
    videoDuration: "5",
    videoSource:
      "https://www.pexels.com/video/scenic-view-of-a-forest-5365208/",
  },
  {
    id: 5,
    displayImg: videoImg3,
    videoTitle:
      "Seamless way to manage the supply ordering in restaurants that have several branches",
    videoDuration: "5",
    videoSource:
      "https://www.pexels.com/video/scenic-view-of-a-forest-5365208/",
  },
];

export const productPurchasedData = [
  {
    id: 0,
    productName: "ChiPeta cheesecken",
    productImage: parsleyImage,
    sku: 60,
    purchasedNumber: 30,
    numberOfOrders: 10,
    rating: 2,
  },
  {
    id: 1,
    productName: "Milk",
    productImage: parsleyImage,
    sku: 40,
    purchasedNumber: 45,
    numberOfOrders: 12,
    rating: 2,
  },
  {
    id: 2,
    productName: "Zucchini",
    productImage: parsleyImage,
    sku: 36,
    purchasedNumber: 50,
    numberOfOrders: 18,
    rating: 3,
  },
  {
    id: 3,
    productName: "Tomato",
    productImage: parsleyImage,
    sku: 30,
    purchasedNumber: 75,
    numberOfOrders: 24,
    rating: 3,
  },
  {
    id: 4,
    productName: "Parsley",
    productImage: parsleyImage,
    sku: 25,
    purchasedNumber: 80,
    numberOfOrders: 32,
    rating: 3,
  },
];

export const topBuyerData = [
  {
    id: 0,
    buyerName: "Punjabi Kadhai",
    aed: 32543,
    buyerValue: 80,
  },
  {
    id: 1,
    buyerName: "KTR Kitchens",
    aed: 28645,
    buyerValue: 65,
  },
  {
    id: 2,
    buyerName: "Bangla Foods",
    aed: 26248,
    buyerValue: 55,
  },
  {
    id: 3,
    buyerName: "NN Sweets",
    aed: 19639,
    buyerValue: 47,
  },
  {
    id: 4,
    buyerName: "Subway",
    aed: 12726,
    buyerValue: 34,
  },
];

export const buyersummaryDetails = [
  {
    id: 0,
    name: "Planned for today",
    data: 5,
    icon: plannedForTodayIcon,
    totalData: [
      {
        id: 0,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
      {
        id: 1,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 3,
      },
      {
        id: 2,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
    ],
  },
  {
    id: 1,
    data: 2,
    name: "Delayed deliveries",
    icon: delayedDeliveriesIcon,
    totalData: [
      {
        id: 0,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
      {
        id: 2,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
    ],
  },
  {
    id: 2,
    data: 5,
    name: "On time delivery",
    icon: onTimeDeliverIcon,
    totalData: [
      {
        id: 0,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
      {
        id: 1,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
      {
        id: 2,
        orderId: "PO-123456",
        quantity: 4,
        aed: 235,
        delayedDays: 1,
      },
    ],
  },
  {
    id: 3,
    data: 1500,
    name: "Outstanding amount",
    icon: outstandingAmountIcon,
    totalData: [
      {
        id: 0,
        orderId: "PO-123456",
        businessName: "Delta Foods",
        quantity: 4,
        aed: 235,
        dueDays: 10,
      },
      {
        id: 1,
        orderId: "PO-123456",
        businessName: "Skyline suppliers",
        quantity: 4,
        aed: 235,
        dueDays: 15,
      },
      {
        id: 2,
        orderId: "PO-123456",
        businessName: "Loin dates",
        quantity: 4,
        aed: 235,
        dueDays: 15,
      },
    ],
  },
  {
    id: 4,
    data: 200,
    name: "Overdue amount",
    icon: overdueAmountIcon,
  },
  {
    id: 5,
    data: 300,
    name: "Due today",
    icon: dueTodayIcon,
  },
];

export const supplierSummaryDetails = [
  {
    id: 0,
    name: "Placed today",
    data: 5,
    icon: plannedForTodayIcon,
  },
  {
    id: 1,
    data: 2,
    name: "Confirmed",
    icon: confirmedOrderIcon,
  },
  {
    id: 2,
    data: 5,
    name: "Delayed",
    icon: delayedDeliveriesIcon,
  },
  {
    id: 3,
    name: "Delivery rate",
    data: 73,
    icon: delayedDeliverTruckIcon,
  },
  {
    id: 4,
    data: 63,
    name: "Quality rate",
    icon: qualityRateIcon,
  },
  {
    id: 5,
    data: 40,
    name: "Satisfaction rate",
    icon: SatisfactionIcon,
  },
];

export const totalTasksData = [
  {
    id: 0,
    orderId: "PO-123456",
    taskDetails: "New order PO 123456 placed by Mehfil for 11 items. ",
    timeofupdate: 5,
    unitOfTime: "min",
    taskAction: "Accept order",
  },
  {
    id: 1,
    orderId: "PO-123456",
    taskDetails: "New order PO 23473 placed by Okara for 17 items.",
    timeofupdate: 15,
    unitOfTime: "min",
    taskAction: "Accept order",
  },
  {
    id: 2,
    orderId: "PO-123456",
    taskDetails:
      "2 new products are rejected from the admin, update  product details for them.",
    timeofupdate: 1,
    unitOfTime: "hr",
    taskAction: "View catalogue",
  },
  {
    id: 3,
    orderId: "PO-123456",
    taskDetails: "Create new deals/ offers on fruits for the upcoming season.",
    timeofupdate: 7,
    unitOfTime: "hrs",
    taskAction: "View catalogue",
  },
  {
    id: 4,
    orderId: "PO-123456",
    taskDetails: "Update product prices for PO 37462 as per the contract.",
    timeofupdate: 23,
    unitOfTime: "hrs",
    taskAction: "Check order",
  },
];

export const sortOptions = [
  {
    id: 0,
    label: "What’s new",
    onClick: () => {},
  },
  {
    id: 1,
    label: "Featured",
    onClick: () => {},
  },
  {
    id: 2,
    label: "Prices Per Unit : Low to High",
    onClick: () => {},
  },
  {
    id: 3,
    label: "% Off: High to Low",
    onClick: () => {},
  },
  {
    id: 4,
    label: "% Off: Low to High",
    onClick: () => {},
  },
];
export const products = [
  {
    id: 1,
    productName: "Blue T-Shirt",
    productImage: productImageDummy,
    outOfstockStatus: false,
    liked: true,
    offerPercentage: 10,
    actualPrice: 25.99,
    offerPrice: 23.39,
    productDeliveryStatus: false,
    productQuantity: 5,
    supplierName: "Fashion Hub",
    supllierType: "online",
  },
  {
    id: 2,
    productName: "Black Jeans",
    productImage: productImageDummy,
    outOfstockStatus: true,
    liked: false,
    offerPercentage: 0,
    actualPrice: 49.99,
    offerPrice: 49.99,
    productDeliveryStatus: false,
    productQuantity: 0,
    supplierName: "Denim World",
    supllierType: "online",
  },
  {
    id: 3,
    productName: "Red Sneakers",
    productImage: productImageDummy,
    outOfstockStatus: false,
    liked: true,
    offerPercentage: 15,
    actualPrice: 79.99,
    offerPrice: 67.99,
    productDeliveryStatus: false,
    productQuantity: 3,
    supplierName: "Sporty Feet",
    supllierType: "online",
  },
  {
    id: 4,
    productName: "Green Backpack",
    productImage: productImageDummy,
    outOfstockStatus: false,
    liked: false,
    offerPercentage: 0,
    actualPrice: 39.99,
    offerPrice: 39.99,
    productDeliveryStatus: false,
    productQuantity: 0,
    supplierName: "Travel Gear",
    supllierType: "online",
  },
  {
    id: 5,
    productName: "White Dress",
    productImage: productImageDummy,
    outOfstockStatus: false,
    liked: true,
    offerPercentage: 20,
    actualPrice: 59.99,
    offerPrice: 47.99,
    productDeliveryStatus: false,
    productQuantity: 7,
    supplierName: "Elegant Fashion",
    supllierType: "online",
  },
  {
    id: 6,
    productName: "Brown Leather Wallet",
    productImage: productImageDummy,
    outOfstockStatus: false,
    liked: true,
    offerPercentage: 5,
    actualPrice: 29.99,
    offerPrice: 28.49,
    productDeliveryStatus: false,
    productQuantity: 2,
    supplierName: "Leather Emporium",
    supllierType: "online",
  },
];

export const preferencesConstants = {
  headerText: "My preferences",
  outletText: "Select your outlet",
  outletsHeader: "Outlets",
  usersHeader: "Users",
  addUser: "Add user",
  editUser: "Edit user",
  submit: "Submit",
  cancel: "Cancel",
  deleteUserHeader: "Delete User ?",
  confirmDelete: "Are you sure you want to delete this user ?",
};

export const buyerAPIEndpoints = {
  outletsList: "store/buyer/business_store/:businessId",
  editOutlet: "store/buyer/:businessId/outlet/:storeId",
  deleteOutlet: "store/buyer/:businessId/outlet/:outletId",
  addOutlet: "store/buyer/:buyerId/outlet",
  invoiceFilters: "store/invoice/buyer_filter/",
  buyerFilters: "store/invoice/supplier_filter",
  invoiceFilteredListing: "store/invoice/",
};

export const supplierPreferenceEndpoints = {
  usersList: "store/supplier/:supplier_id/customer",
  updateUser: "store/supplier/:supplier_id/customer/:customer_id",
};

export const adminManageCatalogue = {
  masterProducts: "Master products",
  supplierProducts: "Supplier products",
  offlineProducts: "Offline Products",
};

export const offlineSuppliers = {
  heading: "Offline Suppliers",
  tabsList: [
    {
      id: 0,
      label: "Buyer users",
      value: "withBuyerId",
    },
    {
      id: 1,
      label: "Admin users",
      value: "withoutBuyerId",
    },
  ],
  sortOptions: [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {},
    },
    {
      id: 1,
      label: constants.productListing.SORT_A_Z,
      onClick: () => {},
    },
    {
      id: 2,
      label: constants.productListing.SORT_Z_A,
      onClick: () => {},
    },
  ],
  listHeaders: {
    email: "Email",
    name: "Name",
    businessName: "Business name",
    address: "Address",
  },
};

export const bulkuploadEndpoints = {
  admin: "admin/master-product/bulk-upload",
  supplier: "store/product/bulk-upload/supplier/:supplier_id",
};

export const MARK_AS_DEFAULT = "Mark this as my default outlet";

export const USER_ROLES = {
  SUPPLIERADMIN: "supplier-admin",
  SUPPLIERMANAGER: "supplier-manager",
  SUPPLIERSTAFF: "supplier-staff",
  SUPERUSER: "super-user",
};

/* Images in prod server are not getting stored. It is taking 1sec to store the image. 
 When in UI image is trying to render before it is stored in minio. So, added 1.5sec wait time to render the image.
*/
export const DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO = 1500;
