import React from "react";
import cancel from "../../../assets/images/cancelToast.svg";
import styles from "./PopupView.module.scss";
import { IPopupView } from "../../../models/IPopupView";

function PopupView(props: IPopupView) {
  const style = {
    "--component-width": `${props.width}px`,
    "--component-height": `${props.height}px`,
    maxWidth: "var(--component-width)",
    width: "100%",
    minHeight: "var(--component-height)",
  };
  return (
    <>
      <div className={styles.popupOverlay}>
        <div className={styles.popupView} style={style}>
        {!props.hideHeader && <div className={styles.header}>
            <div className={styles.heading}>{props.heading}</div>
            <div className={styles.cancel} onClick={props.handlePopup}>
              <img src={cancel} alt="cancel"></img>
            </div>
          </div>}
             <div>
             <props.childComponent/>
           </div>
        </div>
      </div>
    </>
  );
}

export default PopupView;
