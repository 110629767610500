import React from "react";
import styles from "./AdminOrdersListingCardStyles.module.scss";

const MigratedOrderCard = (props: any) => {

  const { orderId, price, placedOn, buyerName, total, orderType, orderPONumber, vat} = props?.order;
  console.log(orderId, "orderId")
  return (
    <div className={`${styles.card} ${styles.hover}`} onClick={() => {}}>
      <div className={styles.cardDetails}>
        <div
          className={`${styles.flex} ${styles.flexColumn}`}
        >
          <div className={`${styles.align}`}>
            <div className={styles.orderID}>{orderPONumber}</div>
            {orderType === "Offline" && (
              <div className={styles.offlineOrder}>Offline</div>
            )}
          </div>
          <div className={styles.commonTxtStyle}>{buyerName}</div>
        </div>
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.commonTxtStyle}>{placedOn}</div>
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.commonTxtStyle}>{price}</div>
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.commonTxtStyle}>{vat}</div>
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.commonTxtStyle}>{total}</div>
      </div>
    </div>
  );
};

export default MigratedOrderCard;
