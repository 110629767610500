import { createSlice } from "@reduxjs/toolkit";

type StatePropType = {
  cataloguePageNumber: number;
  ordersPageNumber: number;
  productsApprovalPageNumber: number;
  buyerPageNumber: number;
  supplierPageNumber: number;
  manageOrdersPageNumber: number;
  admincataloguePageNumber: number;
  offlineSuppliersPageNumber: number;
  buyerApprovalPageNumber: number;
  supplierApprovalPageNumber: number;
  supplierOrderListingPageNumber: number;
  supplierInvoicesPageNumber: number;
  supplierManageCataloguePageNumber: number;
  usersPageNumber: number;
  productApprovalPageNumber: number;
  specilalPricingPageNumber: number;
  adminManageOrdersPageNumber: number;
};

type ActionPropType = {
  payload: {
    type:
      | "cataloguePageNumber"
      | "ordersPageNumber"
      | "productsApprovalPageNumber"
      | "buyerPageNumber"
      | "supplierPageNumber"
      | "manageOrdersPageNumber"
      | "offlineSuppliersPageNumber"
      | "reset"
      | "admincataloguePageNumber"
      | "buyerApprovalPageNumber"
      | "supplierApprovalPageNumber"
      | "supplierOrderListingPageNumber"
      | "supplierInvoicesPageNumber"
      | "supplierManageCataloguePageNumber"
      | "usersPageNumber"
      | "productApprovalPageNumber"
      | "specilalPricingPageNumber"
      | "adminManageOrdersPageNumber";
    index: number;
  };
};

const selectedPageState: StatePropType = {
  cataloguePageNumber: 1,
  ordersPageNumber: 1,
  buyerPageNumber: 1,
  manageOrdersPageNumber: 1,
  supplierPageNumber: 1,
  productsApprovalPageNumber: 1,
  admincataloguePageNumber: 1,
  offlineSuppliersPageNumber: 1,
  buyerApprovalPageNumber:1,
  supplierApprovalPageNumber:1,
  supplierOrderListingPageNumber: 1,
  supplierInvoicesPageNumber: 1,
  supplierManageCataloguePageNumber: 1,
  usersPageNumber:1,
  productApprovalPageNumber: 1,
  specilalPricingPageNumber: 1,
  adminManageOrdersPageNumber: 1,
};

export const selectedPageSlice = createSlice({
  name: "selectedPage",
  initialState: selectedPageState,
  reducers: {
    setPage(state: StatePropType, action: ActionPropType) {
      if (action.payload.type === "reset") {
        state = selectedPageState;
      } else {
        state[action.payload.type] = action.payload.index;
      }
      console.log("stattatata",state);
      return state;
    },
  },
});

export const { setPage } = selectedPageSlice.actions;
export default selectedPageSlice.reducer;
