import styles from "./ContactSupport.module.scss";
import { useEffect, useRef } from "react";
import phoneIcon from "../../../assets/images/phone-call.svg";
import emailIcon from "../../../assets/images/email1.svg";
import whatsAPP from "../../../assets/images/whatsappIcon.svg";
import { constants } from "../../constants/constants";

const ContactSupport = ({ setCloseContactSupport }: any) => {
  const contactSupportRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        contactSupportRef &&
        contactSupportRef.current &&
        !contactSupportRef?.current?.contains(event.target)
      ) {
        setCloseContactSupport(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClose = () => {
    setCloseContactSupport(false);
  };

  return (
    <>
      <section className={styles.parentContainer} ref={contactSupportRef}>
        <header>
          <div className={styles.heading}>Contact Support</div>
        </header>
        <main className={styles.mainContainer}>
          <div className={styles.phoneContact}>
            <div className={styles.infoContaioner}>
              <img src={phoneIcon} alt="phone" />
              <a href={`tel:${constants.contactSupport.CONTACT_SUPPORT_NUMBER}`}>{constants.contactSupport.CONTACT_SUPPORT_PHONE}</a>
            </div>
            <div className={styles.infoContaioner}>
              <img src={whatsAPP} alt="phone" />

              <a
                href={`https://wa.me/${constants.contactSupport.CONTACT_SUPPORT_NUMBER}`}
                target="_blank"
                rel="noreferrer"
              >
                {constants.contactSupport.CONTACT_SUPPORT_PHONE}
              </a>
            </div>
          </div>
          <div className={styles.infoContaioner}>
            <img src={emailIcon} alt="phone" />
            <a href={`mailto:${constants.contactSupport.CONTACT_SUPPORT_MAIL}`}>
              {constants.contactSupport.CONTACT_SUPPORT_MAIL}
            </a>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.closeButton} onClick={handleClose}>
              Close
            </button>
          </div>
        </main>
      </section>
    </>
  );
};

export default ContactSupport;
