import { createSlice } from "@reduxjs/toolkit";

interface profileImage {
    profileImage: any,
}

const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") || "{}")
const changeProfileImage : profileImage = {
    profileImage:  loggedInUser?.profile_image
  };
const changeProfileImageChange = createSlice({
    name: "changeProfileImage",
    initialState: changeProfileImage,
    reducers: {
        setProfileImage: (state, action) => {
            state.profileImage = action.payload
        }
    }


});
export const {setProfileImage} = changeProfileImageChange.actions;
export default changeProfileImageChange.reducer;