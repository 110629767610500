import styles from "./AdminSupplierListingCard.module.scss";
export const AdminSupplierListingCard = (props: any) => {
  const { handleCardClick, ...cardDataWithoutClick } = props;
  return (
    <>
      <div
        className={styles.card}
        onClick={() => handleCardClick(cardDataWithoutClick)}
      >
        <div className={styles.product}>
          <div className={styles.productName}>{props?.businessName}</div>
        </div>

        <div className={styles.product}>
          <div className={`${styles.emailID} ${styles.textStyle}`}>
            {props?.emailID}
          </div>
        </div>

        <div className={styles.product}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {props?.contactName}
          </div>
        </div>
        <div className={`${styles.brandName} ${styles.textStyle}`}>
          {props?.phNo}
        </div>
        <div className={`${styles.brandName} ${styles.textStyle}`}>
          {props?.address}
        </div>
      </div>
    </>
  );
};
