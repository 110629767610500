import styles from "./AccountDetailsStyles.module.scss";
import { constants } from "../../../common/constants/constants";
// import addCertificate from "../../../assets/images/addCertificate.svg";
import cancel from "../../../assets/images/cancelToast.svg";
import update from "../../../assets/images/update.svg";
import Button from "../../../common/components/button/Button";
import { useEffect, useState } from "react";
import { MovPopup } from "./components/movPopup/MovPopup";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../common/constants/routeConfig";
import { ImageViewer } from "../../../common/components/imageViewer/ImageViewer";
import { useSelector } from "react-redux";
import AddCertificate from "./addCertificate/AddCertificate";
import { Drawer } from "@mui/material";
import { IAccoutDetailsProps } from "../../../models/IAccoutDetailsProps";
import { useAxios } from "../../../services/useAxios";
import useFetchCountries from "../../../services/useCountry";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
type AccountDetailsCompProps = {
  viewCertificate: (filename: any, filesource: any) => void;
  setIsAddCertificate: (state: boolean) => void;
  setShowMovPopup: (state: boolean) => void;
  closeDrawer: () => void;
  currentUserAccountDetails: any;
  selectedCountry: any;
  selectedEmirate: any;
};

export const AccountDetails = ({ setIsAcntDetails }: IAccoutDetailsProps) => {
  const { countriesList } = useFetchCountries();
  const [currentUserAccountDetails, setCurrentUserAccountDetails] =
    useState<any>({});
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [isAddCertificate, setIsAddCertificate] = useState(false);
  const [showMovPopup, setShowMovPopup] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileSource, setFileSource] = useState();
  const [isViewFile, setIsViewFile] = useState(false);

  const viewCertificate = (fileName: any, fileSource: any) => {
    setIsViewFile(true);
    setFileName(fileName);
    setFileSource(fileSource);
  };

  const handleCloseDrawer = () => {
    if (showMovPopup) {
      setShowMovPopup(false);
      return;
    }
    if (isViewFile) {
      setIsViewFile(false);
      return;
    }
    if (isAddCertificate) {
      setIsAddCertificate(false);
      return;
    }
    setIsAcntDetails(false);
  };
  const [currentUser] = useState(
    JSON.parse(localStorage?.getItem("loggedInUser") as any)
  );

  //api to fetch the account details
  const getAccountDetails = useAxios({
    axiosParams: {
      url: `store/supplier/${currentUser?.business_details?.[0]?.business_setup_id}`,
    },
    method: "GET",
  });
  const getAccountDetailsById = async () => {
    const response: any = await getAccountDetails.fetchData();
    if (response && response?.status === "SUCCESS") {
      setCurrentUserAccountDetails(response?.data);
    }
  };
  useEffect(() => {
    getAccountDetailsById();
  }, []);

  useEffect(() => {
    if (currentUser?.business_details?.[0]?.business_setup_id) {
      let country = null;
      if (
        currentUserAccountDetails &&
        currentUserAccountDetails?.address &&
        currentUserAccountDetails?.address?.country_code
      ) {
        country = countriesList?.find(
          (ele: any) =>
            ele?.iso_2 === currentUserAccountDetails?.address?.country_code
        );
        setSelectedCountry(country);
      }
      if (
        currentUser?.business_details?.[0]?.business_setup_id &&
        country &&
        currentUserAccountDetails?.address?.address_2
      ) {
        setSelectedEmirate(
          country.emirates.find(
            (ele: any) =>
              ele?.id === currentUserAccountDetails?.address?.address_2
          )
        );
      }
    }
  }, [countriesList, currentUserAccountDetails]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={true}
        onClose={() => handleCloseDrawer()}
        hideBackdrop={false}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <p className={styles.heading}>
              {isAddCertificate ? "Add Certificate" : "Account Details"}
            </p>
            <img
              src={cancel}
              alt="cancel"
              className={styles.cancel}
              onClick={handleCloseDrawer}
            />
          </div>
          {isAddCertificate ? (
            <AddCertificate setAddCertificate={setIsAddCertificate} />
          ) : (
            <AccountDetailsComp
              currentUserAccountDetails={currentUserAccountDetails}
              viewCertificate={viewCertificate}
              setIsAddCertificate={setIsAddCertificate}
              setShowMovPopup={setShowMovPopup}
              closeDrawer={() => setIsAcntDetails(false)}
              selectedCountry={selectedCountry}
              selectedEmirate={selectedEmirate}
            />
          )}
          {showMovPopup && (
            <MovPopup
              value={
                currentUserAccountDetails &&
                currentUserAccountDetails?.min_order_value &&
                Number(currentUserAccountDetails?.min_order_value)
              }
              setShowPopup={setShowMovPopup}
              currentUserAccountDetails={currentUserAccountDetails}
              setCurrentUserAccountDetails={setCurrentUserAccountDetails}
            />
          )}
          {isViewFile && (
            <ImageViewer
              fileName={fileName}
              fileSource={fileSource}
              handleImageViewer={setIsViewFile}
            />
          )}
        </div>
      </Drawer>
    </>
  );
};

function AccountDetailsComp({
  viewCertificate,
  setIsAddCertificate,
  setShowMovPopup,
  closeDrawer,
  currentUserAccountDetails,
  selectedCountry,
  selectedEmirate,
}: AccountDetailsCompProps) {
  const accountDetails = useSelector((state: any) => state.accountDetails);
  const navigate = useNavigate();
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(loggedInUser?.business_details?.[0]?.customer_id);
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );

  const [toastData] = useState(constants.defaultToastData);

  const [fetchedData, setFetchedData] = useState<any>({});
  const fetchCTACardStatus = useAxios({
    axiosParams: {
      url: `store/onboarding/supplier/${customerId}/${businessId}`,
    },
    method: "GET",
  });
  const fetchCTACardStatusAPI = async () => {
    if (customerId && businessId) {
      const response: any = await fetchCTACardStatus.fetchData();
      if (response && response?.status === "SUCCESS") {
        setFetchedData(response?.data);
      }
    }
  };

  console.log(loggedInUser, 'user')
  const getBusinessStore = (stores: any[]) => {
    const defaultStore = stores?.filter(
      (each_store: any) => each_store?.is_default
    );
    if (defaultStore?.length > 0) return defaultStore?.[0];
    else return stores?.[0];
  };
  useEffect(() => {
    fetchCTACardStatusAPI();
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          {currentUserAccountDetails && currentUserAccountDetails.logo_file && (
            <div className={styles.logo}>
              <img src={currentUserAccountDetails.logo_file} alt="logo" />
            </div>
          )}
          <div className={styles.details}>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.businessName}
              </div>
              <div className={styles.value}>
                {(currentUserAccountDetails &&
                  currentUserAccountDetails?.business_name) ??
                  "-"}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.primaryContactName}
              </div>
              <div className={styles.value}>
                {(currentUserAccountDetails &&
                  currentUserAccountDetails?.business_stores &&
                  getBusinessStore(currentUserAccountDetails?.business_stores)
                    ?.contact_name) ??
                  "-"}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.email_id}
              </div>
              <div className={styles.value}>
                {(currentUserAccountDetails &&
                  currentUserAccountDetails?.email_id) ??
                  "-"}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.contact_no}
              </div>
              <div className={styles.value}>
                {(currentUserAccountDetails &&
                  currentUserAccountDetails?.business_stores &&
                  getBusinessStore(currentUserAccountDetails?.business_stores)
                    ?.phone_number?.split("#")
                    ?.join("-")) ??
                  "-"}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.BusinessSetupForm.address}
              </div>
              <div className={styles.value}>
                {getBusinessStore(currentUserAccountDetails?.business_stores)
                  ?.address
                  ? `${
                      currentUserAccountDetails &&
                      currentUserAccountDetails?.business_stores &&
                      getBusinessStore(
                        currentUserAccountDetails?.business_stores
                      )?.address?.address
                    }, ${
                      currentUserAccountDetails?.business_stores &&
                      getBusinessStore(
                        currentUserAccountDetails?.business_stores
                      )?.address?.area
                    }, ${
                      currentUserAccountDetails?.business_stores &&
                      getBusinessStore(
                        currentUserAccountDetails?.business_stores
                      )?.address?.emirate?.name
                    }, ${
                      currentUserAccountDetails?.business_stores &&
                      getBusinessStore(
                        currentUserAccountDetails?.business_stores
                      )?.address?.country?.display_name
                    }`
                  : "-"}
                {/* {currentUserAccountDetails &&
                  currentUserAccountDetails?.address &&
                  currentUserAccountDetails?.address?.address_1}
                ,{" "}
                {currentUserAccountDetails &&
                  currentUserAccountDetails?.address &&
                  currentUserAccountDetails?.address?.city}
                , {selectedEmirate && selectedEmirate?.name},{" "}
                {selectedCountry && selectedCountry?.display_name} */}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.IBAN}
              </div>
              <div className={styles.value}>
                {currentUserAccountDetails?.iban == null ||
                currentUserAccountDetails?.iban == undefined ||
                currentUserAccountDetails?.iban == ""
                  ? "-"
                  : currentUserAccountDetails?.iban}
              </div>
            </div>
            <div>
              <div className={styles.label}>{constants.accountDetails.MOV}</div>
              <div className={styles.value}>
                {currentUserAccountDetails?.min_order_value ? "AED " : "-"}
                {currentUserAccountDetails &&
                  currentUserAccountDetails?.min_order_value &&
                  Number(currentUserAccountDetails?.min_order_value)}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.trade_license}
              </div>
              <div className={styles.value}>
                License number:
                {currentUserAccountDetails &&
                  currentUserAccountDetails?.license_number}{" "}
                |{" "}
                <span
                  className={`${styles.view} ${
                    fetchedData?.business_setup_status === "pending"
                      ? styles.disable
                      : null
                  }`}
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    viewCertificate(
                      "License File",
                      currentUserAccountDetails &&
                        currentUserAccountDetails?.license_file
                    );
                  }}
                >
                  View certificate
                </span>
              </div>
            </div>
            <div>
              <div className={styles.label}>
                {constants.accountDetails.watermelon_signed_contract}
              </div>
              <div
                className={`${styles.view}  ${
                  fetchedData?.business_setup_status === "pending"
                    ? styles.disable
                    : null
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  viewCertificate(
                    "Watermelon Signed Contract",
                    currentUserAccountDetails &&
                      currentUserAccountDetails?.contract_file
                  );
                }}
              >
                View certificate
              </div>
            </div>
            {accountDetails.pestControlCertification !== null && (
              <div>
                <div className={styles.label}>
                  {constants.accountDetails.pest_control_certification}
                </div>
                <div className={styles.value}>
                  Report Number:{accountDetails.reportNO} |{" "}
                  <span className={styles.view}>View certificate</span>
                </div>
              </div>
            )}
            {accountDetails.signedContract !== null && (
              <div>
                <div className={styles.label}>
                  {constants.accountDetails.signed_contract}
                </div>
                <div className={styles.view}>View certificate</div>
              </div>
            )}
          </div>
          {accountDetails.pestControlCertification === null &&
            accountDetails.signedContract === null && (
              <div className={styles.add}>
                {/* <div
                  className={styles.addCard}
                  onClick={() => {
                    setToastData({
                      message: "Add certificate will be done in the next phase",
                      status: "success",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                    // setIsAddCertificate(true);
                  }}
                >
                  <div className={styles.icon}>
                    <img src={addCertificate} alt="add" />
                  </div>
                  <div className={styles.addLabel}>Add certificate</div>
                </div> */}
              <div
                  className={`${styles.addCard} ${ fetchedData?.business_setup_status === 'pending' &&styles.disabledCard}`}
                  onClick={() => setShowMovPopup(true)}
                >
                  <div className={styles.icon}>
                    <img src={update} alt="update" />
                  </div>
                  <div className={styles.addLabel}>
                    Update minimum order value{" "}
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className={styles.btns}>
          {accountDetails.pestControlCertification !== null &&
            accountDetails.signedContract !== null && (
              <div className={styles.editBtn}>
                <Button
                  label={"Update MOV"}
                  secondary={true}
                  handleClick={() => setShowMovPopup(true)}
                />
              </div>
            )}
          <div className={styles.editBtn}>
            <Button
              disabled={loggedInUser?.business_details?.[0]?.role_key==="supplier-staff"}
              label={"Edit details"}
              handleClick={() => {
                navigate(routeConfig.businessSetupForm, {
                  state: {
                    userID:
                      currentUserAccountDetails &&
                      currentUserAccountDetails?.id,
                  },
                });
                closeDrawer();
              }}
            />
          </div>
        </div>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={errorToast}
          content={toastData?.message}
          position={"top-right"}
        />
      ) : (
        <></>
      )}
    </>
  );
}
