import { configureStore } from "@reduxjs/toolkit";
import accountDetailsSlice from "../reducerSlices/accountDetailsSlice";
import addIndividualProductFormSlice from "../reducerSlices/addIndividualProductFormSlice";
import adminAddBuyerSlice from "../reducerSlices/adminAddBuyerSlice";
import adminBuyerListSlice from "../reducerSlices/adminBuyerListSlice";
import adminCatalogueListSlice from "../reducerSlices/adminCatalogueListSlice";
import adminManageOrderDetailsSlice from "../reducerSlices/adminManageOrderDetailsSlice";
import adminManageOrdersListSlice from "../reducerSlices/adminManageOrdersListSlice";
import adminProductApprovalSlice from "../reducerSlices/adminProductApprovalSlice";
import adminSupplierListSlice from "../reducerSlices/adminSupplierListSlice";
import counterSlice from "../reducerSlices/counterSlice";
import headerSlice from "../reducerSlices/headerSlice";
import loaderSlice from "../reducerSlices/loaderSlice";
import loginInSlice from "../reducerSlices/loginInSlice";
import manageCatalogueListSlice from "../reducerSlices/manageCatalogueList";
import manageOrderDetailsSlice from "../reducerSlices/manageOrderDetails";
import manageOrdersListSlice from "../reducerSlices/manageOrdersList";
import orderedProductsDetailsSlice from "../reducerSlices/orderedProductsDetailsSlice";
import productDetailsSlice from "../reducerSlices/productDetailsSlice";
import selectedTabSlice from "../reducerSlices/selectedTabSlice";
import setupAccountStateSlice from "../reducerSlices/setupAccountStateSlice";
import signUpSlice from "../reducerSlices/signUpSlice";
import supplierInvoiceListSlice from "../reducerSlices/supplierInvoiceList";
import userTypeSlice from "../reducerSlices/userTypeSlice";
import selectedPageSlice from "../reducerSlices/selectedPageSlice";
import toastStatusSlice from "../reducerSlices/toastStatusSlice";
import referAFriendSlice from "../reducerSlices/referAFriendSlice";
import modifyOrdersSlice from "../reducerSlices/modifyOrdersSlice";
import ProfileImageSlice from "../reducerSlices/ProfileImageSlice";
import myCartSlice from "../reducerSlices/myCartSlice";
import activeOutletSlice from "../reducerSlices/activeOutletSlice";
import filterDataSlice from "../reducerSlices/filtersDataSlice";
import specialPriceSlice from "../reducerSlices/specialPriceSlice";

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    header: headerSlice,
    userType: userTypeSlice,
    adminSupplierList: adminSupplierListSlice,
    adminBuyerList: adminBuyerListSlice,
    adminProductApprovalList: adminProductApprovalSlice,
    accountDetails: accountDetailsSlice,
    adminCatalogue: adminCatalogueListSlice,
    orderedProductsDetails: orderedProductsDetailsSlice,
    setupAccountState: setupAccountStateSlice,
    loginUserDetails: loginInSlice,
    addIndividualProductForm: addIndividualProductFormSlice,
    signUpUserDetails: signUpSlice,
    adminAddBuyer: adminAddBuyerSlice,
    loader: loaderSlice,
    selectedTab: selectedTabSlice,
    selectedPage: selectedPageSlice,
    manageCatalogueList: manageCatalogueListSlice,
    singleProductDetails: productDetailsSlice,
    manageOrdersList: manageOrdersListSlice,
    manageOrderDetails: manageOrderDetailsSlice,
    supplierInvoiceList: supplierInvoiceListSlice,
    adminManageOrdersList: adminManageOrdersListSlice,
    adminManageOrderDetails: adminManageOrderDetailsSlice,
    toastStatus: toastStatusSlice,
    setReferAFriendState: referAFriendSlice,
    modifyOrdersModal: modifyOrdersSlice,
    setProfileImage: ProfileImageSlice,
    myCart: myCartSlice,
    activeOutlet:activeOutletSlice,
    filteredKeys: filterDataSlice,
    specialPrice: specialPriceSlice,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
