import styles from "./ProgressBar.module.scss";
import { IProgressBarProps } from "../../../models/IProgressBarProps";

const ProgressBar = ({ progress, customClass, customBarColor, borderRadius }: IProgressBarProps) => {
  return (
    <div className={`${styles.Parentdiv} ${customClass}`} data-testid="progressBar"  >
      <div className={styles.Childdiv} style={{ width: `${progress}%` ,backgroundColor: `${customBarColor}`, borderRadius: `${borderRadius}`}} data-testid="filled">
      </div>
    </div>
  );
};

export default ProgressBar;
