import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

export interface userTypeState {
  user: any;
  loading: boolean;
  error: string | null;
}

const initialState: userTypeState = {
  user: null,
  loading: false,
  error: null,
};

// interface FetchUserPayload {
//   email: string;
//   password: string;
//   userType: string;
// }

// export const createUser = createAsyncThunk(
//   "user/createUser",
//   async (payload: FetchUserPayload) => {
//     const { email, password, userType } = payload;
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_API_URL}store/customers`,
//         {
//           email,
//           password,
//           role: {
//             role_key: userType,
//           },
//         }
//       );
//       if (response?.data?.customer?.id) {
//         localStorage.setItem("user", JSON.stringify(response?.data?.customer));
//       }
//       return response?.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const signUpSlice = createSlice({
  name: "signUpReducer",
  initialState: initialState,
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(createUser.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(createUser.fulfilled, (state, action) => {
  //       state.user = action.payload;
  //       state.loading = false;
  //       state.error = null;
  //     })
  //     .addCase(createUser.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error =
  //         action.error.message || "An error occurred while creating user data.";
  //     });
  // },
});

export default signUpSlice.reducer;
