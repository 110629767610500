import { Bar } from "react-chartjs-2";
import styles from "./TopPlacedProductChart.module.scss";
import footerIcon from "../../../assets/images/footerIcon.svg";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);

const TopPlacedProductChart = () => {
  const options = {
    responsive: true,
    plugins: {
      xLineVisible: false,
      legend: {
        position: "top" as const,
        display: false,
      },
      tooltip: {
        enabled: false,
        usePointStyle: true,
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderColor: "rgba(104, 161, 75, 1)",
        borderWidth: 1,
        titleColor: "#383434",
        displayColors: false,
        callbacks: {
          // To change title in tooltip
          title: (data: any) => {
            return `${data[0].parsed.y}%`;
          },
          // To change label in tooltip
          label: (data: any) => {
            return data.parsed.y === 2 ? "Good" : "Critical";
          },
        },
        external: (data: any) => {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (tooltipEl) {
            tooltipEl.style.backgroundColor = "#fff";
            tooltipEl.style.borderRadius = "6px";
            tooltipEl.style.border = " 1px solid rgba(104, 161, 75, 1)";
            tooltipEl.style.borderWidth = "1px";
            tooltipEl.style.padding = "10px";
            tooltipEl.style.width = "235px";
            tooltipEl.style.height = "90px";
          }

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";

            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = data.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          const getBody = (bodyItem: any) => {
            return bodyItem.lines;
          };

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = "<div>";

            titleLines.forEach((title: any) => {
              innerHtml +=
                "<div style='font-size: 20px; font-family: Urbanist;'>" +
                title +
                "</div>";
            });
            innerHtml += "</div><div>";

            innerHtml +=
              "<div id='separator' style='height: 1px; width: 100%; background-color: #B9B9B9; margin: 12px 0px'></div>";

            bodyLines.forEach((body: any, i: any) => {
              innerHtml +=
                "</div><div style='display: flex; flex-direction:row; justify-content:space-between; width: 100%;'>";
              innerHtml +=
                "<div style='font-size: 12px; font-weight: 400; font-family: Urbanist; align-self:flex-start;'>";
              innerHtml += "<div>AED 3173.00</div>";
              innerHtml +=
                "<div style='color:#949494;'>Revenue generated</div>";
              innerHtml += "</div>";
              innerHtml +=
                "<div style='font-size: 12px; font-weight: 400; font-family: Urbanist; align-self:flex-start;'>";
              innerHtml += "<div>14 SKUs</div>";
              innerHtml += "<div style='color:#949494;'>Quantity sold</div>";
              innerHtml += "</div>";
            });
            innerHtml += "</div>";

            let tableRoot = tooltipEl.querySelector("div");
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            } else {
              tableRoot = document.createElement("div");
              tableRoot.innerHTML = innerHtml;
              tooltipEl.appendChild(tableRoot);
            }
          }

          const position = data.chart.canvas.getBoundingClientRect();
          const bodyFont = data.helpers?.toFont(tooltipModel.options.bodyFont);

          tooltipEl.classList.add("custom-tooltip");
          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          const tooltipWidth = 235;
          const tooltipLeft =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            tooltipWidth / 2 +
            "px";
          tooltipEl.style.left = tooltipLeft;
          tooltipEl.style.font = bodyFont?.string;
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
          const tooltipHeight = 90;
          const yOffset = 10;
          const topPosition =
            position.top +
            window.pageYOffset +
            tooltipModel.caretY -
            tooltipHeight -
            yOffset +
            "px";
          tooltipEl.style.top = topPosition;
        },
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          tickColor: "white",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 20,
        },
        min: 0,
        max: 100,
        border: {
          display: false,
        },
      },
    },
  };
  const labels = [
    "Milk",
    "Cheese",
    "Parsley",
    "Rice",
    "Yogurt",
    "Onion",
    "Lettuce",
    "Chicken",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Item Placed",
        barThickness: 6,
        data: [80, 75, 62, 58, 60, 47, 42, 36],
        backgroundColor: "#FCA66C",
        borderRadius: 6,
      },
    ],
  };

  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.barGraph}>
          <Bar options={options} data={data} />
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerIcon}>
            <img src={footerIcon} alt="footerIcon" />
          </div>
          <div className={styles.footerText}>
            Lettuce consumption has been increased by 12% over last 2 weeks.
          </div>
        </div>
      </div>
    </>
  );
};

export default TopPlacedProductChart;
