import { useState } from "react";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import styles from "./mobileNumberField.module.scss";
import { constants } from "../../../constants/constants";
import Button from "../../button/Button";

const MobileNumberField = ({
  label,
  selectedCountry = null,
  onCountryChange,
  onMobileNumberChange,
  errorMessage,
  registerMobileNumber,
  registerCountry,
  value,
  id,
  defaultValue,
  mobileNumberField,
  editForm,
  disabled,
  width,
  options,
  showGetOtp = false,
  handleGetOtp = () => {},
  tabIndex = 0
}: any) => {
  const handleMobileNumberChange = (event: any) => {
    const newValue = event;
    onMobileNumberChange(newValue?.target?.value);
  };

  const countryList = options && options.length > 0 ? options : [];
  const [mobileSelectedvalue, setMobileSelectedvalue] =
    useState<string>(defaultValue);
  return (
    <>
      <div
        className={`${styles.inputLabelStyles} ${!disabled ? styles.disabledIbanLabel : null} ${
          errorMessage ? styles.errorStyle : null
        } `
      }
      >
        {label}
      </div>
      <div
        style={{ width: width ? `${width}` : "400px" }}
        className={`${styles.fieldWrapper} ${
          errorMessage ? styles.errorBorder : null
        } ${editForm ? styles.editFormStyles : ""}`}
        tabIndex={tabIndex}
      >
        <Dropdown
          mobileNumberField={mobileNumberField}
          label=""
          options={countryList || []}
          minWidth={"105px"}
          register={registerCountry}
          onChange={onCountryChange}
          overlayWidth={"400px"}
          id={id}
          isDropdownOutlineRequired={false}
          isCountryList={true}
          defaultValue={defaultValue ? defaultValue : ""}
          selectedValue={defaultValue ? defaultValue : ""}
          isContactNumber={true}
          defaultLabel={
            mobileSelectedvalue
              ? mobileSelectedvalue
              : defaultValue
              ? defaultValue
              : countryList[0]?.dial_codes
          }
          disabled={disabled}
          ismobileDropDown={true}
          setSelectedvalue={setMobileSelectedvalue}
        />
        <div className={styles.rowSeperator}></div>
        <input
          type="number"
          value={value}
          data-testid={"phone"}
          className={`${styles.inputField} ${
            editForm ? styles.editFormInputStyles : ""
          }`}
          {...registerMobileNumber}
          placeholder={"Enter your phone number"}
          onChange={handleMobileNumberChange}
          disabled={disabled}
        />
        {showGetOtp && (
          <Button
            link
            label={constants.confirmContactNo.getOtp}
            handleClick={handleGetOtp}
            customClass={styles.getOtp}
          />
        )}
      </div>
      {errorMessage ? (
        <div className={styles.errorMessage}>{errorMessage.toString()}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default MobileNumberField;
