import React, { useRef, useState } from "react";
import styles from "./FilterSearchUsingAlphabets.module.scss";
import searchIcon from "../../../assets/images/so-search.svg";
import closeIcon from "../../../assets/images/close.svg";
import CheckboxButton from "../checkbox/CheckboxButton";
import { IAlphabeticalFilterProps, IFilterSearchSupplier } from "../../../models/IFilterSearchSupplier";
import { MinMaxValues } from "../../../models/IMarketplaceFiltersProps";
import { findMinAndMaxValues, transformFilterValue } from "../../../utils/filters";


const FilterSearchUsingAlphabets = ({
  handleClose,
  suppliersArray,
  limitedOptions,
  filterName,
  setLimitedOptions,
  setShowMoreFilterValues,
  setCurrentPage,
  setQueryParameters,
  queryParameters,
  setPrices,
  prices
}: IAlphabeticalFilterProps) => {
  const alphabets = [
    { value: "#", id: "#" },
    { value: "A", id: "A" },
    { value: "B", id: "B" },
    { value: "C", id: "C" },
    { value: "D", id: "D" },
    { value: "E", id: "E" },
    { value: "F", id: "F" },
    { value: "G", id: "G" },
    { value: "H", id: "H" },
    { value: "I", id: "I" },
    { value: "J", id: "J" },
    { value: "K", id: "K" },
    { value: "L", id: "L" },
    { value: "M", id: "M" },
    { value: "N", id: "N" },
    { value: "O", id: "O" },
    { value: "P", id: "P" },
    { value: "Q", id: "Q" },
    { value: "R", id: "R" },
    { value: "S", id: "S" },
    { value: "T", id: "T" },
    { value: "U", id: "U" },
    { value: "V", id: "V" },
    { value: "W", id: "W" },
    { value: "X", id: "X" },
    { value: "Y", id: "Y" },
    { value: "Z", id: "Z" },
  ];
  // const alphabet = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // const arrayLength = 75;

  // function generateSupplierObject(id: any) {
  //   const letterIndex = id % alphabet.length;
  //   const randomName = `${alphabet[letterIndex]} Supplier`;
  //   const supplierId = `SUP${String(id + 1).padStart(4, "0")}`;
  //   const quantity = Math.floor(Math.random() * 50) + 1;

  //   return {
  //     id: id + 1,
  //     supplierName: randomName,
  //     supplierId: supplierId,
  //     quantity: quantity,
  //   };
  // }

  // const suppliersArray = [];

  // for (let i = 0; i < options?.length; i++) {
  //   suppliersArray.push(generateSupplierObject(i));
  // }

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAlphabet, setSelectedAlphabet] = useState("#");

  const groupedSuppliers: { [key: string]: IFilterSearchSupplier[] } =
    suppliersArray.reduce((acc: any, supplier: any) => {
      const firstLetter = supplier.supplierName[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(supplier);
      return acc;
    }, {} as { [key: string]: IFilterSearchSupplier[] });

  const [shownGroups, setShownGroups] = useState<string[]>(() =>
    Object.keys(groupedSuppliers)
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    const filteredGroups = Object.keys(groupedSuppliers).filter((letter) =>
      groupedSuppliers[letter].some((supplier) =>
        supplier.supplierName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    );
    setShownGroups(filteredGroups);
  };

  const filteredSuppliers = Object.keys(groupedSuppliers).reduce(
    (filtered, letter) => {
      filtered[letter] = groupedSuppliers[letter].filter((supplier) =>
        supplier.supplierName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return filtered;
    },
    {} as { [key: string]: IFilterSearchSupplier[] }
  );

  const isAlphabetUsed = (alphabet: string) => {
    return Object.keys(groupedSuppliers).some(
      (letter) => letter.toUpperCase() === alphabet
    );
  };
  const [hoveredAlphabet, setHoveredAlphabet] = useState("");

  const handleAlphabetHover = (alphabet: string) => {
    setHoveredAlphabet(alphabet);
  };

  const myRefs = useRef([]);
  myRefs.current = alphabets.map(
    (_, i) => myRefs.current[i] ?? React.createRef()
  );

  const filterBodyRef = useRef<HTMLDivElement>(null);
  const handleAlphabetClick = (alphabet: string) => {
    setSelectedAlphabet(alphabet);

    const alphabetSection = filteredSuppliers[alphabet];
    if (
      alphabetSection &&
      alphabetSection.length > 0 &&
      filterBodyRef.current
    ) {
      const firstSupplier = filterBodyRef.current.querySelector(
        `.${styles.supplierSection}[data-alphabet="${alphabet}"] .${styles.supplier}`
      );
      if (firstSupplier) {
        const isSupplierInView = isElementInView(firstSupplier);
        if (!isSupplierInView) {
          firstSupplier.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const isElementInView = (element: Element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= window.innerHeight &&
      rect.right <= 0
    );
  };

  const runFunction = (ref: React.RefObject<HTMLButtonElement> | null) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleIsCheckedInTotalOptions = (
    filterName: string,
    item: IFilterSearchSupplier,
    totalOptions: any[],
    limitedOptions?: any[]
  ) => {
    setCurrentPage(1);
    //  without limit
    const latestTotalOptions = totalOptions?.map((each_item: any) => {
      if (each_item?.supplierId === item?.supplierId) {
        return { ...item, isChecked: !item?.isChecked };
      }
      return each_item;
    });
    setShowMoreFilterValues((prev: any) => ({
      ...prev,
      totalOptions: latestTotalOptions,
    }));
    //  with limit
    const latestLimitedOptions =
      limitedOptions?.map((each_filter: any) => {
        if (each_filter?.name === filterName) {
          const latestOptions = each_filter?.options?.map((each_item: any) => {
            if (each_item?.id === item?.supplierId)
              return { ...each_item, isChecked: !item?.isChecked };
            return each_item;
          });
          return { ...each_filter, options: latestOptions };
        }
        return each_filter;
      }) ?? [];
    setLimitedOptions([...latestLimitedOptions]);
    const queryValue = queryParameters[transformFilterValue(filterName)];
    if (queryValue && Array?.isArray(queryValue)) {
      console.log("iff",queryValue);
      let uniqueIds: any[] = [];
      if (queryValue?.includes(item?.supplierId)) {
        uniqueIds = queryValue?.filter((id: string) => id != item?.supplierId);
      } else {
        uniqueIds = [...queryValue, item?.supplierId];
      }
      queryParameters[transformFilterValue(filterName)] = [...uniqueIds];
      setQueryParameters({ ...queryParameters });
    } else {
      if (filterName === "prices") {
        if (prices?.includes(item?.supplierId)) {
          const latestPrices = prices?.filter((id: string) => id != item?.supplierId);
          if (latestPrices?.length > 0) {
            const result: MinMaxValues = findMinAndMaxValues(latestPrices);
            setQueryParameters({
              ...queryParameters,
              min_price: result?.minLeft,
              max_price: result?.maxRight,
            });
            setPrices([...latestPrices]);
          } else {
            setQueryParameters({
              ...queryParameters,
              min_price: "",
              max_price: "",
            });
            setPrices([]);
          }
        } else {
          const latestPrices = [...prices, item?.id];
          const result: MinMaxValues = findMinAndMaxValues(latestPrices);
          setQueryParameters({
            ...queryParameters,
            min_price: result?.minLeft,
            max_price: result?.maxRight,
          });
          setPrices([...latestPrices]);
        }
      } else {
        setQueryParameters({
          ...queryParameters,
          [transformFilterValue(filterName)]: [item?.supplierId],
        });
      }
    }
  };
  console.log(suppliersArray,"ggggg",shownGroups);
  return (
    <div className={styles.container}>
      <div className={styles.filterHeader}>
        <div className={styles.searchContainer}>
          <img src={searchIcon} alt="search" />
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        {alphabets.map((alphabet, i) => (
          <div
            key={alphabet.id}
            className={`${styles.alphabet} ${
              selectedAlphabet === alphabet.value ? styles.active : ""
            } ${!isAlphabetUsed(alphabet.value) ? styles.grayedOut : ""}`}
            onClick={() => {
              handleAlphabetClick(alphabet.value);
              runFunction(myRefs.current[i]);
            }}
            onMouseEnter={() => handleAlphabetHover(alphabet.value)}
            onMouseLeave={() => handleAlphabetHover("")}
          >
            {alphabet.value}
          </div>
        ))}
        <div className={styles.close} onClick={handleClose}>
          <img src={closeIcon} alt="close" />
        </div>
      </div>
      <div className={styles.filterBodyContainer}>
        <div className={styles.filterBody}>
          {Object.keys(filteredSuppliers).map(
            (letter, i) =>
              shownGroups.includes(letter) && (
                <div
                  ref={myRefs.current[i]}
                  className={`${styles.supplierSection} ${
                    hoveredAlphabet && hoveredAlphabet !== letter
                      ? styles.grayedOut
                      : ""
                  }`}
                  key={letter}
                  data-alphabet={letter}
                >
                  <div className={styles.sectionHeader}>{letter}</div>
                  {filteredSuppliers[letter].map(
                    (supplier: IFilterSearchSupplier) => (
                      <div className={styles.supplier} key={supplier.id}>
                        <CheckboxButton
                          checked={supplier?.isChecked}
                          handleChange={() =>
                            handleIsCheckedInTotalOptions(
                              filterName,
                              supplier,
                              suppliersArray,
                              limitedOptions
                            )
                          }
                        />
                        <div className={styles.supplierName}>
                          {supplier.supplierName}
                        </div>
                        {supplier.quantity > 0 && (
                          <div className={styles.quantity}>
                            {`(${supplier.quantity})`}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSearchUsingAlphabets;
