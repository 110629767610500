import { OnboardingDashboard } from "../onboardindDashboardFlow/onboardingDashboard/OnboardingDashboard";

const SupplierDashBoard = () => {
  return (
    <>
      <OnboardingDashboard />
    </>
  );
};

export default SupplierDashBoard;
