import styles from "./OrdersListingStyles.module.scss";
import { TabComponent } from "./components/tabComponent/TabComponent";

export const OrdersListing = () => {
  // const [userType, setUserType] = React.useState("supplier");
  // const searchCustomStyles = {
  //   marginRight: "38px",
  //   marginLeft: "40px",
  //   paddingTop: "0px",
  //   paddingBottom: "0px",
  // };

  return (
    <>
      <div className={styles.ordersListing}>
        {/* <div className={styles.sideNav}>
          <SideNav userType={userType} setUserType={setUserType} selectedId={constants.navLinksId.orders}/>
        </div> */}
        <div className={styles.right}>
          {/* <div className={styles.search}>
            <Search
              placeholder={"Search for products, customers, orders, and more!"}
              customStyles={searchCustomStyles}
            />
          </div> */}
          <div className={styles.heading}>Manage orders</div>
          <TabComponent />
        </div>
      </div>
    </>
  );
};
