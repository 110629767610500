import { Drawer } from "@mui/material";
import styles from "./SideDrawerStyles.module.scss";
import cancel from "../../../assets/images/cancelToast.svg";
import backArrow from "../../../assets/images/login/arrowLeft.svg";
import { ISideDrawerProps } from "../../../models/ISideDrawer";

export const SideDrawer = ({
  isdrawer,
  childComponent,
  setIsDrawer,
  heading,
  backNavigate,
  setBackNavigation,
  cancelButton,
  hideBackground,
}: ISideDrawerProps) => {
  function handleChange() {
    setBackNavigation && setBackNavigation(true);
  }
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isdrawer && isdrawer["right"]}
        onClose={() => {
          setIsDrawer && setIsDrawer({ right: false })}}
        hideBackdrop={hideBackground}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <div
              className={`${styles.heading} ${
                backNavigate ? styles.backArrowConatiner : null
              }`}
            >
              {backNavigate && (
                <div className={styles.backArrow} onClick={handleChange}>
                  <img src={backArrow} alt="back Arrow" />
                </div>
              )}
              {heading}
            </div>
            {!cancelButton && (
              <div
                className={styles.cancel}
                onClick={() => {
                  setIsDrawer && setIsDrawer({ right: false });
                  setBackNavigation && setBackNavigation(true);
                }}
              >
                <img src={cancel} alt="cancel"></img>
              </div>
            )}
          </div>
          <div className={styles.content}>{childComponent}</div>
        </div>
      </Drawer>
    </>
  );
};
