import { constants } from "./constants";
import { routeConfig } from "./routeConfig";

export const getPlaceHolder = (value: string) => {
    switch(value){
        case routeConfig.supplierDashboard : return "Search for products, customers, orders, and more!"
        case routeConfig.orderListingCatalogue : return "Search for products, customers, orders, and more!"
        case routeConfig.productListingCatalogue : return constants.productListing.SEARCHBAR_PLACEHOLDER
        case routeConfig.inVoices : return "Search for products, customers, orders, and more!"
        case routeConfig.supplierReport: return "Search for products, customers, orders, and more!"
        case routeConfig.specialPricing: return "Search for products, customers, orders, and more!"
        case routeConfig.myProfile: return "Search for products, customers, orders, and more!"
        default: return "Search for products, customers, orders, and more!"
    }
}