import React from 'react';
import ArrowLeft from '../../../assets/images/Arrow-left-pink.svg';
import { CategoryCardProps } from '../../../models/ICategoryCard';
import styles from './CategoryCard.module.scss';

const CategoryCard = ({
  title,
  imgSrc,
  id,
  handleCardClick,
  cardType,
  head,
}: CategoryCardProps) => {
  return (
    <div
      data-testid="category-card"
      className={`${styles.categoryCardContainer} ${
        cardType === 'search' && styles.searchCard
      } ${cardType === "marketplace" && styles.marketplaceCard}`}
      onClick={() => handleCardClick && handleCardClick(id)}
    >
      <div className={styles.cardContainer}>
        <div className={`${styles.imgContainer} ${cardType === "marketplace" && styles.marketplaceImg}`}>
          <img src={imgSrc} alt="icon" />
        </div>
        <div className={`${styles.head} ${cardType === "marketplace" && styles.marketplaceHead}`}>{head}</div>
        <div className={`${styles.title} ${cardType === "marketplace" && styles.marketplaceTitle}`}>{title}</div>
      </div>
      <div className={styles.hoverContainer}>
        <div className={styles.hoverTitle}>{title}</div>
        <div className={styles.hoverImgContainer}>
          <img src={ArrowLeft} alt="fresh-food" />
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
