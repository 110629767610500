import React from "react";
import PaymentModeCard from "../../cartSummary/paymentModeCard";
import PaymnentModeLabelValue from "../../cartSummary/paymentModeCardLabelValue";
import styles from "./paymentSummary.module.scss";
import amountIcon from "../../images/amountIcon.svg";
import Button from "../../../../../common/components/button/Button";
const PaymentSummary = () => {
  return (
    <div className={styles.paymentSummaryWrapper}>
      <PaymentModeCard
        paymentMode="COD"
        renderLabelValue={() => {
          return (
            <>
              <PaymnentModeLabelValue
                label="Estimated subtotal"
                value="AED  140.00"
              />
              <PaymnentModeLabelValue
                label="Delivery charges"
                value="AED  50.00"
              />
              <PaymnentModeLabelValue
                label="VAT charges (05%)"
                value="AED  9.75"
              />
            </>
          );
        }}
      />
      <PaymentModeCard
        paymentMode="online"
        renderLabelValue={() => {
          return (
            <>
              <PaymnentModeLabelValue
                label="Estimated subtotal"
                value="AED  140.00"
              />
              <PaymnentModeLabelValue
                label="Delivery charges"
                value="AED  50.00"
              />
              <PaymnentModeLabelValue
                label="VAT charges (05%)"
                value="AED  9.75"
              />
            </>
          );
        }}
      />
      <div className={styles.totalCartAmountWrapper}>
        <img src={amountIcon} alt="amount icon" />
        <div>
          <p className={styles.amountText}>
            <span className={styles.currency}>AED</span> 438.50
          </p>
          <p className={styles.estimatedText}>Estimated total to pay</p>
        </div>
      </div>
      <div className={styles.payBtn}>

      <Button handleClick={() => {}} label="Pay online" />
      </div>
    </div>
  );
};

export default PaymentSummary;
