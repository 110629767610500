import { useDispatch } from "react-redux";
import Button from "../button/Button";
import { logoutUser } from "../../../reducerSlices/loginInSlice";
import {
  setIsBusinessSkipped,
  setSearchValue,
} from "../../../reducerSlices/headerSlice";
import styles from "./SessionExpired.module.scss";
import { useContext, useEffect } from "react";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { TokenContext } from "../../../App";
const SessionExpired = () => {
  const dispatch = useDispatch();
  const contextValue: any = useContext(TokenContext);
  const login = () => {
    dispatch(logoutUser());
    localStorage.removeItem("loggedInUser");
    localStorage.clear();
    dispatch(setIsBusinessSkipped("false"));
    dispatch(setSearchValue(""));
    contextValue.removeSessionToken();
  };
  useEffect(() => {
    dispatch(updateIsLoading(false));
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.txt}>
        The current user session has expired, likely due to an invalid or missing token. Please log in again.
      </div>
      <div className={styles.btn}>
        <Button label={"Login"} handleClick={login} />
      </div>
    </div>
  );
};
export default SessionExpired;
