import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

export interface userTypeState {
  user: any;
  loading: boolean;
  error: string | null;
  cartId?: string | null;
}

const initialState: userTypeState = {
  user: null,
  loading: false,
  error: null,
  cartId: null,
};

// interface FetchUserPayload {
//   email: string;
//   password: string;
// }
// const buyers = ["buyer-admin", "buyer-staff", "buyer-manager"];
// const buyers = ["supplier", "supplier", "supplier"];
// const buyers = ["admin"];
// export const fetchUser = createAsyncThunk(
//   "user/fetchUser",
//   async (payload: FetchUserPayload) => {
//     const { email, password } = payload;
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_API_URL}store/auth`,
//         {
//           email,
//           password,
//         }
//       );
//       localStorage.setItem(
//         "loggedInUser",
//         JSON.stringify({
//           ...response?.data?.user,
//           typeOfUser: buyers,
//         })
//       );
//       return {
//         ...response?.data,
//         typeOfUser: buyers,
//       };
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const loginSlice = createSlice({
  name: "loginReducer",
  initialState: initialState,
  reducers: {
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    updateCartId: (state, action) => {
      state.cartId = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchUser.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(fetchUser.fulfilled, (state, action) => {
  //       state.user = action.payload;
  //       state.loading = false;
  //       state.error = null;
  //     })
  //     .addCase(fetchUser.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error =
  //         action.error.message || "An error occurred while fetching user data.";
  //     });
  // },
});

export const { logoutUser, saveUser, updateCartId } = loginSlice.actions;
export default loginSlice.reducer;
