import React, { useEffect, useState } from "react";
import Styles from "./notifications.module.scss";
import { useAxios } from "../../../services/useAxios";
import { constants } from "../../../common/constants/constants";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import { getIcon } from "../../../reducerSlices/manageOrdersList";
import { getRouteParams, getTimeReceived } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/components/loader/Loader";
import { APIMethods } from "../../../common/constants/urlConstants";

const Notifications = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") as any);
  const customerId = loggedInUser?.business_details?.[0]?.customer_id;
  const [notificationsList, setNotificationsList] = useState([]);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [isLoading, setIsLoading] = useState(false);

  //api to get the notifications list
  const getNotificationsList = useAxios({
    axiosParams: {
      url: `store/notifications/${customerId}`,
    },
    method: "GET",
  });

  //api to update the read status of the notification on clicking notification card
  const updateNotificationreadStatus = useAxios({
    axiosParams: {
      url: "store/notifications/",
    },
    method: "PATCH"
  });

  //this function is used to sort the notifications based on their read status and date
  const sortNotificationsList = (list: any) => {
    return list.slice().sort((a: any, b: any) => {
      if (!a.readStatus && b.readStatus) {
        return -1;
      } else if (a.readStatus && !b.readStatus) {
        return 1;
      } else {
        return b.date - a.date;
      }
    });
  };
  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      let response: any = await getNotificationsList.fetchData();
      let list = response?.data?.notifications?.map((ele: any) => {
        return {
          ...ele,
          id: ele?.id,
          type: ele?.metadata?.data?.Status,
          logo: getIcon(ele?.metadata?.data?.Status?.toLowerCase()),
          time: getTimeReceived(ele?.created_at),
          desc: ele?.metadata?.data?.inapp_Message,
          readStatus: ele?.read_status,
        };
      });
      setIsLoading(false);
      setNotificationsList(sortNotificationsList(list));
    } catch (error) {
      setIsLoading(false);
      setToastData({
        message: "Unable to fetch the notifications.",
        status: "Fail",
      });
    }
  };
  useEffect(() => {
    if (customerId) {
      fetchNotifications();
    }
  }, []);
  const navigate = useNavigate();
  const onNotificationClick = async (item: any) => {
    setIsLoading(true);
    if (!item?.readStatus) {
      try {
        const url = `store/notifications/${item?.id}/${customerId}`;
        const response: any = await updateNotificationreadStatus.fetchData({
          axiosParams: {
            url: url,
          },
          method: APIMethods.post,
        });
        if (response?.status === "SUCCESS") {
          const { routeName } = getRouteParams(item?.metadata?.data);
          navigate(routeName);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e, "notifystatusupdate");
        setIsLoading(false);
      }
    } else {
      const { routeName } = getRouteParams(item?.metadata?.data);
      navigate(routeName);
      setIsLoading(false);
    }
  };
  return (
    <>
      <section className={Styles.notificationBody}>
        <Loader isLoading={isLoading} />
        {!isLoading && notificationsList?.length > 0 ? (
          notificationsList?.map((item: any) => (
            <div
              className={`${
                item.readStatus
                  ? Styles.readNotificationBlock
                  : Styles.unreadNotificationBlock
              }`}
              onClick={() => onNotificationClick(item)}
            >
              <div className={Styles.notificationDesc}>
                <div>
                  <img src={item?.logo} alt="" />
                </div>
                <div
                  className={`${
                    item.readStatus ? Styles.readText : Styles.descriptionText
                  }`}
                >
                  {item?.desc}
                </div>
              </div>

              <div className={Styles.notiificationTimeline}>
                <div className={Styles.timelineTime}>{item?.time}</div>
                <div className={Styles.timelineBorder}></div>
              </div>
            </div>
          ))
        ) : (
          <>
            {!isLoading && (
              <div className={Styles.noNotifications}>No notifications</div>
            )}
          </>
        )}
      </section>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};

export default Notifications;
