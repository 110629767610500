import CartProducts from "./cartProducts";
import styles from "./myCart.module.scss";
import EmptyCart from "./cartProducts/emptyCart";
import { useSelector } from "react-redux";
import BackNavigationBtn from "./components/backNavigationBtn";
import { useNavigate } from "react-router-dom";
import CartSummary from "./cartSummary";
const MyCart = () => {
  const { data } = useSelector((state: any) => state.myCart);
const navigate = useNavigate()
  return (
    <div className={styles.myCartPageWrapper}>
    <BackNavigationBtn handleBack={() => {
      navigate(-1)
    }} text="My cart (0 items)" />
      {data?.supplier_products?.length === 0 ? (
        <EmptyCart />
      ) : (
        <div className={styles.myCartWrapper}>
          <CartProducts />
          <CartSummary />
        </div>
      )}
    </div>
  );
};

export default MyCart;
