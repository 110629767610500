import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import outOfStock from "../../../../../assets/images/Mark as out of stock.svg";
import inStock from "../../../../../assets/images/markInStock.svg";
import activate from "../../../../../assets/images/activate.svg";
import remove from "../../../../../assets/images/cancelToast.svg";
import deactivate from "../../../../../assets/images/deactivate.svg";
import view from "../../../../../assets/images/viewDetails.svg";
import { IProductListingCard } from "../../../../../models/IProductListingCard";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import emptyImage from "../../../../../assets/images/emptybox.svg";
import {
  removeProduct,
  updateCatalogueProductStatus,
} from "../../../../../reducerSlices/manageCatalogueList";
import successToast from "../../../../../assets/images/successToast.svg";
import errorToast from "../../../../../assets/images/errorToast.svg";
import { useAxios } from "../../../../../services/useAxios";
import { constants } from "../../../../constants/constants";
import styles from "./ProductListingCardStyles.module.scss";
import deleteIcon from "../../../../../assets/images/deleteIcon.svg";
import { APIMethods } from "../../../../constants/urlConstants";
import { ToastNotification } from "../../../../components/toastNotification/ToastNotification";
import PopperComponent from "../../../../components/PopperComponent/PopperComponent";
import RowActions from "../../../../components/rowActions/RowActions";
import DeletePopup from "../../../../components/deletePopup";
import { setToastStatusApproval } from "../../../../../reducerSlices/toastStatusSlice";
import { isImgUrlValid } from "../../../../../utils/functions";
import CheckboxButton from "../../../../components/checkbox/CheckboxButton";
export const ProductListingCard = ({
  order,
  showMenu,
  handleShowMenu,
  onProductDelete,
  isNewProductTab = false,
  isProposedTab = false,
  isPendingTab = false,
  isItemChecked = false,
  onCheckBoxSelected,
}: IProductListingCard) => {
  const dispatch = useDispatch();
  const {
    productId,
    productTitle,
    productStatus: productCardState,
    // productHandle,
    // productCountryId: orderingOption,
    countryDisplayName,
    productSubCategoryName,
    productCategoryName,
    iamgeUrl,
    countryFlag,
    productAvailabilityState,
    productIsDeactivated,
    // orderingOptionUnitName,
    productType,
    businessName,
    productBrandName,
  } = order;

  const [image, setImage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const productCardStateUpdated =
    productCardState === "published"
      ? "Active"
      : productCardState === "rejected"
      ? "Rejected"
      : productCardState === "draft"
      ? "In Review"
      : productCardState === "proposed"
      ? "proposed"
      : "Inactive";
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [productStatusValue, setProductStatusValue] = useState(
    productCardStateUpdated
  );
  const isApproval = window.location.pathname.includes(
    "/admin/products-approval"
  );
  const [updateProductActionData, setUpdateProductActionData] = useState<any>(
    {}
  );
  const actionShow =
    showMenu?.id === productId && showMenu?.show ? true : false;

  const updateProduct = useAxios({
    axiosParams: {
      url: `store/catalogue/updateproduct/${productId}`,
      data: updateProductActionData && updateProductActionData,
    },
    method: "POST",
  });
  const deleteProduct = useAxios({
    axiosParams: {
      url: `store/product/${productId}`,
    },
    method: APIMethods.delete,
  });

  const deleteProductAPI = async () => {
    if (productId) {
      dispatch(updateIsLoading(true));
      const reponse: any = await deleteProduct.fetchData();
      if (reponse && reponse?.status === "SUCCESS") {
        dispatch(removeProduct({ productId }));
        onProductDelete && onProductDelete();
        dispatch(updateIsLoading(false));
        dispatch(
          setToastStatusApproval({
            message: "Product has been deleted successfully.",
            status: "Success",
            type: "delete",
          } as any)
        );
      } else {
        // Show API failure message when the Response status is Failure
        setTimeout(() => {
          dispatch(
            setToastStatusApproval({
              message:
                reponse?.response?.data?.error_msg ?? "Something Went Wrong",
              status: "failure",
              type: "delete",
            } as any)
          );
          dispatch(updateIsLoading(false));
        }, 500);
      }
    }
  };
  const updateProductStatus = async () => {
    if (Object.keys(updateProductActionData).length > 0) {
      dispatch(updateIsLoading(true));
      const response: any = await updateProduct.fetchData();

      if (response) {
        if (response.status === "SUCCESS") {
          if (updateProductActionData.is_deactivated === true) {
            setProductStatusValue("Inactive");
            dispatch(
              updateCatalogueProductStatus({
                productIsDeactivated: updateProductActionData.is_deactivated,
                productId: productId,
              })
            );
          } else if (updateProductActionData.is_deactivated === false) {
            setProductStatusValue("Active");
            dispatch(
              updateCatalogueProductStatus({
                productIsDeactivated: updateProductActionData.is_deactivated,
                productId: productId,
              })
            );
          } else if (updateProductActionData.avaliabilty_status === false) {
            dispatch(
              updateCatalogueProductStatus({
                productAvailabilityState:
                  updateProductActionData.avaliabilty_status,
                productId: productId,
              })
            );
          } else if (updateProductActionData.avaliabilty_status === true) {
            dispatch(
              updateCatalogueProductStatus({
                productAvailabilityState:
                  updateProductActionData.avaliabilty_status,
                productId: productId,
              })
            );
          }
        }
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(false));
      }
    }
  };

  useEffect(() => {
    updateProductStatus();
  }, [updateProductActionData]);

  useEffect(() => {
    setProductStatusValue(productCardStateUpdated);
  }, [productCardStateUpdated]);

  const handleProductStatus = (actionLabel: string) => {
    let actionData: any = {};
    handleShowMenu && handleShowMenu(productId, false);
    if (actionLabel === constants.productListing.OUT_OF_STOCK) {
      actionData = { avaliabilty_status: false };
    } else if (actionLabel === constants.productListing.IN_STOCK) {
      actionData = { avaliabilty_status: true };
    } else if (actionLabel === constants.productListing.DEACTIVATE_PROD) {
      setProductStatusValue("Inactive");
      actionData = { is_deactivated: true };
    } else if (actionLabel === constants.productListing.ACTIVATE_PROD) {
      setProductStatusValue("Active");
      actionData = { is_deactivated: false };
    } else if (actionLabel === constants.productListing.REMOVE_PROD) {
      actionData = { deleted_at: true };
      setProductStatusValue("Rejected");
      dispatch(removeProduct({ productId }));
      onProductDelete && onProductDelete();
    } else if (actionLabel === constants.productListing.VIEW_PROD_DETAILS) {
      let user = localStorage.getItem("loggedInUser");
      if (user && JSON.parse(user)?.typeOfUser?.includes("admin")) {
        window.location.pathname.includes(
          "/admin/manage-catalogue/product-details/"
        )
          ? navigate(`/admin/manage-catalogue/product-details/${productId}`)
          : navigate(`/admin/products-approval/product-details/${productId}`);
      } else {
        navigate(`/supplier/catalogue/product-details/${productId}`);
      }
      return;
    } else if (actionLabel === constants.productListing.DELETE_PRODUCT) {
      setShowDeletePopup(true);
    }

    const handleActivateToast = () => {
      if (productIsDeactivated) {
        setToastData({
          message: "Product has been activated successfully.",
          status: "active",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      } else {
        setToastData({
          message: "Product has been deactivated successfully.",
          status: "deactive",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    };

    const handleStockToast = () => {
      if (productAvailabilityState === true) {
        setToastData({
          message: "Product has been set to Out of Stock",
          status: "outOfStock",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      } else {
        setToastData({
          message: "Product has been set to In Stock",
          status: "inStock",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      }
    };

    if (Object.keys(actionData).length > 0) {
      setUpdateProductActionData(actionData);
      // updateProductStatus();
      if (
        actionLabel === constants.productListing.ACTIVATE_PROD ||
        actionLabel === constants.productListing.DEACTIVATE_PROD
      ) {
        handleActivateToast();
      } else if (
        actionLabel === constants.productListing.IN_STOCK ||
        actionLabel === constants.productListing.OUT_OF_STOCK
      ) {
        handleStockToast();
      }
    }
  };

  const getActionItems = () => {
    if (
      productCardStateUpdated === constants.productListing.ACTIVE ||
      productCardStateUpdated === constants.productListing.REJECTED
    )
      return [
        { icon: view, label: constants.productListing.VIEW_PROD_DETAILS },
        {
          icon:
            productCardStateUpdated === constants.productListing.REJECTED
              ? remove
              : !productAvailabilityState
              ? inStock
              : outOfStock,
          label:
            productCardStateUpdated === constants.productListing.ACTIVE
              ? !productAvailabilityState
                ? constants.productListing.IN_STOCK
                : constants.productListing.OUT_OF_STOCK
              : constants.productListing.REMOVE_PROD,
        },
        {
          icon: productIsDeactivated ? activate : deactivate,
          label: productIsDeactivated
            ? constants.productListing.ACTIVATE_PROD
            : constants.productListing.DEACTIVATE_PROD,
        },
        {
          icon: deleteIcon,
          label: constants.productListing.DELETE_PRODUCT,
        },
      ];
    return [];
  };
  const onMouseEnter = () => {
    handleShowMenu && handleShowMenu(productId || "", true);
  };

  const onMouseLeave = () => {
    handleShowMenu && handleShowMenu(productId || "", false);
  };

  const navigate = useNavigate();
  const onCardClick = (e: any) => {
    if (e.target.type === "checkbox") {
      return;
    }
    let user = localStorage.getItem("loggedInUser");
    if (user && JSON.parse(user)?.typeOfUser?.includes("admin")) {
      if (productType === "offline") {
        if (productStatusValue === "proposed") {
          navigate(
            `/admin/products-approval/products-approval-form/${productId}`,
            {
              state: {
                offlineProduct: true,
              },
            }
          );
        } else {
          navigate(`/admin/products-approval/product-details/${productId}`, {
            state: {
              offlineProduct: true,
            },
          });
        }
      } else if (productStatusValue === "proposed") {
        navigate(
          `/admin/products-approval/products-approval-form/${productId}`,
          {
            state: {
              masterProduct: true,
            },
          }
        );
      } else {
        navigate(`/admin/products-approval/product-details/${productId}`);
      }
    } else {
      isNewProductTab
        ? navigate(`/supplier/catalogue/product-update/${productId}`, {
            state: {
              masterProduct: isNewProductTab,
            },
          })
        : navigate(`/supplier/catalogue/product-details/${productId}`);
    }
  };
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  const handleConfirmDelete = () => {
    setShowDeletePopup(false);
    deleteProductAPI();
  };
  const handleCancelDelete = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div
        className={`${styles.card} ${
          !productAvailabilityState &&
          productStatusValue !== "Inactive" &&
          styles.outOfStock
        }
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onCardClick}
      >
        <div
          className={`${styles.product} ${
            (productCardState === constants.productListing.ACTIVE ||
              productCardState === constants.productListing.PENDING) &&
            styles.activeProduct
          } ${styles.textOverflow}`}
        >
          {(isProposedTab || isPendingTab) && (
            <div style={{ zIndex: 1000 }}>
              <CheckboxButton
                checked={isItemChecked}
                handleChange={onCheckBoxSelected}
                customClass={styles.checkBoxStyles}
              />
            </div>
          )}
          <div className={styles.productImgContainer}>
            <img
              className={`${styles.productImg} ${
                (productStatusValue === "Inactive" || productIsDeactivated) &&
                styles.deactivateImg
              }`}
              src={isImgUrlValid(iamgeUrl) ? image || iamgeUrl : emptyImage}
              alt="product"
              onError={() => {
                setImage(emptyImage);
              }}
            />
          </div>

          <div
            className={`${styles.productName} ${styles.textOverflow} ${
              (productStatusValue === "Inactive" || productIsDeactivated) &&
              styles.inactive
            }`}
            title={productTitle}
          >
            {productTitle
              ?.split(" ")
              .map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" ") ?? "-"}
          </div>
          {productCardStateUpdated === "Active" && (
            <div
              className={`${
                productIsDeactivated ? styles.inActive : styles.active
              }`}
            >
              {/* {productStatusValue} */}
            </div>
          )}
          {isAdmin && productType === "offline" && (
            <span className={`${styles.offlineTag}`}>Offline</span>
          )}
        </div>
        <div
          className={`${styles.businessName} ${styles.textOverflow} ${
            (productStatusValue === "Inactive" || productIsDeactivated) &&
            styles.inactive
          }`}
          title={businessName}
        >
          {businessName
            ?.split(" ")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ") ?? "-"}
        </div>
        {/* <div
          className={`${styles.productId} ${styles.textStyle} ${styles.textOverflow}`}
          title={productHandle}
        >
          {productHandle}
        </div> */}
        {/* {!isApproval &&  <div
            className={`${styles.quantity} ${styles.textStyle} ${
              styles.orderUnits
            } ${styles.textOverflow} ${
              (productStatusValue === "Inactive" || productIsDeactivated) &&
              styles.inactive
            }`}
            title={orderingOptionUnitName}
          >
            {orderingOptionUnitName ?? "--"}
          </div> } */}
        <div
          className={`${styles.quantity} ${styles.textStyle} ${
            styles.textOverflow
          } ${
            (productStatusValue === "Inactive" || productIsDeactivated) &&
            styles.inactive
          }`}
          title={productBrandName}
        >
          {productBrandName
            ?.split(" ")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ") ?? "-"}
        </div>

        <div className={`${styles.country}`}>
          <div className={styles.countryFlag} title={countryDisplayName}>
            {countryFlag && <img src={countryFlag} alt="flag" />}
          </div>
          <div
            className={`${styles.textStyle} ${styles.textOverflow} ${
              (productStatusValue === "Inactive" || productIsDeactivated) &&
              styles.inactive
            }`}
          >
            {countryDisplayName ?? "-"}
          </div>
        </div>
        <div className={styles.productType}>
          <div
            className={`${styles.textStyle} ${styles.textOverflow} ${
              styles.productSubCategory
            } ${
              (productStatusValue === "Inactive" || productIsDeactivated) &&
              styles.inactive
            }`}
            title={productSubCategoryName}
          >
            {productSubCategoryName ?? "-"}
          </div>

          <div
            className={`${styles.productCategory} ${styles.textOverflow} ${
              (productStatusValue === "Inactive" || productIsDeactivated) &&
              styles.inactive
            }`}
            title={productCategoryName}
          >
            <span
              className={`${styles.separator} ${styles.textStyle} ${
                (productStatusValue === "Inactive" || productIsDeactivated) &&
                styles.inactive
              }`}
            >
              |
            </span>{" "}
            in {productCategoryName ?? "-"}
          </div>
        </div>
        {productCardState !== "draft" &&
          productCardState !== "proposed" &&
          actionShow &&
          !isApproval && (
            <PopperComponent>
              <div className={styles.actionItems}>
                {getActionItems().length > 0 && (
                  <RowActions
                    actions={getActionItems().filter((el: any) => {
                      if (isAdmin) {
                        if (el.label === constants.productListing.REMOVE_PROD) {
                          return false;
                        }
                      } else if (
                        productCardState !== "published" &&
                        el.label === constants.productListing.DELETE_PRODUCT
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    })}
                    handleAction={handleProductStatus}
                  />
                )}
              </div>
            </PopperComponent>
          )}
      </div>
      {showDeletePopup && (
        <DeletePopup
          open={showDeletePopup}
          popupContent="Are you sure you want to delete this product ?"
          title="Delete Product"
          handleClose={() => {
            setShowDeletePopup(false);
          }}
          handleCancel={handleCancelDelete}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {toastData?.message ? (
        <ToastNotification
          icon={toastData?.status === "failure" ? errorToast : successToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
};
