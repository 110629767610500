import delivery from '../../../assets/images/Delevery_notDone.svg';
import DeliveredDone from '../../../assets/images/Delivered_Done.svg';
import onTheWayDone from '../../../assets/images/OTW_Done.svg';
import ontheWay from '../../../assets/images/OTW_notstarted.svg';
import onTheWayProcessing from '../../../assets/images/OTW_process.svg';
import packingProcess from '../../../assets/images/Packageing_process.svg';
import confirmedProcess from '../../../assets/images/confirmedProcess.svg';
import confirmedDone from '../../../assets/images/confirmed_Done.svg';
import confirmed from '../../../assets/images/confirmed_notstarted.svg';
import packing from '../../../assets/images/packaging_notstarted.svg';
import packingDone from '../../../assets/images/packed_done.svg';
import placedInactive from "../../../assets/images/placedInactive.svg";
import placedDone from '../../../assets/images/placed_done.svg';
import placed from '../../../assets/images/placed_process.svg';
import  deliveryCancelledIcon from "../../../assets/images/orderStatusIcons/Cancelled.svg"
import { IPackageStatusProps } from '../../../models/IPackageStatusProps';
import styles from './PackageTracker.module.scss';
const trackingIcons = {
    placed:{
        icons:{
            completedIcon:placedDone,
            processIcon: placed,
            notStartIcon: placedInactive
        }      
    },
    confirmed:{
        icons: {  
            notStartIcon: confirmed,
            processIcon: confirmedProcess,
            completedIcon:confirmedDone,
        }
    },
    packed:{
        icons:{ 
            notStartIcon: packing,
            processIcon: packingProcess,
            completedIcon:packingDone,
        }
    },
    ontheway:{
        icons:{
            
            notStartIcon:ontheWay,
            processIcon: onTheWayProcessing,
            completedIcon:onTheWayDone,
            
        }
    },
    delivered:{
        icons:{
            notStartIcon: delivery,
            processIcon: DeliveredDone,
            completedIcon:DeliveredDone,
            cancelled: deliveryCancelledIcon
        }
    }
}

const statusEnum = {
    Active: 'active',
    Completed: 'completed',
    cancelled: 'cancelled'
}

function renderStatus(status:string, statusIcon: { notStartIcon:string, processIcon:string, completedIcon:string, cancelled?: string}){
    switch(status)
    {
        case statusEnum.Active:
            return(
                <img src={statusIcon?.processIcon} alt="order status icon" />
            )
        case statusEnum.Completed:
            return(
                <img src={statusIcon?.completedIcon} alt="order status icon" />
            )
        case statusEnum.cancelled:
            return (
                <img src={statusIcon?.cancelled ?? statusIcon?.notStartIcon} alt='order status icon'/>
            )
        default:
            return(
                <img src={statusIcon?.notStartIcon} alt="order status icon" />
            )
    }
    
}   

const OrderStatusCards = ({statusIcon, statusTitle,status, statusDetails}:IPackageStatusProps) =>{
    return(
        <div className={styles.statuscardConatiner}>
            <div className={styles.svgIcons}>
            {renderStatus(status,statusIcon)}
            </div>
            <div className={styles.orderStatusmessage}>
                <div className={`${styles.statusTitleContainer} ${status==="inactive" && styles.inactive}`}>{statusTitle}</div>
                <div className={styles.statusDetailsContainer}>{statusDetails}</div>
            </div>     
        </div>
    )
}

const PackageTracker = (orderStatus: any) =>{ 
     const {orderId, status, orderType } = orderStatus?.orderStatus;
     const placed = status[0];
     const confirmed = status[1];
     const packing = status[2];
     const ontheway = status[3];
     const delivered = status[4];
    return(
        <div>
            <div className={styles.packageTrackerConatiner} >
                <div className={styles.orderID}>Order ID : {orderId} {orderType === "Offline" && <span className={styles.statusChip}>{orderType}</span>}</div>
                <div className={styles.packageStatusTracker}>
                        <OrderStatusCards
                                statusIcon={trackingIcons.placed?.icons}
                                statusTitle={placed?.message}
                                status={placed?.status}
                                statusDetails={placed?.statusDetails}
                                />
                        <div className={styles.statusLine}></div>
                        <OrderStatusCards
                                statusIcon={trackingIcons.confirmed?.icons}
                                statusTitle={confirmed?.message}
                                status={confirmed?.status}
                                statusDetails={confirmed?.statusDetails}
                                />
                        <div className={styles.statusLine}></div>
                        <OrderStatusCards
                                statusIcon={trackingIcons.packed?.icons}
                                statusTitle={packing?.message}
                                status={packing?.status}
                                statusDetails={packing?.statusDetails}
                                />
                        <div className={styles.statusLine}></div>
                        <OrderStatusCards
                                statusIcon={trackingIcons.ontheway?.icons}
                                statusTitle={ontheway?.message}
                                status={ontheway?.status}
                                statusDetails={ontheway?.statusDetails}
                                />
                        <div className={styles.statusLine}></div>
                        <OrderStatusCards
                                statusIcon={trackingIcons?.delivered.icons}
                                statusTitle={delivered?.message}
                                status={delivered?.status}
                                statusDetails={delivered?.statusDetails}
                                />
                </div>
            </div>
        </div>
    )
}
export default PackageTracker;