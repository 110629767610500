import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAxios } from "../../../../services/useAxios";
import { useEffect, useRef, useState } from "react";
import { APIMethods, buyerEndpoints } from "../../../../common/constants/urlConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import Breadcrumb from "../../../../common/components/breadCrumb/BreadCrumb";
import { routeConfig } from "../../../../common/constants/routeConfig";
import styles from "./SubCategoryItemsListStyles.module.scss";
import SubCategoryCard from "./components/subCategoryCard/SubCategoryCard";
// import Filters from "./components/filters/Filters";
import Button from "../../../../common/components/button/Button";
import ProductCardWithCart from "../../../../common/components/productCardWithCart/ProductCardWithCart";
import { EmptyContainer } from "../../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import telescope from "../../../../assets/images/telescope.svg";
import sortIcon from "../../../../assets/images/lowerTriangle.svg";
import { constants } from "../../../../common/constants/constants";
const SubCategoryItemsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryId = '' } = useParams();
  const { activeOutlet } = useSelector((state:any)=> state);
  const [params, setParams] = useSearchParams();
  const categoryName  =  params.getAll('categoryName').join(',');
  console.log(setParams)
  const breadcrumbItems = [
    { label: "Marketplace", link: routeConfig.marketPlace },
    { label: `${categoryName}` },
  ];
  const [showSort, setShowSort] = useState(false);
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const [prevSelected, setPrevSelected] = useState(0);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(loggedInUser?.business_details?.[0]?.customer_id);
  const [subCategoryId, setSubCategoryId] = useState<string>(""); // selected subCategory id
  const [subCategoryName, setSubCategoryName] = useState<string>(""); // selected subCategory name
  const [subCategories, setSubCategories] = useState<any[]>([]); // contains list of subcategories
  const [subCategoryProducts, setSubCategoryProducts] = useState<any[]>([]);
  const [showEmptyContainer, setShowEmptyContainer] = useState<boolean>(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [cardsCount,setCardsCount] = useState(0);
  const getProductsByCategoryApi = useAxios({
    axiosParams: {
      url:`${buyerEndpoints.getCategoryProducts.replace(":customerId",customerId).replace(":customerBusinessId",activeOutlet?.id).replace(":categoryId",categoryId ?? '').replace(":subCategoryId",subCategoryId)}`
    },
    method: APIMethods.get,
  });
  const getCategoriesApi = useAxios({
    axiosParams: {
      url: buyerEndpoints.getCategories,
    },
    method: APIMethods.get,
  });
  const getSubCategories = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getCategoriesApi?.fetchData();
    if (response && response?.status === "SUCCESS") {
      const categories: any[] = response?.data?.categories;
      const subCategories = categories
        ?.find((cat) => cat?.id == categoryId)
        ?.sub_categories?.map((sub_cat: any) => ({
          id: sub_cat?.id,
          name: sub_cat?.name,
          image: sub_cat?.image,
        }));
      setSubCategories(subCategories);
      setSubCategoryId(subCategories?.[0]?.id);
      setSubCategoryName(subCategories?.[0]?.name);
      dispatch(updateIsLoading(false));
    }
  };
  const getProductsByCategoryList = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductsByCategoryApi?.fetchData();
    if (response && response?.status === "SUCCESS") {
      const data =
        response?.data?.products?.sub_categories?.[0]?.sub_sub_categories ?? [];
      if (data?.length > 0) {
        const filteredProducts = data?.filter(
          (each: any) => each?.products?.length > 0
        );
        // has some products
        if (filteredProducts?.length > 0) {
          setShowEmptyContainer(false);
          data?.sort((a:any, b:any) => b?.products?.length - a?.products?.length);
          setSubCategoryProducts(data);
        } else {
          setShowEmptyContainer(true);
          setSubCategoryProducts(data);
        }
      } else {
        setShowEmptyContainer(true);
        setSubCategoryProducts(data);
      }
      dispatch(updateIsLoading(false));
    }
  };
  const handleSubCategoryClick = (id: string,name:string) => {
    setSubCategoryId(id);
    setSubCategoryName(name);
  };
  const getProductImageUrl = (images: any[]) => {
    if (images?.length > 0) {
      const defaultImgs = images?.filter(
        (eachImage) => eachImage?.metadata?.default_image
      );
      if (defaultImgs?.length > 0) return defaultImgs?.[0]?.url;
      else return images?.[0]?.url;
    }
    return null;
  };
  const transformVariantToBundleOptions = (variants: any[]) => {
    if (variants?.length) {
      const options = variants?.map((variant: any) => ({
        id: variant?.id,
        value: variant?.id,
        name: `${variant?.metadata?.ordering_option_value} x ${variant?.metadata?.secondary_ordering_option_value} ${variant?.metadata?.secondary_ordering_option_label}`,
      }));
      return options;
    }
    return [];
  };
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const sortOptions = [
    {
      id: 0,
      label: 'What’s new',
      onClick: () => {},
    },
    {
      id: 1,
      label: 'Featured',
      onClick: () => {},
    },
    {
      id: 2,
      label: 'Prices Per Unit : Low to High',
      onClick: () => {},
    },
    {
      id: 3,
      label: '% Off: High to Low',
      onClick: () => {},
    },
    {
      id: 4,
      label: '% Off: Low to High',
      onClick: () => {},
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  const getCardValue = () => {
    if(width <=1000){
      setCardsCount(2);
    }
      else if (width > 1000 && width < 1280) {
      setCardsCount(3);
    } else if (width >= 1280 && width <1536) {
      setCardsCount(4);
    } else if (width >=1536 && width < 1920) {
      setCardsCount(5);
    } else {
      setCardsCount(6);
    }
  };
  useEffect(() => {
    getSubCategories();
  }, []);
  useEffect(() => {
    if (categoryId && subCategoryId && activeOutlet?.id && customerId) {
      setSubCategoryProducts([]);
      getProductsByCategoryList();
    }
  }, [activeOutlet?.id,categoryId, subCategoryId]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(()=>{
    getCardValue();
  },[width])
  console.log("ssssss",subCategoryProducts)
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Breadcrumb items={breadcrumbItems} />
        <div
          className={`${styles.sort}`}
          onClick={handleSort}
          ref={sortContainerRef}
          data-testid="invoice-sort-button"
        >
          <div className={styles.selectedSort}>
            <div className={styles.content}>
              <div className={styles.sortByText}>Sort by:</div>
              <div className={styles.selected}>
                {sortOptions[prevSelected]?.label}
              </div>
            </div>
            <div className="icon">
              <img src={sortIcon} alt="sort"></img>
            </div>
          </div>
          {showSort && (
            <div className={styles.sortList} data-testid="invoice-sort">
              <div className={styles.sortBy}>
                {constants.ordersListing.SORT_BY}
              </div>
              {sortOptions.map((option, index) => (
                <div
                  className={`${styles.sortOption} ${
                    index === sortOptions.length - 1 && styles.lastOption
                  } ${option.id === prevSelected && styles.selectedOpt}`}
                  onClick={() => handleSortingOptions(option.id)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          !showEmptyContainer ? styles.contentWrapper : styles?.emptyWrapper
        }`}
      >
        <div className={styles.subCategoriesList}>
          <div className={styles.container}>
            {subCategories?.map((subCat: any) => (
              <SubCategoryCard
                selectedSubCategoryId={subCategoryId}
                id={subCat?.id}
                name={subCat?.name}
                image={subCat?.image}
                handleSubCategoryClick={() =>
                  handleSubCategoryClick(subCat?.id, subCat?.name)
                }
              />
            ))}
          </div>
        </div>
        {!showEmptyContainer ? (
          <div className={styles.filtersAndProductsWrapper}>
            {/* <section className={styles.filters}>
              <Filters
                filtersList={filtersList}
                setFilteredList={setFilteredList}
              />
            </section> */}
            <section className={styles.subSubCategoryProducts}>
              {subCategoryProducts?.map(
                (eachSubSubCategory: {
                  sub_sub_category_id: string;
                  sub_sub_category_name: string;
                  products: any[];
                }) =>
                  eachSubSubCategory?.products?.length > 0 && (
                    <div className={styles.productsSection}>
                      <div className={styles.categoryHeader}>
                        <div
                          className={styles.categoryName}
                        >{`All ${eachSubSubCategory?.sub_sub_category_name}`}</div>
                        {eachSubSubCategory?.products?.length > 0 && (
                          <Button
                            link
                            label="View all"
                            customClass={styles.viewAll}
                            handleClick={() => {
                              // navigate(routeConfig.ProductsListAndFiltersPage, {
                              //   state: {
                              //     categoryId,
                              //     categoryName,
                              //     subCategoryId,
                              //     subCategoryName,
                              //     subSubCategoryId:
                              //       eachSubSubCategory?.sub_sub_category_id,
                              //   },
                              // });
                              navigate({
                                pathname:
                                  routeConfig.ProductsListAndFiltersPage,
                                search: createSearchParams({
                                  category_ids: [categoryId],
                                  categoryName: [categoryName],
                                  sub_category_ids:[subCategoryId],
                                  subCategoryName:[subCategoryName],
                                  sub_sub_categories_ids:[eachSubSubCategory?.sub_sub_category_id]
                                })?.toString(),
                              });
                            }}
                          />
                        )}
                      </div>
                      <div className={`${styles.products}`}>
                        {eachSubSubCategory?.products?.map(
                          (product: any, index: number) => {
                            if (index < cardsCount) {
                              return (
                                <ProductCardWithCart
                                  key={product?.id}
                                  id={product?.id}
                                  created_at={product?.created_at}
                                  supllierType={"online"}
                                  productName={product?.title}
                                  productImage={getProductImageUrl(
                                    product?.images
                                  )}
                                  country={{
                                    flag: product?.country_flag,
                                    name: product?.country_display_name,
                                  }}
                                  liked={product?.is_favorite}
                                  supplierName={
                                    product?.business_name
                                  }
                                  outOfstockStatus={
                                    !product?.avaliabilty_status
                                  }
                                  bundleOptions={transformVariantToBundleOptions(
                                    product?.variants
                                  )}
                                  variants={product?.variants}
                                  handleClick={() => {
                                    navigate(
                                      routeConfig?.buyerProductDetails?.replace(
                                        ":productId",
                                        product?.id
                                      )
                                    );
                                  }}
                                />
                              );
                            }
                          }
                        )}
                      </div>
                    </div>
                  )
              )}
            </section>
          </div>
        ) : (
          <div className={styles.emptyContainer}>
            <EmptyContainer
              imgUrl={telescope}
              title={"Oops!"}
              subText={"No products available"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SubCategoryItemsList;
