import React from "react";
import styles from "./ratingWithComment.module.scss";
import StarIcon from "../rating/Star";
import defaultImg from "../../../../../assets/images/supplierLogoDefault.svg";
const RatingWithComment = ({
  profileImg,
  reviewText,
  rating,
  profileName,
  // overallReview,
}: any) => {
  return (
    <div className={styles.reviewCommentWrapper}>
      <div className={styles.profileDetailsWrapper}>
        <div className={styles.profileImg}>
        <img src={profileImg ?? defaultImg} alt="profile" />
        </div>
        <div className={styles.profileNameRatingWrapper}>
          <div className={styles.profileNameWrapper}>
            <p className={styles.profileName}>{profileName}</p>
            {/* <p>{overallReview}</p> */}
          </div>
          <div className={styles.individualRatingWrapper}>
            {rating} <StarIcon filled noStroke className={styles.ratingIcon} />
          </div>
        </div>
      </div>
      <div className={styles.reviewText}>
        <p>{reviewText}</p>
      </div>
    </div>
  );
};

export default RatingWithComment;
