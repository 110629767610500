import { createSlice } from "@reduxjs/toolkit";

export interface userTypeState {
  value: string;
}

const initialState: userTypeState = {
  value: "buyer",
};

const userSlice = createSlice({
  name: "userTypeReducer",
  initialState: initialState,
  reducers: {
    setUserTypeGlobal: (state, action) => {
      state = action.payload;
    },
  },
});

export const { setUserTypeGlobal } = userSlice.actions;

export default userSlice.reducer;
