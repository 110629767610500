// import React from "react";
import "keen-slider/keen-slider.min.css";
import {
  KeenSliderInstance,
  KeenSliderPlugin,
  useKeenSlider,
} from "keen-slider/react";
import { MutableRefObject } from "react";
import { GlassMagnifier } from "react-image-magnifiers";
import styles from "./ProductCarouselZoom.module.scss";

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main: any) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

function ProductCarouselZoom(props: any) {
  const { imageData } = props;
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );
  if (imageData.length <= 0) return <></>;
  return (
    <section className={styles.productCarouselZoomContainer}>
      <div
        ref={sliderRef}
        className={`keen-slider ${styles.keen_slider__preview}`}
      >
        {imageData &&
          imageData?.map((image: any) => {
            return (
              <div
                className={`keen-slider__slide ${styles.imagePreviewContainer}`}
              >
                <GlassMagnifier
                  className={styles.imageWrapper}
                  largeImageSrc={image?.largeImageSrc}
                  imageAlt="Example"
                  imageSrc={image?.smallImageSrc}
                  square={true}
                  magnifierBorderSize={0.5}
                  magnifierSize={"30%"}
                  magnifierBorderColor="#ffffff"
                />
              </div>
            );
          })}
      </div>
      <div className={styles.ProductCarouselZoomText}>
        Zoom in! Just roll over the image.
      </div>
      <div className={styles.preview_divider}></div>
      <div ref={thumbnailRef} className={`keen-slider ${styles.thumbnail}`}>
        {imageData?.map((image: any) => {
          return (
            <div
              className={`keen-slider__slide ${styles.thumbnailImageContainer}`}
            >
              <img src={image?.smallImageSrc} alt=""></img>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default ProductCarouselZoom;
