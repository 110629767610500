export const productStatus = (status: string) => {
    switch (status) {
        case 'published':
            return 'Active'
        case 'draft':
            return 'In Review'
        case 'rejected':
            return 'Rejected'
        case 'proposed':
            return 'Out of Stock'
        default:
            return 'Active'
    }
}

export const splitDecimalValue = (value: string) => {
    console.log("fsdfs",value);
    return Number(value)
    .toFixed(2)
    .toString()
    .split(".");
}

export const calculateOfferValue = (orderAmount: number, off: number) => {
    const discountedAmount = (orderAmount * (100 - off)) / 100;
    const nonNegativeDiscountedAmount = Math.max(discountedAmount, 0);
    return nonNegativeDiscountedAmount?.toFixed(2);
};
