import styles from './SummaryActionOverlay.module.scss';
import OrderIcon from '../../../../../assets/images/OrderIcon.svg';
// import { useEffect, useRef} from 'react';
const SummaryActionOverlay = ({overlayData, id}: any) => {



    return (
        <>
            <div className={styles.overlayParent} data-testid={`overlaychild-${id}`}>

                {overlayData?.map((data:any)=>
                ( (id === 0) || (id === 1) || (id === 2) ? (<div className={styles.InfoContainer} key={data?.id}>
                    <div className={styles.infoDetails}  title='overlay-data'>
                        <div className={styles.imageCOntainer}>
                        <img src={OrderIcon} alt="order icon" />
                        </div>

                        <div className={styles.infoData}>
                            <div className={styles.productData}>{data?.orderId}</div>
                            <div className={styles.productInfo}>
                                <span>{data?.quantity}</span><span>items</span>|<span>{data?.aed}</span><span> AED</span>
                            </div>
                        </div>
                    </div>
                   {data.delayedDays && (id === 1) && <div className={styles.delayedText}>Delayed by <span>{data?.delayedDays}</span> {data?.delayedDays && data?.delayedDays > 1 ? (<span>days</span>):(<span>day</span>)}
                   </div>}
                </div>):
                (<div className={styles.InfoContainer} title="outstanding-amount" key={data?.id} >
                <div className={styles.infoDetails}>
                    <div className={styles.infoData}>
                        <div className={styles.productData}>{data?.businessName}</div>
                        <div className={styles.productInfo}>
                            <span>{data?.aed}</span><span>AED</span>|<span>Due by</span><span>{data?.dueDays}</span>{data?.dueDays && data?.dueDays > 1 ? (<span>days</span>):(<span>day</span>)}
                        </div>
                    </div>
                </div>
            </div>)))}
                
                <div className={styles.overlayFooter}>
                    <span></span>
                    {id===3 ?(<div className={styles.viewAllOrder} >View all</div>):(<div className={styles.viewAllOrder} >View all orders</div>)}
                    <span></span>
                </div>
            </div>  
        </>
    )
}

export default SummaryActionOverlay;