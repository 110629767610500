import { useEffect, useState } from "react";
import { IProductQuantityPickerProps } from "../../../models/IProductQuantityPickerProps";
import styles from "./ProductQuantityPicker.module.scss";

const ProductQuantityPicker = ({
  quantity,
  discount,
  isSupplier,
  moq,
  varaint,
  id,
  selectedId,
  onClick,
}: // toggleAttributes,
  IProductQuantityPickerProps) => {
  // const [select, setSelected] = useState(false);
  // const handle = () => {
  //   setSelected(!select);
  // };
  // useEffect(() => {
  //   console.log(select, "selected");
  // }, [select]);
  const [offerPrice, setOfferPrice] = useState<number>();
  const [showAttributes, setShowAttributes] = useState<boolean>(false);
  const [attributesData, setAttributesData] = useState<any>();
  //Offer value price calculation
  const getOfferValuePrice = () => {
    let offerValue =
      Number(varaint?.unitPrice * (100 - varaint?.metadata?.offer)) / 100;
    setOfferPrice(offerValue);
  };

  //To make first letter of key to upper case
  // const camelCase = (value: string) => {
  //   return value.charAt(0).toUpperCase() + value.slice(1);
  // };
  //filtering metadata to get attributes
  const makeAttributesData = () => {
    // let attrributes = [];
    // const metaDataFilter = [
    //   "offer",
    //   "vatPercentage",
    //   "orderingOptionId",
    //   "orderingOptionLabel",
    //   "orderingOptionValue",
    //   "secondayOrderingOptionId",
    //   "secondayOrderingOptionLabel",
    //   "secondayOrderingOptionValue",
    // ];
    // console.log(
    //   varaint?.metadata?.attributes,
    //   "metadata",
    //   Object.entries(varaint?.metadata?.attributes)
    // );
    const finalAttributes: any =
      varaint?.metadata?.attributes &&
      Object.entries(varaint?.metadata?.attributes).map(([key, value]) => ({
        [key]: value,
      }));
    if (finalAttributes?.length > 0) {
      setAttributesData(finalAttributes);
    }
  };

  useEffect(() => {
    getOfferValuePrice();
    makeAttributesData();
  }, []);
  const toggleAttributes = () => {
    setShowAttributes(!showAttributes);
  };
  return (
    <>
      <div
        className={`${styles.productQuantityContainer} ${!isSupplier && id === selectedId ? styles.isSelected : ""
          } ${!isSupplier && styles.cursor}`}
        key={varaint?.id}
        onClick={() => { isSupplier ? toggleAttributes() : onClick() }}
      >
        <div className={styles.subContainer}>
          <div className={styles.quantityAndPrice}>
            <div className={styles.quantityAndAed}>
              <div>{`${varaint?.metadata?.orderingOptionValue} x ${varaint?.metadata?.secondaryOrderingOptionValue} ${varaint?.metadata?.secondaryOrderingOptionLabel}`}</div>
              <div>|</div>
              <div>
                AED {""}
                {offerPrice?.toFixed(2)}
              </div>
              {isSupplier ? (
                <p className={`${styles.grossPrice}`}>
                  {discount > 0 && varaint?.unitPrice ? Number(varaint?.unitPrice)?.toFixed(2) : ""}
                </p>
              ) : null}
            </div>
          </div>
          <div className={styles.quantityAndDiscount}>
            {isSupplier ? (
              <div className={styles.quantity}>
                MOQ: {varaint?.minQuantity}{" "}
              </div>
            ) : null}{" "}
            {discount > 0 && (
              <>
                <div className={styles.line}>|</div>{" "}
                <div
                  className={
                    isSupplier ? styles.supplierDiscount : styles.discount
                  }
                >
                  {varaint?.metadata?.offer}% off
                </div>
              </>
            )}
            {attributesData && attributesData?.length > 0 && (
              <span
                // onClick={(e) => {e.stopPropagation();toggleAttributes()}}
                className={styles.arrowIcon}
              >
                {/* <img
                  src={showAttributes ? chevronUp : chevronDown}
                  alt="seperator"
                /> */}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductQuantityPicker;
