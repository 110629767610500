import styles from "./IndividualCustomerReview.module.scss";
import Star from "../../../assets/images/Star.svg";
import { ICustomerReviewProps } from "../../../models/ICustomerReviewProps";
import defaultImg from "../../../assets/images/supplierLogoDefault.svg"; 
import { useState } from "react";
const IndividualCustomerReview = ({imgSrc,customerName,rating,comments}:ICustomerReviewProps) => {
    const [showDefaultImg,setShowDefaultImg] = useState(false);
    return (
      <>
        <div className={styles.reviewContainer}>
          <div className={styles.top}>
            <div className={styles.profileImg}>
              <img
                src={showDefaultImg ? defaultImg : imgSrc}
                alt="customer"
                onError={() => setShowDefaultImg(!showDefaultImg)}
              />
            </div>
            <div className={styles.nameAndSummary}>
              <div className={styles.customerName}>{customerName}</div>
              {/* <h6 className={styles.summary}>{summary}</h6> */}
              <div className={styles.rating}>
                {rating} <img src={Star} alt="Star Icon" />
              </div>
            </div>
          </div>
          <div className={styles.bottom}>{comments}</div>
        </div>
      </>
    );
}

export default IndividualCustomerReview;