import styles from "./ProductReview.module.scss";
import IndividualCustomerReview from "../individualCustomerReview/IndividualCustomerReview";
import ProductAverageRatingSection from "../productAverageRatingSection/ProductAverageRatingSection";
const ProductReview = ({reviewData}: any) => {
  const customerReviews = reviewData?.data?.map((each:any)=>({
    customerName: each?.first_name + " " + each?.last_name,
    rating: each?.user_rating,
    comments: each?.comments,
    src: each?.images,
  }))  
     
  return (
    <>
      <div className={styles.reviewContainer}>
        {/* Average Rating Section */}
        <div className={styles.averageRatingSection}>
        <ProductAverageRatingSection rating={reviewData}/>
        </div>
        {/* Customer Review Section */}
        <div className={styles.customerReviewSection}>
          {customerReviews.map((customerReview: any) => (
            <IndividualCustomerReview
              imgSrc={customerReview.src}
              customerName={customerReview?.customerName}
              rating={customerReview?.rating}
              comments={customerReview.comments}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductReview;
