import axios from "axios";

const useFileUpload = () => {
  const uploadFile = async (file: any) => {
    if (!file?.name) return;
    try {
      const formData = new FormData();
      formData.append("files", file);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}store/miniobucket/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        return response?.data;
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    uploadFile,
  };
};

export default useFileUpload;
