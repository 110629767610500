import { useEffect, useRef, useState } from "react";
import Button from "../../components/button/Button";
import styles from "./SpecialPricingStyles.module.scss";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import SpecialPricingList from "./components/specialPricingList/SpecialPricingList";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import infoIconBlack from "../../../pages/buyer/myCart/images/infoIconBlack.svg";
const SpecialPricing = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popperRef = useRef<any>();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  useEffect(() => {
    if (popperRef.current) {
      setAnchorEl(popperRef.current);
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, 5000);
  }, []);
  const [isEmpty, setIsEmpty] = useState(false);
  return (
    <>
      <div className={styles.specialPricing}>
        <div className={styles.right}>
          <div className={styles.header}>
            <div className={styles.leftPart}>
              <div className={styles.heading}>Special pricing list</div>
             {!isEmpty &&<> 
             <div className={styles.editcelltext}>
                <ClickAwayListener
                  onClickAway={() => {
                    setAnchorEl(null);
                  }}
                >
                  <div
                    onClick={handleClick}
                    ref={popperRef}
                    className={styles.textCenter}
                  >
                    <img
                      src={infoIconBlack}
                      width="18px"
                      height="18px"
                      alt="info"
                    />
                    Info
                  </div>
                </ClickAwayListener>
              </div>
              <div>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement="right-end"
                  className={styles.popper}
                >
                  <Paper className={styles.popperPaper}>
                    <div className={styles.condition}>
                      <span className={styles.font}>
                        * Double click to edit the price or order value
                      </span>
                    </div>
                  </Paper>
                </Popper>
              </div>
              </>}
            </div>
            <Button
              label={"Create"}
              handleClick={() =>
                isAdmin
                  ? navigate(routeConfig.AdminSpecialPricingRequest)
                  : navigate(routeConfig.supplierSpecialPricingRequest)
              }
              customClass={styles.rightPart}
            />
          </div>
          <SpecialPricingList setIsEmpty={setIsEmpty}/>
        </div>
      </div>
    </>
  );
};
export default SpecialPricing;
