import styles from "./TaskNeedAttention.module.scss";
import OrderIcon from "../../../assets/images/OrderIcon.svg";

const TaskNeedAttention = ({ tasksData }: any) => {
  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.cardContainer}>
          {tasksData?.map((data: any) => (
            <div
              className={styles.InfoContainer}
              key={data?.id}
              data-testid="task-card"
            >
              <div className={styles.infoDetails} title="overlay-data">
                <div className={styles.imageCOntainer}>
                  <img
                    src={OrderIcon}
                    alt="order icon"
                    data-testid="task-icon"
                  />
                </div>

                <div className={styles.infoData}>
                  <div className={styles.productData}>{data?.taskDetails}</div>
                  <div className={styles.productInfo}>
                    <span>{data?.timeofupdate}</span>
                    <span>{data?.unitOfTime}</span>
                  </div>
                </div>
              </div>
              <div className={styles.orderAction}>
                <div className={styles.spearator}></div>
                {data?.taskAction}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TaskNeedAttention;
