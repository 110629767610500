import styles from "./OrderShippedCard.module.scss";
import image from "../../../../assets/images/orderShipped.svg";
const OrderShippedCard = () => {
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={image} alt="Shippedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          <div className={styles.cardTitle}>Order shipped!</div>
          <div className={styles.cardSubTitle}>
            Sit back & relax, the order is on the move! It's been shipped and is
            now out for delivery.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderShippedCard;
