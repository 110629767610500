import { useEffect, useState } from "react";
import styles from "./BuyerBusinessApprovalPage.module.scss";
import { TabComponent } from "./components/tabsComponent/Tabs";
import { constants } from "../../../common/constants/constants";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetToastStatus } from "../../../reducerSlices/toastStatusSlice";
export const BuyerBusinessApprovalPage = () => {
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const { toastStatus } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (toastStatus?.message?.length > 0 && toastStatus?.type === "buyer") {
      setToastData({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setToastData(constants.defaultToastData);
      }, 2000);
    }
  }, [dispatch, toastStatus]);

  return (
    <>
      <div className={styles.approvalPage}>
        {/* <div className={styles.sideNav}>
          <AdminSideNav />
        </div> */}
        <div className={styles.right}>
          <div className={styles.heading}>Business Approval - Buyer</div>
          <TabComponent cardType="buyer" />
        </div>
      </div>
      {toastData?.message != "" ? (
        <ToastNotification
          icon={
            toastData.status === "approved"||"rejected" || "edited" || "created"
              ? successToast
              : errorToast
          }
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      ) : (
        <></>
      )}
    </>
  );
};
