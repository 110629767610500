import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cartHeaderIcon from "../../../assets/images/headerCart.svg";
import cartHeaderHoverIcon from "../../../assets/images/headerCartHover.svg";
import dropDownIcon from "../../../assets/images/location.svg";
import wishlistHeaderIcon from "../../../assets/images/wishlish-header.svg";
import wishListHoverIcon from "../../../assets/images/wishlistHeaderhover.svg";
import { HeaderDropdownOptions, HeaderProps } from "../../../models/IHeader";
import Dropdown from "../formFields/dropdown/dropdown";
import Search from "../search/Search";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import { setActiveOutlet } from "../../../reducerSlices/activeOutletSlice";

// const countryDropdownOptions: HeaderDropdownOptions[] = [
//   {
//     id: 1,
//     label: "Al-Baraha",
//     value: "Al-Baraha",
//     name: "Al-Baraha",
//   },
//   {
//     id: 2,
//     label: "India",
//     value: "India",
//     name: "India",
//   },
//   {
//     id: 3,
//     label: "USA",
//     value: "USA",
//     name: "USA",
//   },
//   {
//     id: 4,
//     label: "UK",
//     value: "UK",
//     name: "UK",
//   },
// ];
const allCategoriesDropdownOptions: HeaderDropdownOptions[] = [
  {
    id: 1,
    label: "All Categories",
    value: "All Categories",
    name: "All Categories",
  },
  {
    id: 2,
    label: "Fruits",
    value: "Fruits",
    name: "Fruits",
  },
  {
    id: 3,
    label: "Vegetables",
    value: "Vegetables",
    name: "Vegetables",
  },
  {
    id: 4,
    label: "Meat",
    value: "Meat",
    name: "Meat",
  },
  {
    id: 5,
    label: "Seafood",
    value: "Seafood",
    name: "Seafood",
  },
];

const Header = ({
  searchSuggestions,
  setSearchSuggestions,
  customHeaderStyles,
  userType,
  placeholder,
}: HeaderProps) => {
  const [checkDashboard, setCheckDashboard] = useState(false);
  const dispatch = useDispatch();
  const cartValue =
    // useSelector((state:any)=>state?.header?.cartValue)
    Number(localStorage.getItem("cartCount"));
  // const searchHistorys = useSelector(
  //   (state: any) => state.header.searchHistory
  // );
  // console.log(searchHistorys);
  const navigate = useNavigate();
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any) ?? {}
  );
  const [isWishlistHover, setIsWishlistHover] = React.useState(false);
  const [isCartHover, setIsCartHover] = React.useState(false);
  const [outlets] = useState(
    getTransformedOutlets(loggedInUser?.business_details)
  );
  const [value, setValue] = React.useState(
    JSON.parse(localStorage?.getItem("activeOutlet") as any)
  );
  const [searchDropdownValue, setSearchDropdownValue] = React.useState(
    allCategoriesDropdownOptions[0]
  );
  const handleDropdownChange = (value: any) => {
    localStorage?.setItem("activeOutlet", JSON?.stringify(value));
    localStorage?.setItem("cartCount", JSON.stringify(value?.cartCount));
    dispatch(setActiveOutlet(value));
    setValue(value);
    window.location.reload();
  };

  const handleCategoryDropdownChange = (value: HeaderDropdownOptions) => {
    setSearchDropdownValue(value);
  };
  const handleCart = () => {
    navigate(routeConfig.myCart);
  };
  function getTransformedOutlets(businessStores: any) {
    const outlets = businessStores?.map((each: any) => ({
      id: each?.id,
      value: each?.id,
      cartId: each?.cart_id,
      name: each?.saved_as,
      isDefault: each?.is_default,
      cartCount: each?.cart_count ?? 0,
      businessStoreId: each?.business_store_id,
    }));
    return outlets;
  }
  useEffect(() => {
    if (localStorage?.getItem("activeOutlet")) {
      dispatch(
        setActiveOutlet(
          JSON.parse(localStorage?.getItem("activeOutlet") as any)
        )
      );
    }
  }, []);
  useEffect(() => {
    setCheckDashboard(window.location.pathname.includes("/supplier/dashboard"));
  }, [window.location.pathname]);

  return (
    <div
      className={styles.container}
      style={customHeaderStyles && { ...customHeaderStyles }}
    >
      {userType !== "supplier" && (
        <div className={styles.dropdownContainer}>
          <Dropdown
            id="countryDropdown"
            margin="0"
            icon={dropDownIcon}
            onChange={handleDropdownChange}
            minWidth="133px"
            label=""
            selectedValue={value}
            options={outlets}
            defaultLabel=""
            isDropdownOutlineRequired={true}
            customClass="countryDropdown"
            isOutlets={true}
          />
        </div>
      )}
      {checkDashboard && (
        <div className={styles.searchContainer}>
          <Search
            searchDropdownValue={searchDropdownValue}
            setSearchDropdownValue={setSearchDropdownValue}
            searchDropdownOptions={allCategoriesDropdownOptions}
            handleCategoryDropdownChange={handleCategoryDropdownChange}
            showDropdown={userType !== "supplier"}
            placeholder={placeholder}
            showSeperator={true}
          />
        </div>
      )}
      {userType !== "supplier" && (
        <div className={styles.ctaContainer}>
          <div
            className={styles.wishlist}
            onMouseOver={() => setIsWishlistHover(true)}
            onMouseLeave={() => setIsWishlistHover(false)}
          >
            {isWishlistHover ? (
              <img src={wishListHoverIcon} alt="wishlist-icon-hover" />
            ) : (
              <img src={wishlistHeaderIcon} alt="wishlist-icon" />
            )}
          </div>
          <div
            data-testid="cart"
            className={styles.cart}
            onClick={handleCart}
            onMouseOver={() => setIsCartHover(true)}
            onMouseLeave={() => setIsCartHover(false)}
          >
            {isCartHover ? (
              <img src={cartHeaderHoverIcon} alt="cart-icon-hover" />
            ) : (
              <img src={cartHeaderIcon} alt="cart-icon" />
            )}
            {cartValue > 0 && (
              <div data-testid="cartValue" className={styles.badge}>
                {cartValue}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
