/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import businessSetupIcon from "../../../../assets/images/businessSetupIcon.svg";
import Breadcrumb from "../../../../common/components/breadCrumb/BreadCrumb";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import FileUploadCard from "../../../../common/components/fileUploadCard/FileUploadCard";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../../common/components/formFields/mobileNumberField/mobileNumberField";
import {
  DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO,
  constants,
} from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import useFileUpload from "../../../../services/useUploadImage";
import useFetchCountries from "../../../../services/useCountry";
import { ISupplierSetupFormData } from "../../../../models/ISupplierSetupFormData";
import { useAxios } from "../../../../services/useAxios";
import styles from "../../../supplier/onBoardingDashboard/businessSetupForm/BusinessSetupForm.module.scss";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
// import { MARK_AS_DEFAULT } from "../../../../common/constants/constants";
import { OnBoardingIntro } from "../../../../common/components/CoachMarks/caochMarksHelper";
import {
  setIsBusinessSkipped,
  // setIsSetupCompleted,
} from "../../../../reducerSlices/headerSlice";
// import cancel from "../../../../assets/images/cancelToast.svg";
import AddOutlet from "../../components/AddOutlet";
import OutletCard from "../../components/OutletCard";
// import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import DeletePopup from "../../../../common/components/deletePopup";

function BuyerBusinessSetupForm() {
  const { countriesList } = useFetchCountries();
  // const [logoImageFile, setLogoImageFile] = useState("");
  // const [logoImageFileUrl, setLogoImageFileUrl] = useState("");
  // const [contractImageFile, setContractImageFile] = useState("");
  const [licenseFileError, setLicenseFileError] = useState(false);
  // const [contractFileError, setContractFileError] = useState(false);
  const [vatFileError, setVatFileError] = useState(false);
  // const [contractImageFileUrl, setContractImageFileUrl] = useState("");
  const [licenseImageFile, setLicenseImageFile] = useState("");
  const [licenseImageFileUrl, setLicenseImageFileUrl] = useState("");
  // const [ibanValid, setIbanValid] = useState<string>("");
  const [vatImageFile, setVATImageFile] = useState("");
  const [vatImageFileUrl, setVATImageFileUrl] = useState("");
  const navigate = useNavigate();
  const [showAddOutlet, setShowAddoutlet] = useState(false);
  const [showAddOutletButton, setShowAddoutletButton] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  // const [deliveryLocations, setDeliveryLocations] = useState<{ id: string }[]>(
  //   []
  // );
  const [currentUser] = useState(
    JSON.parse(localStorage?.getItem("loggedInUser") as any)
  );
  const [finalValues, setFinalValues] = useState<any>();
  const breadcrumbItems = [{ label: "Edit account details" }];
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [outletName, setOutletName] = useState<String>();
  const loggedInUser: any =
    JSON.parse(localStorage.getItem("loggedInUser") || "{}") ||
    useSelector((state: any) => state.loginUserDetails?.user);
  const [hovered, setHovered] = useState("");
  // const [checked, setChecked] = useState(true);
  const [editOutletData, setEditOutletData] = useState<any>();
  const [outletNames, setOutletNames] = useState();
  const [finalOutletsPayload, SetFinalOutletsPayload] = useState<any>();
  const [editingOutlet, setEditingOutlet] = useState(false);
  const [editingBuyer, setEditingBuyer] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const schema = yup
    .object({
      businessName: yup.string().required("Enter business name"),
      emailID: yup
        .string()
        .required("Enter email Id")
        .matches(
          constants.formaValidations.EMAIL_REGEX,
          "Enter valid email Id"
        ),
      logo: yup.mixed().optional().nullable(),
      licenseNo: yup.string().required("Enter license number"),
      licenseFile: yup.mixed().required("Upload License File"),
      contactName: yup.string().required("Enter contact name"),
      countryName: yup.object().required("Select country"),
      phNo: yup
        .number()
        .positive()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      vatNo: yup.string().optional().nullable(),
      vatFile: yup.mixed().optional().nullable(),
      address: yup.string().required("Enter address"),
      area: yup.string().required("Enter area name"),
      emirates: yup.object().required("Select emirates"),
      country: yup.object().required("select country"),
      outletName: yup.string().required("Enter outlet name"),
      // is_default: yup.boolean().optional(),
      // contract: yup.mixed().required("Upload signed contract file"),
    })
    .required();

  const location = useLocation();
  const userId = location?.state?.userID;
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    // control,
    getValues,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const values = getValues();

  const modifyData = () => {
    if (!editingBuyer) {
      let newFormData = {
        business_name: values?.businessName,
        contact_name: values?.contactName,
        email_id: values?.emailID,
        phone_number: `${(values?.countryName as any)?.dial_codes}#${
          values?.phNo
        }`,
        license_number: values?.licenseNo,
        license_file: licenseImageFileUrl && licenseImageFileUrl,
        vat_number: values?.vatNo,
        vat_file: vatImageFileUrl != "" ? vatImageFileUrl : null,
        outlets: [
          {
            contact_name: values?.contactName,
            phone_number: `${(values?.countryName as any)?.dial_codes}#${
              values?.phNo
            }`,
            saved_as: values?.outletName,
            address: values?.address,
            area: values?.area,
            emirate: {
              id: (values?.emirates as any)?.id,
            },
            country: {
              iso_2: (values?.country as any)?.iso_2,
            },
            id: `${values?.outletName}-${(values?.country as any)?.iso_2}`,
            is_default: true,
          },
        ],
      };
      setFinalValues(newFormData);
    }
  };

  useEffect(() => {
    if (
      // !currentBusinessData &&
      values?.emirates &&
      values?.country &&
      values?.address &&
      values?.area &&
      values?.outletName
    ) {
      modifyData();
      setShowAddoutletButton(true);
    }
  }, [selectedEmirate, outletName]);

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "countryName") {
        setValue(name, text);
      }
      if (name === "country" || name === "emirates") {
        setValue(name, text);
      }
      if (
        name === "logo" ||
        name === "licenseFile" ||
        name === "contract" ||
        name === "vatFile"
      ) {
        setValue(name, text);
      }
      if (name === "outletName") {
        setValue(name, text);
        setOutletName(text);
      }
      // if (name === "deliveryAddress") {
      //   setValue(name, text);
      //   const deliveryLocationsData = text?.map((deliveryLocation: any) => ({
      //     id: deliveryLocation?.id,
      //   }));
      //   setDeliveryLocations(deliveryLocationsData);
      // }
    },
    [setValue]
  );

  const [customerId] = useState(
    loggedInUser?.business_details?.[0]?.customer_id
  );
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );

  const [fetchedData, setFetchedData] = useState<any>({});
  const fetchCTACardStatus = useAxios({
    axiosParams: {
      url: `store/onboarding/buyer/${customerId}/${businessId}`,
    },
    method: "GET",
  });
  const fetchCTACardStatusAPI = async () => {
    if (customerId && businessId) {
      // dispatch(updateIsLoading(true));
      const response: any = await fetchCTACardStatus.fetchData();
      if (response && response?.status === "SUCCESS") {
        setFetchedData(response?.data);
      }
    }
  };

  useEffect(() => {
    // console.log(currentUser, "currentUser");
    // if (currentUser?.business_details?.[0]?.business_status !== "pending") {
    fetchCTACardStatusAPI();
    // }
  }, []);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getVatFiles = async () => {
      if (vatImageFile) {
        // dispatch(updateIsLoading(true));
        const response: any = await uploadFile(vatImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setVATImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getVatFiles();
  }, [vatImageFile]);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getLicenseFiles = async () => {
      if (licenseImageFile) {
        dispatch(updateIsLoading(true));
        const response: any = await uploadFile(licenseImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setLicenseImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLicenseFiles();
  }, [licenseImageFile]);

  // useEffect(() => {
  //   const { uploadFile } = useFileUpload();
  //   const getContractFiles = async () => {
  //     if (contractImageFile) {
  //       dispatch(updateIsLoading(true));
  //       const response: any = await uploadFile(contractImageFile);
  //       if (response) {
  //         dispatch(updateIsLoading(false));
  //         setContractImageFileUrl(response?.data[0]);
  //       } else {
  //         dispatch(updateIsLoading(false));
  //       }
  //     }
  //   };
  //   getContractFiles();
  // }, [contractImageFile]);

  // useEffect(() => {
  //   const { uploadFile } = useFileUpload();
  //   const getLogoFiles = async () => {
  //     if (logoImageFile) {
  //       dispatch(updateIsLoading(true));
  //       const response: any = await uploadFile(logoImageFile);
  //       if (response) {
  //         dispatch(updateIsLoading(false));
  //         setLogoImageFileUrl(response?.data[0]);
  //       } else {
  //         dispatch(updateIsLoading(false));
  //       }
  //     }
  //   };
  //   getLogoFiles();
  // }, [logoImageFile]);

  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates = selectedOption?.emirates?.map((emirate: ICountry) => ({
      ...emirate,
      value: emirate?.name,
    }));
    setEmiratesList(emirates);
  };
  const getFinalOutlets = (outlets: any) => {
    let newOutlets = null;
    if (
      outlets?.slice(1)?.filter((item: any) => item?.is_default === true)
        ?.length
    ) {
      outlets[0].is_default = false;
      newOutlets = [...outlets];
    } else {
      newOutlets = [...outlets];
    }

    const finalOutlets = newOutlets?.map((outlet: any, index: number) => ({
      is_default: outlet?.is_default,
      contact_name: outlet?.contact_name,
      address: outlet?.address,
      area: outlet?.area,
      phone_number: `${outlet?.phone_number}`,
      emirate: {
        id: (values?.emirates as any)?.id,
      },
      country: {
        iso_2: (values?.country as any)?.iso_2,
      },
      saved_as: outlet?.saved_as,
      is_base_address: index === 0,
    }));
    SetFinalOutletsPayload(() => finalOutlets);
    // return finalOutlets;
  };
  // console.log(finalValues, "finalValues");
  // // create a business starts
  const postFinalSupplierSetupData = useAxios({
    axiosParams: {
      url: `store/buyer/${
        loggedInUser && loggedInUser?.business_details?.[0]?.id
      }`,
      data: {
        business_name: values?.businessName,
        email_id: values?.emailID,
        license_number: values?.licenseNo,
        license_file: licenseImageFileUrl && licenseImageFileUrl,
        vat_number: values?.vatNo,
        vat_file: vatImageFileUrl != "" ? vatImageFileUrl : null,
        outlets:
          finalValues && finalValues?.outlets?.length > 0 && finalValues
            ? finalOutletsPayload
            : [],
      },
    },
    method: "POST",
  });
  // // create a business ends

  // get the current business starts
  const [currentBusinessData, setCurrentBusinessData] = useState<any>({});

  useEffect(() => {
    const getFlag: any =
      currentBusinessData &&
      currentBusinessData?.business_stores &&
      countriesList &&
      countriesList.length > 0 &&
      countriesList.filter(
        (country) =>
          country?.dial_codes ===
          (currentBusinessData?.business_stores &&
            (baseAddressCheck(
              currentBusinessData?.business_stores
            )?.phone_number?.split("#")[0] as any))
      )[0];
    if (getFlag) {
      setMobileNumberCode({
        dial_codes: getFlag?.dial_codes,
        flag: getFlag && getFlag?.flag,
      });
    } else {
      const constantFlag =
        countriesList &&
        countriesList.length > 0 &&
        countriesList.filter(
          (country) => country?.dial_codes === ("+971" as any)
        )[0];
      if (constantFlag) {
        setMobileNumberCode(constantFlag);
      }
    }
  }, [countriesList, currentBusinessData]);

  const modifyOutletsData = (outlets: any) => {
    let newData: any = [];
    outlets?.forEach((item: any) => {
      const modifiedOutlet = {
        address: item?.address?.address,
        area: item?.address?.area,
        is_default: item?.is_default,
        contact_name: item?.contact_name,
        phone_number: item?.phone_number,
        saved_as: item?.saved_as,
        emirate: item?.address?.emirate,
        country: item?.address?.country,
        id: item?.id,
      };
      newData.push(modifiedOutlet);
    });
    return newData;
  };

  const getCurrentBusiness = useAxios({
    axiosParams: {
      url: `store/buyer/${currentUser?.business_details?.[0]?.business_setup_id}`,
    },
    method: "GET",
  });

  const baseAddressCheck = (data: any) => {
    let baseAddress = data?.find((item: any) => item?.is_base_address === true);
    return baseAddress;
  };
  const getCurrentBussinessSetupData = useCallback(async () => {
    // console.log(currentUser, "currentUser", fetchedData);
    if (
      fetchedData?.business_setup_status === "review" ||
      fetchedData?.business_setup_status === "approved" ||
      fetchedData?.business_setup_status === "rejected"
    ) {
      const response: any = await getCurrentBusiness.fetchData();
      dispatch(updateIsLoading(true));
      if (response && response?.status === "SUCCESS") {
        setEditingBuyer(true);
        setTimeout(() => {
          dispatch(updateIsLoading(false));
        }, 1000);
        const businessData = response?.data;
        // setExistingOutletsLength(businessData?.business_stores?.length);
        setCurrentBusinessData(businessData);
        if (businessData) {
          setValue(
            "outletName",
            businessData?.business_stores &&
              baseAddressCheck(businessData?.business_stores)?.saved_as
          );
          setValue("businessName", businessData?.business_name);
          setValue(
            "contactName",
            businessData?.business_stores &&
              baseAddressCheck(businessData?.business_stores)?.contact_name
          );
          setValue(
            "countryName",
            businessData?.business_stores &&
              baseAddressCheck(
                businessData?.business_stores
              )?.phone_number?.split("#")[0]
          );
          setMobileNumberCode({
            dial_codes:
              businessData?.business_stores &&
              baseAddressCheck(
                businessData?.business_stores
              )?.phone_number?.split("#")[0],
          });
          setValue(
            "phNo",
            businessData?.business_stores &&
              baseAddressCheck(
                businessData?.business_stores
              )?.phone_number?.split("#")[1]
          );
          setValue("emailID", businessData?.email_id);
          setValue("licenseNo", businessData?.license_number);
          setValue("licenseFile", businessData?.license_file);
          setValue("vatFile", businessData?.vat_file);
          setValue("logo", businessData?.logo_file);
          setFinalValues((prev: any) => ({
            ...prev,
            outlets:
              businessData?.business_stores &&
              businessData?.business_stores?.length > 0 &&
              modifyOutletsData(businessData?.business_stores),
          }));
          // setValue("contract", businessData?.contract_file);
          // setValue("minimumOrderValue", businessData?.min_order_value);
          setValue(
            "address",
            businessData?.business_stores &&
              baseAddressCheck(businessData?.business_stores)?.address?.address
          );
          setValue(
            "area",
            businessData?.business_stores &&
              baseAddressCheck(businessData?.business_stores)?.address?.area
          );
          setValue("vatNo", businessData?.vat_number);
          setLicenseImageFileUrl(businessData?.license_file);
          setVATImageFileUrl(businessData?.vat_file);
        }
      }
    }
  }, [getCurrentBusiness]);

  useEffect(() => {
    getCurrentBussinessSetupData();
  }, [fetchedData]);

  const [mobileNumberCode, setMobileNumberCode] = useState<any>();
  // const [selectedLocations, setSelectedLocations] = useState<any>([]);

  useEffect(() => {
    if (currentUser?.business_details?.[0]?.business_setup_id) {
      let country = null;
      if (
        currentBusinessData &&
        currentBusinessData?.business_stores &&
        currentBusinessData?.business_stores[0]?.addresses &&
        currentBusinessData?.business_stores[0]?.addresses[0] &&
        currentBusinessData?.business_stores[0]?.addresses[0]?.country
      ) {
        country = countriesList?.find(
          (ele: any) =>
            ele?.iso_2 ===
            currentBusinessData?.business_stores[0]?.addresses[0]?.country
              ?.iso_2
        );
        if (country) {
          setSelectedCountry(country);
          setValue("country", country);
        }
      } else {
        const defaultCountry = countriesList?.find(
          (ele: any) => ele?.iso_2 === "ae"
        );
        if (defaultCountry) {
          console.log(defaultCountry, "defaultCountry");
          const list = defaultCountry?.emirates;
          const defaultEmiratesList = list.map((item: any) => ({
            ...item,
            value: item?.name,
          }));
          setSelectedCountry(defaultCountry);
          setEmiratesList(defaultEmiratesList);
          // setSelectedLocations(listWithValue);
          setValue("country", defaultCountry);
          const emirate: any = defaultCountry?.emirates?.find(
            (ele: any) =>
              ele?.id ===
              baseAddressCheck(currentBusinessData?.business_stores)?.address
                ?.emirate?.id
          );
          setSelectedEmirate(emirate);
          setValue("emirates", emirate);
        }
      }
      console.log(currentBusinessData, "currentBusinessData");
    }
  }, [countriesList, currentBusinessData]);

  // get the current business ends

  // update final buyer data starts
  const updateFinalBuyerData = useAxios({
    axiosParams: {
      url: `store/buyer/${
        loggedInUser && loggedInUser?.business_details?.[0]?.business_setup_id
      }`,
      data: {
        business_name: values?.businessName,
        email_id: values?.emailID,
        license_number: values?.licenseNo,
        license_file: licenseImageFileUrl && licenseImageFileUrl,
        vat_number: values?.vatNo,
        vat_file: vatImageFileUrl != "" ? vatImageFileUrl : null,
        existing_outlets:
          finalValues?.outlets?.length > 0 &&
          finalValues?.outlets?.filter(
            (item: any) => !item?.id?.includes("outlet")
          ),
        new_outlets:
          finalValues?.outlets?.length > 0 &&
          finalValues?.outlets?.filter((item: any) =>
            item?.id?.includes("outlet")
          ),
      },
    },
    method: "PUT",
  });

  useEffect(() => {
    if (isSubmitted) {
      if (
        !licenseImageFile &&
        !licenseImageFileUrl &&
        licenseImageFileUrl?.length === 0
      ) {
        setLicenseFileError(true);
      }
      if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
        setVatFileError(false);
      }
    }
  }, [errors, isSubmitted]);
  console.log(errors, "errors");
  const submit = async (data: ISupplierSetupFormData) => {
    console.log("submit");
    if (
      !licenseImageFile &&
      !licenseImageFileUrl &&
      licenseImageFileUrl.length === 0
    ) {
      setLicenseFileError(true);
    }
    const shouldMakeApiCalls =
      licenseImageFileUrl && licenseImageFileUrl.length > 0;

    if (shouldMakeApiCalls && !editingOutlet) {
      if (!editingBuyer) {
        dispatch(updateIsLoading(true));
        const response: any = await postFinalSupplierSetupData?.fetchData();
        if (response?.status === "SUCCESS") {
          localStorage.setItem("isBusinessSkipped", "true");
          dispatch(setIsBusinessSkipped("true"));
          dispatch(updateIsLoading(false));
          navigate(routeConfig.supplierDashboard);
        }
      } else {
        // console.log(finalValues, "updating", values);
        dispatch(updateIsLoading(true));
        finalValues.outlets[0].address = values?.address;
        finalValues.outlets[0].area = values?.area;
        finalValues.outlets[0].contact_name = values?.contactName;
        finalValues.outlets[0].country = values?.country;
        finalValues.outlets[0].emirate = values?.emirates;
        finalValues.outlets[0].saved_as = values?.outletName;
        finalValues.outlets[0].phone_number = `${
          (values?.countryName as any)?.dial_codes
        }#${values?.phNo}`;
        console.log(finalValues, "updating22222", values);

        const response: any = await updateFinalBuyerData?.fetchData();
        console.log(response, "Response");
        if (response?.status === "SUCCESS") {
          localStorage.setItem("isBusinessSkipped", "true");
          dispatch(setIsBusinessSkipped("true"));
          dispatch(updateIsLoading(false));
          navigate(routeConfig.supplierDashboard);
        }
        // if (!response) {
        //   dispatch(updateIsLoading(true));
        // }
        // dispatch(updateIsLoading(false));
      }
    }
    // }
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowAddoutlet(false);
    setEditingOutlet(false);
    setEditOutletData("");
  };

  const handleOutletAdd = (event: any) => {
    event?.preventDefault();
    let names = finalValues?.outlets?.map((item: any) => item?.saved_as);
    setOutletNames(names);
    setShowAddoutlet(!showAddOutlet);
    setEditingOutlet(false);
    setEditOutletData(null);
  };

  const outletExists = (id: string) => {
    console.log(finalValues, "finalValues");
    let editIndex = finalValues?.outlets?.findIndex(
      (item: any) => item?.id === id
    );
    console.log(editIndex, "editIndex");
    return editIndex;
  };

  // modifying outlets array on adding or editing the outlets
  const getOutletData = (data: any) => {
    console.log(data, "data");
    if (outletExists(data?.id) != -1) {
      if (data?.is_default) {
        finalValues?.outlets?.forEach((item: any) => (item.is_default = false));
        let newValues = finalValues;
        // newValues?.outlets?.push(data);
        newValues.outlets[outletExists(data?.id)] = data;
        setFinalValues(newValues);
        // setChecked(!checked);
      } else {
        let newValues = finalValues;
        newValues.outlets[outletExists(data?.id)] = data;
        // newValues?.outlets?.push(data);
        setFinalValues(newValues);
      }
    } else {
      if (data?.is_default) {
        finalValues?.outlets?.forEach((item: any) => (item.is_default = false));
        let newValues = finalValues;
        newValues?.outlets?.push(data);
        setFinalValues(newValues);
        // setChecked(!checked);
      } else {
        let newValues = finalValues;
        newValues?.outlets?.push(data);
        setFinalValues(newValues);
      }
    }
    // console.log(finalValues?.outlets, "OUTLETS");
    setEditingOutlet(false);
    getFinalOutlets(finalValues?.outlets);
    setEditOutletData({});
  };

  const handleMouseOver = (id: string) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered("");
  };
  // const handleCheckBox = () => {
  //   if (finalValues?.outlets?.length > 1) {
  //     if (!checked) {
  //       finalValues?.outlets?.forEach((item: any) => (item.is_default = false));
  //       finalValues.outlets[0].is_default =
  //         !finalValues?.outlets?.[0]?.is_default;
  //       setChecked(!checked);
  //     } else {
  //       // alert("make any other outlet as default");
  //       setToastData({
  //         message: "make any other outlet as default",
  //         status: "Alert",
  //       });
  //       setTimeout(() => {
  //         setToastData(constants.defaultToastData);
  //       }, 3000);
  //       return;
  //     }
  //   } else {
  //     setToastData({
  //       message: "Add more outlets and make any other outlet as default",
  //       status: "Alert",
  //     });
  //     setTimeout(() => {
  //       setToastData(constants.defaultToastData);
  //     }, 3000);
  //     return;
  //   }
  // };

  // delete outlet API starts here
  const deleteOutletApi = useAxios({
    axiosParams: {
      url: `store/buyer/${
        loggedInUser && loggedInUser?.business_details?.[0]?.business_setup_id
      }/outlet/${deleteId}`,
      data: {
        updated_customer_id: customerId,
      },
    },
    method: "DELETE",
  });

  const checkDeleteIdExists = (id: any) => {
    return currentBusinessData?.business_stores?.find(
      (item: any) => item?.id === id
    );
  };

  const deleteOutlet = (id: string) => {
    // let newValues = finalValues;
    // let filteredValues = newValues?.outlets?.filter(
    //   (item: any) => item?.id != id
    // );
    // finalValues.outlets = filteredValues;
    setDeleteId(id);
    setShowDeletePopup(true);
  };

  const deleteYes = async () => {
    if (checkDeleteIdExists(deleteId)) {
      setShowDeletePopup(false);
      dispatch(updateIsLoading(true));
      const deleteResponse: any = await deleteOutletApi.fetchData();
      if (deleteResponse?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        setToastData({
          message: "Outlet deleted successfully",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        getCurrentBussinessSetupData();
      } else {
        dispatch(updateIsLoading(false));
        setToastData({
          message: deleteResponse?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    } else {
      setShowDeletePopup(false);
      dispatch(updateIsLoading(true));
      setTimeout(() => {
        dispatch(updateIsLoading(false));
      }, 2000);
      setTimeout(() => {
        let newValues = finalValues;
        let filteredValues = newValues?.outlets?.filter(
          (item: any) => item?.id != deleteId
        );
        finalValues.outlets = filteredValues;
        setToastData({
          message: "Outlet deleted successfully",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }, 2000);
    }
    handleMouseLeave();
  };
  const deleteNo = () => {
    setDeleteId("");
    setShowDeletePopup(false);
  };

  const editOutlet = (id: string) => {
    let newValues = finalValues;
    let reqOutlet = newValues?.outlets?.find((item: any) => item?.id === id);
    setEditOutletData({ ...reqOutlet });
    setEditingOutlet(true);
    // }
    setShowAddoutlet(!showAddOutlet);
  };

  return (
    <>
      <div className={styles.businessSetupForm}>
        <div className={styles.right}>
          {userId ? (
            <div className={styles.breadCrumbContainer}>
              <Breadcrumb items={breadcrumbItems} />
            </div>
          ) : (
            <div className={styles.top}>
              <div className={styles.greetings}>
                <div className={styles.user}>
                  {constants.BusinessSetupForm.hello}{" "}
                  {loggedInUser?.first_name ?? "User"}
                </div>
                <div className={styles.welcome}>
                  {constants.BusinessSetupForm.welcome}
                </div>
                <div className={styles.welcome}>
                  {constants.BusinessSetupForm.supplierWelcomeContent}
                </div>
              </div>
              <div className={styles.icon}>
                <img src={businessSetupIcon} alt="businessSetup" />
              </div>
            </div>
          )}
          <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
            <div className={styles.form}>
              <div className={styles.businessSetup}>
                <div className={styles.setup}>
                  {constants.BusinessSetupForm.businessSetup}
                </div>
                <div className={styles.content}>
                  {constants.BusinessSetupForm.setupContent}
                </div>
                <div className={styles.inputFields}>
                  <div className={styles.column1}>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        id="businessName"
                        label="Business name"
                        placeholder="Enter business name"
                        onChange={onChangeField("businessName")}
                        register={register("businessName")}
                        errorMessage={errors.businessName?.message}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        id="emailID"
                        label="Email ID"
                        placeholder="Enter email here"
                        onChange={onChangeField("emailID")}
                        register={register("emailID")}
                        errorMessage={errors.emailID?.message}
                      />
                    </div>
                    <div
                      className={`${styles.inputField} ${styles.marginBottom}`}
                    >
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        id="licenseNo"
                        label="Trade license number"
                        placeholder="Enter license number"
                        onChange={onChangeField("licenseNo")}
                        register={register("licenseNo")}
                        errorMessage={
                          errors.licenseNo?.message &&
                          errors.licenseNo?.message.length > 60
                            ? `${errors.licenseNo?.message.slice(0, 60)}...`
                            : errors.licenseNo?.message
                        }
                      />
                    </div>
                    <div className={styles.inputField}>
                      <FileUploadCard
                        width={"100%"}
                        id={2}
                        accept=".jpg, .jpeg, .png, .pdf"
                        register={register("licenseFile")}
                        error={licenseFileError && "Upload license file"}
                        onChange={onChangeField("licenseFile")}
                        file={licenseImageFile}
                        imageUrl={licenseImageFileUrl}
                        setImageUrl={setLicenseImageFileUrl}
                        setFile={setLicenseImageFile}
                      />

                      {errors.licenseFile && (
                        <p className={styles.errorMessage}>
                          {errors.licenseFile.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.column2}>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        id="contactName"
                        label="Primary contact name"
                        placeholder="Enter name here"
                        onChange={onChangeField("contactName")}
                        register={register("contactName")}
                        errorMessage={errors.contactName?.message}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <MobileNumberField
                        defaultValue={
                          mobileNumberCode
                            ? mobileNumberCode
                            : countriesList &&
                              countriesList.length > 0 &&
                              countriesList.filter(
                                (country) =>
                                  country?.dial_codes === ("+971" as any)
                              )[0]
                        }
                        width="100%"
                        overlayWidth={"300px"}
                        label="Phone number"
                        id={"phnNo"}
                        options={countriesList}
                        registerMobileNumber={register("phNo")}
                        registerCountry={register("countryName")}
                        onMobileNumberChange={onChangeField("phNo")}
                        onCountryChange={(selectedOption: ICountry) => {
                          onChangeField("countryName")(selectedOption);
                          setMobileNumberCode(selectedOption);
                        }}
                        errorMessage={errors.phNo?.message}
                        mobileNumberField={true}
                      />
                    </div>
                    <div
                      className={`${styles.inputField} ${styles.marginBottom}`}
                    >
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        id="vatNo"
                        label="VAT number (optional)"
                        placeholder="Enter VAT number"
                        onChange={onChangeField("vatNo")}
                        register={register("vatNo")}
                        errorMessage={
                          errors.vatNo?.message &&
                          errors.vatNo?.message.length > 60
                            ? `${errors.vatNo?.message.slice(0, 60)}...`
                            : errors.vatNo?.message
                        }
                      />
                    </div>
                    <div className={styles.inputField}>
                      <FileUploadCard
                        width={"100%"}
                        id={4}
                        accept=".jpg, .jpeg, .png, .pdf"
                        register={register("vatFile")}
                        onChange={onChangeField("vatFile")}
                        file={vatImageFile}
                        error={vatFileError && "Upload vat file"}
                        setFile={setVATImageFile}
                        imageUrl={vatImageFileUrl}
                        setImageUrl={setVATImageFileUrl}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.businessSetup}>
                <div className={styles.setup}>
                  {constants.BusinessSetupForm.address}
                </div>
                <div className={styles.content}>
                  {constants.BusinessSetupForm.adressContent}
                </div>
                <div className={styles.inputFields}>
                  <div className={styles.column1}>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        label="Address"
                        placeholder="Enter address"
                        onChange={onChangeField("address")}
                        register={register("address")}
                        errorMessage={errors.address?.message}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <Dropdown
                        id={"country"}
                        label="Country"
                        // options={countriesList}
                        options={
                          countriesList &&
                          countriesList.filter(
                            (country: ICountry) => country?.iso_2 === "ae"
                          )
                        }
                        minWidth="100%"
                        isDropdownOutlineRequired={true}
                        register={register("country")}
                        onChange={(selectedOption: ICountry) => {
                          onChangeField("country")(selectedOption);
                          setSelectedCountry(selectedOption);
                          handleCountryClick(selectedOption);
                        }}
                        defaultLabel={selectedCountry?.name ?? "Select country"}
                        errorMessage={errors.country?.message}
                        selectedValue={selectedCountry}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        label="Outlet name"
                        placeholder="Enter outlet name here"
                        onChange={onChangeField("outletName")}
                        register={register("outletName")}
                        errorMessage={errors.outletName?.message}
                      />
                    </div>
                  </div>
                  <div className={styles.column2}>
                    <div className={styles.inputField}>
                      <InputField
                        maxWidth="100%"
                        minWidth="250px"
                        label="Area"
                        placeholder="Enter area here"
                        onChange={onChangeField("area")}
                        register={register("area")}
                        errorMessage={errors.area?.message}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <Dropdown
                        id={"Emirate"}
                        label="Emirate"
                        options={emiratesList}
                        minWidth="100%"
                        isDropdownOutlineRequired={true}
                        register={register("emirates")}
                        onChange={(selectedOption: ICountry) => {
                          onChangeField("emirates")(selectedOption);
                          setSelectedEmirate(selectedOption);
                        }}
                        defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                        errorMessage={errors.emirates?.message}
                        selectedValue={selectedEmirate}
                      />
                    </div>
                    {/* <div className={styles.inputField}> */}
                    {/* <div className={styles.defOutlet}>
                    <div
                      className={styles.checkbox}
                      onClick={() => handleCheckBox()}
                    >
                      <CheckboxButton
                        checked={checked}
                        handleChange={() => onChangeField("is_default")}
                        label={MARK_AS_DEFAULT}
                        disabled={false}
                        register={register("is_default")}
                      />
                    </div>
                  </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              {showAddOutletButton ? (
                <div className={styles.businessSetup}>
                  <div className={styles.action2}>
                    <button
                      className={styles.submit}
                      onClick={(e) => handleOutletAdd(e)}
                    >
                      {constants.BusinessSetupForm.addoutletButton}
                    </button>
                  </div>
                </div>
              ) : (
                <div className={styles.businessSetup2}></div>
              )}
              <div className={styles.addressListWrapper}>
                {finalValues &&
                  finalValues?.outlets &&
                  finalValues?.outlets?.length > 0 &&
                  finalValues?.outlets?.slice(1).map((outlet: any) => {
                    return (
                      <OutletCard
                        key={outlet?.id}
                        isHoverd={hovered === outlet?.id}
                        addressDetails={outlet}
                        handleMouseOver={handleMouseOver}
                        handleMouseLeave={handleMouseLeave}
                        deleteOutlet={deleteOutlet}
                        editOutlet={editOutlet}
                        emiratesList={[]}
                        businessStatus={
                          fetchedData?.business_setup_status
                            ? fetchedData?.business_setup_status
                            : ""
                        }
                        countriesList={countriesList}
                        // handleSelect={handleSelectAddress}
                        // selected={selectedAddress}
                      />
                    );
                  })}
              </div>
            </div>

            <div className={styles.action}>
              <button
                className={styles.skip}
                onClick={() => {
                  !loggedInUser.supplier_db && new OnBoardingIntro(true);
                  localStorage.setItem("isBusinessSkipped", "true");
                  dispatch(setIsBusinessSkipped("true"));
                  navigate(routeConfig.supplierDashboard);
                }}
              >
                {userId
                  ? constants.BusinessSetupForm.cancelButton
                  : constants.BusinessSetupForm.skipButton}
              </button>
              <button className={styles.submit}>
                {constants.BusinessSetupForm.submitButton}
              </button>
            </div>
          </form>
        </div>
        {showAddOutlet && (
          <AddOutlet
            showAddOutlet={showAddOutlet}
            handleClose={handleClose}
            countriesList={countriesList}
            emiratesList={emiratesList}
            currentBusinessData={currentBusinessData}
            outletData={getOutletData}
            editOutlet={editOutletData}
            outletNames={outletNames}
            isEditOutlet={editingOutlet}
            businessSetupForm={true}
          />
        )}

        {showDeletePopup && (
          <DeletePopup
            open={showDeletePopup}
            handleClose={() => setShowDeletePopup(false)}
            title="Delete outlet"
            handleCancel={deleteNo}
            handleConfirm={deleteYes}
            popupContent="Are you sure you want to delete this outlet ?"
          />
        )}

        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default BuyerBusinessSetupForm;
