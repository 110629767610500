/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CategoryCard from "../../../common/components/categoryCard/CategoryCard";
import contact from "../../../assets/images/contact.svg";
import card1 from "../../../assets/images/card1.svg";
import card2 from "../../../assets/images/card2.svg";
import styles from "./ManageCatalogue.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { routeConfig } from "../../../common/constants/routeConfig";
import readXlsxFile from "read-excel-file";
import IndividualProductCard from "../../../common/components/individualProductCard/IndividualProductCard";
import { constants } from "../../../common/constants/constants";
// import { useAxios } from "../../../services/useAxios";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { urlConstants } from "../../../common/constants/urlConstants";
import { fileHeaderFields } from "./bulkUploadConstants";
import axios from "axios";

interface IRowData {
  [key: string]: {
    value?: string | number | string[] | number[];
    editMode?: boolean;
    errorState?: boolean;
  };
}

export function removeDuplicateVariants(input: any) {
  const output = { ...input, variants: [] };
  const seenValues = new Set();
  const duplicateOptionIndex = [];
  // Loop through input object to extract variant data
  for (let i = 1; `unit_price_${i}` in input; i++) {
    const variant: any = {
      [`unit_price_${i}`]: input[`unit_price_${i}`].value,
      [`ordering_option_label_${i}`]: input[`ordering_option_label_${i}`].value,
      [`ordering_option_value_${i}`]: input[`ordering_option_value_${i}`].value,
      [`secondary_ordering_option_value_${i}`]:
        input[`secondary_ordering_option_value_${i}`].value,
      [`secondary_ordering_option_label_${i}`]:
        input[`secondary_ordering_option_label_${i}`].value,
    };

    const variantValuesString = JSON.stringify(Object.values(variant));

    // Check for duplicate variants based on values
    if (
      !seenValues.has(variantValuesString) &&
      !JSON.parse(variantValuesString).every((ele: any) => !ele)
    ) {
      seenValues.add(variantValuesString);
      output.variants.push(variant);
    } else {
      duplicateOptionIndex.push(i);
      // Set respective values to empty for duplicates and set errorState to true
      Object.keys({
        ...variant,
        [`offer_${i}`]: "",
        [`min_quantity_${i}`]: "",
      }).forEach((key) => {
        // variant[key] = { value: "", editMode: false, errorState: true };
        output[key] = { value: "", editMode: false, errorState: true };
      });
      output.variants.push(variant);

      // Update keys outside the variants array based on duplicate index
      // const duplicateIndex = seenValues.size;
      // console.log(duplicateIndex, 'DDDD', i);
      // Object.keys(output).forEach((key) => {
      //   if (!key.startsWith("variants") && key.endsWith(`_${i}`)) {
      //     output[key] = { value: "", editMode: false, errorState: true };
      //   }
      // });
    }
  }
  // Remove the variants array from the output
  delete output.variants;
  return { uniqueueOptionsProduct: output, duplicateOptionIndex };
}
export function getDynamicMappedKey(key: any) {
  if (key.includes("product_option")) {
    const variantOptionLabel = key.split("_");
    variantOptionLabel[1] = "option";
    const variantOptionIndex = key.split("_")[1]?.replace("option", "");
    return fileHeaderFields[variantOptionLabel.join("_")] + variantOptionIndex;
  }
}

const getValue = (mappedKey: any, value: any) => {
  if (mappedKey === "is_deactivated") {
    if (value === true || value === false) {
      return value;
    } else {
      return false;
    }
  } else {
    return value;
  }
};
export function createArrayOfObjects(dataArray: string[]) {
  const keys: any = dataArray[0];
  const arrayOfObjects = [];
  const duplicateProductOptionsIndex = [];
  for (let i = 1; i < dataArray.length; i++) {
    const values = dataArray[i];
    const obj: IRowData = {};

    for (let j = 0; j < keys.length; j++) {
      const fieldKey = keys[j]?.toLowerCase()?.replace(/\s+/g, "");
      const mappedKey =
        fileHeaderFields[fieldKey] ||
        getDynamicMappedKey(fieldKey) ||
        fieldKey?.toLowerCase()?.replace(/\s+/g, "");

      obj[mappedKey] = {
        value: getValue(mappedKey, values[j]),
        editMode: false,
        errorState: !values[j],
      };
    }
    // console.log(obj, 'OBJJJ');
    let { uniqueueOptionsProduct, duplicateOptionIndex } =
      removeDuplicateVariants(obj);
    duplicateProductOptionsIndex.push(duplicateOptionIndex);
    arrayOfObjects.push(uniqueueOptionsProduct);
  }
  return { products: arrayOfObjects, duplicateProductOptionsIndex };
}
const ManageCatalogue = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const { supplierId } = useParams();
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  //Validation for product title
  const checkForTitle = (data: any) => {
    // const titleSet = new Set();

    for (let i = 0; i < data.length; i++) {
      const titleValue = data[i].title.value;
      if (!titleValue?.length) {
        setToastData({
          message: `Product title is empty at row number ${i + 1}`,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        return false;
      }
      //  else if (titleSet.has(titleValue)) {
      //   // Duplicate found, return the index of the first duplicate
      //   const duplicateIndex = data.findIndex(
      //     (item: any) => item.title.value === titleValue
      //   );
      //   // setTitleErrorRow(duplicateIndex+1);
      //   if (duplicateIndex) {
      //     setToastData({
      //       message: `Product title is duplicate at row number ${i + 1}`,
      //       status: "Fail",
      //     });
      //     setTimeout(() => {
      //       setToastData(constants.defaultToastData);
      //     }, 3000);
      //     return false;
      //   }
      // }
      // titleSet.add(titleValue);
    }
    return true;
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target;

    const allowedExtensions = ["xlsx", "xls", "csv"];
    if (input?.files && input?.files[0]) {
      const uploadedFile = input.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (allowedExtensions?.includes(fileExtension as string)) {
        try {
          const rows: any = await readXlsxFile(uploadedFile, {
            sheet: process.env.REACT_APP_BULK_UPLOAD_SHEET,
          });
          let headerKeys = rows[0].map(
            (el: any) =>
              fileHeaderFields[el.toLowerCase()?.replace(/\s+/g, "")] ||
              getDynamicMappedKey(el.toLowerCase()?.replace(/\s+/g, "")) ||
              el
          );
          if (rows?.length < 7002) {
            const { products, duplicateProductOptionsIndex } =
              createArrayOfObjects(rows);
            if (products.length > 0) {
              // console.log(products, "ARRAY OF OBJECTS");
              if (checkForTitle(products)) {
                isAdmin
                  ? navigate(
                      supplierId
                        ? routeConfig.addBulkSupplierProductFromAdmin.replace(
                            ":supplierId",
                            supplierId
                          )
                        : routeConfig.adminAddBulkProduct,
                      {
                        state: {
                          name: uploadedFile?.name,
                          tableData: products,
                          headerKeys: headerKeys,
                          duplicateProductOptionsIndex,
                        },
                      }
                    )
                  : navigate(routeConfig.addBulkProduct, {
                      state: {
                        name: uploadedFile?.name,
                        tableData: products,
                        headerKeys: headerKeys,
                        duplicateProductOptionsIndex,
                      },
                    });
              }
              //  else if (typeof checkForTitle(arrayOfObjects) === "number"){
              //   alert(`name errror, ${checkForTitle(arrayOfObjects)}`)
              // } else {
              //   alert("Title is mandatory for all the products");
              // }
            }
          } else {
            setToastData({
              message: `Please upload less than 1000 products`,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
          }
        } catch (error: any) {
          if (error?.message?.includes('Sheet "Products list" not found')) {
            setToastData({
              message: `Sheet name is not matching, provide a valid sheet with "Products list" as name`,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 6000);
          } else {
            setToastData({ message: `Invalid Excel Template`, status: "Fail" });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
          }
        }
      } else {
        setToastData({
          message: `Please upload a valid Excel file.`,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    }
  };

  const downloadBulkTemplate = async () => {
    const url1 = `${urlConstants.BASE_URL}store/product/bulk-upload/template`;
    const jwtToken = await localStorage.getItem("token");
    console.log("=== jwt token ===", jwtToken);
    const response: any = await axios.get(url1, {
      responseType: "blob",
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
    console.log(response, "response");
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `template.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  // const downloadBulkTemplate = async () => {
  //   const response: any = await bulkUploadTemplate?.fetchData();
  //   if (response) {
  //     const blob = new Blob([response], { type: "text/xlsx" });
  //     const href = window.URL.createObjectURL(blob);
  //     const anchor = document.createElement("a");
  //     anchor.href = href;
  //     anchor.download = "bulk_products_upload_template.xlsx";
  //     document.body.appendChild(anchor);
  //     anchor.click();
  //     document.body.removeChild(anchor);
  //     window.URL.revokeObjectURL(href);
  //   }
  // };

  return (
    <div className={styles.app}>
      <div className={styles.rightBodyContainer}>
        <div className={styles.text}>{constants.manageCatalogue.manage}</div>
        <div className={styles.text2}>
          {constants.manageCatalogue.bulkHeading}
        </div>
        <div className={styles.outerBox}>
          <div className={styles.outerBox1}>
            {!supplierId && (
              <div className={styles.card1}>
                <IndividualProductCard
                  cardType="search"
                  imgSrc={card1}
                  head="Add individual product"
                  id={2}
                  title={<div>{constants.manageCatalogue.supplier}</div>}
                  handleCardClick={() =>
                    isAdmin
                      ? navigate(routeConfig.adminAddProductForm)
                      : navigate(routeConfig.addIndividualProduct)
                  }
                />
              </div>
            )}
            <div className={styles.card2}>
              <div>
                <label htmlFor="inputNew">
                  <input
                    className={styles.inputField}
                    type="file"
                    id="inputNew"
                    onChange={handleFileUpload}
                    accept=".xls, .xlsx"
                    onClick={(event: any) => {
                      event.target.value = null;
                    }}
                  />
                  <CategoryCard
                    cardType="search"
                    imgSrc={card2}
                    head="Bulk product upload"
                    id={3}
                    title={<></>}
                    handleCardClick={() => {}}
                  />
                </label>
              </div>
              <div className={styles.card2Text} onClick={downloadBulkTemplate}>
                <a
                  // href={""}
                  // download="bulk_template.xlsx"
                  className={styles.downloadLink}
                >
                  <span className={styles.downloadText}>
                    {constants.manageCatalogue.download}
                  </span>
                </a>
                <div className={styles.downloadTextSecondary}>
                  {constants.manageCatalogue.template}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contact}>
            <div className={styles.logo}>
              <img className={styles.imgLogo} src={contact} alt="Contact" />
            </div>
            <div className={styles.contactText}>
              <p className={styles.contactText1}>
                {constants.manageCatalogue.help}
              </p>
              <p className={styles.contactText2}>
                {constants.manageCatalogue.email} |
                {constants.manageCatalogue.phoneNo}
              </p>
            </div>
          </div>
        </div>
      </div>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </div>
  );
};

export default ManageCatalogue;
