import React from "react";
import PopUp from "../PopUp/PopUp";
import styles from "./deletePopupStyles.module.scss";
import Button from "../button/Button";
type DeletePopupProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  handleConfirm : () => void;
  handleCancel : () => void;
  renderActions? : () => React.JSX.Element;
  popupContent: string;
};
const DeletePopup = ({
  open,
  handleClose,
  title,
  handleConfirm,
  handleCancel,
  renderActions,
  popupContent
}: DeletePopupProps) => {
  return (
    <PopUp open={open} handleClose={handleClose} title={title}>
      <div className={styles.deleteConfirmContainer}>
        <p>{popupContent}</p>
          {renderActions ? (
            renderActions()
          ) : (
            <div className={styles.popupActions}>

              <Button label="Cancel" secondary handleClick={handleCancel} />
              <Button label="Confirm" handleClick={handleConfirm} />
              </div>

          )}
      </div>
    </PopUp>
  );
};

export default DeletePopup;
