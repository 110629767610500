/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import loadash, { cloneDeep } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  // useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import EditIcon from "../../../assets/images/edit.svg";
import infoIcon from "../../../assets/images/icon-error.svg";
// import whiteEye from "../../../../assets/images/whiteEye.svg";
// import { v4 as uuidv4 } from "uuid";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import alertToast from "../../../assets/images/alertToast.svg";
import whiteTrash from "../../../assets/images/whiteTrash.svg";
import CatalogueInfo from "../../../common/components/CatalogueInfo/CatalogueInfo";
import BreadCrumb from "../../../common/components/breadCrumb/BreadCrumb";
import Button from "../../../common/components/button/Button";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import InputField from "../../../common/components/formFields/inputField/inputField";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import {
  APIMethods,
  urlConstants,
  // adminEndPoints
} from "../../../common/constants/urlConstants";
import {
  IBrand,
  ICategory,
  ICertificate,
  ICountry,
  IEqualTo,
  // IMetadataVariants,
  IOptions,
  IOrderingOption,
  IPriceVariants,
  IProductDetailsWithDiscount,
  ISubCategory,
  ISubSubCategory,
  // IVariants,
} from "../../../models/IAddIndividualProductForm";
import {
  resetWholeForm,
  setFirstForm,
  setSecondBottomForm,
  setSecondTopForm,
} from "../../../reducerSlices/addIndividualProductFormSlice";
import {
  getSingleProductDetails,
  resetProductDetails,
  // resetProductDetails,
} from "../../../reducerSlices/productDetailsSlice";
import { useAxios } from "../../../services/useAxios";
import useFetchCountries from "../../../services/useCountry";
import styles from "./AdminLinkProductStyles.module.scss";
import useDidUpdateEffect from "../../../hooks/useDidUpdateEffect";
import axios from "axios";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";

const LightTooltip = styled(({ showTooltip, className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} open={showTooltip} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #ec187b",
    fontSize: 11,
  },
}));

const AddIndividualPartOne = React.memo(
  ({
    isReadOnlyMode,
    setSecondFormShow,
    singleProductDetails,
    navToLinkProduct,
    setProductId,
    productId,
  }: {
    secondFormShow: boolean;
    setSecondFormShow: Dispatch<SetStateAction<boolean>>;
    isReadOnlyMode: boolean;
    singleProductDetails: any;
    navToLinkProduct: boolean;
    productId?: string;
    setProductId: Dispatch<SetStateAction<any>>;
  }) => {
    const { countriesList } = useFetchCountries();

    const { addIndividualProductForm } = useSelector((state: any) => state);

    const dispatch = useDispatch();

    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [totalFiles, setTotalFiles] = useState<any[]>(
      addIndividualProductForm?.firstForm?.productImagesUrl || []
    );
    const [defaultproductImages, setDefaultproductImages] = useState<any[]>(
      addIndividualProductForm?.firstForm?.defaultproductImagesUrl || []
    );
    const [brandName, setBrandName] = useState<string>("");
    const [, setBrandApiData] = useState<IBrand[]>([]);

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
    const [selectedSubcategory, setSelectedSubcategory] =
      useState<ISubCategory>({});
    const [selectedSubcategories, setSelectedSubcategories] = useState<
      ICategory[]
    >([]);
    const [, setSelectedProductSection] = useState<ISubSubCategory[]>([]);

    const [selectedSection, setSelectedSection] = useState<ISubSubCategory>({});

    const [selectedCountry, setSelectedCountry] = useState<ICountry>(
      addIndividualProductForm?.firstForm?.productCountryOrigin || {}
    );
    //  link product states starts
    const [searchedProduct, setSearchedProduct] = useState("");
    const [toastData, setToastData] = useState(constants.defaultToastData);
    const [suggestedProducts, setSuggestedProducts] = useState<any>([]);
    const [InputFocused, setInputFocused] = useState<boolean>(false);
    const [selectedSuggestedProduct, setSelectedSuggestedProduct] =
      useState<boolean>(false);
    const linkSuggestionRef = useRef<HTMLDivElement>(null);
    //  link product states ends
    const removeUploadedFile = (index: number) => {
      const updatedUploadedFiles = [...totalFiles];
      const updatedProductImagesUrls = [
        ...addIndividualProductForm?.firstForm?.productImagesUrl,
      ];
      updatedProductImagesUrls.splice(index, 1);
      updatedUploadedFiles.splice(index, 1);
      setTotalFiles(updatedUploadedFiles);
      dispatch(setFirstForm({ productImages: updatedUploadedFiles }));
      dispatch(
        setFirstForm({
          productImages: updatedUploadedFiles,
          productImagesUrl: updatedProductImagesUrls ?? [],
        })
      );
      setValue("productImages", [...updatedUploadedFiles]);
    };
    const removeDefaultProductImage = () => {
      setDefaultproductImages([]);
      dispatch(
        setFirstForm({ defaultproductImages: [], defaultproductImagesUrl: [] })
      );
      setValue("defaultproductImages", []);
    };
    const extractFileNameFromUrl = (url: string) => {
      const filenameSlash = url.split("/");
      const filenameHyphen = filenameSlash[filenameSlash.length - 1].split("-");
      if (filenameHyphen[filenameHyphen?.length - 1]) {
        return filenameHyphen[filenameHyphen?.length - 1];
      }
      return url;
    };
    useEffect(() => {
      if (
        selectedCategory ||
        (singleProductDetails?.categories &&
          singleProductDetails?.categories[0])
      ) {
        const selectedCategoryObj = categories?.find(
          (cat: ICategory) =>
            cat.value ===
            (selectedCategory?.value ||
              (singleProductDetails?.categories &&
                singleProductDetails?.categories[0]?.name))
        );
        if (
          selectedCategoryObj &&
          selectedCategoryObj?.subcategories &&
          selectedCategoryObj?.subcategories?.length > 0
        ) {
          const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
            (category: ICategory) => ({
              ...category,
              value: category?.name,
              productSection: category?.sub_sub_categories,
            })
          );
          setSelectedSubcategories(updatedCategoriesData);
        } else {
          setSelectedSubcategories([]);
        }
      } else {
        setSelectedSubcategories([]);
      }
    }, [selectedCategory, categories]);
    useEffect(() => {
      if (selectedSubcategory || singleProductDetails?.subCategory) {
        const selectedSubcategoryObj = selectedSubcategories.find(
          (subcat: ICategory) =>
            subcat.value === singleProductDetails?.subCategory?.name
        );
        if (selectedSubcategoryObj) {
          if (selectedSubcategoryObj?.productSection) {
            const updatedCategoriesData =
              selectedSubcategoryObj?.productSection?.map(
                (category: ISubCategory) => ({
                  ...category,
                  value: category?.name,
                  productSection: selectedSubcategoryObj?.sub_sub_categories,
                })
              ) as ISubSubCategory[];
            setSelectedProductSection(updatedCategoriesData);
            dispatch(
              setFirstForm({ productSectionList: updatedCategoriesData })
            );
          } else {
            setSelectedProductSection([]);
          }
        } else {
          setSelectedProductSection([]);
        }
      }
    }, [selectedSubcategories]);
    const handleCategoryClick = (categoryValue: ICategory) => {
      setSelectedCategory(categoryValue);
      setSelectedSubcategories([]);
      setSelectedProductSection([]);
    };

    const handleSubcategoryClick = (subcategoryValue: ISubCategory) => {
      if (!subcategoryValue) return;
      setSelectedSubcategory(subcategoryValue);
      const selectedSubcategoryObj = selectedSubcategories.find(
        (subcat: ICategory) => subcat.value === subcategoryValue?.value
      );
      if (selectedSubcategoryObj) {
        if (selectedSubcategoryObj?.productSection) {
          const updatedCategoriesData =
            selectedSubcategoryObj?.productSection?.map(
              (category: ISubCategory) => ({
                ...category,
                value: category?.name,
                productSection: selectedSubcategoryObj?.sub_sub_categories,
              })
            ) as ISubSubCategory[];
          setSelectedProductSection(updatedCategoriesData);
          dispatch(setFirstForm({ productSectionList: updatedCategoriesData }));
        } else {
          setSelectedProductSection([]);
        }
      } else {
        setSelectedProductSection([]);
      }
    };
    const schema = yup.object().shape({
      productName: yup.string().required("Enter product name"),
      productBrand: yup.string().optional(),
      productCategory: yup
        .object()
        .required("Select product category")
        .test("option-test", "Select Product category", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      productSubCategory: yup
        .object()
        .required("Select product subcategory")
        .test("option-test", "Select Product Subcategory", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      productSection: yup
        .object()
        .required("Select product sub sub category")
        .test("option-test", "Select Product Sub Sub Category", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      productCountryOrigin: yup
        .object()
        .required("Select country origin")
        .test("option-test", "Select Country origin", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      supplierProductCode: yup.string().optional().nullable(),
      productDescription: yup.string().optional().nullable(),
      productTags: yup.string().required("Enter product tags "),
      hsCode: yup.string().optional(),
      productImages: yup.mixed().optional(),
      defaultproductImages:
        isReadOnlyMode ||
        (defaultproductImages && defaultproductImages?.length > 0)
          ? yup.mixed().optional()
          : yup
              .array()
              .min(1, "Upload default product images")
              .max(1, "Upload default product images")
              .required("Upload default product images"),
    });

    const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
    } = useForm({
      resolver: yupResolver(schema),
    });

    // get the brand name or create the brand name api call starts

    const getBrand = useAxios({
      axiosParams: {
        url: "store/brands",
        data: {
          name: brandName,
        },
      },
      method: "POST",
    });

    const debouncedGetBrandName = loadash.debounce(async () => {
      if (brandName) {
        const response: any = await getBrand?.fetchData();
        setBrandApiData(response?.data && response?.data[0]);
        if (response?.data && response?.data[0]) {
          setValue("productBrand", response?.data[0]?.name);
          dispatch(setFirstForm({ productBrandApiData: response?.data[0] }));
        }
      }
    }, 1000);

    const [isBrandNameFocused, setIsBrandNameFocused] = useState(false);

    // Add an event listener to track focus on the "Product brand" input field
    useEffect(() => {
      const handleBrandNameFocus = () => {
        setIsBrandNameFocused(true);
      };

      const handleBrandNameBlur = () => {
        setIsBrandNameFocused(false);
      };

      const brandNameInput = document.querySelector(
        'input[name="productBrand"]'
      );

      if (brandNameInput) {
        brandNameInput.addEventListener("focus", handleBrandNameFocus);
        brandNameInput.addEventListener("blur", handleBrandNameBlur);
      }

      return () => {
        if (brandNameInput) {
          brandNameInput.removeEventListener("focus", handleBrandNameFocus);
          brandNameInput.removeEventListener("blur", handleBrandNameBlur);
        }
      };
    }, []);
    useEffect(() => {
      if (brandName) {
        debouncedGetBrandName();
      }
    }, [isBrandNameFocused, brandName]);

    // get the brand name or create the brand name api call ends
    const onChangeField = React.useCallback(
      (name: any) => (text: any) => {
        setValue(name, text);
        if (name !== "watermelonProductCode") {
          dispatch(setFirstForm({ [name]: text }));
        }
        if (name === "productBrand") {
          setBrandName(text);
        }
        if (name === "productName") {
          if (text.length >= 2) {
            setSearchedProduct(text);
          } else {
            setSearchedProduct("");
          }
        }
      },
      [dispatch, setValue]
    );

    const submit = (data: any) => {
      if (data) {
        const updatedFullData = {
          ...addIndividualProductForm.firstForm,
          ...data,
        };
        dispatch(setFirstForm(updatedFullData));
        setSecondFormShow(true);
      }
    };

    const navigate = useNavigate();
    // get categories api call starts
    const getCategories = useAxios({
      axiosParams: {
        url: "store/categories",
      },
      method: "GET",
    });

    const getAllCategoriesList = useCallback(async () => {
      const response: any = await getCategories?.fetchData();
      if (response) {
        const updatedCategoriesData = response?.data?.categories?.map(
          (category: ICategory) => ({
            ...category,
            value: category.name,
            subcategories: category.sub_categories,
          })
        );
        const finalCategoreis = updatedCategoriesData?.filter(
          (item: ICategory) => item?.name !== "Offline Product Category"
        );
        setCategories(finalCategoreis);
      }
    }, [getCategories]);
    // get categories api call ends

    useEffect(() => {
      getAllCategoriesList();
    }, []);

    // get images url api call starts
    let formdata = new FormData();
    let list = totalFiles?.filter((ele: any) => !ele?.url);
    if (list && list.length) {
      list.slice(0, 5).forEach((ele: any) => {
        if (ele && !ele.url) {
          formdata.append(`multiplefiles`, ele);
        }
      });
    }

    const getImagesUrl = useAxios({
      axiosParams: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "store/miniobucket/uploadmultiplefile",
        data: formdata,
      },
      method: "POST",
    });

    const [showTooltip, setShowTooltip] = useState(false);
    const getImagesUrlList = useCallback(async () => {
      let list = totalFiles?.filter((ele: any) => !ele?.url);
      if (list && list?.length > 0) {
        const response: any = await getImagesUrl?.fetchData();
        if (response) {
          const updatedImagesUrl = response?.data?.map((image: string) => ({
            id: null,
            url: image,
          }));
          if (updatedImagesUrl && updatedImagesUrl?.length > 0) {
            let files = cloneDeep(totalFiles.filter((ele: any) => ele?.url));
            dispatch(
              setFirstForm({
                productImagesUrl: [...files, ...updatedImagesUrl],
              })
            );
            setValue("productImages", [...files, ...updatedImagesUrl]);
          }
        }
      }
    }, [dispatch, getImagesUrl]);

    useEffect(() => {
      getImagesUrlList();
    }, [totalFiles]);
    const getDefaultProductImageUrl = async () => {
      let list = defaultproductImages?.filter((ele: any) => !ele?.url);
      let formdata = new FormData();
      // Define headers
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      if (list && list?.length > 0) {
        dispatch(updateIsLoading(true));
        list.slice(0, 1).forEach((ele: any) => {
          if (ele && !ele.url) {
            formdata.append(`multiplefiles`, ele);
          }
        });
        try {
          const defaultImgResponse: any = await axios.post(
            `${urlConstants.BASE_URL}store/miniobucket/uploadmultiplefile`,
            formdata,
            { headers }
          );
          const response = defaultImgResponse?.data;
          if (
            response &&
            (response?.status === "SUCCESS" || response?.status === 200)
          ) {
            const updatedImagesUrl = response?.data?.map((image: string) => ({
              id: null,
              url: image,
            }));
            if (updatedImagesUrl && updatedImagesUrl?.length > 0) {
              let files = cloneDeep(
                defaultproductImages.filter((ele: any) => ele?.url)
              );
              if (files) {
                dispatch(
                  setFirstForm({
                    defaultproductImagesUrl: [...files, ...updatedImagesUrl],
                  })
                );
                setValue("defaultproductImages", [
                  ...files,
                  ...updatedImagesUrl,
                ]);
              }
            }
            dispatch(updateIsLoading(false));
          }
        } catch (error) {
          console.log("API Failed", error);
        }
      }
    };
    useEffect(() => {
      getDefaultProductImageUrl();
    }, [defaultproductImages]);

    // setting the values for the selected product
    useDidUpdateEffect(() => {
      singleProductDetails?.title &&
        onChangeField("productName")(singleProductDetails?.title);
      onChangeField("productBrand")(singleProductDetails?.brand?.name);
      onChangeField("supplierProductCode")(singleProductDetails?.productCode);
      onChangeField("HS")(singleProductDetails?.hsCode);
      onChangeField("productTags")(
        singleProductDetails?.tags?.map((tag: any) => tag?.value).join(", ")
      );
      onChangeField("productDescription")(singleProductDetails?.description);
      setSelectedCountry({
        dial_codes: singleProductDetails?.countries?.dialCodes,
        display_name: singleProductDetails?.countries?.displayName,
        emirates: singleProductDetails?.countries?.emirates ?? [],
        flag: singleProductDetails?.countries?.flag,
        id: singleProductDetails?.countries?.id,
        iso_2: singleProductDetails?.countries?.iso_2,
        iso_3: singleProductDetails?.countries?.iso_3,
        name: singleProductDetails?.countries?.name,
        region_id: singleProductDetails?.countries?.id,
        value: singleProductDetails?.countries?.displayName,
      });
      // dispatch(
      //   setFirstForm({ productImagesUrl: singleProductDetails?.images })
      // );
      // setTotalFiles(singleProductDetails?.images ?? []);
      // dispatch(
      //   setFirstForm({ productImagesUrl: singleProductDetails?.images })
      // );
      const defaultImg = singleProductDetails?.images?.filter(
        (item: any) => item.metadata !== null
      );
      const productImgs = singleProductDetails?.images?.filter(
        (item: any) => item.metadata === null
      );
      !totalFiles?.length && setTotalFiles(productImgs ?? []);
      !defaultproductImages?.length &&
        setDefaultproductImages(defaultImg?.slice(0, 1) ?? []);
      dispatch(
        setFirstForm({
          defaultproductImagesUrl: defaultImg?.slice(0, 1),
          productImagesUrl: productImgs,
        })
      );
    }, [singleProductDetails]);
    // useEffect(() => {
    //   console.log("dayayaaab",singleProductDetails?.images, isImgApiDataSet)
    //   if(!isImgApiDataSet && singleProductDetails){
    //     console.log("dayayaaaa",singleProductDetails?.images, isImgApiDataSet)
    //     setTotalFiles(singleProductDetails?.images ?? []);
    //     dispatch(
    //       setFirstForm({ productImagesUrl: singleProductDetails?.images })
    //     );
    //     setIsImgApiDataSet(true);
    //   }
    // }, [singleProductDetails]);
    // link product
    const getSuggestedProducts = useAxios({
      axiosParams: {
        url: `admin/supplier/retriveproduct`,
        data: {
          name: searchedProduct,
          page: 1, //showing only 1st page products as suggestion
        },
      },
      method: APIMethods.post,
    });
    const debouncedgetSuggestedProductsList = loadash.debounce(async () => {
      if (searchedProduct !== "") {
        const response: any = await getSuggestedProducts?.fetchData();
        setSuggestedProducts(response?.data);
      } else {
        setSuggestedProducts([]);
      }
    }, 1000);
    const handleSuggestedProduct = (productId: string) => {
      if (navToLinkProduct && !selectedSuggestedProduct) {
        setProductId(productId);
        setSelectedSuggestedProduct(false); // set to true , if limits to 1 otherwise false
        setInputFocused(false);
      }
    };

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (
          linkSuggestionRef &&
          linkSuggestionRef.current &&
          !linkSuggestionRef?.current?.contains(event.target)
        ) {
          setSuggestedProducts([]);
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (!selectedSuggestedProduct && searchedProduct?.length >= 2) {
        setSuggestedProducts([]);
        debouncedgetSuggestedProductsList();
      } else {
        setSuggestedProducts([]);
      }
      return () => {
        debouncedgetSuggestedProductsList.cancel();
      };
    }, [searchedProduct]);
    return (
      <form
        onSubmit={handleSubmit(submit)}
        className={styles.addIndividualForm}
      >
        <div
          className={styles.generalInfoBlock}
          onScroll={() => setShowTooltip(false)}
        >
          <div className={styles.textArea}>
            <div
              className={`${styles.input} ${
                navToLinkProduct ? styles.productNameSugg : null
              }`}
            >
              <div className={styles.label}>Product name</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productName}
                placeholder="Enter product name..."
                id="productName"
                onChange={onChangeField("productName")}
                register={register("productName")}
                maxWidth="100%"
                errorMessage={errors.productName?.message}
                editForm={isReadOnlyMode}
                autoComplete={false}
                onFocus={() => setInputFocused(true)}
                tabIndex={1}
              />
              {navToLinkProduct &&
                InputFocused &&
                suggestedProducts?.length > 0 && (
                  <div
                    className={styles.productNameSuggestionsContainer}
                    ref={linkSuggestionRef}
                  >
                    {suggestedProducts?.map(
                      (each_product: any, index: number) => (
                        <div
                          key={each_product.product_id}
                          onClick={() => {
                            handleSuggestedProduct(each_product.product_id);
                          }}
                          className={`${styles.productSuggestionItem} ${
                            index != suggestedProducts.length - 1
                              ? styles.seperator
                              : null
                          }`}
                        >
                          {each_product.product_title}
                        </div>
                      )
                    )}
                  </div>
                )}
            </div>
            <div className={styles.input}>
              <div className={styles.label}>Product brand (optional)</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productBrand}
                placeholder="Enter product brand name..."
                id="productBrand"
                onChange={onChangeField("productBrand")}
                register={register("productBrand")}
                errorMessage={errors.productBrand?.message}
                maxWidth="100%"
                editForm={isReadOnlyMode}
                tabIndex={2}
              />
            </div>
          </div>
          <div className={styles.textArea}>
            <div className={styles.input}>
              <div className={styles.label}>Product category</div>
              <Dropdown
                minWidth="100%"
                label={""}
                selectedValue={
                  singleProductDetails
                    ? singleProductDetails?.categories[0]
                    : addIndividualProductForm?.firstForm?.productCategory ||
                      selectedCategory
                }
                options={categories}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productCategory?.name ??
                  selectedCategory?.name ??
                  "Select product category..."
                }
                id="productCategory"
                onChange={(selectedOption: ICategory) => {
                  onChangeField("productCategory")(selectedOption);
                  handleCategoryClick(selectedOption);
                }}
                errorMessage={errors.productCategory?.message}
                editForm={isReadOnlyMode}
                tabIndex={3}
              />
            </div>
            <div className={styles.input}>
              <div className={styles.label}>Product subcategory</div>
              <Dropdown
                selectedValue={
                  singleProductDetails
                    ? singleProductDetails?.subCategory
                    : addIndividualProductForm?.firstForm?.productSubCategory ||
                      selectedSubcategory
                }
                minWidth="100%"
                label={""}
                options={selectedSubcategories}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productSubCategory
                    ?.name ??
                  selectedSubcategory?.name ??
                  "Select product subcategory..."
                }
                id="productSubCategory"
                onChange={(selectedOption: ISubCategory) => {
                  onChangeField("productSubCategory")(selectedOption);
                  handleSubcategoryClick(selectedOption);
                }}
                errorMessage={errors.productSubCategory?.message}
                editForm={isReadOnlyMode}
                tabIndex={4}
              />
            </div>
          </div>
          <div className={styles.textAreaMargin}>
            <div className={styles.input}>
              <div className={styles.label}> Product sub sub category</div>
              <Dropdown
                selectedValue={
                  singleProductDetails
                    ? singleProductDetails?.subSubCategory
                    : addIndividualProductForm?.firstForm?.productSection ||
                      selectedSection
                }
                minWidth="100%"
                label={""}
                options={
                  addIndividualProductForm?.firstForm?.productSectionList
                }
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productSection?.name ??
                  selectedSection?.name ??
                  "Select product sub sub category..."
                }
                id="productSection"
                onChange={(selectedOption: ISubSubCategory) => {
                  onChangeField("productSection")(selectedOption);
                  setSelectedSection(selectedOption);
                }}
                errorMessage={errors.productSection?.message}
                editForm={isReadOnlyMode}
                tabIndex={5}
              />
            </div>
            <div className={styles.input}>
              <div className={styles.label}>Product country origin</div>
              <Dropdown
                selectedValue={selectedCountry}
                minWidth="100%"
                label={""}
                options={countriesList}
                isCountryList={true}
                isCountryOrigin={true}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productCountryOrigin
                    ?.name ??
                  selectedCountry?.name ??
                  "Enter country origin...."
                }
                id="productCountryOrigin"
                onChange={(selectedOption: ICountry) => {
                  onChangeField("productCountryOrigin")(selectedOption);
                  setSelectedCountry(selectedOption);
                }}
                register={register("productCountryOrigin")}
                errorMessage={errors.productCountryOrigin?.message}
                editForm={isReadOnlyMode}
                tabIndex={6}
              />
            </div>
          </div>
          <div className={styles.textArea}>
            <div className={styles.input}>
              <div className={`${styles.label} ${styles.tootltipLabel}`}>
                <span>Supplier Product Code (Optional)</span>
              </div>
              <InputField
                // value={watch("supplierProductCode")}
                value={addIndividualProductForm?.firstForm?.supplierProductCode}
                maxWidth="100%"
                placeholder="Enter supplier product code..."
                register={register("supplierProductCode")}
                id="supplierProductCode"
                onChange={onChangeField("supplierProductCode")}
                editForm={isReadOnlyMode}
                errorMessage={errors.supplierProductCode?.message}
                tabIndex={7}
              />
            </div>
            <div className={styles.input}>
              <div className={`${styles.label} ${styles.tootltipLabel}`}>
                <span>Product HS code (Optional)</span>
                <span
                  className={styles.tooltipContainer}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <LightTooltip
                    title="HS Code is a standardised international system for classifying traded products. Example: 6204.62.0010 (Men's cotton shirts, long-sleeved)."
                    placement="top"
                    arrow
                    showTooltip={showTooltip}
                  >
                    <img src={infoIcon} alt="Error" />
                  </LightTooltip>
                </span>
              </div>
              <InputField
                value={addIndividualProductForm?.firstForm?.HS}
                maxWidth="100%"
                placeholder="Enter product HS code..."
                id="HS"
                onChange={onChangeField("HS")}
                editForm={isReadOnlyMode}
                tabIndex={8}
              />
            </div>
          </div>
          {/* {singleProductDetails && singleProductDetails?.handle && (
          <div className={styles.textArea}>
            <div className={styles.input}>
              <div className={styles.label}>Watermelon Product Code</div>
              <div className={styles.generateBox}>
                <InputField
                  value={singleProductDetails && singleProductDetails?.handle}
                  disabled={true}
                  placeholder="Enter watermelon product code..."
                  id="watermelonProductCode"
                  maxWidth="100%"
                  editForm={true}
                />
              </div>
            </div>
            <div className={styles.input}></div>
          </div>
        )} */}
          <div className={styles.textAreaLast}>
            <div className={styles.input1}>
              <div className={styles.label}>Product description (optional)</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productDescription}
                maxWidth="100%"
                height="100%"
                placeholder="Enter here..."
                id="productDescription"
                onChange={onChangeField("productDescription")}
                register={register("productDescription")}
                errorMessage={errors.productDescription?.message}
                isTextarea
                editForm={isReadOnlyMode}
                tabIndex={9}
              />
            </div>
            <div className={styles.input1}>
              <div className={styles.label}>Product tags</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productTags}
                maxWidth="100%"
                height="100%"
                type="text"
                placeholder="Enter Product Tags separated by “,” comma..."
                id="productTags"
                onChange={onChangeField("productTags")}
                register={register("productTags")}
                isTextarea
                errorMessage={errors.productTags?.message}
                editForm={isReadOnlyMode}
                tabIndex={10}
              />
            </div>
          </div>
          <div className={styles.multipleUpload}>
            <div className={styles.uploadLabel}>
              Upload product Default Image
            </div>
            <label
              tabIndex={11}
              htmlFor="defaultproductImages"
              style={{
                pointerEvents:
                  isReadOnlyMode || defaultproductImages?.length >= 1
                    ? "none"
                    : "auto",
                filter:
                  isReadOnlyMode || defaultproductImages?.length >= 1
                    ? "grayscale(100%)"
                    : "none",
              }}
            >
              <div className={styles.upperText}>
                Drop file here or <span>Upload</span>
              </div>
              <div className={styles.uploadedText}>
                (Max uploads: 01, Formats: JPG, PNG, JPEG)
              </div>
            </label>
            <input
              value={[]}
              hidden
              type="file"
              id="defaultproductImages"
              accept=".jpg,.jpeg,.png,.svg"
              multiple
              {...register("defaultproductImages")}
              onChange={(e) => {
                const files = e.target.files;
                if (files && files?.length > 0) {
                  const newUploadedImages = Object.keys(files)
                    .map((key: any) => files[key])
                    .slice(0, 1);
                  if (files[0].size <= 2000000) {
                    if (e?.target?.accept) {
                      if (
                        e?.target?.accept?.includes(
                          newUploadedImages[0]?.type?.split("/")[1]
                        )
                      ) {
                        setDefaultproductImages([...newUploadedImages]);
                        setValue("defaultproductImages", [
                          ...newUploadedImages,
                        ]);
                        dispatch(
                          setFirstForm({
                            defaultproductImages: [...newUploadedImages],
                          })
                        );
                      } else {
                        setToastData({
                          message:
                            "Please upload the appropriate type of images as mentioned",
                          status: "failure",
                        });
                        setTimeout(() => {
                          setToastData(constants.defaultToastData);
                        }, 3000);
                      }
                    } else {
                      setDefaultproductImages([...newUploadedImages]);
                      setValue("defaultproductImages", [...newUploadedImages]);
                      dispatch(
                        setFirstForm({
                          defaultproductImages: [...newUploadedImages],
                        })
                      );
                    }
                  } else {
                    setToastData({
                      message:
                        "The maximum allowed image size is 2MB. Please upload an image of less than 2MB.",
                      status: "failure",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                }
              }}
              className={styles.fileInput}
            />

            {nextButtonClicked && defaultproductImages?.length === 0 && (
              <p className={styles.errorMessage}>
                Upload product default image
              </p>
            )}

            <div
              className={`${styles.uploadedFilesContainer} ${
                isReadOnlyMode ? styles.disabledMode : null
              }`}
            >
              {defaultproductImages &&
                defaultproductImages?.map((file: any, index) => (
                  <div key={index} className={styles.uploadedFile}>
                    <img
                      src={file?.url ? file?.url : URL.createObjectURL(file)}
                      alt={`Uploaded Image ${index}`}
                    />
                    <div className={styles.uploadOverlay}>
                      <div className={styles.overlayButtons}>
                        {/* <div className={styles.eyeButton}>
                        <img src={whiteEye} alt="whiteEyeIcon"></img>      View icon will be done in phase 2
                      </div> */}
                        <div
                          className={styles.deleteButton}
                          onClick={removeDefaultProductImage}
                        >
                          <img src={whiteTrash} alt="whitedeleteIcon"></img>
                        </div>
                      </div>
                    </div>
                    <div className={styles.imageName} title={file?.url}>
                      {file?.name
                        ? file?.name?.length > 20
                          ? `${file?.name?.slice(0, 20)}...`
                          : file?.name
                        : extractFileNameFromUrl(file?.url)
                        ? extractFileNameFromUrl(file?.url)?.length > 20
                          ? `${extractFileNameFromUrl(file?.url).slice(
                              0,
                              20
                            )}...`
                          : extractFileNameFromUrl(file?.url)
                        : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={`${styles.multipleUpload} ${styles.mediaMargin}`}>
            <div className={styles.uploadLabel}>
              Upload product media (optional)
            </div>
            <label
              tabIndex={12}
              htmlFor="productImages"
              style={{
                pointerEvents:
                  isReadOnlyMode || totalFiles?.length >= 4 ? "none" : "auto",
                filter:
                  isReadOnlyMode || totalFiles?.length >= 4
                    ? "grayscale(100%)"
                    : "none",
              }}
            >
              <div className={styles.upperText}>
                Drop file here or <span>Upload</span>
              </div>
              <div className={styles.uploadedText}>
                (Max uploads: 04, Formats: JPG, PNG, JPEG)
              </div>
            </label>
            <input
              value={[]}
              hidden
              type="file"
              id="productImages"
              accept=".jpg,.jpeg,.png,.svg"
              multiple
              {...register("productImages")}
              onChange={(e) => {
                const files = e.target.files;
                if (files && files?.length > 0) {
                  const newUploadedImages = Object.keys(files)
                    .map((key: any) => files[key])
                    .slice(0, 4 - totalFiles?.length);
                  let isAnyUploadedFileHaveMoreSize: any = [];
                  for (let i = 0; i < files.length; i++) {
                    if (files[0]["size"] > 2000000) {
                      isAnyUploadedFileHaveMoreSize.push(files[0]);
                    }
                  }
                  if (!isAnyUploadedFileHaveMoreSize.length) {
                    if (e?.target?.accept) {
                      if (
                        e?.target?.accept?.includes(
                          newUploadedImages[0]?.type?.split("/")[1]
                        )
                      ) {
                        setTotalFiles((prevTotalFiles: any) => [
                          ...prevTotalFiles,
                          ...newUploadedImages,
                        ]);

                        setValue("productImages", [
                          ...totalFiles,
                          ...newUploadedImages,
                        ]);
                        dispatch(
                          setFirstForm({
                            productImages: [
                              ...totalFiles,
                              ...newUploadedImages,
                            ],
                          })
                        );
                      } else {
                        setToastData({
                          message:
                            "Please upload the appropriate type of images as mentioned",
                          status: "failure",
                        });
                        setTimeout(() => {
                          setToastData(constants.defaultToastData);
                        }, 3000);
                      }
                    } else {
                      setTotalFiles((prevTotalFiles: any) => [
                        ...prevTotalFiles,
                        ...newUploadedImages,
                      ]);

                      setValue("productImages", [
                        ...totalFiles,
                        ...newUploadedImages,
                      ]);
                      dispatch(
                        setFirstForm({
                          productImages: [...totalFiles, ...newUploadedImages],
                        })
                      );
                    }
                  } else {
                    setToastData({
                      message:
                        "The maximum allowed image size is 2MB. Please upload an image of less than 2MB.",
                      status: "failure",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                }
              }}
              className={styles.fileInput}
            />

            {/* {nextButtonClicked && totalFiles?.length === 0 && (
              <p className={styles.errorMessage}>Upload product images</p>
            )} */}

            <div
              className={`${styles.uploadedFilesContainer} ${
                isReadOnlyMode ? styles.disabledMode : null
              }`}
            >
              {totalFiles &&
                totalFiles.map((file: any, index) => (
                  <div key={index} className={styles.uploadedFile}>
                    <img
                      src={file?.url ? file?.url : URL.createObjectURL(file)}
                      alt={`Uploaded Image ${index}`}
                    />
                    <div className={styles.uploadOverlay}>
                      <div className={styles.overlayButtons}>
                        {/* <div className={styles.eyeButton}>
                        <img src={whiteEye} alt="whiteEyeIcon"></img>      View icon will be done in phase 2
                      </div> */}
                        <div
                          className={styles.deleteButton}
                          onClick={() => removeUploadedFile(index)}
                        >
                          <img src={whiteTrash} alt="whitedeleteIcon"></img>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.imageName}
                      title={file?.name ?? file?.url}
                    >
                      {file?.name
                        ? file?.name?.length > 20
                          ? `${file?.name?.slice(0, 20)}...`
                          : file?.name
                        : extractFileNameFromUrl(file?.url)
                        ? extractFileNameFromUrl(file?.url)?.length > 20
                          ? `${extractFileNameFromUrl(file?.url).slice(
                              0,
                              20
                            )}...`
                          : extractFileNameFromUrl(file?.url)
                        : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            tabIndex={13}
            label={"Cancel"}
            large={true}
            customClass={styles.formButtonCancel}
            handleClick={() => {
              dispatch(resetWholeForm());
              dispatch(resetProductDetails());
              navigate(routeConfig?.adminAddBuyer);
            }}
          />
          <Button
            tabIndex={14}
            label={"Next"}
            large={true}
            customClass={styles.formButton}
            handleClick={() => {
              setNextButtonClicked(true);
            }}
          />
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </form>
    );
  }
);

const AddIndividualTwoTop = React.memo(
  ({
    isIncludeWithVAT,
    setIsIncludeWithVAT,
    secondFormShow,
    setSecondFormShow,
    isReadOnlyMode,
    singleProductDetails,
    productId,
    isTheProductsData,
    selectedCertifications,
    setSelectedCertifications,
    setIsTheProductsData,
    isApiDataSet,
    setIsApiDataSet,
  }: {
    isIncludeWithVAT: boolean;
    setIsIncludeWithVAT: Dispatch<SetStateAction<boolean>>;
    secondFormShow: boolean;
    setSecondFormShow: Dispatch<SetStateAction<boolean>>;
    setSelectedCertifications: Dispatch<SetStateAction<string[]>>;
    setIsTheProductsData: Dispatch<SetStateAction<string[]>>;
    isReadOnlyMode: boolean;
    singleProductDetails: any;
    productId?: string;
    isTheProductsData: string[];
    selectedCertifications: string[];
    isApiDataSet: boolean;
    setIsApiDataSet: Dispatch<SetStateAction<boolean>>;
  }) => {
    const { addIndividualProductForm } = useSelector((state: any) => state);
    const dispatch = useDispatch();

    const [orderAmount, setOrderAmount] = useState(0);
    const [off, setOff] = useState(0);
    const [buttonLabel, setButtonLabel] = useState("Add");

    const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
    const [selectedMeasuringUnit, setSelectedMeasuringUnit] =
      useState<IOrderingOption>({});
    const [equalsTo, setEqualsTo] = useState<IEqualTo[]>([]);
    const [selectedEqualsTo, setSelectedEqualsTo] = useState<IEqualTo>({});
    const [tableData, setTableData] = useState<IProductDetailsWithDiscount[]>(
      addIndividualProductForm?.secondTopForm
        ?.addedProductDetailsWithDiscount || []
    );
    const [toastData, setToastData] = useState(constants.defaultToastData);
    // const { productId } = useParams();

    const schema = yup.object().shape({
      orderingOption: yup
        .object()
        .required("Select ordering option unit")
        .test("option-test", "Select Ordering option unit", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      orderingOptionValue: yup
        .number()
        .positive()
        .moreThan(0, "Enter positive number greater than 0")
        .required("Enter ordering option value")
        .typeError("Enter ordering option  value "),
      moq: yup
        .number()
        .positive()
        .moreThan(0, "Enter positive number")
        .max(999, "Exceeded the maximum MOQ (999)")
        .required("Enter MOQ number")
        .typeError(" Enter MOQ number")
        .test("test", "enter positive", (value) => value > 0),
      equalto: yup
        .object()
        .required("Select equal to unit option")
        .test("option-test", "Select equal to unit option", (option) =>
          Object.keys(option)?.length > 0 ? true : false
        ),
      enterNumber: yup
        .number()
        .positive()
        .moreThan(0, "Enter positive number")
        .typeError("Enter an equivalent number")
        .required("Enter number"),
      orderAmount: yup
        .number()
        .positive()
        .max(2147483647, "Exceeded the maximum order amount (2147483647)")
        .moreThan(0, "Enter amount greater than 0")
        .test("is-decimal", "Order amount should not in decimal", (value) =>
          Number.isInteger(value)
        )
        .typeError("Enter order amount")
        .required("Enter order amount"),
      off: yup
        .number()
        .moreThan(-1, "Enter positive number")
        .typeError("Enter discount")
        .required("Enter discount"),
      discountedAmount: yup
        .number()
        .positive()
        .required("Enter discounted amount")
        .typeError("Enter discounted amount")
        .nullable(),
      // addedProductDetailsWithDiscount: yup.array(),
    });
    const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
      getValues,
      trigger,
      reset,
      watch,
    } = useForm({
      resolver: yupResolver(schema),
    });

    // useEffect(() => {
    //   if (addIndividualProductForm?.secondTopForm) {
    //     reset(addIndividualProductForm?.secondTopForm);
    //   }
    // }, [addIndividualProductForm, reset]);

    const onChangeField = React.useCallback(
      (name: any) => (text: any) => {
        setValue(name, text);
        if (name === "orderAmount") {
          setOrderAmount(text);
          if (off) {
            const discountedAmount: string = calculateDiscountedAmount(
              Number(text),
              Number(off)
            );
            setValue("discountedAmount", Number(discountedAmount));
          }
        }
        if (name === "off") {
          setOff(text);
          if (orderAmount) {
            const discountedAmount: string = calculateDiscountedAmount(
              Number(orderAmount),
              Number(text)
            );
            setValue("discountedAmount", Number(discountedAmount));
          }
        }
        if (!watch("off") && !watch("orderAmount")) {
          setValue("discountedAmount", null);
        }
      },
      [off, orderAmount, setValue]
    );

    // get measuring units api call starts
    const measuringUnitsApi = useAxios({
      axiosParams: {
        url: "store/units",
      },
      method: "GET",
    });

    const getMeasuringUnits = useCallback(async () => {
      const response: any = await measuringUnitsApi?.fetchData();
      if (response) {
        const updatedMeasuringUnits = response?.data?.product_units?.map(
          (unit: IEqualTo) => ({
            ...unit,
            value: unit.name,
          })
        );
        setMeasuringUnits(updatedMeasuringUnits);
      }
    }, [measuringUnitsApi]);

    useEffect(() => {
      getMeasuringUnits();
    }, []);

    const handleMeasuringUnitChange = (selectedOption: IOrderingOption) => {
      setSelectedMeasuringUnit(selectedOption);
      const updatedSubUnits = selectedOption?.sub_units?.map(
        (unit: IEqualTo) => ({
          ...unit,
          value: unit.name,
        })
      ) as IEqualTo[];
      setEqualsTo(updatedSubUnits);
    };
    // get measuring units api call ends

    const handleEqualsToChange = (selectedOption: IEqualTo) => {
      setSelectedEqualsTo(selectedOption);
    };

    const submit = () => {
      const formDataWithTableData = {
        addedProductDetailsWithDiscount: tableData,
      };

      const updatedFullData = {
        ...addIndividualProductForm,
        ...formDataWithTableData,
      };

      dispatch(setSecondTopForm(updatedFullData));
    };

    const values = getValues();

    const handleRemoveRow = (indexToRemove: number) => {
      setTableData((prevTableData) =>
        prevTableData.filter((_, index) => index !== indexToRemove)
      );
      // setSelectedEqualsTo({} as any);
      // setSelectedEqualsTo({} as any);
      // reset();
      // setButtonLabel("Add");
      if (editRowIndex === indexToRemove) {
        setSelectedMeasuringUnit({} as any);
        setSelectedEqualsTo({} as any);
        setOff(0);
        setOrderAmount(0);
        reset();
        setButtonLabel("Add");
      } else if (indexToRemove > -1 && indexToRemove < editRowIndex) {
        const latestIndx = editRowIndex - 1;
        setEditRowIndex(latestIndx);
      }
    };
    const calculateDiscountedAmount = (orderAmount: number, off: number) => {
      const discountedAmount = (orderAmount * (100 - off)) / 100;
      const nonNegativeDiscountedAmount = Math.max(discountedAmount, 0);
      values.discountedAmount = Number(nonNegativeDiscountedAmount?.toFixed(2));
      return nonNegativeDiscountedAmount?.toFixed(2);
    };

    const handleAddRow = async (e: Event) => {
      e.preventDefault();
      const isValid = await trigger();
      if (isValid) {
        const formData = getValues();
        if (formData.orderingOption && formData.moq) {
          const discountedAmount = calculateDiscountedAmount(
            formData.orderAmount,
            formData.off
          );
          const doesRowExist = tableData.some((row) => {
            return (
              Number(row?.discountedAmount) === formData?.discountedAmount &&
              row?.enterNumber === formData?.enterNumber &&
              row?.equalto?.value === (formData?.equalto as any)?.value &&
              row?.moq === formData?.moq &&
              row?.off === formData?.off &&
              row?.orderAmount === formData?.orderAmount &&
              row?.orderingOption?.value ===
                (formData?.orderingOption as any)?.value &&
              row?.orderingOptionValue === formData?.orderingOptionValue
            );
          });

          if (doesRowExist) {
            setToastData({
              message: "Duplicate variant found. Please provide a new one.",
              status: "Alert",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
            return;
          }
          const newTableRow = {
            orderingOption: formData.orderingOption,
            orderingOptionValue: formData.orderingOptionValue,
            moq: formData.moq,
            equalto: formData.equalto ? formData.equalto : "",
            enterNumber: formData.enterNumber,
            orderAmount: formData.orderAmount,
            off: formData.off,
            discountedAmount: discountedAmount,
            // id: uuidv4(),
            id: null,
          };

          setTableData(
            (prevTableData) =>
              [...prevTableData, newTableRow] as IProductDetailsWithDiscount[]
          );

          // setValue("addedProductDetailsWithDiscount", [
          //   ...(getValues("addedProductDetailsWithDiscount") || []),
          //   newTableRow,
          // ]);
          const formDataWithUpdatedTableData = {
            ...addIndividualProductForm.secondBottomForm,
            addedProductDetailsWithDiscount: [...tableData, newTableRow],
          };
          dispatch(setSecondTopForm(formDataWithUpdatedTableData));
          setSelectedMeasuringUnit({} as any);
          setSelectedEqualsTo({} as any);
          reset();
          setOff(0);
          setOrderAmount(0);
          if (tableData?.length === 5) {
            return;
          }
        }
      }
    };

    useEffect(() => {
      dispatch(
        setSecondTopForm({ addedProductDetailsWithDiscount: tableData })
      );
    }, [tableData, dispatch]);

    const [editRow, setEditRow] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState<number>(-1);
    const handleEditRow = (index: number) => {
      setEditRow(true);
      setButtonLabel("Update");
      setEditRowIndex(index);
      const rowToEdit: IProductDetailsWithDiscount = tableData[index];
      if (!rowToEdit) return;
      if (rowToEdit) {
        setValue("orderingOption", rowToEdit?.orderingOption);
        setSelectedMeasuringUnit(rowToEdit?.orderingOption);
        setValue("moq", rowToEdit?.moq);
        setValue("equalto", rowToEdit?.equalto);
        setSelectedEqualsTo(rowToEdit?.equalto);
        setValue("enterNumber", rowToEdit?.enterNumber);
        setValue("orderAmount", rowToEdit?.orderAmount);
        setValue("off", rowToEdit?.off);
        setValue("discountedAmount", rowToEdit?.discountedAmount);
        setValue("orderingOptionValue", rowToEdit?.orderingOptionValue);
        setOff(rowToEdit?.off);
        setOrderAmount(rowToEdit?.orderAmount);
      }
    };

    const handleUpdateRow = async (e: Event, index: number) => {
      e.preventDefault();
      const isValid = await trigger();
      if (isValid) {
        const formData = getValues();
        if (formData.orderingOption && formData.moq) {
          const discountedAmount = calculateDiscountedAmount(
            formData.orderAmount,
            formData.off
          );
          const updatedRow: IProductDetailsWithDiscount = {
            orderingOption: formData.orderingOption,
            orderingOptionValue: formData.orderingOptionValue,
            moq: formData.moq,
            equalto: formData.equalto ? formData.equalto : "",
            enterNumber: formData.enterNumber,
            orderAmount: formData.orderAmount,
            off: formData.off,
            discountedAmount: Number(discountedAmount),
            id: tableData[index].id,
          };

          if (editRowIndex !== -1) {
            const updatedTableData = [...tableData];
            updatedTableData[editRowIndex] = updatedRow;
            setTableData(updatedTableData);
            setOff(0);
            setOrderAmount(0);
            setEditRowIndex(-1);
            setSelectedEqualsTo({} as any);
            setSelectedMeasuringUnit({} as any);
            reset();
          } else {
            setTableData((prevTableData) => [...prevTableData, updatedRow]);
            dispatch(
              setSecondTopForm({
                ...addIndividualProductForm?.secondTopForm,
                addedProductDetailsWithDiscount: [
                  ...addIndividualProductForm?.secondTopForm
                    ?.addedProductDetailsWithDiscount,
                  updatedRow,
                ],
              })
            );
            // setValue("addedProductDetailsWithDiscount", [
            //   ...(getValues("addedProductDetailsWithDiscount") || []),
            //   updatedRow,
            // ]);
          }
          reset();
          setButtonLabel("Add");
          setEditRow(false);
          setEditRowIndex(-1);

          if (tableData?.length === 5) {
            return;
          }
        }
      }
    };
    const getUnits = (orderUnitsId: string, equalToUnitsId: string) => {
      const orderUnitsObj = measuringUnits?.find(
        (orderUnit: any) => orderUnit?.id === orderUnitsId
      );
      const updatedSubUnits = orderUnitsObj?.sub_units?.map((sub_unit) => ({
        ...sub_unit,
        value: sub_unit?.name,
      }));
      const updatedorderUnitsObj = {
        ...orderUnitsObj,
        sub_units: updatedSubUnits,
      };
      const equalToUnitsObj = updatedorderUnitsObj?.sub_units?.find(
        (sub_unit) => sub_unit?.sub_unit_id === equalToUnitsId
      );
      return { updatedorderUnitsObj, equalToUnitsObj };
    };
    useEffect(() => {
      let newArray =
        singleProductDetails &&
        singleProductDetails?.variants.map((variant: any) => {
          const { updatedorderUnitsObj, equalToUnitsObj } = getUnits(
            variant?.options[0]?.value,
            variant?.metadata?.selectedUnit
          );
          const orderUnit = updatedorderUnitsObj;
          const amount = variant?.prices[0]?.amount || 0;
          const offer = variant?.metadata?.offer || 0;
          const discountedAmount = amount - amount * (offer / 100);
          const moq = variant?.prices[0]?.minQuantity;
          const equalto = equalToUnitsObj;
          const enterNumber = variant?.metadata?.selectedQuantity;
          return {
            id: variant?.id,
            discountedAmount: discountedAmount,
            // orderingOption: {
            //   value: orderUnit,
            // },
            orderingOption: orderUnit,
            orderingOptionValue: variant?.metadata?.orderingOptionValue,
            orderAmount: amount,
            moq: moq,
            off: offer,
            enterNumber: enterNumber,
            // equalto: {
            //   value: equalto,
            // },
            equalto: equalto,
          };
        });
      if (!(measuringUnits && measuringUnits?.length > 0)) {
        newArray = [];
      }
      if (newArray?.length > 0 && !isApiDataSet) {
        const updatedTableData = tableData.slice();
        newArray.forEach((newItem: any) => {
          const existingItemIndex = updatedTableData.findIndex(
            (item: any) => item?.id === newItem?.id
          );
          if (existingItemIndex !== -1) {
            updatedTableData[existingItemIndex] = newItem;
          } else {
            updatedTableData.push(newItem);
          }
        });
        setTableData(updatedTableData);
        setIsApiDataSet(true);
      }
    }, [singleProductDetails, measuringUnits, productId]);

    return (
      <div className={styles.addIndividualFormTwo}>
        <form
          onSubmit={handleSubmit(submit)}
          key={`variant_${
            (
              addIndividualProductForm?.secondTopForm
                ?.addedProductDetailsWithDiscount ?? []
            )?.length
          }`}
        >
          <div className={styles.producingPricing}>
            <div className={styles.producingPricingForm}>
              <div className={styles.producingPricingText}>Product pricing</div>
              <div className={`${styles.OuterFormBox} ${styles.customPadding}`}>
                <form
                  onSubmit={handleSubmit(submit)}
                  className={styles.towTopForm}
                >
                  <div className={styles.producingPricingTextArea}>
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>Ordering option</div>
                        <Dropdown
                          tabIndex={1}
                          borderRightRemove={true}
                          minWidth="100%"
                          label={""}
                          options={measuringUnits}
                          defaultLabel={
                            addIndividualProductForm?.secondTopForm
                              ?.orderingOption?.name ||
                            selectedMeasuringUnit?.name ||
                            "Select ordering option..."
                          }
                          selectedValue={
                            addIndividualProductForm.secondTopForm
                              ?.orderingOption || selectedMeasuringUnit
                          }
                          id="orderingOption"
                          register={register("orderingOption")}
                          onChange={(selectedOption: IOrderingOption) => {
                            handleMeasuringUnitChange(selectedOption);
                            onChangeField("orderingOption")(selectedOption);
                          }}
                          errorMessage={errors.orderingOption?.message}
                        />
                      </div>
                      <div className={styles.lineDivider}></div>
                      <div className={styles.input}>
                        <div className={`${styles.label} ${styles.hiddenMode}`}>
                          MOQ
                        </div>
                        <InputField
                          tabIndex={2}
                          borderLeftRemove={true}
                          width="100%"
                          id="orderingOptionValue"
                          type="number"
                          onChange={onChangeField("orderingOptionValue")}
                          register={register("orderingOptionValue")}
                          errorMessage={
                            errors.orderingOptionValue?.message ?? ""
                          }
                          minWidth="100%"
                        />
                      </div>
                    </div>
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>Equals to</div>
                        <Dropdown
                          tabIndex={3}
                          borderRightRemove={true}
                          minWidth="100%"
                          label={""}
                          options={equalsTo || []}
                          defaultLabel={
                            addIndividualProductForm?.secondTopForm?.equalto
                              ?.name ||
                            selectedEqualsTo?.name ||
                            "Select equals to..."
                          }
                          selectedValue={
                            addIndividualProductForm.secondTopForm?.equalto ||
                            selectedEqualsTo
                          }
                          id="equalto"
                          onChange={(selectedOption: IEqualTo) => {
                            handleEqualsToChange(selectedOption);
                            onChangeField("equalto")(selectedOption);
                          }}
                          register={register("equalto")}
                          errorMessage={errors.equalto?.message}
                        />
                      </div>
                      <div className={styles.lineDivider}></div>
                      <div className={`${styles.input}  ${styles.smallInput}`}>
                        <div className={styles.label}>&nbsp;</div>
                        <InputField
                          tabIndex={4}
                          borderLeftRemove={true}
                          placeholder="Enter number..."
                          width="100%"
                          id="enterNumber"
                          type="number"
                          onChange={onChangeField("enterNumber")}
                          register={register("enterNumber")}
                          errorMessage={errors.enterNumber?.message ?? ""}
                          minWidth="100%"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.textAreaSecondary} ${styles.flexColumn}`}
                  >
                    <div className={styles.discountTextArea}>
                      <div className={styles.orderTextArea}>
                        <div className={styles.input}>
                          <div className={styles.label}>Order amount</div>
                          <InputField
                            tabIndex={5}
                            placeholder="AED Enter Amount... "
                            width="100%"
                            id="orderAmount"
                            type="number"
                            onChange={onChangeField("orderAmount")}
                            register={register("orderAmount")}
                            errorMessage={errors.orderAmount?.message ?? ""}
                            minWidth="100%"
                            maxWidth="none"
                          />
                        </div>
                      </div>
                      <div className={styles.orderTextArea}>
                        <div className={styles.input}>
                          <div className={styles.label}>% Off</div>
                          <InputField
                            tabIndex={6}
                            borderRightRemove={true}
                            placeholder="Enter %..."
                            width="100%"
                            id="off"
                            onChange={onChangeField("off")}
                            register={register("off")}
                            type="number"
                            errorMessage={errors.off?.message ?? ""}
                            minWidth="100%"
                          />
                        </div>
                        <div className={styles.lineDivider}></div>
                        <div
                          className={`${styles.input} ${styles.discountText}`}
                        >
                          <div className={styles.label}>Discounted Amount</div>
                          <InputField
                            tabIndex={-1}
                            editForm={true} // disabling this field because it's value can be calculated from order amount of off
                            borderLeftRemove={true}
                            placeholder="AED 0.00"
                            width="100%"
                            id="discountedAmount"
                            type="number"
                            onChange={onChangeField("discountedAmount")}
                            register={register("discountedAmount")}
                            errorMessage={
                              errors.discountedAmount?.message
                                ? "Enter discounted amount"
                                : ""
                            }
                            minWidth="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.discountTextArea} ${styles.moqPadding}`}
                    >
                      <div className={styles.orderTextArea}>
                        <div className={styles.input}>
                          <div className={styles.label}>MOQ</div>
                          <InputField
                            tabIndex={7}
                            placeholder="Enter Minimum Order Quantity..."
                            id="moq"
                            type="number"
                            onChange={onChangeField("moq")}
                            register={register("moq")}
                            errorMessage={errors.moq?.message ?? ""}
                            minWidth="56%"
                          />
                        </div>
                      </div>
                      <div className={styles.Addbtn}>
                        {editRow ? (
                          <Button
                            label={buttonLabel}
                            large={true}
                            handleClick={(e: Event) =>
                              handleUpdateRow(e, editRowIndex)
                            }
                          />
                        ) : (
                          <Button
                            tabIndex={8}
                            label={buttonLabel}
                            large={true}
                            handleClick={(e: Event) => handleAddRow(e)}
                            disabled={tableData?.length === 5}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {tableData?.length === 5 ? (
                    <div className={styles.noteMessage}>
                      Note: Maximum 5 product pricing is allowed
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className={styles.tableContainer}>
              {tableData && tableData?.length > 0 && (
                <>
                  <div className={styles.tableHeading}>
                    <div className={styles.tableHeadData1}>
                      Product ordering options
                    </div>
                    <div className={styles.tableHeadData2}>Equals to</div>
                    <div className={styles.tableHeadData3}>Order amount</div>
                    <div className={styles.tableHeadData4}>% Off</div>
                    <div className={styles.line}></div>
                    <div className={styles.tableHeadDat5}>
                      Discounted amount
                    </div>
                  </div>
                  <div className={styles.horizontal}></div>
                </>
              )}
              {tableData &&
                tableData.length > 0 &&
                tableData.map(({ ...row }, index) => (
                  <>
                    <div className={styles.tableData} key={index}>
                      <div className={styles.tableHeadData1}>
                        {row.orderingOption?.value} (Min. order quantity ={" "}
                        {row?.moq})
                      </div>
                      <div className={styles.tableHeadData2}>
                        {`${row.orderingOptionValue} x `}
                        {row.enterNumber} {row.equalto?.value}/
                        {row.orderingOption.value}
                      </div>
                      <div className={styles.tableHeadData3}>
                        AED {Number(row?.orderAmount)?.toFixed(2)}
                      </div>
                      <div className={styles.tableHeadData4}>{row.off}%</div>
                      <div className={styles.line}></div>
                      <div className={styles.tableHeadData5}>
                        AED {Number(row?.discountedAmount).toFixed(2)}
                      </div>
                      <div
                        className={styles.remove}
                        onClick={() => handleEditRow(index)}
                      >
                        <div className={styles.deleteIcon}>
                          <img src={EditIcon} alt="Delete" />
                        </div>
                        <div className={styles.delete}>Edit</div>
                      </div>
                      <div
                        className={styles.remove}
                        onClick={() => handleRemoveRow(index)}
                      >
                        <div className={styles.deleteIcon}>
                          <img src={deleteIcon} alt="Delete" />
                        </div>
                        <div className={styles.delete}>Remove</div>
                      </div>
                    </div>
                    <div className={styles.horizontal}></div>
                  </>
                ))}
            </div>
          </div>
        </form>
        {toastData?.message ? (
          <ToastNotification
            icon={
              toastData.status === "Success"
                ? successToast
                : toastData.status === "Alert"
                ? alertToast
                : errorToast
            }
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
        <AddIndividualPartTwo
          isIncludeWithVAT={isIncludeWithVAT}
          setIsIncludeWithVAT={setIsIncludeWithVAT}
          singleProductDetails={singleProductDetails}
          handleAddRow={handleAddRow}
          // secondFormShow={secondFormShow}
          setSecondFormShow={setSecondFormShow}
          isReadOnlyMode={isReadOnlyMode}
          isTheProductsData={isTheProductsData}
          selectedCertifications={selectedCertifications}
          setSelectedCertifications={setSelectedCertifications}
          setIsTheProductsData={setIsTheProductsData}
        />
      </div>
    );
  }
);

const AddIndividualPartTwo = React.memo(
  ({
    isIncludeWithVAT,
    setIsIncludeWithVAT,
    handleAddRow,
    // secondFormShow,
    setSecondFormShow,
    singleProductDetails,
    isReadOnlyMode,
    isTheProductsData,
    selectedCertifications,
    setSelectedCertifications,
    setIsTheProductsData,
  }: {
    isIncludeWithVAT: boolean;
    setIsIncludeWithVAT: Dispatch<SetStateAction<boolean>>;
    handleAddRow: (e: Event) => void;
    // secondFormShow: boolean;
    setSecondFormShow: Dispatch<SetStateAction<boolean>>;
    setSelectedCertifications: Dispatch<SetStateAction<string[]>>;
    setIsTheProductsData: Dispatch<SetStateAction<string[]>>;
    singleProductDetails: any;
    isReadOnlyMode: boolean;
    isTheProductsData: string[];
    selectedCertifications: string[];
  }) => {
    const { addIndividualProductForm } = useSelector((state: any) => state);
    const [loggedInUser] = useState<any>(
      JSON.parse(localStorage.getItem("loggedInUser") || "{}")
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [certifications, setCertifications] = useState<ICertificate[]>([]);
    const [toastData, setToastData] = useState(constants.defaultToastData);
    const [isTheProduct, setIsTheProduct] = useState<ICertificate[]>([]);
    // const [isTheProductsData, setIsTheProductsData] = useState<string[]>(
    //   singleProductDetails
    //     ? singleProductDetails?.metadata?.productDetails
    //     : addIndividualProductForm?.secondBottomForm?.isProducts || []
    // );
    // const [selectedCertifications, setSelectedCertifications] = useState<
    //   string[]
    // >(addIndividualProductForm?.secondBottomForm?.certifications || []);
    const schema = yup.object().shape({
      certifications: yup
        .mixed()
        .optional()
        .notOneOf([
          "HACCP",
          "FDA",
          "GMP",
          "ISO 22000",
          "Halal",
          "Kosher",
          "BRC",
          "IFS",
        ]),
      isProducts: yup
        .mixed()
        .optional()
        .notOneOf([
          "HACCP",
          "FDA",
          "GMP",
          "ISO 22000",
          "Halal",
          "Kosher",
          "BRC",
          "IFS",
        ]),
    });
    const { warehouseId } = useParams();
    const {
      handleSubmit,
      setValue,
      // getValues,
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        certifications: certifications || [],
        isProducts: isTheProduct || [],
      },
    });
    // get certifications and is the product api call starts
    const certificationsAndIsTheProductApi = useAxios({
      axiosParams: {
        url: "store/product-certificates",
      },
      method: "GET",
    });

    function removeDuplicatesByPropertyName(arr: any[], propertyName: string) {
      const uniqueItems = new Map();

      for (const item of arr) {
        uniqueItems.set(item[propertyName], item);
      }

      return Array.from(uniqueItems.values());
    }

    const getCertificationsAndIsTheProduct = useCallback(async () => {
      const response: any = await certificationsAndIsTheProductApi?.fetchData();
      if (response) {
        const updatedCertifications =
          response?.data?.certificates?.certificates?.map(
            (certificate: ICertificate) => ({
              ...certificate,
              value: certificate.name,
            })
          );
        setCertifications(
          removeDuplicatesByPropertyName(updatedCertifications, "name")
        );
        const updatedIsTheProducts =
          response?.data?.specifications?.specifications?.map(
            (item: ICertificate) => ({
              ...item,
              value: item.name,
            })
          );
        setIsTheProduct(
          removeDuplicatesByPropertyName(updatedIsTheProducts, "name")
        );
      }
    }, [certificationsAndIsTheProductApi]);

    useEffect(() => {
      getCertificationsAndIsTheProduct();
    }, []);
    // get certifications and is the product api call ends

    const handleCertificationToggle = (certification: string) => {
      const currentCertifications = selectedCertifications;
      if (currentCertifications?.includes(certification)) {
        setSelectedCertifications((prevSelected: any) =>
          prevSelected.filter((selected: any) => selected !== certification)
        );
        const updatedCertifications = currentCertifications.filter(
          (c: string) => c !== certification
        );
        setValue("certifications", updatedCertifications);
      } else {
        if (currentCertifications?.length < 5) {
          setSelectedCertifications((prevSelected: any) => [
            ...prevSelected,
            certification,
          ]);
          const updatedCertifications = [
            ...currentCertifications,
            certification,
          ];
          setValue("certifications", updatedCertifications);
          if (addIndividualProductForm) {
            dispatch(
              setSecondBottomForm({
                ...addIndividualProductForm,
                certifications: updatedCertifications,
              })
            );
          }
        }
      }
    };
    const handleIsTheProductToggle = (item: string) => {
      const currentIsProducts = isTheProductsData;
      if (currentIsProducts?.includes(item)) {
        setIsTheProductsData((prevSelected: any) =>
          prevSelected.filter((selected: any) => selected !== item)
        );
        const updatedIsProducts = currentIsProducts.filter(
          (i: string) => i !== item
        );
        setValue("isProducts", updatedIsProducts);
      } else {
        if (currentIsProducts && currentIsProducts?.length < 5) {
          setIsTheProductsData((prevSelected: any) => [...prevSelected, item]);
          const updatedIsProducts = [...currentIsProducts, item];
          setValue("isProducts", updatedIsProducts);
          if (addIndividualProductForm) {
            dispatch(
              setSecondBottomForm({
                ...addIndividualProductForm,
                isProducts: updatedIsProducts,
              })
            );
          }
        }
      }
    };
    const transformVariantPayload = (
      tableData: IProductDetailsWithDiscount[]
    ) => {
      const variants = tableData?.map((row: IProductDetailsWithDiscount) => {
        const prices: IPriceVariants[] = [];
        const options: IOptions[] = [];
        prices.push({
          amount: row?.orderAmount,
          currency_code: "aed",
          min_quantity: row?.moq,
        });
        options.push({
          value: row?.orderingOption?.id,
        });
        return {
          id: row?.id ?? null,
          title: addIndividualProductForm?.firstForm?.productName ?? "title",
          inventory_quantity: 10000,
          prices: prices,
          metadata: {
            selected_unit: row?.equalto?.sub_unit_id,
            selected_quantity: row?.enterNumber,
            offer: row?.off,
            equal_to_unit_name: row?.equalto?.name,
            ordering_option_unit_name: row?.orderingOption?.name,
            ordering_option_value: row?.orderingOptionValue,
          },
          options: options,
        };
      });
      return variants;
    };
    const transformTags = (tags: string) => {
      if (tags) {
        const updatedTags = tags.split(",");
        if (updatedTags?.length > 0) {
          const finalTags = updatedTags?.map((tag: any) => ({ value: tag }));
          return finalTags;
        }
      }
    };
    function transformImagePayload(
      defaultImgUrl: { id: string | null; url: string }[],
      productImagesUrl: { id: string | null; url: string }[]
    ) {
      const finalImgPayload = [];
      if (defaultImgUrl?.length > 0) {
        finalImgPayload.push({
          ...defaultImgUrl[0],
          metadata: { default_image: true },
        });
      }
      if (productImagesUrl?.length > 0) {
        finalImgPayload.push(...productImagesUrl);
      }
      return finalImgPayload;
    }
    const postData = useAxios({
      axiosParams: {
        url: "admin/supplier/addproducts",
        data: addIndividualProductForm && {
          title: addIndividualProductForm?.firstForm?.productName,
          is_vat_included:
            addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT ??
            false,
          handle: `${addIndividualProductForm?.firstForm?.productName}${loggedInUser?.id}`,
          ware_house_id: warehouseId,
          description: addIndividualProductForm?.firstForm?.productDescription,
          status: "draft",
          options: [
            {
              title: "ordering_option",
            },
          ],
          variants: transformVariantPayload(
            addIndividualProductForm?.secondTopForm
              ?.addedProductDetailsWithDiscount
          ),
          sales_channels: [],
          is_deactivated: null,
          brand: {
            id: addIndividualProductForm?.firstForm?.productBrandApiData?.id,
          },
          supplier: {
            id: warehouseId,
            // id: supplierId,
          },
          metadata: {
            certifications:
              addIndividualProductForm?.secondBottomForm?.certifications,
            product_details:
              addIndividualProductForm?.secondBottomForm?.isProducts,
          },
          tags: transformTags(addIndividualProductForm?.firstForm?.productTags),
          categories: [
            {
              id: addIndividualProductForm?.firstForm?.productCategory?.id,
            },
          ],
          sub_category: {
            id: addIndividualProductForm?.firstForm?.productSubCategory?.id,
          },
          sub_sub_category: {
            id: addIndividualProductForm?.firstForm?.productSection?.id,
          },
          countries: {
            iso_2:
              addIndividualProductForm?.firstForm?.productCountryOrigin?.iso_2,
          },
          hs_code: addIndividualProductForm?.firstForm?.hsCode,
          product_code:
            addIndividualProductForm?.firstForm?.firstForm?.supplierProductCode,
          images: transformImagePayload(
            addIndividualProductForm?.firstForm?.defaultproductImagesUrl,
            addIndividualProductForm?.firstForm?.productImagesUrl
          ),
        },
      },
      method: "POST",
    });

    const postFullformData = async () => {
      if (loggedInUser?.id && addIndividualProductForm) {
        dispatch(updateIsLoading(true));
        const response: any = await postData?.fetchData();
        if (response && response?.status == "SUCCESS") {
          dispatch(resetWholeForm());
          dispatch(resetProductDetails());
          navigate(routeConfig.supplierBusinessApprovalPage);
        } else {
          setToastData({
            message: "Oops! Something went wrong. Please retry.",
            status: "Failure",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
        dispatch(updateIsLoading(false));
      }
    };

    const submit = (data: any) => {
      const formDataWithSelections = {
        ...data,
        certifications: selectedCertifications,
        isProducts: isTheProductsData,
      };
      const updatedFullData = {
        ...addIndividualProductForm,
        partTwoData: formDataWithSelections,
      };
      dispatch(setSecondBottomForm(updatedFullData));
    };

    return (
      <>
        <form
          onSubmit={handleSubmit(submit)}
          className={styles.certificateForm}
        >
          <div className={styles.checkInput} tabIndex={9}>
            <CheckboxButton
              checked={isIncludeWithVAT}
              handleChange={() => {
                setIsIncludeWithVAT(!isIncludeWithVAT);
              }}
            />
            <span
              style={{ cursor: "pointer", fontFamily: "UrbanistSemiBold" }}
              onClick={() => {
                setIsIncludeWithVAT(!isIncludeWithVAT);
              }}
            >
              Included VAT
            </span>
          </div>
          <div className={styles.certificationsText}>
            <div className={styles.text1}>Certifications (Optional)</div>
            <div className={styles.text2}>
              {constants.addIndividualProduct.optional}
            </div>
          </div>
          <div className={styles.checkboxFrame}>
            {certifications?.map((certificate) => {
              const isChecked = selectedCertifications?.includes(
                certificate.value
              );
              const isDisabled =
                selectedCertifications?.length === 5 && !isChecked;
              // const isMatching =
              //   singleProductDetails?.metadata?.certifications?.includes(
              //     certificate?.value
              //   );

              return (
                <div
                  className={`${styles.checkInput} ${
                    isDisabled ? styles.disabledCheckbox : ""
                  }`}
                  key={certificate.id}
                >
                  <CheckboxButton
                    checked={isChecked}
                    handleChange={() =>
                      handleCertificationToggle(certificate.value)
                    }
                    disabled={isDisabled || isReadOnlyMode}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      pointerEvents: isReadOnlyMode ? "none" : "auto",
                    }}
                    onClick={() => handleCertificationToggle(certificate.value)}
                  >
                    {certificate.value}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={styles.certificationsText}>
            <div className={styles.text1}>Is the product? (Optional)</div>
            <div className={styles.text2}>
              {constants.addIndividualProduct.optionalHeading}
            </div>
          </div>
          <div className={styles.checkboxFrame}>
            {isTheProduct?.map((item) => {
              // const isMatching =
              //   singleProductDetails?.metadata?.productDetails?.includes(
              //     item.value
              //   );

              const isChecked = isTheProductsData?.includes(item.value);
              const isDisabled = isTheProductsData?.length === 5 && !isChecked;

              return (
                <div
                  className={`${styles.checkInput} ${
                    isDisabled ? styles.disabledCheckbox : ""
                  }`}
                  key={item.id}
                >
                  <CheckboxButton
                    checked={isChecked}
                    handleChange={() => handleIsTheProductToggle(item.value)}
                    disabled={isDisabled || isReadOnlyMode}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      pointerEvents: isReadOnlyMode ? "none" : "auto",
                    }}
                    onClick={() => handleIsTheProductToggle(item.value)}
                  >
                    {item.value}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={styles.buttonContainer2}>
            <Button
              label={"Previous"}
              large={true}
              customClass={styles.formButtonCancel}
              handleClick={() => setSecondFormShow(false)}
            />
            <Button
              label={"Link Product"}
              large={true}
              customClass={styles.formButton}
              handleClick={(e: Event) => {
                if (
                  addIndividualProductForm &&
                  addIndividualProductForm?.firstForm &&
                  addIndividualProductForm?.secondTopForm &&
                  addIndividualProductForm?.secondBottomForm &&
                  addIndividualProductForm?.secondTopForm
                    ?.addedProductDetailsWithDiscount?.length > 0
                ) {
                  postFullformData();
                } else {
                  setToastData({
                    message: "Please add atleast One Variant",
                    status: "Failure",
                  });
                  setTimeout(() => {
                    setToastData(constants.defaultToastData);
                  }, 3000);
                  // handleAddRow(e);
                }
              }}
            />
          </div>
        </form>
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={3000}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
);

const AdminLinkProduct = () => {
  const [navToLinkProduct, setnavToLinkProduct] = useState(false);
  const [productId, setProductId] = useState();
  const [isReadOnlyMode] = useState(false);
  const [isApiDataSet, setIsApiDataSet] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { singleProductDetails } = useSelector((state: any) => state);
  const getProductDetails = useAxios({
    axiosParams: {
      url: `store/customproduct/${productId}`,
    },
    method: "GET",
  });
  const { addIndividualProductForm } = useSelector((state: any) => state);

  const getProductDetailsData = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductDetails.fetchData();
    if (response && response?.product) {
      dispatch(getSingleProductDetails(response?.product));
    }
    dispatch(updateIsLoading(false));
  }, [dispatch, getProductDetails, singleProductDetails]);

  useEffect(() => {
    if (productId) {
      getProductDetailsData();
    }
  }, [productId]);
  const [secondFormShow, setSecondFormShow] = useState<boolean>(false);

  const breadcrumbItems = [
    {
      label: "Supplier Approval",
      link: routeConfig.supplierBusinessApprovalPage,
    },
    {
      label: "Link Product",
    },
  ];
  useEffect(() => {
    if (location.pathname.includes("link-product")) {
      setnavToLinkProduct(true);
    }
  }, [location, location?.pathname]);
  const [selectedCertifications, setSelectedCertifications] = useState<
    string[]
  >(
    addIndividualProductForm?.secondBottomForm?.certifications
      ? addIndividualProductForm?.secondBottomForm?.certifications
      : []
  );
  const [isTheProductsData, setIsTheProductsData] = useState<string[]>(
    addIndividualProductForm?.secondBottomForm?.isProducts
      ? addIndividualProductForm?.secondBottomForm?.isProducts
      : []
  );
  const [isIncludeWithVAT, setIsIncludeWithVAT] = useState<boolean>(
    addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT
      ? addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT
      : false
  );
  useEffect(() => {
    if (singleProductDetails) {
      setIsIncludeWithVAT(singleProductDetails?.isVatIncluded ?? false);
    }
  }, [singleProductDetails]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          isIncludeWithVAT: isIncludeWithVAT,
        })
      );
    }
  }, [isIncludeWithVAT]);

  useEffect(() => {
    setSelectedCertifications(singleProductDetails?.metadata?.certifications);
    setIsTheProductsData(singleProductDetails?.metadata?.productDetails);
  }, [singleProductDetails]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          isProducts: isTheProductsData,
        })
      );
    }
  }, [isTheProductsData]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          certifications: selectedCertifications,
        })
      );
    }
  }, [selectedCertifications]);
  useEffect(() => {
    return () => {
      dispatch(resetProductDetails());
      dispatch(resetWholeForm());
    };
  }, []);
  return (
    <div className={styles.app}>
      <div className={styles.rightBodyContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.breadcrumb}>
            <BreadCrumb items={breadcrumbItems} />
          </div>
        </div>
        <div className={`${styles.outerBox} `}>
          <div className={styles.catalogueMargin}>
            <CatalogueInfo item={!secondFormShow} filledTab={secondFormShow} />
          </div>
          {!secondFormShow ? (
            <div
              className={`${styles.horizontal} ${styles.horizontalTop}`}
            ></div>
          ) : null}
          <div
            className={`${styles.OuterFormBox} ${
              styles.addIndividualFormBlock
            } ${secondFormShow && styles.outerFormBox2}`}
          >
            {!secondFormShow ? (
              <AddIndividualPartOne
                singleProductDetails={singleProductDetails}
                isReadOnlyMode={isReadOnlyMode}
                secondFormShow={secondFormShow}
                setSecondFormShow={setSecondFormShow}
                navToLinkProduct={navToLinkProduct}
                productId={productId}
                setProductId={setProductId}
              />
            ) : (
              <AddIndividualTwoTop
                isIncludeWithVAT={isIncludeWithVAT}
                setIsIncludeWithVAT={setIsIncludeWithVAT}
                singleProductDetails={singleProductDetails}
                isReadOnlyMode={isReadOnlyMode}
                secondFormShow={secondFormShow}
                setSecondFormShow={setSecondFormShow}
                productId={productId}
                selectedCertifications={selectedCertifications}
                isTheProductsData={isTheProductsData}
                setSelectedCertifications={setSelectedCertifications}
                setIsTheProductsData={setIsTheProductsData}
                isApiDataSet={isApiDataSet}
                setIsApiDataSet={setIsApiDataSet}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(AdminLinkProduct);
