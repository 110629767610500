import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import changePassword from "../../../assets/images/changePassword.svg";
import changePhone from "../../../assets/images/changePhoneNumber.svg";
import editImage from "../../../assets/images/edit.svg";
import mail from "../../../assets/images/mail.svg";
import phone from "../../../assets/images/phone-call_black.svg";
import fileUpload from "../../../assets/images/uploadIcon.svg";
import trashCan from "../../../assets/images/whiteTrash.svg";
import { MyProfilePros } from "../../../models/IMyProfile";
import { IProfileEditCardsProps } from "../../../models/IProfileEditCardsProps";
import { ResetPasswordComp } from "../../../pages/buyer/login/resetPassword/ResetPassword";
import ConfirmContactNo from "../../../pages/supplier/confirmContactNo/ConfirmContactNo";
import styles from "./MyProfile.module.scss";
import EditProfile from "./editProfile/EditProfile";
import { useAxios } from "../../../services/useAxios";
import { APIMethods, resetMyProfile } from "../../constants/urlConstants";
import useFileUpload from "../../../services/useUploadImage";
import { ToastNotification } from "../../components/toastNotification/ToastNotification";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import {
  DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO,
  constants,
} from "../../constants/constants";
import { setProfileImage } from "../../../reducerSlices/ProfileImageSlice";
import { useDispatch } from "react-redux";
import Loader from "../../components/loader/Loader";
import imageCompression from "browser-image-compression";

const ProfileEditCards = ({ label, logo, onClick }: IProfileEditCardsProps) => {
  return (
    <div className={styles.card} onClick={onClick}>
      <div>
        <img src={logo} alt="cardLogo" />
      </div>
      <div className={styles.cardLabel}>{label}</div>
    </div>
  );
};
const MyProfile = ({
  backNavigation,
  setIsBack,
  setBackNavigation,
  setProfileHeader,
  setIsDrawer,
}: MyProfilePros) => {
  const [isEditProfile, setEditProfile] = useState(false);
  const [isChangePassword, setChangePassword] = useState(false);
  const [isChangeContact, setIsChangeContact] = useState(false);
  // const [profileImage, setprofileImage] = useState("");
  const profile = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [loading, setLoading] = useState(false);
  let formattedRoleKey = "N/A";

  if (profile?.business_details?.[0]?.role_key) {
    const roleKeyParts = profile.business_details?.[0].role_key.split("-");
    const roleKeyWithoutFirstPart = roleKeyParts.slice(1).join(" ");
    formattedRoleKey =
      roleKeyWithoutFirstPart.charAt(0).toUpperCase() +
      roleKeyWithoutFirstPart.slice(1);
  }
  const [accountDetails, setAccountDetails] = useState({
    username:
      profile?.first_name === null || ""
        ? "User"
        : `${profile.first_name} ${profile.last_name}`,
    role: formattedRoleKey ?? "N/A",
    email: profile?.email,
    phone:
      profile && profile?.phone && profile?.phone
        ? profile?.phone?.split("#")?.join("-")
        : "N/A",
    avatarImage: profile?.profile_image,
  });

  const dispatch = useDispatch();

  // const getUserDetails = useAxios({
  //   axiosParams:{
  //     url:`store/customer/${profile?.id}`
  //   }
  // })
  // const getUserDetailsAPi = async  ()=>{
  //   const response: any = await getUserDetails?.fetchData()
  //   if(response && response?.status === "SUCCESS"){
  //     profile['phone'] = response?.data?.phone?.split('#')?.join('-');
  //     localStorage.setItem("loggedInUser", JSON.stringify(profile));
  //   }
  // }

  // useEffect(()=>{
  //   if(profile?.id){
  //     getUserDetailsAPi()
  //   }
  // },[profile?.id])

  useEffect(() => {
    setAccountDetails(accountDetails);
  }, [accountDetails]);

  const handleEditProfile = () => {
    setEditProfile(true);
    setIsBack && setIsBack(true);
    setBackNavigation && setBackNavigation(false);
    setProfileHeader && setProfileHeader("Edit Profile");
  };

  const handleChangePassword = () => {
    setChangePassword(true);
    setIsBack && setIsBack(true);
    setBackNavigation && setBackNavigation(false);
    setProfileHeader && setProfileHeader("Change login password");
  };

  const handleChangeContact = () => {
    setIsChangeContact(true);
    setIsBack && setIsBack(true);
    setBackNavigation && setBackNavigation(false);
    setProfileHeader && setProfileHeader("Change contact number");
  };

  useEffect(() => {
    if (backNavigation === true) {
      setChangePassword(false);
      setEditProfile(false);
      setIsChangeContact(false);
      setProfileHeader && setProfileHeader("My Profile");
    }
  }, [backNavigation]);

  const getNameInitials = (name: string) => {
    const words = name?.trim()?.split(" ");

    if (words?.length === 1) {
      return words[0]?.substring(0, 2)?.toUpperCase();
    } else if (words?.length >= 2) {
      const initials = words
        .map((word) => word?.charAt(0)?.toUpperCase())
        .join("");
      return initials?.substring(0, 2);
    } else {
      return "";
    }
  };

  const avatarInitials = getNameInitials(
    accountDetails && accountDetails?.username
  );

  const resetProfilePhotoAPI = useAxios({
    axiosParams: {
      url: `${resetMyProfile?.resetProfilePhoto}${profile?.id}`,
    },
    method: APIMethods.post,
  });

  const deleteProfilePhotoAPI = useAxios({
    axiosParams: {
      url: `${resetMyProfile?.resetProfilePhoto}${profile?.id}`,
    },
    method: APIMethods.delete,
  });

  const { uploadFile } = useFileUpload();

  // const handleSetProfileImage = async (profileImage:string[]) => {
  //   setLoading(true);
  //   const resetPhotoResponse: any = await resetProfilePhotoAPI.fetchData({
  //     axiosParams: {
  //       url: `${resetMyProfile?.resetProfilePhoto}${profile?.id}`,
  //       data: {
  //         profile_image: profileImage?.[0],
  //       },
  //     },
  //     method: APIMethods.post,
  //   });
  //   setLoading(false);
  //   if (resetPhotoResponse?.status === "SUCCESS") {
  //     localStorage.setItem("loggedInUser", JSON.stringify(profile));
  //     dispatch(setProfileImage(profileImage?.[0]));
  //   }
  // };
  // useEffect(() => {
  //   if (profileImage) {
  //     handleSetProfileImage();
  //   }
  // }, [profileImage]);

  async function displayFile(e: any) {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024;

    setLoading(true);

    try {
      if (fileSize > 1) {
        setToastData({
          message: "File size should be less than or equal to 1MB",
          status: "error",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 2000);
        setLoading(false);
        return;
      }
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(selectedFile, options);
      const fileUploadResponse: any = await uploadFile(compressedFile);
      if (fileUploadResponse?.status === "SUCCESS") {
        setTimeout(async () => {
          const profileImageUrl = fileUploadResponse?.data?.[0];
          const resetPhotoResponse: any = await resetProfilePhotoAPI.fetchData({
            axiosParams: {
              url: `${resetMyProfile?.resetProfilePhoto}${profile?.id}`,
              data: {
                profile_image: profileImageUrl,
              },
            },
            method: APIMethods.post,
          });
          if (resetPhotoResponse?.status === "SUCCESS") {
            profile.profile_image = profileImageUrl;
            setAccountDetails({
              ...accountDetails,
              avatarImage: profileImageUrl,
            });
            dispatch(setProfileImage(fileUploadResponse.data));
            localStorage.setItem("loggedInUser", JSON.stringify(profile));
            dispatch(setProfileImage(profileImageUrl));
            setLoading(false);
          }
          // setImage(fileUploadResponse.data);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  async function deletePhoto() {
    setLoading(true);

    const response: any = await deleteProfilePhotoAPI.fetchData();
    setLoading(false);

    if (response.status === "SUCCESS") {
      profile.profile_image = "";
      setAccountDetails({ ...accountDetails, avatarImage: "" });
      localStorage.setItem("loggedInUser", JSON.stringify(profile));
      dispatch(setProfileImage(""));
      // issue while reuploading image when toast exists so restrict user while uploading files when toast exists temporarily
      setToastData({
        message: "Profile photo has been deleted successfully.",
        status: "active",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 2000);
    }
  }
  const AvatarContainer = styled("div")`
    position: absolute;
    z-index: 2;
    left: 20px;
    bottom: 22px;
    border-radius: 50%;
    cursor: pointer;
    & .MuiAvatar-root {
      height: 134px;
      width: 134px;
      background: #4c862e;
      font-size: 64px;
      line-height: normal;
      margin-top: 14px;
      text-align: left;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      letter-spacing: 0.32px;
      position: absolute;
      bottom: 0;
    }
    .userName {
      color: #fff;
      font-family: "UrbanistLight";
      font-size: 16px;
      line-height: normal;
      margin-top: 14px;
      text-align: left;
    }
  `;

  if (isChangePassword && !backNavigation) {
    return <ResetPasswordComp isLoggedIn />;
  } else if (isEditProfile && !backNavigation) {
    return <EditProfile />;
  } else if (isChangeContact && !backNavigation) {
    return <ConfirmContactNo overlayCallback={setIsDrawer} />;
  } else {
    return (
      <>
        <Loader isLoading={loading} />

        {
          <div className={styles.myProfileContainer}>
            <div className={styles.formContainer}>
              <div
                className={`${styles.avatarContainer} ${
                  toastData?.message
                    ? styles.pointerEventsNone
                    : styles.pointerEventsAuto
                }`}
              >
                <div
                  className={`${styles.avatar} ${
                    toastData?.message
                      ? styles.avatarHoverDelay
                      : styles.avatarHover
                  }`}
                >
                  <AvatarContainer className={styles.xyz}>
                    {accountDetails.avatarImage ? (
                      <Avatar
                        alt="user-name"
                        src={accountDetails?.avatarImage}
                        data-testid="avatar"
                      />
                    ) : (
                      <Avatar data-testid="avatar-initials">
                        {avatarInitials}
                      </Avatar>
                    )}
                    <div className={styles.upload}>
                      <div className={styles.ButtonContainer}>
                        <input
                          hidden
                          type="file"
                          id="uploadImage"
                          accept=".JPEG,.PNG,.JPG"
                          onChange={(e) => {
                            displayFile(e);
                          }}
                        />
                        <label htmlFor="uploadImage">
                          <div className={styles.uploadButton}>
                            <img src={fileUpload} alt="uplaod" />
                            Upload
                          </div>
                        </label>
                        {accountDetails?.avatarImage ? (
                          <div
                            className={styles.deleteButton}
                            onClick={deletePhoto}
                          >
                            <img src={trashCan} alt="delete" />
                            Delete
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </AvatarContainer>
                </div>
              </div>
              <div className={styles.profileContainer}>
                <div className={styles.profileDetails}>
                  <div className={styles.profileName}>
                    {accountDetails?.username}
                  </div>
                  <div className={styles.editProfile}>
                    <img
                      src={editImage}
                      alt="editProfile"
                      onClick={handleEditProfile}
                    />
                    <div
                      className={styles.editProfileText}
                      onClick={handleEditProfile}
                    >
                      Edit
                    </div>
                  </div>
                </div>
                <div className={styles.profileRole}>
                  {profile?.designation || "N/A"}
                </div>
                <div className={styles.divider}></div>
                <div className={styles.contactDetails}>
                  <div className={styles.emailId}>
                    <img src={mail} alt="emailID" /> {accountDetails.email}
                  </div>
                  <div className={styles.phoneNumber}>
                    <img src={phone} alt="phoneNumber" /> {accountDetails.phone}
                  </div>
                </div>
              </div>
              <div className={styles.cardsContainer}>
                <ProfileEditCards
                  label="Change login password"
                  logo={changePassword}
                  onClick={handleChangePassword}
                />
                <ProfileEditCards
                  label="Change contact number"
                  logo={changePhone}
                  onClick={handleChangeContact}
                />
              </div>
            </div>
          </div>
        }
        {toastData?.message ? (
          <ToastNotification
            icon={toastData?.status === "active" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default MyProfile;
