import React, { forwardRef, useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import moreActions from "../../../assets/images/moreActions.svg";
import styles from "./popperComponentStyles.module.scss";

const PopperComponent = forwardRef(
  ({ children, placement = "bottom", arrowClass, paperProps, handleClickAwayFromProp, popperArrowStyles = {} }: any, ref) => {
    const [anchorEl, setAnchorEl] = useState<any | null>(ref);
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    function handleClickAway(e: any) {
      setAnchorEl(null);
      handleClickAwayFromProp && handleClickAwayFromProp()
    }
    return (
      <>
        <>
          {!ref && (
            <div
              style={{
                marginLeft: "auto",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            >
              <img src={moreActions} alt="more actions" />
            </div>
          )}

          <Popper
            sx={{
              zIndex: 2000,
              '&[data-popper-placement*="bottom-start"]': {
                marginTop: "1rem",
              },
              '&[data-popper-placement*="bottom"] .popperArrow': {
                top: 0,
                left: 0,
                marginTop: "-10px",
                marginLeft: "-5px",
                marginRight: 0,
                "&::before": {
                  transformOrigin: "0 100%",
                }, 
                ...popperArrowStyles,      
              },
              '&[data-popper-placement*="top"] .popperArrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-10px",
                marginLeft: "4px",
                marginRight: 0,
                "&::before": {
                  transformOrigin: "100% 0",
                },
              },
              '&[data-popper-placement*="left"] .popperArrow': {
                right: 0,
                marginRight: "-17px",
                marginTop: "-12px",
                marginBottom: 0,
                "&::before": {
                  transformOrigin: "0 0",
                },
              },
            }}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
            // disablePortal={true}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: false,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={50}>
                <Paper {...paperProps}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                      <div
                        ref={setArrowRef}
                        className={`${styles.arrow} ${arrowClass} popperArrow`}
                      />

                      {children}
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      </>
    );
  }
);

export default PopperComponent;
