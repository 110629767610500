import React, { useEffect, useState } from "react";
import styles from "../components/AdminOrdersFiltersStyles.module.scss";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
// import RangeDatePicker from "../../../../common/components/rangeDatePicker/RangeDatePicker";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import DropdownTextField from "../../../../common/components/formFields/dropdown-text_Field/DropdownTextField";
import Button from "../../../../common/components/button/Button";
import { useForm } from "react-hook-form";
import { constants } from "../../../../common/constants/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../common/components/datePicker/DatePicker";
import { setFilterData } from "../../../../reducerSlices/filtersDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
const AdminOrdersFilters = ({
  overlayCallback,
  activeTab,
  isAdmin,
  onSubmitData,
  ShowSupplierListInFilter,
  ShowBuyerListInFilter,
}: any) => {
  // const date = new Date();
  const filtrationKeys = useSelector((state: any) => state?.filteredKeys);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<any>();
  const [deliveredOn, setDeliveredOn] = useState<any>();
  const [supplier, setSupplier] = useState<any>();
  const [buyer, setBuyer] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [startChanged, setStartChanged] = useState(false);
  const [endChanged, setEndChanged] = useState(false);
  const [orderQuantityDropdown, setOrderQuantityDropdown] = useState<any>(
    constants.orderFilters.orderAmountDropdown[0]
  );
  const [orderValueDropdown, setOrderValueDropdown] = useState<any>(
    constants.orderFilters.orderAmountDropdown[0]
  );
  const handleStartDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      setStartDate(selectedDate);
    } else if (selectedDate == null) {
      setStartDate(null);
    }
  };
  const handleDeliveredOn = (selectedDate: Date | null) => {
    if (selectedDate) {
      // const adjustedDate = new Date(selectedDate.toLocaleString());
      setDeliveredOn(selectedDate);
      // setStartChanged(true);
    } else if (selectedDate == null) {
      setDeliveredOn(null);
    }
  };
  // const handleEndDateChange = (selectedDate: Date | null) => {
  //   if (selectedDate) {
  //     const adjustedDate = new Date(selectedDate.toLocaleString());
  //     setEndDate(adjustedDate);
  //     setEndChanged(true);
  //   }
  // };
  const [paymentStatus, setPaymentStatus] = useState({
    paid: false,
    unpaid: false,
  });
  const [deliveryStatusPartially, setDeliveryStatusPartially] = useState(false);
  const [orderStatus, setOrderStatus] = useState({
    delivered: false,
    cancelled: false,
    returned: false,
    deliveryModified: false,
  });

  const [checked, setChecked] = useState(false);
  const handleDeliveryStatusCheck = () => {
    if (!checked && !deliveryStatusPartially) {
      setChecked(true);
      setOrderStatus({
        delivered: true,
        cancelled: true,
        returned: true,
        deliveryModified: true,
      });
    } else {
      setDeliveryStatusPartially(false);
      setChecked(false);
      setOrderStatus({
        delivered: false,
        cancelled: false,
        returned: false,
        deliveryModified: false,
      });
    }
  };

  const isInvalidDateRange =
    startChanged && endChanged && endDate?.getTime() < startDate?.getTime();
  const schema = yup.object({
    buyer: yup.object().nullable(),
    supplier: yup.object().nullable(),
    orderValueDropdown: yup.mixed().optional(),
    orderValue: yup
      .string()
      .nullable()
      .test(
        "order-value-test",
        "Order value should not be negative",
        (orderValue) =>
          orderValue != "" && Number(orderValue) < 0 ? false : true
      ),
    actualAmount: yup
      .string()
      .nullable()
      .test(
        "actual-amount-test",
        "Actual amount should not be negative",
        (actualAmount) =>
          actualAmount != "" && Number(actualAmount) < 0 ? false : true
      ),
    actualAmountDropdown: yup.mixed().optional(),
    orderQuantityDropdown: yup.mixed().optional(),
    orderQuantity: yup
      .string()
      .nullable()
      .test("quantity-test", "Quantity should not be negative", (quantity) =>
        quantity != "" && Number(quantity) < 0 ? false : true
      ),
  });
  const defaultValues = {
    // orderValueDropdown: constants.orderFilters.orderAmountDropdown[0],
    // actualAmountDropdown: constants.orderFilters.orderAmountDropdown[0],
    orderValue: "",
    actualAmount: "",
    // orderQuantityDropdown: constants.orderFilters.orderAmountDropdown[0],
    orderQuantity: "",
  };
  const {
    register,
    formState: { errors },
    // handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  });
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "orderQuantityDropdown") {
        setOrderQuantityDropdown(text);
      } else if (name === "orderValueDropdown") {
        setOrderValueDropdown(text);
      }
    },
    [setValue]
  );
  const resetFilters = () => {
    // setPaymentStatus((payment: any) => {
    //   payment.paid = false;
    //   payment.unpaid = false;
    //   return payment;
    // });
    setPaymentStatus({
      paid: false,
      unpaid: false,
    });
    // setOrderStatus((orderStatus: any) => {
    //   orderStatus.cancelled = false;
    //   orderStatus.delivered = false;
    //   orderStatus.deliveryModified = false;
    //   orderStatus.returned = false;
    //   return orderStatus;
    // });
    setOrderStatus({
      cancelled: false,
      delivered: false,
      deliveryModified: false,
      returned: false,
    });
    setBuyer(null);
    setSupplier(null);
    setStartDate(null);
    setEndDate(null);
    setDeliveredOn(null);
    setStartChanged(false);
    setEndChanged(false);
    setOrderQuantityDropdown(constants.orderFilters.orderAmountDropdown[0]);
    setOrderValueDropdown(constants.orderFilters.orderAmountDropdown[0]);
    reset();
  };

  const getBuyer = (id: string) => {
    if (ShowBuyerListInFilter?.length > 0) {
      return ShowBuyerListInFilter?.find(
        (buyer: any) => buyer?.buyer_id === id
      );
    }
  };

  const getSupplier = (id: string) => {
    if (ShowSupplierListInFilter?.length > 0) {
      return ShowSupplierListInFilter?.find(
        (supplier: any) => supplier?.supplier_id === id
      );
    }
  };

  // Pre set filtered values
  useEffect(() => {
    if (filtrationKeys?.filterData) {
      setPaymentStatus(
        filtrationKeys?.filterData?.paymentStatus
          ? filtrationKeys?.filterData?.paymentStatus
          : {
            paid: false,
            unpaid: false,
          }
      );
      setOrderStatus(
        filtrationKeys?.filterData?.orderStatus
          ? filtrationKeys?.filterData?.orderStatus
          : {
            cancelled: false,
            delivered: false,
            deliveryModified: false,
            returned: false,
          }
      );
      if (filtrationKeys?.filterData?.placedDate) {
        // var timestamp = Date.parse(filtrationKeys?.filterData?.placedDate);
        // console.log("timestamp", timestamp);
        // var dateObject = new Date(timestamp);
        // handleStartDateChange(dateObject);
        // console.log(dateObject, "selectedDate2222");
        // let dateReq = dateObject?.toLocaleString();
        // console.log(dateObject, 'dateReq');

        handleStartDateChange(filtrationKeys?.filterData?.placedDate);
        // setStartChanged(true);
      }
      if (filtrationKeys?.filterData?.deliveredDate) {
        // var timestamp = Date.parse(filtrationKeys?.filterData?.deliveredDate);
        // console.log("timestamp", timestamp);
        // var dateObject = new Date(timestamp);
        // console.log(dateObject, "timestamp2222");
        // let dateReq = dateObject?.toLocaleString();
        setDeliveredOn(filtrationKeys?.filterData?.deliveredDate);
        // setStartChanged(true);
      }
      if (filtrationKeys?.filterData?.buyer) {
        let buyer = getBuyer(filtrationKeys?.filterData?.buyer);
        setBuyer(buyer);
      }
      if (filtrationKeys?.filterData?.supplier) {
        let supplier = getSupplier(filtrationKeys?.filterData?.supplier);
        setSupplier(supplier);
      }
      if (filtrationKeys?.filterData?.orderValue) {
        setValue("orderValue", filtrationKeys?.filterData?.orderValue);
      }
      if (filtrationKeys?.filterData?.orderValueDropdown) {
        let newDropdownvalue = constants.orderFilters.orderAmountDropdown?.find(
          (item) =>
            item?.label === filtrationKeys?.filterData?.orderValueDropdown
        );
        setValue("orderValueDropdown", newDropdownvalue);
        setOrderValueDropdown(newDropdownvalue);
      }
      if (filtrationKeys?.filterData?.orderQuantity) {
        setValue("orderQuantity", filtrationKeys?.filterData?.orderQuantity);
      }
      if (filtrationKeys?.filterData?.orderQuantityDropdown) {
        let newDropdownvalue = constants.orderFilters.orderAmountDropdown?.find(
          (item) =>
            item?.label === filtrationKeys?.filterData?.orderQuantityDropdown
        );
        setValue("orderQuantityDropdown", newDropdownvalue);
        setOrderQuantityDropdown(newDropdownvalue);
      }
      // setDeliveredOn(filtrationKeys?.filteredData?.deliveredDate);
    }
  }, [filtrationKeys]);

  const getDropdownValue = (dropdown: any): string => {
    return typeof dropdown == "string" ? dropdown : dropdown?.value;
  };
  const getValidData = (data: any) => {
    const finalData: any = {};
    if (data?.orderValue && data?.orderValueDropdown) {
      finalData["orderValue"] = data?.orderValue;
      finalData["orderValueDropdown"] = getDropdownValue(
        data?.orderValueDropdown
      );
    }
    if (data?.orderQuantity && data?.orderQuantityDropdown) {
      finalData["orderQuantity"] = data?.orderQuantity;
      finalData["orderQuantityDropdown"] = getDropdownValue(
        data?.orderQuantityDropdown
      );
    }
    if (data?.buyer && Object.keys(data?.buyer)?.length > 0) {
      finalData["buyer"] = data?.buyer?.buyer_id;
    }
    if (startDate) {
      finalData["placedDate"] = startDate;
    }
    // if (endDate) {
    //   finalData["DeliveredDate"] = new Date(endDate);
    // }
    if (deliveredOn) {
      finalData["deliveredDate"] = deliveredOn;
    }
    if (activeTab !== 3) {
      if (paymentStatus?.paid) {
        finalData["paymentStatus"] = {
          ...finalData["paymentStatus"],
          paid: true,
        };
      }
      if (paymentStatus?.unpaid) {
        finalData["paymentStatus"] = {
          ...finalData["paymentStatus"],
          unpaid: true,
        };
      }
    } else {
      if (orderStatus?.cancelled) {
        finalData["orderStatus"] = {
          ...finalData["orderStatus"],
          cancelled: true,
        };
      }
      if (orderStatus?.delivered) {
        finalData["orderStatus"] = {
          ...finalData["orderStatus"],
          delivered: true,
        };
      }
      if (orderStatus?.deliveryModified) {
        finalData["orderStatus"] = {
          ...finalData["orderStatus"],
          deliveryModified: true,
        };
      }
      if (orderStatus?.returned) {
        finalData["orderStatus"] = {
          ...finalData["orderStatus"],
          returned: true,
        };
      }
      if (data?.actualAmount && data?.actualAmountDropdown) {
        finalData["actualAmount"] = data?.actualAmount;
        finalData["actualAmountDropdown"] = getDropdownValue(
          data?.actualAmountDropdown
        );
      }
    }
    if (isAdmin) {
      if (data?.supplier && Object.keys(data?.supplier)?.length > 0) {
        finalData["supplier"] = data?.supplier?.supplier_id;
      }
    }
    return Object.keys(finalData)?.length > 0 ? finalData : null;
  };

  const submit = () => {
    const data = getValues();
    if (!isInvalidDateRange) {
      const finalData = getValidData(data);
      onSubmitData(finalData);
      if (isEmpty(finalData)) {
        dispatch(setFilterData({}));
      } else {
        let newData = { ...finalData, activeFilters: true };
        dispatch(setFilterData(newData));
      }
      overlayCallback({ right: false });
    }
  };
  useEffect(() => {
    const anyOneChecked = orderStatus?.cancelled || orderStatus?.delivered;
    // ||
    // orderStatus?.returned ||
    // orderStatus?.deliveryModified;
    const allChecked = orderStatus?.cancelled && orderStatus?.delivered;
    // &&
    // orderStatus?.returned &&
    // orderStatus?.deliveryModified;
    if (anyOneChecked) {
      setDeliveryStatusPartially(true);
      setChecked(false);
    }
    if (allChecked) {
      setDeliveryStatusPartially(false);
      setChecked(true);
    }
    if (!allChecked && !anyOneChecked) {
      setChecked(false);
      setDeliveryStatusPartially(false);
    }
  }, [orderStatus]);
  return (
    <div className={styles.form} data-testid="admin-order-filters">
      <div className={styles.formInputs}>
        {activeTab !== 3 ? (
          <div className={styles.payment}>
            <div className={styles.paymentLabel}>Payment</div>
            <div className={styles.paymentInput}>
              <div className={styles.paid}>
                <CheckboxButton
                  checked={paymentStatus.paid}
                  handleChange={() =>
                    setPaymentStatus({
                      ...paymentStatus,
                      paid: !paymentStatus.paid,
                    })
                  }
                />
                <div className={styles.paidLabel}>Paid</div>
              </div>
              <div className={styles.paid}>
                <CheckboxButton
                  checked={paymentStatus.unpaid}
                  handleChange={() =>
                    setPaymentStatus({
                      ...paymentStatus,
                      unpaid: !paymentStatus.unpaid,
                    })
                  }
                />
                <div className={styles.paidLabel}>Unpaid</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.status}>
            <div className={styles.statusCheckbox}>
              <CheckboxButton
                partiallySelected={deliveryStatusPartially}
                checked={checked}
                handleChange={handleDeliveryStatusCheck}
              />
              <div className={styles.statusLabel}>Status</div>
            </div>
            <div className={styles.statusInputs}>
              <div className={styles.column}>
                <div className={styles.statusOption}>
                  <CheckboxButton
                    checked={orderStatus?.delivered}
                    handleChange={() =>
                      setOrderStatus({
                        ...orderStatus,
                        delivered: !orderStatus?.delivered,
                      })
                    }
                  />
                  <div>Delivered</div>
                </div>
                {/* <div className={styles.statusOption}>
                  <CheckboxButton
                    checked={orderStatus?.returned}
                    handleChange={() =>
                      setOrderStatus({
                        ...orderStatus,
                        returned: !orderStatus?.returned,
                      })
                    }
                  />
                  <div>Returned</div>
                </div> */}
              </div>
              <div className={styles.column}>
                <div className={styles.statusOption}>
                  <CheckboxButton
                    checked={orderStatus?.cancelled}
                    handleChange={() =>
                      setOrderStatus({
                        ...orderStatus,
                        cancelled: !orderStatus?.cancelled,
                      })
                    }
                  />
                  <div>Cancelled</div>
                </div>
                {/* <div className={styles.statusOption}>
                  <CheckboxButton
                    checked={orderStatus?.deliveryModified}
                    handleChange={() =>
                      setOrderStatus({
                        ...orderStatus,
                        deliveryModified: !orderStatus?.deliveryModified,
                      })
                    }
                  />
                  <div>Delivered (modified)</div>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {/* <div className={styles.date}>
          <RangeDatePicker
            disablePast={false}
            customClass={styles.dateCustomClass}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            isInvalidDateRange={isInvalidDateRange}
            startlabel="Placed on"
            endlabel="Delivered on"
            errorMessage={isInvalidDateRange ? "invalid date range" : ""}
          />
        </div> */}
        <div className={styles.date2}>
          <div>
            <p data-testid="startLabel" className={styles.dateLabel}>
              Placed on
            </p>
            <DatePickerComponent
              key={`placed_on_date`}
              data-testid="datePickerStart"
              date={startDate?.length ?? startDate}
              setDate={setStartDate}
              handleDateChange={handleStartDateChange}
              showOuterError={true}
              placeholder="Enter placed on date"
              disablePast={false}
              disableFuture={true}
              maxWidth={600}
            />
          </div>
          {activeTab >= 2 && (
            <div>
              <p data-testid="startLabel" className={styles.dateLabel}>
                Delivered on
              </p>
              <DatePickerComponent
                key={`delivered_date`}
                data-testid="datePickerStart"
                date={deliveredOn?.length ?? deliveredOn}
                setDate={setDeliveredOn}
                handleDateChange={handleDeliveredOn}
                showOuterError={true}
                placeholder="Enter delivered on date"
                disablePast={false}
                disableFuture={true}
              />
            </div>
          )}
        </div>
        <div className={styles.buyer}>
          <Dropdown
            key={`dropdown_${buyer?.name}`}
            customDropdownStyles={styles.dropDownMargin}
            id={"Buyer"}
            label="Buyer"
            options={ShowBuyerListInFilter}
            minWidth="100%"
            isDropdownOutlineRequired={true}
            register={register("buyer")}
            onChange={(selectedOption: any) => {
              onChangeField("buyer")(selectedOption);
              setBuyer(selectedOption);
            }}
            defaultLabel={buyer?.name ?? "Select the buyer"}
            selectedValue={buyer}
            defaultValue={buyer}
            errorMessage={errors.buyer?.message as any}
            searchField={true}
          />
        </div>
        {isAdmin && (
          <div className={styles.buyer} data-testid="supplier-dropdown">
            <Dropdown
              key={`dropdown_${supplier?.name}`}
              customDropdownStyles={styles.dropDownMargin}
              id={"supplier"}
              label="supplier"
              options={ShowSupplierListInFilter}
              minWidth="100%"
              isDropdownOutlineRequired={true}
              register={register("supplier")}
              onChange={(selectedOption: any) => {
                onChangeField("supplier")(selectedOption);
                setSupplier(selectedOption);
              }}
              defaultLabel={supplier?.name ?? "Select the supplier"}
              selectedValue={supplier}
              defaultValue={supplier}
              errorMessage={errors.supplier?.message as any}
              searchField={true}
            />
          </div>
        )}
        <div className={styles.buyer}>
          <DropdownTextField
            minWidth={"100%"}
            id={"Order Value"}
            label="Order Value"
            dropdownList={constants.orderFilters.orderAmountDropdown}
            textFieldPlaceholder={"Enter price"}
            isCurrenctReq={true}
            registerDropdown={register("orderValueDropdown")}
            onDropdownChange={onChangeField("orderValueDropdown")}
            selectedDropdownValue={orderValueDropdown}
            registerTextField={register("orderValue")}
            onTextFieldChange={onChangeField("orderValue")}
            value={watch("orderValue")}
            errorMessage={errors.orderValue?.message}
            textFieldType={"number"}
          />
        </div>
        {/* {activeTab === 3 && (
          <div className={styles.buyer}>
            <DropdownTextField
              minWidth={"100%"}
              id={"Actual amount"}
              label="Actual amount"
              dropdownList={constants.orderFilters.orderAmountDropdown}
              registerDropdown={register("actualAmountDropdown")}
              onDropdownChange={onChangeField("actualAmountDropdown")}
              selectedDropdownValue={watch("actualAmountDropdown")}
              textFieldPlaceholder={"Enter price"}
              isCurrenctReq={true}
              registerTextField={register("actualAmount")}
              onTextFieldChange={onChangeField("actualAmount")}
              value={watch("actualAmount")}
              errorMessage={errors.actualAmount?.message}
              textFieldType={"number"}
            />
          </div>
        )} */}
        <div className={styles.buyer}>
          <DropdownTextField
            minWidth={"100%"}
            id={"Order quantity"}
            label="Order quantity"
            dropdownList={constants.orderFilters.orderAmountDropdown}
            textFieldPlaceholder={"Enter quantity"}
            registerDropdown={register("orderQuantityDropdown")}
            onDropdownChange={onChangeField("orderQuantityDropdown")}
            selectedDropdownValue={orderQuantityDropdown}
            registerTextField={register("orderQuantity")}
            onTextFieldChange={onChangeField("orderQuantity")}
            value={watch("orderQuantity")}
            errorMessage={errors.orderQuantity?.message}
            textFieldType={"number"}
          />
        </div>
      </div>
      <div className={styles.filterActions}>
        <div className={styles.reset} onClick={resetFilters}>
          Reset
        </div>
        <div>
          <Button
            label={"Cancel"}
            handleClick={() => {
              overlayCallback({ right: false });
            }}
            width="120px"
            secondary={true}
          />
        </div>
        <div>
          <Button
            label={"Confirm"}
            handleClick={() => submit()}
            width="120px"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminOrdersFilters;
