import { useState } from "react";
import styles from "./ProductEllipsisDescription.module.scss";
import { IProductEllipsisDescriptionProps } from "../../../models/IProductEllipsisDescriptionProps";

const ProductEllipsisDescription = ({
  title,
  details,
  showMore,
}: IProductEllipsisDescriptionProps) => {
  const [collapse, setCollapse] = useState(false);
  const collapseText = collapse ? "Read Less" : "Read More";

  const handleEllipsisText = () => {
    setCollapse(!collapse);
  };
  return (
    <>
      <div className={styles.knowYourProduct}>
        <div className={styles.knowYourProductTitle}>{title}</div>
        <p className={styles.knowYourProductContent}>
          {collapse || (details && details?.length < 150)
            ? details
            : details && details?.substring(0, 150) + "..."}
          {showMore && details?.length > 150 ? (
            <span
              onClick={handleEllipsisText}
              className={styles.ellipsisButton}
            >
              {collapseText}
            </span>
          ) : null}
        </p>
      </div>
    </>
  );
};

export default ProductEllipsisDescription;
