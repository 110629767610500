import { default as productImageDummy } from "../../../assets/images/product-image.png";
export const transformData = (data: any) => {
  const offerprice = (actualPrice: number, off: number) => {
    return (actualPrice - (off / 100) * actualPrice).toFixed(2);
  };
  const transformKeys = (item: any) => {
    return {
      id: item?.id,
      productName: item?.title,
      productImage: item?.metadata?.product_image ?? productImageDummy,
      outOfstockStatus: false,
      liked: true,
      offerPercentage: item?.metadata?.offer,
      unit: item?.variant?.metadata?.secondary_ordering_option_label,
      unitValue: item?.variant?.metadata?.secondary_ordering_option_value,
      actualPrice: +item?.unit_price.toFixed(2),
      offerPrice: offerprice(
        +item?.unit_price,
        item?.metadata?.is_special_price_applied ? 0 : +item?.metadata?.offer
      ),
      productDeliveryStatus: true,
      productQuantity: item?.quantity,
      supllierType: data?.warehouse?.type === "offline" ? "offline" : "online",
      created_at: item?.created_at,
      country: item?.country,
      deliveredQuantity: item?.delivered_quantity, // after mark as received
      isSpecialpriceApplied: item?.metadata?.is_special_price_applied,
    };
  };

  // console.log(data, "data");

  const invoiceDetails = {
    invoiceNo: data?.invoice_number,
    paidStatus: data?.orders?.payment_status,
    details: [
      {
        productKey: "Name of Manufacturer/ Packer/ Importer",
        productDescription: data?.supplier?.business_name ?? "N/A",
      },
      {
        productKey: "Order ID",
        productDescription: data?.orders?.order_number,
      },
      {
        productKey: "Sold by",
        productDescription: data?.warehouse?.contact_name ?? "N/A",
      },
      {
        productKey: "Mode of payment",
        productDescription:
          data?.orders?.payment_option_id === "pay_opt_001" ? "COD" : "Online",
        // productDescription: data?.orders?.order_type,
      },
      {
        productKey: "Supplier's contact details",
        productDescription: `Contact number: ${
          data?.outlet?.phone_number && data?.outlet?.phone_number.includes("+")
            ? ""
            : "+"
        }${
          data?.outlet?.phone_number?.split("#").join("-") ?? "N/A" ?? "N/A"
        } \nEmail address: ${data?.supplier?.email_id}`,
      },
      {
        productKey: "Invoice amount",
        productDescription: ` AED ${Number(data?.orders?.total)?.toFixed(2)}`,
      },
      {
        productKey: "Delivery Address (Outlet)",
        productDescription: `${data?.outlet?.addresses?.[0]?.city ?? ""}, ${
          data?.outlet?.addresses?.[0]?.address_1 ?? ""
        }, ${data?.outlet?.addresses?.[0]?.emirate?.name ?? ""}, ${
          data?.outlet?.addresses?.[0]?.country?.display_name ?? ""
        }`,
      },
      {
        productKey:
          data?.orders?.payment_status === "paid"
            ? "Paid amount"
            : "Estimated amount",
        productDescription: `AED ${
          data?.orders?.actual_amount > 0
            ? data?.orders?.actual_amount &&
              Number(data?.orders?.actual_amount)?.toFixed(2)
            : data?.orders?.total && Number(data?.orders?.total)?.toFixed(2)
        }`,
      },
    ],
    productSummary: {
      estimatedSubtotal: data?.orders?.sub_total,
      deliverCharges: data?.orders?.delivery_charge,
      vatCharges:
        data?.orders?.actual_vat > 0
          ? data?.orders?.actual_vat
          : data?.orders?.vat_tax,
      vatPercentage:
        data?.orders?.actual_vat != 0 || data?.orders?.vat_tax != 0 ? 5 : "",
      estimatedAmount: data?.orders?.total || 0,
      recievedAmount: data?.orders?.total || 0,
      totalItem: data?.orders?.product_count,
      totalAmount: data?.orders?.total,
      modifiedAmount: data?.orders?.actual_amount || 0,
    },
    items: data?.orders?.items.map(transformKeys),
  };

  return invoiceDetails;
};

export const IsProductOlderThan30Days = (createdAtDate: any) => {
  const currentDate: any = new Date();
  const timeDifference: any = currentDate - createdAtDate;
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return daysDifference > 30;
};
