import React from "react";
import styles from "./cartSummary.module.scss";
import PaymentModeCard from "./paymentModeCard";
import amountIcon from "../images/amountIcon.svg";
import Button from "../../../../common/components/button/Button";
import PaymnentModeLabelValue from "./paymentModeCardLabelValue";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { constants } from "../../../../common/constants/constants";
const CartSummary = () => {
  const navigate = useNavigate();
  const handlePlaceOrder = () => {
    navigate(routeConfig.placeOrder);
  };
  return (
    <div className={styles.cartSummaryWrapper}>
      <div className={styles.viewCouponsWrapper}>
        <p>View available coupons</p>
        <button className={styles.viewCouponBtn}>View</button>
      </div>
      <div className={styles.cartModeOfPaymentWrapper}>
        <PaymentModeCard
          paymentMode="COD"
          renderLabelValue={() => {
            return (
              <>
                <PaymnentModeLabelValue
                  label={constants.myCart.estimatedSubTotal}
                  value="AED  140.00"
                />
                <PaymnentModeLabelValue
                    label={constants.myCart.deliveryCharges}
                  value="AED  50.00"
                />
                <PaymnentModeLabelValue
                label={constants.myCart.vatCharges}
                  value="AED  9.75"
                />
              </>
            );
          }}
        />
        <PaymentModeCard
          paymentMode="online"
          renderLabelValue={() => {
            return (
              <>
                <PaymnentModeLabelValue
                 label={constants.myCart.estimatedSubTotal}
                  value="AED  140.00"
                />
                <PaymnentModeLabelValue
                 label={constants.myCart.deliveryCharges}
                  value="AED  50.00"
                />
                <PaymnentModeLabelValue
                  label={`${constants.myCart.vatCharges} (05%)`}
                  value="AED  9.75"
                />
              </>
            );
          }}
        />
        <PaymentModeCard
          paymentMode="both"
          renderLabelValue={() => {
            return (
              <>
                <PaymnentModeLabelValue
                  label={constants.myCart.estimatedSubTotal}
                  value="AED  140.00"
                />
                <PaymnentModeLabelValue
                  label={constants.myCart.deliveryCharges}
                  value="AED  50.00"
                />
                <PaymnentModeLabelValue
                  label={`${constants.myCart.vatCharges} (05%)`}
                  value="AED  9.75"
                />
              </>
            );
          }}
        />
        <div className={styles.totalCartAmountWrapper}>
          <img src={amountIcon} alt="amount icon" />
          <div>
            <p className={styles.amountText}>
              <span className={styles.currency}>AED</span> 438.50
            </p>
            <p className={styles.estimatedText}>{constants.myCart.estimatedTotal}</p>
          </div>
        </div>
        <div className={styles.placeOrderBtn}>
          <Button handleClick={handlePlaceOrder} label="Place order" />
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
