import { createSlice } from "@reduxjs/toolkit";
interface popupState {
    popupState: boolean,
}
const showReferAFriend : popupState = {
    popupState: false
  };
const showReferAFriendSlice = createSlice({
    name: "showReferAFriend",
    initialState: showReferAFriend,
    reducers: {
        setReferAFriendState: (state, action) => {
            state.popupState = action.payload
        }
    }


});
export const {setReferAFriendState} = showReferAFriendSlice.actions;
export default showReferAFriendSlice.reducer;