import styles from "./OfflineSupplierCardStyles.module.scss";
const OfflineSupplierCard = ({
  customerId,
  email,
  customerName,
  businessName,
  address,
}: any) => {
  return (
    <div className={styles.card} key={customerId}>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_20} ${styles.name}`}
        title={customerName}
      >
        {customerName ?? "-"}
      </div>
      <div
        className={`${styles.columnValue}  ${styles.textOverflow} ${styles.w_30}`}
        title={email}
      >
        {email ?? "-"}
      </div>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_20}`}
        title={businessName}
      >
        {businessName ?? "-"}
      </div>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_30}`}
        title={address}
      >
        {address ?? "-"}
      </div>
    </div>
  );
};

export default OfflineSupplierCard;
