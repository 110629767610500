import { useSelector, useDispatch} from "react-redux";
import { useState } from "react";
import image from "../../../../assets/images/orderCancelled.svg";
import { useAxios } from "../../../../services/useAxios";
import { APIMethods } from "../../../constants/urlConstants";
import Button from "../../button/Button";
import styles from "./orderCancelledStyles.module.scss";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
const OrderCancelled = ({ orderId, isAdmin = false }: any) => {
  const [currentUser] = useState(
    JSON.parse(localStorage?.getItem("loggedInUser") as any)
  );
  const { adminManageOrderDetails } = useSelector((state: any) => state);
  const dispatch = useDispatch()
  // re-order api
  const reOrder = useAxios({
    axiosParams: {
      url: `store/master-order/re-order/${orderId}`,
      data: {
        buyer_id: adminManageOrderDetails && adminManageOrderDetails?.buyerId,
        // cart_id: adminManageOrderDetails && adminManageOrderDetails?.cartId, // removed according to the new reorder api index 20
        outlet_id: adminManageOrderDetails && adminManageOrderDetails?.outletId,
      },
    },
    method: APIMethods.post,
  });

  const reOrderItem = async () => {
    if (orderId) {
      dispatch(updateIsLoading(true))
      const response: any = await reOrder?.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false))
      }
      else{
        dispatch(updateIsLoading(false))
        console.log('loader')
      }
    }
  };
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={image} alt="placedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          <div className={styles.cardTitle}>Order cancelled!</div>
          <div className={styles.cardSubTitle}>
            Supplier had to cancel your order.
          </div>
        </div>
        {!isAdmin && currentUser?.typeOfUser?.[0] === "buyer" &&  (
          <div className={styles.buttonContainer}>
            <Button
              label="Order again"
              handleClick={() => {
                reOrderItem();
              }}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCancelled;
