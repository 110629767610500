import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { EditProfileProps } from "../../../../models/IMyProfile";
import Button from "../../../components/button/Button";
import InputField from "../../../components/formFields/inputField/inputField";
import styles from "./EditProfile.module.scss";
import { useAxios } from "../../../../services/useAxios";
import { APIMethods, resetMyProfile } from "../../../constants/urlConstants";

const EditProfile = () => {
  const schema = yup.object({
    firstName: yup.string().required("Enter your first name"),
    lastName: yup.string().required("Enter your last name"),
    designation: yup.string().required("Enter your designation"),
  });
  const profile = JSON.parse(localStorage.getItem("loggedInUser") || "{}");

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      firstName: profile.first_name ? profile.first_name : "",
      lastName: profile.last_name ? profile.last_name : "",
      designation: profile.designation ? profile.designation : "",
    },
    resolver: yupResolver(schema),
  });
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
    },
    [setValue]
  );
  const resetProfileNameAndRoleAPI = useAxios({
    axiosParams: {
      url: resetMyProfile.resetNameAndRole + profile.id,
      data: {
        first_name: watch("firstName"),
        last_name: watch("lastName"),
        designation: watch("designation"),
      },
    },
    method: APIMethods.post,
  });
  const onSubmit = async (formData: EditProfileProps) => {
    const response: any = await resetProfileNameAndRoleAPI.fetchData();
    if (response?.status === "SUCCESS") {
      profile.first_name = watch("firstName");
      profile.last_name = watch("lastName");
      profile.designation = watch("designation");
      localStorage.setItem("loggedInUser", JSON.stringify(profile));
      window.location.reload();
    }
  };
  return (
    <>
      <div className={styles.editProfilrConatiner}>
        <div className={styles.formContainer}>
          <form>
            <div>
              <InputField
                label="First Name"
                value={watch("firstName")}
                placeholder="First Name"
                id="firstName"
                onChange={onChangeField("firstName")}
                register={register("firstName")}
                errorMessage={errors.firstName?.message}
              />
            </div>
            <div>
              <InputField
                label="Last Name"
                value={watch("lastName")}
                placeholder="Last Name"
                id="lastName"
                onChange={onChangeField("lastName")}
                register={register("lastName")}
                errorMessage={errors.lastName?.message}
              />
            </div>
            <div>
              <InputField
                label="Your designation"
                value={watch("designation")}
                id="designation"
                placeholder="Your Designation"
                onChange={onChangeField("designation")}
                register={register("designation")}
                errorMessage={errors.designation?.message}
              />
            </div>
            <div>
              <InputField
                label="Email ID"
                disabled
                id="emailId"
                value={profile && profile?.email}
              />
            </div>
          </form>
          <div className={styles.buttonConatiner}>
            <Button
              label="Update"
              handleClick={handleSubmit(onSubmit)}
              height="40px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
