import React from "react";
import ProductsGroupBySupplier from "./productsGroupBySupplier";
import styles from "./cartProducts.module.scss";
import { useSelector } from "react-redux";
const CartProducts = () => {
  const { data } = useSelector((state: any) => state.myCart);

  return (
    <div className={styles.CartProducts}>
      {data?.supplier_products?.map((supplier: any) => {
        return (
          <ProductsGroupBySupplier
            supplierData={supplier}
            key={supplier?.supplier?.id}
          />
        );
      })}
    </div>
  );
};

export default CartProducts;
