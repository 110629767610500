import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import outOfStock from "../../../assets/images/Mark as out of stock.svg";
import inStock from "../../../assets/images/markInStock.svg";
import deactivate from "../../../assets/images/deactivate.svg";
import activate from "../../../assets/images/activate.svg";
import view from "../../../assets/images/viewDetails.svg";
import { constants } from "../../../common/constants/constants";
import {
  IAdminCatalogueListingCardData,
  IAdminCatalogueListingCardProps,
} from "../../../models/IAdminCatalogueListingCardProps";
import successToast from "../../../assets/images/successToast.svg";
import DeletePopup from "../../../common/components/deletePopup";
import styles from "./AdminCatalogueListingCardStyles.module.scss";
import emptyImage from "../../../assets/images/emptybox.svg";
import { useAxios } from "../../../services/useAxios";
import { APIMethods } from "../../../common/constants/urlConstants";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { useDispatch } from "react-redux";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import { setToastStatusApproval } from "../../../reducerSlices/toastStatusSlice";
import PopperComponent from "../../../common/components/PopperComponent/PopperComponent";
import RowActions from "../../../common/components/rowActions/RowActions";
import Button from "../../../common/components/button/Button";
import { isImgUrlValid } from "../../../utils/functions";
type GetActionItemsReturnType = {
  key: "ACTIVATE_DEACTIVATE" | "IN_OUT_STOCK" | "VIEW" | "DELETE_PRODUCT";
  icon: typeof view | typeof outOfStock | typeof activate | typeof deactivate;
  label: string;
};
const {
  VIEW_PROD_DETAILS,
  OUT_OF_STOCK,
  DEACTIVATE_PROD,
  ACTIVATE_PROD,
  IN_STOCK,
  DELETE_PRODUCT,
} = constants.productListing;
export const AdminCatalogueListingCard = (
  props: IAdminCatalogueListingCardProps
) => {
  const dispatch = useDispatch();

  const {
    handleActivationDeactivation,
    handleMarkOutOfStock,
    handleShowMenu,
    productStatus,
    isProductAvailable,
    iamgeUrl,
    id,
    showMenu,
    finalActive,
    setFinalActive,
    tabLabel,
    tabProductType,
  } = props;
  const actionsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [showDelete, setShowDelete] = useState(false);

  const actionShow = showMenu?.id === id && showMenu?.show ? true : false;
  const isMasterProduct = tabLabel === "Master products";
  const isOfflineProduct = tabLabel === "Offline Products";
  const onMouseEnter = () => {
    handleShowMenu(id, true);
  };

  const onMouseLeave = () => {
    handleShowMenu(id, false);
  };

  console.log(finalActive, "finalActive");

  const deleteProduct = useAxios({
    axiosParams: {
      url:
        tabLabel === "Supplier products"
          ? `store/product/${id}`
          : `store/offline-product/${id}`,
    },
    method: APIMethods.delete,
  });

  const deleteProductAPI = async () => {
    if (id) {
      setShowDelete(false);
      dispatch(updateIsLoading(true));
      const reponse: any = await deleteProduct.fetchData();
      if (reponse && reponse?.status === "SUCCESS") {
        const latestList =
          finalActive?.filter(
            (each: IAdminCatalogueListingCardData) => each.id != id
          ) ?? [];
        setFinalActive(latestList);
        dispatch(updateIsLoading(false));
        dispatch(
          setToastStatusApproval({
            message: "Product has been deleted successfully",
            status: "deleted",
            type: "productDeleted",
          } as any)
        );
      } else {
        // Show API failure message when the Response status is Failure
        setTimeout(() => {
          dispatch(
            setToastStatusApproval({
              message: reponse?.response?.data?.error_msg ?? "Something Went Wrong",
              status: "failure",
              type: "productDeleted",
            } as any)
          );
          dispatch(updateIsLoading(false));
          setTimeout(() => {
            dispatch(
              setToastStatusApproval({
                message: "",
                status: "",
                type: "",
              } as any)
            );
            dispatch(updateIsLoading(false));
          }, 5000);
        }, 500);
      }
    }
  };
  console.log(props, "props");

  const handleProductStatus = (
    key:
      | typeof VIEW_PROD_DETAILS
      | typeof OUT_OF_STOCK
      | typeof DEACTIVATE_PROD
      | typeof ACTIVATE_PROD
      | typeof IN_STOCK
      | typeof DELETE_PRODUCT
  ) => {
    if (key === ACTIVATE_PROD || key === DEACTIVATE_PROD) {
      handleActivationDeactivation();
      handleActivateToast();
    } else if (key === OUT_OF_STOCK || key === IN_STOCK) {
      handleMarkOutOfStock();
      handleStockToast();
    } else if (key === VIEW_PROD_DETAILS) {
      window.location.pathname.includes("/admin/manage-catalogue")
        ? isMasterProduct
          ? navigate(`/admin/manage-catalogue/product-details/${id}`, {
              state: {
                masterProduct: true,
              },
            })
          : isOfflineProduct
          ? navigate(`/admin/manage-catalogue/product-details/${id}`, {
              state: {
                offlineProduct: true,
              },
            })
          : navigate(`/admin/manage-catalogue/product-details/${id}`)
        : navigate(`/admin/manage-catalogue/product-details/${id}`);
    } else if (key === DELETE_PRODUCT) {
      setShowDelete(true);
    }
    handleShowMenu(id, false);
  };

  const getActionItems = (): GetActionItemsReturnType[] | [] => {
    const actionItems: any = [
      { icon: view, label: VIEW_PROD_DETAILS, key: "VIEW" },
      { icon: deleteIcon, label: DELETE_PRODUCT, key: "DELETE_PRODUCT" },
    ];
    if (tabProductType === "online") {
      actionItems.unshift(
        {
          icon: isProductAvailable ? outOfStock : inStock,
          label: isProductAvailable ? OUT_OF_STOCK : IN_STOCK,
          key: "IN_OUT_STOCK",
        },
        {
          icon: productStatus === "Active" ? deactivate : activate,
          label: productStatus === "Active" ? DEACTIVATE_PROD : ACTIVATE_PROD,
          key: "ACTIVATE_DEACTIVATE",
        }
      );
    }

    return actionItems;
  };

  // const masterActions = getActionItems()?.filter((each)=> each?.key !== 'DELETE_PRODUCT')

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        actionsRef &&
        actionsRef.current &&
        !actionsRef?.current?.contains(event.target)
      ) {
        handleShowMenu(id, false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [productImage, setProductImage] = useState(iamgeUrl);

  const handleActivateToast = () => {
    if (productStatus !== "Active") {
      setToastData({
        message: "Product has been activated successfully.",
        status: "active",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    } else {
      setToastData({
        message: "Product has been deactivated successfully.",
        status: "deactive",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const handleStockToast = () => {
    if (isProductAvailable === true) {
      setToastData({
        message: "Product has been set to Out of Stock",
        status: "outOfStock",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    } else {
      setToastData({
        message: "Product has been set to In Stock",
        status: "inStock",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const handleConfirmDelete = () => {
    deleteProductAPI();
  };

  const handleCancelDelete = () => {
    setShowDelete(false);
  };
  return (
    <>
      <tr
        className={`${styles.card} ${
          !isProductAvailable &&
          productStatus !== "Deactivated" &&
          styles.outOfStock
        }`}
        onClick={props.onCardClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <td
          className={`${styles.product} ${
            productStatus === "Active" && styles.activeProduct
          }`}
        >
          <div className={styles.productImgContainer}>
            <img
              className={`${styles.productImg} ${
                productStatus === "Deactivated" && styles.deactivateImg
              }`}
              src={isImgUrlValid(productImage) ? productImage : emptyImage}
              alt="product"
              onError={() => {
                setProductImage(emptyImage);
              }}
            />
          </div>
          <div
            className={`${styles.productName} ${
              productStatus === "Deactivated" && styles.inactive
            }`}
            title={props.productName}
          >
            {props.productName
              ?.split(" ")
              ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
              ?.join(" ")}
          </div>
          {productStatus !== "Deactivated" && (
            <div className={`${styles.active}`}>
              {/* {productStatus === "Deactivated" ? "Inactive" : productStatus} */}
            </div>
          )}
        </td>
        {!isMasterProduct && (
          <td
            className={`${styles.businessName} ${
              productStatus === "Deactivated" && styles.inactive
            }`}
          >
            {props.businessName
              ?.split(" ")
              ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
              ?.join(" ")}
          </td>
        )}
        {/* <td
          className={`${styles.productId} ${styles.textStyle}`}
          title={props.productId}
        >
          {props.productId}
        </td> */}
        <td
          className={`${styles.quantity} ${styles.textStyle} ${
            productStatus === "Deactivated" && styles.inactive
          }`}
          title={props.productQuantity}
        >
          {props.productQuantity}
        </td>
        <td
          className={`${styles.brandName} ${styles.textStyle} ${
            productStatus === "Deactivated" && styles.inactive
          }`}
          title={props.brandName}
        >
          {props.brandName
            ?.split(" ")
            ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
            ?.join(" ")}
        </td>
        <td
          className={`${styles.quantity} ${styles.textStyle} ${
            styles.countryOfOrigin
          } ${productStatus === "Deactivated" && styles.inactive}`}
          title={props.countryOrigin}
        >
          {props.countryOrigin}
        </td>
        <td className={styles.productType}>
          <div
            className={`${styles.textStyle} ${styles.textStyle2} ${
              productStatus === "Deactivated" && styles.inactive
            }`}
            title={props.productSubCategory}
          >
            {props?.productSubCategory as any}
          </div>

          <div className={styles.productCategory} title={props.productCategory}>
            <span
              className={`${styles.separator} ${styles.textStyle} ${
                productStatus === "Deactivated" && styles.inactive
              }`}
            >
              |
            </span>
            <div
              className={`${styles.textStyle3} ${
                productStatus === "Deactivated" && styles.inactive
              }`}
            >
              in {props?.productCategory as any}
            </div>
          </div>
        </td>
        {props.productCardState !== constants.productListing.IN_REVIEW &&
          actionShow && (
            <PopperComponent>
              <div className={styles.actions}>
                <div className={styles.actionItems}>
                  {getActionItems().length > 0 && (
                    <RowActions
                      actions={getActionItems()}
                      handleAction={handleProductStatus}
                    />
                  )}
                </div>
              </div>
            </PopperComponent>
          )}
      </tr>
      {toastData?.message ? (
        <ToastNotification
          icon={successToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
      {showDelete && (
        <DeletePopup
          open={showDelete}
          handleClose={() => {
            setShowDelete(false);
          }}
          title="Delete Product"
          popupContent={
            isMasterProduct
              ? "Deleting a master product is not possible due to its association with multiple cloned products."
              : "Are you sure you want to delete this product ?"
          }
          handleConfirm={handleConfirmDelete}
          handleCancel={handleCancelDelete}
          {...(isMasterProduct && {
            renderActions: () => {
              return (
                <div className={styles.cancelDelete}>
                  <Button
                    width="100px"
                    label="close"
                    handleClick={handleCancelDelete}
                  />
                </div>
              );
            },
          })}
        />
      )}
    </>
  );
};
