import React, { useState } from "react";
import styles from "./deliveryAddressTime.module.scss";
import SelectAddress from "./selectAddress";
import SelectDeliveryTime from "./selectDeliveryTime";
const DeliveryAddressTime = () => {
  const addressList = [
    {
      id: "addr_01HBAYSQPQ1X6BQ794NZPQN38K",
      name: "Ahmed Ali",
      addressLine1: "2591 Reyes Radial",
      addressLine2: "Hor Hanzol East, Dubai",
      country: {
        name: "UAE",
      },
      phone: "971 23 388 0987",
      is_default: true,
    },
    {
      id: "addr_01HBAYSQPQ1X6BQ794NZPQN39I",
      name: "Ahmed Ali",
      addressLine1: "2591 Reyes Radial",
      addressLine2: "Hor Hanzol East, Dubai",
      country: {
        name: "UAE",
      },
      phone: "971 23 388 0987",
    },
  ];

  const deliveryTimeList = [
    {
      id: "time_01HBAYSQPQ1X6BQ794NZPQN39I",
    },
    {
      id: "time_01HBAYSQPQ1X6BQ794NZPQN803",
    },
    {
      id: "time_01HBAYSQPQ1X6BQ794NZ454554",
    },
  ];
  const [hovered, setHovered] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(addressList[0].id);
  const [timeHoverd, setTimeHovered] = useState("");
  const [selectedTime, setSelectedTime] = useState(deliveryTimeList[0].id);
  const handleMouseOver = (id: string) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered("");
  };

  const handleSelectAddress = (id: string) => {
    setSelectedAddress(id);
  };

  const handleTimeMouseOver = (id: string) => {
    setTimeHovered(id);
  };
  const handleTimeMouseLeave = () => {
    setTimeHovered("");
  };
  const handleSelectTime = (id: string) => {
    setSelectedTime(id);
  };

  return (
    <div className={styles.deliveryAddressTimeWrapper}>
      <p className={styles.subHeading}>Select your address</p>
      <div className={styles.addressListWrapper}>
        {addressList.map((address) => {
          return (
            <SelectAddress
              key={address.id}
              isHoverd={hovered === address.id}
              addressDetails={address}
              handleMouseOver={handleMouseOver}
              handleMouseLeave={handleMouseLeave}
              handleSelect={handleSelectAddress}
              selected={selectedAddress}
            />
          );
        })}
      </div>
      <button className={styles.textBtn}>Add new address</button>
      <div className={styles.horizontalLine} />
      <div className={styles.selectDeliveryTimeWrapper}>
        {deliveryTimeList.map((time) => {
          return (
            <SelectDeliveryTime
              key={time.id}
              isHoverd={timeHoverd === time.id}
              timeDetails={time}
              handleMouseOver={handleTimeMouseOver}
              handleMouseLeave={handleTimeMouseLeave}
              handleSelect={handleSelectTime}
              selected={selectedTime}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DeliveryAddressTime;
