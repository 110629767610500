export const LOGIN_PAGE_STRINGS = {
  GET_STARTED: "Let's get you ",
  GET_STARTED2: "started on managing your procurement!",
  WELCOME: "Welcome !",
  EMAILID: "Email ID",
  SUGGESTION: "We suggest you to use work email id",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm password",
  SIGNUP: "Sign up",
  ALREADY_USER: "Already a user?",
  SIGNIN: "Sign in",
  CONTINUE_WITH: "or continue with",
  GOOGLE: "Google",
  SIGNUP_TITLE_SUPPLIER: "Sign up as supplier",
  SIGNUP_TITLE_BUYER: "Sign up as buyer",
  APPLE: "Apple",
  WELCOME_BACK: "Welcome Back!",
  REMEMBER_ME: "Remember me",
  NO_ACCOUNT: "Don’t have an account?",
  REGISTER_HERE: "Register here",
  FORGOT_PASSWORD: "Forgot password?",
  EMAIL_PLACEHOLDER: "Enter email address",
  PASSWORD_PLACEHOLDER: "Enter Password",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm new password here...",
  PASSWORD_MUST_CONTAIN: "Password must contain",
  START_YOU: "Start your",
  SUPPLIER_LOGIN_TEXT:
    " supplier journey and unlock new business possibilities",

  //forgot password
  BACK: "Back",
  FORGOT_PASSWORD_LABEL: "Forgot Password",
  RESEND_VERIFICATION_LABEL: "Resend verification mail",
  FORGOT_PWD_TEXT:
    "To Reset your Password, enter the Email Address you use to sign in to Watermelon",
  RESEND_VER_LINK_TEXT:
    "Enter the Email Address you use to sign in to Watermelon",
  RESET_LINK: "Send reset link",
  RESEND_LINK: "Send verification link",

  //reset password
  RESET_PWD_LABEL: "Reset Password",
  NEW_PASSWORD: "New password",
  NEW_PASSWORD_PLACEHOLDER: "Enter new password here...",
  RESET_PWD_TEXT:
    "Kindly create a new password. Your new password must be different to your previously three used passwords",
  RESET_PASSWORD: "Reset password",
  CURRENT_PASSWORD: "Current password",
  CURRENT_PASSWORD_PLACEHOLDER: "Enter current password here...",
  RESET_PWD_CHANGE_TEXT:
    "Your new password must be different to your previously used three passwords.",

  //verify account
  VERIFY_ACCOUNT_LABEL: "verify your account",
  VERIFY_ACCOUNT_TEXT: "A link has been sent to",
  VERIFY_ACCOUNT_TEXT2: "link will expire in 15 mins.",
  DIDNT_REC_MAIL: "Didn’t receive the mail?",
  RESEND: "Resend",
  LOGIN_WITH_ACC: "Log in with different account",

  // reset link
  RESET_LINK_LABEL: "Reset link sent",
  RESET_RESEND_LABEL: "Password link sent",
  RESET_LINK_TEXT: "Please check your inbox",
  CHANGE_EMAIL: "Change email",
  PASSWORD_CONSTRIANTS: "Password must contain",

  //Reset completed
  RESET_COMPLETED: "Reset completed",
  RESET_COMPLETED_TEXT: "Your password has been changed successfully",

  //Error messages
  REQUIRED: "*Required",
  INVALID_MAIL: "Enter valid email ID",
  INVALID_PASSWORD: "Enter valid password",
  CONFIRM_PASSSWORD: "Password and confirm password should match",
};
