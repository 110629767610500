import React from "react";
import styles from "./rowActionsStyles.module.scss";
const RowActions = ({ actions, handleAction = () => {} }: any) => {
  return (
    <div className={styles.actions}>
      <div className={styles.actionItems}>
        {actions.map((action: any, index: any) => {
          return (
            <div
              key={action.key}
              className={styles.option}
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (action.onClick) {
                  action.onClick();
                } else {
                  handleAction(action.label);
                }
              }}
            >
              <img src={action.icon} alt={action.label} />
              <div className={styles.label}>{action.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RowActions;
