/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import cancel from "../../../../../assets/images/cancelToast.svg";
import filterIcon from "../../../../../assets/images/ordersListing/Filter.svg";
import searchIcon from "../../../../../assets/images/ordersListing/searchIcon.svg";
import sortIcon from "../../../../../assets/images/ordersListing/sortIcon.svg";
import search from "../../../../../assets/images/so-search.svg";
import {
  ICategory,
  ICountry,
  IEqualTo,
  IOrderingOption,
  ISubCategory,
  ISubSubCategory,
} from "../../../../../models/IAddIndividualProductForm";
import { IProduct } from "../../../../../models/IProductListingCard";
import { addCatalogueListActive } from "../../../../../reducerSlices/manageCatalogueList";
import { setTab } from "../../../../../reducerSlices/selectedTabSlice";
import Button from "../../../../components/button/Button";
import CheckboxButton from "../../../../components/checkbox/CheckboxButton";
import Dropdown from "../../../../components/dropdown/Dropdown";
import PaginationComponent from "../../../../components/pagination/Pagination";
// import PopupView from "../../../../components/popupView/PopupView";
import { constants } from "../../../../constants/constants";
import { routeConfig } from "../../../../constants/routeConfig";
import { EmptyContainer } from "../../../ordersListing/components/emptyContainer/EmptyContainer";
// import { AddSupplierPopup } from "../addSupplierPopup/AddSupplierPopup";
import { ProductListingCard } from "../productListingCard/ProductListingCard";
import styles from "./ProductListingTabsStyles.module.scss";
import { useAxios } from "../../../../../services/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { ToastNotification } from "../../../../components/toastNotification/ToastNotification";
import successToast from "../../../../../assets/images/successToast.svg";
import errorToast from "../../../../../assets/images/errorToast.svg";
import useFetchCountries from "../../../../../services/useCountry";
import { resetToastStatus } from "../../../../../reducerSlices/toastStatusSlice";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import headerCardStyles from "../../../../../pages/admin/supplierBusinessApprovalProcess/components/approvalCardSupplier/ApprovalCard.module.scss";
import { setPage } from "../../../../../reducerSlices/selectedPageSlice";
// import { axiosAPI } from "../../../../../services/axiosInstance";
import lodash from "lodash";
import axios from "axios";
import { urlConstants } from "../../../../constants/urlConstants";

export const ProductListingTabs = () => {
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.supplierManageCataloguePageNumber ?? 1
  );
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.supplier_manage_catalogue ?? 0
  );
  const itemsPerPage = 10;
  const { manageCatalogueList } = useSelector((state: any) => state);
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState(selectedTab);
  const [finalActive, setFinalActive] = useState<IProduct[]>([]);
  const [finalInReview, setFinalInreview] = useState<IProduct[]>([]);
  const [finalRejected, setFinalRejected] = useState<IProduct[]>([]);
  const [finalProposed, setFinalProposed] = useState<IProduct[]>([]);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [selectedBrand, setSelectedBrand] = useState<any>({});
  const [showMenu, setShowMenu] = useState<any>({});
  const [productCreateToast, setProductCreateToast] = useState(
    constants.defaultToastData
  );
  const { toastStatus } = useSelector((state: any) => state);
  const myRef = useRef<null | HTMLDivElement>(null);

  const dispatch = useDispatch();
  const [selectedSubcategory, setSelectedSubcategory] = useState<ISubCategory>(
    {}
  );
  const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
  const [paidCheckBox, setPaidCheckBox] = useState<any>(false);
  const [unPaidCheckBox, setUnPaidCheckBox] = useState<any>(false);
  const [selectedSection, setSelectedSection] = useState<ISubSubCategory>({});
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [selectedMeasuringUnit, setSelectedMeasuringUnit] =
    useState<IOrderingOption>({});
  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );
  const { countriesList } = useFetchCountries();
  const getCatlogueProducts = useAxios({
    axiosParams: {
      url: `store/product/supplier/${loggedInUser &&
        loggedInUser?.business_details?.[0] &&
        loggedInUser?.business_details?.[0]?.business_setup_id
        }?status=${activeTab === 0
          ? "published"
          : activeTab === 1
            ? "draft"
            : activeTab === 2
              ? "rejected"
              : activeTab === 3
                ? "proposed"
                : "active"
        }&offset=${(currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}&page=${currentPage}`,
    },
    method: "GET",
  });

  //This function is used to fetch products list
  const getCatlogueProductsList = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getCatlogueProducts.fetchData();
    if (response && response?.data && response?.data?.products) {
      // console.log(response?.data?.products, "response?.data?.products");

      dispatch(
        addCatalogueListActive({
          data: response?.data?.products,
          isSearch: false,
        })
      );
      setTotalProductCount(response?.data?.count);
      setShowEmptyContainer(response?.data?.products?.length === 0);
    } else {
      dispatch(addCatalogueListActive({ data: [], isSearch: false }));
      setTotalProductCount(0);
      setShowEmptyContainer(true);
    }
    dispatch(updateIsLoading(false));
  }, [getCatlogueProducts]);

  useEffect(() => {
    if (searchText && searchText?.length > 0 && showCross) fetchData();
    else getCatlogueProductsList();
    // setShowSearch(false);
  }, [activeTab, currentPage]);

  useEffect(() => {
    if (
      (toastStatus?.message?.length > 0 &&
        toastStatus?.type === "productCreation") ||
      toastStatus?.type === "delete"
    ) {
      setProductCreateToast({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setProductCreateToast(constants.defaultToastData);
      }, 5000);
    }
  }, [dispatch, toastStatus]);

  useEffect(() => {
    myRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    if (manageCatalogueList) {
      switch (activeTab) {
        case 0:
          setFinalActive(manageCatalogueList);
          break;
        case 1:
          setFinalInreview(manageCatalogueList);
          break;
        case 2:
          setFinalRejected(manageCatalogueList);
          break;
        case 3:
          setFinalProposed(manageCatalogueList);
          break;
        default:
          break;
      }
    }
  }, [activeTab, manageCatalogueList]);

  const [categories, setCategories] = useState<ICategory[]>(
    constants.tempCategory
  );
  const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    ICategory[]
  >([]);
  const [selectedProductSection, setSelectedProductSection] = useState<
    ISubSubCategory[]
  >([]);

  const selectTab = (index: number) => {
    // dispatch(setTab({ type: "orders", index }));
    setActiveTab(index);
    setCurrentPage(1);
    dispatch(setPage({ type: "supplierManageCataloguePageNumber", index: 1 }));
    dispatch(setTab({ type: "supplier_manage_catalogue", index: index }));
  };
  const tabsData = [
    {
      id: 0,
      label: constants.productListing.ACTIVE,
      content: finalActive,
      visibleContent: finalActive,
    },
    {
      id: 1,
      label: constants.productListing.IN_REVIEW,
      content: finalInReview,
      visibleContent: finalInReview,
    },
    {
      id: 2,
      label: constants.productListing.REJECTED,
      content: finalRejected,
      visibleContent: finalRejected,
    },
    {
      id: 3,
      label: constants.productListing.PROPOSED,
      content: finalProposed,
      visibleContent: finalProposed,
    },
  ];
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    dispatch(
      setPage({ type: "supplierManageCataloguePageNumber", index: page })
    );
  };
  // useEffect(() => {
  //   setCurrentPage(1);
  //   dispatch(setPage({type:"supplierManageCataloguePageNumber", index:1}));
  // }, [activeTab]);

  const [showSort, setShowSort] = useState(false);
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const [prevSelected, setPrevSelected] = useState(-1);
  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => { },
    },
    {
      id: 1,
      label: constants.productListing.SORT_A_Z,
      onClick: () => { },
    },
    {
      id: 2,
      label: constants.productListing.SORT_Z_A,
      onClick: () => { },
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const [showSearch, setShowSearch] = useState(false);

  const [showCross, setShowCross] = useState(false);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase().trimStart();
    setShowCross(inputValue.length > 0);
    setSearchText(inputValue);
    setCurrentPage(1);
    dispatch(setPage({ type: "supplierManageCataloguePageNumber", index: 1 }));
  };
  const fetchSearchDataAlgolia = async () => {
    const url: string = `store/algolia/mp-search`;
    const status: string =
      activeTab === 0
        ? "published"
        : activeTab === 1
          ? "draft"
          : activeTab === 2
            ? "rejected"
            : activeTab === 3
              ? "proposed"
              : "active";
    const supplierId =
      loggedInUser &&
      loggedInUser?.business_details?.[0] &&
      loggedInUser?.business_details?.[0]?.business_setup_id;
    const body: any = {
      q: searchText,
      supplier_id: supplierId,
      status: status,
      page: currentPage - 1,
      hitsPerPage: 10,
    };

    const apiResponse = axios
      .post(`${urlConstants?.BASE_URL}${url}`, body)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error?.response?.data;
      });
    return apiResponse;
  };
  const fetchData = async () => {
    if (searchText && searchText?.length > 0 && showCross) {
      const response = await fetchSearchDataAlgolia();
      if (response && response?.data) {
        const searchResults = response?.data;
        dispatch(
          addCatalogueListActive({ data: searchResults, isSearch: true })
        );
        setTotalProductCount(response?.count);
        setShowEmptyContainer(searchResults.length === 0);
      }
    } else {
      getCatlogueProductsList();
      setShowEmptyContainer(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [showCross]);

  const getSearchDebouncedData = lodash.debounce(fetchData, 800);

  useEffect(() => {
    if (searchText.length !== 0) {
      getSearchDebouncedData();
      return () => {
        getSearchDebouncedData.cancel();
      };
    }
  }, [searchText]);

  // second phase code is not reviewed
  const [showFilter, setShowFilter] = useState(false);
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchIcon = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowSearch(!showSearch);
    setShowSort(false);
  };
  const isClickedOutOfSearch = (event: React.MouseEvent<HTMLInputElement>) => {
    return (
      showSearch &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      inputRef.current.value === ""
    );
  };
  const handleCancelSeacrh = () => {
    setShowCross(false);
    setShowSearch(!showSearch);
    setSearchText("");
  };

  const filtersRef = useRef<HTMLDivElement>(null);
  const filitersContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target as Node)
      ) {
        setShowSort(false);
      }
      if (
        isClickedOutOfSearch(
          event as unknown as React.MouseEvent<HTMLInputElement>
        )
      ) {
        setShowSearch(false);
      }
      if (
        filtersRef &&
        filtersRef.current &&
        !filtersRef?.current?.contains(event.target as Node) &&
        filitersContainerRef &&
        !filitersContainerRef.current?.contains(event.target as Node)
      ) {
        setShowFilter(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSearch]);
  const schema = yup.object({
    productBrand: yup.mixed(),
    category: yup.mixed(),
    subCategory: yup.mixed(),
    productSection: yup.mixed(),
    countryOrigin: yup.mixed(),
    baseUnitSize: yup.mixed(),
    paidCheckBox: yup.mixed(),
    unPaidCheckBox: yup.mixed(),
  });
  const confirmFilters = () => {
    setShowFilter(false);
  };
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      if (name === "countryName") setValue(name, text);
    },

    [setValue]
  );
  const navigate = useNavigate();
  // const [showPopup, setShowPopup] = useState(false);
  const handlePopup = (type: string) => {
    if (fetchedData && fetchedData?.business_setup_status === "pending") {
      setToastData({
        message: "Please setup your business to add a Product",
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    } else if (fetchedData && fetchedData?.business_setup_status === "review") {
      if (fetchedData?.is_approved !== true) {
        setToastData({
          message:
            "Business setup request is under review. Please wait until it gets approved.",
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      } else {
        navigate(routeConfig.manageSupplierCatalogue);
      }
    } else if (
      fetchedData &&
      fetchedData?.business_setup_status === "rejected"
    ) {
      setToastData({
        message: "Business setup is rejected, Please re-upload documents ",
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    } else if (
      fetchedData &&
      fetchedData?.business_setup_status === "approved"
    ) {
      if (type === "new") {
        navigate(routeConfig.manageSupplierCatalogue);
      } else {
        navigate(routeConfig.modifyBulkProduct);
      }
    }
  };
  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryObj = categories?.find(
        (cat: ICategory) => cat.value === selectedCategory?.value
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.subcategories &&
        selectedCategoryObj?.subcategories?.length > 0
      ) {
        const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
          (category: ICategory) => ({
            ...category,
            value: category?.name,
            productSection: category?.sub_sub_categories,
          })
        );
        setSelectedSubcategories(updatedCategoriesData);
      } else {
        setSelectedSubcategories([]);
      }
    } else {
      setSelectedSubcategories([]);
    }
  }, [selectedCategory, categories]);

  const handleCategoryClick = (categoryValue: ICategory) => {
    setSelectedCategory(categoryValue);
    setSelectedSubcategories([]);
    setSelectedProductSection([]);
  };

  const handleSubcategoryClick = (subcategoryValue: ISubCategory) => {
    if (!subcategoryValue) return;
    setSelectedSubcategory(subcategoryValue);
    const selectedSubcategoryObj = selectedSubcategories.find(
      (subcat: ICategory) => subcat.value === subcategoryValue?.value
    );
    if (selectedSubcategoryObj) {
      if (selectedSubcategoryObj?.productSection) {
        const updatedCategoriesData =
          selectedSubcategoryObj?.productSection?.map(
            (category: ISubCategory) => ({
              ...category,
              value: category?.name,
              productSection: selectedSubcategoryObj?.sub_sub_categories,
            })
          ) as ISubSubCategory[];
        setSelectedProductSection(updatedCategoriesData);
      } else {
        setSelectedProductSection([]);
      }
    } else {
      setSelectedProductSection([]);
    }
  };

  // second phase code is not reviewed

  // if (manageCatalogueList.length === 0) {
  //   dispatch(updateIsLoading(true));
  // } else {
  //   dispatch(updateIsLoading(false));
  // }
  const [customerId] = useState(
    loggedInUser &&
    loggedInUser?.typeOfUser[0] !== "admin" &&
    loggedInUser?.business_details?.[0]?.customer_id
  );
  const [businessId] = useState(
    loggedInUser &&
    loggedInUser?.typeOfUser[0] !== "admin" &&
    loggedInUser?.business_details?.[0]?.business_setup_id
  );

  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [fetchedData, setFetchedData] = useState<any>({});
  const fetchCTACardStatus = useAxios({
    axiosParams: {
      url: `store/onboarding/supplier/${customerId}/${businessId}`,
    },
    method: "GET",
  });
  const fetchCTACardStatusAPI = async () => {
    if (customerId && businessId) {
      const response: any = await fetchCTACardStatus.fetchData();
      if (response && response?.status === "SUCCESS") {
        setFetchedData(response?.data);
      }
    }
  };

  const onProductDelete = () => {
    getCatlogueProductsList();
  };

  useEffect(() => {
    fetchCTACardStatusAPI();
  }, []);

  // get categories api call starts
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: "GET",
  });

  const getAllCategoriesList = useCallback(async () => {
    const response: any = await getCategories?.fetchData();
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          subcategories: category.sub_categories,
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
    }
  }, [getCategories]);

  useEffect(() => {
    getAllCategoriesList();
  }, []);

  const handleBrandClick = (brandValue: any) => {
    setSelectedBrand(brandValue);
  };
  // get measuring units api call starts
  const measuringUnitsApi = useAxios({
    axiosParams: {
      url: "store/units",
    },
    method: "GET",
  });

  const getMeasuringUnits = useCallback(async () => {
    const response: any = await measuringUnitsApi?.fetchData();
    if (response) {
      const updatedMeasuringUnits = response?.data?.product_units?.map(
        (unit: IEqualTo) => ({
          ...unit,
          value: unit.name,
        })
      );
      setMeasuringUnits(updatedMeasuringUnits);
    }
  }, [measuringUnitsApi]);

  useEffect(() => {
    getMeasuringUnits();
  }, []);
  const handleReset = () => {
    setValue("productBrand", null);
    setValue("category", null);
    setValue("subCategory", null);
    setValue("productSection", null);
    setValue("countryOrigin", null);
    setValue("baseUnitSize", null);
    setValue("paidCheckBox", null);
    setValue("unPaidCheckBox", null);
    setPaidCheckBox(false);
    setUnPaidCheckBox(false);

    setSelectedBrand({});
    setSelectedCategory({});
    setSelectedSubcategory({});
    setSelectedSection({});
    setSelectedCountry({});
    setSelectedMeasuringUnit({});
    getCatlogueProductsList();
  };

  const handleShowMenu = (id: number | string | undefined, show: boolean) => {
    setShowMenu({ id, show });
  };

  return (
    <div className={styles.productListingTabsContainer}>
      <div className={styles.header}>
        <div className={styles.heading}>
          {constants.productListing.MANAGE_CATALOGUE}
        </div>
        <div className={styles.buttonSection}>
          <div className={styles.addNew}>
            <Button
              label={"Modify bulk product"}
              handleClick={() => handlePopup("modify")}
              width="160px"
              secondary
            />
          </div>
          <div className={styles.addNew}>
            <Button
              label={constants.productListing.ADD_NEW}
              handleClick={() => handlePopup("new")}
              width="120px"
            />
          </div>
        </div>
      </div>
      <>
        {tabsData && tabsData.length > 0 && (
          <div className={styles.container}>
            <div className={styles.tabs}>
              <div className={styles.tabsStyle}>
                <div className={styles.tabList}>
                  {tabsData &&
                    tabsData.length > 0 &&
                    tabsData.map((each) => (
                      <div
                        className={styles.tabListItem}
                        onClick={() => selectTab(each.id)}
                      >
                        <div
                          className={`${styles.label} ${each.id === activeTab && styles.activeLabel
                            }`}
                        >
                          {each.label}
                        </div>
                        {activeTab === each.id && (
                          <div className={styles.border}></div>
                        )}
                      </div>
                    ))}
                </div>
                <div className={styles.hr}></div>
              </div>
              {(totalProductCount > 10 || searchText != "") && (
                <div
                  className={`${styles.actions} ${showSearch && styles.actions2
                    }`}
                >
                  <div className={styles.search}>
                    {showSearch ? (
                      <div className={`${styles.searchContainer}`}>
                        <div className={styles.searchIconInput}>
                          <img src={search} alt="searchIcon" height="24px" />
                          <input
                            value={searchText}
                            ref={inputRef}
                            type="search"
                            placeholder="Search by product name or category or brand"
                            className={`${styles.searchInput} search-input`}
                            onChange={handleSearch}
                            autoFocus
                          />
                        </div>
                        {showCross && (
                          <div
                            className={styles.cancel}
                            onClick={handleCancelSeacrh}
                          >
                            <img src={cancel} alt="cancel" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className={styles.searchIcon}
                        onClick={(e) => handleSearchIcon(e)}
                      >
                        <img src={searchIcon} alt="search"></img>
                      </div>
                    )}
                  </div>
                  {false && totalProductCount > 10 && (
                    <div
                      className={`${styles.icon} ${styles.sort}`}
                      onClick={handleSort}
                      ref={sortContainerRef}
                    >
                      <img src={sortIcon} alt="sort"></img>
                      {showSort && (
                        <div className={styles.sortList}>
                          <div className={styles.sortBy}>
                            {constants.ordersListing.SORT_BY}
                          </div>
                          {sortOptions &&
                            sortOptions.length > 0 &&
                            sortOptions.map((option, index) => (
                              <div
                                className={`${styles.sortOption} ${index === sortOptions.length - 1 &&
                                  styles.lastOption
                                  } ${option.id === prevSelected &&
                                  styles.selectedOpt
                                  }`}
                                onClick={() => handleSortingOptions(option.id)}
                              >
                                {option.label}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                  {false && totalProductCount > 10 && (
                    <div
                      className={`${styles.icon}`}
                      onClick={handleFilter}
                      ref={filtersRef}
                    >
                      <img src={filterIcon} alt="filter"></img>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!showEmptyContainer && (
              <div className={styles.tabsContent}>
                {tabsData &&
                  tabsData.length > 0 &&
                  tabsData.map(
                    (each) =>
                      activeTab === each?.id && (
                        <div className={styles.tabslistContainer}>
                          <div className={`${styles.tableHeader}`}>
                            <div className={styles.details}>
                              <div
                                className={`${headerCardStyles.column} ${styles.headerColumn1
                                  }
                       ${tabsData[activeTab]?.label === "Master products" &&
                                  styles.masterProductHeader
                                  } 
                       `}
                              >
                                Product Name
                              </div>
                              {tabsData[activeTab]?.label !==
                                "Master products" && (
                                  <div
                                    className={`${headerCardStyles.column} ${styles.headerColumn2}`}
                                  >
                                    {constants.buyersListHeaders.businessName}
                                  </div>
                                )}
                              {/* <div
                         className={`${headerCardStyles.column}  ${styles.contactName}`}
                       >
                         Watermelon product code
                       </div> */}
                              <div
                                className={`${headerCardStyles.column} ${styles.headerColumn3} `}
                              >
                                Product brand
                              </div>
                              <div
                                className={`${headerCardStyles.column} ${styles.headerColumn4}`}
                              >
                                Country Origin
                              </div>
                              <div
                                className={`${headerCardStyles.column} ${styles.headerColumn5}`}
                              >
                                Category
                              </div>
                            </div>
                          </div>
                          <div
                            key={each?.id}
                            className={styles.ordersList}
                            ref={myRef}
                          >
                            {each &&
                              each?.visibleContent &&
                              each?.visibleContent.length > 0 &&
                              each?.visibleContent.map((order: IProduct) => (
                                <ProductListingCard
                                  order={order}
                                  onProductDelete={onProductDelete}
                                  handleShowMenu={handleShowMenu}
                                  showMenu={showMenu}
                                />
                              ))}
                          </div>
                          <div className={styles.paginationContainer}>
                            <PaginationComponent
                              count={Math.ceil(
                                totalProductCount / itemsPerPage
                              )}
                              page={currentPage}
                              handlePagination={handlePagination}
                              showFirstButton={true}
                              showLastButton={true}
                            />
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
            {showEmptyContainer && (
              <div className={styles.emptyContainer}>
                <EmptyContainer title={"No Data"} />
              </div>
            )}
          </div>
        )}

        {showFilter && (
          <div className={styles.overlay}>
            <div className={styles.filters} ref={filitersContainerRef}>
              <div className={styles.header}>
                <div data-teId="heading" className={styles.filtersHeading}>
                  Filters
                </div>
                <div className={styles.cancelFilter} onClick={handleFilter}>
                  <img src={cancel} alt="cancel"></img>
                </div>
              </div>
              <form
                className={styles.form}
                onSubmit={handleSubmit(confirmFilters)}
              >
                <div className={styles.formInputs}>
                  <div className={styles.payment}>
                    <div className={styles.paymentLabel}>Status</div>
                    <div className={styles.paymentInput}>
                      <div className={styles.paid}>
                        <CheckboxButton
                          customClass={styles.checkboxField}
                          customLabel={styles.checkboxLabel}
                          register={register("paidCheckBox")}
                          handleChange={() => {
                            onChangeField("paidCheckBox");
                            setPaidCheckBox(!paidCheckBox);
                          }}
                          label="Active"
                          checked={paidCheckBox}
                          setChecked={setPaidCheckBox}
                        />
                      </div>
                      <div className={styles.paid}>
                        <CheckboxButton
                          customClass={styles.checkboxField}
                          customLabel={styles.checkboxLabel}
                          register={register("unPaidCheckBox")}
                          handleChange={() => {
                            onChangeField("unPaidCheckBox");
                            setUnPaidCheckBox(!unPaidCheckBox);
                          }}
                          label="Inactive"
                          checked={unPaidCheckBox}
                          setChecked={setUnPaidCheckBox}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Dropdown
                      minWidth="400px"
                      label={"Product brand"}
                      register={register("productBrand")}
                      selectedValue={selectedBrand}
                      options={constants.foodBrands}
                      defaultLabel={selectedBrand?.label ?? "Select the brand"}
                      id={"0"}
                      onChange={(selectedOption: any) => {
                        onChangeField("productBrand");
                        handleBrandClick(selectedOption);
                      }}
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      label={"Category"}
                      options={categories}
                      defaultLabel={
                        selectedCategory?.name ?? "Select the category"
                      }
                      id={"1"}
                      register={register("category")}
                      onChange={(selectedOption: ICategory) => {
                        onChangeField("category")(selectedOption);
                        handleCategoryClick(selectedOption);
                      }}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      label={"Subcategory"}
                      selectedValue={selectedSubcategory}
                      options={selectedSubcategories}
                      defaultLabel={
                        selectedSubcategory?.name ?? "Select the subcategory"
                      }
                      id={"2"}
                      onChange={(selectedOption: ISubCategory) => {
                        onChangeField("subCategory")(selectedOption);
                        handleSubcategoryClick(selectedOption);
                      }}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      selectedValue={selectedSection}
                      label={"Subsubcategory"}
                      defaultLabel={
                        selectedSection?.name ?? "Select the Sub subcategory"
                      }
                      id={"2"}
                      options={selectedProductSection}
                      register={register("subCategory")}
                      onChange={(selectedOption: ISubSubCategory) => {
                        onChangeField("productSection")(selectedOption);
                        setSelectedSection(selectedOption);
                      }}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      selectedValue={selectedCountry}
                      label={"Country origin"}
                      options={countriesList}
                      defaultLabel={
                        selectedCountry?.name ?? "Select the country origin"
                      }
                      id={"3"}
                      register={register("countryOrigin")}
                      onChange={(selectedOption: ICountry) => {
                        onChangeField("productCountryOrigin")(selectedOption);
                        setSelectedCountry(selectedOption);
                      }}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      selectedValue={selectedMeasuringUnit}
                      label={"Base unit size"}
                      options={measuringUnits}
                      defaultLabel={
                        selectedMeasuringUnit?.name ?? "Select the size"
                      }
                      id={"4"}
                      register={register("baseUnitSize")}
                      onChange={(selectedOption: any) => {
                        onChangeField("baseUnitSize");
                        setSelectedMeasuringUnit(selectedOption);
                      }}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      disablePortal={true}
                    />
                  </div>
                </div>
                <div className={styles.filterActions}>
                  <div className={styles.reset} onClick={handleReset}>
                    Reset
                  </div>
                  <div>
                    <Button
                      label={"Cancel"}
                      handleClick={handleFilter}
                      width="120px"
                      secondary={true}
                    />
                  </div>
                  <div>
                    <Button
                      label={"Confirm"}
                      handleClick={handleSubmit(confirmFilters)}
                      width="120px"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
        {productCreateToast?.message ? (
          <ToastNotification
            icon={
              productCreateToast.status === "Success"
                ? successToast
                : errorToast
            }
            content={productCreateToast?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

// const getSearchData = useAxios({
//   axiosParams: {
//     url: `store/product/supplier/${
//       loggedInUser &&
//       loggedInUser?.business_details?.[0] &&
//       loggedInUser?.business_details?.[0]?.business_setup_id
//     }?status=${
//       activeTab === 0
//         ? "published"
//         : activeTab === 1
//         ? "draft"
//         : activeTab === 2
//         ? "rejected"
//         : activeTab === 3
//         ? "proposed"
//         : "active"
//     }&offset=${
//       (currentPage - 1) * itemsPerPage
//     }&limit=${itemsPerPage}&page=${currentPage}&q=${searchText}`,
//   },
//   method: "GET",
// });
// console.log("======= search called =======");
// try {
//   const response = await fetchSearchDataAlgolia();
//   if (response.status === "SUCCESS") {
//     if(res)
//   } else {
//     console.log("=== error =====", response);
//   }
// } catch (error) {
//   console.warn("error", error);
// }
// await fetchSearchDataAlgolia();
// const response: any = await getSearchData.fetchData();
