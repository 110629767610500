import OverallSummaryDetailsKPI from "../../../components/login/overallSummaryDetailsKPI/OverallSummarDetailsKPI";
import styles from "./SupplierFullyLoadedDashboard.module.scss";
import MostPurchasedProduct from "../../../components/login/mostPurchasedProduct/MostPurchasedProduct";
import TopBuyer from "../../../components/login/topBuyers/TopBuyer";
import TopPlacedProductChart from "../../../components/login/topPlacedProductChart/TopPlacedProductChart";
import TaskNeedAttention from "../../../components/login/taskNeedAttention/TaskNeedAttention";
import {
  supplierSummaryDetails,
  buyersummaryDetails,
  totalTasksData,
  productPurchasedData,
  topBuyerData,
} from "../../../common/constants/constants";

const SupplierFullyLoadedDashboard = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.right}>
          <div className={styles.content}>
            <div className={`${styles.cards} ${styles.margin}`}>
              <div className={styles.cardHeader}>
                <div className={styles.heading}>
                  Here is your quick overview based on last 30 days
                </div>
              </div>
              <OverallSummaryDetailsKPI
                supplierSummaryDetails={supplierSummaryDetails}
                buyersummaryDetails={buyersummaryDetails}
                userType="supplier"
              ></OverallSummaryDetailsKPI>
            </div>
            <div className={styles.twoCards}>
              <div className={`${styles.cards} ${styles.margin}`}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>
                    Top items placed by buyers
                  </div>
                </div>
                <TopPlacedProductChart></TopPlacedProductChart>
              </div>
              <div className={`${styles.cards} ${styles.margin}`}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>Tasks need attention</div>
                </div>
                <TaskNeedAttention
                  tasksData={totalTasksData}
                ></TaskNeedAttention>
              </div>
            </div>
            <div className={styles.twoCards}>
              <div className={`${styles.cards} ${styles.margin}`}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>
                    Products with low quality score
                  </div>
                </div>
                <MostPurchasedProduct
                  purchasedProductData={productPurchasedData}
                  userType="supplier"
                ></MostPurchasedProduct>
              </div>
              <div className={`${styles.cards} ${styles.margin}`}>
                <div className={styles.cardHeader}>
                  <div className={styles.heading}>Top buyers</div>
                </div>
                <TopBuyer topBuyerData={topBuyerData}></TopBuyer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierFullyLoadedDashboard;
