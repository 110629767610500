import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cancel from "../../../assets/images/cancelToast.svg";
import searchIcon from "../../../assets/images/ordersListing/searchIcon.svg";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import searchImg from "../../../assets/images/so-search.svg";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import PopupView from "../../../common/components/popupView/PopupView";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import { AddSupplierPopup } from "../../../common/pages/productsListing/components/addSupplierPopup/AddSupplierPopup";
import { AdminSupplierListingCard } from "../adminSupplierListingCard/AdminSupplierListingCard";
import cardStyles from "../adminSupplierListingCard/AdminSupplierListingCard.module.scss";
import styles from "./AdminSupplierListing.module.scss";
const AdminSupplierListing = ({ supplierDataArr }: any) => {
  const navigate = useNavigate();

  const itemsPerPage = 9;
  const [, setSelectedCardData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [finalActive] = useState(supplierDataArr);
  const [, setSearchValue] = useState("");
  const [showSort, setShowSort] = useState(false);
  const [prevSelected, setPrevSelected] = useState(-1);


  const visibleData = (data: any) => {
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const visibleProducts = data.slice(firstIndex, lastIndex);
    return visibleProducts;
  };

  const handleSearch = () => {
    setShowSearch(!showSearch);
  };
  const handleSort = () => {
    setShowSort(!showSort);
  };

  const tabsData = [
    {
      id: 0,
      label: constants.productListing.ACTIVE,
      content: finalActive,
      visibleContent: visibleData(finalActive),
    },
  ];

  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
  };
  const handleCardClick = (cardData: any) => {
    setSelectedCardData(cardData);
    if (cardData) {
      navigate(routeConfig.adminSupplierSetupForm, {
        state: {
          cardData: cardData,
        },
      });
    }
  };

  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {},
    },
    {
      id: 1,
      label: constants.productListing.SORT_A_Z,
      onClick: () => {},
    },
    {
      id: 2,
      label: constants.productListing.SORT_Z_A,
      onClick: () => {},
    },
  ];
  const [showPopup, setShowPopup] = useState(false);
  const handlePopup = () => {
    setShowPopup(!showPopup);
  };
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const sortContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showPopup && (
        <PopupView
          heading={constants.productListing.ADD_NEW_SUPPLIER}
          childComponent={AddSupplierPopup}
          height={469}
          width={1116}
          handlePopup={handlePopup}
        />
      )}
      {tabsData.length > 0 && (
        <div className={styles.container}>
          <div className={styles.searchMain}>
            <div className={styles.hr}></div>
            <div
              className={`${styles.actions} ${showSearch && styles.actions2}`}
            >
              <div className={styles.search}>
                {showSearch ? (
                  <div className={styles.searchContainer}>
                    <div className={styles.searchIconInput}>
                      <img src={searchImg} alt="searchIcon" height="24px" />
                      <input
                        type="search"
                        placeholder="Search..."
                        className={styles.searchInput}
                        autoFocus
                        onChange={(e) => setSearchValue(e.target?.value)}
                      />
                    </div>
                    <div className={styles.cancel} onClick={handleSearch}>
                      <img src={cancel} alt="cancel" />
                    </div>
                  </div>
                ) : (
                  <div className={styles.searchIcon}>
                    <img
                      src={searchIcon}
                      alt="search"
                      onClick={handleSearch}
                    ></img>
                  </div>
                )}
              </div>
              <div
                className={`${styles.icon} ${styles.sort}`}
                onClick={handleSort}
                ref={sortContainerRef}
              >
                <img src={sortIcon} alt="sort"></img>
                {showSort && (
                  <div className={styles.sortList}>
                    <div className={styles.sortBy}>
                      {constants.ordersListing.SORT_BY}
                    </div>
                    {sortOptions.map((option, index) => (
                      <div
                        className={`${styles.sortOption} ${
                          index === sortOptions.length - 1 && styles.lastOption
                        } ${option.id === prevSelected && styles.selectedOpt}`}
                        onClick={() => handleSortingOptions(option.id)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {
            <div className={styles.tabsContent}>
              <div className={`${cardStyles.card} ${cardStyles.removeHoverEffect}`}>
                <div className={cardStyles.product}>
                  <div className={`${cardStyles.productName} ${styles.tableHeaderColumn}`}>{constants.suppliersListHeaders.businessName}</div>
                </div>

                <div className={cardStyles.product}>
                  <div className={`${cardStyles.emailID} ${cardStyles.textStyle} ${styles.tableHeaderColumn}`}>
                    {constants.suppliersListHeaders.email}
                  </div>
                </div>

                <div className={cardStyles.product}>
                  <div className={`${cardStyles.brandName} ${cardStyles.textStyle} ${styles.tableHeaderColumn}`}>
                    {constants.suppliersListHeaders.primaryContactName}
                  </div>
                </div>
                <div className={`${cardStyles.brandName} ${cardStyles.textStyle} ${styles.tableHeaderColumn}`}>
                  {constants.suppliersListHeaders.phoneNO}
                </div>
                <div className={`${cardStyles.brandName} ${cardStyles.textStyle} ${styles.tableHeaderColumn}`}>
                  {constants.suppliersListHeaders.address}
                </div>
              </div>
              {tabsData.map((each) => (
                <div className={styles.tabslistContainer}>
                  <div key={each.id} className={styles.ordersList}>
                    {each.visibleContent.map((order: any) => (
                      <AdminSupplierListingCard
                        id={order.id}
                        logo={order.logo}
                        businessName={order.businessName}
                        emailID={order.emailID}
                        licenseNo={order.licenseNo}
                        contactName={order.contactName}
                        phNo={order.phNo}
                        countryName={order.countryName}
                        paymentMethod={order.paymentMethod}
                        address={order.address}
                        emirates={order.emirates?.value}
                        area={order.area}
                        country={order.country?.value}
                        licenseFile={order.licenseFile}
                        contract={order.contract}
                        iban={order.iban}
                        handleCardClick={handleCardClick}
                        confirmPassword={order?.confirmPassword}
                        password={order?.password}
                      />
                    ))}
                  </div>
                  <div className={styles.paginationContainer}>
                    <PaginationComponent
                      count={Math.ceil(each.content.length / itemsPerPage)}
                      page={currentPage}
                      handlePagination={handlePagination}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      )}
    </>
  );
};

export default AdminSupplierListing;
