import styles from "./ProductListingPage.module.scss";

// import React from "react";
const ProductListingPage = () => {
  // const [userType, setUserType] = React.useState("buyer");
  return (
    <div className={styles.app}>
      {/* <SideNav userType={userType} setUserType={setUserType} selectedId={constants.navLinksId.catalogue}/> */}
      {/* <FilterSearchUsingAlphabets /> */}
      {/* <div className={styles.bodyContainer}> */}
      {/* <div className={styles.breadcrumb}>one</div> */}
      {/* <ShopByCategory /> */}
      {/* <ProductListing /> */}
      {/* </div> */}
    </div>
  );
};

export default ProductListingPage;
