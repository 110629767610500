import { IBlogsAndArticles } from "../../../models/IBlogsAndArticles";
import arrow from "../../../assets/images/blogArrow.svg";
import styles from "./BlogsAndArticles.module.scss";
import { useState } from "react";
import "../../constants/constants";
import { constants } from "../../constants/constants";

function BlogsAndArticles(props: IBlogsAndArticles) {
  const [isHover, setIsHover] = useState(false);
  function onMouseLeave() {
    setIsHover(false);
  }
  function onMouseEnter() {
    setIsHover(true);
  }
  function redirectToURL() {
    const url = props?.url;
    if (url) {
      window.open(url, "_blank");
    }
  }

  return (
    <div
      className={styles.BlogsAndArticles}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={redirectToURL}
    >
      <div className={styles.image}>
        <img src={props.img} alt="blog img" />
      </div>
      <div className={styles.details}>
        <div className={styles.info}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.author}>
            {constants.blogsAndArticles.by} {props.author}
          </div>
        </div>
        <div className={styles.cardFooter}>
          <div className={styles.horizontalRule}></div>
          {isHover ? (
            <div className={styles.arrow}>
              <img src={arrow} alt="arrow" />
            </div>
          ) : (
            <div className={styles.readMore}>
              {constants.blogsAndArticles.readMore}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogsAndArticles;
