import { SvgIcon } from "@mui/material";
import React from "react";
import styles from "./start.module.scss";
const StarIcon = ({ stroke = "#B9B9B9", noStroke, filled, ...rest }: any) => {
  return (
    <SvgIcon {...rest} className={`${rest.className} ${filled ? styles.starFilled : ""}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3L14.781 8.59516L21 9.4979L16.5 13.8507L17.562 20L12 17.0952L6.438 20L7.5 13.8507L3 9.4979L9.219 8.59516L12 3Z"
          stroke={!noStroke && stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default StarIcon;
