/* eslint-disable array-callback-return */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import verificationFailed from "../../../assets/images/confirmContactNo/Error.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import verificationSuccess from "../../../assets/images/confirmContactNo/Success.svg";
import otpSent from "../../../assets/images/confirmContactNo/sentOtpStatus.svg";
import errorIcon from "../../../assets/images/confirmContactNo/verificationFailedIcon.svg";
import otpSuccessIcon from "../../../assets/images/confirmContactNo/verificationSuccessIcon.svg";
import verificationInitial from "../../../assets/images/confirmContactNo/VerifyPhone.svg";
// import Dropdown from "../../../common/components/formFields/dropdown/dropdown";
import { constants } from "../../../common/constants/constants";
import styles from "./ConfirmContactNoStyles.module.scss";
import useFetchCountries from "../../../services/useCountry";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../services/useAxios";
import { APIMethods, urlConstants } from "../../../common/constants/urlConstants";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import axios from "axios";
const ConfirmContactNo = ({ overlayCallback }: any) => {
  const { countriesList } = useFetchCountries();
  const [otpStatus, setOtpStatus] = React.useState<
    "initial" | "sent" | "success" | "failed"
  >("initial");
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(loggedInUser?.business_details?.[0]?.customer_id);
  const [number, setNumber] = React.useState(new Array(6).fill(""));

  const [timer, setTimer] = React.useState(0);

  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [mobileNumberCode, setMobileNumberCode] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>()
  const [disableOTP, setDisableOtp] = useState<boolean>(false);
  // const [phoneNoValidation, setPhoneNoValidation] = React.useState(
  //   countriesList[0]?.validation
  // );
  const [isNoValid, setIsNoValid] = React.useState<boolean>(false);
  const elementsRef = React.useRef(
    number && number.map(() => React.createRef<HTMLInputElement>())
  );

  //schema for otp fields, phoneNo and countryCode
  const schema = yup
    .object({
      phoneNo: yup
        .string()
        .required(constants.confirmContactNo.emptyField),
      country: yup.object().required(constants.confirmContactNo.validDropdown),
      otp1: yup.mixed(),
      otp2: yup.mixed(),
      otp3: yup.mixed(),
      otp4: yup.mixed(),
      otp5: yup.mixed(),
      otp6: yup.mixed(),
    })
    .required();

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    watch,
    handleSubmit,
      } = useForm({
    defaultValues: {
      phoneNo: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      country: countriesList[0],
    },
    resolver: yupResolver(schema),
  });

  const getId = (index: number): any => {
    return `otp${[index]}`;
  };
  const onChangeField = React.useCallback(
    (name: any, index: number) => async (text: any) => {
      if (name.includes("otp")) {
        if (index + 1 <= number.length && text) {
          elementsRef.current[index + 1]?.current?.focus();
        }
        number[index] = text;
        setNumber(number);
        if (number?.every((ele: number) => ele)) {
          let response: any
          //verify entered otp
          try{
                response = await axios.post(`${urlConstants.BASE_URL}store/customer/mobileVerify`,
                { customer_id: customerId,
                  phone: `${(watch("country") as any)?.dial_codes}#${watch("phoneNo")}`,
                  otp:number?.join("").toString()
                }
            );
            if(response && (response?.data?.status === "SUCCESS" || response?.status === 200)){
              const profile = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
              const latestDetails = {
                ...profile,
                phone:`${(watch("country") as any)?.dial_codes}#${watch("phoneNo")}`
              };
              localStorage.setItem('loggedInUser',JSON.stringify(latestDetails));
              setOtpStatus("success");
              setDisableOtp(true);
            }
            else{
              setOtpStatus("failed");
            }
          }
          catch(error){
            setOtpStatus("failed");
          }
        }
      } else if (name === "country") {
        // setPhoneNoValidation(text?.validation);
        setValue("phoneNo", "");
        setIsNoValid(false);
        // setIsNoValid(false);
      } else if (name === "phoneNo") {
        setPhoneNumber(text);
        // Check if the entered phone number is valid
        if (text) {
          setIsNoValid(true); // Set isNoValid to true for a valid phone number
        } else {
          setIsNoValid(false); // Set isNoValid to false for an invalid phone number
        }
        setValue(name, text);
        trigger(name);
      }
      setValue(name, text);
      trigger(name);
    },
    [setValue]
  );

  const getOtpStatus = () => {
    switch (otpStatus) {
      case "initial":
        return {
          icon: verificationInitial,
          label: "Verify phone number ",
          desc: "We suggest you to use your WhatsApp number.",
        };
      case "sent":
        return {
          icon: otpSent,
          label: "OTP has been sent",
          desc: "Please enter the received OTP on your mobile number below.",
        };
      case "failed":
        return {
          icon: verificationFailed,
          label: "Verification unsuccessful!",
          desc: "Mobile verification with OTP failed. Please try again.",
        };
      case "success":
        return {
          icon: verificationSuccess,
          label: "Verification successful!",
          desc: "You can now confirm the verified mobile number.",
        };
    }
  };
  const getTime = () => {
    let min = Math.floor(timer / 60);
    let sec = timer % 60;
    return `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
  };

  //api to send otp to the entered phone number
  const sendOtp = useAxios({
    axiosParams:{
      url:`store/customer/generateOtp`,
      data:{
        phone: `${mobileNumberCode?.dial_codes as string}#${phoneNumber}`
      }
    },
    method:APIMethods.post
  });

  const handleGetOtp = async () => {
    if(mobileNumberCode && phoneNumber){
      const otpresponse: any =  await sendOtp?.fetchData();
      if(otpresponse && otpresponse?.status === "SUCCESS"){
        if (otpresponse?.data && otpresponse?.data?.status == 429) {
          setToastData({
            message:"Maximum limit reached.",
            status: "Failure"
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
        else{
          setOtpStatus("sent");
          setTimer(120);
        }
      }
      else{
        setToastData({
          message:otpresponse?.error_msg ?? "Unable to send OTP",
          status: "Failure"
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    }
    setNumber(new Array(6).fill(""));
    elementsRef &&
      elementsRef.current &&
      elementsRef.current.map((each_inp) => {
        if (each_inp && each_inp.current && each_inp.current.value) {
          return (each_inp.current.value = "");
        }
      });
  };

  // handle the confirm action here
  const handleConfirm = (data: any) => {
    const profile = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
    const latestDetails = {
      ...profile,
      phone:`${data?.country?.dial_codes}#${data?.phoneNo}`
    };
    localStorage.setItem('loggedInUser',JSON.stringify(latestDetails));
    overlayCallback({ right: false });
  };
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // React.useEffect(() => {
  //   if (watch("phoneNo") != "") {
  //     setIsNoValid(true);
  //   } else {
  //     setIsNoValid(false);
  //   }
  // }, [watch]);



  return (
    <>
      <div className={styles.container}>
        <div className={styles.otpStatus}>
          <div className={styles.icon}>
            <img src={getOtpStatus().icon} alt="otp status icon" />
          </div>
          <div className={styles.info}>
            <div className={styles.label}>{getOtpStatus().label}</div>
            <div className={styles.desc}>{getOtpStatus().desc}</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.getOtpFieldWrap}>
            {/* <div
              className={`${styles.inputLabel} ${
                errors.phoneNo?.message ? styles.labelError : null
              }`}
            >
              {constants.confirmContactNo.label}
            </div> */}
            {/* <div className={`${styles.inputField}`}> */}
              {/* <Dropdown
                label={""}
                options={countriesList}
                id={"country"}
                register={register("country")}
                isDropdownOutlineRequired={false}
                isCountryList={true}
                onChange={onChangeField("country", 0)}
                mobileNumberField={true}
                overlayWidth={"400px"}
                minWidth={"80px"}
                selectedValue={watch("country")}
              /> */}
              {/* <div className={styles.rowSeperator}></div> */}
              {/* <input
                id="phoneNo"
                type="text"
                placeholder={constants.confirmContactNo.placeHolder}
                className={styles.inputTag}
                {...register("phoneNo")}
                onChange={(e) => onChangeField("phoneNo", 0)(e.target.value)}
              /> */}
              {/* {isNoValid && otpStatus === "initial" && (
                <button className={styles.getOtp} onClick={handleGetOtp}>
                  {constants.confirmContactNo.getOtp}
                </button>
              )} */}
              <MobileNumberField
                  disabled={otpStatus !== "initial"}
                  editForm={otpStatus !== "initial"}
                  showGetOtp={isNoValid && otpStatus === "initial"}
                  handleGetOtp={handleGetOtp}
                  defaultValue={
                    mobileNumberCode ? mobileNumberCode : countriesList[0]
                  }
                  width="100%"
                  overlayWidth={"300px"}
                  label="Phone number"
                  id={"phnNo"}
                  options={countriesList}
                  registerMobileNumber={register("phoneNo")}
                  registerCountry={register("country")}
                  onMobileNumberChange={onChangeField("phoneNo",0)}
                  onCountryChange={(selectedOption: ICountry) => {
                    onChangeField("country",0)(selectedOption);
                    setMobileNumberCode(selectedOption);
                  }}
                  errorMessage={errors.phoneNo?.message}
                  mobileNumberField={true}
                  isConfirmContactNo={true}
              />
            {/* </div> */}

          </div>
          {/* {errors.phoneNo?.message && (
            <p className={styles.error}>{errors.phoneNo?.message}</p>
          )} */}
          {otpStatus !== "initial" && (
            <div className={styles.otpBoxesWrapper}>
              <div className={styles.otpBoxesLabel}>
                {constants.confirmContactNo.otp}
              </div>
              <div className={styles.otpBoxes}>
                {number &&
                  number.map((ele: string, index: number) => (
                    <input
                      className={styles.otpBox}
                      maxLength={1}
                      key={index}
                      {...register(getId(index))}
                      ref={elementsRef?.current[index]}
                      onChange={(e) =>
                        onChangeField(getId(index), index)(e.target.value)
                      }
                      onKeyUp={(e) => {
                        const key = e.key.toLowerCase();
                        if (key === "backspace" || key === "delete") {
                          if (index - 1 >= 0) {
                            elementsRef.current[index]?.current?.focus();
                          }
                        }
                      }}
                      disabled={disableOTP}
                    />
                  ))}
              </div>
              {otpStatus === "sent" && (
                <div className={styles.otpSentInfo}>
                  <div className={styles.otpSentDesc}>
                    {constants.confirmContactNo.otpSentDesc}
                  </div>
                  <div className={styles.timer}>{getTime()}</div>
                </div>
              )}
              {(otpStatus === "success" || otpStatus === "failed") && (
                <div className={styles.verificationStatus}>
                  <div className={styles.statusIcon}>
                    <img
                      src={otpStatus === "success" ? otpSuccessIcon : errorIcon}
                      alt="icon"
                    />
                  </div>
                  <div
                    className={`${
                      otpStatus === "success" ? styles.success : styles.failed
                    }`}
                  >
                    {otpStatus === "success"
                      ? constants.confirmContactNo.vericationSuccess
                      : constants.confirmContactNo.verificationFailed}
                  </div>
                </div>
              )}
              {(otpStatus === "sent" || otpStatus === "failed") && (
                <div className={styles.seperator}></div>
              )}
              {(otpStatus === "sent" || otpStatus === "failed") && (
                <div className={styles.resendOtpWrapper}>
                  <div className={styles.resendOtpDesc}>
                    {otpStatus === "sent"
                      ? constants.confirmContactNo.resendOtpDesc
                      : otpStatus === "failed"
                      ? constants.confirmContactNo.getNewOtpDesc
                      : null}
                  </div>
                  <button className={styles.resendOtp} onClick={handleGetOtp}>
                    {otpStatus === "sent"
                      ? constants.confirmContactNo.resendOtp
                      : otpStatus === "failed"
                      ? constants.confirmContactNo.getNewOtp
                      : null}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <button
            className={styles.cancel}
            onClick={() => overlayCallback({ right: false })}
          >
            {constants.confirmContactNo.cancel}
          </button>
          <button
            className={`${styles.confirm} ${
              otpStatus === "success" ? styles.active : styles.disable
            }`}
            onClick={handleSubmit(handleConfirm)}
          >
            {constants.confirmContactNo.confirm}
          </button>
        </div>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={3000}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ConfirmContactNo;
