import React from "react";
import styles from "./ShopByCategory.module.scss";
import CategoryCard from "../../../../common/components/categoryCard/CategoryCard";
import { ICategoryProps } from "../../../../models/ICategoryProps";
import { createSearchParams, useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../common/constants/routeConfig";

const ShopByCategory = ({ rows }: { rows: ICategoryProps[] }) => {
  const navigate = useNavigate()
  const handleCardClick = (id: number , name: string) => {
    // navigate(routeConfig.subCategoryItemsListPage.replace(':categoryId',id?.toString()),{state:{'categoryName':name}});
    navigate({
      pathname: routeConfig.subCategoryItemsListPage.replace(
        ":categoryId",
        id?.toString()
      ),
      search: createSearchParams({ 'categoryName': [name] })?.toString(),
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Shop by Category</h1>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.row1}>
          {rows &&
            rows.map((each: any, index: number) => (
              <div className={`${styles.row}`}>
                {each.map((item: any) => (
                  <CategoryCard
                    cardType="marketplace"
                    title={item?.label}
                    imgSrc={item?.imgSrc}
                    id={item?.id}
                    handleCardClick={() => handleCardClick(item?.id,item?.label)}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ShopByCategory;
