// import { constants } from "../../../constants/constants";
import Dropdown from "../dropdown/dropdown";
import styles from "./DropdownTextField.module.scss"

const DropdownTextField = ({
    label,
    selectedDropdownValue,
    dropdownList,
    onDropdownChange,
    onTextFieldChange,
    textFieldType,
    textFieldPlaceholder,
    errorMessage,
    registerDropdown,
    registerTextField,
    isCurrenctReq,
    value,
    id,
    minWidth,
    disablePortal,
}: any) => {

    
    return (
        <>
            <div className={`${styles.inputLabelStyles} ${errorMessage ? styles.errorStyle : null}`}>{label}</div>
            <div className={`${styles.fieldWrapper} ${errorMessage ? styles.errorBorder : null}`} style={{minWidth:minWidth}}>
                <Dropdown
                    isfilters={true}
                    key={`dropdown`}
                    label=""
                    options={dropdownList}
                    minWidth={  "120px"}
                    selectedValue={selectedDropdownValue}
                    onChange={onDropdownChange}
                    overlayWidth={"400px"}
                    id={id}
                    isDropdownOutlineRequired={false}
                    isCountryList={false}
                    register={registerDropdown}
                    disablePortal={disablePortal}
                />
                <div className={styles.rowSeperator}></div>
                {isCurrenctReq && <div className={styles.currency}>AED</div>}
                <input
                    key={`input`}
                    type={textFieldType}
                    value={value ?? ""}
                    data-testid={textFieldType}
                    className={styles.inputField}
                    {...registerTextField}
                    placeholder={textFieldPlaceholder}
                    onChange={(e)=> onTextFieldChange(e.target.value)}
                />
            </div>
            {errorMessage ? (
                <div className={styles.errorMessage}>
                    {errorMessage.toString()}
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default DropdownTextField;
