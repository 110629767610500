import React from "react";
import { useNavigate } from "react-router-dom";
import chevronLeft from "../../../assets/images/chevron-left.svg";
import { IBreadcrumbProps } from "../../../models/IBreadcrumbProps";
import styles from "./Breadcrumb.module.scss"; // Import your CSS module

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();
  const handleNavigate = (link?: string , state?:Object) => {
    if (link) {
      if(state){
        navigate(link,{state})
      }
      else{
        navigate(link);
      }
    }
  };
  return (
    <div className={styles.breadcrumbContainer}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.link ? (
            <span
              className={`${styles.breadcrumbLink}`}
              onClick={() => handleNavigate(item.link, item?.state)}
              title={item.label?.length > 40 ? item.label : ""}
            >
              {item.label?.length > 40 ? (item.label as string).slice(0,40)+"..." : item.label}
            </span>
          ) : (
            <span className={`${styles.breadcrumbLabel} `} title={item.label?.length > 40 ? item.label : ""}>{item.label?.length > 40 ? (item.label as string).slice(0,40)+"..." : item.label}</span>
          )}
          {index < items.length - 1 && (
            <span className={styles.breadcrumbSeparator}>
              <img src={chevronLeft} alt="seperator" />
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
