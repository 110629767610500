import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import cancel from "../../../../assets/images/cancelToast.svg";
import sortIcon from "../../../../assets/images/ordersListing/sortIcon.svg";
import Button from "../../../../common/components/button/Button";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import PageSearch from "../../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import { constants } from "../../../../common/constants/constants";
import { ProductListingCard } from "../../../../common/pages/productsListing/components/productListingCard/ProductListingCard";
import styles from "./ProductsApprovalTabsStyles.module.scss";
import { setTab } from "../../../../reducerSlices/selectedTabSlice";
import { useAxios } from "../../../../services/useAxios";
import headerCardStyles from "../../supplierBusinessApprovalProcess/components/approvalCardSupplier/ApprovalCard.module.scss";
import filterIcon from "../../../../assets/images/ordersListing/Filter.svg";
import {
  adminEndPoints,
  APIMethods,
  urlConstants,
} from "../../../../common/constants/urlConstants";
import { addCatalogueListActive } from "../../../../reducerSlices/manageCatalogueList";
import { EmptyContainer } from "../../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import { resetToastStatus } from "../../../../reducerSlices/toastStatusSlice";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import {
  ICategory,
  ICountry,
  IEqualTo,
  IOrderingOption,
  ISubCategory,
  ISubSubCategory,
} from "../../../../models/IAddIndividualProductForm";
import useFetchCountries from "../../../../services/useCountry";
import loadash from "lodash";
import { setPage } from "../../../../reducerSlices/selectedPageSlice";
import axios from "axios";

export const ProductApprovalTabs = () => {
  const { countriesList } = useFetchCountries();
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.products_approval ?? 0
  );
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.productApprovalPageNumber
  );
  const [selectedBrand, setSelectedBrand] = useState<any>({});
  const [categories, setCategories] = useState<ICategory[]>(
    constants.tempCategory
  );

  const [selectedSubcategory, setSelectedSubcategory] = useState<ISubCategory>(
    {}
  );
  const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    ICategory[]
  >([]);
  const [selectedProductSection, setSelectedProductSection] = useState<
    ISubSubCategory[]
  >([]);
  const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
  const [selectedSection, setSelectedSection] = useState<ISubSubCategory>({});
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [selectedMeasuringUnit, setSelectedMeasuringUnit] =
    useState<IOrderingOption>({});

  const [searchText, setSearchText] = useState("");
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  const { manageCatalogueList } = useSelector((state: any) => state);
  const toastStatus = useSelector((state: any) => state?.toastStatus);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [finalAll, setFinalAll] = useState([]);
  const [finalActive, setFinalActive] = useState([]);
  const [finalInReview, setFinalInreview] = useState([]);
  const [finalRejected, setFinalRejected] = useState([]);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [activeTab, setActiveTab] = useState(selectedTab);
  const filtersRef = useRef<HTMLDivElement>(null);
  const [showSort, setShowSort] = useState(false);
  const [prevSelected, setPrevSelected] = useState(-1);
  const [showFilter, setShowFilter] = useState(false);
  const [childCardData, setchildCardData] = useState<{
    id: number | string | undefined;
    show: boolean;
  }>({ id: undefined, show: false });
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const scrollTabDataRef = useRef<HTMLDivElement>(null);
  const filitersContainerRef = useRef<HTMLDivElement>(null);
  const sortByAscAmount = () => {
    let filteredData;
    if (activeTab === 0) {
      filteredData = finalActive;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? 1 : -1
      );
      setFinalActive(filteredData);
    } else if (activeTab === 1) {
      filteredData = finalInReview;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? 1 : -1
      );
      setFinalInreview(filteredData);
    } else if (activeTab === 2) {
      filteredData = finalRejected;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? 1 : -1
      );
      setFinalRejected(filteredData);
    }
  };
  const sortByDescAmount = () => {
    let filteredData;
    if (activeTab === 0) {
      filteredData = finalActive;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() < b.productName.toLowerCase() ? 1 : -1
      );
      setFinalActive(filteredData);
    } else if (activeTab === 1) {
      filteredData = finalInReview;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() < b.productName.toLowerCase() ? 1 : -1
      );
      setFinalInreview(filteredData);
    } else if (activeTab === 2) {
      filteredData = finalRejected;
      filteredData = filteredData.sort((a: any, b: any) =>
        a.productName.toLowerCase() < b.productName.toLowerCase() ? 1 : -1
      );
      setFinalRejected(filteredData);
    }
  };
  const selectTab = (index: number) => {
    setCheckedItems([]);
    setIsAllSelected(false);
    dispatch(setTab({ type: "products_approval", index }));
    setActiveTab(index);
    setCurrentPage(1);
    dispatch(setPage({ type: "productApprovalPageNumber", index: 1 }));
  };
  const itemsPerPage = constants.paginationLimitPerPage;
  // const visibleData = (data: any) => {
  //   const lastIndex = currentPage * itemsPerPage;
  //   const firstIndex = lastIndex - itemsPerPage;
  //   const visibleProducts = data.slice(firstIndex, lastIndex);
  //   return visibleProducts;
  // };
  const searchCustomStyles = {
    top: "0px",
  };
  const tabsData = [
    {
      id: 0,
      label: constants.productListing.ALL,
      content: finalAll,
      visibleContent: finalAll,
    },
    {
      id: 1,
      label: constants.productListing.PENDING,
      content: finalInReview,
      visibleContent: finalInReview,
    },
    {
      id: 2,
      label: constants.productListing.APPROVED,
      content: finalActive,
      visibleContent: finalActive,
    },
    {
      id: 3,
      label: constants.productListing.REJECTED,
      content: finalRejected,
      visibleContent: finalRejected,
    },
    {
      id: 4,
      label: constants.productListing.PROPOSED,
      content: finalActive,
      visibleContent: finalActive,
    },
  ];
  const handlePagination = (e: any, page: number) => {
    setIsAllSelected(false);
    setCurrentPage(page);
    dispatch(setPage({ type: "productApprovalPageNumber", index: page }));
  };

  const handleSort = () => {
    setShowSort(!showSort);
  };
  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {},
    },
    {
      id: 1,
      label: constants.productListing.SORT_A_Z,
      onClick: sortByAscAmount,
    },
    {
      id: 2,
      label: constants.productListing.SORT_Z_A,
      onClick: sortByDescAmount,
    },
  ];
  const handleBrandClick = (brandValue: any) => {
    setSelectedBrand(brandValue);
  };
  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryObj = categories?.find(
        (cat: ICategory) => cat.value === selectedCategory?.value
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.subcategories &&
        selectedCategoryObj?.subcategories?.length > 0
      ) {
        const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
          (category: ICategory) => ({
            ...category,
            value: category?.name,
            productSection: category?.sub_sub_categories,
          })
        );
        setSelectedSubcategories(updatedCategoriesData);
      } else {
        setSelectedSubcategories([]);
      }
    } else {
      setSelectedSubcategories([]);
    }
  }, [selectedCategory, categories]);

  const handleCategoryClick = (categoryValue: ICategory) => {
    setSelectedCategory(categoryValue);
    setSelectedSubcategories([]);
    setSelectedProductSection([]);
  };

  const handleSubcategoryClick = (subcategoryValue: ISubCategory) => {
    if (!subcategoryValue) return;
    setSelectedSubcategory(subcategoryValue);
    const selectedSubcategoryObj = selectedSubcategories.find(
      (subcat: ICategory) => subcat.value === subcategoryValue?.value
    );
    if (selectedSubcategoryObj) {
      if (selectedSubcategoryObj?.productSection) {
        const updatedCategoriesData =
          selectedSubcategoryObj?.productSection?.map(
            (category: ISubCategory) => ({
              ...category,
              value: category?.name,
              productSection: selectedSubcategoryObj?.sub_sub_categories,
            })
          ) as ISubSubCategory[];
        setSelectedProductSection(updatedCategoriesData);
      } else {
        setSelectedProductSection([]);
      }
    } else {
      setSelectedProductSection([]);
    }
  };
  // get measuring units api call starts
  const measuringUnitsApi = useAxios({
    axiosParams: {
      url: "store/units",
    },
    method: "GET",
  });

  const getMeasuringUnits = useCallback(async () => {
    const response: any = await measuringUnitsApi?.fetchData();
    if (response) {
      const updatedMeasuringUnits = response?.data?.product_units?.map(
        (unit: IEqualTo) => ({
          ...unit,
          value: unit.name,
        })
      );
      setMeasuringUnits(updatedMeasuringUnits);
    }
  }, [measuringUnitsApi]);

  useEffect(() => {
    getMeasuringUnits();
  }, []);
  // get categories api call starts
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: "GET",
  });

  const getAllCategoriesList = useCallback(async () => {
    const response: any = await getCategories?.fetchData();
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          subcategories: category.sub_categories,
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
    }
  }, [getCategories]);

  useEffect(() => {
    getAllCategoriesList();
  }, []);
  const handleReset = () => {
    setValue("productBrand", null);
    setValue("category", null);
    setValue("subCategory", null);
    setValue("productSection", null);
    setValue("countryOrigin", null);
    setValue("baseUnitSize", null);
    setValue("paidCheckBox", null);
    setValue("unPaidCheckBox", null);

    setSelectedBrand({});
    setSelectedCategory({});
    setSelectedSubcategory({});
    setSelectedSection({});
    setSelectedCountry({});
    setSelectedMeasuringUnit({});
    getAllCategoriesList();
  };
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchText(value);
    dispatch(setPage({ type: "productApprovalPageNumber", index: 1 }));
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  useEffect(() => {
    scrollTabDataRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const schema = yup.object({
    productBrand: yup.mixed(),
    category: yup.mixed(),
    subCategory: yup.mixed(),
    productSection: yup.mixed(),
    countryOrigin: yup.mixed(),
    baseUnitSize: yup.mixed(),
    paidCheckBox: yup.mixed(),
    unPaidCheckBox: yup.mixed(),
  });
  const confirmFilters = () => {
    setShowFilter(false);
  };
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      if (name === "countryName") setValue(name, text);
    },
    [setValue]
  );

  const getProductList = useAxios({
    axiosParams: {
      url: `${adminEndPoints.getAllStatesProductList}${
        activeTab !== 0
          ? `?status=${
              activeTab === 0
                ? "draft,published,rejected"
                : activeTab === 1
                ? "draft"
                : activeTab === 2
                ? "published"
                : activeTab === 3
                ? "rejected"
                : activeTab === 4
                ? "proposed"
                : "published"
            }&`
          : "?"
      }limit=${itemsPerPage}&page=${currentPage}&offset=${
        (currentPage - 1) * itemsPerPage
      }${searchText.length > 0 ? `&q=${searchText}` : ""}`,
    },
    method: APIMethods.get,
  });

  const getCatalogueProductsList = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductList.fetchData();
    if (response && response?.data && response?.data?.products) {
      const data = response?.data?.products;
      data.forEach((ele: any) => {
        ele["icon"] = ele?.image_url;
        ele["productName"] = ele?.product_title;
        // ele['status'] = productStatus(ele?.product_status)
        ele["productStatus"] = ele?.product_is_deactivated
          ? "Deactivated"
          : "Active";
        ele["productQuantity"] = ele?.product_variant_metadata?.selected_unit;
        ele["brandName"] = ele?.product_brand_name ?? "-";
        ele["countryFlag"] = ele?.country_flag;
        ele["countryOrigin"] = ele?.country_flag;
        ele["countryOrigin"] = ele?.country_display_name;
        ele["productSubCategory"] = ele?.sub_sub_category_name;
        ele["productCategory"] = ele?.product_category_name;
        ele["productCardState"] = ele?.product_status;
        ele["productType"] = ele?.product_type;
      });
      dispatch(addCatalogueListActive(data));
      setTotalProductCount(response?.data?.count);
      setShowEmptyContainer(data.length === 0);
      dispatch(updateIsLoading(false));
    } else {
      dispatch(updateIsLoading(false));
    }
    // }
  };

  const getDebouncedData = loadash.debounce(getCatalogueProductsList, 200);

  // useEffect(() => {
  //   if(searchText)
  //   getDebouncedData();
  //   return () => getDebouncedData.cancel();
  // }, [searchText]);

  const handleShowMenu = (id: number | string | undefined, show: boolean) => {
    setchildCardData({ id: id, show: show });
  };

  useEffect(() => {
    if (searchText !== undefined) getDebouncedData();
    else getCatalogueProductsList();
    return () => getDebouncedData?.cancel();
  }, [searchText, currentPage, activeTab]);

  // useEffect(() => {
  //   getCatalogueProductsList();
  // }, []);

  useEffect(() => {
    if (manageCatalogueList) {
      switch (activeTab) {
        case 0:
          setFinalAll(manageCatalogueList);
          break;
        case 1:
          setFinalInreview(manageCatalogueList);
          break;
        case 2:
        case 4:
          setFinalActive(manageCatalogueList);
          break;
        case 3:
          setFinalRejected(manageCatalogueList);
          break;
        default:
          break;
      }
    }
  }, [manageCatalogueList]);
  useEffect(() => {
    if (
      toastStatus?.message?.length > 0 &&
      toastStatus?.type === "productStatus"
    ) {
      setToastData({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }, [dispatch, toastStatus]);

  const checkSelectedTab = (checkingTab: string) => {
    const selectedTab: any = tabsData?.find((tab: any) => tab.id === activeTab);

    if (selectedTab) {
      return selectedTab?.label === checkingTab;
    } else {
      return false;
    }
  };

  const [checkedItems, setCheckedItems] = useState<any>([]);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleCheckBoxChange = (productId: string) => {
    if (checkedItems?.includes(productId)) {
      const newItems = checkedItems?.filter((item: any) => item !== productId);
      setCheckedItems(newItems);
    } else {
      const newItems = [...checkedItems, productId];
      setCheckedItems(newItems);
    }
  };

  const handleSelectedAll = (dropDownSelect: boolean) => {
    if (dropDownSelect && (activeTab === 4 || activeTab === 1)) {
      const products =
        activeTab === 1 ? tabsData[1]?.content : tabsData[4]?.content;

      const updatingIds = products?.map((item: any) => item?.productId);
      const combinesIds = [
        ...checkedItems,
        ...updatingIds.filter(
          (item2) => !checkedItems.some((item1: any) => item1 === item2)
        ),
      ];
      setCheckedItems([...combinesIds]);
    }
    if (!dropDownSelect) {
      const products =
        activeTab === 1 ? tabsData[1]?.content : tabsData[4]?.content;
      const productIds = products?.map((item: any) => item?.productId);
      const updatingIds = checkedItems?.filter(
        (item: any) => !productIds?.includes(item)
      );
      setCheckedItems([...updatingIds]);
    }
  };

  useEffect(() => {
    if (activeTab === 1 || activeTab === 4) {
      const products =
        activeTab === 1 ? tabsData[1]?.content : tabsData[4]?.content;
      const productIds = products?.map((item: any) => item?.productId);
      if (
        checkedItems?.length >= productIds?.length &&
        productIds?.length > 0
      ) {
        const isAllementsExists = productIds.every((element: any) =>
          checkedItems?.includes(element)
        );
        if (isAllementsExists) {
          setIsAllSelected(true);
        } else {
          setIsAllSelected(false);
        }
      } else {
        setIsAllSelected(false);
      }
    }
  }, [checkedItems, tabsData]);

  const onApproveClick = async () => {
    dispatch(updateIsLoading(true));
    console.log("url is =====", urlConstants.BASE_URL);
    const endPoint: string =
      activeTab === 1
        ? "admin/product/bulk_approve"
        : "admin/product/bulk_proposed_product_create";
    const url: string = `${urlConstants.BASE_URL}${endPoint}`;
    const body = { product_ids: checkedItems };
    const response = await axios.post(url, body);

    if (response?.data?.status === "SUCCESS") {
      setCheckedItems([]);
      await getCatalogueProductsList();
    } else {
      setToastData({
        message: response?.data?.error_msg ?? "Something went wrong",
        status: response?.data?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setToastData(constants.defaultToastData);
      }, 5000);
    }

    dispatch(updateIsLoading(false));
  };

  return (
    <div className={styles.ParentContainer}>
      {/* <AdminSideNav /> */}
      {tabsData.length > 0 && (
        <div className={styles.container}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.heading}>Products Approval </div>
            {checkedItems?.length !== 0 &&
              (activeTab === 1 || activeTab === 4) && (
                <div style={{ width: 156, padding: 16 }}>
                  <Button
                    // eslint-disable-next-line no-template-curly-in-string
                    label={`Approve ( ${checkedItems?.length} )`}
                    secondary={true}
                    handleClick={() => onApproveClick()}
                  />
                </div>
              )}
          </div>
          <div className={styles.tabs}>
            <div className={styles.tabsStyle}>
              <div className={styles.tabList}>
                {tabsData.map((each) => (
                  <div
                    className={styles.tabListItem}
                    onClick={() => selectTab(each.id)}
                  >
                    <div
                      className={`${styles.label} ${
                        each.id === activeTab && styles.activeLabel
                      }`}
                    >
                      {each.label}
                    </div>
                    {activeTab === each.id && (
                      <div className={styles.border}></div>
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.hr}></div>
            </div>
            <div className={`${styles.actions} ${styles.actions2}`}>
              <PageSearch
                handleSearch={handleSearch}
                customStyles={searchCustomStyles}
                customPlaceHolder="Search by product name or category"
              />
              <div
                className={`${styles.icon} ${styles.sort} ${styles.hide}`}
                onClick={handleSort}
                ref={sortContainerRef}
              >
                <img src={sortIcon} alt="sort"></img>
                {showSort && (
                  <div className={styles.sortList}>
                    <div className={styles.sortBy}>
                      {constants.ordersListing.SORT_BY}
                    </div>
                    {sortOptions.map((option, index) => (
                      <div
                        className={`${styles.sortOption} ${
                          index === sortOptions.length - 1 && styles.lastOption
                        } ${option.id === prevSelected && styles.selectedOpt}`}
                        onClick={() => handleSortingOptions(option.id)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className={`${styles.icon} ${styles.hide}`}
                onClick={handleFilter}
                ref={filtersRef}
                data-testId="filterBtn"
              >
                <img src={filterIcon} alt="filter"></img>
              </div>
            </div>
          </div>
          {!showEmptyContainer ? (
            <div className={styles.tabsContent} style={{ marginTop: "30px" }}>
              {tabsData.map(
                (each) =>
                  activeTab === each.id && (
                    <div className={styles.tabslistContainer}>
                      <div className={`${styles.tableHeader}`}>
                        <div className={styles.details}>
                          <div
                            className={`${headerCardStyles.column} ${styles.productName} ${styles.headerColor} ${styles.productNameAndCheckbox}`}
                          >
                            {(checkSelectedTab("Proposed") ||
                              checkSelectedTab("Pending")) && (
                              <CheckboxButton
                                checked={isAllSelected}
                                handleChange={() => {
                                  handleSelectedAll(!isAllSelected);
                                  setIsAllSelected(!isAllSelected);
                                }}
                              />
                            )}
                            <p>Product Name</p>
                          </div>

                          <div
                            className={`${headerCardStyles.column} ${styles.businessName}`}
                          >
                            {constants.buyersListHeaders.businessName}
                          </div>
                          {/* <div
                            className={`${headerCardStyles.column} ${styles.headerColor} ${styles.contactName}`}
                          >
                            Watermelon product code
                          </div>{" "} */}
                          <div
                            className={`${headerCardStyles.column} ${styles.headerColor} ${styles.email}`}
                          >
                            Product brand
                          </div>
                          <div
                            className={`${headerCardStyles.column} ${styles.headerColor} ${styles.address}`}
                          >
                            Country Origin
                          </div>
                          <div
                            className={`${headerCardStyles.column} ${styles.headerColor}`}
                          >
                            Category
                          </div>
                        </div>
                      </div>
                      <div
                        ref={scrollTabDataRef}
                        key={each.id}
                        className={styles.ordersList}
                      >
                        {each.visibleContent.map((order: any) => {
                          let orderData = cloneDeep(order);
                          // orderData["countryFlag"] = orderData.productCountryOrigin?.icon
                          orderData["icon"] = orderData?.icon;
                          orderData["countryOrigin"] =
                            orderData.productCountryOrigin?.name;
                          orderData["productSubCategory"] =
                            orderData.productSubCategory?.name;
                          orderData["productCategory"] =
                            orderData.productCategory?.name;

                          return (
                            <ProductListingCard
                              order={orderData}
                              showMenu={childCardData}
                              isNewProductTab={activeTab === 4}
                              handleShowMenu={handleShowMenu}
                              isProposedTab={checkSelectedTab("Proposed")}
                              isPendingTab={checkSelectedTab("Pending")}
                              isItemChecked={checkedItems?.includes(
                                orderData?.productId
                              )}
                              onCheckBoxSelected={() =>
                                handleCheckBoxChange(orderData?.productId)
                              }
                            />
                          );
                        })}
                      </div>
                      <div className={styles.paginationContainer}>
                        <PaginationComponent
                          count={Math.ceil(totalProductCount / itemsPerPage)}
                          page={currentPage}
                          handlePagination={handlePagination}
                          showFirstButton={true}
                          showLastButton={true}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div className={styles.emptyContainer}>
              <EmptyContainer title={"Woah!"} subText={"No products"} />
            </div>
          )}
        </div>
      )}
      {showFilter && (
        <div className={styles.overlay}>
          <div className={styles.filters} ref={filitersContainerRef}>
            <div className={styles.header}>
              <div data-teId="heading" className={styles.filtersHeading}>
                Filters
              </div>
              <div className={styles.cancelFilter} onClick={handleFilter}>
                <img src={cancel} alt="cancel"></img>
              </div>
            </div>
            <form
              className={styles.form}
              onSubmit={handleSubmit(confirmFilters)}
            >
              <div className={styles.formInputs}>
                <div className={styles.payment}>
                  <div className={styles.paymentLabel}>Status</div>
                  <div className={styles.paymentInput}>
                    <div className={styles.paid}>
                      <CheckboxButton
                        customClass={styles.checkboxField}
                        customLabel={styles.checkboxLabel}
                        register={register("paidCheckBox")}
                        handleChange={() => {
                          onChangeField("paidCheckBox");
                        }}
                        label="Active"
                      />
                    </div>
                    <div className={styles.paid}>
                      <CheckboxButton
                        customClass={styles.checkboxField}
                        customLabel={styles.checkboxLabel}
                        register={register("unPaidCheckBox")}
                        handleChange={() => {
                          onChangeField("unPaidCheckBox");
                        }}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Dropdown
                    minWidth="400px"
                    label={"Product brand"}
                    register={register("productBrand")}
                    selectedValue={selectedBrand}
                    options={constants.foodBrands}
                    defaultLabel={selectedBrand?.label ?? "Select the brand"}
                    id={"0"}
                    onChange={(selectedOption: any) => {
                      onChangeField("productBrand");
                      handleBrandClick(selectedOption);
                    }}
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    label={"Category"}
                    options={categories}
                    defaultLabel={
                      selectedCategory?.name ?? "Select the category"
                    }
                    id={"1"}
                    register={register("category")}
                    onChange={(selectedOption: ICategory) => {
                      onChangeField("category")(selectedOption);
                      handleCategoryClick(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    label={"Subcategory"}
                    selectedValue={selectedSubcategory}
                    options={selectedSubcategories}
                    defaultLabel={
                      selectedSubcategory?.name ?? "Select the subcategory"
                    }
                    id={"2"}
                    onChange={(selectedOption: ISubCategory) => {
                      onChangeField("subCategory")(selectedOption);
                      handleSubcategoryClick(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedSection}
                    label={"Subsubcategory"}
                    defaultLabel={
                      selectedSection?.name ?? "Select the Sub subcategory"
                    }
                    id={"2"}
                    options={selectedProductSection}
                    register={register("subCategory")}
                    onChange={(selectedOption: ISubSubCategory) => {
                      onChangeField("productSection")(selectedOption);
                      setSelectedSection(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedCountry}
                    label={"Country origin"}
                    options={countriesList}
                    defaultLabel={
                      selectedCountry?.name ?? "Select the country origin"
                    }
                    id={"3"}
                    register={register("countryOrigin")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("productCountryOrigin")(selectedOption);
                      setSelectedCountry(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedMeasuringUnit}
                    label={"Base unit size"}
                    options={measuringUnits}
                    defaultLabel={
                      selectedMeasuringUnit?.name ?? "Select the size"
                    }
                    id={"4"}
                    register={register("baseUnitSize")}
                    onChange={(selectedOption: any) => {
                      onChangeField("baseUnitSize");
                      setSelectedMeasuringUnit(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
              </div>
              <div className={styles.filterActions}>
                <div className={styles.reset} onClick={handleReset}>
                  Reset
                </div>
                <div>
                  <Button
                    label={"Cancel"}
                    handleClick={handleFilter}
                    width="120px"
                    secondary={true}
                  />
                </div>
                <div>
                  <Button
                    label={"Confirm"}
                    handleClick={handleSubmit(confirmFilters)}
                    width="120px"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {toastData?.message !== "" ? (
        <ToastNotification
          icon={
            toastData.status === "approved" || toastData.status === "rejected"
              ? successToast
              : errorToast
          }
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
