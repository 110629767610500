import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SpecialPriceState {
  selectedBuyerId: string;
  selectedSupplierId: string;
  selectedVariants: Array<{
    variantName: string;
    variantId: string;
    originalPrice?: any;
    price?: any;
    priceErrorMessage?: string;
    moValueErrorMessage?: string;
    moOptionErrorMessage?: string;
    productId?: string;
    options?: any;
    moOption?: any;
    moValue?: any;
  }>;
}

const initialState: SpecialPriceState = {
  selectedBuyerId: "",
  selectedSupplierId: "",
  selectedVariants: [],
};

export const specialPriceSlice = createSlice({
  name: "specialPrice",
  initialState,
  reducers: {
    updateSelectedBuyerId: (state, action) => {
      state.selectedBuyerId = action.payload;
    },
    updateSelectedSupplierId: (state, action) => {
      state.selectedSupplierId = action.payload;
    },
    updateSelectedVariants: (
      state: any,
      action: PayloadAction<
        Array<{ variantId: string; originalPrice?: any; price: any; options?:any; moOption?:any; moValue?:any }>
      >
    ) => {
      state.selectedVariants = action.payload;
    },
    updatePrice: (
      state: any,
      action: PayloadAction<{
        variantId: string;
        originalPrice?: any;
        price: any;
      }>
    ) => {
      console.log(action.payload, "payload");
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, price: action.payload.price };
        }
        return item;
      });
      state.selectedVariants = data;
    },
    updateMoOption: (
      state: any,
      action: PayloadAction<{
        variantId: string;
        moOption?: any;
      }>
    ) => {
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, moOption: action.payload.moOption };
        }
        return item;
      });
      console.log(data, "redux")
      state.selectedVariants = data;
    },
    updateMoValue: (
      state: any,
      action: PayloadAction<{
        variantId: string;
        moValue?: any;
      }>
    ) => {
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, moValue: action.payload.moValue };
        }
        return item;
      });
      state.selectedVariants = data;
    },
    updatePriceErrorMessage: (
      state: any,
      action: PayloadAction<{ variantId: string; priceErrorMessage: string }>
    ) => {
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, priceErrorMessage: action.payload.priceErrorMessage };
        }
        return item;
      });
      state.selectedVariants = data;
    },
    updateMoOptionErrorMessage: (
      state: any,
      action: PayloadAction<{ variantId: string; moOptionErrorMessage: string }>
    ) => {
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, moOptionErrorMessage: action.payload.moOptionErrorMessage };
        }
        return item;
      });
      state.selectedVariants = data;
    },
    updateMoValueErrorMessage: (
      state: any,
      action: PayloadAction<{ variantId: string; moValueErrorMessage: string }>
    ) => {
      const data = state?.selectedVariants?.map((item: any, i: any) => {
        if (item?.variantId === action.payload.variantId) {
          return { ...item, moValueErrorMessage: action.payload.moValueErrorMessage };
        }
        return item;
      });
      state.selectedVariants = data;
    },
    removeSelectedVariant: (state, action: PayloadAction<{ variantId: string; }>) => {
      const updatedItems = state?.selectedVariants?.filter(
        (item: any) => item.variantId !== action?.payload?.variantId
      );
      state.selectedVariants = updatedItems;
    }
  },
});

export const {
  updateSelectedVariants,
  updatePrice,
  updatePriceErrorMessage,
  updateMoValueErrorMessage,
  updateMoOptionErrorMessage,
  updateSelectedBuyerId,
  updateSelectedSupplierId,
  removeSelectedVariant,
  updateMoOption,
  updateMoValue,
} = specialPriceSlice.actions;

export default specialPriceSlice.reducer;
