import { createSlice } from "@reduxjs/toolkit";
import cancelled from "../assets/images/orderStatusIcons/CancelledSmall.svg";
import confirmed from "../assets/images/orderStatusIcons/Confirmed.svg";
import delivered from "../assets/images/orderStatusIcons/Delivered.svg";
import packed from "../assets/images/orderStatusIcons/Packed.svg";
import placed from "../assets/images/orderStatusIcons/Placed.svg";
import onProgress from "../assets/images/orderStatusIcons/Progressing.svg";
import { convertTimeStampToUserTimeZone } from "../utils/functions";

export const getIcon = (status: string) => {
  switch (status) {
    case "placed":
      return placed;
    case "delivered":
      return delivered;
    case "confirmed":
      return confirmed;
    case "shipped":
      return packed;
    case "canceled":
      return cancelled;
    default:
      return onProgress;
  }
};
const transformKeys = (item: any) => {
  return {
    actualAmount: Number(item?.actual_amount)?.toFixed(2) ?? "-",
    icon: getIcon(item?.order_tracking?.status),
    orderId: item?.id ?? "-",
    orderStatus:
      item?.fulfillment_status === "not-fullfilled"
        ? "Modified"
        : item?.order_tracking?.status?.replace(
            /^./,
            item?.order_tracking?.status[0].toUpperCase()
          ) ?? "-",
    paidStatus:
      item?.payment_status != null
        ? item?.payment_status === "not_paid"
          ? "Unpaid"
          : item?.payment_status?.replace(
              /^./,
              item?.payment_status[0].toUpperCase()
            )
        : "-",
    placedOn:
      convertTimeStampToUserTimeZone(item?.order_tracking?.placed_date) ?? "-",
    buyerName: item?.buyer?.business_name || "-",
    orderValue: item?.total != null ? Number(item?.total)?.toFixed(2) : "-",
    orderItems: item?.items?.count || "-",
    type: item?.status ?? "-",
    orderPONumber: item?.order_number ?? "-",
  };
};

const manageOrdersListSlice = createSlice({
  name: "manageOrdersList",
  initialState: [],
  reducers: {
    addOrdersListPlaced: (state, action) => {
      const transformedPayload = action.payload.map(transformKeys);
      return transformedPayload;
    },
    updateOrderList: (state, action) => {
      const latestOrders = state?.filter(
        (each_order: any) => each_order?.orderId != action?.payload?.orderId
      );
      return latestOrders;
    },
  },
});

export const { addOrdersListPlaced, updateOrderList } =
  manageOrdersListSlice.actions;

export default manageOrdersListSlice.reducer;
