import styles from "./TopBuyer.module.scss";
import ProgressBar from "../../../common/components/progressBar/ProgressBar";
import footerIcon from "../../../assets/images/footerIcon.svg";
import { ITopBuyerProps } from "../../../models/ITopBuyers";

const TopBuyer = ({ topBuyerData }: any) => {
  return (
    <>
      <div className={styles.parentContainer}>
        {topBuyerData &&
          topBuyerData?.map((data: ITopBuyerProps, index: number) => (
            <div
              className={styles.cardParent}
              key={data?.id}
              data-testid={`buyerCard-${data?.id}`}
              title="card"
            >
              <div className={styles.buyerCard}>
                <div className={styles.progressBarContainer}>
                  <div className={styles.progressBarInfo}>
                    <div className={styles.buyerName}>{data?.buyerName}</div>
                    <div className={styles.totalAED}>
                      AED<span>{data?.aed?.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className={styles.progressBar}>
                    <ProgressBar
                      progress={data?.buyerValue}
                      customClass={styles.progressBarStyles}
                      customBarColor={"#FCA66C"}
                      borderRadius="8px"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className={styles.footerContainer}>
          <div className={styles.footerIcon}>
            <img src={footerIcon} alt="footerIcon" />
          </div>
          <div className={styles.footerText}>
            Punjabi Kadhai has place 10% more orders in last 3 weeks.
          </div>
        </div>
      </div>
    </>
  );
};
export default TopBuyer;
