import styles from "../AdminDashboard.module.scss";
import AdminCatalogueListing from "../../adminCatalogueListing/AdminCatalogueListing";

const AdminManageCatalogue = () => {
  return (
    <div className={styles.container}>
      <div className={styles.rightContainer}>
        <div className={styles.supplierContainer}>
          <AdminCatalogueListing />
        </div>
      </div>
    </div>
  );
};

export default AdminManageCatalogue;
