// import React, { useState } from "react";
import styles from "./ProductCardCta.module.scss";
import { IProductCardCtaProps } from "../../../models/IProductWithCart";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { CircularProgress } from "@mui/material";
const ProductCardCta = ({
  showAddItemValue,
  setShowAddItemValue,
  matchQuantity,
  width,
  selectedVariant,
  handleOnQuantityChange,
  isAddToCartLoading,
  minOrderQuantity = 1,
}: IProductCardCtaProps) => {
  // const [showAddItemValue, setShowAddItemValue] = useState(false);
  const [value, setValue] = useState<number>(matchQuantity ?? 0);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const handleClick = () => {
    if (value === 0) {
      setValue(minOrderQuantity);
      setShowAddItemValue(true);
      setIsEmpty(false);
      handleOnQuantityChange && handleOnQuantityChange(minOrderQuantity);
    } else {
      setValue(0);
      handleOnQuantityChange && handleOnQuantityChange(0);
      setShowAddItemValue(false);
    }
  };

  const handlePositive = () => {
    setValue(value + 1);
    handleOnQuantityChange && handleOnQuantityChange(value + 1);
    if (isEmpty) {
      setIsEmpty(false);
    }
  };

  const handleNegative = () => {
    if (value > minOrderQuantity) {
      setValue(value - 1);
      handleOnQuantityChange && handleOnQuantityChange(value - 1);
      if (isEmpty) {
        setIsEmpty(false);
      }
    } else {
      setShowAddItemValue(false);
      // setValue(1);
      setValue(0);
      handleOnQuantityChange && handleOnQuantityChange(0);
    }
  };
  const handleOnBlur = () => {
    console.log("on blur triggered");
    if (value < minOrderQuantity || isEmpty) {
      setValue(0);
      setShowAddItemValue(false);
      handleOnQuantityChange && handleOnQuantityChange(0);
    }
  };

  const debouncedQuantity = useDebounce(() => {
    !isEmpty && handleOnQuantityChange && handleOnQuantityChange(value);
  }, 1000);

  useEffect(() => {
    if (!value && !isEmpty) {
      setShowAddItemValue(false);
    } else {
      setShowAddItemValue(true);
    }
  }, [value]);
  useEffect(() => {
    if (selectedVariant) {
      setValue(selectedVariant?.match_quantity);
    }
  }, [selectedVariant?.id]);

  return !isAddToCartLoading ? (
    <div
      style={{ width: width }}
      className={styles.productCardCtaContainer}
      key={selectedVariant?.id}
    >
      <button
        data-testid="add-button"
        className={`${styles.ctaButton} ${
          showAddItemValue ? styles.hideCta : ""
        }`}
        onClick={handleClick}
      >
        Add
      </button>
      {showAddItemValue && (
        <div
          data-testid="item-value-container"
          className={`${styles.addItemValueContainer} ${styles.showItemValueContainer}`}
        >
          <button
            data-testid="minus-button"
            className={`${styles.addItemValueButton} ${styles.minus}`}
            onClick={handleNegative}
          >
            -
          </button>
          <input
            type="numeric"
            data-testid="item-value"
            className={styles.addItemValue}
            value={value}
            onChange={(e) => {
              // if (/^\d*$/.test(e.target.value)) {
              //   const quantity = e.target.value
              //   setValue(Number(quantity));
              //   handleOnQuantityChange && handleOnQuantityChange(Number(quantity));
              // }
              const quantity = e.target.value;
              if (
                quantity !== "" &&
                /[0-9]+/g.test(quantity) &&
                Number(quantity) > 0 &&
                Number(quantity) <= 999
              ) {
                setValue(Number(quantity));
                setIsEmpty(false);
              } else {
                setIsEmpty(true);
                setValue(0);
              }
              debouncedQuantity();
            }}
            onBlur={handleOnBlur}
          />
          <button
            data-testid="plus-button"
            className={`${styles.addItemValueButton} ${styles.plus}`}
            onClick={handlePositive}
          >
            +
          </button>
        </div>
      )}
    </div>
  ) : (
    <div
      style={{ width: width }}
      className={`${styles.productCardCtaContainer} ${styles.loaderBox}`}
    >
      <CircularProgress
        style={{ color: "#ec187b", width: "28px", height: "28px" }}
      />
    </div>
  );
};

export default ProductCardCta;
