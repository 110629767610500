import { createSlice } from "@reduxjs/toolkit";

const activeOutletSlice = createSlice({
  name: "activeOutlet",
  initialState: {},
  reducers: {
    setActiveOutlet: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setActiveOutlet } = activeOutletSlice.actions;

export default activeOutletSlice.reducer;