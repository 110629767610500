import React from "react";
import styles from "./selectAddress.module.scss";
import RadioButton from "../../../../../../common/components/radio/RadioButton";
import editIcon from "../../../../../../assets/images/editIcon.svg";
import deleteIcon from "../../../../../../assets/images/deleteIcon.svg";

type SelectAddressProps = {
  addressDetails: any;
  handleMouseOver: (id: string) => void;
  handleMouseLeave: () => void;
  isHoverd: boolean;
  handleSelect: (id: string) => void;
  selected: string;
};
const SelectAddress = ({
  addressDetails,
  handleMouseOver,
  handleMouseLeave,
  handleSelect,
  isHoverd,
  selected,
}: SelectAddressProps) => {
  return (
    <div
      className={`${styles.selectAddressWrapper} ${
        isHoverd && styles.addressHovered
      } ${selected === addressDetails.id && styles.addressSelected}`}
      onMouseEnter={() => {
        handleMouseOver(addressDetails.id);
      }}
      onClick={() => {
        handleSelect(addressDetails.id);
      }}
      onMouseLeave={handleMouseLeave}
    >
      <RadioButton
        checked={selected === addressDetails.id}
        handleChange={(e) => {
        }}
        value={addressDetails.id}
      />

      <div>
        <p className={styles.addressUserName}>
          Ahmed Ali{" "}
          <span
            className={`${styles.badge} ${
              addressDetails.is_default && styles.defaultBadge
            }`}
          >
            {addressDetails.is_default ? "Default" : "Other"}
          </span>
        </p>

        <p className={styles.addressText}>
          Al Baraha, Near Amayra Heights, Jumerah, Dubai, UAE
        </p>
        <p className={styles.addressText}>Contact: + 971 23 388 0987</p>
      </div>
      {isHoverd && (
        <div className={styles.addressActions}>
          <button className={styles.addressActionBtn}>
            <img src={editIcon} alt="edit icon" />
            <div>Edit</div>
          </button>
          <button className={styles.addressActionBtn}>
            <img src={deleteIcon} alt="delete icon" />
            <div>Delete</div>
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectAddress;
