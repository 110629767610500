import axios from "axios";
import { ICountry } from "../models/IAddIndividualProductForm";
import { useEffect, useState } from "react";

const useFetchCountries = () => {
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}store/country`
        );

        if (response) {
          const result = response?.data?.data?.countries;
          const updatedCountriesList = result?.map((country: ICountry) => ({
            ...country,
            value: country?.display_name,
            name: country?.display_name,
          }));
          setCountriesList(updatedCountriesList);
        } else {
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return {
    countriesList,
    loading,
  };
};

export default useFetchCountries;
