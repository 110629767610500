import { createSlice } from "@reduxjs/toolkit";

type StatePropType = {
  catalogue: number;
  orders: number;
  products_approval: number;
  buyer: number;
  supplier: number;
  manage_orders: number;
  users_list: number;
  admin_manage_catalogue: number;
  offline_suppliers: number;
  supplier_manage_catalogue: number;
  admin_special_pricing: number;
};

type ActionPropType = {
  payload: {
    type:
      | "catalogue"
      | "orders"
      | "products_approval"
      | "buyer"
      | "supplier"
      | "manage_orders"
      | "users_list"
      | "admin_manage_catalogue"
      | "offline_suppliers"
      | "supplier_manage_catalogue"
      | "admin_special_pricing";
    index: number;
  };
};

const selectedTabState: StatePropType = {
  catalogue: 0,
  orders: 0,
  products_approval: 0,
  buyer: 0,
  supplier: 0,
  manage_orders: 0,
  users_list: 0,
  admin_manage_catalogue: 0,
  offline_suppliers: 0,
  supplier_manage_catalogue: 0,
  admin_special_pricing:0,
};

export const selectedTabSlice = createSlice({
  name: "selectedTab",
  initialState: selectedTabState,
  reducers: {
    setTab(state: StatePropType, action: ActionPropType): void {
      state[action.payload.type] = action.payload.index;
    },
  },
});

export const { setTab } = selectedTabSlice.actions;
export default selectedTabSlice.reducer;
