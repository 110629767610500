import { createSlice } from "@reduxjs/toolkit";
import fruit from "../assets/images/fruit.svg";
import IND from "../assets/images/Flag.svg";
const initialState = {
  cardData: [
    {
      id: 0,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 1,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 2,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 3,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 4,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 5,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 6,
      icon: fruit,
      productName: "Mango",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 7,
      icon: fruit,
      productName: "Pomegranate",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 8,
      icon: fruit,
      productName: "Banana",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
    {
      id: 9,
      icon: fruit,
      productName: "Apple",
      productStatus: "Active",
      productId: "MNO0006",
      productQuantity: "Carton",
      brandName: "Fresho",
      countryFlag: IND,
      countryOrigin: "India",
      productSubCategory: "Fruits",
      productCategory: "Fruits & Vegetables",
      productCardState: "In review",
    },
  ],
};
const adminCatalogueListSlice = createSlice({
  name: "adminCatalogueList",
  initialState: initialState,

  reducers: {
    updateCatalogue: (state: any, action) => {
      const { id, newData } = action.payload;

      const catalogueIndex = state.cardData.findIndex(
        (buyer: any) => buyer.id === id
      );

      if (catalogueIndex !== -1) {
        state.cardData = state.cardData.map((buyer: any, index: number) => {
          if (index === catalogueIndex) {
            return {
              ...buyer,
              ...newData,
              emirates: { ...buyer.emirates, ...newData.emirates },
              country: { ...buyer.country, ...newData.country },
            };
          }
          return buyer;
        });
      } else {
        state.cardData.push({
          id: id ? id : Math.floor(Math.random() * 100),
          ...newData,
          emirates: { ...newData.emirates },
          country: { ...newData.country },
        });
      }
    },
  },
});

export const { updateCatalogue } = adminCatalogueListSlice.actions;

export default adminCatalogueListSlice.reducer;
