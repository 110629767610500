import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import InputField from "../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import styles from "./AdminLevelFormStyles.module.scss";
import useFetchCountries from "../../../services/useCountry";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../services/useAxios";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { setToastStatusApproval } from "../../../reducerSlices/toastStatusSlice";
import { useDispatch } from "react-redux";

function AdminLevelForm() {
  const { countriesList } = useFetchCountries();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [mobileNumberProductCode, setMobileNumberProductCode] = useState<any>();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [editForm, setEditForm] = useState(false);
  const { adminId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const schema = yup
    .object({
      firstName: yup.string().required("Enter first name"),
      lastName: yup.string().required("Enter last name"),
      emailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter valid email"),
      address: yup.string().required("Enter address"),
      country: yup.object().required("Select country"),
      emirates: yup.object().required("Select emirates"),
      password: adminId
        ? yup.string().optional()
        : yup
            .string()
            .required("Enter password")
            .test("password", "Password must meet the criteria", (value) => {
              return passwordRegex.test(value || "");
            }),
      confirmPassword: adminId
        ? yup.string().optional()
        : yup
            .string()
            .required("Enter confirm password")
            .oneOf([yup.ref("password"), ""], "Passwords must match"),
      phNo: yup
        .number()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      countryName: yup.object().required("Select country"),
      area: yup.string().required("Enter area"),
    })
    .required();
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    // trigger,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const values = getValues();

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      // trigger(name)
      isSubmitted && trigger(name)
    },
    [setValue, isSubmitted]
  );

  const getData = useAxios({
    axiosParams: {
      url: `admin/user/${adminId}`,
    },
    method: "GET",
  });

  const updateAdminData = useAxios({
    axiosParams: {
      url: `admin/user/${adminId}`,
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        phone_number: `${(values?.countryName as any)?.dial_codes}#${
          values?.phNo
        }`,
        saved_addresses: [
          {
            country: {
              iso_2: selectedCountry?.iso_2,
            },
            address: values?.address,
            emirate: {
              id: selectedEmirate?.id,
            },
            area: values?.area,
          },
        ],
      },
    },
    method: "PUT",
  });

  const [currentAdminData, setCurrentAdminData] = useState<any>({});
  useEffect(() => {
    const getFlag: any =
      currentAdminData &&
      countriesList &&
      countriesList.length > 0 &&
      countriesList.filter(
        (country) =>
          country?.dial_codes ===
          (currentAdminData?.phone_number?.split("#")[0] as any)
      )[0];
    if (getFlag) {
      setMobileNumberProductCode({
        dial_codes: currentAdminData?.phone_number?.split("#")[0],
        flag: getFlag && getFlag?.flag,
      });
    }
  }, [countriesList, currentAdminData]);
  const getAdminDetails = async () => {
    if (adminId) {
      const response: any = await getData.fetchData();
      if (response.status === "SUCCESS") {
        const adminData = response?.data;
        setCurrentAdminData(adminData);
        setValue("firstName", adminData?.first_name);
        setValue("lastName", adminData?.last_name);
        setValue("emailID", adminData?.email);
        setValue("address", adminData?.saved_addresses[0]?.address);
        setValue("area", adminData?.saved_addresses[0]?.area);
        setValue(
          "countryName",
          adminData?.saved_addresses[0]?.phone_number?.split("#")[0]
        );
        setValue("country", adminData?.saved_addresses[0]?.country?.iso_2);
        setValue("emirates", adminData?.saved_addresses[0]?.emirate?.id);
        setValue("phNo", adminData?.phone_number?.split("#")[1]);
      }
    }
  };

  useEffect(() => {
    if (adminId) {
      getAdminDetails();
    }
  }, [adminId]);

  useEffect(() => {
    if (adminId) {
      let country = null;
      if (getValues()?.country) {
        country = countriesList?.find(
          (ele: any) => ele?.iso_2 === getValues()?.country
        );
        setSelectedCountry(country);
      }
      if (adminId && country && getValues()?.emirates) {
        setSelectedEmirate(
          country?.emirates?.find(
            (ele: any) => ele?.id === getValues()?.emirates
          )
        );
      }
    }
  }, [adminId, countriesList, currentAdminData, getValues]);

  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates = selectedOption?.emirates.map((emirate: ICountry) => ({
      ...emirate,
      value: emirate?.name,
    }));
    setEmiratesList(emirates);
  };
  const postAdminData = useAxios({
    axiosParams: {
      url: "admin/user/create",
      data: {
        first_name: values && values?.firstName,
        last_name: values && values?.lastName,
        email: values && values?.emailID,
        password: values && values?.password,
        admin_roles: [
          {
            role_key: "admin",
          },
        ],
        phone_number: `${(values?.countryName as any)?.dial_codes}#${
          values?.phNo
        }`,
        saved_addresses: [
          {
            country: {
              iso_2: selectedCountry?.iso_2,
            },
            address: values && values?.address,
            emirate: {
              id: selectedEmirate?.id,
            },
            area: values && values?.area,
          },
        ],
      },
    },
    method: "POST",
  });

  useEffect(() => {
    if (updateAdminData?.error) {
      setToastData({
        message: updateAdminData?.error?.message as any,
        status: "Failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }

    if (postAdminData?.error) {
      setToastData({
        message: postAdminData.error.message as any,
        status: "Failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }, [updateAdminData?.error, postAdminData.error]);

  const updateAdminApiCall = async () => {
    const response: any = await updateAdminData.fetchData();
    if (response.status === "SUCCESS") {
      dispatch(
        setToastStatusApproval({
          message: "Admin has been updated successfully",
          status: "created",
          type: "create",
        } as any)
      );
      return response;
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const submit = async (data: any) => {
    if (data) {
      if (!adminId) {
        const response: any = await postAdminData.fetchData();
        if (response && response?.status === "SUCCESS") {
          console.log(response);
          dispatch(
            setToastStatusApproval({
              message: "Admin has been created successfully",
              status: "edited",
              type: "edit",
            } as any)
          );
          reset();
          navigate(routeConfig.adminAddBuyer);
        }
      } else {
        const response: any = await updateAdminApiCall();
        if (response && response?.status === "SUCCESS") {
          navigate(routeConfig.adminAddBuyer);
        }
      }
    }
  };

  return (
    <div className={styles.businessSetupForm}>
      <div className={styles.right}>
        <div className={styles.top}>
          <div className={styles.greetings}>
            <div className={styles.user}>
              {adminId
                ? "Edit Admin"
                : constants.adminLevelFormLabels.welcomenMsg}
            </div>
          </div>
          <div className={styles.actionsOnUser}>
            {adminId && !editForm && (
              <button
                className={styles.submit}
                type="button"
                onClick={() => setEditForm(!editForm)}
              >
                Edit Form
              </button>
            )}
          </div>
        </div>
        <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
          <div className={styles.form}>
            <div className={styles.businessSetup}>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("firstName")}
                    maxWidth="100%"
                    minWidth="250px"
                    disabled={adminId && !editForm}
                    id="firstName"
                    label={constants.adminLevelFormLabels.firstName}
                    placeholder="Enter first name"
                    onChange={onChangeField("firstName")}
                    register={register("firstName")}
                    errorMessage={errors.firstName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    value={watch("lastName")}
                    minWidth="250px"
                    id="lastName"
                    disabled={adminId && !editForm}
                    label={constants.adminLevelFormLabels.lastName}
                    placeholder="Enter last name"
                    onChange={onChangeField("lastName")}
                    register={register("lastName")}
                    errorMessage={errors.lastName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="emailID"
                    disabled={adminId}
                    label="Email ID"
                    placeholder="Enter email here"
                    onChange={onChangeField("emailID")}
                    register={register("emailID")}
                    errorMessage={errors.emailID?.message}
                    value={watch("emailID")}
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      mobileNumberProductCode
                        ? mobileNumberProductCode
                        : countriesList &&
                          countriesList.length > 0 &&
                          countriesList.filter(
                            (country) => country?.dial_codes === ("+971" as any)
                          )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    value={watch("phNo")}
                    label="Phone number"
                    id={"phnNo"}
                    options={countriesList}
                    editForm={!editForm && adminId ? true : false}
                    registerMobileNumber={register("phNo")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      onChangeField("countryName")(selectedOption);
                      setMobileNumberProductCode(selectedOption);
                    }}
                    errorMessage={
                      errors.phNo?.message && errors.phNo?.message.length > 60
                        ? `${errors.phNo?.message.slice(0, 60)}...`
                        : errors.phNo?.message
                    }
                    mobileNumberField={true}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    value={watch("address")}
                    disabled={adminId && !editForm}
                    id="address"
                    label={constants.adminLevelFormLabels.address}
                    placeholder="Enter address"
                    onChange={onChangeField("address")}
                    register={register("address")}
                    errorMessage={errors.address?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    value={watch("area")}
                    id="area"
                    disabled={adminId && !editForm}
                    label={constants.adminLevelFormLabels.area}
                    placeholder="Enter area"
                    onChange={onChangeField("area")}
                    register={register("area")}
                    errorMessage={errors.area?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"country"}
                    label="Country"
                    editForm={!editForm && adminId ? true : false}
                    options={
                      countriesList &&
                      countriesList.filter(
                        (country: ICountry) => country?.iso_2 === "ae"
                      )
                    }
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("country")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("country")(selectedOption);
                      setSelectedCountry(selectedOption);
                      handleCountryClick(selectedOption);
                    }}
                    defaultLabel={selectedCountry?.name ?? "Select country"}
                    errorMessage={(errors.country as any)?.message}
                    selectedValue={selectedCountry}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"Emirate"}
                    label="Emirate"
                    editForm={!editForm && adminId ? true : false}
                    options={emiratesList}
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("emirates")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("emirates")(selectedOption);
                      setSelectedEmirate(selectedOption);
                    }}
                    defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                    errorMessage={(errors.emirates as any)?.message}
                    selectedValue={selectedEmirate}
                  />
                </div>

                {!adminId && (
                  <div className={styles.inputField}>
                    <InputField
                      type={"password"}
                      maxWidth="100%"
                      id="password"
                      disabled={adminId && !editForm}
                      label={constants.adminLevelFormLabels.password}
                      placeholder="Enter password"
                      onChange={onChangeField("password")}
                      register={register("password")}
                      errorMessage={errors.password?.message}
                      value={watch("password")}
                    />
                  </div>
                )}
                {!adminId && (
                  <div className={styles.inputField}>
                    <InputField
                      type={"password"}
                      maxWidth="100%"
                      id="password"
                      disabled={adminId && !editForm}
                      label={constants.adminLevelFormLabels.confirmPassword}
                      placeholder="Enter confirm password"
                      onChange={onChangeField("confirmPassword")}
                      register={register("confirmPassword")}
                      errorMessage={errors.confirmPassword?.message}
                      value={watch("confirmPassword")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <button
              className={styles.skip}
              onClick={() => navigate(routeConfig.adminAddBuyer)}
            >
              {constants.adminLevelFormLabels.cancel}
            </button>
            <button className={styles.submit} type="submit" onClick={() => {}}>
              {adminId ? "Update" : constants.adminLevelFormLabels.submit}
            </button>
          </div>
        </form>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AdminLevelForm;
