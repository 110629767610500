import { createSlice } from "@reduxjs/toolkit";
import { convertTimeStampToUserTimeZone } from "../utils/functions";

function formatBuyerPhoneNum(number: any): any {
  if (!number) {
    return "-";
  }

  const [countryCode, phoneNo = ""] = number.split("#");
  return `${
    countryCode.includes("+") ? countryCode : `+${countryCode}`
  }-${phoneNo}`;
}

const getMarkAsDeliverOfflineOrderPayload = (lineItems: any[],buyerId:string,actualSubtotal:number,amountPaid:number) => {
  const transformedLineItems = lineItems?.map((eachItem: any) => ({
    actual_amount: eachItem?.actual_amount,
    delivered_quantity: eachItem?.quantity,
    line_item_id: eachItem?.id,
    quantity: eachItem?.quantity,
  }));
  return {
    actual_subtotal:actualSubtotal,
    amount_paid:amountPaid,
    buyer_id:buyerId,
    line_items:transformedLineItems
  }
};

const transformKeysDetails = (item: any) => {
  return {
    icon: "",
    orderId: item?.id || "-",
    invoiceId: item?.invoice_id ?? "-",
    invoiceNo: item?.invoice_number,
    orderStatus: item?.status || "-",
    fulfillmentStatus: item?.fulfillment_status || "-",
    paidStatus:
      item?.payment_status === "not_paid"
        ? "Unpaid"
        : item?.payment_status || "-",
    gracePeriod: item?.grace_period || "-",
    orderType: item?.order_type || "-",
    cartId: item?.cart?.id || "-",
    buyerId: item?.buyer?.id || "-",
    supplierId: item?.supplier_id || "-",
    billingAddressId: item?.billing_address_id || "-",
    shippingAddressId: item?.shipping_address_id || "-",
    canceledAt: convertTimeStampToUserTimeZone(item?.canceled_at),
    metadata: item?.metadata || null,
    invoice: item?.invoice || null,
    orderTrackId: item?.order_track_id || null,
    rating: item?.rating || null,
    paymentOptionId: item?.payment_option_id || "-",
    paymentMode: item?.payment_option?.name,
    outletId: item?.outlet?.id || "-",
    wareHouseId: item?.ware_house_id || "-",
    notes: item?.notes || "-",
    deliveryCharge: item?.delivery_charge || 0,
    vatTax: item?.vat_tax || 0,
    subTotal: item?.sub_total || 0,
    total: item?.total || 0,
    actualAmount: item?.actual_amount || 0,
    actualSubtotal: item?.actual_subtotal || 0,
    actualVat: item?.actual_vat || 0,
    productCount: item?.product_count || 0,
    buyerName: item?.buyer?.business_name ?? "-",
    buyerPhoneNum: formatBuyerPhoneNum(item?.ware_house?.phone_number),
    buyerEmail: item?.buyer?.email_id ?? "-",
    totalItems: item?.items,
    buyerPOC: item?.outlet?.contact_name ?? "-",
    orderPO: item?.order_number?.replace("-", " "),
    deliveryAddresss: `${item?.outlet?.contact_name}, ${
      item?.billing_address?.area ?? "-"
    } , ${item?.billing_address?.address ?? "-"}, 
    ${item?.billing_address?.emirate?.name ?? "-"}, ${
      item?.billing_address?.country?.name ?? "-"
    }`,
    status: item?.order_tracking?.status ?? "-",
    buyerDetailsPlacedDate:
      item?.order_tracking?.placed_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.placed_date)
        : "-",
    placedDate:
      item?.order_tracking?.placed_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.placed_date)
        : "-",
    confirmedDate:
      item?.order_tracking?.confirmed_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.confirmed_date)
        : "-",
    packedDate:
      item?.order_tracking?.packed_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.packed_date)
        : "-",
    ontheWayDate:
      item?.order_tracking?.packed_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.packed_date)
        : "-", // check with backed team
    deliveredDate:
      item?.order_tracking?.delivered_date != null
        ? convertTimeStampToUserTimeZone(item?.order_tracking?.delivered_date)
        : "-",
    manufactureName: item?.ware_house?.contact_name ?? "-",
    soldBy: item?.supplier?.business_name ?? "-",
    supplierContactNo: item?.ware_house?.phone_number ?? "-",
    supplierEmail: item?.supplier?.email_id ?? "-",
    orderedItemsList: item?.items?.items?.map((each_item: any) => ({
      id: each_item?.id,
      productCode: each_item?.product_code ?? "-",
      productName: each_item?.title ?? "-",
      productBrand: each_item?.brand?.name ?? "-",
      orderItems:
        `${
          `${
            each_item?.delivered_quantity !== null
              ? each_item?.delivered_quantity
              : each_item?.quantity
          } x ${each_item?.metadata?.secondary_ordering_option_value}` ?? `-`
        } ${each_item?.metadata?.secondary_ordering_option_label}` ?? "-",
      itemAmount: each_item?.metadata?.is_special_price_applied
        ? `AED ${Number(each_item?.quantity * each_item?.unit_price)?.toFixed(
            2
          )}`
        : `AED ${Number(
            each_item?.quantity *
              ((each_item?.unit_price *
                (100 - (each_item?.metadata?.offer ?? 0))) /
                100)
          )?.toFixed(2)}` ?? "-",
      offer: each_item?.metadata?.offer
        ? Number(each_item?.metadata?.offer)
        : 0 ?? "-",
      updatedAmount:
        each_item?.actual_amount && each_item?.actual_amount > 0
          ? `AED ${Number(
              (each_item?.delivered_quantity !== null
                ? each_item?.delivered_quantity
                : each_item?.quantity) * each_item?.actual_amount
            )?.toFixed(2)}`
          : "-" ?? "-",
    })),
    miniMumOrderValue: item?.supplier?.min_order_value ?? "-",
    initialModifyOrdersData: item?.items?.items?.map((each_item: any) => ({
      id: each_item?.id,
      productCode: each_item?.product_code ?? "-",
      productName: each_item?.title ?? "-",
      productBrand: each_item?.brand?.name ?? "-",
      orderItems:
        `${
          `${
            each_item?.delivered_quantity !== null
              ? each_item?.delivered_quantity
              : each_item?.quantity
          } x ${each_item?.metadata?.secondary_ordering_option_value}` ?? `-`
        } ${each_item?.metadata?.secondary_ordering_option_label}` ?? "-",
      itemAmount: `AED ${Number(each_item?.unit_price)?.toFixed(2)} ?? "-"`,
      offer: each_item?.metadata?.offer
        ? Number(each_item?.metadata?.offer)
        : 0 ?? "-",
      updatedAmount:
        each_item?.actual_amount && each_item?.actual_amount > 0
          ? `AED ${Number(each_item?.actual_amount)?.toFixed(2)}`
          : "-" ?? "-",
      isSpecialPriceApplied: each_item?.metadata?.is_special_price_applied,
    })),
    markAsReceivedPayload:
      item?.order_type === "Offline"
        ? getMarkAsDeliverOfflineOrderPayload(
            item?.items?.items,
            item?.buyer?.id,
            item?.actual_subtotal,
            item?.actual_amount
          )
        : null,
  };
};

const adminManageOrderDetailsSlice = createSlice({
  name: "adminManageOrderDetails",
  initialState: [],
  reducers: {
    adminGetOrderDetailsById: (state: any, action: any) => {
      state = action.payload;
      const transformPayload: any = transformKeysDetails(action?.payload);
      return transformPayload;
    },
    updateAdminOrderStatus: (state: any, action: any) => {
      if (action?.payload) {
        return {
          ...state,
          ...action?.payload,
        };
      }
    },
    resetAdminOrderDetails: () => {
      return [];
    },
  },
});

export const {
  adminGetOrderDetailsById,
  updateAdminOrderStatus,
  resetAdminOrderDetails,
} = adminManageOrderDetailsSlice.actions;

export default adminManageOrderDetailsSlice.reducer;
