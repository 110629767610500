export const formatDate = (date: any) => {
    // const [day, month, year] = date?.split("/");
    // return `${year}-${month}-${day}`;
    const parsedDate = new Date(date);

    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');

    const outputDate = `${year}-${month}-${day}`;

    return outputDate
  };