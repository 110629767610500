/* eslint-disable array-callback-return */
import { ThemeProvider } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import sidenavBs from "./assets/images/bs-profile.svg";
import Header from "./common/components/header/Header";
import SideNav, { AdminSideNav } from "./common/components/sideNav/SideNav";
import { componentRoutes } from "./common/constants/componentRoutes";
import { routeConfig } from "./common/constants/routeConfig";
import { getPlaceHolder } from "./common/constants/searchStrings";
import { customTheme } from "./common/styles/customTheme";
import useToken from "./hooks/useToken";
import { IRouteComponents } from "./models/IRouteComponents";
import AccountVerification from "./pages/buyer/login/accountVerification/AccountVerification";
import { ForgotPassword } from "./pages/buyer/login/forgotPassword/ForgotPassword";
import ResetCompleted from "./pages/buyer/login/resetCompleted/ResetCompleted";
import { ResetLink } from "./pages/buyer/login/resetLink/ResetLink";
import { ResetPassword } from "./pages/buyer/login/resetPassword/ResetPassword";
import SignInForm from "./pages/buyer/login/signIn/signIn";
import SignUpForm from "./pages/buyer/login/signup/signUp";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./common/components/loader/Loader";
import { logoutUser } from "./reducerSlices/loginInSlice";
import PopupView from "./common/components/popupView/PopupView";
import SessionExpired from "./common/components/sessionExpired/SessionExpired";
import PrivacyPolicy from "./pages/buyer/privacyPolicy/PrivacyPolicy";
// import { setIsBusinessSkipped } from "./reducerSlices/headerSlice";
export const TokenContext: any = React.createContext({});
function App() {
  const { sessionToken, removeSessionToken, setSessionToken } = useToken();
  const [userType, setUserType] = useState("supplier");
  const [placeholderForSearch, setPlaceHolderForSearch] = useState("");
  const [roles, setRoles] = useState([]);
  const { loginUserDetails } = useSelector((state: any) => state);
  let loggedInUser = loginUserDetails?.user;
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const userData = localStorage.getItem("loggedInUser");
  if (userData != null) {
    loggedInUser = JSON.parse(userData);
  }
  const isBusinessSkippedFromStore = useSelector(
    (state: any) =>
      state.header.isBusinessSkipped == "true" ||
      localStorage.getItem("isBusinessSkipped") === "true"
  );

  const isBusinessSetupCompleted = useSelector(
    (state: any) =>
      state.header.isSetupCompleted == "true" ||
      localStorage.getItem("isSetupCompleted") === "true"
  );

  const goToMainDashboard = () => {
    if (userData) {
      const businessDetails =
        loggedInUser?.business_details && loggedInUser?.business_details?.[0];
      let value =
        isBusinessSkippedFromStore ||
        isBusinessSetupCompleted ||
        businessDetails?.business_status !== "pending";
      return value;
    }
    return true;
  };

  const [isAdmin, setIsAdmin] = useState(false);
  // const [isOnboarding, setIsOnboarding] = useState(false);

  const [filteredComponentRoutes, setFilteredComponentRoutes] =
    useState<IRouteComponents[]>(componentRoutes);
  const dispatch = useDispatch();

  // logout user from every tab if logged out from one tab

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (
        e.storageArea?.length === 0 &&
        !window.location.href.includes("login")
      ) {
        dispatch(logoutUser());
        window.location.href = "/login";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const updateRoutes = () => {
    // setIsOnboarding(false);
    if (loggedInUser) {
      setRoles(
        (loggedInUser && loggedInUser?.typeOfUser) ||
          loggedInUser?.typeOfUser ||
          []
      );
      if (loggedInUser?.typeOfUser) {
        if (loggedInUser?.typeOfUser.includes("admin")) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        //restricting the component routes based on the logged user except share-product, it is allowed for both supplier and admin
        const filteredComponentRoutes = componentRoutes.filter((eachRoute) => {
          const routePath = eachRoute.path;
          const typeOfUser = loggedInUser?.typeOfUser;
          for (const user of typeOfUser) {
            if (
              routePath.includes(`/${user}/`) ||
              routePath.includes("/share-product")
            ) {
              return true;
            }
          }
        });
        setFilteredComponentRoutes(filteredComponentRoutes);
      }
    }
    setPlaceHolderForSearch(
      getPlaceHolder(window.location.pathname.replace("wm/", ""))
    );
  };

  useEffect(() => {
    updateRoutes();
  }, [window.location.pathname]);

  useEffect(() => {
    setIsDataUpdated(true);
    updateRoutes();
  }, [loggedInUser && !isDataUpdated]);
  useEffect(() => {
    setUserType(loggedInUser?.typeOfUser[0]);
  }, [loggedInUser]);
  const providerValue = React.useMemo(
    () => ({ removeSessionToken }),
    [removeSessionToken]
  );
  const isSessionExpired = localStorage.getItem("isSessionExpired");
  const [isExpired, setIsExpired] = useState<any>("false");
  useEffect(() => {
    if (isSessionExpired === "true") setIsExpired(isSessionExpired);
    else {
      setIsExpired("false");
    }
  }, [isSessionExpired]);
  if (!sessionToken)
    return (
      <>
        <Loader />
        <BrowserRouter basename={process.env.REACT_APP_APP_BASE_PATH}>
          <Routes>
            <Route
              path={routeConfig.login}
              element={<SignInForm tokenCallBack={setSessionToken} />}
            />
            <Route
              path={routeConfig.privacyPolicy}
              element={<PrivacyPolicy />}
            />
            <Route
              path={routeConfig.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={routeConfig.resetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={routeConfig.accountVerification}
              element={<AccountVerification />}
            />
            <Route path={routeConfig.resetLink} element={<ResetLink />} />
            <Route
              path={routeConfig.resetCompleted}
              element={<ResetCompleted />}
            />
            <Route path={routeConfig.signup} element={<SignUpForm />} />
            <Route
              path={routeConfig.resendVerification}
              element={<ForgotPassword />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  const path = window.location.pathname;

  return (
    <div>
      <div className="App">
        <ThemeProvider theme={customTheme}>
          <TokenContext.Provider value={providerValue}>
            <BrowserRouter basename={process.env.REACT_APP_APP_BASE_PATH}>
              <div>
                <div className="wrapper-flex-body">
                  {!goToMainDashboard() ? (
                    <div className="buisness-setup-sidenav">
                      <img src={sidenavBs} alt="sidenav-logo" />
                    </div>
                  ) : isAdmin ? (
                    <AdminSideNav />
                  ) : (
                    <SideNav
                      userType={userType}
                      setUserType={setUserType}
                      onRouteChange={(value: string) =>
                        setPlaceHolderForSearch(getPlaceHolder(value))
                      }
                      roles={roles}
                    />
                  )}
                  <div className="wrapper-header-body">
                    {!isAdmin &&
                      (path.includes("setup-business") ? (
                        // || path.includes("report")
                        <></>
                      ) : (
                        <Header
                          userType={userType}
                          placeholder={placeholderForSearch}
                        />
                      ))}
                    <div
                      className={`${
                        !isAdmin &&
                        !path.includes("onboarding") &&
                        !path.includes("special-pricing")
                          ? "full-height-wrap"
                          : "wrap"
                      }`}
                    >
                      <Routes>
                        {filteredComponentRoutes &&
                          filteredComponentRoutes.map(
                            (eachRoute: IRouteComponents) => (
                              <Route
                                path={eachRoute.path}
                                element={eachRoute.element}
                                key={eachRoute.path.split("/").join("-")}
                              />
                            )
                          )}
                        <Route
                          path="*"
                          element={
                            <Navigate
                              to={
                                isAdmin
                                  ? routeConfig.adminAddBuyer
                                  : loggedInUser?.typeOfUser[0] === "supplier"
                                  ? goToMainDashboard()
                                    ? routeConfig.supplierDashboard
                                    : routeConfig.businessSetupForm
                                  : goToMainDashboard()
                                  ? routeConfig.dashboard
                                  : routeConfig.buyerBusinessSetupForm
                              }
                            />
                          }
                        />
                      </Routes>
                    </div>
                  </div>
                </div>
                {isExpired === "true" && (
                  <PopupView
                    childComponent={SessionExpired}
                    height={100}
                    width={400}
                    heading={"Session Expired"}
                    handlePopup={() => setIsExpired("false")}
                    hideHeader={true}
                  />
                )}
              </div>
            </BrowserRouter>
            <Loader />
          </TokenContext.Provider>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;
