import { useState } from "react";
import { ISupllierProductCard } from "../../../models/ISupplierProductCard";
import styles from "./SupplierProductCard.module.scss";
import dashedArrow from "../../../assets/images/dashedArrow.svg";
function SupplierProductCard(props: ISupllierProductCard) {
  const [icon, setIcon] = useState(props.icon);
  const [arrow, setArrow] = useState(false);
  function changeIconOnMouseEnter() {
    setIcon(props.hoverIcon);
  }
  function changeIconOnMouseLeave() {
    setIcon(props.icon);
    setArrow(false);
  }
  function setArrowTrue() {
    setArrow(true);
  }
  function setArrowFalse() {
    setArrow(false);
  }
  return (
    <div className={styles.card} style={props.customStyles&&{maxWidth:props.customStyles.maxWidth}}>
      <div
        className={`${styles.SupplierProductCard} ${
          props.isBulk ? styles.bulkCard : ""
        }`}
        onMouseLeave={changeIconOnMouseLeave}
        onMouseEnter={changeIconOnMouseEnter}
        onClick={!props.isBulk ? props.upload : () => {}}
      >
        <div
          className={styles.top}
          onClick={props.isBulk ? props.upload : () => {}}
        >
          <div className={styles.icon}>
            <img src={icon} alt="icon"></img>
          </div>
          <div
            className={`${styles.heading} ${
              props.isBulk ? styles.bulkHeading : ""
            }`}
          >
            {props.heading}
          </div>
        </div>
        {!props.isBulk && (
          <div className={styles.bottom}>
            <div className={styles.horizontalRule}></div>
            <div className={styles.content}>{props.content}</div>
          </div>
        )}
      </div>
      {props.isBulk && (
        <div
          className={styles.cardFooter}
          onMouseLeave={setArrowFalse}
          onMouseEnter={setArrowTrue}
          onClick={props.download}
        >
          <div>
            <span className={styles.download}>Download</span>
            {props.bulkContent}
          </div>
          {arrow && (
            <div className={styles.arrow}>
              <img src={dashedArrow} alt="arrow"></img>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SupplierProductCard;
