import React from "react";
import emptyCartIcon from "../../images/emptyCartIcon.svg";
import styles from "./emptyCartStyles.module.scss";
import Button from "../../../../../common/components/button/Button";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../../common/constants/routeConfig";
import { constants } from "../../../../../common/constants/constants";
const EmptyCart = () => {
    const navigate = useNavigate()
  return (
    <div className={styles.emptyCartWrapper}>
      <img src={emptyCartIcon} alt="Empty Cart" />
      <h2 className={styles.emptyCartHeading}> {constants.myCart.emptyCartHeader}</h2>
      <p className={styles.emptyCartText} >
        {constants.myCart.emptyCartInfoText}
      </p>
      <div className={styles.marketPlaceBtn}>
      <Button label="Go to marketplace" handleClick={() => {
        navigate(routeConfig.marketPlace)
      }} />
      </div>
    
    </div>
  );
};

export default EmptyCart;
