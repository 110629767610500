import styles from "../../BulkUpload.module.scss";
import uploadAction from "../../../../../assets/images/uploadaction.svg";
import iconError from "../../../../../assets/images/icon-error.svg";
import whiteEye from "../../../../../assets/images/whiteEye.svg";
import whiteTrash from "../../../../../assets/images/whiteTrash.svg";
import errorToast from "../../../../../assets/images/errorToast.svg";
import successToast from "../../../../../assets/images/successToast.svg";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import Dropdown from "../../../dropdown/Dropdown";
import { ToastNotification } from "../../../toastNotification/ToastNotification";
import { constants } from "../../../../constants/constants";
import { errorRowUtils } from "../../utils";
import cancelAction from "../../../../../assets/images/chevron-left-white.svg";
import AttributeCell from "../../components/AttributeCell";
import { isCurrUserAdmin } from "../../../../../utils/clientStorageUtils";
import EmptyProductOption from "../../components/EmptyProductOption";
import { getTextWithoutOverflow } from "../../../../../utils/functions";
import { CircularProgress } from "@mui/material";
import { LightTooltip } from "../../components/LightTooltip";
import { isAttributePresentInCurrentCategory } from "../../utils";
import UploadImg from "./UploadImg";

const TableRow = ({
  customer,
  i,
  index,
  changeEditStatus,
  removeImage,
  variantsData,
  categories,
  countriesList,
  measuringUnits,
}: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  // const [modalImageSrc, setModalImageSrc] = useState("");
  // const [currentImageIndex, setCurrentImageIndex] = useState(null);
  // const [currentImageIdx, setCurrentImageIdx] = useState(null);
  const [defImageSrc, setDefImageSrc] = useState("");
  const [defFileName, setDefFileName] = useState("");
  // const [imgfileName, setImgFileName] = useState("");
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const optionsErrorIndex = customer.duplicateProductOptionsIndex;
  // const handleOpenModal = (
  //   imageSrc: string,
  //   index: any,
  //   idx: any,
  //   fileName: string
  // ) => {
  //   setModalImageSrc(imageSrc);
  //   setCurrentImageIndex(index);
  //   setCurrentImageIdx(idx);
  //   setModalOpen(true);
  //   setImgFileName(fileName);
  // };
  //Open Modal for default Image
  const defImageModalOpen = (imageSrc: string, fileName: string) => {
    setDefImageSrc(imageSrc);
    setDefFileName(fileName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (rowIndex: number, imageIndex: number) => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const throwError = () => {
    setToastData({ message: `Maximum 4 images only`, status: "Fail" });
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 3000);
  };
  // console.log(categories, '___CAAAA');
  customer.category["subcategories"] =
    customer?.category?.subcategories ||
    customer?.category?.sub_categories ||
    (categories?.length &&
      categories?.find((item: any) => item.name === customer?.category?.value)
        ?.subcategories);
  customer.sub_category["subSubCategories"] =
    customer?.category?.subcategories?.length &&
    customer?.category?.subcategories?.find(
      (item: any) => item.name === customer?.sub_category?.value
    )?.sub_sub_categories;

  return (
    <>
      {customer && customer?.media ? (
        <tr className={errorRowUtils(customer, index) ? styles.errorShown : ""}>
          {/* Product Name Start  */}
          <td
            style={{
              backgroundColor: errorRowUtils(customer, index) ? "#f9e0e0" : "",
            }}
          >
            <div className={styles.column}>
              {!customer?.title?.editMode && !customer?.title?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => changeEditStatus("title", i, true)}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Product variant title is required"
                  >
                    <LightTooltip
                      title="Product variant title is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.title?.editMode ? (
                    <span
                      onDoubleClick={() => changeEditStatus("title", i, true)}
                    >
                      {/* <span onClick={() => changeEditStatus("title", i, true)}> */}
                      {customer?.title?.value?.length
                        ? customer?.title?.value
                        : ""}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <input
                        type="text"
                        name=""
                        id="supplier"
                        value={customer?.title?.value}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) =>
                          changeEditStatus("title", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus("title", i, e.target.value, false)
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("title", i, false);
                          }
                        }}
                        autoFocus={customer?.title?.editMode}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Product Name Ends  */}

          {/* Watermelon product code starts*/}
          {!isCurrUserAdmin() && (
            <td>
              <div className={styles.column}>
                {!customer?.master_watermelon_product_code?.editMode &&
                !customer?.master_watermelon_product_code?.value ? (
                  <div
                    className={styles.editableField}
                    onDoubleClick={() =>
                      changeEditStatus(
                        "master_watermelon_product_code",
                        i,
                        true
                      )
                    }
                  >
                    <span
                      className={styles.errorTooltip}
                      // title="Watermelon product code is required"
                    >
                      -
                      {/* <LightTooltip
                        title="Watermelon product code is required"
                        placement="top"
                        arrow
                      >
                        <img src={iconError} alt="Error" />
                      </LightTooltip> */}
                    </span>
                  </div>
                ) : (
                  <div className={styles.column}>
                    {!customer?.master_watermelon_product_code?.editMode ? (
                      <span
                        onDoubleClick={() =>
                          changeEditStatus(
                            "master_watermelon_product_code",
                            i,
                            true
                          )
                        }
                      >
                        {customer?.master_watermelon_product_code?.value
                          ? customer?.master_watermelon_product_code?.value
                          : ""}
                      </span>
                    ) : (
                      <div className={styles.editableField}>
                        <input
                          type="text"
                          name=""
                          id="supplier"
                          value={
                            customer?.master_watermelon_product_code?.value
                              ?.length
                              ? customer?.master_watermelon_product_code?.value
                              : ""
                          }
                          onChange={(e) =>
                            changeEditStatus(
                              "master_watermelon_product_code",
                              i,
                              e.target.value
                            )
                          }
                          onBlur={(e) =>
                            changeEditStatus(
                              "master_watermelon_product_code",
                              i,
                              e.target.value,
                              false
                            )
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              changeEditStatus(
                                "master_watermelon_product_code",
                                i,
                                false
                              );
                            }
                          }}
                          autoFocus={
                            customer?.master_watermelon_product_code?.editMode
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </td>
          )}

          {/* Watermelon product code ends*/}

          {/* Description Starts */}
          <td>
            <div className={styles.column}>
              {!customer?.description?.editMode &&
              !customer?.description?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => changeEditStatus("description", i, true)}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Description is required"
                  >
                    <LightTooltip
                      title="Description is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.description?.editMode ? (
                    <span
                      onDoubleClick={() =>
                        changeEditStatus("description", i, true)
                      }
                    >
                      {getTextWithoutOverflow({
                        text: customer?.description?.value,
                        length: 20,
                      })}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <input
                        type="text"
                        name=""
                        id="supplier"
                        value={
                          customer?.description?.value?.length
                            ? customer?.description?.value
                            : ""
                        }
                        onChange={(e) =>
                          changeEditStatus("description", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus(
                            "description",
                            i,
                            e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("description", i, false);
                          }
                        }}
                        autoFocus={customer?.description?.editMode}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Description Ends */}

          {/* Product Brand Starts  */}
          <td>
            <div className={styles.column}>
              {!customer?.brand?.editMode && !customer?.brand?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => changeEditStatus("brand", i, true)}
                >
                  <span
                    className={styles.errorTooltip}
                    // title="Error message for Product Brand"
                  >
                    -
                    {/* <LightTooltip title="Add" placement="top" arrow>
                        <img src={iconError} alt="Error" />
                      </LightTooltip> */}
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.brand?.editMode ? (
                    <span
                      onDoubleClick={() => changeEditStatus("brand", i, true)}
                    >
                      {getTextWithoutOverflow({
                        text: customer?.brand?.value,
                        length: 20,
                      })}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <input
                        type="text"
                        name=""
                        id="supplier"
                        value={
                          customer?.brand?.value?.length
                            ? customer?.brand?.value
                            : ""
                        }
                        onChange={(e) =>
                          changeEditStatus("brand", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus("brand", i, e.target.value, false)
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("brand", i, false);
                          }
                        }}
                        autoFocus={customer?.brand?.editMode}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Product Brand Ends  */}
          {/* Certificates Starts  */}
          <td>
            <div className={styles.column}>
              {!Array.isArray(customer?.certifications?.value) ? (
                !customer?.certifications?.editMode &&
                !customer?.certifications?.value ? (
                  <div
                    className={styles.editableField}
                    onDoubleClick={() =>
                      changeEditStatus("certifications", i, true)
                    }
                  >
                    <span
                      className={styles.errorTooltip}
                      // title="Error message for certifications"
                    >
                      -
                      {/* <LightTooltip title="Add" placement="top" arrow>
                          <img src={iconError} alt="Error" />
                        </LightTooltip> */}
                    </span>
                  </div>
                ) : (
                  <div className={styles.column}>
                    {!customer?.certifications?.editMode ? (
                      <span
                        onDoubleClick={() =>
                          changeEditStatus("certifications", i, true)
                        }
                      >
                        {getTextWithoutOverflow({
                          text: customer?.certifications?.value,
                          length: 20,
                        })}
                      </span>
                    ) : (
                      <div className={styles.editableField}>
                        <input
                          type="text"
                          name=""
                          id="supplier"
                          value={
                            customer?.certifications?.value?.length > 15
                              ? `${customer?.certifications?.value}`
                              : customer?.certifications?.value || ""
                          }
                          onChange={(e) =>
                            changeEditStatus(
                              "certifications",
                              i,
                              e.target.value
                            )
                          }
                          onBlur={(e) =>
                            changeEditStatus(
                              "certifications",
                              i,
                              e.target.value,
                              false
                            )
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              changeEditStatus("certifications", i, false);
                            }
                          }}
                          autoFocus={customer?.certifications?.editMode}
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div>
                  {customer?.certifications?.editMode ? (
                    <input
                      type="text"
                      name=""
                      id="supplier"
                      value={customer?.certifications?.value.join(", ") || ""}
                      onChange={(e) =>
                        changeEditStatus("certifications", i, e.target.value)
                      }
                      onBlur={(e) =>
                        changeEditStatus(
                          "certifications",
                          i,
                          e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          changeEditStatus("certifications", i, false);
                        }
                      }}
                      autoFocus={customer?.certifications?.editMode}
                    />
                  ) : (
                    <span
                      onDoubleClick={() =>
                        changeEditStatus("certifications", i, true)
                      }
                    >
                      {customer?.certifications?.value.join(", ").length
                        ? `${customer?.certifications?.value.join(", ")}`
                        : ""}
                    </span>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Certificates Ends  */}
          {/* specifications Starts  */}
          <td>
            <div className={styles.column}>
              {!Array.isArray(customer?.specifications?.value) ? (
                !customer?.specifications?.editMode &&
                !customer?.specifications?.value ? (
                  <div
                    className={styles.editableField}
                    onDoubleClick={() =>
                      changeEditStatus("specifications", i, true)
                    }
                  >
                    <span
                      className={styles.errorTooltip}
                      // title="Error message for specifications"
                    >
                      -
                      {/* <LightTooltip title="Add" placement="top" arrow>
                          <img src={iconError} alt="Error" />
                        </LightTooltip> */}
                    </span>
                  </div>
                ) : (
                  <div className={styles.column}>
                    {!customer?.specifications?.editMode ? (
                      <span
                        onDoubleClick={() =>
                          changeEditStatus("specifications", i, true)
                        }
                      >
                        {getTextWithoutOverflow({
                          text: customer?.specifications?.value,
                          length: 20,
                        })}
                      </span>
                    ) : (
                      <div className={styles.editableField}>
                        <input
                          type="text"
                          name=""
                          id="supplier"
                          value={
                            customer?.specifications?.value?.length
                              ? customer?.specifications?.value
                              : ""
                          }
                          onChange={(e) =>
                            changeEditStatus(
                              "specifications",
                              i,
                              e.target.value
                            )
                          }
                          onBlur={(e) =>
                            changeEditStatus(
                              "specifications",
                              i,
                              e.target.value,
                              false
                            )
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              changeEditStatus("specifications", i, false);
                            }
                          }}
                          autoFocus={customer?.specifications?.editMode}
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div>
                  {customer?.specifications?.editMode ? (
                    <input
                      type="text"
                      name=""
                      id="supplier"
                      value={customer?.specifications?.value.join(", ") || ""}
                      onChange={(e) =>
                        changeEditStatus("specifications", i, e.target.value)
                      }
                      onBlur={(e) =>
                        changeEditStatus(
                          "specifications",
                          i,
                          e.target.value,
                          false
                        )
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          changeEditStatus("specifications", i, false);
                        }
                      }}
                      autoFocus={customer?.specifications?.editMode}
                    />
                  ) : (
                    <span
                      onDoubleClick={() =>
                        changeEditStatus("specifications", i, true)
                      }
                    >
                      {customer?.specifications?.value.join(", ")
                        ? customer?.specifications?.value.join(", ")
                        : ""}
                    </span>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* specifications Ends  */}
          {/* Tags Starts */}
          <td>
            <div className={styles.column}>
              {!Array.isArray(customer?.tags?.value) ? (
                !customer?.tags?.editMode && !customer?.tags?.value ? (
                  <div
                    className={styles.editableField}
                    onDoubleClick={() => changeEditStatus("tags", i, true)}
                  >
                    <span
                      className={styles.errorTooltip}
                      //removed the required tooltip for tags and replaced it with '-'
                      // title="Tags is required"
                    >
                      -
                      {/* <LightTooltip
                          title="Tags is required"
                          placement="top"
                          arrow
                        >
                          <img src={iconError} alt="Error" />
                        </LightTooltip> */}
                    </span>
                  </div>
                ) : (
                  <div className={styles.column}>
                    {!customer?.tags?.editMode ? (
                      <span
                        onDoubleClick={() => changeEditStatus("tags", i, true)}
                      >
                        {getTextWithoutOverflow({
                          text: customer?.tags?.value,
                          length: 20,
                        })}
                      </span>
                    ) : (
                      <div className={styles.editableField}>
                        <input
                          type="text"
                          name=""
                          id="supplier"
                          value={
                            customer?.tags?.value?.length
                              ? customer?.tags?.value
                              : ""
                          }
                          onChange={(e) =>
                            changeEditStatus("tags", i, e.target.value)
                          }
                          onBlur={(e) =>
                            changeEditStatus("tags", i, e.target.value, false)
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              changeEditStatus("tags", i, false);
                            }
                          }}
                          autoFocus={customer?.tags?.editMode}
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div>
                  {customer?.tags?.editMode ? (
                    <input
                      type="text"
                      name=""
                      id="supplier"
                      value={customer?.tags?.value.join(", ") || ""}
                      onChange={(e) =>
                        changeEditStatus("tags", i, e.target.value)
                      }
                      onBlur={(e) =>
                        changeEditStatus("tags", i, e.target.value, false)
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          changeEditStatus("tags", i, false);
                        }
                      }}
                      autoFocus={customer?.tags?.editMode}
                    />
                  ) : (
                    <span
                      onDoubleClick={() => changeEditStatus("tags", i, true)}
                    >
                      {customer?.tags?.value.join(", ").length
                        ? `${customer?.tags?.value.join(", ")}`
                        : customer?.tags?.value.join(", ")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Tags ends */}
          {/* Category Starts  */}
          <td>
            <div className={styles.column}>
              {!customer?.category?.editMode && !customer?.category?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => {
                    // changeEditStatus("category", i, "", true)
                  }}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Category name is required"
                  >
                    <LightTooltip
                      title="Category name is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={`${styles.column} ${styles.disabledText}`}>
                  {!customer?.category?.editMode ? (
                    <span
                      onDoubleClick={() => {
                        // changeEditStatus("category", i, true)
                      }}
                    >
                      {getTextWithoutOverflow({
                        text: customer?.category?.value,
                        length: 20,
                      })}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <Dropdown
                        autoFocus={customer?.category?.editMode}
                        selectedValue={
                          customer?.category?.value
                            ? customer?.category?.value
                            : ""
                        }
                        minWidth="200px"
                        overlayWidth="200px"
                        label={""}
                        customDropdownStyles={styles.customDropDown}
                        options={categories.length > 0 && categories}
                        defaultLabel={
                          customer?.category?.value
                            ? customer?.category?.value
                            : "Select product category..."
                        }
                        disabled
                        id="category"
                        onChange={(selectedOption: any) => {
                          changeEditStatus("category", i, selectedOption?.name);
                        }}
                        // errorMessage="ERROR FROM DROP DOWN"
                      ></Dropdown>
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Category Ends  */}
          {/* SubCategory Starts  */}
          <td>
            <div className={styles.column}>
              {!customer?.sub_category?.editMode &&
              !customer?.sub_category?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => {
                    // changeEditStatus("sub_category", i, true)
                  }}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Subcategory name is required"
                  >
                    <LightTooltip
                      title="Subcategory name is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={`${styles.column} ${styles.disabledText}`}>
                  {!customer?.sub_category?.editMode ? (
                    <span
                      onDoubleClick={() => {
                        // changeEditStatus("sub_category", i, true)
                      }}
                    >
                      {getTextWithoutOverflow({
                        text: customer?.sub_category?.value,
                        length: 20,
                      })}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <Dropdown
                        selectedValue={
                          customer?.category?.sub_category?.value
                            ? customer?.category?.sub_category?.value
                            : ""
                        }
                        minWidth="200px"
                        overlayWidth="200px"
                        customDropdownStyles={styles.customDropDown}
                        label={""}
                        options={customer?.category?.subcategories}
                        defaultLabel={
                          customer?.sub_category?.value
                            ? customer?.sub_category?.value
                            : "Select product subcategory..."
                        }
                        disabled
                        id="sub_category"
                        onChange={(selectedOption: any) => {
                          // console.log(selectedOption, "SELECTED");
                          changeEditStatus(
                            "sub_category",
                            i,
                            selectedOption?.name
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* SubCategory Ends  */}
          {/* SubSubCategory Starts  */}
          <td>
            <div className={styles.column}>
              {!customer?.sub_sub_category?.editMode &&
              !customer?.sub_sub_category?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => {
                    // changeEditStatus("sub_sub_category", i, true)
                  }}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Sub subcategory name is required"
                  >
                    <LightTooltip
                      title="Sub subcategory name is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={`${styles.column} ${styles.disabledText}`}>
                  {!customer?.sub_sub_category?.editMode ? (
                    <span
                      onDoubleClick={() => {
                        // changeEditStatus("sub_sub_category", i, true)
                      }}
                    >
                      {getTextWithoutOverflow({
                        text: customer?.sub_sub_category?.value,
                        length: 20,
                      })}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <Dropdown
                        selectedValue={
                          customer?.sub_sub_category?.value
                            ? customer?.sub_sub_category?.value
                            : ""
                        }
                        minWidth="200px"
                        overlayWidth="200px"
                        customDropdownStyles={styles.customDropDown}
                        label={""}
                        options={customer?.sub_category?.subSubCategories}
                        defaultLabel={
                          customer?.sub_sub_category?.value
                            ? customer?.sub_sub_category?.value
                            : "Select product sub subcategory"
                        }
                        id="sub_sub_category"
                        disabled
                        onChange={(selectedOption: any) => {
                          // console.log(selectedOption, "SELECTED");
                          changeEditStatus(
                            "sub_sub_category",
                            i,
                            selectedOption?.name,
                            true
                          );
                        }}
                        // errorMessage="ERROR FROM DROP DOWN"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* SubSubCategory Starts  */}

          {/* Country Origin Starts */}
          <td>
            <div className={styles.column}>
              {!customer?.country?.editMode && !customer?.country?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => changeEditStatus("country", i, true)}
                >
                  <span
                    className={styles.errorTooltip}
                    title="Country is required"
                  >
                    <LightTooltip
                      title="Country is required"
                      placement="top"
                      arrow
                    >
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.country?.editMode ? (
                    <span
                      onDoubleClick={() => changeEditStatus("country", i, true)}
                    >
                      {customer?.country?.value?.length
                        ? customer?.country?.value
                        : ""}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <Dropdown
                        selectedValue={
                          customer?.country?.value
                            ? customer?.country?.value
                            : ""
                        }
                        minWidth="200px"
                        label={""}
                        isCountryList={true}
                        isCountryOrigin={true}
                        options={countriesList}
                        customDropdownStyles={styles.customDropDown}
                        defaultLabel={
                          customer?.country?.value ?? "Select country of origin"
                        }
                        id="country"
                        onChange={(selectedOption: any) => {
                          // console.log(selectedOption.name, "SELECTED");
                          changeEditStatus("country", i, selectedOption.name);
                        }}
                        // errorMessage="ERROR FROM DROP DOWN"
                      />
                      {/* <select
                      value={customer?.country?.value}
                        onChange={(e) =>
                          changeEditStatus("country", i, e.target.value)
                        }
                      >
                        <option value="" disabled selected>Choose Value</option>
                        {
                          countriesList.map((item: any, index: number) => {
                            return <option key={index}>{item?.name}</option>
                          })
                        }
                      </select> */}
                      {/* <input
                        type="text"
                        name=""
                        id="supplier"
                        value={
                          customer?.country?.value?.length > 15
                            ? `${customer?.country?.value?.slice(0, 15)}...`
                            : customer?.country?.value || ""
                        }
                        onChange={(e) =>
                          changeEditStatus("country", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus("country", i, e.target.value, false)
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("country", i, false);
                          }
                        }}
                      /> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Country Origin Ends */}
          {/* Product Code Starts */}
          <td>
            <div className={styles.column}>
              {!customer?.supplier_product_code?.editMode &&
              !customer?.supplier_product_code?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() =>
                    changeEditStatus("supplier_product_code", i, true)
                  }
                >
                  <span
                    className={styles.errorTooltip}
                    // title="Supplier product Code is required"
                  >
                    -
                    {/* <LightTooltip
                        title="Supplier product Code is required"
                        placement="top"
                        arrow
                      >
                        <img src={iconError} alt="Error" />
                      </LightTooltip> */}
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.supplier_product_code?.editMode ? (
                    <span
                      onDoubleClick={() =>
                        changeEditStatus("supplier_product_code", i, true)
                      }
                    >
                      {customer?.supplier_product_code?.value?.length
                        ? `${customer?.supplier_product_code?.value}`
                        : customer?.supplier_product_code?.value || ""}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <input
                        type="text"
                        name=""
                        id="supplier"
                        value={
                          customer?.supplier_product_code?.value?.length
                            ? customer?.supplier_product_code?.value
                            : ""
                        }
                        onChange={(e) =>
                          changeEditStatus(
                            "supplier_product_code",
                            i,
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          changeEditStatus(
                            "supplier_product_code",
                            i,
                            e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("supplier_product_code", i, false);
                          }
                        }}
                        autoFocus={customer?.supplier_product_code?.editMode}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* Product Code Ends */}

          {/* HS Code Starts */}
          <td>
            <div className={styles.column}>
              {!customer?.hs_code?.editMode && !customer?.hs_code?.value ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() => changeEditStatus("hs_code", i, true)}
                >
                  <span
                    className={styles.errorTooltip}
                    // title="HS Code"
                  >
                    -
                    {/* <LightTooltip title="Add" placement="top" arrow>
                        <img src={iconError} alt="Error" />
                      </LightTooltip> */}
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.hs_code?.editMode ? (
                    <span
                      onDoubleClick={() => changeEditStatus("hs_code", i, true)}
                    >
                      {customer?.hs_code?.value ? customer?.hs_code?.value : ""}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <input
                        type="text"
                        name=""
                        id="supplier"
                        value={
                          customer?.hs_code?.value?.toString().length > 0
                            ? customer?.hs_code?.value
                            : ""
                        }
                        onChange={(e) =>
                          changeEditStatus("hs_code", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus("hs_code", i, e.target.value, false)
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("hs_code", i, false);
                          }
                        }}
                        autoFocus={customer?.hs_code?.editMode}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* HS Code Ends */}
          {/* isVat Starts */}
          <td>
            <div className={styles.column}>
              {!customer?.is_vat_included?.editMode &&
              customer?.is_vat_included?.value === "" ? (
                <div
                  className={styles.editableField}
                  onDoubleClick={() =>
                    changeEditStatus("is_vat_included", i, true)
                  }
                >
                  <span className={styles.errorTooltip} title="Vat is required">
                    <LightTooltip title="Vat is required" placement="top" arrow>
                      <img src={iconError} alt="Error" />
                    </LightTooltip>
                  </span>
                </div>
              ) : (
                <div className={styles.column}>
                  {!customer?.is_vat_included?.editMode ? (
                    <span
                      onDoubleClick={() =>
                        changeEditStatus("is_vat_included", i, true)
                      }
                    >
                      {customer?.is_vat_included &&
                        customer?.is_vat_included?.value?.toString()}
                    </span>
                  ) : (
                    <div className={styles.editableField}>
                      <Dropdown
                        selectedValue={
                          customer?.is_vat_included !== undefined
                            ? customer.is_vat_included.value.toString()
                            : ""
                        }
                        minWidth="200px"
                        label={""}
                        customDropdownStyles={styles.customDropDown}
                        options={[
                          { name: "true", value: "true" },
                          { name: "false", value: "false" },
                        ]}
                        defaultLabel={
                          customer?.is_vat_included?.value?.toString() ??
                          "Select is vat included"
                        }
                        id="is_vat_included"
                        onChange={(selectedOption: any) => {
                          changeEditStatus(
                            "is_vat_included",
                            i,
                            selectedOption.name
                          );
                        }}
                        // errorMessage="ERROR FROM DROP DOWN"
                      ></Dropdown>
                      {/*<select
                      value={customer?.is_vat_included !== undefined
                        ? customer.is_vat_included.value.toString()
                        : ""}
                      onChange={(e) =>
                        changeEditStatus("is_vat_included", i, e.target.value)
                      }
                      onBlur={(e) =>
                        changeEditStatus(
                          "is_vat_included",
                          i,
                          e.target.value,
                          false
                        )
                      }
                      >
                        <option value="" disabled selected>Choose Value</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select> */}
                      {/* <input
                        type="text"+
                        name=""
                        id="supplier"
                        value={
                          customer?.is_vat_included?.value?.length > 15
                            ? `${customer?.is_vat_included?.value?.slice(
                                0,
                                15
                              )}...`
                            : customer?.is_vat_included?.value || ""
                        }
                        onChange={(e) =>
                          changeEditStatus("is_vat_included", i, e.target.value)
                        }
                        onBlur={(e) =>
                          changeEditStatus(
                            "is_vat_included",
                            i,
                            e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            changeEditStatus("is_vat_included", i, false);
                          }
                        }}
                      /> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
          {/* isVat Ends */}
          {/* Default Image Starts  */}
          <td>
            <div className={styles.column}>
              <div className={styles.imageContainer}>
                {customer?.image_1?.value?.length && (
                  <span
                    className={`${styles.imageBox} ${
                      !customer.imgUrlLoaded && styles.pendingImgLoad
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(index, customer?.image_1?.value)
                    }
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    {!customer.imgUrlLoaded && (
                      <div className={styles.imgLoading}>
                        <CircularProgress size={20} color="primary" />
                      </div>
                    )}
                    <img
                      src={customer?.image_1?.value}
                      alt={`Uploaded Image`}
                    />
                    {isHovered && customer.imgUrlLoaded && (
                      <div className={styles.uploadOverlay}>
                        <div className={styles.overlayButtons}>
                          <div
                            className={styles.eyeButton}
                            onClick={() =>
                              defImageModalOpen(
                                customer?.image_1?.value,
                                customer?.image_1?.fileName
                              )
                            }
                          >
                            <img src={whiteEye} alt="whiteEyeIcon"></img>
                          </div>
                          <div
                            className={styles.deleteButton}
                            onClick={() =>
                              removeImage(
                                "image_1",
                                index,
                                customer?.image_1?.value
                              )
                            }
                          >
                            <img src={whiteTrash} alt="whitedeleteIcon"></img>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal
                      open={
                        modalOpen && defImageSrc === customer?.image_1?.value
                      }
                      onClose={handleCloseModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className={styles.modal}
                    >
                      <div className={styles.imageModalBox}>
                        <div className={styles.header}>
                          <img
                            src={cancelAction}
                            alt=""
                            onClick={handleCloseModal}
                          />
                          <p>
                            {defFileName?.length > 0
                              ? defFileName
                              : "Default image"}
                          </p>
                        </div>
                        {/* <div className={styles.topdiv}>
                            <Button handleClick={handleCloseModal} label="close" customClass={styles.clsBtn}/>
                          </div> */}
                        <img src={defImageSrc} alt="" />
                      </div>
                    </Modal>
                  </span>
                )}
                {!customer?.image_1?.value?.length && (
                  <span
                    className={`${styles.uploadLabel} ${
                      !customer.imgUrlLoaded && styles.pendingImgLoad
                    }`}
                  >
                    {!customer.imgUrlLoaded && (
                      <div className={styles.imgLoading}>
                        <CircularProgress size={20} color="primary" />
                      </div>
                    )}
                    <label htmlFor={`file-def-${index}`}>
                      <img src={uploadAction} alt="upload action" />
                    </label>
                    <input
                      hidden
                      type="file"
                      name="file"
                      id={`file-def-${index}`}
                      disabled={!customer.imgUrlLoaded}
                      // multiple
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        if (customer.imgUrlLoaded) {
                          const files = e.target.files;
                          if (files && files.length > 0) {
                            const uploadedImages = Array.from(files);
                            changeEditStatus("image_1", index, uploadedImages);
                          }
                        }
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
          </td>
          {/* Default Image Ends  */}
          {/* Media Starts  */}
          <td>
            <div className={styles.column}>
              <div className={styles.imageContainer}>
                {/* {Array.isArray(customer?.media?.value) &&
                  customer?.media?.value?.map((item: any, idx: any) => (
                  
                  ))} */}
                <UploadImg
                  customer={customer}
                  index={index}
                  changeEditStatus={changeEditStatus}
                  throwError={throwError}
                  name={"gallery_image_1"}
                  removeImage={removeImage}
                  imgIndx={1}
                />
                <UploadImg
                  customer={customer}
                  index={index}
                  changeEditStatus={changeEditStatus}
                  throwError={throwError}
                  name={"gallery_image_2"}
                  removeImage={removeImage}
                  imgIndx={2}
                />
                <UploadImg
                  customer={customer}
                  index={index}
                  changeEditStatus={changeEditStatus}
                  throwError={throwError}
                  name={"gallery_image_3"}
                  removeImage={removeImage}
                  imgIndx={3}
                />
                <UploadImg
                  customer={customer}
                  index={index}
                  changeEditStatus={changeEditStatus}
                  throwError={throwError}
                  name={"gallery_image_4"}
                  removeImage={removeImage}
                  imgIndx={4}
                />

                {/* {!Array.isArray(customer?.media?.value) && (
                  <span
                    className={`${styles.uploadLabel} ${
                      !customer.imgUrlLoaded && styles.pendingImgLoad
                    }`}
                  >
                    <label htmlFor={`file-${index}`}>
                      <img src={uploadAction} alt="upload action" />
                      {!customer.imgUrlLoaded && (
                        <div className={styles.imgLoading}>
                          <CircularProgress size={20} color="primary" />
                        </div>
                      )}
                    </label>
                    <input
                      hidden
                      type="file"
                      name="file"
                      id={`file-${index}`}
                      disabled={!customer.imgUrlLoaded}
                      multiple
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files && files.length > 0) {
                          const uploadedImages = Array.from(files);
                          // .map((file) =>
                          //   URL.createObjectURL(file)
                          // );
                          const newMediaValue = (customer?.media?.value || [])
                            .concat(uploadedImages)
                            .slice(0, 5); // Ensure a maximum of 5 images
                          changeEditStatus("media", index, newMediaValue);
                        }
                      }}
                    />
                  </span>
                )} */}
              </div>
            </div>
          </td>
          {/* Media Ends */}

          {/* attributes start */}
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "size",
              customer
            )}
            fieldName="size"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "flavor",
              customer
            )}
            fieldName="flavor"
          />

          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "capacity",
              customer
            )}
            fieldName="capacity"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "color",
              customer
            )}
            fieldName="color"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "scent",
              customer
            )}
            fieldName="scent"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "origin",
              customer
            )}
            fieldName="origin"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "material",
              customer
            )}
            fieldName="material"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "type",
              customer
            )}
            fieldName="type"
          />
          <AttributeCell
            currentRow={customer}
            changeEditStatus={changeEditStatus}
            index={i}
            isInvalidAttribute={isAttributePresentInCurrentCategory(
              "cutorform",
              customer
            )}
            fieldName="cutorform"
          />

          {/* attributes end */}

          {/* is product deactivate */}
          {customer?.is_deactivated !== undefined && (
            <td>
              <div className={styles.column}>
                <Dropdown
                  selectedValue={
                    customer?.is_deactivated !== undefined
                      ? customer.is_deactivated.value.toString()
                      : ""
                  }
                  minWidth="200px"
                  overlayWidth="200px"
                  label={""}
                  customDropdownStyles={styles.customDropDown}
                  options={[
                    { name: "true", value: "true" },
                    { name: "false", value: "false" },
                  ]}
                  defaultLabel={
                    customer?.is_deactivated?.value?.toString() ??
                    "Select is product deactivated"
                  }
                  id="is_deactivated"
                  onChange={(selectedOption: any) => {
                    changeEditStatus("is_deactivated", i, selectedOption.name);
                  }}
                  defaultValue={"false"}
                  // setSelectedvalue={setSelectedValue}
                ></Dropdown>
              </div>
            </td>
          )}

          {variantsData &&
            variantsData?.length > 0 &&
            variantsData?.map((variant: any, variantIndex: number) => {
              const isDuplicateOption = optionsErrorIndex?.includes(
                variantIndex + 1
              );
              return (
                <React.Fragment key={variantIndex}>
                  {/* ordering_option_value Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[`ordering_option_value_${variantIndex + 1}`]
                        ?.editMode &&
                      !customer?.[`ordering_option_value_${variantIndex + 1}`]
                        ?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`ordering_option_value_${
                            variantIndex + 1
                          }`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } ordering unit value is required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[
                            `ordering_option_value_${variantIndex + 1}`
                          ]?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `ordering_option_value_${variantIndex + 1}`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `ordering_option_value_${variantIndex + 1}`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <input
                                type="number"
                                min={1}
                                name=""
                                id="supplier"
                                value={
                                  customer?.[
                                    `ordering_option_value_${variantIndex + 1}`
                                  ]?.value?.length > 15
                                    ? `${customer?.[
                                        `ordering_option_value_${
                                          variantIndex + 1
                                        }`
                                      ]?.value?.slice(0, 15)}...`
                                    : customer?.[
                                        `ordering_option_value_${
                                          variantIndex + 1
                                        }`
                                      ]?.value || ""
                                }
                                onChange={(e) =>
                                  changeEditStatus(
                                    `ordering_option_value_${variantIndex + 1}`,
                                    i,
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  changeEditStatus(
                                    `ordering_option_value_${variantIndex + 1}`,
                                    i,
                                    e.target.value,
                                    false
                                  )
                                }
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    changeEditStatus(
                                      `ordering_option_value_${
                                        variantIndex + 1
                                      }`,
                                      i,
                                      false
                                    );
                                  }
                                }}
                                autoFocus={
                                  customer?.[
                                    `ordering_option_value_${variantIndex + 1}`
                                  ]?.editMode
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* ordering_option_value_1 Ends  */}
                  {/* ordering_option_label Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[`ordering_option_label_${variantIndex + 1}`]
                        ?.editMode &&
                      !customer?.[`ordering_option_label_${variantIndex + 1}`]
                        ?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`ordering_option_label_${
                            variantIndex + 1
                          }`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } ordering unit label is required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[
                            `ordering_option_label_${variantIndex + 1}`
                          ]?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `ordering_option_label_${variantIndex + 1}`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `ordering_option_label_${variantIndex + 1}`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <Dropdown
                                selectedValue={
                                  customer?.[
                                    `ordering_option_label_${variantIndex + 1}`
                                  ]?.value.length
                                    ? customer?.[
                                        `ordering_option_label_${
                                          variantIndex + 1
                                        }`
                                      ]?.value
                                    : ""
                                }
                                minWidth="200px"
                                label={""}
                                customDropdownStyles={styles.customDropDown}
                                options={measuringUnits}
                                defaultLabel={
                                  customer?.[
                                    `ordering_option_label_${variantIndex + 1}`
                                  ]?.value
                                    ? customer?.[
                                        `ordering_option_label_${
                                          variantIndex + 1
                                        }`
                                      ]?.value
                                    : "Select ordering option unit name"
                                }
                                id="ordering_option_label_"
                                onChange={(selectedOption: any) => {
                                  changeEditStatus(
                                    `ordering_option_label_${variantIndex + 1}`,
                                    i,
                                    selectedOption.name
                                  );
                                }}
                                // errorMessage="ERROR FROM DROP DOWN"
                              ></Dropdown>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* ordering_option_label Ends  */}
                  {/* secondary_ordering_option_value Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[
                        `secondary_ordering_option_value_${variantIndex + 1}`
                      ]?.editMode &&
                      !customer?.[
                        `secondary_ordering_option_value_${variantIndex + 1}`
                      ]?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`secondary_ordering_option_value_${
                            variantIndex + 1
                          }`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } secondary ordering unit value id required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[
                            `secondary_ordering_option_value_${
                              variantIndex + 1
                            }`
                          ]?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `secondary_ordering_option_value_${
                                    variantIndex + 1
                                  }`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `secondary_ordering_option_value_${
                                    variantIndex + 1
                                  }`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <input
                                type="number"
                                min={1}
                                name=""
                                id="supplier"
                                value={
                                  customer?.[
                                    `secondary_ordering_option_value_${
                                      variantIndex + 1
                                    }`
                                  ]?.value?.length > 15
                                    ? `${customer?.[
                                        `secondary_ordering_option_value_${
                                          variantIndex + 1
                                        }`
                                      ]?.value?.slice(0, 15)}...`
                                    : customer?.[
                                        `secondary_ordering_option_value_${
                                          variantIndex + 1
                                        }`
                                      ]?.value || ""
                                }
                                onChange={(e) =>
                                  changeEditStatus(
                                    `secondary_ordering_option_value_${
                                      variantIndex + 1
                                    }`,
                                    i,
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  changeEditStatus(
                                    `secondary_ordering_option_value_${
                                      variantIndex + 1
                                    }`,
                                    i,
                                    e.target.value,
                                    false
                                  )
                                }
                                onKeyUp={(e: any) => {
                                  if (e.key === "Enter") {
                                    changeEditStatus(
                                      `secondary_ordering_option_value_${
                                        variantIndex + 1
                                      }`,
                                      i,
                                      false
                                    );
                                  }
                                }}
                                autoFocus={
                                  customer?.[
                                    `secondary_ordering_option_value_${
                                      variantIndex + 1
                                    }`
                                  ]?.editMode
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* secondary_ordering_option_value Ends  */}

                  {/* secondary_ordering_option_label Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[
                        `secondary_ordering_option_label_${variantIndex + 1}`
                      ]?.editMode &&
                      !customer?.[
                        `secondary_ordering_option_label_${variantIndex + 1}`
                      ]?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`secondary_ordering_option_label_${
                            variantIndex + 1
                          }`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } secondary ordering unit label is required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[
                            `secondary_ordering_option_label_${
                              variantIndex + 1
                            }`
                          ]?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `secondary_ordering_option_label_${
                                    variantIndex + 1
                                  }`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `secondary_ordering_option_label_${
                                    variantIndex + 1
                                  }`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <Dropdown
                                selectedValue={
                                  customer?.[
                                    `secondary_ordering_option_label_${
                                      variantIndex + 1
                                    }`
                                  ]?.value.length
                                    ? customer?.[
                                        `secondary_ordering_option_label_${
                                          variantIndex + 1
                                        }`
                                      ]?.value
                                    : ""
                                }
                                minWidth="200px"
                                label={""}
                                customDropdownStyles={styles.customDropDown}
                                options={
                                  customer?.[
                                    `ordering_option_label_${variantIndex + 1}`
                                  ]?.subUnits
                                  // customer?.varaints?.[variantIndex]?.[`ordering_option_label_${variantIndex+1}`]?.subUnits
                                }
                                defaultLabel={
                                  customer?.[
                                    `secondary_ordering_option_label_${
                                      variantIndex + 1
                                    }`
                                  ]?.value
                                    ? customer?.[
                                        `secondary_ordering_option_label_${
                                          variantIndex + 1
                                        }`
                                      ]?.value
                                    : "Select equal to unit name"
                                }
                                id="secondary_ordering_option_label_"
                                onChange={(selectedOption: any) => {
                                  changeEditStatus(
                                    `secondary_ordering_option_label_${
                                      variantIndex + 1
                                    }`,
                                    i,
                                    selectedOption.name
                                  );
                                }}
                                // errorMessage="ERROR FROM DROP DOWN"
                              ></Dropdown>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* secondary_ordering_option_label Ends  */}

                  {/* price  Starts */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[`unit_price_${variantIndex + 1}`]
                        ?.editMode &&
                      !customer?.[`unit_price_${variantIndex + 1}`]?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`unit_price_${variantIndex + 1}`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } unit price is required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[`unit_price_${variantIndex + 1}`]
                            ?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `unit_price_${variantIndex + 1}`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `unit_price_${variantIndex + 1}`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <input
                                type="number"
                                name=""
                                id="supplier"
                                min={1}
                                value={
                                  customer?.[`unit_price_${variantIndex + 1}`]
                                    ?.value?.length > 15
                                    ? `${customer?.[
                                        `unit_price_${variantIndex + 1}`
                                      ]?.value?.slice(0, 15)}...`
                                    : customer?.[
                                        `unit_price_${variantIndex + 1}`
                                      ]?.value || ""
                                }
                                onChange={(e) =>
                                  changeEditStatus(
                                    `unit_price_${variantIndex + 1}`,
                                    i,
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  changeEditStatus(
                                    `unit_price_${variantIndex + 1}`,
                                    i,
                                    e.target.value,
                                    false
                                  )
                                }
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    changeEditStatus(
                                      `unit_price_${variantIndex + 1}`,
                                      i,
                                      false
                                    );
                                  }
                                }}
                                autoFocus={
                                  customer?.[`unit_price_${variantIndex + 1}`]
                                    ?.editMode
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* price  Ends */}
                  {/* offer Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[`offer_${variantIndex + 1}`]?.editMode &&
                      !customer?.[`offer_${variantIndex + 1}`]?.value &&
                      customer?.[`offer_${variantIndex + 1}`]?.value !== 0 ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`offer_${variantIndex + 1}`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } offer is required`}
                          showErr={false}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[`offer_${variantIndex + 1}`]
                            ?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `offer_${variantIndex + 1}`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[`offer_${variantIndex + 1}`]
                                  ?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <input
                                type="number"
                                min={1}
                                max="99"
                                name=""
                                id="supplier"
                                value={
                                  customer?.[
                                    `offer_${variantIndex + 1}`
                                  ]?.value?.toString()?.length > 0
                                    ? customer?.[`offer_${variantIndex + 1}`]
                                        ?.value
                                    : ""
                                }
                                onChange={(e) =>
                                  changeEditStatus(
                                    `offer_${variantIndex + 1}`,
                                    i,
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  changeEditStatus(
                                    `offer_${variantIndex + 1}`,
                                    i,
                                    e.target.value,
                                    false
                                  )
                                }
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    changeEditStatus(
                                      `offer_${variantIndex + 1}`,
                                      i,
                                      false
                                    );
                                  }
                                }}
                                autoFocus={
                                  customer?.[`offer_${variantIndex + 1}`]
                                    ?.editMode
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* offer Ends  */}

                  {/* currency type Starts */}
                  {/* <td>
    <div className={styles.column}>
      {!customer?.[`currency_type_${variantIndex + 1}`]
        ?.editMode &&
      !customer?.[`currency_type_${variantIndex + 1}`]?.value ? (
        <div
          className={styles.editableField}
          onClick={() =>
            changeEditStatus(
              `currency_type_${variantIndex + 1}`,
              i,
              true
            )
          }
        >
          <span
            className={styles.errorTooltip}
            title="Error message for Ordering Option 2"
          >
            <LightTooltip title="Add" placement="top" arrow>
              <img src={iconError} alt="Error" />
            </LightTooltip>
          </span>
        </div>
      ) : (
        <div className={styles.column}>
          {!customer?.[`currency_type_${variantIndex + 1}`]
            ?.editMode ? (
            <span
              onClick={() =>
                changeEditStatus(
                  `currency_type_${variantIndex + 1}`,
                  i,
                  true
                )
              }
            >
              {customer?.[`currency_type_${variantIndex + 1}`]
                ?.value?.length > 15
                ? `${customer?.[
                    `currency_type_${variantIndex + 1}`
                  ]?.value?.slice(0, 15)}...`
                : customer?.[`currency_type_${variantIndex + 1}`]
                    ?.value || ""}
            </span>
          ) : (
            <div className={styles.editableField}>
              <input
                type="text"
                name=""
                id="supplier"
                value={
                  customer?.[`currency_type_${variantIndex + 1}`]
                    ?.value?.length > 15
                    ? `${customer?.[
                        `currency_type_${variantIndex + 1}`
                      ]?.value?.slice(0, 15)}...`
                    : customer?.[
                        `currency_type_${variantIndex + 1}`
                      ]?.value || ""
                }
                onChange={(e) =>
                  changeEditStatus(
                    `currency_type_${variantIndex + 1}`,
                    i,
                    e.target.value
                  )
                }
                onBlur={(e) =>
                  changeEditStatus(
                    `currency_type_${variantIndex + 1}`,
                    i,
                    e.target.value,
                    false
                  )
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    changeEditStatus(
                      `currency_type_${variantIndex + 1}`,
                      i,
                      false
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  </td> */}
                  {/* currency type Ends */}
                  {/* min Quantity  Starts  */}
                  <td>
                    <div className={styles.column}>
                      {!customer?.[`min_quantity_${variantIndex + 1}`]
                        ?.editMode &&
                      !customer?.[`min_quantity_${variantIndex + 1}`]?.value ? (
                        <EmptyProductOption
                          changeEditStatus={changeEditStatus}
                          index={variantIndex}
                          productIndex={i}
                          isDuplicateOption={isDuplicateOption}
                          fieldName={`min_quantity_${variantIndex + 1}`}
                          tooltipTitle={`Product option${
                            variantIndex + 1
                          } min quantity is required`}
                        />
                      ) : (
                        <div className={styles.column}>
                          {!customer?.[`min_quantity_${variantIndex + 1}`]
                            ?.editMode ? (
                            <span
                              onDoubleClick={() =>
                                changeEditStatus(
                                  `min_quantity_${variantIndex + 1}`,
                                  i,
                                  true
                                )
                              }
                            >
                              {getTextWithoutOverflow({
                                text: customer?.[
                                  `min_quantity_${variantIndex + 1}`
                                ]?.value,
                                length: 20,
                              })}
                            </span>
                          ) : (
                            <div className={styles.editableField}>
                              <input
                                type="number"
                                name=""
                                id="supplier"
                                min={1}
                                value={
                                  customer?.[`min_quantity_${variantIndex + 1}`]
                                    ?.value?.length > 15
                                    ? `${customer?.[
                                        `min_quantity_${variantIndex + 1}`
                                      ]?.value?.slice(0, 15)}...`
                                    : customer?.[
                                        `min_quantity_${variantIndex + 1}`
                                      ]?.value || ""
                                }
                                onChange={(e) =>
                                  changeEditStatus(
                                    `min_quantity_${variantIndex + 1}`,
                                    i,
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  changeEditStatus(
                                    `min_quantity_${variantIndex + 1}`,
                                    i,
                                    e.target.value,
                                    false
                                  )
                                }
                                onKeyUp={(e) => {
                                  if (e.key === "Enter") {
                                    changeEditStatus(
                                      `min_quantity_${variantIndex + 1}`,
                                      i,
                                      false
                                    );
                                  }
                                }}
                                autoFocus={
                                  customer?.[`min_quantity_${variantIndex + 1}`]
                                    ?.editMode
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {/* min Quantity Ends  */}
                </React.Fragment>
              );
            })}
        </tr>
      ) : (
        <></>
      )}
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};

export default TableRow;
