import { Drawer } from "@mui/material";
import styles from "./EditUser.module.scss";
import cancel from "../../../../assets/images/cancelToast.svg";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import {
  constants,
  preferencesConstants,
  supplierPreferenceEndpoints,
  USER_ROLES,
} from "../../../../common/constants/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../../services/useAxios";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { useDispatch } from "react-redux";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import { cloneDeep } from "lodash";

type EditUserProps = {
  showAddOutlet: boolean;
  handleClose: () => void;
  editData: any;
  supplierId: string;
  storeId: string;
  fetchUsers: () => void;
  customerId: string;
};
const EditUser = ({
  showAddOutlet,
  handleClose,
  editData,
  supplierId,
  storeId,
  fetchUsers,
  customerId,
}: EditUserProps) => {
  const [userType, setUserType] = useState<any>();
  const dispatch = useDispatch();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const roleKey =
    editData?.role_key === USER_ROLES.SUPPLIERADMIN &&
      editData?.is_super_user
      ? USER_ROLES.SUPERUSER
      : editData?.role_key;

  useEffect(() => {
    if (editData) {
      setValue("firstName", editData?.first_name);
      setValue("lastName", editData?.last_name);
      setValue("userType", {
        name: editData?.role,
        label: editData?.role,
        value: editData?.role_key,
        payloadValue: editData?.role_key,
      });
      setUserType({
        name: editData?.role?.split(" ")[1],
        label: editData?.role,
        value: editData?.role_key,
        payloadValue: editData?.role_key,
      });
      setValue("emailId", editData?.email)
    }
  }, [editData]);

  const schema = editData
    ? yup
      .object({
        firstName: yup.string().required("Enter first name"),
        lastName: yup.string().required("Enter last name"),
        userType: yup.object().required("Select user type"),
        emailId: yup.string().optional(),
        password: yup.string().optional(),
      })
      .required()
    : yup
      .object({
        firstName: yup.string().required("Enter first name"),
        lastName: yup.string().required("Enter last name"),
        userType: yup.object().required("Select user type"),
        emailId: yup
          .string()
          .required("Enter email id")
          .matches(
            constants.formaValidations.EMAIL_REGEX,
            "Enter a valid Email"
          ),
        password: yup.string().required("Enter password"),
      })
      .required();

  const {
    register,
    formState: { errors },
    // formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    // control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const values = getValues();
  // console.log(errors);

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
    },
    [setValue]
  );

  // Add User API
  const postAddUserData = useAxios({
    axiosParams: {
      url: supplierPreferenceEndpoints.usersList.replace(
        ":supplier_id",
        supplierId
      ),
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.emailId,
        password: values?.password,
        role: {
          role_key: userType?.payloadValue,
        },
        customer_type: "company",
        existing_ware_houses: [storeId],
      },
    },
    method: "POST",
  });
  //Update user data
  const updateUser = useAxios({
    axiosParams: {
      url: supplierPreferenceEndpoints.updateUser
        .replace(":supplier_id", supplierId)
        .replace(":customer_id", editData?.customer_id),
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        role: roleKey === USER_ROLES.SUPERUSER ? undefined : userType?.payloadValue,
        updated_customer_id: customerId,
      },
    },
    method: "PUT",
  });

  const submit = async () => {
    if (editData) {
      dispatch(updateIsLoading(true));
      const response: any = await updateUser?.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        const userData = localStorage.getItem("loggedInUser");
        const userDetails = userData ? JSON.parse(userData) : {}
        if(editData?.customer_id === userDetails?.id) {
          let data = cloneDeep(userDetails);
          data.first_name = values?.firstName;
          data.last_name = values?.lastName;
          localStorage.setItem("loggedInUser", JSON.stringify(data));
          window.location.reload();
        }
        setToastData({
          message: "User updated successfully",
          status: "Success",
        });
        fetchUsers();
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        handleReset();
        handleClose();
      } else {
        dispatch(updateIsLoading(false));
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    } else {
      dispatch(updateIsLoading(true));
      const response: any = await postAddUserData?.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        setToastData({
          message: "User added successfully",
          status: "Success",
        });
        fetchUsers();
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        handleReset();
        handleClose();
      } else {
        dispatch(updateIsLoading(false));
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    }
  };

  const handleReset = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("emailId", "");
    setValue("password", "");
    setValue("userType", {});
    setUserType(null);
  };

  const skipAdd = (e: any) => {
    e.preventDefault();
    handleReset();
    handleClose();
  };
  return (
    <>
      <Drawer
        anchor={"right"}
        open={showAddOutlet}
        //   onClose={() => handleClose}
        hideBackdrop={false}
      >
        <div className={styles.addoutletContainer}>
          <div className={styles.header}>
            <p className={styles.heading}>
              {editData
                ? preferencesConstants.editUser
                : preferencesConstants.addUser}
            </p>
            <img
              src={cancel}
              alt="cancel"
              className={styles.cancel}
              onClick={(e) => skipAdd(e)}
            />
          </div>
          {editData ? (
            <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
              <div className={styles.form}>
                <div className={styles.inputFields}>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="firstName"
                      label="First name"
                      value={watch("firstName")}
                      placeholder="Enter first name here"
                      onChange={onChangeField("firstName")}
                      register={register("firstName")}
                      errorMessage={errors.firstName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="lastName"
                      value={watch("lastName")}
                      label="Last name"
                      placeholder="Enter last name here"
                      onChange={onChangeField("lastName")}
                      register={register("lastName")}
                      errorMessage={errors.lastName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Dropdown
                      // customDropdownStyles={styles.dropDownMargin}
                      id={"userType"}
                      label="User Type"
                      // editForm={isUserTypeDisabled}
                      options={constants.userTypesListSupplier}
                      minWidth="440px"
                      isDropdownOutlineRequired={true}
                      register={register("userType")}
                      onChange={(selectedOption: ICountry) => {
                        if (selectedOption) {
                          onChangeField("userType")(selectedOption);
                          setUserType(selectedOption);
                        }
                      }}
                      defaultLabel={userType?.name ?? "Select user type .."}
                      selectedValue={userType}
                      defaultValue={userType}
                      errorMessage={errors.userType?.message as any}
                      disabled={editData?.is_super_user}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="emailId"
                      label="Email id"
                      value={watch("emailId")}
                      placeholder="Enter email id here"
                      onChange={onChangeField("emailId")}
                      register={register("emailId")}
                      errorMessage={errors.emailId?.message}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className={styles.action}>
                <button className={styles.skip} onClick={(e) => skipAdd(e)}>
                  {preferencesConstants.cancel}
                </button>
                <button className={styles.submit}>
                  {preferencesConstants.submit}
                </button>
              </div>
            </form>
          ) : (
            <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
              <div className={styles.form}>
                <div className={styles.inputFields}>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="firstName"
                      label="First name"
                      value={watch("firstName")}
                      placeholder="Enter first name here"
                      onChange={onChangeField("firstName")}
                      register={register("firstName")}
                      errorMessage={errors.firstName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="lastName"
                      value={watch("lastName")}
                      label="Last name"
                      placeholder="Enter last name here"
                      onChange={onChangeField("lastName")}
                      register={register("lastName")}
                      errorMessage={errors.lastName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Dropdown
                      // customDropdownStyles={styles.dropDownMargin}
                      id={"userType"}
                      label="User Type"
                      // editForm={isUserTypeDisabled}
                      options={constants.userTypesListSupplier}
                      minWidth="440px"
                      isDropdownOutlineRequired={true}
                      register={register("userType")}
                      onChange={(selectedOption: ICountry) => {
                        onChangeField("userType")(selectedOption);
                        setUserType(selectedOption);
                      }}
                      defaultLabel={userType?.name ?? "Select user type .."}
                      selectedValue={userType}
                      defaultValue={userType}
                      errorMessage={errors.userType?.message as any}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="emailId"
                      label="Email id"
                      value={watch("emailId")}
                      placeholder="Enter email id here"
                      onChange={onChangeField("emailId")}
                      register={register("emailId")}
                      errorMessage={errors.emailId?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="password"
                      label="Password"
                      value={watch("password")}
                      placeholder="Enter password here"
                      onChange={onChangeField("password")}
                      register={register("password")}
                      errorMessage={errors.password?.message}
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.action}>
                <button className={styles.skip} onClick={(e) => skipAdd(e)}>
                  {preferencesConstants.cancel}
                </button>
                <button className={styles.submit}>
                  {preferencesConstants.submit}
                </button>
              </div>
            </form>
          )}
        </div>
      </Drawer>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};
export default EditUser;
