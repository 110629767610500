import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartValue: 0,
  searchValue: "",
  isBusinessSkipped: "false",
  isSetupCompleted: "false",
  onboardingData: {},
  searchHistory: [
    // {
    //   id: "1",
    //   label: "Ground Nuts",
    //   value: "Ground Nuts",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "2",
    //   label: "Chicken",
    //   value: "Chicken",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "3",
    //   label: "Red Reddish",
    //   value: "Red Reddish",
    //   categoryType: "Fruits & Vegetables",
    // },
    // {
    //   id: "4",
    //   label: "Prawns",
    //   value: "Prawns",
    //   categoryType: "Sea Food",
    // },
    // {
    //   id: "5",
    //   label: "Olive Oil",
    //   value: "Olive Oil",
    //   categoryType: "Oil & Sauces",
    // },
  ],
  searchSuggestions: [
    // {
    //   id: "1",
    //   label: "Butter",
    //   value: "Butter",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "2",
    //   label: "Butternut Cubes",
    //   value: "Butternut Cubes",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "3",
    //   label: "Unsalted Butter",
    //   value: "Unsalted Butter",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "4",
    //   label: "Smooth Peanut Butter",
    //   value: "Smooth Peanut Butter",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "5",
    //   label: "Garlic Butter",
    //   value: "Garlic Butter",
    //   categoryType: "All Categories",
    // },
    // {
    //   id: "6",
    //   label: "Crunchy Peanut Butter",
    //   value: "Crunchy Peanut Butter",
    //   categoryType: "All Categories",
    // },
  ],
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setCartValue(state, action) {
      state.cartValue = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setSearchHistory(state, action) {
      state.searchHistory = action.payload;
    },
    setSearchSuggestions(state, action) {
      state.searchSuggestions = action.payload;
    },
    setIsBusinessSkipped(state, action) {
      state.isBusinessSkipped = action.payload;
    },
    setIsOnboardingData(state, action) {
      state.onboardingData = action.payload;
    },
    setIsSetupCompleted(state, action){
      state.isSetupCompleted = action.payload;
    }
  },
});

export const {
  setCartValue,
  setSearchValue,
  setSearchHistory,
  setIsBusinessSkipped,
  setIsOnboardingData,
  setIsSetupCompleted
} = headerSlice.actions;

export default headerSlice.reducer;
