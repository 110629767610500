import { createSlice } from "@reduxjs/toolkit";




const InitialtoastStatus = {
  message : "",
  status : "",
  type:  ""
}

export const toastStatusSlice = createSlice({
   name: 'toastStatus',
   initialState: InitialtoastStatus,
   reducers: {
    setToastStatusApproval : (state, action: any) => {
      return {
        ...state,
        ...action.payload
      }
    },
    resetToastStatus : () =>{
      return InitialtoastStatus;
    }
   }
})

export const { setToastStatusApproval, resetToastStatus }  = toastStatusSlice.actions;
export default toastStatusSlice.reducer;