export const hierarchy = (
  isSuperUser: boolean,
  loggedUser: string,
  userCardRole: string
) => {
  if (isSuperUser) {
    return true;
  } else if (
    !isSuperUser &&
    loggedUser?.includes("admin") &&
    (userCardRole?.includes("Admin") ||
      userCardRole?.includes("Manager") ||
      userCardRole?.includes("Staff"))
  ) {
    return true;
  } else if (
    !isSuperUser &&
    loggedUser?.includes("manager") &&
    (userCardRole?.includes("Manager") || userCardRole?.includes("Staff"))
  ) {
    return true;
  }
};
