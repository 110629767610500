import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import {
  adminEndPoints,
  nonAuthorizedApis,
  urlConstants,
} from "../common/constants/urlConstants";

export const axiosAPI = axios.create({
  baseURL: urlConstants.BASE_URL,
});
const userData = localStorage.getItem("loggedInUser");
const userType = userData ? JSON.parse(userData)?.typeOfUser?.[0] : "";
let isTokenRefreshing = false;
let failedRequestsQueue: Function[] = [];

const addToQueue = (callback: Function) => {
  failedRequestsQueue.push(callback);
};

const processQueue = () => {
  failedRequestsQueue.forEach(callback => callback());
  failedRequestsQueue = [];
};

const generateToken = async (refreshToken: string, userId: string) => {
  try {
    const response = await axios.post(
      userType === "admin"
        ? `${urlConstants?.BASE_URL}${nonAuthorizedApis.refreshTokenAdmin}`
        : `${urlConstants?.BASE_URL}${nonAuthorizedApis.refreshTokenUser}`,
      {
        refreshToken: refreshToken,
        userId: userId,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const value = config;

    if (value.url && !Object.values(nonAuthorizedApis).includes(value.url)) {
      const storedtoken = localStorage.getItem("token")?.replaceAll('"', "");
      if (storedtoken && value && value.headers) {
        value.headers.token = storedtoken;
        value.headers.Authorization = `Bearer ${storedtoken}`;
      }
    }
    if (value.url && Object.values(adminEndPoints).includes(value.url)) {
      const storedtoken = sessionStorage
        .getItem("accessCode")
        ?.replaceAll('"', "");
      if (storedtoken && value && value.headers) {
        value.headers.accessCode = storedtoken;
      }
    }

    return value;
  },
  (error: AxiosError) => Promise.reject(error)
);
axios.interceptors.response.use(
  function (response) {
     // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const prevRequest = error?.config;
    //if api is unauthorized - 401 and not generated token
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      const refreshToken = localStorage.getItem("refreshToken");
      const userData = localStorage.getItem("loggedInUser");
      const userId = userData ? JSON.parse(userData)?.id : "";
      prevRequest.sent = true;
      if (refreshToken && userId && !prevRequest?.url?.includes("refreshToken")) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          try {
            const tokenResponse = await generateToken(refreshToken, userId) as any;
            if (tokenResponse?.status === 200) {
              const newAccessToken = tokenResponse?.data?.accessToken;
              localStorage.setItem("token", newAccessToken);
              prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
              processQueue(); // Retry failed requests
              return axios(prevRequest); // Retry current request
            } else {
              if (tokenResponse?.response?.data?.status === 9003) {
                localStorage.setItem("isSessionExpired", "true");
              }
              return Promise.reject(error);
            }
          } catch (e: any) {
            if (e?.response?.data?.status === 9003) {
              localStorage.setItem("isSessionExpired", "true");
            }
          } finally {
            isTokenRefreshing = false;
          }
        } else {
          return new Promise((resolve, reject) => {
            addToQueue(() => {
              resolve(axios(prevRequest));
            });
          });
        }
      }
    }
    return Promise.reject(error);
  }
);