import * as React from "react";
import { useForm } from "react-hook-form";
import Dropdown from "../dropdown/Dropdown";
import styles from "./AddAttributes.module.scss";
import { useSelector } from "react-redux";
import uniqBy from "lodash/uniqBy";
import whiteTrash from "../../../assets/images/delete.svg";

interface IAttribute {
  name: string;
  value: string;
  label: string;
  id: string;
  typeValue?: string;
}

export default function AddAttributes({
  selectedAttributes,
  setSelectedAttributes,
  attributesList,
  allAttributesCheck,
  setAllAttributesCheck,
  isRowEdit,
}: any) {
  const { addIndividualProductForm } = useSelector((state: any) => state);

  const { handleSubmit } = useForm({
    mode: "onBlur",
  });

  const [updatedErrors, setErrors] = React.useState<any>({});
  const [allAttributes, setAllAttributes] = React.useState<IAttribute[]>(() => {
    const updatedAllAttributes: any = uniqBy(
      attributesList,
      (el: any) => el.value
    );
    return updatedAllAttributes;
  });

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedAttributes((prevAttributes: any) => {
      const updatedAttributes = [...prevAttributes];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        typeValue: event.target.value,
      };
      return updatedAttributes;
    });

    if (updatedErrors[`attribute.${index}.name`] && event.target.value) {
      const copy = { ...updatedErrors };
      delete copy[`attribute.${index}.name`];
      setErrors(copy);
    }
  };

  const handleDropdownChange = (selectedOption: any, index: number) => {
    const updatedAttributes = [...selectedAttributes];
    updatedAttributes[index] = selectedOption;
    setSelectedAttributes(updatedAttributes);
    if (updatedErrors[`attribute.${index}.type`] && selectedOption) {
      const copy = { ...updatedErrors };
      delete copy[`attribute.${index}.type`];

      setErrors(copy);
    }
  };
  const handleAppend = () => {
    // const filteredFields = fields.filter((field: any) => field?.attribute_type);
    // const hasEmptyDropdownOrInput = filteredFields.some(
    //   (_, index) =>
    //     !selectedAttributes[index] ||
    //     getValues(`attribute.${index}.name`) === ""
    // );

    const hasAllValues = selectedAttributes.every(
      (_: any, index: any) =>
        selectedAttributes[index] && selectedAttributes[index]?.typeValue
    );

    console.log(selectedAttributes, "Add");
    if (hasAllValues) {
      // const appendedIndex = fields.length;
      // const inputValue = getValues(`attribute.${appendedIndex}.name`);
      // if (!isRowEdit) {
      // append({
      //   ...selectedAttributes[appendedIndex],
      //   name: selectedAttributes[appendedIndex]?.name,
      //   value: selectedAttributes[appendedIndex]?.value,
      //   typeValue: inputValue && inputValue,
      //   keyName: selectedAttributes[appendedIndex]?.name,
      // });
      // }
      console.log(selectedAttributes, "selectedAttributes");

      setSelectedAttributes([...selectedAttributes, {}]);
    } else {
      const updatedErrors: any = {};
      selectedAttributes.forEach((attr: any, index: any) => {
        if (!attr?.name) {
          updatedErrors[`attribute.${index}.type`] = {
            type: "required",
            message: "This field is required",
          };
        }
        if (!attr.typeValue) {
          updatedErrors[`attribute.${index}.name`] = {
            type: "required",
            message: "This field is required",
          };
        }
      });
      setErrors(updatedErrors);
    }
  };

  const handleRemove = (index: number) => {
    const updatedAttributes = [...selectedAttributes];
    updatedAttributes.splice(index, 1);
    setSelectedAttributes(updatedAttributes);
  };

  React.useEffect(() => {
    if (attributesList) {
      // const selectedAttrValues = selectedAttributes.map(
      //   (el: any) => el && el?.name
      // );
      const updatedAllAttributes: any = uniqBy(
        attributesList,
        (el: any) => el.value
      );
      if (allAttributesCheck) {
        setAllAttributes(
          addIndividualProductForm &&
            addIndividualProductForm?.firstForm &&
            addIndividualProductForm?.firstForm?.categoriesAttributes
        );
        // setSelectedAttributes([]);
        setAllAttributesCheck(false);
      } else {
        if (updatedAllAttributes.length === 0) setAllAttributesCheck(false);
      }
      // setAllAttributes(updatedAllAttributes);
    }
  }, []);

  return (
    <>
      <div className={styles.attributesLabel}>Product Attributes</div>
      <form onSubmit={handleSubmit((data) => console.log(data))}>
        {selectedAttributes.map((attribute: IAttribute, index: number) => (
          <section className={styles.section} key={index}>
            <div>
              <Dropdown
                label={""}
                options={allAttributes}
                selectedValue={attribute}
                id={`addAttributes_${index}`}
                isfilters
                onChange={(selectedOption: any) => {
                  handleDropdownChange(selectedOption, index);
                }}
                defaultLabel={
                  attribute && attribute.name
                    ? attribute.name
                    : "Select Attributes..."
                }
                isCurrentMenuItemDisabled={(val) => {
                  if (selectedAttributes.find((el: any) => el?.value === val)) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                errorMessage={updatedErrors[`attribute.${index}.type`]?.message}
              />
            </div>
            <div className={styles.inputAttributeValue}>
              <input
                placeholder="Enter Attribute Value"
                value={attribute?.typeValue || ""}
                onChange={(e) => handleInputChange(index, e)}
                className={styles.inputAttributeValueInput}
              />
              <p className={styles.error}>
                {updatedErrors[`attribute.${index}.name`]?.message}
              </p>
            </div>

            <button
              type="button"
              onClick={() => handleRemove(index)}
              className={`${styles.attributButton} ${styles.attrDeleteBtn}`}
            >
              <img src={whiteTrash} alt="whitedeleteIcon"></img>
            </button>
          </section>
        ))}
        <button
          type="button"
          onClick={handleAppend}
          className={styles.attributButton}
          disabled={selectedAttributes.length === allAttributes.length}
        >
          <div className={styles.iconBtn}>+</div> Add Atrribute
        </button>
      </form>
    </>
  );
}
