import React from "react";
import LoginBackground from "../../../../components/login/LoginBackground";
import styles from "./AccountVerfifcation.module.scss";
import { useState } from "react";
import mailIcon from "../../../../assets/images/login/mailIcon.svg";
import ResetIcon from "../../../../assets/images/login/ResetIcon.svg";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useAxios } from "../../../../services/useAxios";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import { constants } from "../../../../common/constants/constants";
import successToast from '../../../../assets/images/successToast.svg'
import errorToast from '../../../../assets/images/errorToast.svg';


const VerifyAccount = () => {
  const navigate = useNavigate();
  const [resend, setResend] = useState(false)
  const [toastData, setToastData] = useState(constants.defaultToastData)
  const location = useLocation();
  const emailId = location?.state?.email;
  var maskId = (emailId: string) => emailId.replaceAll(
    /(?<=.)[^@\n](?=[^@\n]*?[^@\n]@)|(?:(?<=@.)|(?!^)\G(?=[^@\n]*$)).(?=.*[^@\n]\.)/g,
    "*"
  ).replaceAll("**", "*");

  const sendMailAPI = useAxios({
    axiosParams:{
      url: 'store/customer/updatetoken',
      data:{
        email: emailId
      }
    },
    method: "POST"
  });

  const resendActivationMail =  async() =>{
      const response: any = await sendMailAPI.fetchData();
      if(response?.status === 'SUCCESS'){
        setToastData({
          message: "Email has been sent successfully",
          status: "Success",
        });
      }
      else{
        setToastData({
          message: response?.message,
          status: "Fail",
        });
      }
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
  }

  const handleResendEmail = () =>{
    if(emailId){
      resendActivationMail()
    }

  }

  return (
    <>
      <section className={styles.accountVerification}>
        <div className={styles.accountVerification__body}>
          <div className={styles.icon}>
            {resend ? <img src={ResetIcon} alt="reset" /> : <img src={mailIcon} alt="mail" />}
          </div>
          <div className={styles.headerText}>
            {LOGIN_PAGE_STRINGS.VERIFY_ACCOUNT_LABEL}
          </div>
          <div className={styles.subheaderText}>
            {LOGIN_PAGE_STRINGS.VERIFY_ACCOUNT_TEXT}
            <span className={styles.email}>{maskId(emailId)}</span>
            <br />
            {LOGIN_PAGE_STRINGS.VERIFY_ACCOUNT_TEXT2}
          </div>
          <div className={styles.resendSection}>
            {LOGIN_PAGE_STRINGS.DIDNT_REC_MAIL}
            <span
              className={styles.link_primary}
              onClick={() => {
                handleResendEmail();
                navigate(routeConfig.accountVerification,{ state: {
                  email: emailId,
                }})
                setResend(true)
              }}
            >
              {LOGIN_PAGE_STRINGS.RESEND}
            </span>
          </div>
        </div>

        <div className={styles.accountVerification__footer}>
          <div
            className={styles.link_primary}
            onClick={() => {
              navigate(routeConfig.signin);
            }}
          >
            {LOGIN_PAGE_STRINGS.LOGIN_WITH_ACC}
          </div>
        </div>
      </section>
      {toastData?.message ?
        <ToastNotification
        icon={toastData.status === "Success" ? successToast : errorToast}
        content={toastData?.message}
        position={"top-right"}
        autoClose={5000}
        ></ToastNotification>
        :
        <></>
      }
    </>
  );
};

export const AccountVerification = () => {
  return (
    <LoginBackground
      form={
        <>
          <VerifyAccount />
          {/* <ResetLink/> */}
        </>
      }
    ></LoginBackground>
  );
};

export default AccountVerification;
