import React, { useEffect, useState } from "react";
import LoginBackground from "../../../../components/login/LoginBackground";

import suggestion from "../../../../assets/images/login/suggestion.svg";
import styles from "./signUp.module.scss";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import Button from "../../../../common/components/button/Button";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useNavigate } from "react-router-dom";
import { constants } from "../../../../common/constants/constants";
// import BasicSwitch from "../../../../common/components/switch/Switch";
import { useDispatch } from "react-redux";
import { setUserTypeGlobal } from "../../../../reducerSlices/userTypeSlice";
import { ISignup } from "../../../../models/ILogin";
// import AccountButton from "../../../../common/components/accountButton/AccountButton";
// import googleLogo from "../../../../assets/images/googleLogo.png";
// import appleLogo from "../../../../assets/images/appleLogo.png";
// import { createUser } from "../../../../reducerSlices/signUpSlice";
// import { RootState } from "../../../../store/store";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  loginRoutes,
} from "../../../../common/constants/urlConstants";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import CryptoJS from "crypto-js";
// import BasicSwitch from "../../../../common/components/switch/Switch";

const SignUp = ({ setChecked, checked, handleSwitch }: ISignup) => {
  const navigate = useNavigate();
  // const { signUpUserDetails } = useSelector((state: RootState) => state);
  const [tncChecked, setTncChecked] = useState(false);
  const [userType, setUserType] = useState("supplier-admin");
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    firstName: yup.string().required("Enter First name"),
    lastName: yup.string().required("Enter Last name"),
    email: yup
      .string()
      .required("Enter Email ID")
      .matches(constants.formaValidations.EMAIL_REGEX, "Invalid Email format"),
    password: yup
      .string()
      .required("Enter Password")
      .matches(
        constants.formaValidations.PASSWORD_REGEX,
        "Password must be 8-14 characters with upper, lower, number, and special character."
      ),
    confirmPassword: yup
      .string()
      .required("Enter Confirm password")
      .matches(
        constants.formaValidations.PASSWORD_REGEX,
        "Password must be 8-14 characters with upper, lower, number, and special character."
      )
      .oneOf([yup.ref("password"), ""], "Passwords must match"),
    tnc: yup.boolean().oneOf([true], "Please accept the terms & conditions"),
  });
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    getValues,
    watch,
    // reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const values = getValues();
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      isSubmitted && trigger(name);
    },
    [setValue, isSubmitted]
  );
  useEffect(() => {
    // malke it === when you want buyer
    checked !== true
      ? setUserType("supplier-admin")
      : // if we will have switch than change below line to buyer-admin
        setUserType("buyer-admin");
  }, [checked]);

  const [toastData, setToastData] = useState(constants.defaultToastData);
  const signUp = useAxios({
    axiosParams: {
      url: loginRoutes.signUp,
      data: {
        first_name: watch("firstName"),
        last_name: watch("lastName"),
        email: watch("email"),
        password: generateHashedPassword(watch("password")), // watch("password"),
        customer_type: "company",
        role: {
          role_key: userType,
        },
      },
    },
    method: APIMethods.post,
  });

  function generateHashedPassword(password: string) {
    const secretKey = process.env.REACT_APP_PASSWORD_HASHING_SECRET_KEY ?? '';
    const iv = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_PASSWORD_HASHING_IV_SECRET_KEY ?? ''
    );
    const encryptedString = CryptoJS.AES.encrypt(password, secretKey, {
      iv: iv,
    });
    return encryptedString.toString();
  }

  const onSubmit = async (data: any) => {
    if (data?.tnc) {
      try {
        // dispatch(
        //   createUser({
        //     email: values?.email,
        //     password: values?.password,
        //     userType: userType,
        //   }) as any
        // );
        dispatch(updateIsLoading(true));
        const response: any = await signUp.fetchData();
        if (response?.data?.id) {
          values.email = "";
          values.password = "";
          values.confirmPassword = "";
          // if (response?.customer?.id) {
          //   localStorage.setItem("user", JSON.stringify(response?.customer));
          // }
          navigate(routeConfig.accountVerification, {
            state: {
              email: data.email,
            },
          });
          dispatch(updateIsLoading(false));
        } else {
          setToastData({
            message: response?.response?.data?.error_msg,
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
          dispatch(updateIsLoading(false));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setValue("tnc", tncChecked);
  }, [tncChecked]);

  return (
    <>
      <section className={styles.signup}>
        <div className={styles.signup__header}>
          <div className={styles.signup__heading}>
            {/* uncomment below lines when you want buyer */}

            {/* {userType === "supplier-admin" */}
            {/* ? */}
            {LOGIN_PAGE_STRINGS.SIGNUP_TITLE_SUPPLIER}
            {/* : LOGIN_PAGE_STRINGS.SIGNUP_TITLE_BUYER} */}
          </div>
          {/* uncommenet below lines when you want buyer */}
          {/* <div className={styles.signup__rightContainer}>
            <div className={styles.heading}>Buyer</div>
            <BasicSwitch
              switchType="dual"
              handleChange={() => handleSwitch(reset)}
              checked={checked}
              setChecked={setChecked}
            />
            <div className={styles.heading}>Supplier</div>
          </div> */}
        </div>
        <div className={styles.signup__form}>
          <form key={`${userType}-signup`}>
            {/* <!-- email --> */}
            <div className={`${styles.email} ${styles.formField}`}>
              <div className={styles.formFieldItem}>
                <div className={styles.inputField}>
                  <div className={styles.label}>First Name</div>
                  <InputField
                    placeholder={"Enter first name"}
                    customClass={styles.inputFieldMargin}
                    type="text"
                    onChange={onChangeField("firstName")}
                    register={register("firstName")}
                    errorMessage={errors.firstName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <div className={styles.label}>Last Name</div>
                  <InputField
                    placeholder={"Enter last name"}
                    customClass={styles.inputFieldMargin}
                    type="text"
                    onChange={onChangeField("lastName")}
                    register={register("lastName")}
                    errorMessage={errors.lastName?.message}
                  />
                </div>
                <div className={styles.label}>
                  {LOGIN_PAGE_STRINGS.EMAILID}
                  <div className={styles.suggestion}>
                    <div className={styles.icon}>
                      <img src={suggestion} alt="suggestion" />
                    </div>
                    {LOGIN_PAGE_STRINGS.SUGGESTION}
                  </div>
                </div>
                <InputField
                  placeholder={LOGIN_PAGE_STRINGS.EMAIL_PLACEHOLDER}
                  customClass={styles.inputFieldMargin}
                  type="email"
                  onChange={onChangeField("email")}
                  register={register("email")}
                  errorMessage={errors.email?.message}
                />
              </div>
            </div>
            {/* <!-- email --> */}

            {/* <!-- password --> password form-field */}
            <div className={`${styles.password} ${styles.formField}`}>
              <div className={styles.formFieldItem}>
                <div className={styles.label}>
                  {LOGIN_PAGE_STRINGS.PASSWORD}
                </div>
                <div className={styles.formFieldPassword}>
                  <InputField
                    placeholder={LOGIN_PAGE_STRINGS.PASSWORD_PLACEHOLDER}
                    customClass={styles.inputFieldMargin}
                    type="password"
                    onChange={onChangeField("password")}
                    register={register("password")}
                    errorMessage={errors.password?.message}
                  />
                </div>
              </div>
              {/* <!-- password constraints  --> */}
              <div className="password-constraint-container">
                <div className="contain-text">
                  {/* {{ stringConstants.PASSWORD_CONSTRIANTS }} */}
                </div>
                <div className="password-constraint">
                  <div className="icon">
                    <div className="dot"></div>
                  </div>

                  {/* <div>{{ constraint.label }}</div> */}
                </div>
              </div>
              {/* <!-- password constraints  --> */}
            </div>
            {/* <!-- password --> */}

            {/* <!-- confirn password --> name-section */}
            <div
              className={`${styles.nameSection} ${styles.confirmPassword} ${styles.formField} `}
            >
              <div className={styles.formFieldItem}>
                <div className={styles.label}>
                  {LOGIN_PAGE_STRINGS.CONFIRM_PASSWORD}
                </div>
                <div className={styles.formFieldPassword}>
                  <InputField
                    placeholder={
                      LOGIN_PAGE_STRINGS.CONFIRM_PASSWORD_PLACEHOLDER
                    }
                    type="password"
                    customClass={styles.inputFieldMargin}
                    onChange={onChangeField("confirmPassword")}
                    register={register("confirmPassword")}
                    errorMessage={errors.confirmPassword?.message}
                  />
                </div>
              </div>
              {/* <!-- password constraints  --> */}
              <div className="password-constraint-container">
                <div className="contain-text">
                  {/* {{ stringConstants.PASSWORD_CONSTRIANTS }} */}
                </div>
                <div className="password-constraint">
                  <div className="icon">
                    <div className="dot"></div>
                  </div>

                  {/* <div>{{ constraint.label }}</div> */}
                </div>
              </div>
              {/* <!-- password constraints  --> */}
            </div>

            {/* <!-- confirn password --> */}

            {/* Check box condpition */}
            <div className={styles.tncContainer}>
              <div className={`${styles.formField} ${styles.tnc}`}>
                <CheckboxButton
                  checked={tncChecked}
                  setChecked={setTncChecked}
                  register={register("tnc")}
                  handleChange={onChangeField("tnc")}
                />
                <div className={styles.tncHeading}>
                  <span onClick={() => setTncChecked(!tncChecked)}>
                    I accept the
                  </span>
                  <a
                    href="https://www.watermelon.market/terms-and-conditions/"
                    target="blank"
                  >
                    terms & conditions
                  </a>
                </div>
                {/* TNC error message */}
              </div>
              {errors.tnc && !tncChecked && (
                <div className={styles.errorMessage}>{errors.tnc.message}</div>
              )}
            </div>
            {/* Check box condition */}

            {/* Signup button */}

            <div className={`${styles.formField} ${styles.signupMargin} `}>
              <Button
                label={LOGIN_PAGE_STRINGS.SIGNUP}
                large={true}
                handleClick={handleSubmit(onSubmit)}
              />
            </div>

            {/* Sign up button */}
            {/* <div className={styles.alternateText}>or continue with</div>
            <div className={styles.alternateSignIn}>
              <AccountButton
                buttonIcon={googleLogo}
                buttonLabel="Google"
              ></AccountButton>
              <AccountButton
                buttonIcon={appleLogo}
                buttonLabel="Apple"
              ></AccountButton>
            </div> */}

            <div className={styles.signup__signin}>
              {LOGIN_PAGE_STRINGS.ALREADY_USER}
              <span
                className={styles.link_primary}
                onClick={() => {
                  navigate(routeConfig.login);
                }}
              >
                {LOGIN_PAGE_STRINGS.SIGNIN}
              </span>
            </div>
          </form>
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status == "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

const SignUpForm = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [userType, setUserType] = useState("buyer");
  const handleSwitch = (reset: any) => {
    reset();
    setChecked(!checked);
    dispatch(setUserTypeGlobal(userType));
  };
  useEffect(() => {
    setUserType(checked === true ? "supplier" : "buyer");
  }, [userType, checked]);
  useEffect(() => {
    localStorage.setItem("userType", userType);
  }, [userType, checked]);

  return (
    <LoginBackground
      userType={userType}
      form={
        <>
          <SignUp
            handleSwitch={handleSwitch}
            checked={checked}
            setChecked={setChecked}
          />
        </>
      }
    ></LoginBackground>
  );
};

export default SignUpForm;
