import React, { useState } from "react";
import styles from "./ProductsGroupBySupplier.module.scss";
import SupplierDetails from "./supplierDetails";
import CartProduct from "./cartProduct";
import InputField from "../../../../../common/components/formFields/inputField/inputField";
const ProductsGroupBySupplier = ({ supplierData }: { supplierData: any }) => {
  const [notesVal, setNotesVal] = useState("");
const {products} = supplierData
  return (
    <div className={styles.productsGroupBySupplierWrapper}>
      <SupplierDetails
        supplierDetails={supplierData?.supplier}
        wareHouseDetails={supplierData?.ware_house}
        amountDetails={supplierData?.amounts}
        productsDetails={supplierData?.products}
      />
      <div className={styles.allSupplierCartProducts}>
        {products.map((product:any) => {
          return  <CartProduct productDetails={product} key={product?.id} />
        })}
      </div>
      <InputField
        value={notesVal}
        onChange={setNotesVal}
        placeholder="Enter here..."
        textAreaClass={styles.productNotes}
        inputWrapClass={styles.productNotes}
        label="Notes (optional)"
        isTextarea
      />
    </div>
  );
};

export default ProductsGroupBySupplier;
