import { createSlice } from "@reduxjs/toolkit";

interface IInitialState {
  firstForm: {
    productName?: string;
    productBrand?: string;
    productCategory?: string;
    productSubCategory?: string;
    productSection?: string;
    productCountryOrigin?: string;
    supplierProductCode?: string;
    productDescription?: string;
    productTags?: string;
    productImages?: string[];
    HS?: string;
  };
  secondTopForm: {
    addedProductDetailsWithDiscount: [];
    orderingOption?: {
      name?: string;
    };
    equalto?: {
      name?: string;
    };
  };
  secondBottomForm: {};
}
const initialState: IInitialState = {
  firstForm: {},
  secondTopForm: {
    addedProductDetailsWithDiscount: [],
    orderingOption: {},
    equalto: {},
  },
  secondBottomForm: {},
};

const addIndividualProductFormSlice = createSlice({
  name: "addIndividualProductForm",
  initialState,
  reducers: {
    setFirstForm: (state, action) => {
      return {
        ...state,
        firstForm: {
          ...state.firstForm,
          ...action.payload,
        },
      };
    },
    setSecondTopForm: (state, action) => {
      return {
        ...state,
        secondTopForm: {
          ...state.secondTopForm,
          ...action.payload,
        },
      };
    },
    setSecondBottomForm: (state, action) => {
      return {
        ...state,
        secondBottomForm: {
          ...state.secondBottomForm,
          ...action.payload,
        },
      };
    },
    resetWholeForm:() =>{
      return initialState;
    }
  },
});

export const { setFirstForm, setSecondTopForm, setSecondBottomForm, resetWholeForm } =
  addIndividualProductFormSlice.actions;

export default addIndividualProductFormSlice.reducer;
