import styles from './ReferAFriend.module.scss';
import React, { useState } from 'react';
import friendsIcon from '../../../../assets/images/refer_a_friend.svg';
import InputField from '../../../../common/components/formFields/inputField/inputField';
import MobileNumberField from '../../../../common/components/formFields/mobileNumberField/mobileNumberField';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioButton from '../../../../common/components/radio/RadioButton';
import { Controller } from 'react-hook-form';
import blackCross from '../../../../assets/images/x-circle.svg';
import lowerDesign from '../../../../assets/images/lowerDesign.svg';
import upperDesign from '../../../../assets/images/UpperDesign.svg';
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import useFetchCountries from "../../../../services/useCountry";
import { setReferAFriendState } from '../../../../reducerSlices/referAFriendSlice';
import { useDispatch } from 'react-redux';

const ReferAFriend = () => {

    const schema = yup.object({
        friendName: yup.string().required("Enter your friend's name"),
        phoneNo: yup.number().required("Enter your friend's Phone number").typeError("Enter a phone number"),
        email: yup.string().required("Enter your friend's email"),
        userType: yup.string().required("Select user type"),
        countryName: yup.object().required("Select country"),
    })

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        // getValues,
        // reset,
        // watch,
        control
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { countriesList } = useFetchCountries();
    const dispatch = useDispatch();
    const [mobileNumberCode, setMobileNumberCode] = useState<any>();
    const onChangeField = React.useCallback(
        (name: any) => (text: any) => {
            setValue(name, text);
        },
        [setValue]
    );

    const formSubmit = (data: any) => {
        handleClose()
    }


    const handleUserType = (newValue: "supplier" | "buyer") => {
            setValue("userType", newValue)
    }

    const handleClose = () =>{
        dispatch(setReferAFriendState(false))
    }

    return (
        <>
            <div className={styles.referAFriendParent}>

                <div className={styles.container}>
                    <img src={lowerDesign} alt="lowerDesign" className={styles.lowerDesign}></img>
                    <img src={upperDesign} alt="upperDesign" className={styles.upperDesign} />
                    <div className={styles.header}>
                        <div>
                            <img src={friendsIcon} alt="friendsIcon" />
                        </div>
                        <div className=''>
                            <div className={styles.heading}>Refer a friend!</div>
                            <div className={styles.subHeading} >Receive a delightful AED 100.00 gift voucher for your subsequent Watermelon purchase on the marketplace every time you refer a friend.</div>
                        </div>
                    </div>
                    <div className={styles.formContainer}>
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <div className={styles.formRow}>
                                <div className={styles.InputFieldContainer}>
                                    <InputField
                                        maxWidth="100%"
                                        minWidth="250px"
                                        id="friendName"
                                        label="Your friend’s name"
                                        placeholder="Enter your friend's name"
                                        onChange={onChangeField("friendName")}
                                        register={register("friendName")}
                                        errorMessage={
                                            errors.friendName?.message &&
                                                errors.friendName?.message.length > 60
                                                ? `${errors.friendName?.message.slice(
                                                    0,
                                                    60
                                                )}...`
                                                : errors.friendName?.message
                                        }
                                    />
                                </div>
                                <div className={styles.InputFieldContainer}>
                                    <MobileNumberField
                                        label="Your friend’s contact number"
                                        id={"phoneNo"}
                                        width="100%"
                                        type="number"
                                        defaultValue={mobileNumberCode ? mobileNumberCode : countriesList[0]}
                                        options={countriesList}
                                        registerMobileNumber={register("phoneNo")}
                                        onMobileNumberChange={onChangeField("phnNo")}
                                        registerCountry={register("countryName")}
                                        onCountryChange={(selectedOption: ICountry) => {
                                            onChangeField("countryName")(selectedOption);
                                            setMobileNumberCode(selectedOption);
                                        }}
                                        errorMessage={errors.phoneNo?.message}
                                        mobileNumberField={true}
                                    />
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.InputFieldContainer}>
                                    <InputField
                                        maxWidth="100%"
                                        minWidth="250px"
                                        id="email"
                                        label="Your friend’s email"
                                        placeholder="Enter your friend’s email"
                                        onChange={onChangeField("email")}
                                        register={register("email")}
                                        errorMessage={
                                            errors.email?.message &&
                                                errors.email?.message.length > 60
                                                ? `${errors.email?.message.slice(
                                                    0,
                                                    60
                                                )}...`
                                                : errors.email?.message
                                        }
                                    />
                                </div>
                                <div className={styles.InputFieldContainer}>
                                    <div className={styles.ButtonFieldLabel}>
                                        Your friend is interested to be a ?
                                    </div>
                                    <div className={styles.buttonsConatiner}>
                                        <Controller
                                            name="userType"
                                            control={control}
                                            defaultValue="cod"
                                            rules={{ required: "Enter the user type" }}
                                            render={({ field }) => (
                                                <>
                                                    <div className={styles.radioButton}>
                                                        <RadioButton
                                                            checked={field.value === "buyer"}
                                                            handleChange={(e) => {
                                                                field.onChange(e.target.value);
                                                                handleUserType("buyer");
                                                            }}
                                                            value="buyer"
                                                            name="userType"
                                                        />
                                                        <label
                                                            htmlFor="cod"
                                                            onClick={() => {
                                                                field.onChange("cod");
                                                                handleUserType("buyer");
                                                            }}
                                                        >
                                                            Buyer
                                                        </label>
                                                    </div>
                                                    <div className={styles.radioButton}>
                                                        <RadioButton
                                                            checked={field.value === "supplier"}
                                                            handleChange={(e) => {
                                                                field.onChange(e.target.value);
                                                                handleUserType("supplier");
                                                            }}
                                                            value="supplier"
                                                            name="userType"
                                                        />
                                                        <label
                                                            htmlFor="supplier"
                                                            onClick={() => {
                                                                field.onChange("supplier");
                                                                handleUserType("supplier");
                                                            }}
                                                        >
                                                            Supplier
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        
                                    </div>
                                    {true && (
                                            <p className={styles.errorMessage}>{errors?.userType?.message}</p>
                                        )}
                                </div>
                            </div>
                            <div className={styles.submitButton}>
                                <button type="submit" className={styles.submit}>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={styles.close} onClick={handleClose}>
                        <img src={blackCross} alt="close" />
                    </div>
                </div >
            </div >
        </>
    )
}
export default ReferAFriend;