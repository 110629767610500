import styles from './CatalogueInfo.module.scss'

const CatalogueInfo = ({item, filledTab} : any) => {
    return (
        <div className={styles.info}>
            <div className={styles.generalFrame}>
                <div className={`${item ? styles.generalicon : styles.priceIcon} ${filledTab && styles.priceIcon1}`}>1</div>
                <div className={`${item ? styles.generalText : styles.priceText} ${filledTab && styles.priceText1}`}>General information</div>
            </div>
            <div className={styles.infoLine}></div>
            <div className={styles.priceFrame}>
                <div className={`${!filledTab ? styles.priceIcon : styles.generalicon}`}>2</div>
                <div className={`${!filledTab ? styles.priceText : styles.generalText}`}>Pricing & certification</div>
            </div>
        </div>
    )
}

export default CatalogueInfo
