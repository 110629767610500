/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import Button from "../../../../common/components/button/Button";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import LoginBackground from "../../../../components/login/LoginBackground";
import styles from "./ResetPassword.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { constants } from "../../../../common/constants/constants";
import waringPass from "../../../../assets/images/warning.svg";
import checkGreenPass from "../../../../assets/images/checkgreen.svg";
import { SideDrawer } from "../../../../common/components/SideDrawer/SideDrawer";
import { ResetAccount } from "../resetCompleted/ResetCompleted";
import { IResetPasswordFormData } from "../../../../models/IResetPasswordFormData";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  // loginRoutes,
  resetMyProfile,
} from "../../../../common/constants/urlConstants";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import CryptoJS from "crypto-js";

export const ResetPasswordComp = ({ ...props }) => {
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = props?.isLoggedIn;
  const [completedReset, setCompletedReset] = React.useState(false);
  const [isResetCompleted, setResetCompleted] = React.useState({
    right: true,
  });
  const [checkPassConditions, setCheckConditions] = useState([
    {
      id: 1,
      heading: "Between 08 to 14 character",
      check: false,
    },
    {
      id: 2,
      heading: "An uppercase character",
      check: false,
    },
    {
      id: 3,
      heading: "A lowercase character",
      check: false,
    },
    {
      id: 4,
      heading: "A number",
      check: false,
    },
    {
      id: 5,
      heading: "A special character",
      check: false,
    },
  ]);

  const location = useLocation();
  const resetData = location.search.split("?");

  useEffect(() => {
    if (resetData[1] == "expired") {
      setToastData({ status: "Error", message: "Reset Password link is expired." });
      setTimeout(() => {
        navigate(routeConfig.signin)
      }, 5000);
    }
  }, [])

  const schema = yup.object().shape(
    isLoggedIn
      ? {
        currentPassword: yup.string().required("Enter password"),
        // .matches(
        //   constants.formaValidations.PASSWORD_REGEX,
        //   "Invalid password format"
        // ),
        password: yup
          .string()
          .required("Enter password")
          .matches(
            constants.formaValidations.PASSWORD_REGEX,
            "Invalid password format"
          ),
        confirmPassword: yup
          .string()
          .required("Enter confirm password")
          .matches(
            constants.formaValidations.PASSWORD_REGEX,
            "Invalid password format"
          )
          .oneOf([yup.ref("password"), ""], "Passwords must match"), // Check if confirmPassword matches password
      }
      : {
        currentPassword: yup.string(),
        // .matches(
        //   constants.formaValidations.PASSWORD_REGEX,
        //   "Invalid password format"
        // ),
        password: yup
          .string()
          .required("Enter password")
          .matches(
            constants.formaValidations.PASSWORD_REGEX,
            "Invalid password format"
          ),
        confirmPassword: yup
          .string()
          .required("Enter confirm password")
          .matches(
            constants.formaValidations.PASSWORD_REGEX,
            "Invalid password format"
          )
          .oneOf([yup.ref("password"), ""], "Passwords must match"), // Check if confirmPassword matches password
      }
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      const newPassword = text;
      const updatedConditions = checkPassConditions.map((condition) => {
        switch (condition.id) {
          case 1:
            return {
              ...condition,
              check: newPassword.length >= 8 && newPassword.length <= 14,
            };
          case 2:
            return { ...condition, check: /[A-Z]/.test(newPassword) };
          case 3:
            return { ...condition, check: /[a-z]/.test(newPassword) };
          case 4:
            return { ...condition, check: /[0-9]/.test(newPassword) };
          case 5:
            return {
              ...condition,
              check: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword),
            };
          default:
            return condition;
        }
      });

      setCheckConditions(updatedConditions);
    },
    [setValue, checkPassConditions]
  );



  const resetPasswordAPI = useAxios({
    axiosParams: {
      url: resetMyProfile.updatePassword,
      data: {
        // email: resetData[1],
        token: resetData[1],
        password: generateHashedPassword(watch("password")), //watch("password"),
      },
    },
    method: APIMethods.post,
  });

  let profileData: any;
  if (isLoggedIn) {
    profileData = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
  }

  function generateHashedPassword(password: any) {
    const secretKey = process.env.REACT_APP_PASSWORD_HASHING_SECRET_KEY ?? '';
    const iv = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_PASSWORD_HASHING_IV_SECRET_KEY ?? ''
    );
    const encryptedString = CryptoJS.AES.encrypt(password, secretKey, {
      iv: iv,
    });
    return encryptedString.toString();
  }
  
  const updatePasswordAPI = useAxios({
    axiosParams: {
      url: resetMyProfile.updatePassword,
      data: {
        customer_id: profileData?.id,
        password: generateHashedPassword(watch("password")), //watch("password"),
        current_password: generateHashedPassword(watch("currentPassword")), // watch("currentPassword"),
      },
    },
    method: APIMethods.post,
  });
  const onSubmit = async (data: IResetPasswordFormData) => {
    if (resetData) {
      dispatch(updateIsLoading(true));
      if (!isLoggedIn) {
        const response: any = await resetPasswordAPI.fetchData();
        if (response && response?.status === "SUCCESS") {
          if (isLoggedIn) {
            setCompletedReset(true);
          } else {
            navigate(routeConfig.resetCompleted);
            // Handle your form submission here
          }
          dispatch(updateIsLoading(false));
        } else {
          setToastData({
            message: response?.response?.data?.error_msg
              ? response?.response?.data?.error_msg
              : "Unable to reset password.",
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
          dispatch(updateIsLoading(false));
        }
      } else if (profileData) {
        const response: any = await updatePasswordAPI.fetchData();
        if (response?.status === "SUCCESS") {
          if (isLoggedIn) {
            setCompletedReset(true);
          } else {
            navigate(routeConfig.resetCompleted);
          }
          dispatch(updateIsLoading(false));
        } else {
          setToastData({
            message: response?.response?.data?.error_msg
              ? response?.response?.data?.error_msg
              : "Unable to reset password.",
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
          dispatch(updateIsLoading(false));
        }
      }
    }
  };

  return (
    <section
      className={`${styles.resetPassword} ${isLoggedIn && styles.changePswd}`}
    >
      <div className={styles.resetPassword__header}>
        {!isLoggedIn ? (
          <div className={styles.headerText}>
            {LOGIN_PAGE_STRINGS.RESET_PWD_LABEL}
          </div>
        ) : null}
        <div className={styles.subheaderText}>
          {isLoggedIn
            ? LOGIN_PAGE_STRINGS.RESET_PWD_CHANGE_TEXT
            : LOGIN_PAGE_STRINGS.RESET_PWD_TEXT}
        </div>
      </div>
      <div className={styles.passwordCheck}>
        <div className={styles.mainHeading}>
          {LOGIN_PAGE_STRINGS.PASSWORD_MUST_CONTAIN}
        </div>
        <div className={styles.checkConditions}>
          {checkPassConditions &&
            checkPassConditions.map((item: any) => (
              <div className={styles.conditionBox}>
                {!watch("confirmPassword") && !watch("password") ? (
                  <div className={styles.default}></div>
                ) : (
                  <img
                    src={item.check === true ? checkGreenPass : waringPass}
                    alt="check"
                  />
                )}
                <div className={styles.condtionHeading}>{item.heading}</div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.resetPassword__form}>
        <form>
          {isLoggedIn ? (
            <div
              className={`${styles.password} ${styles.formField} ${styles.currentPassword}`}
            >
              <div className={styles.formFieldItem}>
                <div className={styles.label}>
                  {LOGIN_PAGE_STRINGS.CURRENT_PASSWORD}
                </div>
                <div className="">
                  <InputField
                    placeholder={
                      LOGIN_PAGE_STRINGS.CURRENT_PASSWORD_PLACEHOLDER
                    }
                    type="password"
                    onChange={onChangeField("currentPassword")}
                    register={register("currentPassword")}
                    errorMessage={errors.currentPassword?.message}
                    autoComplete={false}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className={`${styles.password} ${styles.formField}`}>
            <div className={styles.formFieldItem}>
              <div className={styles.label}>
                {isLoggedIn
                  ? LOGIN_PAGE_STRINGS.NEW_PASSWORD
                  : LOGIN_PAGE_STRINGS.PASSWORD}
              </div>
              <div className="">
                <InputField
                  placeholder={LOGIN_PAGE_STRINGS.NEW_PASSWORD_PLACEHOLDER}
                  type="password"
                  onChange={onChangeField("password")}
                  register={register("password")}
                  errorMessage={errors.password?.message}
                  autoComplete={false}
                />
              </div>
            </div>
          </div>

          <div
            className={`${isLoggedIn ? styles.currentPassword : styles.confirmPassword
              } ${styles.formField}`}
          >
            <div className={styles.formFieldItem}>
              <div className={styles.label}>
                {LOGIN_PAGE_STRINGS.CONFIRM_PASSWORD}
              </div>
              <div className="">
                <InputField
                  placeholder={LOGIN_PAGE_STRINGS.CONFIRM_PASSWORD_PLACEHOLDER}
                  type="password"
                  onChange={onChangeField("confirmPassword")}
                  register={register("confirmPassword")}
                  errorMessage={errors.confirmPassword?.message}
                  autoComplete={false}
                />
              </div>
            </div>
          </div>
        </form>

        <div className={styles.resetPasswordButton}>
          <Button
            label={LOGIN_PAGE_STRINGS.RESET_PASSWORD}
            large={true}
            handleClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      {completedReset && (
        <SideDrawer
          isdrawer={isResetCompleted}
          cancelButton
          hideBackground
          setIsDrawer={setResetCompleted}
          heading={"Change login password"}
          childComponent={<ResetAccount isLoggedIn={true} />} //add form in childComponent
        />
      )}
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </section>
  );
};
export const ResetPassword = () => {
  return (
    <LoginBackground
      form={
        <>
          <ResetPasswordComp />
        </>
      }
    ></LoginBackground>
  );
};
