import styles from "./SupplierPreferences.module.scss";
import {
  constants,
  preferencesConstants,
  supplierPreferenceEndpoints,
} from "../../../common/constants/constants";
import UsersCard from "./Users/UsersCard";
import { useAxios } from "../../../services/useAxios";
import { useEffect, useState } from "react";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { useDispatch } from "react-redux";
import EditUser from "./EditUser/EditUser";
import PopUp from "../../../common/components/PopUp/PopUp";
import Button from "../../../common/components/button/Button";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";

const SupplierPreferences = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState<any>([]);
  const [hovered, setHovered] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editOutletIndex, setEditOutletIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [supplierId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );
  const [storeId] = useState(
    loggedInUser?.business_details?.[0]?.business_store_id
  );
  const [customerId] = useState(loggedInUser?.business_details?.[0]?.customer_id);
  const [isSuperUser] = useState(loggedInUser?.is_super_user);
  const [loggedInRole] = useState(loggedInUser?.business_details?.[0]?.role_key);

  //Get users list
  const getList = useAxios({
    axiosParams: {
      url: supplierPreferenceEndpoints.usersList.replace(
        ":supplier_id",
        supplierId
      ),
    },
    method: "GET",
  });

  function customSort(a: any, b: any) {
    const roleOrder: any = {
      "supplier-admin": 1,
      "supplier-manager": 2,
      "supplier-staff": 3,
    };
    const roleA = roleOrder[a.role_key];
    const roleB = roleOrder[b.role_key];

    if (roleA !== roleB) {
      return roleA - roleB;
    }
    if (a.role_key === "supplier-admin") {
      if (a.is_super_user !== b.is_super_user) {
        return b.is_super_user - a.is_super_user;
      }
      return a.first_name;
    }
    return a.first_name;
  }

  const fetchUsers = async () => {
    try {
      dispatch(updateIsLoading(true));
      let response: any = await getList.fetchData();
      response?.data?.sort(customSort);
      setUsers(response?.data);
      dispatch(updateIsLoading(false));
    } catch (error) {
      dispatch(updateIsLoading(false));
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  //Delete user
  const deleteUserAPI = useAxios({
    axiosParams: {
      url: supplierPreferenceEndpoints.updateUser
        .replace(":supplier_id", supplierId)
        .replace(":customer_id", deleteId),
      data: {
        updated_customer_id: customerId,
      },
    },
    method: "DELETE",
  });

  const handleMouseOver = (id: string) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered("");
  };
  const editOutlet = (index: number) => {
    setEditOutletIndex(index);
    setShowModal(true);
  };
  const deleteUser = (index: number) => {
    setDeleteId(users[index]?.customer_id);
    setShowDeletePopup(true);
  };
  const deleteYes = async () => {
    setShowDeletePopup(false);
    dispatch(updateIsLoading(true));
    const deleteResponse: any = await deleteUserAPI.fetchData();
    if (deleteResponse?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      setToastData({
        message: "User deleted successfully",
        status: "Success",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
      fetchUsers();
      // getCurrentBussinessSetupData();
    } else {
      dispatch(updateIsLoading(false));
      setToastData({
        message: deleteResponse?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
    }
  };
  const deleteNo = () => {
    setShowDeletePopup(false);
    setDeleteId("");
  };

  const handleClose = () => {
    setEditOutletIndex(-1);
    setShowModal(false);
  };

  return (
    <>
      <section className={styles.preferencesContainer}>
        <div className={styles.header}>{preferencesConstants.headerText}</div>
        <div className={styles.each}>
          <div className={styles.accHeader}>
            {preferencesConstants.usersHeader}
          </div>
          <div className={styles.outletSection}>
            {/* <div className={styles.label}>
              {preferencesConstants.outletText}
            </div> */}
            <div className={styles.outlets}>
              {users?.length > 0 &&
                users?.map((user: any, index: number) => {
                  return (
                    <UsersCard
                      key={user?.customer_id}
                      userData={user}
                      handleMouseOver={handleMouseOver}
                      handleMouseLeave={handleMouseLeave}
                      isHovered={hovered === user?.customer_id}
                      editOutlet={() => editOutlet(index)}
                      deleteUser={() => deleteUser(index)}
                      isSuperUser={isSuperUser}
                      loggedInRole={loggedInRole}
                    />
                  );
                })}
            </div>
            {users?.length > 0 && !loggedInRole?.includes("staff") && (
              <div>
                <button
                  className={styles.addUser}
                  onClick={() => setShowModal(true)}
                >
                  Add user
                </button>
              </div>
            )}
          </div>
        </div>
        {showDeletePopup && (
          <PopUp
            open={showDeletePopup}
            setOpen={true}
            handleClose={() => setShowDeletePopup(false)}
            title={preferencesConstants.deleteUserHeader}
          >
            <div className={styles.deleteConfirmContainer}>
              <p>{preferencesConstants.confirmDelete}</p>
              <div className={styles.action3}>
                <div>
                  <Button handleClick={deleteNo} secondary label="cancel" />
                  <Button handleClick={deleteYes} label="confirm" />
                </div>
              </div>
            </div>
          </PopUp>
        )}
      </section>
      <EditUser
        showAddOutlet={showModal}
        handleClose={handleClose}
        editData={editOutletIndex != -1 && users[editOutletIndex]}
        supplierId={supplierId}
        storeId={storeId}
        fetchUsers={fetchUsers}
        customerId={customerId}
      />
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default SupplierPreferences;
