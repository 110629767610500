import { createSlice } from "@reduxjs/toolkit";
import fruit from "../assets/images/fruit.svg";

const initialState = {
  cardData: [
    {
      id: 0,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "PASSWORD",
      confirmPassword: "CONFIRMPASSWORD",
      userType: "Supplier",
    },
    {
      id: 1,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 2,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 3,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 4,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 5,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 6,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 7,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
    {
      id: 8,
      businessName: "Sample Business",
      emailID: "sample@example.com",
      licenseNo: 12345,
      contactName: "John Doe",
      phNo: 1234567890,
      countryName: "Sample Country",
      paymentMethod: "cod",
      address: "123 Main Street",
      emirates: { label: "Sample Emirates", value: "Sample Emirates" },
      area: "Sample Area",
      country: {
        label: "Sample Country",
        value: "Sample Country",
      },
      licenseFile: {
        file: fruit,
        value: fruit,
      },
      logo: {
        file: fruit,
        value: fruit,
      },
      contract: {
        file: fruit,
        value: fruit,
      },
      iban: "SampleIBAN123",
      password: "1234",
      confirmPassword: "1234",
      userType: "Supplier",
    },
  ],
};
const adminSupplierListSlice = createSlice({
  name: "adminSupplierList",
  initialState: initialState,

  reducers: {
    updateSupplier: (state: any, action) => {
      const { id, newData } = action.payload;

      const supplierIndex = state.cardData.findIndex(
        (supplier: any) => supplier.id === id
      );

      if (supplierIndex !== -1) {
        state.cardData = state.cardData.map((supplier: any) => {
          if (supplier.id === id) {
            return {
              ...supplier,
              ...newData,
              emirates: { ...supplier.emirates, ...newData.emirates },
              country: { ...supplier.country, ...newData.country },
            };
          }
          return supplier;
        });
      } else {
        state.cardData.push({
          id: Math.floor(Math.random() * 100),
          ...newData,
          emirates: { ...newData.emirates },
          country: { ...newData.country },
        });
      }
    },
  },
});

export const { updateSupplier } = adminSupplierListSlice.actions;

export default adminSupplierListSlice.reducer;
