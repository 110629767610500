import { createSlice } from "@reduxjs/toolkit";
// import logo from "../assets/images/dfLogo.png";
const accountDetails = {
    id: null,
    logo: null,
    businessName: null,
    primaryContactName: null,
    emailId: null,
    contactNo: null,
    countryCode: null,
    address: null,
    area: null,
    city: null,
    emirate: null,
    country: null,
    iban: null,
    MOV: null,
    tradeLicenseNo: null,
    tradeLicenseCertificate: null,
    reportNO: null,
    pestControlCertification: null,
    signedContract: null,
  };
const accountDetailsSlice = createSlice({
    name: "account_details",
    initialState: accountDetails,
    reducers: {
        setMov: (state, action) => {
           state.MOV=action.payload;
        },
        setPestCertificate: (state, action) => {
            state.pestControlCertification = action.payload;
        },
        setSignedContract: (state, action) => {
            state.signedContract = action.payload;
        },
        setCertificationID:(state, action) => {
            state.tradeLicenseNo = action.payload;
            state.reportNO = action.payload;
        },
        }

});
export const {setMov, setPestCertificate,setSignedContract, setCertificationID} = accountDetailsSlice.actions;
export default accountDetailsSlice.reducer;