const checkDuplicateProductNameUtils = async (data: any) => {
  const titleSet = new Set();
  for (let i = 0; i < data.length; i++) {
    const titleValue = data[i]?.[0];
    if (!titleValue?.length) {
      let res = { status: false, emptyIndex: i + 1 };
      return res;
    }
    // else if (titleSet.has(titleValue)) {
    //   // Duplicate found, return the index of the first duplicate
    //   const duplicateIndex = data.findIndex((item: any) => item?.[0] === titleValue);
    //   if(duplicateIndex){
    //       let res = {status: false, duplicateIndex: i+1}
    //       return res
    //   }
    // }
    titleSet.add(titleValue);
  }
  let res = { status: true };
  return res;
};

const errorRowUtils = (rowData: any, index: any) => {
  // console.log(rowData, "ROWDATAAAAAA");
  let errorState = false;
  // varCheck(rowData);
  let productOptionsData: any = [];
  for (const property in rowData) {
    //Should be romoved once the images upload completed
    // if (property?.includes("image")) {
    //   return false;
    // }
    if (
      property === "title" ||
      // property === "tags" ||   //made the tags field optional
      property === "category" ||
      property === "sub_category" ||
      property === "sub_sub_category" ||
      property === "country" ||
      property === "description" ||
      property === "image_1"
    ) {
      if (!rowData[property].value || rowData[property].value === null) {
        return true;
      }
    }
  }

  for (let i = 1; `unit_price_${i}` in rowData; i++) {
    const productOptionValues: any = [
      rowData[`unit_price_${i}`].value,
      rowData[`ordering_option_label_${i}`].value,
      rowData[`ordering_option_value_${i}`].value,

      rowData[`secondary_ordering_option_value_${i}`].value,

      rowData[`secondary_ordering_option_label_${i}`].value,
      rowData[`min_quantity_${i}`].value,
    ];
    const optionsHavingNoValue = productOptionValues.filter(
      (el: any) => !Boolean(el) && true
    );
    if (optionsHavingNoValue.length > 0 && optionsHavingNoValue.length !== 6) {
      errorState = true;
    }

    productOptionsData = [...productOptionsData, ...productOptionValues];
  }

  if (errorState) {
    return true;
  }
  if (productOptionsData.every((el: any) => !Boolean(el))) {
    return true;
  }
};

const validExcelUtils = (headers: any) => {
  let reqHeaders = JSON.stringify([
    "title",
    "master_watermelon_product_code",
    "description",
    "brand",
    "certifications",
    "specifications",
    "tags",
    "category",
    "sub_category",
    "sub_sub_category",
    "country",
    "supplier_product_code",
    "hs_code",
    "is_vat_included",
    "unit_price_1",
    "min_quantity_1",
    "ordering_option_value_1",
    "ordering_option_label_1",
    "seconday_ordering_option_value_1",
    "seconday_ordering_option_label_1",
    "offer_1",
    "unit_price_2",
    "min_quantity_2",
    "ordering_option_value_2",
    "ordering_option_label_2",
    "seconday_ordering_option_value_2",
    "seconday_ordering_option_label_2",
    "offer_2",
    "unit_price_3",
    "min_quantity_3",
    "ordering_option_value_3",
    "ordering_option_label_3",
    "seconday_ordering_option_value_3",
    "seconday_ordering_option_label_3",
    "offer_3",
    "unit_price_4",
    "min_quantity_4",
    "ordering_option_value_4",
    "ordering_option_label_4",
    "seconday_ordering_option_value_4",
    "seconday_ordering_option_label_4",
    "offer_4",
    "unit_price_5",
    "min_quantity_5",
    "ordering_option_value_5",
    "ordering_option_label_5",
    "seconday_ordering_option_value_5",
    "seconday_ordering_option_label_5",
    "offer_5",
  ]);
  let headersToCheck = JSON.stringify(headers);
  return reqHeaders === headersToCheck;
};

function isAttributePresentInCurrentCategory(
  attributeName: string,
  currentVariant: any
) {
  const combinedAttributesList = [
    ...(currentVariant.category?.category_attributes ||
      currentVariant.category?.category?.category_attributes ||
      []),
    ...(currentVariant.sub_category?.sub_category_attributes ||
      currentVariant.sub_category?.subCategory?.sub_category_attributes ||
      []),
    ...(currentVariant.sub_sub_category?.sub_sub_category_attributes ||
      currentVariant.sub_sub_category?.subSubCategory
        ?.sub_sub_category_attributes ||
      []),
  ].map((attr: any) => attr?.name?.toLowerCase().replace(/\s+/g, ""));
  if (!combinedAttributesList.includes(attributeName)) {
    return true;
  }
}



export { errorRowUtils, checkDuplicateProductNameUtils, validExcelUtils, isAttributePresentInCurrentCategory };
