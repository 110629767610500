import React from "react";
import styles from "./allReview.module.scss";
// import closeIcon from "../../../../assets/images/close.svg";
import StarIcon from "./rating/Star";
// import EachRatingOverview from "./eachRatingOverview";
import RatingWithComment from "./ratingWithComment";

const AllReviews = ({overlayCallback, reviewData}: any) => {
  const rating = Number(reviewData?.rating);
  const customerReviews = reviewData?.data?.map((each:any)=>({
    profileName: each?.first_name + " " + each?.last_name,
    rating: each?.user_rating,
    reviewText: each?.comments,
    profileImg: each?.images,
  }))
  return (
    <div className={styles.allReviewsWrapper}>
      {/* <div className={styles.drawerHeader}>
        <p className={styles.drawerHeaderText}>All Reviews</p>
        <button className={styles.drawerCloseBtn} onClick={()=>overlayCallback({ right: false })}>
          <img src={closeIcon} alt="close" />
        </button>
      </div> */}
      <div className={styles.reviewsWrapper}>
        <div className={styles.totalReviewsContainer}>
          <p className={styles.totalReviewsText}>
            <span className={styles.colored}>{Number(reviewData?.rating).toFixed(1)}</span>/5{" "}
            <span className={styles.headerSubText}>Based on {Number(reviewData?.total_count)} ratings</span>
          </p>
          <div className={styles.reviewStarsWrapper}>
            {new Array(rating).fill("").map((el, i) => {
              return <StarIcon key={i} noStroke filled />;
            })}
            {rating < 5 &&
              new Array(5 - rating).fill("").map((el, i) => {
                return <StarIcon key={i} />;
              })}
          </div>
        </div>
        {/* <div className={styles.ratingDataWrapper}>
          <EachRatingOverview
            ratingNumber="5"
            totalRatings="100"
            received="39"
          />
          <EachRatingOverview
            ratingNumber="4"
            totalRatings="100"
            received="17"
          />
          <EachRatingOverview
            ratingNumber="3"
            totalRatings="100"
            received="5"
          />
          <EachRatingOverview
            ratingNumber="2"
            totalRatings="100"
            received="3"
          />
          <EachRatingOverview
            ratingNumber="1"
            totalRatings="100"
            received="4"
          />
        </div> */}
        <div className={styles.allReviewCommentsWrapper}>
        {
          customerReviews?.map((each: any)=>
          <RatingWithComment
          profileImg={each?.profileImg}
          reviewText={each?.reviewText}
          rating={each?.rating}
          profileName={each?.profileName}
        />)
        }
        </div>
      </div>
    </div>
  );
};

export default AllReviews;
