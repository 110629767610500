import React from "react";
import styles from "./selectAddress.module.scss";
import RadioButton from "../../../../../common/components/radio/RadioButton";
import editIcon from "../../../../../assets/images/editIcon.svg";
import deleteIcon from "../../../../../assets/images/deleteIcon.svg";

type SelectAddressProps = {
  addressDetails: any;
  handleMouseOver: (id: string | number) => void;
  handleMouseLeave: () => void;
  isHoverd: boolean;
  handleSelect?: (id: string | number) => void;
  selected?: string;
  index: string | number;
  handleEdit?: (id: string | number) => void;
  handleDelete?: (id: string | number, index: string | number) => void;
  isEdit?: boolean;
};
const SelectAddress = ({
  addressDetails,
  handleMouseOver,
  handleMouseLeave,
  handleSelect = () => {},
  isHoverd,
  selected,
  handleEdit = () => {},
  handleDelete = () => {},
  index,
  isEdit,
}: SelectAddressProps) => {
  const { address } = addressDetails;
  return (
    <div
      className={`${styles.selectAddressWrapper} ${
        isHoverd && isEdit && styles.addressHovered
      } ${selected === index && styles.addressSelected}`}
      onMouseEnter={() => {
        handleMouseOver(index);
      }}
      onClick={() => {
        handleSelect(index);
      }}
      onMouseLeave={handleMouseLeave}
    >
      {selected && (
        <RadioButton
          checked={selected === index}
          handleChange={(e) => {}}
          value={String(index)}
        />
      )}

      <div className={styles.textContainer}>
        <p className={styles.addressUserName}>
          {addressDetails?.saved_as}
          <span
            className={`${styles.badge} ${
              addressDetails.is_default && styles.defaultBadge
            }`}
          >
            {addressDetails.is_default ? "Default" : "Other"}
          </span>
        </p>

        <p className={styles.addressText}>
          {`${address?.address},${address?.area}, ${address?.emirate?.name}, ${address?.country?.name}.`}
        </p>
        <p className={styles.addressText}>
          Primary contact name: {addressDetails?.contact_name}
        </p>
        <p className={styles.addressText}>
          Contact: {addressDetails?.phone_number?.split("#").join("-")}
        </p>
      </div>
      {isHoverd && isEdit && (
        <div className={styles.addressActions}>
          <button
            type="button"
            className={styles.addressActionBtn}
            onClick={() => handleEdit(index)}
          >
            <img src={editIcon} alt="edit icon" />
            <div>Edit</div>
          </button>
          {!addressDetails?.is_default && (
            <button
              type="button"
              className={styles.addressActionBtn}
              onClick={() => handleDelete(addressDetails?.id, index)}
            >
              <img src={deleteIcon} alt="delete icon" />
              <div>Delete</div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectAddress;
