// import { useState } from "react";
import Button from "../../common/components/button/Button";
import ProductEllipsisDescription from "../../common/components/productEllipsisDescription/ProductEllipsisDescription";
import ProductQuantityPicker from "../../common/components/productQuantityPicker/ProductQuantityPicker";
import styles from "./ProductDetailsPage.module.scss";
import deliveryTruck from "../../assets/images/delivery-truck.svg";
import ProductReview from "../../common/components/productReview/ProductReview";
import ProductCarouselZoom from "../../common/components/productCarouselZoom/ProductCarouselZoom";
import { constants } from "../../common/constants/constants";
import { updateIsLoading } from "../../reducerSlices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useAxios } from "../../services/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateOfferValue,
  splitDecimalValue,
} from "../../utils/productUtils";
import { camelCaseKeys } from "../../reducerSlices/productDetailsSlice";
import CustomAccordion from "../../common/components/accordion/Accordion";
import { IProductAccordionItemProps } from "../../models/IProductAccordionItemProps";
import supplierLogoDefault from "../../assets/images/supplierLogoDefault.svg";
import AllReviews from "../buyer/marketPlace/AllReviews";
import { SideDrawer } from "../../common/components/SideDrawer/SideDrawer";
import arrowLeft from "../../assets/images/Arrow-left.svg";
import { ToastNotification } from "../../common/components/toastNotification/ToastNotification";
import success from "../../assets/images/successToast.svg";
import emptyImage from "../../assets/images/emptybox.svg";
import ProductQuantityButton from "../buyer/myCart/components/productQuantityButton";
import error from "../../assets/images/errorToast.svg";
import shareIcon from "../../assets/images/share.svg";
import { cloneDeep } from "lodash";
import {
  addProductToCart,
  deleteProductFromCart,
  updateQuantityOfProduct,
} from "../../services/myCart";
import { setCartValue } from "../../reducerSlices/headerSlice";
import { checkImageValid } from "../../utils/functions";
import { buyerEndpoints } from "../../common/constants/urlConstants";
const ProductDetailsPage = () => {
  const navigate = useNavigate();
  const [productDetailsData, setProductDetailsData] = useState<any>({
    productDetails: [],
    productInformationData: [],
    knowyourProductData: [],
    supplierData: [],
    variantsList: [],
    rating: null,
  });
  const [selectedVariant, setSelectedVariant] = useState<number>(0);
  const cartID = localStorage.getItem("cartId");
  // const cartValue = useSelector((state:any)=>state?.header?.cartValue);
  // Number(localStorage.getItem("cartCount"));
  const [toastMsg, setToastMsg] = useState({ icon: "", message: "" });
  const [prodQuantity, setProdQuantity] = useState<any>(0);
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { activeOutlet } = useSelector((state: any) => state);
  const [isdrawer, setIsDrawer] = useState({
    right: false,
  });
  let timeLeft = 0;
  const currentTime = new Date().getHours();
  timeLeft = 14 - currentTime;
  const getProductDetails = useAxios({
    axiosParams: {
      url: `${buyerEndpoints.getProductDetailsForBuyer
        .replace(":productId", productId ?? "")
        .replace(":customerBusinessId", activeOutlet?.id)}`,
    },
    method: "GET",
  });
  const getProductDetailsData = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductDetails.fetchData();
    if (response && response?.data) {
      let formattedImages;
      if (response?.data?.images) {
        let images = [];
        if (
          response?.data?.images?.find(
            (item: any) => item?.metadata?.default_image
          )
        ) {
          let defImage = response?.data?.images?.find(
            (item: any) => item?.metadata?.default_image
          );
          images = response?.data?.images?.filter(
            (item: any) => !item?.metadata?.default_image
          );
          images.unshift(defImage);
        } else {
          images = response?.data?.images;
        }
        let index = 0;
        for (const img of images) {
          const contents = await checkImageValid(img.url);
          if (!contents) {
            images[index].url = emptyImage;
          }
        }
        formattedImages = images?.map((image: any) => ({
          id: image.id,
          smallImageSrc: image.url,
          largeImageSrc: image.url,
        }));
      }
      let productDetails = {
        productName: response?.data?.title,
        origin: response?.data?.origin_country,
        brandName: response?.data?.brand?.name,
        offer: response?.data?.variants?.[0]?.metadata?.offer,
        productPrice: response?.data?.variants?.[0]?.unit_price,
        offerValue: calculateOfferValue(
          response?.data?.variants?.[0]?.unit_price,
          response?.data?.variants?.[0]?.metadata?.offer
        ),
        minQuantity: response?.data?.variants?.[0]?.min_quantity,
        productImages: formattedImages,
        createdAt: response?.data?.created_at,
        countryName: response?.data?.countries?.display_name,
        countryFlag: response?.data?.countries?.flag,
        isFavorite: response?.data?.is_favorite,
        outOfStock: !response?.data?.avaliabilty_status,
      };
      let productInformationData = {
        brandName: response?.data?.brand?.name,
        origin: response?.data?.country?.display_name,
        hs_code: response?.data?.hs_code,
        // weight: response?.data?.weight,
      };

      let knowyourProductData = {
        productData: response?.data?.description,
      };

      let supplierData = {
        supplierLogo: response?.data?.supplier?.profile_image,
        supplierName: response?.data?.supplier?.business_name ?? "-",
        manufacturerInfo: "-",
        address: "-",
      };
      let variantsList: any = response?.data?.variants;

      if (variantsList && variantsList?.length > 0) {
        variantsList = variantsList?.map((variant: any) => ({
          ...variant,
          orderingOptionValue: variant?.metadata?.ordering_option_value,
          quantity: `${variant?.metadata?.secondary_ordering_option_value} ${variant?.metadata?.secondary_ordering_option_label}`,
          amount: variant?.unit_price,
          offer: variant?.metadata?.offer,
          offer_value: calculateOfferValue(
            variant?.unit_price,
            variant?.metadata?.offer
          ),
  
        }));
      }
      let rating = response?.data?.rating;
      let proData = {
        productDetails: productDetails,
        productInformationData: productInformationData,
        knowyourProductData: knowyourProductData,
        supplierData: supplierData,
        variantsList: variantsList,
        rating: rating,
      };
      setProdQuantity(variantsList[0]?.match_quantity);
      setProductDetailsData(proData);
    }
    dispatch(updateIsLoading(false));
  }, [dispatch, getProductDetails]);

  useEffect(() => {
    if (productId && activeOutlet?.id) {
      getProductDetailsData();
    }
  }, [activeOutlet?.id]);
  const onVariantClick = (id: number) => {
    setSelectedVariant(id);
  };
  useEffect(() => {
    setProdQuantity(
      productDetailsData?.variantsList?.[selectedVariant]?.match
        ? productDetailsData?.variantsList?.[selectedVariant]?.match_quantity
        : 0
    );
  }, [selectedVariant]);
  const ProductAccordionItem = ({
    productKey,
    productDescription,
  }: IProductAccordionItemProps) => {
    return (
      <>
        <div className={styles.accordionItem}>
          <div className={styles.productKey}>{productKey}</div>
          <div className={styles.description}>{productDescription}</div>
        </div>
      </>
    );
  };
  const ProductInformationAccordion = () => {
    const accordionItems = [
      {
        productKey: "Brand",
        productDescription:
          productDetailsData?.productInformationData?.brandName ?? "-",
      },
      // { productKey: "Allergen information", productDescription: "-" },
      {
        productKey: "Country of origin",
        productDescription:
          productDetailsData?.productInformationData?.origin ?? "-",
      },
      // { productKey: "Flavour", productDescription: "-" },
      // { productKey: "Diet type", productDescription: "-" },
      // { productKey: "Nut seed type", productDescription: "-" },
      // {
      //   productKey: "Weight",
      //   productDescription:
      //     productDetailsData?.productInformationData?.weight ?? "-",
      // },
      // { productKey: "Package weight", productDescription: "-" },
      {
        productKey: "HS code",
        productDescription:
          productDetailsData?.productInformationData?.hs_code?.length > 0
            ? productDetailsData?.productInformationData?.hs_code
            : "-",
      },
      // { productKey: "Package information ", productDescription: "-" },
    ];
    const selectedVariantAttributes = productDetailsData?.variantsList?.[selectedVariant]?.metadata?.attributes
    for(let attribute in selectedVariantAttributes){
      accordionItems?.push({productKey:attribute,productDescription:selectedVariantAttributes[attribute]});
    }
    return (
      <>
        <div className={styles.productInformationGrid}>
          {accordionItems.map((accordionItem) => (
            <ProductAccordionItem
              key={`Item-${accordionItem.productKey}`}
              productKey={accordionItem.productKey}
              productDescription={accordionItem.productDescription}
            />
          ))}
        </div>
      </>
    );
  };

  const supplierDetails = () => {
    const supplierDetailsList = [
      {
        key: "Sold by",
        description: productDetailsData?.supplierData?.supplierName,
      },
      {
        key: "Name of the Manufacturer/ Packer/ Importer",
        description: productDetailsData?.supplierData?.manufacturerInfo,
      },
      {
        key: "Address of Manufacturer/ Packer/ Importer",
        description: productDetailsData?.supplierData?.address,
      },
    ];
    return (
      <>
        <div className={styles.supplierDetails}>
          <div className={styles.logo}>
            <img
              src={
                productDetailsData?.supplierData?.supplierLogo ??
                supplierLogoDefault
              }
              alt="supplierLogo"
            />
          </div>
          <div className={styles.details}>
            {supplierDetailsList?.map((each) => (
              <div className={styles.accordionItem}>
                <div className={styles.productKey}>{each?.key}</div>
                <div className={styles.description}>{each?.description}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
  const viewAllReviews = () => {
    setIsDrawer({ right: true });
  };
  const backNav = () => {
    navigate(-1);
  };
  const addTofavourites = () => {
    setToastMsg({ icon: success, message: "Item added to favourites." });
  };
  const addProduct = async (count: number) => {
    console.log(cartID, "cartID");
    dispatch(updateIsLoading(true));
    if (cartID) {
      // if count is positive
      let selectedVariance = productDetailsData?.variantsList[selectedVariant];
      if (count) {
        //If the product is already in the cart, update the quantity
        if (selectedVariance?.match) {
          const body = {
            quantity: count,
            metadata: {},
          };
          const response = await updateQuantityOfProduct(
            cartID,
            selectedVariance?.line_item_id,
            body
          );
          if (response?.cart) {
            productDetailsData?.variantsList.forEach((element: any) => {
              if (element.id === selectedVariance?.id) {
                element.match = true;
                element.match_quantity = count;
                selectedVariance.match_quantity = count;
                selectedVariance.match = true;
              }
            });
            setProdQuantity(count);
          } else {
            dispatch(updateIsLoading(false));
            setToastMsg({
              icon: error,
              message: "Unable to update the item quantity",
            });
          }
        }
        //If the product is not in the cart, add the product to the cart.
        else {
          try {
            const addProductResponse = await addProductToCart(
              cartID,
              selectedVariance?.id ?? "",
              count,
              productDetailsData?.productDetails?.productImages ?? ""
            );
            if (addProductResponse?.cart) {
              setProdQuantity(count);
              dispatch(setCartValue(addProductResponse?.cart?.items?.length));
              localStorage.setItem(
                "cartCount",
                addProductResponse?.cart?.items?.length
              );
              productDetailsData?.variantsList.forEach((element: any) => {
                if (element.id === selectedVariance?.id) {
                  element.match = true;
                  element.match_quantity = count;

                  let item = addProductResponse?.cart?.items.find(
                    (ele: any) => ele.variant_id === selectedVariance?.id
                  );
                  if (item) {
                    let variance: any = cloneDeep(selectedVariance);
                    variance.match_quantity = count;
                    variance.match = true;
                    element.line_item_id = item.id;
                    variance.line_item_id = item.id;
                    // setSelectedVariance(variance);
                  }
                }
              });
              dispatch(updateIsLoading(false));
              setToastMsg({ icon: success, message: "Added to the cart" });
            } else {
              setProdQuantity(0);
              dispatch(updateIsLoading(false));
              setToastMsg({ icon: error, message: "Couldn't change variant" });
              return;
            }
          } catch (error) {
            dispatch(updateIsLoading(false));
            console.error("======= ERROR IN ADD =======", error);
          }
        }
      } else {
        // delete the product from cart as the count value is 0.
        const apiResponse = await deleteProductFromCart(
          cartID,
          selectedVariance?.line_item_id
        );

        if (apiResponse?.cart) {
          productDetailsData?.variantsList.forEach((element: any) => {
            if (element.id === selectedVariance?.id) {
              element.match = false;
              element.match_quantity = 0;
              selectedVariance.match_quantity = 0;
              selectedVariance.match = false;
            }
          });
          setProdQuantity(0);
          // if (cartValue) {
          dispatch(setCartValue(apiResponse?.cart?.items?.length));
          localStorage.setItem("cartCount", apiResponse?.cart?.items?.length);
          // }
        } else {
          dispatch(updateIsLoading(false));
          setToastMsg({ icon: error, message: "Unable to perform update" });
        }
      }
    }
    dispatch(updateIsLoading(false));
  };
  const isDataExist = () => {
    if (
      productDetailsData?.productDetails?.length > 0 ||
      productDetailsData?.productInformationData?.length > 0 ||
      productDetailsData?.knowyourProductData?.length > 0 ||
      productDetailsData?.supplierData?.length > 0 ||
      productDetailsData?.variantsList?.length > 0 ||
      productDetailsData?.rating != null
    )
      return true;
    return false;
  };
  console.log(isDataExist());
  console.log(productDetailsData?.variantsList,"sgfdgdfgdf", selectedVariant);
  return (
    <>
      {isDataExist() && 
        <div className={styles.app}>
          <div className={styles.backNav} onClick={backNav}>
            <div className={styles.backIcon}>
              <img src={arrowLeft} alt="arrow" />
            </div>
            <div className={styles.backTxt}>Back to result</div>
          </div>
          <div className={styles.container}>
            <div className={styles.productDetailsContainer}>
              <div className={styles.imagePreview}>
                {/* Image preview component */}
                {productDetailsData?.productDetails?.productImages && (
                  <ProductCarouselZoom
                    imageData={
                      productDetailsData?.productDetails?.productImages
                    }
                  />
                )}
              </div>
              <div className={styles.productDescription}>
                <div className={styles.shareIcon} onClick={() => {}}>
                  <img src={shareIcon} alt="share" />
                </div>
                <div className={styles.productTitle}>
                  <div className={styles.productName}>
                    {productDetailsData?.productDetails?.productName}
                  </div>
                  <div className={styles.productBrand}>
                    Brand:{" "}
                    {productDetailsData?.productDetails?.brandName ?? "-"}
                  </div>
                </div>
                <div className={styles.productPricing}>
                  <div className={styles.productPrice}>
                    <div className={styles.productPriceTop}>
                      <span>
                        <small className={styles.productPriceDenomination}>
                          AED
                        </small>
                        <span className={styles.productPriceValue}>
                          {
                            splitDecimalValue(
                              productDetailsData?.variantsList[selectedVariant]
                                ?.offer_value
                            )?.[0]
                          }
                        </span>
                        <small className={styles.productPriceDecimal}>
                          .
                          {
                            splitDecimalValue(
                              productDetailsData?.variantsList[selectedVariant]
                                ?.offer_value
                            )?.[1]
                          }
                        </small>{" "}
                        {productDetailsData?.variantsList[selectedVariant]
                          ?.metadata?.offer > 0 && (
                          <>
                            <small className={styles.productPriceDiscount}>
                              AED{" "}
                              {Number(
                                productDetailsData?.variantsList[
                                  selectedVariant
                                ]?.amount
                              )?.toFixed(2)}
                            </small>
                            <small className={styles.orderMessage}>
                              (
                              {
                                productDetailsData?.variantsList[
                                  selectedVariant
                                ]?.metadata?.offer
                              }
                              % Off)
                            </small>
                          </>
                        )}
                      </span>
                    </div>
                    <div className={styles.productPriceBottom}>
                      (Inclusive of all taxes.)
                    </div>
                  </div>
                  {timeLeft > 0 && (
                    <div className={styles.productDelivery}>
                      <div className={styles.truck}>
                        <img src={deliveryTruck} alt="delivery-truck" />
                      </div>
                      <div className={styles.orderMessage}>
                        Order within the next {timeLeft} <br />
                        hours for next day delivery.
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.productQuantityGrid}>
                  {camelCaseKeys(productDetailsData)?.variantsList?.map(
                    (variant: any, index: number) => (
                      <ProductQuantityPicker
                        varaint={variant}
                        quantity={`${variant?.metadata?.secondaryOrderingOptionValue} ${variant?.metadata?.secondaryOrderingOptionLabel}`}
                        discount={variant?.metadata?.offer}
                        moq={variant?.minQuantity}
                        isSupplier={false}
                        grossAed={0}
                        id={index}
                        selectedId={selectedVariant}
                        onClick={() => onVariantClick(index)}
                      />
                    )
                  )}
                </div>
                <div className={styles.buttonContainer}>
                  <div>
                    <Button
                      secondary
                      label="Add to favourites"
                      handleClick={addTofavourites}
                      customClass=""
                    />
                  </div>
                  <div>
                    {/* <Button label="Add to cart" handleClick={() => {}} /> */}
                    <ProductQuantityButton
                      quantity={prodQuantity}
                      type="details"
                      onChange={(count: number) => {
                        addProduct(count);
                      }}
                      minQuantity={
                        productDetailsData?.variantsList[selectedVariant]
                          ?.min_quantity
                      }
                      isDeleteIconRequired={true}
                    />
                  </div>
                </div>
                {prodQuantity > 0 && (
                  <div className={styles.totalCost}>
                    <hr />
                    <div
                      className={`${styles.productPriceTop} ${styles.flexCenter} ${styles.margin}`}
                    >
                      <span className={styles.totalText}>Total</span>
                      <span>
                        <small className={styles.productPriceDenomination}>
                          AED
                        </small>
                        <span className={styles.productPriceValue}>
                          {
                            splitDecimalValue(
                              productDetailsData?.variantsList[selectedVariant]
                                ?.offer_value
                            )?.[0]
                          }
                        </span>
                        <small className={styles.productPriceDecimal}>
                          .
                          {
                            splitDecimalValue(
                              productDetailsData?.variantsList[selectedVariant]
                                ?.offer_value
                            )?.[1]
                          }
                        </small>
                      </span>
                    </div>
                  </div>
                )}
                <div className={styles.productDetails}>
                  <ProductEllipsisDescription
                    title={constants.productDetails.buyerDetails.title}
                    details={
                      productDetailsData?.knowyourProductData?.productData ==
                        null ||
                      productDetailsData?.knowyourProductData?.productData ==
                        undefined ||
                      productDetailsData?.knowyourProductData?.productData == ""
                        ? "-"
                        : productDetailsData?.knowyourProductData?.productData
                            ?.charAt(0)
                            .toUpperCase() +
                          productDetailsData?.knowyourProductData?.productData?.slice(
                            1
                          )
                    }
                    showMore={true}
                  />
                  <div className={styles.additionalProductDetails}>
                    <CustomAccordion
                      title={"Product Information"}
                      children={ProductInformationAccordion()}
                      customClass={styles.accordion}
                    />
                    <CustomAccordion
                      title={"Supplier details"}
                      children={supplierDetails()}
                      customClass={styles.accordion}
                    />
                  </div>
                </div>
              </div>
            </div>

            {productDetailsData?.rating && (
              <div className={styles.productReviewContainer}>
                <ProductReview reviewData={productDetailsData?.rating} />
                {productDetailsData?.rating?.total_count > 2 && (
                  <div className={styles.allReviews}>
                    <div className={styles.hr}></div>
                    <div
                      className={styles.allReviewsTxt}
                      onClick={viewAllReviews}
                    >
                      View all reviews
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      }
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={"All Reviews"}
          childComponent={
            <AllReviews
              overlayCallback={setIsDrawer}
              reviewData={productDetailsData?.rating}
            />
          }
        />
      )}
      {toastMsg?.message && (
        <ToastNotification
          icon={toastMsg?.icon}
          content={toastMsg?.message}
          position={"top-right"}
          autoClose={5000}
        />
      )}
    </>
  );
};

export default ProductDetailsPage;
