import { createSlice } from "@reduxjs/toolkit";
import { convertTimeStampToUserTimeZone } from "../utils/functions";

const transformKeysDetails = (item: any) => {
  const formatTimestamp = (timestamp: string | null) => {
    if (timestamp) {
      return timestamp;
    }
    return "";
  };
  return {
    icon: "",
    orderId: item?.id || "",
    orderStatus: item?.status || "",
    fulfillmentStatus: item?.fulfillment_status || "",
    paidStatus: item?.payment_status || "",
    gracePeriod: item?.grace_period || "",
    orderType: item?.order_type || "",
    cartId: item?.cart_id || "",
    buyerId: item?.buyer_id || "",
    supplierId: item?.supplier_id || "",
    billingAddressId: item?.billing_address_id || "",
    shippingAddressId: item?.shipping_address_id || "",
    canceledAt: formatTimestamp(item?.canceled_at),
    metadata: item?.metadata || null,
    invoice: item?.invoice || null,
    orderTrackId: item?.order_track_id || null,
    rating: item?.rating || null,
    paymentOptionId: item?.payment_option_id || "",
    paymentMode: item?.payment_option?.name,
    outletId: item?.outlet_id || "",
    wareHouseId: item?.ware_house_id || "",
    notes: item?.notes || "",
    deliveryCharge: item?.delivery_charge || 0,
    vatTax: item?.vat_tax || 0,
    subTotal: item?.sub_total || 0,
    total: item?.total || 0,
    actualAmount: item?.actual_amount || 0,
    actualSubtotal: item?.actual_subtotal || 0,
    actualVat: item?.actual_vat || 0,
    productCount: item?.product_count || 0,
    buyerName: item?.buyer?.business_name,
    buyerPhoneNum: item?.outlet?.phone_number,
    buyerEmail: item?.buyer?.email_id,
    totalItems: item?.items,
    buyerPOC: item?.outlet?.contact_name,
    orderPO: item?.order_number?.replace("-", " "),
    deliveryAddresss: `${item?.outlet?.contact_name}, ${item?.shipping_address?.area ?? ""} , ${
      item?.shipping_address?.address ?? ""
    }, ${item?.billing_address?.emirate?.name ?? "-"}, ${item?.shipping_address?.country?.name ?? ""}`,
    status: item?.order_tracking?.status,
    placedAt: convertTimeStampToUserTimeZone(item?.order_tracking?.placed_date),
    placedDate:
    item?.order_tracking?.placed_date != null
      ? convertTimeStampToUserTimeZone(item?.order_tracking?.placed_date)
      : "-",
  confirmedDate:
    item?.order_tracking?.confirmed_date != null
      ? convertTimeStampToUserTimeZone(item?.order_tracking?.confirmed_date)
      : "-",
  packedDate:
    item?.order_tracking?.packed_date != null
      ? convertTimeStampToUserTimeZone(item?.order_tracking?.packed_date)
      : "-",
  ontheWayDate:
    item?.order_tracking?.packed_date != null
      ? convertTimeStampToUserTimeZone(item?.order_tracking?.packed_date)
      : "-", // check with backed team
  deliveredDate:
    item?.order_tracking?.delivered_date != null
      ? convertTimeStampToUserTimeZone(item?.order_tracking?.delivered_date)
      : "-",
    orderedItemsList: item?.items?.items?.map((each_item: any) => ({
      id: each_item?.id,
      productCode: each_item?.product_code ?? "-",
      productName: each_item?.title ?? "-",
      productBrand: each_item?.brand?.name ?? "-",
      orderItems: `${`${each_item?.delivered_quantity !== null? each_item?.delivered_quantity:each_item?.quantity } x ${each_item?.metadata?.secondary_ordering_option_value}` ?? `-`} ${each_item?.metadata?.secondary_ordering_option_label ?? "-"}`,
      offer: each_item?.metadata?.offer? Number(each_item?.metadata?.offer) : 0,
      itemAmount: each_item?.metadata?.is_special_price_applied?`AED ${Number(each_item?.quantity * each_item?.unit_price)?.toFixed(2)}`:`AED ${Number(each_item?.quantity * (each_item?.unit_price * (100 - (each_item?.metadata?.offer ?? 0))/100))?.toFixed(2)}`,
      updatedAmount:
        each_item?.actual_amount && each_item?.actual_amount > 0
          ? `AED ${Number((each_item?.delivered_quantity !== null ? each_item?.delivered_quantity:each_item?.quantity) * each_item?.actual_amount)?.toFixed(2)}`
          : "-",
    })),
    initialModifyOrdersData: item?.items?.items?.map((each_item: any) => ({
      id: each_item?.id,
      productCode: each_item?.product_code ?? "-",
      productName: each_item?.title ?? "-",
      productBrand: each_item?.brand?.name ?? "-",
      orderItems: `${`${each_item?.delivered_quantity !== null? each_item?.delivered_quantity:each_item?.quantity } x ${each_item?.metadata?.secondary_ordering_option_value}` ?? `-`} ${each_item?.metadata?.secondary_ordering_option_label ?? "-"}`,
      offer: each_item?.metadata?.offer? Number(each_item?.metadata?.offer) : 0,
      itemAmount: `AED ${Number(each_item?.unit_price)?.toFixed(2)}`,
      updatedAmount:
        each_item?.actual_amount && each_item?.actual_amount > 0
          ? `AED ${Number(each_item?.actual_amount)?.toFixed(2)}`
          : "-",
      isSpecialPriceApplied: each_item?.metadata?.is_special_price_applied,
    })),
    miniMumOrderValue:item?.supplier?.min_order_value 
  };
};

const manageOrdersDetailsSlice = createSlice({
  name: "manageOrdersList",
  initialState: [],
  reducers: {
    getOrderListDetailsById: (state: any, action: any) => {
      state = action.payload;
      const transformPayload: any = transformKeysDetails(action?.payload);
      return transformPayload;
    },
    updateOrderStatus: (state: any, action: any) => {
      if (action?.payload) {
        return {
          ...state,
          ...action?.payload,
        };
      }
    },
  },
});

export const { getOrderListDetailsById, updateOrderStatus } = manageOrdersDetailsSlice.actions;

export default manageOrdersDetailsSlice.reducer;
