import { createSlice } from "@reduxjs/toolkit";

// export interface userTypeState {
//   value: string;
// }

const initialState: any = {
  filterData: {},
};

const filterDataSlice = createSlice({
  name: "filterData",
  initialState: initialState,
  reducers: {
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { setFilterData } = filterDataSlice.actions;

export default filterDataSlice.reducer;
