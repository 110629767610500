import styles from "./SubCategoryCardStyles.module.scss";
const SubCategoryCard = ({id, name, selectedSubCategoryId, image, handleSubCategoryClick}: any) => {
  console.log(selectedSubCategoryId, id)
  return (
  <div className={`${styles.card} ${id === selectedSubCategoryId ? styles.selected: null}`} key={`subCategory${id}`} onClick={handleSubCategoryClick}>
    <div className={styles.icon}>
      <img src={image} alt="category icon" />
    </div>
    <div className={styles.line}></div>
    <p className={styles.title} title={name}>{name}</p>
  </div>);
};

export default SubCategoryCard;