export const fileHeaderFields: any = {
  product_variant_title: "title",
  master_watermelon_product_code: "master_watermelon_product_code",
  description: "description",
  brand: "brand",
  certifications: "certifications",
  specifications: "specifications",
  tags: "tags",
  category_name: "category",
  sub_category_name: "sub_category",
  sub_sub_category_name: "sub_sub_category",
  country: "country",
  supplier_product_code: "supplier_product_code",
  hs_code: "hs_code",
  is_vat_included: "is_vat_included",
  product_option_ordering_unit_value: "ordering_option_value_",
  product_option_ordering_unit_label: "ordering_option_label_",
  product_option_secondary_ordering_unit_value:
    "secondary_ordering_option_value_",
  product_option_secondary_ordering_unit_label:
    "secondary_ordering_option_label_",
  product_option_unit_price: "unit_price_",
  product_option_offer_percentage: "offer_",
  product_option_min_quantity: "min_quantity_",
  // master_product_id: "master_product_id",
  // product_id: "product_id",
  // variant_id: "variant_id_",
  // image_id_1: "image_id_1",
  // image_id_2: "image_id_2",
  // image_id_3: "image_id_3",
  // image_id_4: "image_id_4",
  // image_id_5: "image_id_5",
};

export const attributes = [
  "size",
  "flavor",
  "capacity",
  "color",
  "scent",
  "origin",
  "material",
  "type",
  "cutorform",
];
