import { MinMaxValues } from "../models/IMarketplaceFiltersProps";

export const transformFilterValue = (filterName: string): string => {
  switch (filterName) {
    case "brands":
      return "brand_ids";
    case "products":
      return "products_ids";
    case "supplier":
      return "business_setup_ids";
    case "tags":
      return "tag_ids";
    case "country":
      return "country_ids";
    case "prices":
      return "min_price-max_price";
    default:
      return filterName;
  }
};

const getMinAndMaxValues = (input: string): MinMaxValues => {
  const [left, right] = input.split("-").map(Number);
  return { minLeft: left, maxRight: right };
};
// input: ['20-80','0-70'] o/p: {minLeft:0,maxRight:80}
export const findMinAndMaxValues = (arr: string[]): MinMaxValues => {
  const initialMinMaxValues: MinMaxValues = {
    minLeft: Infinity,
    maxRight: -Infinity,
  };

  return arr.reduce((acc, item) => {
    const { minLeft, maxRight } = getMinAndMaxValues(item);
    return {
      minLeft: Math.min(acc.minLeft, minLeft),
      maxRight: Math.max(acc.maxRight, maxRight),
    };
  }, initialMinMaxValues);
};

export function capitalizeIfAlphabetic(word: string): string {
  if (/^[a-zA-Z]/.test(word)) {
    const firstLetter = word?.[0]?.toUpperCase();
    const restOfWord = word?.slice(1);
    console.log("dsfs", firstLetter + restOfWord);
    return firstLetter + restOfWord;
  } else {
    return word;
  }
}

function customSortOrder(char: string): number {
  const customOrder = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const index = customOrder.indexOf(char.toUpperCase());
  return index !== -1 ? index : Infinity;
}

export function sortFilters(listofFilters: any[]) {
  listofFilters.sort((a, b) => {
    const nameA = capitalizeIfAlphabetic(a.supplierName);
    const nameB = capitalizeIfAlphabetic(b.supplierName);

    for (let i = 0; i < Math.max(nameA.length, nameB.length); i++) {
      const charA = nameA[i] || "#";
      const charB = nameB[i] || "#";

      const orderA = customSortOrder(charA);
      const orderB = customSortOrder(charB);

      if (orderA < orderB) {
        return -1;
      } else if (orderA > orderB) {
        return 1;
      }
    }
    return 0;
  });
  return listofFilters;
}
