/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import CatalogueInfo from "../../../../common/components/CatalogueInfo/CatalogueInfo";
import BreadCrumb from "../../../../common/components/breadCrumb/BreadCrumb";
import { routeConfig } from "../../../../common/constants/routeConfig";

import {
  resetWholeForm,
  setSecondBottomForm,
} from "../../../../reducerSlices/addIndividualProductFormSlice";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import {
  getSingleProductDetails,
  resetProductDetails,
} from "../../../../reducerSlices/productDetailsSlice";
import { useAxios } from "../../../../services/useAxios";
import styles from "./AddIndividual.module.scss";
import AddIndividualTwoTop from "./AddIndividualTwoTop";
import AddIndividualPartOne from "./AddIndividualPartOne";

export const LightTooltip = styled(
  ({ showTooltip, className, ...rest }: any) => {
    const props: any = {};

    if (showTooltip) {
      props["open"] = showTooltip;
    }

    return <Tooltip {...props} {...rest} classes={{ popper: className }} />;
  }
)(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #ec187b",
    fontSize: 11,
  },
}));

const AddIndividual = () => {
  // for edit product //
  const [navFromProductApproval, setnavFromProductApproval] =
    useState<boolean>(false);
  const [, setnavigationFromCatalogue] = useState<boolean>(false);
  const [, setLinkProduct] = useState(false);

  const { productId } = useParams();
  // const [isReadOnlyMode, setIsReadOnlyMode] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const isMasterProduct = location.state?.masterProduct;
  const isOfflineProduct = location.state?.offlineProduct;
  const [isUpdationMode, setIsUpdationMode] = useState(false);
  const dispatch = useDispatch();
  const singleProductDetails  = useSelector((state: any) => state.singleProductDetails);
  const isApproval = location.pathname.includes(
    "/admin/products-approval/products-approval-form/"
  );
  const getProductDetails = useAxios({
    axiosParams: {
      url:
        isMasterProduct && !isApproval
          ? `store/master-product/${productId}`
          : isOfflineProduct
          ? `store/offline-product/${productId}`
          : `store/product/${productId}`,
    },
    method: "GET",
  });

  const addIndividualProductForm = useSelector((state: any) => state.addIndividualProductForm);
  const [isTheProductsData, setIsTheProductsData] = useState<string[]>(
    addIndividualProductForm?.secondBottomForm?.isProducts
      ? addIndividualProductForm?.secondBottomForm?.isProducts
      : []
  );
  const [selectedCertifications, setSelectedCertifications] = useState<
    string[]
  >(
    addIndividualProductForm?.secondBottomForm?.certifications
      ? addIndividualProductForm?.secondBottomForm?.certifications
      : []
  );

  const [isIncludeWithVAT, setIsIncludeWithVAT] = useState<boolean>(
    addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT
      ? addIndividualProductForm?.secondBottomForm?.isIncludeWithVAT
      : false
  );
  useEffect(() => {
    if (singleProductDetails) {
      setIsIncludeWithVAT(singleProductDetails?.isVatIncluded ?? false);
    }
  }, [singleProductDetails]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          isIncludeWithVAT: isIncludeWithVAT,
        })
      );
    }
  }, [isIncludeWithVAT]);
  useEffect(() => {
    if (singleProductDetails) {
      setSelectedCertifications(
        singleProductDetails?.metadata?.certifications ?? []
      );
      setIsTheProductsData(
        singleProductDetails?.metadata?.productDetails ?? []
      );
    }
  }, [singleProductDetails]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          isProducts: isTheProductsData,
        })
      );
    }
  }, [isTheProductsData]);
  useEffect(() => {
    if (addIndividualProductForm) {
      dispatch(
        setSecondBottomForm({
          certifications: selectedCertifications,
        })
      );
    }
  }, [selectedCertifications]);
  const getProductDetailsData = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductDetails.fetchData();
    dispatch(updateIsLoading(false));

    if (response && response?.data) {
      dispatch(getSingleProductDetails(response?.data));
    }
  }, [dispatch, getProductDetails, singleProductDetails]);

  useEffect(() => {
    if (productId) {
      getProductDetailsData();
    }
  }, [productId]);
  const [isApiDataSet, setIsApiDataSet] = useState(false);
  const [secondFormShow, setSecondFormShow] = useState<boolean>(false);
  const isLinkProduct = location.state?.linkProduct;

  const breadcrumbItems = [
    {
      label: `${
        window?.location?.pathname.includes("/supplier/catalogue/")
          ? "Manage catalogue"
          : window?.location?.pathname.includes("/admin/manage-catalogue/")
          ? "Manage catalogue"
          : window?.location?.pathname.includes("/admin/products-approval/")
          ? "Products Approval"
          : window?.location?.pathname.includes("/admin/link-product")
          ? "Supplier Approval"
          : ""
      }`,
      link: window?.location?.pathname.includes("/supplier/catalogue/")
        ? routeConfig.productListingCatalogue
        : window?.location?.pathname.includes("/admin/manage-catalogue/")
        ? routeConfig.adminManageCatalogue
        : window?.location?.pathname.includes("/admin/products-approval/")
        ? routeConfig.adminProductApproval
        : window?.location?.pathname.includes("/admin/link-product")
        ? routeConfig.supplierBusinessApprovalPage
        : routeConfig.dashboard,
    },
    {
      label: isUpdationMode
        ? singleProductDetails?.status === "proposed"
          ? "Create Master Product"
          : "Edit Product"
        : isLinkProduct
        ? "Link Product"
        : "Add Individual Products",
    },
  ];
  // const handleEdit = () => {
  //   setIsReadOnlyMode(false);
  // };
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  useEffect(() => {
    if (location?.state?.path === routeConfig.adminManageCatalogue) {
      setnavigationFromCatalogue(true);
    } else if (location.pathname.includes("/admin/users/link-product/")) {
      setLinkProduct(true);
      setIsUpdationMode(false);
    } else if (
      location.pathname.includes(routeConfig.adminAddProductForm) ||
      location.pathname.includes(routeConfig.addIndividualProduct)
    ) {
      setIsUpdationMode(false);
    } else if (
      location.pathname.includes("/admin/manage-catalogue/edit-product/") ||
      location.pathname.includes("/supplier/catalogue/product-update/")
    ) {
      setIsUpdationMode(true);
    } else if (
      location.pathname.includes(
        "/admin/products-approval/products-approval-form/"
      )
    ) {
      setnavFromProductApproval(true);
      setIsUpdationMode(true);
    }
  }, [location?.state?.path, location?.pathname]);

  useEffect(() => {
    if (productId === undefined && !singleProductDetails?.title) {
      dispatch(resetProductDetails());
      dispatch(resetWholeForm());
    }
  }, [singleProductDetails, productId]);
  useEffect(() => {
    return () => {
      dispatch(resetProductDetails());
      dispatch(resetWholeForm());
    };
  }, []);
  const handleResetTotal = () => {
    dispatch(resetProductDetails());
    dispatch(resetWholeForm());
    setIsIncludeWithVAT(false);
    setSelectedCertifications([]);
    setIsTheProductsData([]);
  };
  return (
    <div className={styles.app}>
      <div className={styles.rightBodyContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.breadcrumb}>
            <BreadCrumb items={breadcrumbItems} />
          </div>
          {/* {productId && isReadOnlyMode && (
            <Button
              label={"Edit"}
              large={true}
              customClass={styles.formButton}
              handleClick={handleEdit}
            />
          )} */}
        </div>
        <div
          className={`${styles.outerBox} ${isAdmin && styles.adminOuterBox}`}
        >
          <div className={styles.catalogueContainer}>
            <CatalogueInfo item={!secondFormShow} filledTab={secondFormShow} />
          </div>
          <div
            className={`${secondFormShow ? styles.catalogueBorder : null}`}
          ></div>
          {!secondFormShow ? (
            <div
              className={`${styles.horizontal} ${styles.horizontalTop}`}
            ></div>
          ) : null}
          <div
            className={`${styles.OuterFormBox} ${
              styles.addIndividualFormBlock
            } ${secondFormShow && styles.outerFormBox2}`}
          >
            {!secondFormShow ? (
              <AddIndividualPartOne
                singleProductDetails={singleProductDetails}
                secondFormShow={secondFormShow}
                setSecondFormShow={setSecondFormShow}
                isAdmin={isAdmin}
                navFromProductApproval={navFromProductApproval}
                isUpdationMode={isUpdationMode}
                resetTotal={handleResetTotal}
                setIsApiDataSet={setIsApiDataSet}
              />
            ) : (
              <AddIndividualTwoTop
                isIncludeWithVAT={isIncludeWithVAT}
                setIsIncludeWithVAT={setIsIncludeWithVAT}
                selectedCertifications={selectedCertifications}
                setSelectedCertifications={setSelectedCertifications}
                isTheProductsData={isTheProductsData}
                setIsTheProductsData={setIsTheProductsData}
                singleProductDetails={singleProductDetails}
                secondFormShow={secondFormShow}
                setSecondFormShow={setSecondFormShow}
                isAdmin={isAdmin}
                navFromProductApproval={navFromProductApproval}
                isApiDataSet={isApiDataSet}
                setIsApiDataSet={setIsApiDataSet}
                isUpdationMode={isUpdationMode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddIndividual;
