export const response = {
  status: "SUCCESS",
  data: {
    outlet: {
      id: "bs_01HGDDM0E2PPWFXNYWHVHXHWPQ",
      created_at: "2023-11-29T11:32:24.133Z",
      updated_at: "2023-12-08T13:25:27.152Z",
      business_name: "veena",
      email_id: "veena@divami.com",
      logo_file: null,
      contract_file: null,
      license_number: "Bbshsbsh5866",
      license_file:
        "product-images/1702041729899-photo-1541963463532-d68292c34b19.jpeg",
      vat_number: "",
      vat_file:
        "product-images/1702041730587-photo-1541963463532-d68292c34b19.jpeg",
      min_order_value: null,
      iban: null,
      account_name: null,
      status: "approved",
      is_approved: true,
      type: "outlet",
      grace_period: null,
      modified_customer_id: "cus_01HGDDM0DX0PRW142SP562ZFH5",
      approved_admin_id: null,
      customer_id: "cus_01HGDDM0DX0PRW142SP562ZFH5",
      address_id: "addr_01HGDDRRRN9WJ27GZBH4TAPHKY",
    },
    buyer: {
      id: "cus_01HGDDM0DX0PRW142SP562ZFH5",
      created_at: "2023-11-29T11:32:24.121Z",
      updated_at: "2023-12-14T06:09:55.564Z",
      deleted_at: null,
      email: "veena@divami.com",
      first_name: "Veena",
      last_name: "Sriram",
      billing_address_id: null,
      phone: "+91#7842311726",
      has_account: true,
      metadata: {
        password_history: [
          "c2NyeXB0AAEAAAABAAAAAbZtaVVmIRFGu+UxU8anq1njwDdkexvwbu+I/fOT3STexndrGPswOoPnRPxDGWMaHDROxslZ4Wd+Hy/9jD0kRExc/otya0iZKgsVGfJL0Mg/",
        ],
      },
      buyer_db: true,
      supplier_db: false,
      is_supplier_activated: false,
      is_buyer_activated: true,
      is_email_verified: true,
      is_phone_verified: true,
      is_offline_supplier_activated: false,
      email_token: null,
      phone_token: null,
      buyer_id: null,
      profile_image: "product-images/1701855669425-1000031153.jpg",
      migrated_password: null,
      validate_migrated_user: null,
      username: null,
      designation: "",
    },
    cart: {
      object: "cart",
      id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
      created_at: "2023-12-12T12:41:37.441Z",
      updated_at: "2023-12-14T06:15:45.959Z",
      deleted_at: null,
      email: "veena@divami.com",
      billing_address_id: null,
      shipping_address_id: "addr_01HHF0R3DANSSF758T5ST700ZY",
      region_id: "reg_01HD48XT1PR9WCKJGDBXBQMH73",
      customer_id: "cus_01HGDDM0DX0PRW142SP562ZFH5",
      payment_id: null,
      type: "default",
      completed_at: null,
      payment_authorized_at: null,
      idempotency_key: null,
      context: {},
      metadata: null,
      buyer_options: [
        {
          note: "",
          supplier_id: "cus_01HEQES9PAY2TB1SHE9C15AR0E",
          ware_house_id: "bs_01HEQES9PBWWM7WADDVBW8F2M2",
          payment_option_id: "pay_opt_001",
        },
      ],
      outlet_id: "bs_01HGDDM0E2PPWFXNYWHVHXHWPQ",
      sales_channel_id: "sc_01HEQEGV9CQ1MKM0A5NMPHAT4W",
    },
    supplier_products: [
      {
        supplier: {
          id: "cus_01HEQES9PAY2TB1SHE9C15AR0E",
          created_at: "2023-11-08T12:33:46.697Z",
          updated_at: "2023-11-08T12:33:46.697Z",
          deleted_at: null,
          email: "nnftcouaezz@zzgmail.com",
          first_name: "Nasim",
          last_name: "Nasar",
          billing_address_id: null,
          phone: null,
          has_account: true,
          metadata: null,
          buyer_db: false,
          supplier_db: false,
          is_supplier_activated: false,
          is_buyer_activated: false,
          is_email_verified: true,
          is_phone_verified: false,
          is_offline_supplier_activated: false,
          email_token: null,
          phone_token: null,
          buyer_id: null,
          profile_image: null,
          migrated_password:
            "$2y$10$FtxQq7r1be9cRZ5o4zbMeu7cCQ4lDqhdqCh8FuvB9mK3sZXjZfe9a",
          validate_migrated_user: true,
          username: null,
          designation: null,
        },
        ware_house: {
          id: "bs_01HEQES9PBWWM7WADDVBW8F2M2",
          created_at: "2023-11-08T12:33:46.699Z",
          updated_at: "2023-11-08T12:33:46.709Z",
          business_name: "Nasar Nelloly Foodstuff.TR.LLC",
          email_id: "nnftcouaezz@zzgmail.com",
          logo_file: "product-images/1701782134846-Nasar_Nelloly_Foodstuff.png",
          contract_file: "",
          license_number: "",
          license_file: "",
          vat_number: "100276274600003",
          vat_file: "",
          min_order_value: 150,
          iban: "",
          account_name: "",
          status: "approved",
          is_approved: true,
          type: "warehouse",
          grace_period: null,
          modified_customer_id: null,
          approved_admin_id: null,
          customer_id: "cus_01HEQES9PAY2TB1SHE9C15AR0E",
          address_id: "addr_01HEQES9PJV3VC42SR37GBP3XH",
          payment_options: [
            { id: "pay_opt_001", name: "COD" },
            { id: "pay_opt_002", name: "ONLINE" },
          ],
          deliverable_locations: [
            {
              id: "emirate_007",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Sharjah",
              country_id: 234,
            },
            {
              id: "emirate_001",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Abu Dubai",
              country_id: 234,
            },
            {
              id: "emirate_002",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ajman",
              country_id: 234,
            },
            {
              id: "emirate_003",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Al Ain",
              country_id: 234,
            },
            {
              id: "emirate_004",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Dubai",
              country_id: 234,
            },
            {
              id: "emirate_005",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Fujairah",
              country_id: 234,
            },
            {
              id: "emirate_006",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ras Al Khaimah",
              country_id: 234,
            },
            {
              id: "emirate_008",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Umm Al Quwain",
              country_id: 234,
            },
          ],
        },
        products: [
          {
            id: "item_01HHKFEPBY5K2BKQZE5NTQCZ3X",
            created_at: "2023-12-14T06:15:35.505Z",
            updated_at: "2023-12-14T06:15:43.434Z",
            cart_id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
            order_id: null,
            swap_id: null,
            claim_order_id: null,
            original_item_id: null,
            order_edit_id: null,
            title: "Nat Frozen Whole Chicken 10x1400g",
            description: "Nat Frozen Whole Chicken 10x1400g",
            thumbnail: null,
            is_return: false,
            is_giftcard: false,
            should_merge: true,
            allow_discounts: true,
            has_shipping: false,
            unit_price: 111,
            variant_id: "variant_01HEQGB2EME3601F5948PFGE97",
            quantity: 10,
            fulfilled_quantity: null,
            returned_quantity: null,
            shipped_quantity: null,
            metadata: {
              offer: 0,
              selected_unit: "pro_unit_m_01HD5NKE2MJKCJMVBS5PYQ9AA13",
              vat_percentage: 1.5,
              selected_quantity: 1,
              equal_to_unit_name: "Other",
              ordering_option_value: 2,
              ordering_option_unit_name: "Other",
            },
            amount: null,
            delivered_quantity: null,
            master_order_id: null,
            actual_amount: 0,
            is_vat_included: null,
            images: [
              "https://qa.watermelon.market/minio/migrated-data/upload_images/NNGT-1102_1690558049.png",
              "https://qa.watermelon.market/minio/migrated-data/upload_images/NNGT-1102_img1_1690558049.png",
            ],
            product_id: "product_01HEQGB2EJ1ZRJFFXRFCXT1084",
            status: "published",
            avaliabilty_status: true,
            min_quantity: 1,
            is_deactivated: false,
            origin_country: {
              name: "UNITED ARAB EMIRATES",
              flag: "https://qa.watermelon.market/minio/wm-static-images/country_flags/ae.svg",
            },
            is_favourite: false,
          },
        ],
        amounts: {
          sub_total: 1110,
          delivery_charge: 0,
          vat_charge: 55.5,
          total: 1165.5,
        },
        notes: "",
        selected_payment_option: "COD",
      },
      {
        supplier: {
          id: "cus_01HEQESC7DHA7A35HNX7C5RNPH",
          created_at: "2023-11-08T12:33:49.292Z",
          updated_at: "2023-11-08T12:33:49.292Z",
          deleted_at: null,
          email: "barafoods17zz@zzgmail.com",
          first_name: "Saquib",
          last_name: "Jawed",
          billing_address_id: null,
          phone: null,
          has_account: true,
          metadata: null,
          buyer_db: false,
          supplier_db: false,
          is_supplier_activated: false,
          is_buyer_activated: false,
          is_email_verified: true,
          is_phone_verified: false,
          is_offline_supplier_activated: false,
          email_token: null,
          phone_token: null,
          buyer_id: null,
          profile_image: null,
          migrated_password:
            "$2y$10$FsyKFKgvyhsWQxchXkei4uRVEw1bs6C.sd0ZnCmwUlk8E9o28YjmG",
          validate_migrated_user: true,
          username: null,
          designation: null,
        },
        ware_house: {
          id: "bs_01HEQESC7EAMDX42FYQ9CGBAES",
          created_at: "2023-11-08T12:33:49.294Z",
          updated_at: "2023-11-08T12:33:49.305Z",
          business_name: "Bait Alraha Foodstuff Trading Co. LLC",
          email_id: "baratradingszz@zzgmail.com",
          logo_file:
            "product-images/1701782075236-Bait_Alraha_Foodstuff_Trading_Co_LLC.png",
          contract_file: "",
          license_number: "TL_Bait_Alraha___6671689773934.pdf",
          license_file: "TL_Bait_Alraha___6671689773934.pdf",
          vat_number: "100571922200003",
          vat_file: "vat_baitalraha___3361689773934.pdf",
          min_order_value: 100,
          iban: "",
          account_name: "",
          status: "approved",
          is_approved: true,
          type: "warehouse",
          grace_period: null,
          modified_customer_id: null,
          approved_admin_id: null,
          customer_id: "cus_01HEQESC7DHA7A35HNX7C5RNPH",
          address_id: "addr_01HEQESC7MK7FS3ADB68CWRD00",
          payment_options: [
            { id: "pay_opt_001", name: "COD" },
            { id: "pay_opt_002", name: "ONLINE" },
          ],
          deliverable_locations: [
            {
              id: "emirate_004",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Dubai",
              country_id: 234,
            },
            {
              id: "emirate_001",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Abu Dubai",
              country_id: 234,
            },
            {
              id: "emirate_002",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ajman",
              country_id: 234,
            },
            {
              id: "emirate_003",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Al Ain",
              country_id: 234,
            },
            {
              id: "emirate_005",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Fujairah",
              country_id: 234,
            },
            {
              id: "emirate_006",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ras Al Khaimah",
              country_id: 234,
            },
            {
              id: "emirate_007",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Sharjah",
              country_id: 234,
            },
            {
              id: "emirate_008",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Umm Al Quwain",
              country_id: 234,
            },
          ],
        },
        products: [
          {
            id: "item_01HHKVF8C03AR6V4CT91VS0MCD",
            created_at: "2023-12-14T09:45:36.865Z",
            updated_at: "2023-12-14T09:45:36.865Z",
            cart_id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
            order_id: null,
            swap_id: null,
            claim_order_id: null,
            original_item_id: null,
            order_edit_id: null,
            title: "Pomegranate",
            description: "Pomegranate",
            thumbnail: null,
            is_return: false,
            is_giftcard: false,
            should_merge: true,
            allow_discounts: true,
            has_shipping: false,
            unit_price: 10,
            variant_id: "variant_01HEQGPG9X9Y379XWP97AMCX24",
            quantity: 1,
            fulfilled_quantity: null,
            returned_quantity: null,
            shipped_quantity: null,
            metadata: {
              offer: 0,
              selected_unit: "pro_unit_m_01HD5NKE2MJKCJMVBS5PYQ9AA13",
              vat_percentage: 1.5,
              selected_quantity: 1,
              equal_to_unit_name: "Other",
              ordering_option_value: 2,
              ordering_option_unit_name: "Other",
            },
            amount: null,
            delivered_quantity: null,
            master_order_id: null,
            actual_amount: 0,
            is_vat_included: null,
            images: [
              "https://qa.watermelon.market/minio/migrated-data/upload_images/sample-product.png",
            ],
            product_id: "product_01HEQGPG9TYD0WM9DEVAHJ7MG6",
            status: "published",
            avaliabilty_status: true,
            min_quantity: 1,
            is_deactivated: false,
            origin_country: {
              name: "UNITED ARAB EMIRATES",
              flag: "https://qa.watermelon.market/minio/wm-static-images/country_flags/ae.svg",
            },
            is_favourite: false,
          },
        ],
        amounts: {
          sub_total: 10,
          delivery_charge: 0,
          vat_charge: 0.5,
          total: 10.5,
        },
        notes: "",
        selected_payment_option: "",
      },
      {
        supplier: {
          id: "cus_01HGZSNG4B3NG2ZBGZ09YKYDVY",
          created_at: "2023-12-06T14:49:15.636Z",
          updated_at: "2023-12-06T14:54:25.822Z",
          deleted_at: null,
          email: "litorol866@jalunaki.com",
          first_name: "lit",
          last_name: "lit",
          billing_address_id: null,
          phone: null,
          has_account: true,
          metadata: null,
          buyer_db: false,
          supplier_db: true,
          is_supplier_activated: false,
          is_buyer_activated: false,
          is_email_verified: true,
          is_phone_verified: false,
          is_offline_supplier_activated: false,
          email_token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImxpdG9yb2w4NjZAamFsdW5ha2kuY29tIiwiaWQiOiJjdXNfMDFIR1pTTkc0QjNORzJaQkdaMDlZS1lEVlkiLCJpYXQiOjE3MDE4NzQyOTMsImV4cCI6MTcwMTg3NjA5M30.FatzZEKqq7QFg8TrxIaafCgw5Xmsdrv9f3SFfcUpUBc",
          phone_token: null,
          buyer_id: null,
          profile_image: null,
          migrated_password: null,
          validate_migrated_user: null,
          username: null,
          designation: null,
        },
        ware_house: {
          id: "bs_01HGZSNG4H39FV5TT186VYMS6R",
          created_at: "2023-12-06T14:49:15.668Z",
          updated_at: "2023-12-06T14:55:32.547Z",
          business_name: "Leo",
          email_id: "litorol866@jalunaki.com",
          logo_file:
            "product-images/1701874423529-Parsley.3b02c14088fe7c028370.jpeg",
          contract_file:
            "product-images/1701874428373-Parsley.3b02c14088fe7c028370.jpeg",
          license_number: "12345",
          license_file:
            "product-images/1701874436906-Parsley.3b02c14088fe7c028370.jpeg",
          vat_number: null,
          vat_file:
            "product-images/1701874451377-Parsley.3b02c14088fe7c028370.jpeg",
          min_order_value: 1,
          iban: "",
          account_name: "",
          status: "approved",
          is_approved: true,
          type: "warehouse",
          grace_period: null,
          modified_customer_id: "cus_01HGZSNG4B3NG2ZBGZ09YKYDVY",
          approved_admin_id: null,
          customer_id: null,
          address_id: "addr_m_01HEM3W5JQJC64ZYRKZV0ARQ9BA013",
          payment_options: [{ id: "pay_opt_001", name: "COD" }],
          deliverable_locations: [
            {
              id: "emirate_008",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Umm Al Quwain",
              country_id: 234,
            },
            {
              id: "emirate_007",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Sharjah",
              country_id: 234,
            },
            {
              id: "emirate_006",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ras Al Khaimah",
              country_id: 234,
            },
            {
              id: "emirate_005",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Fujairah",
              country_id: 234,
            },
            {
              id: "emirate_004",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Dubai",
              country_id: 234,
            },
            {
              id: "emirate_003",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Al Ain",
              country_id: 234,
            },
            {
              id: "emirate_002",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ajman",
              country_id: 234,
            },
            {
              id: "emirate_001",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Abu Dubai",
              country_id: 234,
            },
          ],
        },
        products: [
          {
            id: "item_01HHKVETHX6GG0WEK146DMETMX",
            created_at: "2023-12-14T09:45:22.705Z",
            updated_at: "2023-12-14T09:45:22.705Z",
            cart_id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
            order_id: null,
            swap_id: null,
            claim_order_id: null,
            original_item_id: null,
            order_edit_id: null,
            title: "test",
            description: "test",
            thumbnail: null,
            is_return: false,
            is_giftcard: false,
            should_merge: true,
            allow_discounts: true,
            has_shipping: false,
            unit_price: 100,
            variant_id: "variant_01HGZV0TBZMWAJYQMBJWPTQF4T",
            quantity: 1,
            fulfilled_quantity: null,
            returned_quantity: null,
            shipped_quantity: null,
            metadata: {
              offer: 0,
              selected_unit: "pro_unit_m_01HD5NKE2MJKCJMVBS5PYQ9AA13",
              vat_percentage: 1.5,
              selected_quantity: 1,
              equal_to_unit_name: "Other",
              ordering_option_value: 2,
              ordering_option_unit_name: "Other",
            },
            amount: null,
            delivered_quantity: null,
            master_order_id: null,
            actual_amount: 0,
            is_vat_included: null,
            images: [
              "https://qa.watermelon.market/minio/product-images/1701875515745-Parsley.3b02c14088fe7c028370.jpeg",
            ],
            product_id: "product_01HGZV0TBGNBZ7YB3A5FQTCNEH",
            status: "published",
            avaliabilty_status: true,
            min_quantity: 1,
            is_deactivated: false,
            origin_country: {
              name: "AFGHANISTAN",
              flag: "https://qa.watermelon.market/minio/wm-static-images/country_flags/af.svg",
            },
            is_favourite: false,
          },
        ],
        amounts: {
          sub_total: 100,
          delivery_charge: 0,
          vat_charge: 5,
          total: 105,
        },
        notes: "",
        selected_payment_option: "",
      },
      {
        supplier: {
          id: "cus_01HH4DRTAMDZ08VNM29SAWDM63",
          created_at: "2023-12-08T09:57:33.648Z",
          updated_at: "2023-12-08T10:01:58.903Z",
          deleted_at: null,
          email: "mexah52343@lanxi8.com",
          first_name: "Navanitha",
          last_name: "Navanitha",
          billing_address_id: null,
          phone: null,
          has_account: true,
          metadata: null,
          buyer_db: false,
          supplier_db: true,
          is_supplier_activated: false,
          is_buyer_activated: false,
          is_email_verified: true,
          is_phone_verified: false,
          is_offline_supplier_activated: false,
          email_token: null,
          phone_token: null,
          buyer_id: null,
          profile_image: null,
          migrated_password: null,
          validate_migrated_user: null,
          username: null,
          designation: null,
        },
        ware_house: {
          id: "bs_01HH4DRTAQQ92WKMAKFWGHZKQ5",
          created_at: "2023-12-08T09:57:33.658Z",
          updated_at: "2023-12-08T14:07:37.567Z",
          business_name: "Business1",
          email_id: "mexah52343@lanxi8.com",
          logo_file: "product-images/1702038921431-cupcake.jpg",
          contract_file: "product-images/1702038916373-cupcake.jpg",
          license_number: "789456123467",
          license_file: "product-images/1702038906930-cupcake.jpg",
          vat_number: null,
          vat_file: null,
          min_order_value: 50,
          iban: null,
          account_name: null,
          status: "approved",
          is_approved: true,
          type: "warehouse",
          grace_period: null,
          modified_customer_id: "cus_01HH4DRTAMDZ08VNM29SAWDM63",
          approved_admin_id: null,
          customer_id: "cus_01HH4DRTAMDZ08VNM29SAWDM63",
          address_id: "addr_m_01HEM3W5JQJC64ZYRKZV0ARQ9BA028",
          payment_options: [{ id: "pay_opt_001", name: "COD" }],
          deliverable_locations: [
            {
              id: "emirate_008",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Umm Al Quwain",
              country_id: 234,
            },
            {
              id: "emirate_007",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Sharjah",
              country_id: 234,
            },
            {
              id: "emirate_006",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ras Al Khaimah",
              country_id: 234,
            },
            {
              id: "emirate_005",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Fujairah",
              country_id: 234,
            },
            {
              id: "emirate_004",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Dubai",
              country_id: 234,
            },
            {
              id: "emirate_003",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Al Ain",
              country_id: 234,
            },
            {
              id: "emirate_002",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Ajman",
              country_id: 234,
            },
            {
              id: "emirate_001",
              created_at: "2023-11-08T12:29:07.115Z",
              updated_at: "2023-11-08T12:29:07.115Z",
              name: "Abu Dubai",
              country_id: 234,
            },
          ],
        },
        products: [
          {
            id: "item_01HHKVF2CYTXPR612N1YGDTYJP",
            created_at: "2023-12-14T09:45:30.749Z",
            updated_at: "2023-12-14T09:45:48.368Z",
            cart_id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
            order_id: null,
            swap_id: null,
            claim_order_id: null,
            original_item_id: null,
            order_edit_id: null,
            title: "Mango ",
            description: "Mango ",
            thumbnail: null,
            is_return: false,
            is_giftcard: false,
            should_merge: true,
            allow_discounts: true,
            has_shipping: false,
            unit_price: 477,
            variant_id: "variant_01HHHFCTJZJYVYP1B7A8BP7MJS",
            quantity: 603,
            fulfilled_quantity: null,
            returned_quantity: null,
            shipped_quantity: null,
            metadata: {
              offer: "5",
              selected_unit: "pro_unit_m_01HD5NKE2MJKCJMVBS5PYQ9AA29",
              vat_percentage: 25,
              selected_quantity: "5",
              equal_to_unit_name: "kg",
              ordering_option_value: "10",
              ordering_option_unit_name: "Bag",
            },
            amount: null,
            delivered_quantity: null,
            master_order_id: null,
            actual_amount: 0,
            is_vat_included: null,
            images: [
              "https://qa.watermelon.market/minio/product-images/1702467326342-mango3.jpeg",
              "https://qa.watermelon.market/minio/product-images/1702467326304-mango1.jpeg",
              "https://qa.watermelon.market/minio/product-images/1702467304064-mango.jpeg",
            ],
            product_id: "product_01HHHFCTJM4TCAJY91WA7Z3KFM",
            status: "published",
            avaliabilty_status: true,
            min_quantity: 600,
            is_deactivated: false,
            origin_country: {
              name: "AFGHANISTAN",
              flag: "https://qa.watermelon.market/minio/wm-static-images/country_flags/af.svg",
            },
            is_favourite: false,
          },
          {
            id: "item_01HHKVFW4SAM093SX5S12PJP7Y",
            created_at: "2023-12-14T09:45:57.093Z",
            updated_at: "2023-12-14T09:45:57.093Z",
            cart_id: "cart_01HHF0R3DAY314XG6YK26SFFQB",
            order_id: null,
            swap_id: null,
            claim_order_id: null,
            original_item_id: null,
            order_edit_id: null,
            title: "Potato",
            description: "Potato",
            thumbnail: null,
            is_return: false,
            is_giftcard: false,
            should_merge: true,
            allow_discounts: true,
            has_shipping: false,
            unit_price: 48,
            variant_id: "variant_01HH4G3Q1HYKFN9YYHWYKDR3WZ",
            quantity: 1,
            fulfilled_quantity: null,
            returned_quantity: null,
            shipped_quantity: null,
            metadata: {
              offer: 0,
              selected_unit: "pro_unit_m_01HD5NKE2MJKCJMVBS5PYQ9AA13",
              vat_percentage: 1.5,
              selected_quantity: 1,
              equal_to_unit_name: "Other",
              ordering_option_value: 2,
              ordering_option_unit_name: "Other",
            },
            amount: null,
            delivered_quantity: null,
            master_order_id: null,
            actual_amount: 0,
            is_vat_included: null,
            images: [
              "https://qa.watermelon.market/minio/product-images/1702031620953-potato.jpeg",
            ],
            product_id: "product_01HH4G3Q1572FVWVBTYBJ1Q1T3",
            status: "published",
            avaliabilty_status: true,
            min_quantity: 1,
            is_deactivated: false,
            origin_country: {
              name: "INDIA",
              flag: "https://qa.watermelon.market/minio/wm-static-images/country_flags/in.svg",
            },
            is_favourite: false,
          },
        ],
        amounts: {
          sub_total: 273297.45,
          delivery_charge: 0,
          vat_charge: 13664.872500000001,
          total: 286962.3225,
        },
        notes: "",
        selected_payment_option: "",
      },
    ],
  },
};
