export const urlConstants = Object.freeze({
  BASE_URL: `${process.env.REACT_APP_BASE_API_URL}`,
});

export const APIMethods = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
  patch: "PATCH",
};

export const loginRoutes = {
  signUp: "store/customer/createuser",
  adminSignin: "admin/login",
  userSignin: "store/login",
  forgotPassword: "store/customer/reset-password",
  resetPassword: "store/customers/password-reset",
  resendVerification: "store/customer/updatetoken",
};
export const nonAuthorizedApis = {
  signUp: "store/customer/createuser",
  adminSignin: "admin/login",
  userSignin: "store/login",
  forgotPassword: "store/customer/reset-password",
  resetPassword: "store/customers/password-reset",
  resendVerification: "store/customer/updatetoken",
  refreshTokenAdmin: "admin/login/refreshToken",
  refreshTokenUser: "store/login/refreshToken",
  checkEmail: "store/customer/check-email",
  dowmloadInvoice: "/store/invoice/download_invoice",
  reports: "store/reports/"
};
export const adminEndPoints = {
  getBuyersListForAdmin: "store/buyer", //?page=1&q=&take=10
  // getBuyersListForAdmin: "store/business-setup/buyers", //?page=1&q=&take=10
  getSuppliersListForAdmin: "store/supplier", //?page=1&q=&take=10
  // getSuppliersListForAdmin: "store/business-setup/suppliers", //?page=1&q=&take=10
  getuserDetails: "store/supplier/:userId",
  getBuyerCustomerDetails: "admin/buyer/:business_id/customer/:customer_id",
  // getuserDetails: "store/business-setup/:userId",
  getAllStatesProductList: "admin/product/listing/",
  genericaddproducts: "admin/master-product",
  invoiceListing: "store/invoice",
  specialPricing: "store/special_pricing",
  getBuyerCustomerList: "admin/buyer/customer",
  getSupplierCustomerList: "admin/supplier/customer",
  getMasterProducts: "admin/master-product",
  getSupplierProducts: "admin/product",
  getOfflineSuppliersList: "store/offlinesupplier/adminofflinesupplierlist",
  rejectProposedProduct: "admin/product/:product_id/reject",
  getOutlets: "store/business-setup/:customer_id/:business_id/me",
  getMasterProductsAlgolia: "store/algolia/master-product-search",
  getSupplierAndOfflineProductsAlgolis: "store/algolia/mp-search",
};

export const buyerEndpoints = {
  outletsList: "store/buyer/business_store/",
  getCategories: "store/categories",
  getDefaultFilters: "store/marketplace/filter/:customerBusinessId",
  getFilteredProducts:
    "store/marketplace/search-product/:customerId/:customerBusinessId",
  getProductDetailsForBuyer:
    "store/product/:productId/customer/:customerBusinessId",
  getCategoryProducts:
    "store/marketplace/fetch-category-products/:customerId/:customerBusinessId?category_ids=:categoryId&sub_category_ids=:subCategoryId",
  updateProductQuantity: "store/carts/",
  addProductToCart: "store/my-cart/",
};

export const userEndpoints = {
  createIndividualProduct: "store/master-product/supplier/",
  supplierInvoiceListing: "store/invoice/",
  masterProductDetails: "store/master-product/",
};
export const resetMyProfile = {
  resetNameAndRole: "store/customer/updateprofiledetails/",
  resetProfilePhoto: "store/customer/updateprofileimage/",
  storeFile: "store/miniobucket/uploadfile",
  updatePassword: "store/customer/passwordupdate",
};

export const marketPlaceUrls = {
  getNewCartID: "store/business-setup/",
};
