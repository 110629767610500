import React from "react";
import styles from "./FeatureBrandSupply.module.scss";
import FeaturedBrands from "../../../../common/components/featuredBrands/FeaturedBrands";
const FeatureBrandSupply = ({data}: any) => {

  return (
    data?.length ? (<div className={styles.container}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Featured brands & Suppliers</h1>
      </div>
      <div className={styles.cardContainer}>
        {data &&
          data?.map((each: any) => {
            return (
              <FeaturedBrands
                key={each?.id}
                brandName={each?.brandName}
                brandImg={each?.brandImg}
                offer={each?.offer}
                productsList={each?.productsList}
              />
            );
          })}
      </div>
    </div>) : <></>
  );
};

export default FeatureBrandSupply;
