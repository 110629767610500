import SupplierProductCard from "../../../../components/supplierProductCard/SupplierProductCard";
import bc from "../../../../../assets/images/BC.svg";
import bcHover from "../../../../../assets/images/hoverBC.svg";
import bulkSupplier from "../../../../../assets/images/bulkSupplier.svg";
import bulkSupplierHover from "../../../../../assets/images/bulkSupplierHover.svg";
import styles from "./AddSupplierPopupStyles.module.scss";
import help from "../../../../../assets/images/helpContact.svg";
import { constants } from "../../../../constants/constants";
export const AddSupplierPopup = () => {
  return (
    <>
      <div className={styles.popup}>
        <div className={styles.cards}>
          <SupplierProductCard
            icon={bc}
            hoverIcon={bcHover}
            heading={"Add individual supplier"}
            content={"Add supplier’s basic details one by one"}
            isBulk={false}
            upload={() => {}}
          />

          <SupplierProductCard
            icon={bulkSupplier}
            hoverIcon={bulkSupplierHover}
            heading={"Bulk supplier upload"}
            isBulk={true}
            bulkContent={"the template to upload the supplier details"}
            upload={() => {}}
            content={""}
          />
        </div>
        <div className={styles.helpContainer}>
          <div className={styles.helpIcon}>
            <img src={help} alt="help" />
          </div>
          <div>
            <div className={styles.helpContent}>
              {constants.productListing.HELP}
            </div>
            <div className={styles.helpContact}>
              {constants.productListing.HELP_CONTACT}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
