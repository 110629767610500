import { useDispatch } from "react-redux";
import Button from "../../../../../common/components/button/Button";
import PopupView from "../../../../../common/components/popupView/PopupView";
import styles from "./MovPopupStyles.module.scss";
import React, { useState } from "react";
import { setMov } from "../../../../../reducerSlices/accountDetailsSlice";
import { useAxios } from "../../../../../services/useAxios";
export const MovPopup = ({ setShowPopup, value, currentUserAccountDetails, setCurrentUserAccountDetails }: any) => {
  const Mov = () => {
    const [isError, setIsError] = useState(value ? false : true);
    const [errorMsg,setErrorMsg] = useState<string>('');
    const [movValue, setMovValue] = useState(value);
    const dispatch = useDispatch();
    const handleOnChange = (e: any) => {
      if (!e.target.value) {
        setIsError(true);
        setErrorMsg('Enter minimum order value');
      }
      else if(Number?.isInteger(Number(e.target.value))) {
        setIsError(false);
        setErrorMsg('');

      }
      else {
        setIsError(true);
        setErrorMsg('Only integers is allowed ( Ex: valid - 100 , invalid - 100.2)');
      }
      setMovValue(e.target.value);
    };

    const [currentUser] = useState(
      JSON.parse(localStorage?.getItem("loggedInUser") as any)
    );

    //api to update the Minimum Order Value
    const updateMovValue = useAxios({
      axiosParams: {
        url: `store/business-setup/mov/${currentUser?.business_details?.[0]?.business_setup_id}`,
        data: {
          min_order_value: movValue && movValue,
        },
      },
      method: "POST",
    });
    const updateMov = async () => {
      if (!isError) {
        if (currentUser?.business_details?.[0]?.business_setup_id) {
          const response: any = await updateMovValue.fetchData();
          if (response && response?.status === "SUCCESS") {
            dispatch(setMov(movValue));
            setShowPopup(false);
            setCurrentUserAccountDetails({...currentUserAccountDetails,min_order_value:movValue && movValue})
          }
        }
      }
    };

    return (
      <div className={styles.movContainer}>
        <div className={styles.label}>
          Set the minimum order value required to place an order from your end.
        </div>
        <div
          className={`${styles.inputField} ${isError && styles.errorBorder}`}
        >
          <div className={styles.aed}>AED</div>
          <input
            placeholder={"Enter the amount here"}
            type="number"
            defaultValue={movValue}
            onChange={(e) => handleOnChange(e)}
            autoFocus
          />
        </div>
        {isError && errorMsg?.length > 0 && <div className={styles.errorMessage}>{errorMsg}</div>}
        <div className={styles.btns}>
          <div className={styles.cancel} onClick={() => setShowPopup(false)}>
            Cancel
          </div>
          <div className={styles.confirm}>
            <Button label={"Confirm"} handleClick={updateMov} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <PopupView
        heading={"Set MOV"}
        childComponent={Mov}
        height={293}
        width={460}
        handlePopup={() => setShowPopup(false)}
      />
    </>
  );
};
