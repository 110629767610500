import styles from "./UsersCard.module.scss";
import editIcon from "../../../../assets/images/editIcon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import { useEffect, useState } from "react";
import { hierarchy } from "../../../../utils/hierarchy";

type UserCardProps = {
  key: string;
  userData: any;
  handleMouseOver: (id: string) => void;
  handleMouseLeave: () => void;
  isHovered: boolean;
  editOutlet: (index: number) => void;
  deleteUser: (index: number) => void;
  isSuperUser: boolean;
  loggedInRole: string;
};

const UsersCard = ({
  key,
  userData,
  handleMouseOver,
  handleMouseLeave,
  isHovered,
  editOutlet,
  deleteUser,
  isSuperUser,
  loggedInRole,
}: UserCardProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (hierarchy(isSuperUser, loggedInRole, userData?.role)) {
      setShowEdit(true);
      setShowDelete(true);
    }
  }, []);
  return (
    <div
      className={`${styles.selectAddressWrapper}`}
      onMouseEnter={() => {
        handleMouseOver(userData?.customer_id);
      }}
      //   onClick={() => {
      //     !location?.pathname?.includes("/buyer/setup-business") &&
      //       onClick &&
      //       onClick();
      //   }}
      onMouseLeave={handleMouseLeave}
    >
      {/* {!location?.pathname?.includes("/buyer/setup-business") && (
        <RadioButton
          checked={selected === addressDetails?.id}
          handleChange={(e) => {}}
          value={addressDetails.id}
          inlineStyles={{ padding: "0px" }}
        />
      )} */}

      <div>
        <p className={styles.addressUserName}>
          {userData?.first_name} {userData?.last_name}
          <span className={`${styles.badge} ${styles.defaultBadge}`}>
            {userData?.is_super_user ? "Owner" : userData?.role?.split(" ")[1]}
          </span>
        </p>
        {/* <p className={styles.addressText}>{userData?.role}</p> */}
        <p className={styles.addressText}>Email: {userData?.email}</p>
      </div>
      {isHovered && (
        <div className={styles.addressActions}>
          {showEdit && userData?.is_super_user && isSuperUser && (
            <button
              type="button"
              className={styles.addressActionBtn}
              onClick={(e: any) => {
                e.stopPropagation();
                editOutlet(userData?.customer_id);
              }}
            >
              <img src={editIcon} alt="edit icon" />
              <div>Edit</div>
            </button>
          )}
          {showEdit && !userData?.is_super_user && (
            <button
              type="button"
              className={styles.addressActionBtn}
              onClick={(e: any) => {
                e.stopPropagation();
                editOutlet(userData?.customer_id);
              }}
            >
              <img src={editIcon} alt="edit icon" />
              <div>Edit</div>
            </button>
          )}
          {showDelete &&
            !userData?.is_super_user &&
            !loggedInRole?.includes("manager") &&
            !loggedInRole?.includes("staff") && (
              <button
                className={styles.addressActionBtn}
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  deleteUser(userData?.customer_id);
                }}
              >
                <img src={deleteIcon} alt="delete icon" />
                <div>Delete</div>
              </button>
            )}
        </div>
      )}
    </div>
  );
};

export default UsersCard;
