/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import loadash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import cancelAction from "../../../assets/images/cancelToast.svg";
import Button from "../../../common/components/button/Button";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import FileUploadCard from "../../../common/components/fileUploadCard/FileUploadCard";
import InputField from "../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import {
  DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO,
  constants,
} from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import {
  APIMethods,
  adminEndPoints,
} from "../../../common/constants/urlConstants";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../services/useAxios";
import useFetchCountries from "../../../services/useCountry";
import useFileUpload from "../../../services/useUploadImage";
import styles from "../adminSupplierSetupForm/AdminSupplierSetupForm.module.scss";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { setToastStatusApproval } from "../../../reducerSlices/toastStatusSlice";
import { useDispatch } from "react-redux";
import AddOutletDrawer from "./components/AddOutletDrawer";
import SelectAddress from "./components/selectAddress";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { getCountryByDialCode } from "../../../utils/functions";
import DeletePopup from "../../../common/components/deletePopup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import cancel from "../../../assets/images/cancelToast.svg";

function AdminBuyerBusinessSetupForm() {
  const [userOptions] = useState(constants.userTypesListBuyer);
  const { countriesList } = useFetchCountries();
  const [selectedSuggestedItem, setSelectedSuggestedItem] = useState<any>({});
  const id = useLocation().search.split("=")[1];
  const [userId] = useState(id);
  const { state = {} } = useLocation();
  let customerId;
  let previousPath;
  if (state) {
    customerId = state?.customerId;
    previousPath = state?.previousPath;
  }
  const isCustomer = previousPath === "/admin/users";
  const { businessId } = useParams();
  const [licenseFileError, setLicenseFileError] = useState(false);
  const [vatFileError, setVatFileError] = useState(false);
  const dispatch = useDispatch();
  const [existingBuyersFetchedList, setExistingBuyersFetchedList] =
    useState<any>([]);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [userType, setUserType] = useState<any>();
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [mobileNumberProductCode, setMobileNumberProductCode] = useState<any>();
  const [vatImageFile, setVATImageFile] = useState("");
  const [vatImageFileUrl, setVATImageFileUrl] = useState("");
  const [licenseImageFile, setLicenseImageFile] = useState("");
  const [licenseImageFileUrl, setLicenseImageFileUrl] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState<any>();
  const navigate = useNavigate();
  const [editForm, setEditForm] = useState(false);
  const suggestionRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [showOutletDropdown, setshowOutletDropdown] = useState<boolean>(false);
  const [isOutletChoosed, setisOutletChoosed] = useState<boolean>(false);
  const [rolesOptions, setRolesOptions] = useState(constants.rolesOptions);
  const [isStaffUser, setIsStaffUser] = useState<boolean>(false);
  const [outletsData, setOutletsData] = useState<any>([]);
  const [hovered, setHovered] = useState<number | string>("");
  const currOutletDataRef = useRef({});
  const [showOutlet, setShowOutlet] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<any>({});
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<string | number>();
  const [deleteId, setDeleteId] = useState<string | number>(-1);
  const [additionalOutletData, setAdditionalOutletData] = useState<any>([]);
  const [selectedOutlets, setSelectedOutlets] = useState<any>([]);
  const [unassignedOutlets, setUnassignedOutlets] = useState<any>([]);
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [customerOutletsList, setCustomerOutletsList] = useState<any>([]);
  const [isSuggested, setIsSuggested] = useState(false);

  const currSelectedOutlets = useRef([]);
  const isBuyerAdmin =
    userType?.role_key === "buyer-admin" && (isSuperUser || !id);
  const businessAddressData = currentUserData?.business_stores
    ? currentUserData?.business_stores.find((el: any) => el.is_base_address)
    : {};
  const defaultUserType = userOptions.find(
    (el) => el.name.toLowerCase().trim() === "admin"
  );

  let loggedInUser: any;
  const loggedInUserData = localStorage.getItem("loggedInUser");
  if (loggedInUserData != null) {
    loggedInUser = JSON.parse(loggedInUserData);
  }

  useEffect(() => {
    if (outletsData.length > 0) {
      let newOutletData = [];
      if (isBuyerAdmin && (!isSuggested || businessId)) {
        newOutletData =
          outletsData.length === 1
            ? []
            : outletsData
                .filter((el: any, i: number) => i !== 0)
                ?.map((item: any) => {
                  return { ...item, value: item?.saved_as };
                });
      } else if (outletsData[0]?.saved_as) {
        newOutletData = outletsData?.map((item: any) => {
          return { ...item, value: item?.saved_as };
        });
      }

      setAdditionalOutletData(
        outletsData.map((item: any) => {
          return { ...item, value: item?.saved_as };
        })
      );

      setSelectedOutlets(newOutletData);
    }
    if (outletsData.length === 0) {
      if (outletsData.length === 0) {
        setSelectedOutlets([]);
      }
    }
  }, [outletsData]);

  useEffect(() => {
    if (selectedOutlets) {
      let allOutlets = [...additionalOutletData];
      const removedOutlets: any = [];
      allOutlets?.forEach((item: any) => {
        if (!JSON.stringify(selectedOutlets)?.includes(item?.id)) {
          removedOutlets?.push(item);
        }
      });
      setUnassignedOutlets(removedOutlets);
    }
  }, [selectedOutlets.length]);

  const handleCloseDrawer = () => {
    setShowOutlet(false);
    currOutletDataRef.current = {};
  };
  const handleClose = () => {
    setOpen(false);
    setRolesOptions(constants.rolesOptions);
  };
  const [getCurrentProductStatus, setCurrentProductStatus] = useState("");

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  //schema for buyer form
  const schema = yup
    .object({
      businessName: yup.string().required("Enter business name"),
      emailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter a valid email"),
      userEmailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter a valid email"),
      firstName: yup.string().required("Enter first  name"),
      lastName: yup.string().required("Enter last  name"),
      outletName: yup.string().required("Enter outlet  name"),
      licenseNo: yup.string().required("Enter license number"),
      contactName: yup.string().required("Enter contact name"),
      phNo: yup
        .number()
        .required("Enter Phone number")
        .typeError("Enter Phone number"),
      countryName: yup.object().required("Select Country"),
      vatNo: yup
        .string()
        .optional()
        .nullable()
        .matches(
          constants.formaValidations.VAT_REGEX,
          "VAT number must contain alphabets or numeric only"
        ),
      address: yup.string().required("Enter Address"),
      emirates: yup
        .object()
        .test("is-empty", "Select emirates", function (obj) {
          return Object.keys(obj).length !== 0;
        })
        .required("Select emirates"),
      country: yup
        .object()
        .test("is-empty", "Select country", function (obj) {
          return Object.keys(obj).length !== 0;
        })
        .required("Select country"),
      licenseFile: yup
        .mixed()
        .required("Upload trade license file")
        .typeError("Upload trade license file"),
      vatFile: yup.mixed().optional().nullable(),
      area: yup.string().required("Enter the area"),
      password:
        userId || businessId
          ? yup.string().optional()
          : yup
              .string()
              .required("Enter password")
              .test(
                "password",
                "Must be 8-14 characters with upper, lower, number, and special character.",
                (value) => {
                  return passwordRegex.test(value || "");
                }
              ),
      confirmPassword:
        userId || businessId
          ? yup.string().optional()
          : yup
              .string()
              .required("Enter confirm password")
              .oneOf([yup.ref("password"), ""], "Passwords must match"),
      userType: yup.object().required("Select user type"),
      maxOrderLimit: yup
        .number()
        .positive()
        .when("userType", {
          is: (user: any) => user?.role_key === "buyer-staff",
          then: (schema) => schema.required("Enter order limit"),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      chooseOutlet: yup.boolean().optional(),
      outlet: yup
        .mixed()
        .nullable()
        .test("isExistinBusiness", "Select an outlet", (val: any) => {
          if (isSuggested && !(val && val?.length)) {
            return false;
          } else return true;
        }),
      assignedOutlets: yup
        .mixed()
        .nullable()
        .test("isExistinBusiness", "Select an outlet", (val: any) => {
          if (
            id &&
            !isSuggested &&
            !isEditForm &&
            !isBuyerAdmin &&
            !(val && val?.length)
          ) {
            return false;
          } else return true;
        }),
    })
    .required();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const values = getValues();

  //function to handle the input fields onchange
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "maxOrderLimit") {
        setValue(name, Number(text));
      }
      if (name === "businessName") {
        // trigger(name)
        setSearchedProduct(text);
      } else if (name === "chooseOutlet") {
        if (text?.target?.checked) {
          setValue(name, true);
          setshowOutletDropdown(!showOutletDropdown);
          setisOutletChoosed(!isOutletChoosed);
        }
      } else if (name === "userType") {
        if (text?.payloadValue === "buyer-staff") {
          setIsStaffUser(true);
        } else {
          setIsStaffUser(false);
        }
        setValue(name, text);
      } else if (name === "assignedOutlets") {
        setSelectedOutlets(text);
      }
      isSubmitted && trigger(name);
    },
    [setValue, showOutletDropdown, isSubmitted]
  );

  //function to get the business list based on the input field
  const handleSuggestion = (item: any) => {
    setIsSuggested(true);
    if (!editForm) {
      if (item) {
        const updatedItem = {
          ...item,
          country: {
            ...item?.country,
            emirates: [item?.emirate],
            value: item?.country?.name,
          },
          outletList: [
            {
              ...item?.country,
              value: item?.country?.name,
              address: item?.address,
              area: item?.area,
              emirates: item?.emirate,
            },
          ],
        };
        setUserType(null);
        setSelectedSuggestedItem(updatedItem);
      }
      const currentAddressDetails =
        item.business_stores && item.business_stores[0];
      setValue("businessName", item?.business_name);
      setValue("outletName", currentAddressDetails?.saved_as);
      setValue("contactName", currentAddressDetails?.contact_name);
      setValue(
        "countryName",
        getCountryByDialCode(
          currentAddressDetails?.phone_number?.split("#")[0],
          countriesList
        )
      );
      setValue("phNo", currentAddressDetails?.phone_number?.split("#")[0]);
      setValue("emailID", item?.email_id);
      setValue("licenseNo", item?.license_number);
      setValue("vatNo", item?.vat_number);
      setValue("address", currentAddressDetails?.address?.address);
      setValue("area", currentAddressDetails?.address?.area);
      setValue("country", currentAddressDetails?.address?.country);
      setValue("emirates", currentAddressDetails?.address?.emirate);
      setSelectedEmirate(currentAddressDetails?.address?.emirate);
      setSelectedCountry(currentAddressDetails?.address?.country);
      setValue("licenseFile", item?.license_file || "");
      setLicenseImageFileUrl(item?.license_file || "");
      setValue("vatFile", item?.vat_file || "");
      setVATImageFileUrl(item?.vat_file || "");

      const currPhoneFlagDetails = getCountryByDialCode(
        currentAddressDetails?.phone_number?.split("#")[0],
        countriesList
      );
      setMobileNumberProductCode(currPhoneFlagDetails);
      setValue("phNo", currentAddressDetails?.phone_number?.split("#")[1]);
      setInputFocused(false);
    } else {
      if (item) {
        const defaultAddressDetails =
          item?.business_stores && item?.business_stores[0];
        setValue("businessName", item?.business_name);
        setValue("contactName", defaultAddressDetails?.contact_name);

        setValue("countryName", item?.country);
        setValue("phNo", item?.phNo);
        setValue("emailID", item?.emailID);
        setValue("userEmailID", item?.userEmailID);
        setValue("licenseNo", item?.licenseNo);
        setValue("vatNo", item?.vatNo);
        if (item?.licenseFile) {
          setValue("licenseFile", item?.licenseFile);
          setLicenseImageFileUrl(item?.licenseFile);
        }
        if (item?.vatFile?.length) {
          setValue("vatFile", item?.vatFile);
          setVATImageFileUrl(item?.vatFile);
        }
        const currPhoneFlagDetails = getCountryByDialCode(
          item?.countryName?.dial_codes,
          countriesList
        );
        setMobileNumberProductCode(currPhoneFlagDetails);
        setValue("phNo", item?.phNo);
        setInputFocused(false);
      }
    }
  };

  //api to get approved buyer businesslist
  const getApprovalListOfBuyers = useAxios({
    axiosParams: {
      url: `store/buyer/?page=0&q=${searchedProduct}`,
      data: {
        q: values?.businessName,
        status: "",
      },
    },
    method: "GET",
  });

  //it is used to make api call to get business list for every business name input field change after 1 second
  const debouncedGetBuyersList = loadash.debounce(async () => {
    if (searchedProduct !== "") {
      dispatch(updateIsLoading(true));
      const response: any = await getApprovalListOfBuyers?.fetchData();
      dispatch(updateIsLoading(false));
      if (response && response?.status === "SUCCESS") {
        setExistingBuyersFetchedList(response?.data);
      }
    } else {
      setExistingBuyersFetchedList([]);
    }
  }, 1000);

  //reset form data on clicking cancel
  function resetBusinessDetails() {
    setVATImageFileUrl("");
    setLicenseImageFileUrl("");
    setSelectedOutlet("");
    setSelectedCountry({} as any);
    setSelectedEmirate({} as any);
    setSelectedSuggestedItem({} as any);
    setUserType(defaultUserType);
    setSelectedOutlet([]);
    setSelectedOutlets([]);
    setOutletsData([]);
    setExistingBuyersFetchedList([]);
    setIsSuggested(false);
    const data = getValues();
    reset({
      firstName: data?.firstName,
      lastName: data?.lastName,
      userType: defaultUserType,
    });
  }

  useEffect(() => {
    debouncedGetBuyersList();

    return () => {
      debouncedGetBuyersList.cancel();
    };
  }, [searchedProduct]);

  useEffect(() => {
    //to upload vatfile once it is selected
    const { uploadFile } = useFileUpload();
    const getVatFiles = async () => {
      try {
        if (vatImageFile) {
          const response: any = await uploadFile(vatImageFile);
          if (response?.status === "SUCCESS") {
            setTimeout(() => {
              setVATImageFileUrl(response?.data[0]);
            }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
            setToastData({
              message: "VAT file uploaded successfully",
              status: "Success",
            });
          } else {
            setToastData({
              message: response?.response?.data?.error_msg,
              status: "Fail",
            });
          }
        }
      } catch (error) {
        console.error(error);
        setToastData({
          message: "An error occurred while uploading the VAT file",
          status: "Fail",
        });
      }
    };
    getVatFiles();
  }, [vatImageFile]);

  useEffect(() => {
    //to upload license file once it is selected
    const { uploadFile } = useFileUpload();
    const getLicenseFiles = async () => {
      if (licenseImageFile) {
        const response: any = await uploadFile(licenseImageFile);
        setTimeout(() => {
          setLicenseImageFileUrl(response?.data[0]);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLicenseFiles();
  }, [licenseImageFile]);

  const getData = useAxios({
    axiosParams: {
      url: `${
        userId
          ? adminEndPoints.getuserDetails.replace(":userId", userId)
          : `admin/buyer/${businessId}`
      }`,
    },
    method: APIMethods.get,
  });

  const getCustomerDetails = useAxios({
    axiosParams: {
      url: `${adminEndPoints.getBuyerCustomerDetails
        .replace(":business_id", id || "")
        .replace(":customer_id", customerId || "")}`,
    },
    method: APIMethods.get,
  });

  useEffect(() => {
    if (businessAddressData?.phone_number) {
      setMobileNumberProductCode(
        getCountryByDialCode(
          businessAddressData?.phone_number?.split("#")[0],
          countriesList
        )
      );
    }
  }, [countriesList, customerId, currentUserData]);
  const getUserDetails = async () => {
    let response: any;
    if (isCustomer) {
      dispatch(updateIsLoading(true));
      response = await getCustomerDetails.fetchData();
      const res: any = await getData.fetchData();
      setCustomerOutletsList(
        (res?.data?.business_stores || []).map((el: any) => ({
          ...el,
          value: el.saved_as,
        }))
      );
      dispatch(updateIsLoading(false));
    } else if (userId || businessId) {
      dispatch(updateIsLoading(true));
      response = await await getData.fetchData();
      dispatch(updateIsLoading(false));
    }

    if (response?.status === "SUCCESS") {
      const userData = response?.data;
      if (userData?.customer?.is_super_user) {
        setIsSuperUser(true);
      }
      setCurrentProductStatus(userData?.status);
      let customerData: any;
      if (isCustomer) {
        customerData = userData.customer;
      } else {
        setIsSuperUser(true);
        customerData = userData?.customers?.find((ele: any) => {
          return ele?.id === userData?.customer_id;
        });
      }

      const currDefaultAddressData =
        userData.business_stores &&
        userData.business_stores.find((el: any) => el.is_base_address);
      setValue("firstName", customerData?.first_name);
      setValue("lastName", customerData?.last_name);
      setCurrentUserData(userData);
      setValue("businessName", userData?.business_name);
      setValue(
        "countryName",
        getCountryByDialCode(
          currDefaultAddressData?.phone_number?.split("#")[0],
          countriesList
        )
      );
      setValue("phNo", currDefaultAddressData?.phone_number?.split("#")[1]);
      setValue("userEmailID", customerData?.email);
      setValue("emailID", userData?.email_id);
      setValue("licenseNo", userData?.license_number);
      setValue("vatNo", userData?.vat_number);
      setValue("address", currDefaultAddressData?.address?.address);
      setValue("emirates", currDefaultAddressData?.address?.emirate);
      setValue("outletName", currDefaultAddressData?.saved_as);

      setValue("area", currDefaultAddressData?.address?.area);
      setValue("country", currDefaultAddressData?.address?.country);
      setValue("contactName", currDefaultAddressData?.contact_name);
      setOutletsData([
        currDefaultAddressData,
        ...userData.business_stores.filter((el: any) => !el.is_base_address),
      ]);
      currSelectedOutlets.current = userData.business_stores?.map(
        (el: any) => el.id
      );
      setValue(
        "maxOrderLimit",
        userData?.customer?.max_order_value ??
          userData?.customer?.max_order_value
      );
      setValue("userType", {
        ...customerData?.role,
        name: customerData?.role?.role,
      });
      setUserType({
        ...customerData?.role,
        name: customerData?.role?.role,
      });
      if (userData?.license_file) {
        setValue("licenseFile", userData?.license_file);
        setLicenseImageFileUrl(userData?.license_file);
      }
      if (userData?.vat_file) {
        setValue("vatFile", userData?.vat_file);
        setVATImageFileUrl(userData?.vat_file);
      }
    }
  };

  useEffect(() => {
    if (userId || businessId) {
      let country = null;
      if (businessAddressData?.address?.country) {
        country = countriesList?.find(
          (ele: any) =>
            ele?.iso_2 === businessAddressData?.address?.country?.iso_2
        );
        setSelectedCountry(country);
      }
      if (
        (userId || businessId) &&
        country &&
        businessAddressData?.address?.emirate
      ) {
        setSelectedEmirate(
          country?.emirates?.find(
            (ele: any) => ele?.id === businessAddressData?.address?.emirate?.id
          )
        );
      }
      setValue(
        "countryName",
        getCountryByDialCode(
          businessAddressData?.phone_number?.split("#")[0],
          countriesList
        )
      );
    }
  }, [businessId, countriesList, currentUserData, userId]);
  useEffect(() => {
    getUserDetails();
  }, [userId, businessId]);

  useEffect(() => {
    setValue("userType", defaultUserType || {});
    setUserType(defaultUserType);
  }, [defaultUserType]);

  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates =
      selectedOption &&
      selectedOption?.emirates?.map((emirate: ICountry) => ({
        ...emirate,
        value: emirate?.name,
      }));
    setEmiratesList(emirates);
  };

  const isDefaultOutlets = outletsData.filter((el: any) => el.is_default);
  const new_business = {
    business_name: values?.businessName,
    email_id: values?.emailID,
    license_number: values?.licenseNo,
    license_file:
      licenseImageFileUrl &&
      licenseImageFileUrl.length > 0 &&
      licenseImageFileUrl,
    vat_number: values?.vatNo,
    vat_file: vatImageFileUrl != "" ? vatImageFileUrl : null,
    outlets: [
      {
        address: values?.address,
        area: values?.area,
        emirate: {
          id: (values?.emirates as any)?.id,
        },
        country: {
          iso_2: (values?.country as any)?.iso_2,
        },
        is_default: isDefaultOutlets.length > 0 ? false : true,
        saved_as: values?.outletName,
        contact_name: values?.contactName,
        phone_number: `${(values?.countryName as any)?.dial_codes}#${
          values?.phNo
        }`,
        is_base_address: true,
      },
      ...getNewOutlets(),
    ],
  };

  const postFinalSupplierSetupData = useAxios({
    axiosParams: {
      url: "admin/buyer",
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.userEmailID,
        password: values?.password,
        customer_type: "company",
        role: {
          role_key: (values?.userType as any)?.payloadValue,
        },
        business: new_business,
      },
    },
    method: "POST",
  });
  const postNewCustomerForExistingBusiness = useAxios({
    axiosParams: {
      url: `admin/buyer/${selectedSuggestedItem?.id}/customer`,
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.userEmailID,
        password: values?.password,
        contact_name: values?.contactName,
        phone_number: `${(values?.countryName as any)?.dial_codes}#${
          values?.phNo
        }`,
        role: {
          role_key: (values?.userType as any)?.payloadValue,
        },
        // new_outlets: [],
        max_order_value: Number(values?.maxOrderLimit),
        existing_outlets: Array.from(
          new Set(selectedOutlets.map((el: any) => el && el.id))
        ),
        customer_type: "company",
      },
    },
    method: "POST",
  });
  const postCustomerForm = useAxios({
    axiosParams: {
      url: `admin/buyer/${id}/customer/${customerId}`,
      data: isSuperUser
        ? {
            first_name: values?.firstName,
            last_name: values?.lastName,
            updated_customer_id: loggedInUser?.id,
          }
        : {
            first_name: values?.firstName,
            last_name: values?.lastName,
            role: userType?.role_key,
            updated_customer_id: loggedInUser?.id,
            add_outlets: selectedOutlets
              ?.filter(
                (item: any) =>
                  !currSelectedOutlets.current?.includes(item?.id as never)
              )
              .map((el: any) => el.id),
            delete_outlets: unassignedOutlets?.map((item: any) => item?.id),
            ...(userType?.role_key?.includes("staff") && {
              max_order_value: Number(values?.maxOrderLimit),
            }),
          },
    },
    method: "PUT",
  });

  const getExistingOutlets = () => {
    let existingOutlets: any = [];

    outletsData
      .filter((el: any) => el && !el.isNew)
      .forEach((outlet: any, i: number) => {
        if (i === 0) {
          existingOutlets.push({
            id: outletsData[0] && outletsData[0].id,
            area: values.area,
            address: values.address,
            country: {
              iso_2: selectedCountry?.iso_2,
            },
            emirate: {
              id: selectedEmirate?.id,
            },
            contact_name: values?.contactName,
            phone_number: `${(values?.countryName as any)?.dial_codes}#${
              values?.phNo
            }`,
            saved_as: values.outletName,
            is_default: outlet.is_default,
          });
        } else {
          existingOutlets.push({
            id: outlet.id,
            area: outlet.address?.area,
            address: outlet.address?.address,
            country: {
              iso_2: outlet.address?.country.iso_2,
            },
            emirate: {
              id: outlet.address?.emirate?.id,
            },
            contact_name: outlet.contact_name,
            phone_number: outlet.phone_number,
            saved_as: outlet.saved_as,
            is_default: outlet.is_default,
          });
        }
      });

    return existingOutlets;
  };

  function getNewOutlets() {
    const newOutlets: any = [];
    outletsData
      .filter((el: any) => el && el?.isNew)
      .forEach((outlet: any) => {
        newOutlets.push({
          area: outlet.address?.area,
          address: outlet.address?.address,
          country: {
            iso_2: outlet.address?.country.iso_2,
          },
          emirate: {
            id: outlet.address?.emirate?.id,
          },
          contact_name: outlet.contact_name,
          phone_number: outlet.phone_number,
          saved_as: outlet.saved_as,
          is_default: outlet.is_default,
        });
      });

    return newOutlets;
  }

  // update the current business setup
  const newOutlets = getNewOutlets();
  const updateCurrentBusinessSetup = useAxios({
    axiosParams: {
      url: `${
        getCurrentProductStatus === "approved" ? "admin" : "store"
      }/buyer/${businessId}`,
      data: {
        // is_edit: "admin",
        business_name: values?.businessName,
        license_number: values?.licenseNo,
        license_file:
          licenseImageFileUrl &&
          licenseImageFileUrl.length > 0 &&
          licenseImageFileUrl,
        vat_number: values?.vatNo,
        vat_file:
          vatImageFileUrl && vatImageFileUrl?.length > 0 && vatImageFileUrl,
        super_admin: {
          first_name: values?.firstName,
          last_name: values?.lastName,
        },
        existing_outlets: getExistingOutlets(),
        ...(newOutlets.length > 0 && { new_outlets: newOutlets }),

        // phone_number: `${(values?.countryName as any)?.dial_codes}#${
        //   values?.phNo
        // }`,
      },
    },
    method: "PUT",
  });

  const checkEmail = useAxios({
    axiosParams: {
      url: "store/customer/check-email",
      data: {
        email: values?.userEmailID && values?.userEmailID,
      },
    },
    method: "POST",
  });

  const checkEmailIsExist = async () => {
    const response: any = await checkEmail.fetchData();
    if (response && response?.status === 1004) {
    } else {
      setToastData({
        message: "Email id already exits!",
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const updateBuyerApiCall = async () => {
    const response: any = await updateCurrentBusinessSetup.fetchData();
    if (response?.status === "SUCCESS") {
      return response;
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      if (
        !licenseImageFile &&
        !licenseImageFileUrl &&
        licenseImageFileUrl?.length === 0
      ) {
        setLicenseFileError(true);
      }
      if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
        setVatFileError(false);
      }
    }
  }, [errors, isSubmitted]);

  async function createBuyer() {
    dispatch(updateIsLoading(true));
    const response: any = await postFinalSupplierSetupData?.fetchData();
    dispatch(updateIsLoading(false));
    if (response?.status === "SUCCESS") {
      navigate(routeConfig.adminAddBuyer);
      reset();
      dispatch(
        setToastStatusApproval({
          message: "Successfully created the buyer",
          status: "created",
          type: "buyer",
        } as any)
      );
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      // reset(); if the form needs to be reset
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }

  async function addCustomerToExistingBusiness() {
    dispatch(updateIsLoading(true));
    const response: any = await postNewCustomerForExistingBusiness.fetchData();
    dispatch(updateIsLoading(false));
    if (response?.status === "SUCCESS") {
      navigate(routeConfig.adminAddBuyer);
      reset();
      dispatch(
        setToastStatusApproval({
          message: "Successfully added customer to business",
          status: "created",
          type: "buyer",
        } as any)
      );
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      // reset(); if the form needs to be reset
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }
  const submit = async (data: any) => {
    if (
      !licenseImageFile &&
      !licenseImageFileUrl &&
      licenseImageFileUrl?.length === 0
    ) {
      setLicenseFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls =
      licenseImageFileUrl && licenseImageFileUrl?.length > 0;

    if (shouldMakeApiCalls && !userId && !businessId) {
      if (selectedSuggestedItem?.address) {
        addCustomerToExistingBusiness();
      } else {
        createBuyer();
      }
    } else if (shouldMakeApiCalls) {
      if (
        getCurrentProductStatus === "review" &&
        window?.location?.pathname.includes("/admin/buyer-approval-form/")
      ) {
        await approveSelectedBuyer();
      } else {
        dispatch(updateIsLoading(true));
        const response: any = await updateBuyerApiCall();
        dispatch(updateIsLoading(false));

        if (response && response?.status === "SUCCESS") {
          userId
            ? navigate(routeConfig.adminAddBuyer)
            : navigate(routeConfig.buyerBusinessApprovalPage);
        }
        if (editForm) {
          dispatch(
            setToastStatusApproval({
              message: "Successfully edited the buyer",
              status: "edited",
              type: "buyer",
            } as any)
          );
        }
      }
    }

    handleClose();
    let selectedRoles = rolesOptions.filter((item) => {
      if (item.checked) {
        return item;
      }
    });
    if (selectedRoles.length > 0) {
    }
    setRolesOptions(constants.rolesOptions);
  };

  const submitBuyerCustomerForm = async () => {
    dispatch(updateIsLoading(true));

    const response: any = await postCustomerForm.fetchData();

    dispatch(updateIsLoading(false));
    if (response?.status === "SUCCESS") {
      navigate(routeConfig.adminAddBuyer);
      reset();
      dispatch(
        setToastStatusApproval({
          message: "Successfully updated customer details",
          status: "created",
          type: "buyer",
        } as any)
      );
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      // reset(); if the form needs to be reset
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const onCancel = () => {
    handleClose();
    setRolesOptions(constants.rolesOptions);
  };

  // approval api
  const approveBuyer = useAxios({
    axiosParams: {
      url: `store/business-setup/${businessId ?? userId}/approve`,
      data: {
        status: "approved",
      },
    },
    method: "POST",
  });

  //reject  api
  const rejectBuyer = useAxios({
    axiosParams: {
      url: `store/business-setup/${businessId ?? userId}/approve`,
      data: {
        status: "rejected",
      },
    },
    method: "POST",
  });
  const rejectSelectedBuyer = async () => {
    if (licenseImageFileUrl.length === 0) {
      setLicenseFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls =
      licenseImageFileUrl && licenseImageFileUrl.length > 0;
    if (shouldMakeApiCalls || licenseImageFile) {
      if (businessId) {
        dispatch(updateIsLoading(true));
        const updateBuyerResponse: any = await updateBuyerApiCall();
        dispatch(updateIsLoading(false));
        if (updateBuyerResponse && updateBuyerResponse?.status === "SUCCESS") {
          dispatch(updateIsLoading(true));
          const response: any = await rejectBuyer?.fetchData();
          dispatch(updateIsLoading(false));
          if (response?.status === "SUCCESS") {
            dispatch(
              setToastStatusApproval({
                message: "Rejected the buyer",
                status: "rejected",
                type: "buyer",
              } as any)
            );
            navigate(routeConfig.buyerBusinessApprovalPage);
          } else {
            setToastData({
              message: response?.response?.data?.error_msg,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 5000);
          }
          reset();
        }
      }
    }
  };
  const approveSelectedBuyer = async () => {
    if (licenseImageFileUrl.length === 0) {
      setLicenseFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls =
      licenseImageFileUrl && licenseImageFileUrl.length > 0;
    if (shouldMakeApiCalls || licenseImageFile) {
      if (businessId || userId) {
        dispatch(updateIsLoading(true));
        const updateBuyerResponse: any = await updateBuyerApiCall();
        dispatch(updateIsLoading(false));
        if (updateBuyerResponse && updateBuyerResponse?.status === "SUCCESS") {
          dispatch(updateIsLoading(true));
          const approveResonse: any = await approveBuyer?.fetchData();
          dispatch(updateIsLoading(false));
          if (approveResonse?.status === "SUCCESS") {
            dispatch(
              setToastStatusApproval({
                message: "Approved the buyer",
                status: "approved",
                type: "buyer",
              } as any)
            );
            navigate(routeConfig.buyerBusinessApprovalPage);
          } else {
            setToastData({
              message: approveResonse?.response?.data?.error_msg,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 5000);
          }
          reset();
        }
      }
    }
  };

  const handleMouseOver = (id: string | number) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered("");
  };
  const handleEditOutlet = (index: string | number) => {
    currOutletDataRef.current = {
      ...selectedOutlets[Number(index)],
      index,
    };
    setShowOutlet(true);
  };
  const handleAddOutlet = (
    outletDetails: any,
    isEdit: boolean,
    index: string | number
  ) => {
    let copyOutletsData = [...outletsData];
    if (isEdit) {
      if (outletDetails.is_default) {
        copyOutletsData = copyOutletsData.map((el, i) => {
          if (i === Number(index) + 1) {
            return el;
          } else {
            return { ...el, is_default: false };
          }
        });
      }
      copyOutletsData.splice(Number(index) + 1, 1, {
        ...copyOutletsData[Number(index) + 1],
        ...outletDetails,
      });
      const defOutletExist = copyOutletsData
        ?.slice(1)
        .find((item) => item.is_default === true);
      if (!defOutletExist) {
        copyOutletsData[0].is_default = true;
      }
      setToastData({
        message:
          "Outlet updated, please click on submit/update to save your changes",
        status: "Success",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 4000);
    } else {
      if (outletDetails.is_default) {
        copyOutletsData = copyOutletsData.map((el) => ({
          ...el,
          is_default: false,
        }));
      }
      copyOutletsData.push(outletDetails);
      setToastData({
        message:
          "Outlet added, please click on update/submit to save your changes",
        status: "Success",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 4000);
    }
    setOutletsData(copyOutletsData);
  };
  const handleDeleteOutlet = (id: number | string, index: string | number) => {
    if (id) {
      setShowDeletePopup(true);
      setDeleteIndex(index);
      setDeleteId(id);
    } else {
      setShowDeletePopup(true);
      setDeleteIndex(index);
      setDeleteId(-1);
    }

    // const copyOutletsData = [...outletsData];
    // copyOutletsData.splice(Number(index) + 1, 1);
    // setOutletsData(copyOutletsData);
  };

  const deleteExistingOutlet = useAxios({
    axiosParams: {
      url: `admin/buyer/${businessId}/outlet/${deleteId}`,
      data: {
        updated_customer_id: loggedInUser?.id,
      },
    },
    method: "DELETE",
  });

  const handleConfirmDelete = async () => {
    setShowDeletePopup(false);
    if (deleteId != -1) {
      dispatch(updateIsLoading(true));
      const deleteResponse: any = await deleteExistingOutlet.fetchData();
      if (deleteResponse && deleteResponse?.status === "SUCCESS") {
        if (outletsData?.[Number(deleteIndex) + 1]?.is_default) {
          outletsData[0].is_default = true;
        }
        const copyOutletsData = [...outletsData];
        copyOutletsData.splice(Number(deleteIndex) + 1, 1);
        setOutletsData(copyOutletsData);
        setToastData({
          message: "Outlet deleted successfully",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      } else {
        setToastData({
          message: deleteResponse?.err_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
      dispatch(updateIsLoading(false));
    } else {
      dispatch(updateIsLoading(true));
      setTimeout(() => {
        dispatch(updateIsLoading(false));
      }, 2000);
      if (outletsData?.[Number(deleteIndex) + 1]?.is_default) {
        outletsData[0].is_default = true;
      }
      setTimeout(() => {
        const copyOutletsData = [...outletsData];
        copyOutletsData.splice(Number(deleteIndex) + 1, 1);
        setOutletsData(copyOutletsData);
        setToastData({
          message: "Outlet deleted successfully",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }, 2000);
    }
  };
  const handleCancelDelete = () => {
    setShowDeletePopup(false);
  };
  const isEditForm = (userId || businessId) && !editForm;
  let isUserTypeDisabled = true;
  let showOutletBtn = false;
  if (editForm) {
    isUserTypeDisabled = true;
  }
  if (selectedSuggestedItem?.address) {
    isUserTypeDisabled = false;
  }
  if (isCustomer && editForm) {
    isUserTypeDisabled = false;
  }
  if (isSuggested) {
    isUserTypeDisabled = false;
  }
  if (isCustomer) {
    showOutletBtn = false;
  }
  if (!isEditForm && !isCustomer && businessId) {
    showOutletBtn = true;
  }
  if (!businessId && !isCustomer) {
    showOutletBtn = true;
  }

  useEffect(() => {
    if (Object.keys(selectedSuggestedItem).length <= 0) {
      reset();
    }
  }, [selectedSuggestedItem]);
  return (
    <div className={styles.businessSetupForm}>
      <div className={styles.right}>
        <div className={`${styles.top} ${styles.overrideTop}`}>
          <div className={`${styles.greetings} ${styles.overrideGeetings}`}>
            <div className={styles.user}>
              {userId || businessId ? "Edit" : "Add"} Buyer
            </div>
            <div className={styles.actionsOnUser}>
              {/* {userId && (
                <Button
                  handleClick={handleOpen}
                  label="Assign Roles"
                  width="180px"
                  height="40px"
                  secondary={true}
                />
              )} */}
              {(userId || businessId) && !editForm && (
                <button
                  className={styles.submit}
                  type="button"
                  onClick={() => setEditForm(!editForm)}
                >
                  Edit Form
                </button>
              )}
            </div>
          </div>
          <form
            className={`${styles.bottom} ${styles.overrideBottom}`}
            onSubmit={
              isCustomer
                ? handleSubmit(submitBuyerCustomerForm)
                : handleSubmit(submit)
            }
          >
            <div className={styles.form}>
              <div className={styles.businessSetup}>
                <div className={styles.setup}>
                  {constants.BusinessSetupForm.businessSetup}
                </div>
                <div className={styles.content}>
                  {constants.BusinessSetupForm.setupContent}
                </div>
                <div className={styles.inputFields}>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="Enter first name"
                      id="firstName"
                      maxWidth="100%"
                      label={"First name"}
                      disabled={isEditForm}
                      onChange={onChangeField("firstName")}
                      register={register("firstName")}
                      errorMessage={errors.firstName?.message}
                      value={watch("firstName")}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="Enter last name"
                      id="lastName"
                      disabled={isEditForm}
                      maxWidth="100%"
                      label={"Last name"}
                      onChange={onChangeField("lastName")}
                      register={register("lastName")}
                      errorMessage={errors.lastName?.message}
                      value={watch("lastName")}
                    />
                  </div>

                  <ClickAwayListener
                    onClickAway={() => {
                      if (!isSuggested) {
                        setInputFocused(false);
                      }
                    }}
                  >
                    <div
                      className={`${styles.input} ${styles.productNameSugg} ${styles.inputField}`}
                    >
                      {/* <div className={styles.label}>Business name</div> */}
                      <InputField
                        placeholder="Enter business name"
                        value={watch("businessName")}
                        id="businessName"
                        maxWidth="100%"
                        label={"Business name"}
                        onChange={onChangeField("businessName")}
                        register={register("businessName")}
                        onFocus={() => setInputFocused(true)}
                        errorMessage={errors.businessName?.message}
                        disabled={isEditForm || isCustomer || isSuggested}
                        icon={isSuggested && cancel}
                        endAdornment
                        handleEndAdorment={resetBusinessDetails}
                      />
                      {inputFocused &&
                        existingBuyersFetchedList &&
                        existingBuyersFetchedList.length > 0 && (
                          <div
                            className={styles.productNameSuggestionsContainer}
                            ref={suggestionRef}
                          >
                            {existingBuyersFetchedList &&
                              existingBuyersFetchedList?.map(
                                (item: any, index: number) => (
                                  <div
                                    key={item.productId}
                                    onClick={() => {
                                      handleSuggestion(item);
                                    }}
                                    className={`${
                                      styles.productSuggestionItem
                                    } ${
                                      index !==
                                      existingBuyersFetchedList.length - 1
                                        ? styles.seperator
                                        : null
                                    }`}
                                  >
                                    {item?.business_name}
                                  </div>
                                )
                              )}
                          </div>
                        )}
                    </div>
                  </ClickAwayListener>
                  <div className={styles.inputField}>
                    <InputField
                      value={watch("contactName")}
                      id="contactName"
                      minWidth={"400px"}
                      label="Primary contact name"
                      placeholder="Enter name here"
                      onChange={onChangeField("contactName")}
                      register={register("contactName")}
                      errorMessage={errors.contactName?.message}
                      disabled={isEditForm || isCustomer || isSuggested}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      id="emailID"
                      disabled={
                        userId || businessId ? true : false || isSuggested
                      }
                      label="Business Email ID"
                      placeholder="Enter email here"
                      onChange={onChangeField("emailID")}
                      register={register("emailID")}
                      value={watch("emailID")}
                      errorMessage={
                        !selectedSuggestedItem?.business_email &&
                        errors.emailID?.message
                      }
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      checkEmailIsExist={checkEmailIsExist}
                      value={
                        selectedSuggestedItem?.business_userEmail ??
                        watch("userEmailID")
                      }
                      checkEmail={true}
                      id="userEmailID"
                      label="User Email ID"
                      placeholder="Enter user email here"
                      onChange={onChangeField("userEmailID")}
                      register={register("userEmailID")}
                      errorMessage={
                        !selectedSuggestedItem?.business_userEmail &&
                        errors.userEmailID?.message
                      }
                      disabled={
                        (userId || businessId ? true : false) || isCustomer
                      }
                    />
                  </div>
                  {!userId && !businessId && (
                    <div className={styles.inputField}>
                      <InputField
                        type={"password"}
                        value={
                          selectedSuggestedItem?.password ?? watch("password")
                        }
                        id="password"
                        label="Password"
                        placeholder="Enter password here"
                        onChange={onChangeField("password")}
                        register={register("password")}
                        errorMessage={errors.password?.message}
                      />
                    </div>
                  )}
                  {!userId && !businessId && (
                    <div className={styles.inputField}>
                      <InputField
                        type={"password"}
                        value={
                          selectedSuggestedItem?.confirmPassword ??
                          watch("confirmPassword")
                        }
                        id="confirmPassword"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        onChange={onChangeField("confirmPassword")}
                        register={register("confirmPassword")}
                        errorMessage={errors.confirmPassword?.message}
                      />
                    </div>
                  )}
                  <div className={styles.uploadFields}>
                    <div className={`${styles.inputField}`}>
                      <InputField
                        value={
                          selectedSuggestedItem
                            ? selectedSuggestedItem?.license_number
                            : watch("licenseNo")
                        }
                        id="licenseNo"
                        label="Trade license number"
                        placeholder="Enter license number"
                        onChange={onChangeField("licenseNo")}
                        register={register("licenseNo")}
                        errorMessage={
                          !selectedSuggestedItem?.license_number &&
                          errors.licenseNo?.message &&
                          errors.licenseNo?.message?.length > 60
                            ? `${(errors.licenseNo?.message as any).slice(
                                0,
                                60
                              )}...`
                            : errors.licenseNo?.message
                        }
                        disabled={isEditForm || isCustomer || isSuggested}
                      />
                      <div className={`${styles.uploadWithInput}`}>
                        <FileUploadCard
                          editForm={
                            !editForm && (userId || businessId)
                              ? true
                              : false || isCustomer || isSuggested
                          }
                          width={"100%"}
                          id={2}
                          accept=".jpg, .jpeg, .png, .pdf"
                          register={register("licenseFile")}
                          error={licenseFileError && "Upload license file"}
                          onChange={onChangeField("licenseFile")}
                          file={licenseImageFile}
                          imageUrl={licenseImageFileUrl}
                          setImageUrl={setLicenseImageFileUrl}
                          setFile={setLicenseImageFile}
                        />
                      </div>
                    </div>
                    <div className={`${styles.inputField}`}>
                      <InputField
                        value={
                          selectedSuggestedItem?.vat_number ?? watch("vatNo")
                        }
                        id="vatNo"
                        label="VAT number (optional)"
                        placeholder="Enter VAT number"
                        onChange={onChangeField("vatNo")}
                        register={register("vatNo")}
                        errorMessage={
                          !selectedSuggestedItem?.vat_number &&
                          errors.vatNo?.message &&
                          errors.vatNo?.message?.length > 60
                            ? `${(errors.vatNo?.message as any).slice(
                                0,
                                60
                              )}...`
                            : errors.vatNo?.message
                        }
                        disabled={isEditForm || isCustomer || isSuggested}
                      />
                      <div className={`${styles.uploadWithInput}`}>
                        <FileUploadCard
                          editForm={
                            !editForm && (userId || businessId)
                              ? true
                              : false || isCustomer || isSuggested
                          }
                          width={"100%"}
                          id={4}
                          accept=".jpg, .jpeg, .png, .pdf"
                          register={register("vatFile")}
                          onChange={onChangeField("vatFile")}
                          file={vatImageFile}
                          error={vatFileError && "Upload vat file"}
                          setFile={setVATImageFile}
                          imageUrl={vatImageFileUrl}
                          setImageUrl={setVATImageFileUrl}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputField}>
                    <Dropdown
                      customDropdownStyles={styles.dropDownMargin}
                      id={"userType"}
                      label="User Type"
                      defaultLabel={userType?.name ?? "Select user type .."}
                      selectedValue={userType}
                      defaultValue={userType}
                      options={
                        userOptions && userOptions.length > 0 && userOptions
                      }
                      disabled={
                        Boolean(isUserTypeDisabled) || Boolean(isSuperUser)
                      }
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      editForm={
                        Boolean(isUserTypeDisabled) || Boolean(isSuperUser)
                      }
                      register={register("userType")}
                      onChange={(selectedOption: ICountry) => {
                        onChangeField("userType")(selectedOption);
                        setUserType(selectedOption);
                      }}
                      errorMessage={errors.userType?.message as any}
                    />
                  </div>
                  {(isStaffUser ||
                    (userType && userType?.role_key === "buyer-staff")) && (
                    <div className={styles.inputField}>
                      <InputField
                        type="number"
                        id="maxOrderLimit"
                        label="Max order limit"
                        placeholder="Enter max order limit"
                        register={register("maxOrderLimit")}
                        onChange={onChangeField("maxOrderLimit")}
                        errorMessage={errors.maxOrderLimit?.message}
                        disabled={isEditForm}
                        value={watch("maxOrderLimit")}
                      />
                    </div>
                  )}

                  <div className={styles.inputField}>
                    <MobileNumberField
                      value={watch("phNo")}
                      defaultValue={
                        mobileNumberProductCode
                          ? mobileNumberProductCode
                          : countriesList &&
                            countriesList.length > 0 &&
                            countriesList.filter(
                              (country) =>
                                country?.dial_codes === ("+971" as any)
                            )[0]
                      }
                      width="100%"
                      overlayWidth={"300px"}
                      label="Phone number"
                      id={"phnNo"}
                      options={countriesList}
                      registerMobileNumber={register("phNo")}
                      registerCountry={register("countryName")}
                      onMobileNumberChange={onChangeField("phNo")}
                      onCountryChange={(selectedOption: ICountry) => {
                        onChangeField("countryName")(selectedOption);
                        setMobileNumberProductCode(selectedOption);
                      }}
                      errorMessage={errors.phNo?.message}
                      mobileNumberField={true}
                      editForm={
                        (!editForm && (userId || businessId)) ||
                        isCustomer ||
                        selectedSuggestedItem?.address
                          ? true
                          : false
                      }
                    />
                  </div>
                  {/* {selectedSuggestedItem &&
                    selectedSuggestedItem?.outletList && (
                      <div
                        className={`${styles.inputField} ${styles.checkInput}`}
                      >
                        <CheckboxButton
                          handleChange={onChangeField("chooseOutlet")}
                          register={register("chooseOutlet")}
                          checked={isOutletChoosed}
                        />
                        <span>Choose Outlet</span>
                      </div>
                    )} */}
                  <div className={`${styles.inputField} `}>
                    {isSuggested && (
                      <Dropdown
                        customDropdownStyles={styles.dropDownMargin}
                        id={"Outlet"}
                        label="Add Outlet"
                        options={
                          selectedSuggestedItem &&
                          selectedSuggestedItem?.business_stores?.map(
                            (el: any) => ({
                              ...el,
                              name: el.saved_as,
                              value: el.saved_as,
                            })
                          )
                        }
                        multiple
                        minWidth="400px"
                        isDropdownOutlineRequired={true}
                        register={register("outlet")}
                        onChange={(selectedOption: any) => {
                          setSelectedOutlet(selectedOption);
                          onChangeField("outlet")(selectedOption);
                          const businessAddress =
                            selectedSuggestedItem?.business_stores
                              ? selectedSuggestedItem?.business_stores[0]
                              : {};
                          if (isBuyerAdmin && selectedOption) {
                            setOutletsData([
                              businessAddress,
                              ...selectedOption,
                            ]);
                          } else if (selectedOption) {
                            setOutletsData(selectedOption);
                          }
                        }}
                        errorMessage={errors.outlet?.message as any}
                        defaultLabel={selectedOutlet?.name ?? "Select Outlet"}
                        selectedValue={selectedOutlet}
                      />
                    )}
                  </div>
                </div>
              </div>
              {isBuyerAdmin && !isSuggested && (
                <div className={styles.businessSetup}>
                  <div className={styles.setup}>
                    {constants.BusinessSetupForm.address}
                    {outletsData?.[0]?.is_default && (
                      <span className={styles.defaultBadge}>Default</span>
                    )}
                  </div>
                  <div className={styles.content}>
                    {constants.BusinessSetupForm.adressContent}
                  </div>
                  <div className={styles.inputFields}>
                    <div
                      className={`${styles.inputField} ${styles.addressField}`}
                    >
                      <InputField
                        value={watch("address")}
                        editForm={
                          isOutletChoosed || selectedSuggestedItem?.address
                        }
                        disabled={
                          isEditForm ||
                          isCustomer ||
                          selectedSuggestedItem?.address
                        }
                        label="Address"
                        placeholder="Enter address"
                        onChange={onChangeField("address")}
                        register={register("address")}
                        errorMessage={
                          !selectedSuggestedItem?.address &&
                          errors.address?.message
                        }
                      />
                    </div>
                    <div className={styles.inputField}>
                      <InputField
                        value={watch("area")}
                        editForm={
                          isOutletChoosed || selectedSuggestedItem?.address
                        }
                        label="Area"
                        placeholder="Enter area here"
                        onChange={onChangeField("area")}
                        register={register("area")}
                        errorMessage={
                          !selectedSuggestedItem?.area && errors.area?.message
                        }
                        disabled={
                          isEditForm ||
                          isCustomer ||
                          selectedSuggestedItem?.address
                        }
                      />
                    </div>
                    <div className={styles.inputField}>
                      <Dropdown
                        editForm={
                          isOutletChoosed ||
                          (!editForm && (userId || businessId)) ||
                          isCustomer ||
                          selectedSuggestedItem?.address
                            ? true
                            : false
                        }
                        id={"country"}
                        label="Country"
                        options={
                          countriesList &&
                          countriesList.filter(
                            (country: ICountry) => country?.iso_2 === "ae"
                          )
                        }
                        minWidth="400px"
                        isDropdownOutlineRequired={true}
                        register={register("country")}
                        onChange={(selectedOption: ICountry) => {
                          onChangeField("country")(selectedOption);
                          setSelectedCountry(selectedOption);
                          !selectedOutlet && handleCountryClick(selectedOption);
                        }}
                        defaultLabel={
                          (selectedCountry && selectedCountry?.name) ??
                          "Select country"
                        }
                        errorMessage={
                          (
                            !selectedSuggestedItem?.country &&
                            (errors.country as any)
                          )?.message
                        }
                        selectedValue={selectedCountry}
                      />
                    </div>

                    <div
                      className={`${styles.inputField} ${styles.addressField}`}
                    >
                      <Dropdown
                        id={"Emirate"}
                        label="Emirate"
                        editForm={
                          isOutletChoosed ||
                          (!editForm && (userId || businessId)) ||
                          isCustomer ||
                          selectedSuggestedItem?.address
                            ? true
                            : false
                        }
                        options={emiratesList}
                        minWidth="400px"
                        isDropdownOutlineRequired={true}
                        register={register("emirates")}
                        onChange={(selectedOption: ICountry) => {
                          onChangeField("emirates")(selectedOption);
                          setSelectedEmirate(selectedOption);
                        }}
                        defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                        errorMessage={
                          (
                            !selectedSuggestedItem?.emirate &&
                            (errors.emirates as any)
                          )?.message
                        }
                        selectedValue={selectedEmirate}
                      />
                    </div>
                    <div className={styles.inputField}>
                      <InputField
                        placeholder="Enter outlet name"
                        id="outletName"
                        disabled={
                          isOutletChoosed ||
                          (!editForm && (userId || businessId)) ||
                          isCustomer ||
                          selectedSuggestedItem?.address
                            ? true
                            : false
                        }
                        maxWidth="100%"
                        label={"Outlet name"}
                        onChange={onChangeField("outletName")}
                        register={register("outletName")}
                        errorMessage={errors.outletName?.message}
                        value={watch("outletName")}
                      />
                    </div>
                  </div>
                </div>
              )}

              {window?.location?.pathname.includes("admin/users/add-buyer") &&
                !window?.location?.href.endsWith("admin/users/add-buyer") &&
                additionalOutletData.length > 0 &&
                !isBuyerAdmin && (
                  <div
                    className={`${styles.inputField} ${styles.verifyBox} ${styles.deliveryAddress}`}
                  >
                    <Dropdown
                      id={"assignOutlets"}
                      label="Modify assigned outlets"
                      options={customerOutletsList}
                      editForm={Boolean(isEditForm) || isSuperUser}
                      selectedValue={selectedOutlets}
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      register={register("assignedOutlets")}
                      onChange={onChangeField("assignedOutlets")}
                      errorMessage={errors.assignedOutlets?.message}
                      multiple={true}
                      defaultLabel="Assign outlets here"
                      disabled={Boolean(isEditForm) || isSuperUser}
                    />
                  </div>
                )}
              {!isSuggested && showOutletBtn && (
                <div className={styles.businessSetup}>
                  <div className={styles.action2}>
                    <button
                      type="button"
                      className={`${styles.submit} ${styles.outletBtn}`}
                      onClick={(e) => {
                        if (!businessId && outletsData.length === 0) {
                          setOutletsData([{}]);
                        }
                        setShowOutlet(true);
                      }}
                    >
                      {constants.BusinessSetupForm.addoutletButton}
                    </button>
                  </div>
                </div>
              )}
              <div className={styles.outletsWrapper}>
                {selectedOutlets?.map((outlet: any, index: number | string) => {
                  return (
                    <SelectAddress
                      key={index}
                      isHoverd={
                        !selectedSuggestedItem?.address && hovered === index
                      }
                      handleMouseOver={handleMouseOver}
                      handleMouseLeave={handleMouseLeave}
                      index={index}
                      handleEdit={handleEditOutlet}
                      handleDelete={handleDeleteOutlet}
                      addressDetails={outlet}
                      isEdit={Boolean(showOutletBtn)}
                    />
                  );
                })}
              </div>
            </div>

            <div className={styles.action}>
              {businessId && currentUserData?.status === "review" ? (
                <>
                  <button
                    className={styles.skip}
                    onClick={() =>
                      window?.location?.pathname?.includes(
                        "/admin/buyer-approval-form/"
                      )
                        ? navigate(routeConfig.buyerBusinessApprovalPage)
                        : navigate(routeConfig.adminAddBuyer)
                    }
                  >
                    Cancel
                  </button>
                  <button
                    style={{ marginLeft: "30px" }}
                    className={styles.skip}
                    onClick={handleSubmit(rejectSelectedBuyer)}
                  >
                    Reject
                  </button>
                  <button
                    style={{ marginLeft: "30px" }}
                    type="submit"
                    className={styles.submit}
                    onClick={handleSubmit(approveSelectedBuyer)}
                  >
                    Approve
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={styles.skip}
                    onClick={() =>
                      window?.location?.pathname.includes(
                        "/admin/buyer-approval-form/"
                      )
                        ? navigate(routeConfig.buyerBusinessApprovalPage)
                        : navigate(routeConfig.adminAddBuyer)
                    }
                  >
                    Cancel
                  </button>
                  <button type="submit" className={styles.submit}>
                    {window?.location?.pathname.includes(
                      "/admin/buyer-approval-form/"
                    ) || currentUserData.status
                      ? "Update"
                      : constants.BusinessSetupForm.submitButton}
                  </button>
                  {businessId && currentUserData.status === "rejected" && (
                    <button
                      type="submit"
                      className={styles.submit}
                      onClick={handleSubmit(approveSelectedBuyer)}
                    >
                      Update & Approve
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
        {showOutlet && (
          <AddOutletDrawer
            showAddOutlet={showOutlet}
            handleClose={handleCloseDrawer}
            countriesList={countriesList}
            currOutletData={currOutletDataRef.current}
            outletsList={outletsData}
            handleAddOutlet={handleAddOutlet}
          />
        )}
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal open={open} onClose={handleClose} className={styles.ratingModal}>
        <div className={styles.modalFullContainer}>
          <div className={styles.heading}>
            <div className={styles.leftContainer}>
              {constants.assignRoleHeader}
            </div>
            <div className={styles.rightContainer}>
              <img src={cancelAction} alt="" onClick={handleClose} />
            </div>
          </div>
          <div className={styles.seperator}></div>
          <div className={styles.modalBody}>
            <div className={styles.body}>
              <div className={styles.ratingContainer}></div>
              <div className={styles.questionContainer}>
                <div className={styles.options}>
                  {rolesOptions.map((item, index) => (
                    <div className={styles.option} key={index}>
                      <CheckboxButton
                        checked={item.checked}
                        handleChange={() => {
                          setRolesOptions(
                            rolesOptions.map((item, i) =>
                              i === index
                                ? { ...item, checked: !item.checked }
                                : item
                            )
                          );
                        }}
                      />
                      <label
                        className={styles.label}
                        onClick={() => {
                          setRolesOptions(
                            rolesOptions.map((item, i) =>
                              i === index
                                ? { ...item, checked: !item.checked }
                                : item
                            )
                          );
                        }}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              label="Submit"
              handleClick={() => {}}
              width="100px"
              customClass={styles.button}
            />

            <Button
              label="Cancel"
              handleClick={onCancel}
              width="100px"
              customClass={styles.button}
              secondary={true}
            />
          </div>
        </div>
      </Modal>
      <DeletePopup
        open={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        title="Delete outlet"
        handleCancel={handleCancelDelete}
        handleConfirm={handleConfirmDelete}
        popupContent="Are you sure you want to delete this outlet ?"
      />
    </div>
  );
}

export default AdminBuyerBusinessSetupForm;
