/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import contact from "../../../../assets/images/contact.svg";
import card1 from "../../../../assets/images/card1.svg";
import card2 from "../../../../assets/images/card2.svg";
import styles from "./ModifyBulkProduct.module.scss";
import IndividualProductCard from "../../../../common/components/individualProductCard/IndividualProductCard";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { createArrayOfObjects, getDynamicMappedKey } from "../ManageCatalogue";
import { fileHeaderFields } from "../bulkUploadConstants";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { constants } from "../../../../common/constants/constants";
import CategoryCard from "../../../../common/components/categoryCard/CategoryCard";

const ModifyBulkProduct = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);

  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  //Validation for product title
  const checkForTitle = (data: any) => {
    // const titleSet = new Set();

    for (let i = 0; i < data.length; i++) {
      const titleValue = data[i].title.value;
      if (!titleValue?.length) {
        setToastData({
          message: `Product title is empty at row number ${i + 1}`,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        return false;
      }
      //  else if (titleSet.has(titleValue)) {
      //   // Duplicate found, return the index of the first duplicate
      //   const duplicateIndex = data.findIndex(
      //     (item: any) => item.title.value === titleValue
      //   );
      //   // setTitleErrorRow(duplicateIndex+1);
      //   if (duplicateIndex) {
      //     setToastData({
      //       message: `Product title is duplicate at row number ${i + 1}`,
      //       status: "Fail",
      //     });
      //     setTimeout(() => {
      //       setToastData(constants.defaultToastData);
      //     }, 3000);
      //     return false;
      //   }
      // }
      // titleSet.add(titleValue);
    }
    return true;
  };
  const isAtleastOneProductValid = (products: any) => {
    for (let i = 0; i < products.length; i++) {
      const productId = products[i]?.product_id;
      const variantIds = Object.entries(products[i]).filter(
        (el) => el[0].includes("variant_id_") && el[1]
      );
      if (productId && variantIds.length > 0) {
        return true;
      }
    }
  };
  const handleEditFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleEditFileUpload");
    const input = event.target;

    const allowedExtensions = ["xlsx", "xls", "csv"];

    if (input?.files && input?.files[0]) {
      const uploadedFile = input.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (allowedExtensions?.includes(fileExtension as string)) {
        try {
          const rows: any = await readXlsxFile(uploadedFile);
          let headerKeys = rows[0].map(
            (el: any) =>
              fileHeaderFields[el.toLowerCase()?.replace(/\s+/g, "")] ||
              getDynamicMappedKey(el.toLowerCase()?.replace(/\s+/g, "")) ||
              el
          );
          if (rows?.length < 7002) {
            const { products, duplicateProductOptionsIndex } =
              createArrayOfObjects(rows);
            console.log(products, "ARRAY OF OBJECTS");
            if (products.length > 0) {
              // console.log(products, "ARRAY OF OBJECTS");
              if (checkForTitle(products)) {
                if (isAtleastOneProductValid(products)) {
                  console.log("products", products);
                  isAdmin
                    ? navigate(routeConfig.adminEditBulkProduct, {
                        state: {
                          name: uploadedFile?.name,
                          tableData: products,
                          headerKeys: headerKeys,
                          duplicateProductOptionsIndex,
                        },
                      })
                    : navigate(routeConfig.editBulkProduct, {
                        state: {
                          name: uploadedFile?.name,
                          tableData: products,
                          headerKeys: headerKeys,
                          duplicateProductOptionsIndex,
                        },
                      });
                } else {
                  setToastData({
                    message: `Sheet containes xproduct details, please verify and try again`,
                    status: "Fail",
                  });
                  setTimeout(() => {
                    setToastData(constants.defaultToastData);
                  }, 6000);
                }
              }
              //  else if (typeof checkForTitle(arrayOfObjects) === "number"){
              //   alert(`name errror, ${checkForTitle(arrayOfObjects)}`)
              // } else {
              //   alert("Title is mandatory for all the products");
              // }
            }
          } else {
            setToastData({
              message: `Please upload less than 1000 products`,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
          }
        } catch (error: any) {
          if (error?.message?.includes('Sheet "Products list" not found')) {
            setToastData({
              message: `Sheet name is not matching, provide a valid sheet with "Products list" as name`,
              status: "Fail",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 6000);
          } else {
            setToastData({ message: `Invalid Excel Template`, status: "Fail" });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
          }
        }
      } else {
        setToastData({
          message: `Please upload a valid Excel file.`,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
    }
  };

  // bulk upload template api call starts

  return (
    <div className={styles.app}>
      <div className={styles.rightBodyContainer}>
        <div className={styles.text}>
          {constants.manageCatalogue.modifyBulkProduct}
        </div>
        <div className={styles.outerBox}>
          <div className={styles.outerBox1}>
            <div className={styles.card1}>
              <IndividualProductCard
                cardType="search"
                imgSrc={card1}
                head="Download manage catalogue"
                id={1}
                title={<div>{}</div>}
                handleCardClick={() => {
                  isAdmin
                    ? navigate(routeConfig.adminDownloadManageCatalogue)
                    : navigate(routeConfig.downloadManageCatalogue);
                }}
                isModify={true}
              />
            </div>
            <div className={styles.card2}>
              <div>
                <label htmlFor="inputNew">
                  <input
                    className={styles.inputField}
                    type="file"
                    id="inputNew"
                    onChange={handleEditFileUpload}
                    accept=".xls, .xlsx"
                    onClick={(event: any) => {
                      event.target.value = null;
                    }}
                  />
                  <CategoryCard
                    cardType="search"
                    imgSrc={card2}
                    head="Modify bulk product"
                    id={3}
                    title={<></>}
                    handleCardClick={() => {}}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={styles.contact}>
            <div className={styles.logo}>
              <img className={styles.imgLogo} src={contact} alt="Contact" />
            </div>
            <div className={styles.contactText}>
              <p className={styles.contactText1}>
                {constants.manageCatalogue.help}
              </p>
              <p className={styles.contactText2}>
                {constants.manageCatalogue.email} |
                {constants.manageCatalogue.phoneNo}
              </p>
            </div>
          </div>
        </div>
      </div>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </div>
  );
};

export default ModifyBulkProduct;
