import { constants } from "../common/constants/constants";
import { productStatus } from "./productUtils";
import placeholder from "../assets/images/placeholder.svg";

export const getCountryByDialCode = (dialCode: string | number, list: any) => {
  return list.find((country: any) => country.dial_codes === dialCode);
};

export function isImgUrlValid(url?: string) {
  if (!url) return false;
  if (url?.split(process.env.REACT_APP_MINIO_FILE_PATH as any)[1]) {
    return true;
  } else {
    return false;
  }
}

export function checkImageValid(url: string) {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.onload = function () {
      console.log("insidec1");
      resolve(true);
    };
    image.onerror = function () {
      console.log("insidec2");

      resolve(false);
    };
    console.log("insidec3");

    image.src = url;
  });
}
export const convertTimeStampToUserTimeZone = (timestamp: string) => {
  try {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateOptions = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      timeZone: userTimeZone,
    };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: userTimeZone,
    };

    const date = new Date(timestamp);
    const formattedDate = new Intl.DateTimeFormat(
      "en-US",
      dateOptions as any
    )?.format(date);
    const formattedTime = new Intl.DateTimeFormat(
      "en-US",
      timeOptions as any
    )?.format(date);

    if (formattedDate === "Invalid Date" || formattedTime === "Invalid Date") {
      return "-";
    }
    return `${formattedDate} at ${formattedTime}`;
  } catch (error) {
    console.error("error while formatting time", error);
    return "-";
  }
};

export const getQuestionFromOrderRating = (
  orderRating: number,
  productRating: number
) => {
  let rating = 0;
  if (productRating && orderRating) {
    rating = (Number(orderRating) + Number(productRating)) / 2;
  }
  if (rating > 4.5) {
    return constants.REVIEW_MESSAGE3;
  } else if (rating > 3) {
    return constants.REVIEW_MESSAGE2;
  } else {
    return constants.REVIEW_MESSAGE1;
  }
};

export const getTextWithoutOverflow = ({
  text,
  length = 20,
}: {
  text: string;
  length?: number;
}) => {
  if (typeof text !== "string") return text;
  const trimmedText: string = text?.trim() || "";
  return trimmedText?.length > length
    ? `${trimmedText?.substring(0, length)}...`
    : trimmedText;
};

export const getTimeReceived = (t: string) => {
  const time = new Date(t);

  // Get the current date and time
  const currentTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime.getTime() - time.getTime();

  // Calculate the difference in hours, days, and months
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const monthsDifference = Math.floor(
    timeDifference / (1000 * 60 * 60 * 24 * 30.44)
  );
  let text: string = "";

  if (monthsDifference >= 1) {
    text = `${monthsDifference} mon.`;
  } else if (daysDifference >= 1) {
    text = `${daysDifference} d.`;
  } else if (hoursDifference >= 1) {
    text = `${hoursDifference} hr.`;
  } else {
    text = `${minutesDifference} min.`;
  }

  return text;
};
export const getRouteParams = (notificationDetails: any) => {
  let routeName = "";
  let params = {};
  if (notificationDetails.Category === "order_status") {
    if (notificationDetails.order_type === "Online") {
      routeName = `/supplier/manage-orders/${notificationDetails?.orderId}`;
    }
  } else {
    routeName = "/supplier/dashboard";
  }
  return { routeName, params };
};

export const formatAdminCatalogueResponse = (
  apiData: any,
  isSearchApiResponse: boolean
) => {
  let data = apiData;
  try {
    data.forEach((ele: any) => {
      ele["icon"] = ele?.image_url;
      ele["productName"] = ele?.title;
      ele["status"] = productStatus(
        isSearchApiResponse ? ele?.status : ele?.product_status
      );
      ele["productStatus"] = ele?.is_deactivated ? "Deactivated" : "Active";
      ele["id"] = ele?.id;
      ele["productId"] = ele?.id;
      ele["productQuantity"] = ele?.product_variant_metadata?.selected_unit;
      ele["brandName"] = ele?.brand?.name ?? "-";
      ele["countryFlag"] = isSearchApiResponse
        ? ele?.country?.flag
        : ele?.origin_country?.flag;
      // ele["countryOrigin"] = ele?.country_flag;
      ele["countryOrigin"] = isSearchApiResponse
        ? ele?.country?.name
        : ele?.origin_country?.name ?? "-";
      ele["productSubCategory"] = isSearchApiResponse
        ? ele?.sub_sub_category?.name
        : ele?.product_sub_sub_category?.name ?? "-";
      ele["productCategory"] = isSearchApiResponse
        ? ele?.category?.name
        : ele?.product_category?.name;
      ele["productCardState"] = isSearchApiResponse
        ? ele?.status
        : ele?.product_status;
      ele["isProductAvailable"] = isSearchApiResponse
        ? ele?.avaliabilty_status
        : ele?.product_avaliabilty_status;
      ele["imageUrl"] = getImage(ele?.images) ?? placeholder; // showing  default img , if img is not provided
      ele["businessName"] = ele?.supplier?.business_name ?? "-";
    });
  } catch (error) {
    console.log("====error ====", error);
  }
  return data;
};

export const getImage = (item: any) => {
  let defaultImage = item?.find((item: any) => item?.metadata !== null);
  if (defaultImage) {
    return defaultImage?.url;
  } else {
    return item?.[0]?.url;
  }
};
