import React, { useEffect, useState } from "react";
import styles from "./ProductCardWithCart.module.scss";
import likedImage from "../../../assets/images/favourite-filled.svg";
import unlikedImage from "../../../assets/images/favourite.svg";
// import watermelonApproved from "../../../assets/images/watermelon-approved.svg";
import offerImage from "../../../assets/images/offer.svg";
import newProductImage from "../../../assets/images/new.svg";
import ProductCardCta from "../productCardCta/ProductCardCta";
import productPlaceholder from "../../../assets/images/placeholder.svg";
import Dropdown from "../../components/dropdown/Dropdown";
import { IProductCardWithCart } from "../../../models/IProductWithCart";
import { IsProductOlderThan30Days } from "../invoiceDetails/invoiceDataTransformation";
import { calculateOfferValue } from "../../../utils/productUtils";
import {
  addProductToCart,
  deleteProductFromCart,
  updateQuantityOfProduct,
} from "../../../services/myCart";
import { useDispatch } from "react-redux";
import { setCartValue } from "../../../reducerSlices/headerSlice";
import { cloneDeep } from "lodash";
import { ToastNotification } from "../toastNotification/ToastNotification";
import { constants } from "../../constants/constants";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import alertToast from "../../../assets/images/alertToast.svg";

const ProductCardWithCart = (item: IProductCardWithCart) => {
  const {
    offerPercentage = 0,
    productName,
    productImage,
    outOfstockStatus,
    supllierType,
    delivered,
    TBC,
    supplierName,
    quantity = 5,
    unit,
    unitValue,
    created_at,
    country,
    liked,
    bundleOptions,
    variants,
    handleClick = () => {},
    deliveredQuantity,
    isSpecialpriceApplied,
  } = item;
  const dispatch = useDispatch();
  const [value, setValue] = useState(bundleOptions && bundleOptions?.[0]);
  // const [count, setCount] = useState(variants && variants?.[0]?.match_quantity);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [prodVariants, setProdVariants] = useState<any[]>(variants ?? []);
  const [selectedVariant, setSelectedVariant] = useState(
    variants && variants?.[0]
  );
  const [showAddItemValue, setShowAddItemValue] = useState<boolean>(
    (variants && variants?.[0]?.match_quantity > 0) ?? false
  );
  const [favorite, setFavorite] = React.useState(liked ?? false);
  const [showDefaultImg, setDefaultImg] = useState<boolean>(false);
  const [isAddToCartLoading, setIsAddToCartLoading] = useState<boolean>(false);
  const handleFavorite = () => {
    setFavorite(!favorite);
  };
  const handleDropdownChange = (value: any) => {
    const variant = variants?.find((variant: any) => variant?.id === value?.id);
    setSelectedVariant(variant);
    setValue(value);
  };
  const handleOnQuantityChange = async (quantityToAdd: number) => {
    const minQuantity = selectedVariant?.min_quantity;
    const activeCartID = JSON.parse(
      localStorage.getItem("activeOutlet") as any
    )?.cartId;
    const isProductAlreadyInCart = selectedVariant?.match ?? false;
    if (quantityToAdd >= minQuantity) {
      if (activeCartID) {
        // update quantity if product already exists in cart
        if (isProductAlreadyInCart) {
          try {
            setIsAddToCartLoading(true);
            const body = {
              quantity: quantityToAdd,
              metadata: {},
            };
            const updateProductToCartResponse = await updateQuantityOfProduct(
              activeCartID,
              selectedVariant?.line_item_id,
              body
            );
            const latestVariants = prodVariants?.map((element: any) => {
              if (element?.id === selectedVariant?.id) {
                element.match_quantity = quantityToAdd;
                let item = updateProductToCartResponse?.cart?.items.find(
                  (ele: any) => ele.variant_id === selectedVariant?.id
                );
                if (item) {
                  let variance: any = cloneDeep(selectedVariant);
                  variance.match_quantity = quantityToAdd;
                  setSelectedVariant({ ...variance });
                }
              }
              return element;
            });
            setProdVariants([...latestVariants]);
            if (updateProductToCartResponse?.cart) {
              setToastData({
                message: "Updated the item quantity",
                status: "Success",
              });
              setTimeout(() => setToastData(constants.defaultToastData), 3000);
            }
          } catch (error) {
            console.log("Unable to update the item quantity ", error);
            setToastData({
              message: "Unable to update the item quantity",
              status: "Failure",
            });
            setTimeout(() => setToastData(constants.defaultToastData), 3000);
          } finally {
            setIsAddToCartLoading(false);
          }
        }
        // add product to cart
        else {
          try {
            setIsAddToCartLoading(true);
            const addProductToCartResponse = await addProductToCart(
              activeCartID,
              selectedVariant?.id,
              quantityToAdd,
              productImage ?? ""
            );
            if (addProductToCartResponse?.cart) {
              const latestVariants = prodVariants?.map((element: any) => {
                if (element?.id === selectedVariant?.id) {
                  element.match = true;
                  element.match_quantity = quantityToAdd;
                  let item = addProductToCartResponse?.cart?.items.find(
                    (ele: any) => ele.variant_id === selectedVariant?.id
                  );
                  if (item) {
                    let variance: any = cloneDeep(selectedVariant);
                    variance.match = true;
                    element.line_item_id = item.id;
                    variance.line_item_id = item.id;
                    variance.match_quantity = quantityToAdd;
                    setSelectedVariant({ ...variance });
                  }
                }
                return element;
              });
              setProdVariants([...latestVariants]);
              dispatch(
                setCartValue(addProductToCartResponse?.cart?.items?.length)
              );
              localStorage.setItem(
                "cartCount",
                addProductToCartResponse?.cart?.items?.length
              );
              setToastData({
                message: "Item added to cart",
                status: "Success",
              });
              setTimeout(() => setToastData(constants.defaultToastData), 3000);
            }
          } catch (error) {
            console.log("add to cart failed ", error);
            setToastData({
              message: "Unable to add item to cart",
              status: "Failure",
            });
            setTimeout(() => setToastData(constants.defaultToastData), 3000);
          } finally {
            setIsAddToCartLoading(false);
          }
        }
      }
    } else if (quantityToAdd > 0 && quantityToAdd < minQuantity) {
      setToastData({
        message: `Minimum ${selectedVariant?.min_quantity} units required for cart addition.`,
        status: "Alert",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
    } else {
      if (isProductAlreadyInCart) {
        try {
          setIsAddToCartLoading(true);
          const deleteProductFromCartResponse = await deleteProductFromCart(
            activeCartID,
            selectedVariant?.line_item_id
          );
          if (deleteProductFromCartResponse?.cart) {
            const latestVariants = prodVariants?.map((element: any) => {
              if (element?.id === selectedVariant?.id) {
                element.match = false;
                element.match_quantity = 0;
                let item = deleteProductFromCartResponse?.cart?.items.find(
                  (ele: any) => ele.variant_id === selectedVariant?.id
                );
                if (item) {
                  let variance: any = cloneDeep(selectedVariant);
                  variance.match = false;
                  element.line_item_id = null;
                  variance.line_item_id = null;
                  setSelectedVariant({ ...variance });
                }
              }
              return element;
            });
            setProdVariants([...latestVariants]);
            dispatch(
              setCartValue(deleteProductFromCartResponse?.cart?.items?.length)
            );
            localStorage.setItem(
              "cartCount",
              deleteProductFromCartResponse?.cart?.items?.length
            );
            setToastData({
              message: "Item deleted from cart",
              status: "Success",
            });
            setTimeout(() => setToastData(constants.defaultToastData), 3000);
          }
        } catch (error) {
          console.log("unable to delete the product from cart", error);
          setToastData({
            message: "Unable to delete item from cart",
            status: "Failure",
          });
          setTimeout(() => setToastData(constants.defaultToastData), 3000);
        } finally {
          setIsAddToCartLoading(false);
        }
      }
    }
  };
  // for invoice
  const calculateTotalAmount = (
    offerPrice: number,
    quantity: number
  ): string => {
    return (offerPrice * quantity).toFixed(2) ?? "0.00";
  };
  useEffect(() => {
    setShowAddItemValue(selectedVariant?.match_quantity > 0);
  }, [selectedVariant]);
  useEffect(() => {
    setFavorite(liked ?? false);
  }, [liked]);
  console.log("item", isSpecialpriceApplied);
  return (
    <>
      <div
        className={`${styles.productCardWithCartContainer} ${
          outOfstockStatus ? styles.disableCard : ""
        }${delivered ? styles.deliveredHeight : ""}`}
        onClick={handleClick}
        style={{ pointerEvents: isAddToCartLoading ? "none" : "auto" }}
      >
        <div className={styles.backgroundImage}>
          {productImage && !showDefaultImg ? (
            <img
              alt="productImage"
              className={styles.tinyLogo}
              src={productImage}
              onError={() => {
                setDefaultImg(true);
              }}
            />
          ) : (
            <img
              alt="productImage"
              className={styles.tinyLogoPlaceholder}
              src={productPlaceholder}
            />
          )}
          <div className={styles.imgHeader}>
            {/* remove false to show tbc in future */}
            {false && TBC && <div className={styles.tbcContainer}>{TBC}</div>}
            {supllierType === "online" && !delivered && (
              <div
                data-testid="favourite-icon"
                className={styles.wishlist}
                onClick={() => handleFavorite()}
              >
                {favorite ? (
                  <img src={likedImage} alt="heart-filled" />
                ) : (
                  <img src={unlikedImage} alt="heart" />
                )}
              </div>
            )}
          </div>
          {selectedVariant?.metadata?.offer > 0 && (
            <div className={styles.offerContainer}>
              <span className={styles.offerDetails}>Upto</span>
              <div className={styles.offerText}>
                <span className={styles.offerValue}>
                  {selectedVariant?.metadata?.offer}
                </span>
                <span className={styles.offerOff}>% off</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.horizontalDivider} />
        <div className={styles.productDetailsCard}>
          <span className={styles.productName} title={productName}>
            {productName}
          </span>
          <div className={styles.productDetailsTags}>
            {country?.flag && (
              <div className={styles.countryFlag}>
                <img
                  src={country?.flag}
                  alt="country-flag"
                  className={styles.country}
                />
              </div>
            )}
            <span className={styles.countryName} title={country?.name}>
              {country?.name}
            </span>
            {supllierType === "online" && (
              <>
                <div className={styles.verticalDivider} />
                {/* <div className={styles.countryFlag}>
                  <img src={watermelonApproved} alt="country-flag" />
                </div> */}
                {selectedVariant?.metadata?.offer > 0 && (
                  <div className={styles.countryFlag}>
                    <img src={offerImage} alt="country-flag" />
                  </div>
                )}
                {!IsProductOlderThan30Days(created_at) && (
                  <div className={styles.countryFlag}>
                    <img src={newProductImage} alt="country-flag" />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.priceSupplierNameContainer}>
            <div className={styles.priceContainer}>
              <div className={styles.salePriceContainer}>
                <span>AED</span>
                <span data-testid="actual-price" className={styles.salePrice}>
                  {!window?.location?.pathname?.includes("invoices-details")
                    ? calculateOfferValue(
                        selectedVariant?.unit_price,
                        selectedVariant?.metadata?.offer
                      )
                        ?.toString()
                        ?.split(".")?.[0]
                    : delivered && item?.offerPrice && deliveredQuantity
                    ? calculateTotalAmount(
                        item?.offerPrice,
                        deliveredQuantity
                      )?.split(".")?.[0]
                    : item?.offerPrice &&
                      item?.quantity &&
                      calculateTotalAmount(
                        item?.offerPrice,
                        item?.quantity
                      )?.split(".")?.[0]}
                </span>
                <span>
                  .
                  {!window?.location?.pathname?.includes("invoices-details")
                    ? `${
                        calculateOfferValue(
                          selectedVariant?.unit_price,
                          selectedVariant?.metadata?.offer
                        )
                          ?.toString()
                          ?.split(".")?.[1]
                      }`
                    : delivered && item?.offerPrice && deliveredQuantity
                    ? calculateTotalAmount(
                        item?.offerPrice,
                        deliveredQuantity
                      )?.split(".")?.[1]
                    : item?.offerPrice &&
                      item?.quantity &&
                      calculateTotalAmount(
                        item?.offerPrice,
                        item?.quantity
                      )?.split(".")?.[1]}
                </span>
              </div>
              {!isSpecialpriceApplied &&
                supllierType === "online" &&
                offerPercentage > 0 && (
                  <div className={styles.originalPriceContainer}>
                    <span className={styles.originalPrice}>
                      {" "}
                      {!window?.location?.pathname?.includes("invoices-details")
                        ? selectedVariant?.unit_price?.toFixed(2)
                        : item?.actualPrice &&
                          item?.quantity &&
                          Number(
                            item?.actualPrice *
                              (delivered && deliveredQuantity
                                ? deliveredQuantity
                                : item?.quantity)
                          )?.toFixed(2)}
                    </span>
                    <span className={styles.aedLabel}> AED</span>
                  </div>
                )}
            </div>
            {supllierType === "online" && (
              <>
                <div className={styles.supplierName} title={supplierName}>
                  {supplierName}
                </div>
                {!window?.location?.pathname?.includes("invoices-details") && (
                  <div
                    className={styles.moq}
                    title={selectedVariant?.min_quantity}
                  >
                    Min.Order Quantity:{" "}
                    <span>{selectedVariant?.min_quantity}</span>
                  </div>
                )}
              </>
            )}
          </div>
          {delivered ? (
            <div className={styles.delivered}>
              {delivered && deliveredQuantity ? deliveredQuantity : quantity} x{" "}
              {unitValue ? unitValue : ""} {unit ? unit : ""}
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              {outOfstockStatus ? (
                <div className={styles.outOfStockButton}>
                  <span>Out of Stock</span>
                </div>
              ) : (
                <div
                  className={styles.addToCartButton}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dropdown
                    onChange={handleDropdownChange}
                    minWidth={"96px"}
                    overlayWidth={"96px"}
                    height={"34px"}
                    defaultLabel={value?.name}
                    selectedValue={value}
                    label=""
                    options={bundleOptions}
                    id="dropdown-bundle-options"
                    isMarketplace={true}
                  />
                  <ProductCardCta
                    key={selectedVariant?.id}
                    // value={count}
                    // setValue={setCount}
                    minOrderQuantity={selectedVariant?.min_quantity}
                    matchQuantity={selectedVariant?.match_quantity}
                    width="96px"
                    handleOnQuantityChange={handleOnQuantityChange}
                    showAddItemValue={showAddItemValue}
                    setShowAddItemValue={setShowAddItemValue}
                    selectedVariant={selectedVariant}
                    isAddToCartLoading={isAddToCartLoading}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={
            toastData.status === "Success"
              ? successToast
              : toastData.status === "Alert"
              ? alertToast
              : errorToast
          }
          content={toastData?.message}
          position={"top-right"}
          autoClose={3000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductCardWithCart;
