/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { urlConstants } from "../common/constants/urlConstants";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../reducerSlices/loaderSlice";

interface UseAxiosProps<T> {
  axiosParams: AxiosRequestConfig;
  method?: AxiosRequestConfig["method"];
}

interface UseAxiosResponse<T> {
  error: AxiosError | undefined;
  loading: boolean;
  fetchData: (fetchParams?: UseAxiosProps<T>) => Promise<T | undefined>;
  data: T | undefined;
}

export const useAxios = <T>({
  axiosParams = {},
  method = "GET",
}: UseAxiosProps<T>): UseAxiosResponse<T> => {
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const fetchData = async (fetchParams?: UseAxiosProps<T>) => {
    const mutatedParams = fetchParams ? {...(axiosParams || {}), ...fetchParams.axiosParams} : axiosParams;
    const mutatedMethod = fetchParams?.method ? fetchParams.method : method;
    const url = mutatedParams.url?.includes(urlConstants.BASE_URL)
      ? mutatedParams.url
      : `${urlConstants.BASE_URL}${mutatedParams.url}`;
    mutatedParams.url = url;
    setLoading(true);
    try {
      const result: AxiosResponse<T> = await axios.request<T>({
        ...mutatedParams,
        method: mutatedMethod,
      });
      setData(result?.data);
      return result?.data;
    } catch (err) {
      setError(err);
      dispatch(updateIsLoading(false));
      return err as any;
    } finally {
      setLoading(false);
    }
  };
  return { data, error, loading, fetchData };
};
