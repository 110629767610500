import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data : [
    {
      id: "1",
      productCode: "MR-VW00003",
      productName: "Sundrop Peanut Butter, Crunchy",
      productBrand: "Fresho",
      orderItems: "50 Kg",
      itemAmount: "AED 890.00",
      updatedAmount: "AED 890.00",
    },
    {
      id: "2",
      productCode: "MR-VW00004",
      productName: "Organic Brown Rice, 5kg",
      productBrand: "HealthyHarvest",
      orderItems: "10 bags",
      itemAmount: "USD 50.00",
      updatedAmount: "USD 50.00",
    },
    {
      id: "3",
      productCode: "MR-VW00005",
      productName: "Fresh Apples, Mixed Variety",
      productBrand: "FruitFusion",
      orderItems: "30 Kg",
      itemAmount: "CAD 120.00",
      updatedAmount: "CAD 120.00",
    },
    {
      id: "4",
      productCode: "MR-VW00006",
      productName: "Premium Red Wine, 750ml",
      productBrand: "Vineyard Select",
      orderItems: "12 bottles",
      itemAmount: "EUR 240.00",
      updatedAmount: "EUR 240.00",
    },
    {
      id: "5",
      productCode: "MR-VW00007",
      productName: "Classic Blue Jeans, Straight Fit",
      productBrand: "DenimStyle",
      orderItems: "20 pairs",
      itemAmount: "USD 350.00",
      updatedAmount: "USD 350.00",
    },
    {
      id: "6",
      productCode: "MR-VW00008",
      productName: "Soft Bath Towels, Set of 4",
      productBrand: "HomeComforts",
      orderItems: "5 sets",
      itemAmount: "GBP 45.00",
      updatedAmount: "GBP 45.00",
    },
    {
      id: "7",
      productCode: "MR-VW00009",
      productName: "Gourmet Chocolate Assortment",
      productBrand: "ChocoBliss",
      orderItems: "8 boxes",
      itemAmount: "USD 75.00",
      updatedAmount: "USD 75.00",
    },
    {
      id: "8",
      productCode: "MR-VW00010",
      productName: "Wireless Bluetooth Earphones",
      productBrand: "AudioSync",
      orderItems: "15 pairs",
      itemAmount: "JPY 3500.00",
      updatedAmount: "JPY 3500.00",
    },
    {
      id: "9",
      productCode: "MR-VW00011",
      productName: "Cozy Knit Sweater, Charcoal",
      productBrand: "WinterWarm",
      orderItems: "25 pieces",
      itemAmount: "CAD 180.00",
      updatedAmount: "CAD 180.00",
    },
    {
      id: "10",
      productCode: "MR-VW00012",
      productName: "Green Tea Infusion Pack",
      productBrand: "TeaLovers",
      orderItems: "50 packs",
      itemAmount: "USD 28.00",
      updatedAmount: "USD 28.000",
    },
    {
      id: "11",
      productCode: "MR-VW00013",
      productName: "Stainless Steel Cooking Pot, 8L",
      productBrand: "KitchenEssentials",
      orderItems: "3 pots",
      itemAmount: "EUR 75.00",
      updatedAmount: "EUR 75.00",
    },
    {
      id: "12",
      productCode: "MR-VW00014",
      productName: "Fitness Resistance Bands Set",
      productBrand: "ActiveLife",
      orderItems: "10 sets",
      itemAmount: "USD 42.00",
      updatedAmount: "USD 42.00",
    },
    {
      id: "13",
      productCode: "MR-VW00015",
      productName: "Laptop Shoulder Bag, Black",
      productBrand: "TechCarry",
      orderItems: "15 bags",
      itemAmount: "GBP 55.00",
      updatedAmount: "GBP 55.00",
    },
    {
      id: "14",
      productCode: "MR-VW00016",
      productName: "Scented Candle Trio",
      productBrand: "RelaxNest",
      orderItems: "5 sets",
      itemAmount: "USD 28.00",
      updatedAmount: "USD 28.00",
    },
    {
      id: "15",
      productCode: "MR-VW00017",
      productName: "Decorative Throw Pillows, Set of 2",
      productBrand: "HomeVibe",
      orderItems: "20 sets",
      itemAmount: "AUD 65.00",
      updatedAmount: "AUD 65.00",
    },
    {
      id: "16",
      productCode: "MR-VW00018",
      productName: "Professional Artist Paintbrushes",
      productBrand: "ArtMasters",
      orderItems: "10 sets",
      itemAmount: "USD 50.00",
      updatedAmount: "USD 50.00",
    },
    {
      id: "17",
      productCode: "MR-VW00019",
      productName: "Herbal Shampoo and Conditioner Set",
      productBrand: "NaturElixir",
      orderItems: "30 sets",
      itemAmount: "INR 750.00",
      updatedAmount: "INR 750.00",
    },
    {
      id: "18",
      productCode: "MR-VW00020",
      productName: "Leather Bound Journal, Vintage Style",
      productBrand: "WriteAway",
      orderItems: "8 journals",
      itemAmount: "USD 75.00",
      updatedAmount: "USD 75.00",
    },
    {
      id: "19",
      productCode: "MR-VW00021",
      productName: "Portable Camping Hammock",
      productBrand: "OutdoorEscape",
      orderItems: "12 hammocks",
      itemAmount: "USD 65.00",
      updatedAmount: "USD 65.00",
    },
    {
      id: "20",
      productCode: "MR-VW00022",
      productName: "Men's Grooming Kit, Deluxe Edition",
      productBrand: "GentlemanEssentials",
      orderItems: "15 kits",
      itemAmount: "GBP 90.00",
      updatedAmount: "GBP 90.00",
    },
  ],
};

const orderedProductsDetailsSlice = createSlice({
  name: "orderedProductsDetails",
  initialState,
  reducers: {
  },
});

export default orderedProductsDetailsSlice.reducer;
