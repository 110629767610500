import React from "react";
import styles from "./RangeDatePicker.module.scss";
import DatePickerComponent from "../datePicker/DatePicker";
import { IRangeDatePickerProps } from "../../../models/IRangeDatePickerProps";

const RangeDatePicker = ({
  startlabel,
  endlabel,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleStartDateChange,
  handleEndDateChange,
  errorMessage,
  isInvalidDateRange,
  customClass,
  endPlaceholder,
  startPlaceholder,
  disablePast,
  startDateDisbaleFuture,
  endDateDisbaleFuture

}: IRangeDatePickerProps) => {
  // const currentDate = new Date();
  // startDate = startDate ?? currentDate;
  // endDate = endDate ?? currentDate;

  return (
    <div className={`${styles.container} ${customClass && customClass}`}>
      <div className={styles.individualDateContainer}>
        {startlabel && (
          <p
            data-testid="startLabel"
            className={isInvalidDateRange ? styles.error : styles.label}
          >
            {startlabel}
            {/* <sup>*</sup> */}
          </p>
        )}
        <DatePickerComponent
          key={`start_date`}
          data-testid="datePickerStart"
          date={startDate}
          setDate={setStartDate}
          handleDateChange={handleStartDateChange}
          showOuterError={true}
          placeholder={startPlaceholder}
          disablePast={disablePast}
          disableFuture={startDateDisbaleFuture}
        />
        {isInvalidDateRange && (
          <p data-testid="errorMessageRange" className={styles.errorMessage}>
            {errorMessage}
          </p>
        )}
      </div>
      <div className={styles.individualDateContainer}>
        {endlabel && (
          <p
            data-testid="endLabel"
            className={isInvalidDateRange ? styles.error : styles.label}
          >
            {endlabel}
            {/* <sup>*</sup> */}
          </p>
        )}
        <DatePickerComponent
          key={`end_date`}
          date={endDate}
          setDate={setEndDate}
          handleDateChange={handleEndDateChange}
          showOuterError={true}
          placeholder={endPlaceholder}
          disablePast={disablePast}
          disableFuture={endDateDisbaleFuture}
        />
      </div>
      <div className={styles.seperator}></div>
    </div>
  );
};

export default RangeDatePicker;

// implementation of date range picker in the parent component

// const [selectedDate, setSelectedDate] = useState<any>();
// const [startChanged, setStartChanged] = useState(false);
// const [endChanged, setEndChanged] = useState(false);

// const handleDateChange = (date: Date | null) => {
//   if (date) {
//     setSelectedDate(date);
//   }
// };

// const [startDate, setStartDate] = useState<any>();
// const [endDate, setEndDate] = useState<any>();
// const handleStartDateChange = (selectedDate: Date | null) => {
//   if (selectedDate) {
//     const adjustedDate = new Date(selectedDate.toLocaleString());
//     setStartDate(adjustedDate);
//     setStartChanged(true);
//   }
// };
// const handleEndDateChange = (selectedDate: Date | null) => {
//   if (selectedDate) {
//     const adjustedDate = new Date(selectedDate.toLocaleString());
//     setEndDate(adjustedDate);
//     setEndChanged(true);
//   }
// };
// const isInvalidDateRange =
//   startChanged && endChanged && endDate.getTime() < startDate.getTime();
