import styles from "./AddCertificate.module.scss";
import { useState } from "react";
import React from "react";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import FileUploadCard from "../../../../common/components/fileUploadCard/FileUploadCard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePickerComponent from "../../../../common/components/datePicker/DatePicker";
import { useDispatch } from "react-redux";
import {
  setPestCertificate,
  setCertificationID,
  setSignedContract,
} from "../../../../reducerSlices/accountDetailsSlice";
import { IAddCertificateProps } from "../../../../models/IAddCertificateProps";
// import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
// import errorToast from '../../../../assets/images/errorToast.svg';
// import { constants } from "../../../../common/constants/constants";


const AddCertificate = ({ setAddCertificate }: IAddCertificateProps) => {
  const [contractImageFile, setContractImageFile] = useState("");
  const [reportImageFile, setReportImageFile] = useState("");
  // const [toastData, setToastData] = useState(constants.defaultToastData);
  const schema = yup.object({
    licenseNumber: yup
      .string()
      .required("Enter any Report/ application/ license number"),
    issuingoOrganisation: yup
      .string()
      .required("Enter the name of issuing organisation"),
    contract: yup.mixed().required("Upload contract file"),
    report: yup.mixed().required("Upload report file"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
    },
    [setValue]
  );

  // useEffect(()=>{
  //   setToastData({
  //     message: "Add certificate will be done in the next phase",
  //     status: "success",
  //   });
  //   setTimeout(() => {
  //     setToastData(constants.defaultToastData);
  //   }, 5000);
  // },[])
  

  const dispatch = useDispatch();
  const onSubmit = (formData: any) => {
    dispatch(setPestCertificate(formData));
    dispatch(setSignedContract(formData.report));
    dispatch(setCertificationID(formData.licenseNumber));
    setAddCertificate(false);
  };

  const values = getValues();
  const [date, setDate] = useState<any>();
  const handleStartDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      const adjustedDate = new Date(selectedDate.toLocaleString());
      setDate(adjustedDate);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          Pest Control Contract & latest pest control inspection report
        </div>
        <form
          className={styles.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formField}>
            <InputField
              maxWidth="100%"
              id="verificationNumber"
              label="Report/ application/ license number"
              placeholder="XYZ123456"
              onChange={onChangeField("verificationNumber")}
              register={register("licenseNumber")}
              errorMessage={errors.licenseNumber?.message}
            />
          </div>

          <div className={styles.formField}>
            <InputField
              maxWidth="100%"
              id="issuingoOrganisation"
              label="Issuing organisation"
              placeholder="Dubai municipality"
              onChange={onChangeField("issuingoOrganisation")}
              register={register("issuingoOrganisation")}
              errorMessage={errors.issuingoOrganisation?.message}
            />
          </div>

          {/* Date field to be changed later */}
          <div className={styles.formField}>
            <div className={styles.dateContainer}>
              <DatePickerComponent
                label="Contract expiry date"
                setDate={setDate}
                date={date}
                handleDateChange={handleStartDateChange}
                showOuterError={false}
                errorMessage="Please seclect a date"
                hideAsterisk={true}
              />
            </div>
          </div>
          <div className={styles.formField}>
            <label className={styles.label}>Upload contract</label>
            <div className={styles.uploadContainer}>
              <FileUploadCard
                hoverButtons
                width={"100%"}
                id={4}
                register={register("contract")}
                error={values.contract && "Upload contract file"}
                onChange={onChangeField("contract")}
                file={contractImageFile}
                setFile={setContractImageFile}
              />
            </div>
          </div>

          <div className={styles.formField}>
            <label className={styles.label}>Upload report</label>
            <div className={styles.uploadContainer}>
              <FileUploadCard
                hoverButtons
                width={"100%"}
                id={5}
                register={register("report")}
                error={values.report && "Upload report file"}
                onChange={onChangeField("report")}
                file={reportImageFile}
                setFile={setReportImageFile}
              />
            </div>
          </div>

          <div className={styles.buttonConatiner}>
            <button className={styles.submit} type="submit">
              Add Report
            </button>
          </div>
        </form>
      </div>
      {/* {toastData?.message ? (
        <ToastNotification
          icon={ errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )} */}
    </>
  );
};

export default AddCertificate;
