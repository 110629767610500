/* eslint-disable react-hooks/rules-of-hooks */
import introJs from "intro.js";
import dashboardCoachIcon from "../../../assets/images/dashboard-coach-icon.svg";
import globalSearchoachIcon from "../../../assets/images/global-search-coach.svg";
import leftArrow from "../../../assets/images/left-arrow.svg";
import manageCoachIcon from "../../../assets/images/manage-coach-icon.svg";
import notificationSearchoachIcon from "../../../assets/images/notification-coach-icon.svg";
import productCoachIcon from "../../../assets/images/product-catalogue.svg";
import axios from "axios";
export interface TourStep {
  id: string;
  title: string;
  intro: string;
  element?: any;
}

export class OnBoardingIntro {
  stepCount!: number;
  totalSteps!: number;
  helperLayer!: any;

  user: any = localStorage.getItem("loggedInUser");

  businessDetails = JSON.parse(this.user)?.business_details || [];
  customerId = this.businessDetails[0]?.customer_id;
  businessId = this.businessDetails[0]?.business_setup_id;
  requestData = {
    coachflowstatus: true,
    ...(this.customerId && { customer_id: this.customerId }),
    ...(this.businessId && { business_id: this.businessId }),
  };
  steps: TourStep[] = [
    {
      id: "welcome",
      title: `Welcome to Watermelon ${
        JSON.parse(this.user)?.first_name && JSON.parse(this.user)?.last_name
          ? JSON.parse(this.user)?.first_name +
            " " +
            JSON.parse(this.user)?.last_name
          : "User"
      }`,
      intro: `<div class="tour-card">
        <div class="welcome-card-details">
          <h4 class="title">
            <span>Welcome to Watermelon ${
              JSON.parse(this.user)?.first_name &&
              JSON.parse(this.user)?.last_name
                ? JSON.parse(this.user)?.first_name +
                  " " +
                  JSON.parse(this.user)?.last_name
                : "User"
            }</span>
          </h4>
          <p class="desc">You are now part of the Watermelon Family. Click below to start exploring Watermelon features</p>
        </div>
        <div class="welcome-card-footer">
          <div class="link-primary dismiss-button">Dismiss</div>
          <div class="get-started">
            <span class='intro-next-button'>Get started</span>
            <img src="${leftArrow}" alt="" class='intro-next-button'/>
          </div>
        </div>
      </div>`,
    },
    {
      id: "dashboard",
      title: "Dashboard",
      element: document.querySelector(".dashboard-nav-bar"),
      intro: this.createIntroCard(
        "Dashboard",
        dashboardCoachIcon,
        "This is your default landing screen where you will see a brief information about Order Deliveries, Suppliers, Payments and Inventory"
      ),
    },

    {
      id: "manage-orders",
      title: "Manage orders",
      element: document.querySelector(".orders"),
      intro: this.createIntroCard(
        "Manage orders",
        manageCoachIcon,
        "Seamlessly manage your orders and unveil the secrets of specific order details. Stay on top of your magical transactions with ease."
      ),
    },
    {
      id: "product-catalogue",
      title: "Product catalogue",
      element: document.querySelector(".product"),
      intro: this.createIntroCard(
        "Product catalogue",
        productCoachIcon,
        "Unleash your Supplier superpowers! Showcase and revamp your product offerings to captivate eager buyers."
      ),
    },
    {
      id: "global-search",
      title: "Global search",
      element: document.querySelector(".search-container"),
      intro: this.createIntroCard(
        "Global search",
        globalSearchoachIcon,
        "Find anything and everything on our platform! Search Away!"
      ),
    },

    {
      id: "notifications",
      title: "Notifications",
      element: document.querySelector(".notifications-nav-bar"),
      intro: this.createIntroCard(
        "Notifications",
        notificationSearchoachIcon,
        "Attention! Stay in the know with our magical notifications. Get updates on app actions and activities, like a secret message from the digital realm. Don't miss a beat!"
      ),
    },
  ];
  intro: any;

  coachFlowAPI = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}store/onboarding/coachflow`,
        {
          ...this.requestData,
        }
      );
      if(response?.data?.status === "SUCCESS" || response?.status == 200){
        let userData: any = localStorage.getItem("loggedInUser");
        userData = userData && JSON.parse(userData);
        userData.supplier_db = true;
        localStorage?.setItem("loggedInUser",JSON.stringify(userData));
      }
    } catch (error) {
      console.error("Error making the coachFlow request:", error);
    }
  };
  constructor(isFirstLogin: boolean) {
    this.totalSteps = this.steps.length;
    const intro = introJs().setOptions({
      showBullets: false,
      steps: this.steps,
      disableInteraction: true,
      scrollPadding: 10,
      scrollToElement: false,
    });

    document.addEventListener("click", (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("intro-next-button")) {
        const currentStep = intro.currentStep(); // Retrieve the current step count
        intro.nextStep();
        if (currentStep !== undefined) {
          this.updateStepCount(currentStep + 1);
          if (currentStep === this.totalSteps - 2) {
            let userData: any = localStorage.getItem("loggedInUser");
            userData = userData && JSON.parse(userData);
            if (userData && !userData.supplier_db) {
              // this.coachFlowAPI();
            }
            if (userData) {
              userData.supplier_db = true;
              localStorage.setItem("loggedInUser", JSON.stringify(userData));
            }
          }
        }
      }
    });

    // Add event listener for the previous button
    document.addEventListener("click", (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("intro-previous-button")) {
        const currentStep = intro.currentStep();
        if (currentStep !== undefined) {
          intro.previousStep();
          this.updateStepCount(currentStep - 1);
        }
      }
    });
    // Add event listener for the dismiss button
    document.addEventListener("click", (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("link-primary")) {
        intro.exit(true); // Close the tour and mark it as complete
        let a = 1;
        if (a === 1) {
          let userData: any = localStorage.getItem("loggedInUser");
          userData = userData && JSON.parse(userData);
          if (userData && !userData.supplier_db) {
            // this.coachFlowAPI();
          }
          if (userData) {
            userData.supplier_db = true;
            localStorage.setItem("loggedInUser", JSON.stringify(userData));
          }
          a++;
        }
        // localStorage.setItem("onboardingCompleted", "true");
      }
    });

    // Add event listener for the dismiss button
    document.addEventListener("click", (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("link-primary")) {
        // intro.exit();
      }
    });
    const userData = localStorage.getItem("loggedInUser");
    const onboardingCompleted = userData && JSON.parse(userData).supplier_db;
    if (isFirstLogin && !onboardingCompleted) {
      this.coachFlowAPI();
      intro.start();
      isFirstLogin = false;
    } else {
      intro.start();
      // intro.nextStep();
      // this.updateStepCount(intro.currentStep() + 1);
    }

    this.helperLayer = document.querySelector(".introjs-helperLayer");

    // Inside your constructor or initialization code
    if (this.steps.length > 0) {
      // If there are steps, check if it's the last step and hide the right arrow button
      if (this.steps[this.steps.length - 1].element) {
        const introNextButton =
          this.steps[this.steps.length - 1].element.querySelector(
            ".intro-next-button"
          );
        if (introNextButton) {
          introNextButton.style.display = "none !important"; // Hide the right arrow button
        }
      }
    }
  }

  createIntroCard(title: string, imgSrc: string, description: string) {
    this.stepCount = this.stepCount ? this.stepCount + 1 : 1;
    const totalSteps = 5;
    const showNextButton = this.stepCount !== totalSteps;
    return `<div class="tour-card step-${this.stepCount}">
      <div class="card-details">
        <h4 class="title">
          <img src="${imgSrc}" alt="" />
          <span>${title}</span>
        </h4>
        <p class="desc">${description}</p>
      </div>
      <div class="footer">
        <div class="link-primary dismiss-button">Dismiss</div>
        <div class="get-started">
          ${this.stepCount !==1 ? `<img  src="${leftArrow}" alt="" class="intro-previous-button" />`: ``}
          <h3>${this.stepCount} <span class='of'>of</span> ${totalSteps}</h3>
          <img src="${
            showNextButton ? leftArrow : ""
          }" alt="" class="intro-next-button" />
          </div>
      </div>
    </div>`;
  }

  updateStepCount(currentStep: number) {
    if (this.helperLayer) {
      if (currentStep === 0) {
        this.helperLayer.classList.remove("intro-highlight-2");
        this.helperLayer.classList.add("intro-highlight");
      } else if (currentStep === 1) {
        this.helperLayer.classList.remove("intro-highlight");
        this.helperLayer.classList.remove("intro-highlight-2");
        this.helperLayer.classList.add("intro-highlight-1");
      } else if (currentStep === 2) {
        this.helperLayer.classList.remove("intro-highlight-1");
        this.helperLayer.classList.remove("intro-highlight-2");
        this.helperLayer.classList.add("intro-highlight-12");
      } else if (currentStep === 3) {
        this.helperLayer.classList.remove("intro-highlight-1");
        this.helperLayer.classList.remove("intro-highlight-12");
        this.helperLayer.classList.add("intro-highlight-13");
      } else if (currentStep === 4) {
        this.helperLayer.classList.remove("intro-highlight-1");
        this.helperLayer.classList.remove("intro-highlight-13");
        this.helperLayer.classList.add("intro-highlight-14");
      } else if (currentStep === 5) {
        this.helperLayer.classList.remove("intro-highlight");
        this.helperLayer.classList.remove("intro-highlight-14");
        this.helperLayer.classList.add("intro-highlight-15");
      }
    }
  }
}
