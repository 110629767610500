import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import IDropdownProps from "../../../../models/IDropdownProps";
import styles from "./dropdown.module.scss";

const Dropdown = ({
  label,
  options,
  defaultLabel,
  minWidth,
  selectedValue,
  isDropdownOutlineRequired,
  onChange,
  overlayWidth,
  id,
  errorMessage,
  margin,
  isCountryList = false,
  customClass,
  icon,
  mobileNumberField,
  register,
  editForm,
  defaultValue,
  borderRightRemove,
  disabled,
  ismobileDropDown,
  mobileNumberWidth,
  customDropdownStyles,
  disablePortal,
  boxShadow = false,
  isfilters = false,
  isOutlets = false,
}: IDropdownProps) => {
  const [value, setValue] = useState(
    selectedValue && selectedValue?.value ? selectedValue?.value : defaultLabel
  );

  useEffect(() => {
    if (selectedValue?.value) {
      setValue(selectedValue?.value);
    } else {
      setValue(defaultLabel);
    }
  }, selectedValue);
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event: any) => {
    let value = event?.target?.value;
    let item = options.find((option: any) => option.value === value);
    setValue(value);
    onChange(item);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getPosition = () => {
    return (
      `${
        Number(document.getElementById(id)?.getBoundingClientRect().x) - 10
      }px !important` ?? ""
    );
  };
  let menuProps: any = {
    disablePortal: disablePortal ?? false,
    PaperProps: {
      sx: {
        width: overlayWidth,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15) !important",
        left: getPosition(),
        maxHeight: "255px",
        "& .MuiMenuItem-root": {
          color: "#1C1919",
          fontSize: "14px",
          letterSpacing: "0.5px",
          fontFamily: "UrbanistRegular",
          overflow: "hidden",
          display: "block",
          textOverflow: "ellipsis",
          padding: "8px 20px",
          margin: "2px 0",
        },
      },
    },
  };
  return (
    <>
      <div
        style={{
          margin: `${margin}`,
          minWidth: `${minWidth}`,
        }}
        className={`${mobileNumberField ? "" : styles.inputLabelStyles} ${
          errorMessage ? styles.errorStyle : null
        } ${disabled ? styles.disabledIbanLabel : null}`}
      >
        {label}
        <FormControl
          className={`${styles.formControl} ${
            mobileNumberWidth ? styles.mobileNumberWidth : ""
          } ${customDropdownStyles ? customDropdownStyles : null}`}
        >
          {icon && (
            <div className={styles.iconContainer}>
              {<img src={icon} alt="icon" />}
            </div>
          )}
          <Select
            data-testid={id}
            id={id}
            {...register}
            onChange={handleChange}
            renderValue={(renderValue) => {
              let val =
                options &&
                options.find((ele: any) => ele.value === renderValue);
              if (!val) {
                val =
                  options && options.find((ele: any) => ele.value === value);
              }
              return (
                (mobileNumberField ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <img
                      className={styles.countryIcon}
                      src={val?.flag}
                      alt={val?.flag}
                    />
                    + {val?.dial_codes ?? defaultLabel}
                  </div>
                ) : isfilters || isOutlets ? (
                  <>{val?.name ?? defaultLabel}</>
                ) : (
                  <div>{val?.dial_codes ?? defaultLabel}</div>
                )) ?? defaultLabel
              );
            }}
            displayEmpty={true}
            value={selectedValue ? selectedValue?.value : ""}
            onClose={toggleDropdown}
            disabled={editForm ?? false}
            onOpen={toggleDropdown}
            title={selectedValue ? selectedValue?.name : ""}
            className={`${styles.select} ${
              mobileNumberField ? styles.mobileSelect : null
            } ${customClass && customClass} ${
              isDropdownOutlineRequired ? styles.selectWithBorder : {}
            }
                        ${value === defaultLabel ? styles.placeholder : ""}
                        ${
                          isOpen && isDropdownOutlineRequired
                            ? styles.focused
                            : null
                        }
                        ${
                          errorMessage && isDropdownOutlineRequired
                            ? styles.errorBorder
                            : null
                        }`}
            sx={{
              boxShadow: boxShadow && "none !important",
              width: minWidth,
              ".Mui-focused": {},
              "& .MuiOutlinedInput-notchedOutline": {
                border: "unset !important",
              },
              "& .MuiSelect-outlined": {
                padding: "0 !important",
                paddingRight: "32px !important",
              },
              "& .MuiSvgIcon-root": {
                display: "none !important",
              },
            }}
            MenuProps={menuProps}
          >
            {options &&
              options?.map((option: any, ind: number) => (
                <MenuItem
                  data-testid={option.value}
                  value={mobileNumberField ? option.dial_codes : option.value}
                  key={`menuItem_${ind}`}
                  title={option.name}
                >
                  {isCountryList ? (
                    <div className={styles.childRow}>
                      <img
                        className={styles.countryIcon}
                        src={option.icon}
                        alt={option.icon}
                      />
                      <div className={styles.rowTextStyle}>
                        {mobileNumberField ? option.dial_codes : option.name}
                      </div>
                      <div className={styles.rowSeperator}></div>
                      <div className={styles.rowTextStyle}>{option.value}</div>
                    </div>
                  ) : (
                    option.name
                  )}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      {errorMessage ? (
        <div className={styles.errorMessage}>{errorMessage.toString()}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default Dropdown;
