/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import businessSetupIcon from "../../../../assets/images/businessSetupIcon.svg";
import Breadcrumb from "../../../../common/components/breadCrumb/BreadCrumb";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import FileUploadCard from "../../../../common/components/fileUploadCard/FileUploadCard";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../../common/components/formFields/mobileNumberField/mobileNumberField";
import RadioButton from "../../../../common/components/radio/RadioButton";
import {
  DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO,
  constants,
} from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import useFileUpload from "../../../../services/useUploadImage";
import useFetchCountries from "../../../../services/useCountry";
import { ISupplierSetupFormData } from "../../../../models/ISupplierSetupFormData";
import { useAxios } from "../../../../services/useAxios";
import styles from "./BusinessSetupForm.module.scss";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { OnBoardingIntro } from "../../../../common/components/CoachMarks/caochMarksHelper";
import {
  setIsBusinessSkipped,
  setIsSetupCompleted,
} from "../../../../reducerSlices/headerSlice";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
function BusinessSetupForm() {
  const { countriesList } = useFetchCountries();
  const [logoImageFile, setLogoImageFile] = useState("");
  const [logoImageFileUrl, setLogoImageFileUrl] = useState("");
  const [contractImageFile, setContractImageFile] = useState("");
  const [licenseFileError, setLicenseFileError] = useState(false);
  const [contractFileError, setContractFileError] = useState(false);
  const [vatFileError, setVatFileError] = useState(false);
  const [contractImageFileUrl, setContractImageFileUrl] = useState("");
  const [licenseImageFile, setLicenseImageFile] = useState("");
  const [licenseImageFileUrl, setLicenseImageFileUrl] = useState("");
  const [vatImageFile, setVATImageFile] = useState("");
  const [vatImageFileUrl, setVATImageFileUrl] = useState("");
  const navigate = useNavigate();
  const [deliveryLocations, setDeliveryLocations] = useState<{ id: string }[]>(
    []
  );
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [currentUser] = useState(
    JSON.parse(localStorage?.getItem("loggedInUser") as any)
  );

  const breadcrumbItems = [{ label: "Edit account details" }];
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const loggedInUser =
    JSON.parse(localStorage.getItem("loggedInUser") || "{}") ||
    useSelector((state: any) => state.loginUserDetails?.user);
  const validateUAEIBAN = (iban: any) => {
    const ibanPattern = /^AE\d{2}\s?\d{3}\s?\d{16}$/;
    return ibanPattern.test(iban);
  };
  //schema for business setup form
  const schema = yup
    .object({
      businessName: yup.string().required("Enter business name"),
      emailID: yup
        .string()
        .required("Enter email id")
        .matches(
          constants.formaValidations.EMAIL_REGEX,
          "Enter valid email id"
        ),
      logo: yup.mixed().optional().nullable(),
      licenseNo: yup.string().required("Enter license number"),
      licenseFile: yup.mixed().required("Upload license file"),
      minimumOrderValue: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .test(
          "is-decimal",
          "Only integers is allowed ( Ex: valid - 100 , invalid - 100.2)",
          (value) => Number.isInteger(value)
        )
        .nullable()
        .positive()
        .required("Enter minimum order value"),
      deliveryAddress: yup
        .array()
        .of(yup.object())
        .min(1, "Select a delivery address")
        .required("Select a delivery address"),
      contactName: yup.string().required("Enter contact name"),
      countryName: yup.object().required("Select country"),
      phNo: yup
        .number()
        .positive()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      paymentMethod: yup
        .string()
        .oneOf(["cod", "online", "both"])
        .required("Select a payment method"),
      iban: yup
        .string()
        .optional()
        .when("paymentMethod", {
          is: (paymentMethod: string) =>
            paymentMethod === "online" || paymentMethod === "both",
          then: (schema) =>
            schema
              .required("Enter iban number")
              .test("is-iban", "Invalid IBAN format", (value) =>
                validateUAEIBAN(value)
              ),
          otherwise: (schema) => schema.optional(),
        }),
      accountName: yup
        .string()
        .optional()
        .when("paymentMethod", {
          is: (paymentMethod: string) =>
            paymentMethod === "online" || paymentMethod === "both",
          then: (schema) => schema.required("Enter account name"),
          otherwise: (schema) => schema.optional(),
        }),
      vatNo: yup
        .string()
        .optional()
        .nullable()
        .matches(
          constants.formaValidations.VAT_REGEX,
          "VAT number must contain alphabets or numeric only"
        ),
      vatFile: yup.mixed().optional().nullable(),
      address: yup.string().required("Enter address"),
      area: yup.string().required("Enter area name"),
      emirates: yup.object().required("Select emirates"),
      country: yup.object().required("Select country"),
      contract: yup.mixed().required("Upload signed contract file"),
    })
    .required();

  const location = useLocation();
  const userId = location?.state?.userID;
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const values = getValues();
  const [ibanInputDisabled, setIbanInputDisabled] = useState(true);
  const handlePaymentMethodChange = (newValue: "cod" | "online" | "both") => {
    if (newValue === "cod") {
      setValue("iban", "");
      setValue("accountName", "");
      setIbanInputDisabled(true);
      // setShowAccountName(false);
    } else {
      setIbanInputDisabled(false);
      // setShowAccountName(true);
    }
    setValue("paymentMethod", newValue);
  };

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "countryName") {
        setValue(name, text);
      }
      if (name === "country" || name === "emirates") {
        setValue(name, text);
      }
      if (name === "iban") {
        setValue(name, text?.toUpperCase());
      }
      if (
        name === "logo" ||
        name === "licenseFile" ||
        name === "contract" ||
        name === "vatFile"
      ) {
        setValue(name, text);
      }
      if (name === "deliveryAddress") {
        setValue(name, text);
        const deliveryLocationsData = text?.map((deliveryLocation: any) => ({
          id: deliveryLocation?.id,
        }));
        setDeliveryLocations(deliveryLocationsData);
      }

      isSubmitted && trigger(name);
    },
    [setValue, isSubmitted]
  );

  const [customerId] = useState(
    loggedInUser?.business_details?.[0]?.customer_id
  );
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );

  const [fetchedData, setFetchedData] = useState<any>({});
  //api to check the status of the setup
  const fetchCTACardStatus = useAxios({
    axiosParams: {
      url: `store/onboarding/supplier/${customerId}/${businessId}`,
    },
    method: "GET",
  });
  const fetchCTACardStatusAPI = async () => {
    if (customerId && businessId) {
      const response: any = await fetchCTACardStatus.fetchData();
      if (response && response?.status === "SUCCESS") {
        setFetchedData(response?.data);
      }
    }
  };

  useEffect(() => {
    fetchCTACardStatusAPI();
  }, []);

  useEffect(() => {
    //uploading vatfile once it is selected
    const { uploadFile } = useFileUpload();
    const getVatFiles = async () => {
      if (vatImageFile) {
        dispatch(updateIsLoading(true));
        const response: any = await uploadFile(vatImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setVATImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getVatFiles();
  }, [vatImageFile]);

  useEffect(() => {
    //uploading license file once it is selected
    const { uploadFile } = useFileUpload();
    const getLicenseFiles = async () => {
      if (licenseImageFile) {
        dispatch(updateIsLoading(true));
        const response: any = await uploadFile(licenseImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setLicenseImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLicenseFiles();
  }, [licenseImageFile]);

  useEffect(() => {
    //uploading contract file once it is selected
    const { uploadFile } = useFileUpload();
    const getContractFiles = async () => {
      if (contractImageFile) {
        dispatch(updateIsLoading(true));
        const response: any = await uploadFile(contractImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setContractImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getContractFiles();
  }, [contractImageFile]);

  useEffect(() => {
    //iploading logo once it is selected
    const { uploadFile } = useFileUpload();
    const getLogoFiles = async () => {
      if (logoImageFile) {
        dispatch(updateIsLoading(true));
        const response: any = await uploadFile(logoImageFile);
        setTimeout(() => {
          if (response) {
            dispatch(updateIsLoading(false));
            setLogoImageFileUrl(response?.data[0]);
          } else {
            dispatch(updateIsLoading(false));
          }
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLogoFiles();
  }, [logoImageFile]);

  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates = selectedOption?.emirates?.map((emirate: ICountry) => ({
      ...emirate,
      value: emirate?.name,
    }));
    setEmiratesList(emirates);
  };
  // // create a business starts
  const postFinalSupplierSetupData = useAxios({
    axiosParams: {
      url: `store/supplier/${
        loggedInUser &&
        loggedInUser?.business_details &&
        loggedInUser?.business_details?.[0]?.id
      }`,
      data: {
        business_name: values?.businessName,
        email_id: values?.emailID,
        license_number: values?.licenseNo,
        license_file: licenseImageFileUrl && licenseImageFileUrl,
        vat_number: values?.vatNo ? values?.vatNo : null,
        vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
        account_name: ibanInputDisabled ? null : values?.accountName,
        contract_file: contractImageFileUrl && contractImageFileUrl,
        min_order_value: +values?.minimumOrderValue,
        iban: ibanInputDisabled ? null : values?.iban,
        payment_options:
          values?.paymentMethod === "both"
            ? ["cod", "online"]
            : [values?.paymentMethod],
        deliverable_locations: deliveryLocations,
        ware_houses: [
          {
            is_default: true,
            contact_name: values?.contactName,
            phone_number: `${(values?.countryName as any)?.dial_codes}#${
              values?.phNo
            }`,
            saved_as: values?.businessName,
            address: values?.address,
            area: values?.area,
            emirate: {
              id: (values?.emirates as any)?.id,
            },
            country: {
              iso_2: (values?.country as any)?.iso_2,
            },
          },
        ],
        logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
      },
    },
    method: "POST",
  });
  // // create a business ends
  // get the current business starts
  const [currentBusinessData, setCurrentBusinessData] = useState<any>({});

  useEffect(() => {
    const getFlag: any =
      currentBusinessData &&
      currentBusinessData?.customer_businesses &&
      countriesList &&
      countriesList.length > 0 &&
      countriesList.filter(
        (country) =>
          country?.dial_codes ===
          (currentBusinessData?.customer_businesses[0]?.phone_number?.split(
            "#"
          )[0] as any)
      )[0];
    if (getFlag) {
      setMobileNumberCode({
        dial_codes:
          currentBusinessData?.customer_businesses[0]?.phone_number?.split(
            "#"
          )[0] as any,
        flag: getFlag && getFlag?.flag,
      });
    } else {
      const constantFlag =
        countriesList &&
        countriesList.length > 0 &&
        countriesList.filter(
          (country) => country?.dial_codes === ("+971" as any)
        )[0];
      if (constantFlag) {
        setMobileNumberCode(constantFlag);
      }
    }
  }, [countriesList, currentBusinessData]);

  //api to get the setup data if it is already filled and submitted
  const getCurrentBusiness = useAxios({
    axiosParams: {
      url: `store/supplier/${currentUser?.business_details?.[0]?.business_setup_id}`,
    },
    method: "GET",
  });

  const getCurrentBussinessSetupData = useCallback(async () => {
    if (
      currentUser?.business_details?.[0]?.business_status === "review" ||
      currentUser?.business_details?.[0]?.business_status === "approved" ||
      currentUser?.business_details?.[0]?.business_status === "rejected" ||
      currentUser?.business_details?.[0]?.business_status === "pending"
    ) {
      const response: any = await getCurrentBusiness.fetchData();

      dispatch(updateIsLoading(true));
      if (response && response?.status === "SUCCESS") {
        setTimeout(() => {
          dispatch(updateIsLoading(false));
        }, 1000);
        const businessData = response?.data;
        setCurrentBusinessData(businessData);
        if (businessData) {
          setValue("businessName", businessData?.business_name);
          setValue(
            "contactName",
            businessData?.business_stores[0]?.contact_name
          );
          setValue(
            "countryName",
            businessData?.business_stores[0]?.phone_number?.split("#")[0]
          );
          setMobileNumberCode({
            dial_codes:
              businessData?.business_stores[0]?.phone_number?.split("#")[0],
          });
          setValue(
            "phNo",
            businessData?.business_stores[0]?.phone_number?.split("#")[1]
          );
          setValue("emailID", businessData?.email_id);
          setValue("licenseNo", businessData?.license_number);
          setValue("licenseFile", businessData?.license_file);
          setValue("vatFile", businessData?.vat_file);
          setValue("logo", businessData?.logo_file);
          setValue("contract", businessData?.contract_file);
          setValue("minimumOrderValue", businessData?.min_order_value);
          setValue(
            "address",
            businessData?.business_stores &&
              businessData?.business_stores[0]?.address?.address
          );
          setValue(
            "area",
            businessData?.business_stores &&
              businessData?.business_stores[0]?.address?.area
          );
          setValue("vatNo", businessData?.vat_number);
          setLicenseImageFileUrl(businessData?.license_file);
          setVATImageFileUrl(businessData?.vat_file);
          setLogoImageFileUrl(businessData?.logo_file);
          setContractImageFileUrl(businessData?.contract_file);
          if (businessData?.iban || businessData?.account_name) {
            setIbanInputDisabled(false);
          }
          if (businessData?.iban) {
            setValue("iban", businessData?.iban);
          }
          if (businessData?.account_name) {
            setValue("accountName", businessData?.account_name);
          }
          const onlineOptionExists =
            businessData &&
            businessData?.payment_options &&
            businessData?.payment_options.length > 0 &&
            businessData?.payment_options?.some(
              (option: any) => option?.name === "ONLINE"
            );

          const codOptionExists =
            businessData &&
            businessData?.payment_options &&
            businessData?.payment_options.length > 0 &&
            businessData?.payment_options?.some(
              (option: any) => option?.name === "COD"
            );

          const includesOnlineAndCod = onlineOptionExists && codOptionExists;

          if (includesOnlineAndCod) {
            setValue("paymentMethod", "both");
            // setShowAccountName(true);
          } else if (onlineOptionExists) {
            setValue("paymentMethod", "online");
          } else if (codOptionExists) {
            setValue("paymentMethod", "cod");
          }
        }
      }
    }
  }, [getCurrentBusiness]);

  useEffect(() => {
    getCurrentBussinessSetupData();
  }, []);

  const [mobileNumberCode, setMobileNumberCode] = useState<any>();
  const [selectedLocations, setSelectedLocations] = useState<any>([]);

  useEffect(() => {
    if (currentUser?.business_details?.[0]?.business_setup_id) {
      let country = null;
      if (currentBusinessData?.address?.country) {
        country = countriesList?.find(
          (ele: any) =>
            ele?.iso_2 === currentBusinessData?.address?.country?.iso_2
        );
        if (country) {
          const list =
            country?.emirates &&
            country?.emirates?.filter(
              (item: any) =>
                currentBusinessData &&
                (currentBusinessData as any).deliverable_locations?.some(
                  (newItem: any) => item?.id === newItem?.id
                )
            );
          const listWithValue = list.map((item: any) => ({
            ...item,
            value: item?.name,
          }));
          setSelectedCountry(country);
          setSelectedLocations(listWithValue);
          setValue("deliveryAddress", listWithValue);
          setValue("country", country);
        }
      } else {
        const defaultCountry = countriesList?.find(
          (ele: any) => ele?.iso_2 === "ae"
        );
        if (defaultCountry) {
          const list = defaultCountry?.emirates;
          const defaultEmiratesList = list.map((item: any) => ({
            ...item,
            value: item?.name,
          }));
          setSelectedCountry(defaultCountry);
          setEmiratesList(defaultEmiratesList);
          // setSelectedLocations(listWithValue);
          setValue("country", defaultCountry);
        }
      }
      if (
        currentUser?.business_details?.[0]?.business_setup_id &&
        country &&
        currentBusinessData?.address?.emirate
      ) {
        const emirate: any = country?.emirates?.find(
          (ele: any) => ele?.id === currentBusinessData?.address?.emirate?.id
        );
        setSelectedEmirate(emirate);
        setValue("emirates", emirate);
      }
    }
  }, [countriesList, currentBusinessData]);

  const isVatFileChanged = currentBusinessData?.vat_file === vatImageFileUrl;
  const isContractFileChanged =
    currentBusinessData?.contract_file === contractImageFileUrl;
  const isTradeLicenceFileChanged =
    currentBusinessData?.license_file === licenseImageFileUrl;

  // get the current business ends
  function getPayloadObj(data: any) {
    const isVatNumberChanged = currentBusinessData?.vat_number === data?.vatNo;
    const isLicenceNumberChanged =
      currentBusinessData?.license_number === data?.licenseNo;
    return {
      business_name: data?.businessName,
      // email_id: data?.emailID,
      ...(!isLicenceNumberChanged && { license_number: data?.licenseNo }),
      ...(!isTradeLicenceFileChanged && {
        license_file: licenseImageFileUrl && licenseImageFileUrl,
      }),

      ...(!isVatNumberChanged && { vat_number: data?.vatNo }),
      ...(!isVatFileChanged && {
        vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
      }),
      iban: data?.iban ? data?.iban : null,
      account_name: data?.accountName ? data?.accountName : null,
      ...(!isContractFileChanged && {
        contract_file: contractImageFileUrl && contractImageFileUrl,
      }),
      min_order_value: data?.minimumOrderValue,
      payment_options:
        data?.paymentMethod === "both"
          ? ["cod", "online"]
          : [data?.paymentMethod],
      ware_houses: [
        {
          id:
            currentBusinessData &&
            currentBusinessData?.business_stores &&
            currentBusinessData?.business_stores[0]?.id,
          is_default: true,
          contact_name: data?.contactName,
          phone_number: `${(values?.countryName as any)?.dial_codes}#${
            data?.phNo
          }`,
          saved_as: "warehouse1",
          address: data?.address,
          area: data?.area,
          emirate: {
            id: selectedEmirate?.id,
          },
          country: {
            iso_2: selectedCountry?.iso_2,
          },
        },
      ],
      logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
      deliverable_locations: deliveryLocations,
    };
  }
  // update the current business setup
  const updateCurrentBusinessSetup = useAxios({
    axiosParams: {
      url: `store/supplier/${currentUser?.business_details?.[0]?.business_setup_id}`,
      data: getPayloadObj(values),
    },
    method: "PUT",
  });

  useEffect(() => {
    if (isSubmitted) {
      if (
        !licenseImageFile &&
        !licenseImageFileUrl &&
        licenseImageFileUrl?.length === 0
      ) {
        setLicenseFileError(true);
      }
      if (
        !contractImageFile &&
        !contractImageFileUrl &&
        contractImageFileUrl?.length === 0
      ) {
        setContractFileError(true);
      }
      if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
        setVatFileError(false);
      }
    }
  }, [errors, isSubmitted]);

  const submit = async (data: ISupplierSetupFormData) => {
    if (
      !licenseImageFile &&
      !licenseImageFileUrl &&
      licenseImageFileUrl.length === 0
    ) {
      setLicenseFileError(true);
    }
    if (
      !contractImageFile &&
      !contractImageFileUrl &&
      contractImageFileUrl.length === 0
    ) {
      setContractFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls =
      licenseImageFileUrl &&
      licenseImageFileUrl.length > 0 &&
      contractImageFileUrl &&
      contractImageFileUrl.length > 0;

    if (shouldMakeApiCalls) {
      if (
        fetchedData?.business_setup_status === "review" ||
        fetchedData?.business_setup_status === "approved" ||
        fetchedData?.business_setup_status === "rejected"
      ) {
        dispatch(updateIsLoading(true));
        const response: any = await updateCurrentBusinessSetup?.fetchData({
          axiosParams: {
            url: `store/supplier/${
              loggedInUser &&
              loggedInUser?.business_details &&
              loggedInUser?.business_details?.[0]?.business_setup_id
            }`,
            data: getPayloadObj(data),
          },
          method: "PUT",
        });
        if (response?.status === "SUCCESS") {
          dispatch(updateIsLoading(false));
          localStorage.setItem("isSetupCompleted", "true");
          dispatch(setIsSetupCompleted("true"));
          // !loggedInUser.supplier_db &&
          //   fetchedData?.business_setup_status === "pending" &&
          //   new OnBoardingIntro(true);
          navigate(routeConfig.supplierDashboard);
        }
        if (!response) {
          dispatch(updateIsLoading(true));
        }
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(true));
        const response: any = await postFinalSupplierSetupData?.fetchData();
        if (response?.status === "SUCCESS") {
          !loggedInUser.supplier_db && new OnBoardingIntro(true);
          localStorage.setItem("isBusinessSkipped", "true");
          dispatch(setIsBusinessSkipped("true"));
          navigate(routeConfig.supplierDashboard);
        } else {
          setToastData({
            message: response.response?.data?.error_msg,
            status: "failure",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
        dispatch(updateIsLoading(false));
      }
    }
  };

  return (
    <div className={styles.businessSetupForm}>
      <div className={styles.right}>
        {userId ? (
          <div className={styles.breadCrumbContainer}>
            <Breadcrumb items={breadcrumbItems} />
          </div>
        ) : (
          <div className={styles.top}>
            <div className={styles.greetings}>
              <div className={styles.user}>
                {constants.BusinessSetupForm.hello}{" "}
                {loggedInUser?.first_name ?? "User"}
              </div>
              <div className={styles.welcome}>
                {constants.BusinessSetupForm.welcome}
              </div>
              <div className={styles.welcome}>
                {constants.BusinessSetupForm.supplierWelcomeContent}
              </div>
            </div>
            <div className={styles.icon}>
              <img src={businessSetupIcon} alt="businessSetup" />
            </div>
          </div>
        )}
        <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
          <div className={styles.form}>
            <div className={styles.businessSetup}>
              <div className={styles.setup}>
                {constants.BusinessSetupForm.businessSetup}
              </div>
              <div className={styles.content}>
                {constants.BusinessSetupForm.setupContent}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    id="businessName"
                    label="Business name"
                    placeholder="Enter business name"
                    onChange={onChangeField("businessName")}
                    register={register("businessName")}
                    errorMessage={errors.businessName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    id="contactName"
                    label="Primary contact name"
                    placeholder="Enter name here"
                    onChange={onChangeField("contactName")}
                    register={register("contactName")}
                    errorMessage={errors.contactName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="emailID"
                    label="Email ID"
                    placeholder="Enter email here"
                    onChange={onChangeField("emailID")}
                    register={register("emailID")}
                    errorMessage={errors.emailID?.message}
                    editForm={
                      fetchedData?.business_setup_status == null ||
                      fetchedData?.business_setup_status == undefined ||
                      fetchedData?.business_setup_status == "" ||
                      fetchedData?.business_setup_status == "pending"
                        ? false
                        : true
                    }
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      mobileNumberCode
                        ? mobileNumberCode
                        : countriesList &&
                          countriesList.length > 0 &&
                          countriesList.filter(
                            (country) => country?.dial_codes === ("+971" as any)
                          )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    label="Phone number"
                    id={"phnNo"}
                    options={countriesList}
                    registerMobileNumber={register("phNo")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      onChangeField("countryName")(selectedOption);
                      setMobileNumberCode(selectedOption);
                    }}
                    errorMessage={errors.phNo?.message}
                    mobileNumberField={true}
                  />
                </div>

                <div className={styles.inputField}>
                  <label className={styles.label}>
                    {constants.BusinessSetupForm.logoLabel}
                  </label>
                  <div className={styles.uploadContainer}>
                    <FileUploadCard
                      width={"100%"}
                      id={1}
                      accept=".jpg, .jpeg, .png"
                      register={register("logo")}
                      onChange={onChangeField("logo")}
                      file={logoImageFile}
                      imageUrl={logoImageFileUrl}
                      setImageUrl={setLogoImageFileUrl}
                      setFile={setLogoImageFile}
                      isMultipleFiles={false}
                    />
                  </div>
                </div>
                <div className={styles.inputField}>
                  <label className={styles.label}>
                    {constants.BusinessSetupForm.signedContractLabel}
                  </label>
                  <div className={styles.uploadContainer}>
                    <FileUploadCard
                      width={"100%"}
                      id={3}
                      accept=".jpg, .jpeg, .png, .pdf"
                      register={register("contract")}
                      error={contractFileError && "Upload contract file"}
                      onChange={onChangeField("contract")}
                      file={contractImageFile}
                      imageUrl={contractImageFileUrl}
                      setImageUrl={setContractImageFileUrl}
                      setFile={setContractImageFile}
                    />
                  </div>
                  {errors.contract && (
                    <p className={styles.errorMessage}>
                      {errors?.contract?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div
                    className={`${styles.inputField} ${styles.marginBottom}`}
                  >
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="licenseNo"
                      label="Trade license number"
                      placeholder="Enter license number"
                      onChange={onChangeField("licenseNo")}
                      register={register("licenseNo")}
                      errorMessage={
                        errors.licenseNo?.message &&
                        errors.licenseNo?.message.length > 60
                          ? `${errors.licenseNo?.message.slice(0, 60)}...`
                          : errors.licenseNo?.message
                      }
                    />
                  </div>
                  <div className={styles.inputField}>
                    <FileUploadCard
                      width={"100%"}
                      id={2}
                      accept=".jpg, .jpeg, .png, .pdf"
                      register={register("licenseFile")}
                      error={licenseFileError && "Upload license file"}
                      onChange={onChangeField("licenseFile")}
                      file={licenseImageFile}
                      imageUrl={licenseImageFileUrl}
                      setImageUrl={setLicenseImageFileUrl}
                      setFile={setLicenseImageFile}
                    />

                    {errors.licenseFile && (
                      <p className={styles.errorMessage}>
                        {errors.licenseFile.message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <div className={` ${styles.typeOfPayment}`}>
                    <div className={styles.mainLabel}>
                      {constants.BusinessSetupForm.paymentLabel}
                    </div>
                    <div className={` ${styles.radioBoxContainer}`}>
                      <Controller
                        name="paymentMethod"
                        control={control}
                        defaultValue="cod"
                        rules={{ required: "Please select a payment method" }}
                        render={({ field }) => (
                          <>
                            <div className={styles.radioBox}>
                              <RadioButton
                                checked={field.value === "cod"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("cod");
                                }}
                                value="cod"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="cod"
                                onClick={() => {
                                  field.onChange("cod");
                                  handlePaymentMethodChange("cod");
                                }}
                              >
                                {constants.BusinessSetupForm.cod}
                              </label>
                            </div>
                            <div className={styles.radioBox}>
                              <RadioButton
                                checked={field.value === "online"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("online");
                                }}
                                value="online"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="online"
                                onClick={() => {
                                  field.onChange("online");
                                  handlePaymentMethodChange("online");
                                }}
                              >
                                {constants.BusinessSetupForm.onlinePayment}
                              </label>
                            </div>
                            <div className={styles.radioBox}>
                              <RadioButton
                                checked={field.value === "both"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("both");
                                }}
                                value="both"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="both"
                                onClick={() => {
                                  field.onChange("both");
                                  handlePaymentMethodChange("both");
                                }}
                              >
                                {constants.BusinessSetupForm.onlineAndCod}
                              </label>
                            </div>
                          </>
                        )}
                      />
                      {errors.paymentMethod && (
                        <p className="error">{errors.paymentMethod.message}</p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.inputField} ${styles.verifyBox}`}>
                    <InputField
                      // type="number"
                      label="Provide your IBAN"
                      placeholder="Enter here..."
                      onChange={onChangeField("iban")}
                      register={register("iban")}
                      errorMessage={!ibanInputDisabled && errors.iban?.message}
                      disabled={ibanInputDisabled}
                    />
                  </div>
                  {values.paymentMethod !== "cod" && (
                    <div
                      className={`${styles.inputField} ${styles.deliveryAddress}`}
                    >
                      <InputField
                        minWidth="400px"
                        id="accountName"
                        label="Account name"
                        placeholder="Enter account name here"
                        onChange={onChangeField("accountName")}
                        register={register("accountName")}
                        disabled={ibanInputDisabled}
                        errorMessage={errors.accountName?.message}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={`${styles.inputField} ${styles.marginBottom}`}
                  >
                    <InputField
                      // maxWidth="100%"
                      // minWidth="250px"
                      id="minimumOrderValue"
                      type="number"
                      label="Minimum order value"
                      placeholder="AED 0.00"
                      onChange={onChangeField("minimumOrderValue")}
                      register={register("minimumOrderValue")}
                      errorMessage={
                        errors.minimumOrderValue?.message &&
                        errors.minimumOrderValue?.message.length > 65
                          ? `${errors.minimumOrderValue?.message.slice(
                              0,
                              65
                            )}...`
                          : errors.minimumOrderValue?.message
                      }
                    />
                  </div>
                  <div className={`${styles.inputField} ${styles.verifyBox}`}>
                    <Dropdown
                      id="deliveryAddress"
                      label="Deliverable locations"
                      options={
                        emiratesList &&
                        emiratesList.length > 0 &&
                        Array.from(
                          new Set(emiratesList.map((emirate) => emirate.value))
                        ).map((value) => {
                          return emiratesList.find(
                            (emirate) => emirate.value === value
                          );
                        })
                      }
                      minWidth="100%"
                      isDropdownOutlineRequired={true}
                      register={register("deliveryAddress")}
                      onChange={onChangeField("deliveryAddress")}
                      errorMessage={errors.deliveryAddress?.message}
                      multiple={true}
                      defaultLabel="Enter delivery address here"
                      selectedValue={selectedLocations}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className={`${styles.inputField} ${styles.marginBottom}`}
                  >
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      id="vatNo"
                      label="VAT number (optional)"
                      placeholder="Enter VAT number"
                      onChange={onChangeField("vatNo")}
                      register={register("vatNo")}
                      errorMessage={
                        errors.vatNo?.message &&
                        errors.vatNo?.message.length > 60
                          ? `${errors.vatNo?.message.slice(0, 60)}...`
                          : errors.vatNo?.message
                      }
                    />
                  </div>
                  <div className={styles.inputField}>
                    <FileUploadCard
                      width={"100%"}
                      id={4}
                      accept=".jpg, .jpeg, .png, .pdf"
                      register={register("vatFile")}
                      onChange={onChangeField("vatFile")}
                      file={vatImageFile}
                      error={vatFileError && "Upload vat file"}
                      setFile={setVATImageFile}
                      imageUrl={vatImageFileUrl}
                      setImageUrl={setVATImageFileUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.businessSetup}>
              <div className={styles.setup}>
                {constants.BusinessSetupForm.address}
              </div>
              <div className={styles.content}>
                {constants.BusinessSetupForm.adressContent}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Address"
                    placeholder="Enter address"
                    onChange={onChangeField("address")}
                    register={register("address")}
                    errorMessage={errors.address?.message}
                  />
                </div>

                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Area"
                    placeholder="Enter area here"
                    onChange={onChangeField("area")}
                    register={register("area")}
                    errorMessage={errors.area?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"country"}
                    label="Country"
                    options={
                      countriesList &&
                      countriesList.filter(
                        (country: ICountry) => country?.iso_2 === "ae"
                      )
                    }
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("country")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("country")(selectedOption);
                      setSelectedCountry(selectedOption);
                      handleCountryClick(selectedOption);
                    }}
                    defaultLabel={selectedCountry?.name ?? "Select country"}
                    errorMessage={errors.country?.message}
                    selectedValue={selectedCountry}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"Emirate"}
                    label="Emirate"
                    options={emiratesList}
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("emirates")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("emirates")(selectedOption);
                      setSelectedEmirate(selectedOption);
                    }}
                    defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                    errorMessage={errors.emirates?.message}
                    selectedValue={selectedEmirate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <button
              className={styles.skip}
              onClick={() => {
                !loggedInUser.supplier_db && new OnBoardingIntro(true);
                localStorage.setItem("isBusinessSkipped", "true");
                dispatch(setIsBusinessSkipped("true"));
                navigate(routeConfig.supplierDashboard);
              }}
            >
              {fetchedData?.business_setup_status == null ||
              fetchedData?.business_setup_status == undefined ||
              fetchedData?.business_setup_status == "" ||
              fetchedData?.business_setup_status === "pending"
                ? constants.BusinessSetupForm.skipButton
                : constants.BusinessSetupForm.cancelButton}
            </button>
            <button className={styles.submit}>
              {constants.BusinessSetupForm.submitButton}
            </button>
          </div>
        </form>
        {toastData?.message && (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={3000}
          />
        )}
      </div>
    </div>
  );
}

export default BusinessSetupForm;
