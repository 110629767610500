import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styles from "./AccordionStyles.module.scss";
import expandLessIcon from "../../../assets/images/expandLessIcon.svg";
import styled from "@emotion/styled";
import { IAccordionProps } from "../../../models/IAccordionProps";
import { useState } from "react";
const StyledAccordion = styled(Accordion)`
  && {
    --primaryWhite: #fff;
    transition: all 0.1s ease-in-out;
    &.MuiAccordion-root {
      background-color: var(--primaryWhite);
      box-shadow: unset;
      color: unset;
      height: unset;
      width: 100%;
      height: fit-content;
      padding: 0px 20px;
      &::before{
        height: 0px;
        background-color: unset;
      }
    }
    &.Mui-expanded {
      margin: unset;
    }
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  transition: all 0.1s ease-in-out;
  &.MuiAccordionSummary-root {
    border-bottom: 1px solid #b2b2b2;
    padding: unset;
    min-height: 24px;
    padding-bottom: 17px;
  }
  .MuiAccordionSummary-content {
    margin: unset;
    &.Mui-expanded {
      margin: unset;
    }
  }
  &.Mui-expanded {
    border-bottom: 1px solid #fff;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: unset;
  }
`;

const CustomAccordion = ({
  key,
  title,
  children,
  isExapanded,
  setIsExpanded,
  customClass,
  width,
  maxWidth,
  isFilters = false, //  by default will open for filters
}: IAccordionProps) => {
  const [isExapand, setIsExpand] = useState<boolean>(isFilters); // local state
  const handleToggle = () => {
    setIsExpand(!isExapand); //setting loacal state
    setIsExpanded && setIsExpanded(!isExapanded); // setting parent state
  };
  return (
      <StyledAccordion
        expanded={isExapanded ?? isExapand}
        onChange={handleToggle}
        square={true}
        TransitionProps={{ unmountOnExit: true }}
        key={key ?? "Accordion"}
        className={customClass}
        style={{width:width ?? '100%',maxWidth:maxWidth}}
        elevation={0}
      >
        <StyledAccordionSummary
          expandIcon={<img src={expandLessIcon} alt="icon"/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={styles.accordionHeaderTitle}>{title}</div>
        </StyledAccordionSummary>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
  );
};
export default CustomAccordion;
