import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import loadash, { isNumber } from "lodash";
import styles from "./CreateSpecialPriceRequestStyles.module.scss";
import { constants } from "../../../../constants/constants";
import { routeConfig } from "../../../../constants/routeConfig";
import InputField from "../../../../components/formFields/inputField/inputField";
import CheckboxButton from "../../../../components/checkbox/CheckboxButton";
import { useAxios } from "../../../../../services/useAxios";
import { adminEndPoints, APIMethods } from "../../../../constants/urlConstants";
import { ToastNotification } from "../../../../components/toastNotification/ToastNotification";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import errorToast from "../../../../../assets/images/errorToast.svg";
import successfulToast from "../../../../../assets/images/successToast.svg";
import { IvariantData } from "../../../../../models/IVariantDataProps";
import PaginationComponent from "../../../../components/pagination/Pagination";
import {
  removeSelectedVariant,
  updatePriceErrorMessage,
  updateMoOption,
  updateMoValue,
  updatePrice,
  updateSelectedVariants,
  updateMoValueErrorMessage,
  updateMoOptionErrorMessage,
} from "../../../../../reducerSlices/specialPriceSlice";
import PopupView from "../../../../components/popupView/PopupView";
import Button from "../../../../components/button/Button";
import { EmptyContainer } from "../../../ordersListing/components/emptyContainer/EmptyContainer";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
// import { capitalizeIfAlphabetic } from "../../../../../utils/filters";
// import { capitalizeIfAlphabetic } from "../../../../../utils/filters";

const CreateSpecialPriceRequest = () => {
  const [suggestedBuyers, setSuggestedBuyers] = useState<any[]>([]);
  const [searchedBuyer, setSearchedBuyer] = useState<string>("");
  const [buyerFocused, setBuyerFocused] = useState<boolean>(false);
  const [suggestedSuppliers, setSuggestedSuppliers] = useState<any[]>([]);
  const [searchedSupplier, setSearchedSupplier] = useState<string>("");
  const [supplierFocused, setSupplierFocused] = useState<boolean>(false);
  const [suggestedProducts, setSuggestedProducts] = useState<any[]>([]);
  const [searchedProduct, setSearchedProduct] = useState<any>(null);
  const [productFocused, setProductFocused] = useState<boolean>(false);
  const supplierSuggestionRef = useRef<HTMLDivElement>(null);
  const buyerSuggestionRef = useRef<HTMLDivElement>(null);
  const productsSuggestionRef = useRef<HTMLDivElement>(null);
  //   const [selectedSupplier, setSelectedSupplier] = useState(false);
  const [selectedBuyer, setSelecteddBuyer] = useState(false);
  const [selectedSupplierID, setSelectedSupplierID] = useState("");
  const [selectedBuyerID, setSelecteddBuyerID] = useState("");
  const [productId, setProductId] = useState("");
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [productVariants, setProductVariants] = useState<Array<IvariantData>>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [userID] = useState(loggedInUser && loggedInUser?.id);
  const [userType] = useState(loggedInUser && loggedInUser?.typeOfUser?.[0]);
  const [businessId] = useState(
    loggedInUser && loggedInUser?.business_details?.[0]?.business_setup_id
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedVariants = useSelector(
    (state: any) => state?.specialPrice?.selectedVariants
  );
  const [variantsFinal, setVariantsFinal] = useState<any>([]);
  // Form Schema
  const variantsSchema = yup.array().of(
    yup.object().shape({
      id: yup.string(),
      isVariantChecked: yup.boolean().default(false),
      orderingOptionValue: yup.number(),
      selectedQuantity: yup.number(),
      equalToUnitName: yup.string(),
      price: yup.number(),
      consumption: yup.number().optional().nullable(),
      moOption: yup.object(),
      moValue: yup.string(),
    })
  );
  const schema = yup.object({
    buyer: yup.string().required("Enter first name"),
    supplier: yup.string().required("Enter last name"),
    product: yup.string().required("Enter prduct"),
    variants: variantsSchema,
  });
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // API call for list of buyer
  const buyerlistAPI = useAxios({
    axiosParams: {
      url: `${
        adminEndPoints.specialPricing
      }/getallbusinesslist?list=buyer&offset=0&limit=10000${
        selectedSupplierID ? "&supplier_id=" + selectedSupplierID : ""
      }&q=${searchedBuyer}`,
    },
    method: APIMethods.get,
  });

  // API call for list of supplier
  const supplierlistAPI = useAxios({
    axiosParams: {
      url: `${
        adminEndPoints.specialPricing
      }/getallbusinesslist?list=supplier&offset=0&limit=10000${
        selectedBuyer ? "&buyer_id=" + selectedBuyerID : ""
      }&q=${searchedSupplier}`,
    },
    method: APIMethods.get,
  });

  // API call for list of products based on supplier
  const getProductAPI = useAxios({
    axiosParams: {
      url: `${adminEndPoints.specialPricing}/retriveproduct`,
      data: {
        supplier_id: selectedSupplierID,
        buyer_id: selectedBuyerID,
        name: searchedProduct ?? "",
        limit: 10,
        offset: (currentPage - 1) * 10,
      },
    },
    method: APIMethods.post,
  });
  // function to transform the variant based on the payload type
  const transformVariant = (variant: any) => {
    let data: any = {
      variant_id: variant?.variantId,
      price: variant?.price,
      consumption_type: variant?.moOption?.value,
    };
    if (variant?.moOption?.value?.toLowerCase() === "moq") {
      data = { ...data, consumption_quantity: variant?.moValue };
    } else {
      data = { ...data, consumption_amount: variant?.moValue };
    }
    return data;
  };

  // API call for creating special pricing request
  const craeteRequestCall = useAxios({
    axiosParams: {
      url: `${adminEndPoints.specialPricing}`,
      data: {
        product_id: productVariants?.[0]?.productId,
        variants: variantsFinal?.map(transformVariant),
        outlet_id: selectedBuyerID,
        ware_house_id: selectedSupplierID,
        created_by: userID,
        status: "accepted",
      },
    },
    method: APIMethods.post,
  });

  // function to create the special pricing request
  const createSpecialPricing = async () => {
    if (variantsFinal && variantsFinal?.length > 0) {
      dispatch(updateIsLoading(true));
      const response: any = await craeteRequestCall.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        setToastData({
          message:
            "The selected products have been successfully offered at a special price to the buyer.",
          status: "success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        if (userType === "admin") {
          navigate(routeConfig.adminSpecialPricing);
        } else {
          navigate(routeConfig.specialPricing);
        }
      } else {
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "failure",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        dispatch(updateIsLoading(false));
      }
    } else {
      setToastData({
        message: "No products selected",
        status: "failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };
  const [productsCount, setProductsCount] = useState(0);
  // function to get the product details
  const getProductDetails = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductAPI.fetchData();
    if (response && response.data) {
      dispatch(updateIsLoading(false));
      const variantsData = response?.data?.map((variant: any) => {
        if (variant) {
          const isVariantChecked = selectedVariants?.findIndex(
            (selectedVariant: any) =>
              selectedVariant?.variantId === variant?.pv_id
          );
          return {
            productId: variant?.product_id,
            id: variant?.pv_id,
            title: variant?.product_title,
            primaryUnitLabel: variant?.pv_metadata?.ordering_option_label,
            primaryUnitValue: variant?.pv_metadata?.ordering_option_value,
            secondaryUnitLabel:
              variant?.pv_metadata?.secondary_ordering_option_label,
            secondaryUnitValue:
              variant?.pv_metadata?.secondary_ordering_option_value,
            price:
              isVariantChecked >= 0
                ? selectedVariants?.[isVariantChecked]?.price
                : variant?.amount,
            consumption: null,
            isVariantChecked: isVariantChecked >= 0,
            moOption:
              isVariantChecked >= 0
                ? selectedVariants?.[isVariantChecked]?.moOption
                : options?.[1],
            moValue:
              isVariantChecked >= 0
                ? selectedVariants?.[isVariantChecked]?.moValue
                : variant?.consumption_type?.toLowerCase() === "moq"
                ? "1"
                : "1",
          };
        }
        return variant;
      });
      setProductsCount(response?.count);
      setValue("variants", variantsData);
      setProductVariants(variantsData);
    } else {
      dispatch(updateIsLoading(false));
    }
  };

  // on change function to change the value of the useForm
  const onChangeField = React.useCallback(
    (name: any, index?: number, variantId?: any, selectedVariants?: any) =>
      (text: any) => {
        setValue(name, text);
        if (name === "buyer") {
          setSearchedBuyer(text);
        } else if (name === "product") {
          setSearchedProduct(text);
        } else if (name === "supplier") {
          setSearchedSupplier(text);
        }
        if (name.includes("price")) {
          const idx = selectedVariants?.findIndex(
            (item: any) => item?.variantId === variantId
          );
          if (
            isNumber(index) &&
            productVariants &&
            productVariants?.length > 0
          ) {
            dispatch(updatePrice({ variantId: variantId, price: text }));
            if (
              Number(text) >= selectedVariants?.[idx]?.originalPrice?.toFixed(2)
            ) {
              dispatch(
                updatePriceErrorMessage({
                  variantId: variantId,
                  priceErrorMessage: `Special price should be less than actual product price (${selectedVariants?.[
                    idx
                  ]?.originalPrice?.toFixed(2)} AED)`,
                })
              );
            } else {
              setValue(name, text);
              if (idx !== -1) {
                if (text !== "") {
                  if (Number(text) <= 0) {
                    dispatch(
                      updatePriceErrorMessage({
                        variantId: variantId,
                        priceErrorMessage:
                          "Special price should be greater than 0",
                      })
                    );
                  } else if (
                    text?.includes(".") &&
                    text?.split(".")?.[1]?.length > 2
                  ) {
                    dispatch(
                      updatePriceErrorMessage({
                        variantId: variantId,
                        priceErrorMessage:
                          "only two decimal places are allowed",
                      })
                    );
                  } else {
                    // dispatch(
                    //   updatePrice({ variantId: variantId, price: text })
                    // );
                    dispatch(
                      updatePriceErrorMessage({
                        variantId: variantId,
                        priceErrorMessage: "",
                      })
                    );
                  }
                } else {
                  dispatch(
                    updatePriceErrorMessage({
                      variantId: variantId,
                      priceErrorMessage: "Enter price",
                    })
                  );
                }
              }
            }
          }
          trigger(name);
        }
        if (name.includes("moOption")) {
          // setValue(`variants.${Number(index)}.moValue`, "1");
          // dispatch(updateMoValue({variantId: variantId, moValue: "1"}))
          if (text) {
            dispatch(updateMoOption({ variantId: variantId, moOption: text }));
            dispatch(
              updateMoOptionErrorMessage({
                variantId: variantId,
                moOptionErrorMessage: "",
              })
            );
          } else {
            dispatch(
              updateMoOptionErrorMessage({
                variantId: variantId,
                moOptionErrorMessage: "Select option",
              })
            );
          }
        }
      },
    [setValue, productVariants]
  );
  const onMoValueChange = (index: number, variantId: any, value: any) => {
    setValue(`variants.${index}.moValue`, value);
    dispatch(updateMoValue({ variantId: variantId, moValue: value }));
    if (value) {
      console.log(Number(value), typeof Number(value), "debug");
      if (Number(value) <= 0) {
        console.log("in debug");
        dispatch(
          updateMoValueErrorMessage({
            variantId: variantId,
            moValueErrorMessage: "value should be greater than 0",
          })
        );
        return;
      }
      if (value?.includes(".") && value?.split(".")?.[1]?.length > 2) {
        dispatch(
          updateMoValueErrorMessage({
            variantId: variantId,
            moValueErrorMessage: "only two decimal places are allowed",
          })
        );
      } else {
        // dispatch(updateMoValue({ variantId: variantId, moValue: value }));
        dispatch(
          updateMoValueErrorMessage({
            variantId: variantId,
            moValueErrorMessage: "",
          })
        );
      }
    } else {
      dispatch(
        updateMoValueErrorMessage({
          variantId: variantId,
          moValueErrorMessage: "Enter value",
        })
      );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        (supplierSuggestionRef &&
          supplierSuggestionRef.current &&
          !supplierSuggestionRef?.current?.contains(event.target)) ||
        (buyerSuggestionRef &&
          buyerSuggestionRef.current &&
          !buyerSuggestionRef?.current?.contains(event.target)) ||
        (productsSuggestionRef &&
          productsSuggestionRef.current &&
          !productsSuggestionRef?.current?.contains(event.target))
      ) {
        setSuggestedBuyers([]);
        setSuggestedSuppliers([]);
        setSuggestedProducts([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //   console.log(fields);

  const debouncedGetBuyersList = loadash.debounce(async () => {
    if (searchedBuyer !== "") {
      dispatch(updateIsLoading(true));
      const response: any = await buyerlistAPI?.fetchData();
      if (response) {
        dispatch(updateIsLoading(false));
        setSuggestedBuyers(response?.data?.values);
        setProductVariants([]);
        setValue("variants", []);
        setValue("product", "");
        setProductId("");
        if (response?.data?.count === 0) {
          setToastData({
            message: `Buyer is not available with this name`,
            status: "failure",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
      }
    } else {
      setSuggestedBuyers([]);
      dispatch(updateIsLoading(false));
      setToastData({
        message: `Please upload the appropriate type of file`,
        status: "failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }, 1000);

  const debouncedGetSuppliersList = loadash.debounce(async () => {
    if (searchedSupplier !== "") {
      dispatch(updateIsLoading(true));
      const response: any = await supplierlistAPI?.fetchData();
      if (response) {
        dispatch(updateIsLoading(false));
        setSuggestedSuppliers(response?.data?.values);
        setProductVariants([]);
        setValue("variants", []);
        setValue("product", "");
        setProductId("");
        if (response?.data?.count === 0) {
          setSelectedSupplierID("");
          setToastData({
            message: `Supplier is not available with this name`,
            status: "failure",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
        }
      }
    } else {
      setSuggestedSuppliers([]);
      dispatch(updateIsLoading(false));
    }
  }, 1000);

  const debouncedGetProductsList = loadash.debounce(async () => {
    if (searchedProduct !== "" && selectedSupplierID) {
      dispatch(updateIsLoading(true));
      const response: any = await getProductAPI?.fetchData({
        axiosParams: {
          url: `${adminEndPoints.specialPricing}/retriveproduct`,
          data: {
            supplier_id: selectedSupplierID,
            buyer_id: selectedBuyerID,
            name: searchedProduct,
            limit: 100,
            offset: 0,
          },
        },
      });
      if (response) {
        dispatch(updateIsLoading(false));
        setSuggestedProducts(response?.data);
        console.log(response);
        if (response?.count === 0) {
          setToastData({
            message: `Product is not available with this name`,
            status: "failure",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
        }
      }
    } else {
      setSuggestedProducts([]);
      dispatch(updateIsLoading(false));
    }
  }, 1000);

  useEffect(() => {
    if (userType === "supplier") {
      setSelectedSupplierID(businessId);
    }
    if (searchedBuyer?.length >= 3) {
      setSuggestedBuyers([]);
      debouncedGetBuyersList();
    } else {
      setSuggestedBuyers([]);
    }
    return () => {
      debouncedGetBuyersList.cancel();
    };
  }, [searchedBuyer]);

  useEffect(() => {
    if (searchedSupplier?.length >= 3) {
      setSuggestedSuppliers([]);
      debouncedGetSuppliersList();
    } else {
      setSuggestedSuppliers([]);
    }
    return () => {
      debouncedGetSuppliersList.cancel();
    };
  }, [searchedSupplier]);

  useEffect(() => {
    if (searchedProduct?.length >= 3) {
      setSuggestedProducts([]);
      debouncedGetProductsList();
    } else {
      setSuggestedProducts([]);
    }
    return () => {
      debouncedGetProductsList.cancel();
    };
  }, [searchedProduct]);

  useEffect(() => {
    if (selectedSupplierID && selectedBuyerID) {
      getProductDetails();
    }
  }, [selectedSupplierID, productId, currentPage, selectedBuyerID]);
  useEffect(() => {
    if (searchedProduct === "" && !searchedProduct) {
      getProductDetails();
      setProductId("");
    }
  }, [searchedProduct]);
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleCheckbox = (variant: any, index: number) => {
    const newItem = {
      variantId: variant?.id,
      price: variant?.price,
      priceErrorMessage: "",
      moValueErrorMessage: "",
      originalPrice: variant?.price,
      productId: productVariants?.[0]?.productId,
      variantName: variant?.title,
      options: `${variant?.primaryUnitValue} x ${variant?.secondaryUnitValue} ${variant?.secondaryUnitLabel}`,
      moOption: variant?.moOption,
      moValue: variant?.moValue,
    };
    const idx = selectedVariants?.findIndex(
      (item: any) => item.variantId === variant?.id
    );

    if (idx >= 0) {
      setValue(
        `variants.${index}.price`,
        selectedVariants?.[idx]?.originalPrice
      );
      setValue(`variants.${index}.moOption`, options?.[1]);
      setValue(`variants.${index}.moValue`, "1");
      dispatch(removeSelectedVariant({ variantId: variant?.id }));
      dispatch(
        updateMoValueErrorMessage({
          variantId: variant?.id,
          moValueErrorMessage: "",
        })
      );
    } else {
      if (selectedVariants && selectedVariants?.length > 0)
        dispatch(updateSelectedVariants([...selectedVariants, newItem]));
      else {
        dispatch(updateSelectedVariants([newItem]));
      }
    }
  };
  const getErrorMessage = (variants: any, id: any, field: string) => {
    const index = variants?.findIndex((item: any) => item.variantId === id);
    if (index >= 0) {
      if (field === "price") return variants?.[index]?.priceErrorMessage;
      else if (field === "moOption")
        return variants?.[index]?.moOptionErrorMessage;
      else {
        return variants?.[index]?.moValueErrorMessage;
      }
    } else return "";
  };
  useEffect(() => {
    return () => {
      dispatch(updateSelectedVariants([]));
    };
  }, []);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setVariantsFinal([...selectedVariants]);
  }, [selectedVariants]);
  const handlePopup = () => {
    setShowPopup(false);
    setVariantsFinal(selectedVariants);
  };

  const createRequestPreview = () => {
    const removeVariant = (variantId: any) => {
      const idx = variantsFinal?.findIndex(
        (item: any) => item.variantId === variantId
      );
      if (idx >= 0) {
        const updatedItems = variantsFinal?.filter(
          (item: any) => item.variantId !== variantId
        );
        setVariantsFinal(updatedItems);
      }
    };
    const confirmRequest = () => {
      if (variantsFinal?.length > 0) {
        dispatch(updateSelectedVariants(variantsFinal));
        createSpecialPricing();
      } else {
        setToastData({
          message: "No products selected",
          status: "failure",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      }
    };
    return (
      <>
        <div className={styles.preview}>
          <div className={styles.listContainer}>
            <div className={`${styles.row} ${styles.header}`}>
              <div className={`${styles.cell} ${styles.heading}`}>Variant</div>
              <div className={`${styles.cell} ${styles.heading}`}>Options</div>
              <div className={`${styles.cell} ${styles.heading}`}>Price</div>
              <div className={`${styles.cell} ${styles.heading}`}>MOV/MOQ</div>
              <div className={`${styles.cell} ${styles.heading}`}>
                Order value
              </div>
              <div className={`${styles.cell} ${styles.heading}`}></div>
            </div>
            <div className={styles.hr}></div>
            <div className={styles.list}>
              {variantsFinal?.map((each_variant: any) => (
                <div className={styles.row}>
                  <div
                    className={styles.cell}
                    title={each_variant?.variantName}
                  >
                    {each_variant?.variantName}
                  </div>
                  <div className={styles.cell}>{each_variant?.options}</div>
                  <div className={styles.cell}>{each_variant?.price}</div>
                  <div className={styles.cell}>
                    {each_variant?.moOption?.value}
                  </div>
                  <div className={styles.cell}>{each_variant?.moValue}</div>
                  <Button
                    label={"Remove"}
                    handleClick={() => removeVariant(each_variant?.variantId)}
                    link
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              label={"Cancel"}
              handleClick={handlePopup}
              secondary
              customClass={styles.btnStyles}
            />
            <Button
              label={"Submit"}
              handleClick={confirmRequest}
              customClass={styles.btnStyles}
              disabled={variantsFinal?.length <= 0}
            />
          </div>
        </div>
      </>
    );
  };
  const submitRequest = () => {
    if (selectedVariants?.length > 0) {
      setShowPopup(true);
    } else {
      setToastData({
        message: "No products selected",
        status: "failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };
  const isSubmitDisabled = () => {
    return (
      selectedVariants?.length <= 0 ||
      selectedVariants?.some(
        (item: any) =>
          item?.priceErrorMessage?.length > 0 ||
          item?.moValueErrorMessage?.length > 0 ||
          item?.moOptionErrorMessage?.length > 0
      )
    );
  };
  const options = [
    {
      value: "MOV",
      lable: "MOV",
      name: "MOV",
    },
    {
      value: "MOQ",
      lable: "MOQ",
      name: "MOQ",
    },
  ];
  console.log(watch(`variants.${0}.moValue`), "watch");
  return (
    <div className={styles.businessSetupForm}>
      <div className={styles.right}>
        <div className={styles.top}>
          <div className={styles.greetings}>
            <div className={styles.user}>
              {"Special Pricing Request for Buyer"}
            </div>
          </div>
        </div>
        <form className={styles.bottom}>
          <div className={styles.form}>
            <div className={styles.businessSetup}>
              <div className={styles.inputFields}>
                <div className={styles.column1}>
                  <div
                    className={`${styles.inputField} ${styles.inputFieldSugg}`}
                    ref={buyerSuggestionRef}
                  >
                    <InputField
                      value={watch("buyer")}
                      id="buyer"
                      label={constants.specialPricing.buyerRequest.buyer}
                      placeholder="Search business name (min. 3 characters)"
                      onChange={onChangeField("buyer")}
                      register={register("buyer")}
                      errorMessage={errors.buyer?.message}
                      onFocus={() => setBuyerFocused(true)}
                      autoComplete={false}
                    />
                    {buyerFocused && suggestedBuyers?.length > 0 && (
                      <div className={styles.inputFieldSuggestionsContainer}>
                        {suggestedBuyers?.map(
                          (each_product: any, index: number) => (
                            <div
                              key={each_product.id}
                              onClick={() => {
                                setValue("buyer", each_product?.business_name);
                                setBuyerFocused(false);
                                setSelecteddBuyer(true);
                                setSelecteddBuyerID(
                                  each_product?.business_setup_id
                                );
                                dispatch(updateSelectedVariants([]));
                              }}
                              className={`${styles.productSuggestionItem} ${
                                index != suggestedBuyers.length - 1
                                  ? styles.seperator
                                  : null
                              }`}
                            >
                              {each_product?.business_name}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {userType === "admin" && (
                  <div className={styles.column2}>
                    <div
                      className={`${styles.inputField} ${styles.inputFieldSugg}`}
                      ref={supplierSuggestionRef}
                    >
                      <InputField
                        value={watch("supplier")}
                        id="supplier"
                        label={constants.specialPricing.buyerRequest.supplier}
                        placeholder="Search business name (min. 3 characters)"
                        onChange={onChangeField("supplier")}
                        register={register("supplier")}
                        errorMessage={errors?.supplier?.message}
                        onFocus={() => {
                          setSupplierFocused(true);
                        }}
                        autoComplete={false}
                        editForm={selectedBuyerID ? false : true}
                      />
                      {supplierFocused && suggestedSuppliers?.length > 0 && (
                        <div className={styles.inputFieldSuggestionsContainer}>
                          {suggestedSuppliers?.map(
                            (each_product: any, index: number) => (
                              <div
                                key={each_product?.id}
                                onClick={() => {
                                  setValue(
                                    "supplier",
                                    each_product?.business_name
                                  );
                                  //   setSelectedSupplier(true);
                                  setSupplierFocused(false);
                                  setSelectedSupplierID(
                                    each_product?.business_setup_id
                                  );
                                  dispatch(updateSelectedVariants([]));
                                }}
                                className={`${styles.productSuggestionItem} ${
                                  index != suggestedSuppliers?.length - 1
                                    ? styles.seperator
                                    : null
                                }`}
                              >
                                {each_product?.business_name}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles.column3}>
                  <div
                    className={`${styles.inputField} ${styles.inputFieldSugg}`}
                    ref={productsSuggestionRef}
                  >
                    <InputField
                      value={watch("product")}
                      id="product"
                      label={constants.specialPricing.buyerRequest.product}
                      placeholder="Search product name (min. 3 characters)"
                      onChange={onChangeField("product")}
                      register={register("product")}
                      errorMessage={errors.product?.message}
                      editForm={
                        selectedSupplierID && selectedBuyerID ? false : true
                      }
                      onFocus={() => {
                        setProductFocused(true);
                      }}
                      autoComplete={false}
                    />
                    {productFocused && suggestedProducts?.length > 0 && (
                      <div className={styles.inputFieldSuggestionsContainer}>
                        {suggestedProducts?.map(
                          (each_product: any, index: number) => (
                            <div
                              key={each_product.id}
                              onClick={() => {
                                setValue(
                                  "product",
                                  each_product?.product_title
                                );
                                setCurrentPage(1);
                                // setSelecteddBuyer(true)
                                setProductFocused(false);
                                setProductId(each_product?.product_id);
                              }}
                              className={`${styles.productSuggestionItem} ${
                                index != suggestedProducts?.length - 1
                                  ? styles.seperator
                                  : null
                              }`}
                            >
                              {each_product?.product_title}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {productsCount > 0 ? (
              <div className={styles.variants}>
                {/* <h3 className={styles.heading}>Variants</h3> */}
                <div className={styles.tableContainer}>
                  <div className={styles.table}>
                    <div className={styles.tableHeader}>
                      <div className={styles.tableColumn}>
                        Product variant name
                      </div>
                      <div className={styles.tableColumn}>Options</div>
                      <div className={styles.tableColumn}>Price</div>
                      <div className={styles.tableColumn}>MOV/MOQ</div>
                      <div className={styles.tableColumn}>Order value</div>
                    </div>
                    <div className={styles.tableBody}>
                      {productVariants &&
                        productVariants?.map(
                          (each_variant: any, index: number) => (
                            <div className={`${styles.tableRow}`}>
                              <div className={styles.tableData}>
                                <div>
                                  <CheckboxButton
                                    id={each_variant?.id}
                                    checked={selectedVariants?.some(
                                      (item: any) =>
                                        item.variantId === each_variant?.id
                                    )}
                                    handleChange={() =>
                                      handleCheckbox(each_variant, index)
                                    }
                                  />
                                </div>
                                <div title={each_variant?.title}>
                                  {each_variant?.title?.length > 50
                                    ? each_variant?.title?.slice(0, 50) + "..."
                                    : each_variant?.title}
                                </div>
                              </div>
                              <div className={styles.tableData}>
                                {`${each_variant?.primaryUnitValue} x ${each_variant?.secondaryUnitValue} ${each_variant?.secondaryUnitLabel}`}
                              </div>
                              <div className={styles.tableData}>
                                AED
                                <div>
                                  <InputField
                                    id={`${each_variant?.id}price`}
                                    register={register(
                                      `variants.${index}.price`
                                    )}
                                    value={watch(`variants.${index}.price`)}
                                    onChange={onChangeField(
                                      `variants[${index}].price`,
                                      index,
                                      each_variant?.id,
                                      selectedVariants
                                    )}
                                    errorMessage={getErrorMessage(
                                      selectedVariants,
                                      each_variant?.id,
                                      "price"
                                    )}
                                    type="number"
                                    disabled={
                                      !selectedVariants?.some(
                                        (item: any) =>
                                          item.variantId === each_variant?.id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className={styles.tableData}>
                                <div>
                                  <Dropdown
                                    label={""}
                                    options={options}
                                    id={""}
                                    register={register(
                                      `variants.${index}.moOption`
                                    )}
                                    onChange={(value: any) => {
                                      console.log(value, "onval");
                                      onChangeField(
                                        `variants[${index}].moOption`,
                                        index,
                                        each_variant?.id,
                                        selectedVariants
                                      )(value);
                                    }}
                                    defaultLabel={
                                      (
                                        watch(
                                          `variants.${index}.moOption`
                                        ) as any
                                      )?.value
                                    }
                                    minWidth={"200px"}
                                    disabled={
                                      !selectedVariants?.some(
                                        (item: any) =>
                                          item.variantId === each_variant?.id
                                      )
                                    }
                                    errorMessage={getErrorMessage(
                                      selectedVariants,
                                      each_variant?.id,
                                      "moOption"
                                    )}
                                  />
                                </div>
                              </div>
                              <div className={styles.tableData}>
                                <div>
                                  <InputField
                                    register={register(
                                      `variants.${index}.moValue`
                                    )}
                                    onChange={(value: any) =>
                                      onMoValueChange(
                                        index,
                                        each_variant?.id,
                                        value
                                      )
                                    }
                                    value={watch(`variants.${index}.moValue`)}
                                    errorMessage={getErrorMessage(
                                      selectedVariants,
                                      each_variant?.id,
                                      "moValue"
                                    )}
                                    disabled={
                                      !selectedVariants?.some(
                                        (item: any) =>
                                          item.variantId === each_variant?.id
                                      )
                                    }
                                    type="number"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.emptyContainer}>
                <EmptyContainer title={"Woah!"} subText={"No products"} />
              </div>
            )}
          </div>
          <div className={styles.paginationContainer}>
            <PaginationComponent
              count={Math.ceil(productsCount / itemsPerPage)}
              page={currentPage}
              handlePagination={handlePagination}
              showFirstButton={true}
              showLastButton={true}
            />
          </div>
        </form>
        <div className={styles.action}>
          <button
            className={styles.skip}
            onClick={() =>
              userType === "admin"
                ? navigate(routeConfig.adminSpecialPricing)
                : navigate(routeConfig.specialPricing)
            }
          >
            {constants.adminLevelFormLabels.cancel}
          </button>
          <button
            className={`${styles.submit} ${
              isSubmitDisabled() ? styles.disable : ""
            }`}
            type="submit"
            onClick={submitRequest}
            disabled={isSubmitDisabled()}
          >
            {constants.adminLevelFormLabels.submit}
          </button>
        </div>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData?.status === "success" ? successfulToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
      {showPopup && (
        <PopupView
          heading={"Confirm request"}
          childComponent={createRequestPreview}
          height={469}
          width={900}
          handlePopup={handlePopup}
        />
      )}
    </div>
  );
};

export default CreateSpecialPriceRequest;
