import { useNavigate } from "react-router-dom";
import { ApprovalCardProps } from "../../../../../models/IBusinessApprovalProcess";
import styles from "../../../buyerBusinessApprovalProcess/components/approvalCard/ApprovalCard.module.scss";

export const ApprovalCardSupplier = ({
  logo,
  businessName,
  email,
  primaryContactName,
  phoneNo,
  address,
  id,
  customerId,
}: ApprovalCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.approvalCard} ${styles.cardStyles}`}
      onClick={() => navigate(`/admin/supplier-approval-form/${id}`)}
    >
      {logo && (
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
      )}
      <div className={`${styles.details} ${styles.cardDetails}`}>
        <div
          className={`${styles.column} ${styles.businessName}`}
          title={businessName}
        >
          {businessName && businessName.length > 20
            ? `${businessName
                ?.split(" ")
                ?.map(
                  (word: string) =>
                    word?.charAt(0)?.toUpperCase() + word?.slice(1) + " "
                )
                ?.slice(0, 20)?.join('')}...`
            : businessName
                ?.split(" ")
                ?.map(
                  (word: string) =>
                    word?.charAt(0)?.toUpperCase() + word?.slice(1) + " "
                )}
        </div>
        <div className={styles.column} title={primaryContactName}>
          {primaryContactName && primaryContactName.length > 20
            ? `${primaryContactName.slice(0, 20)}...`
            : primaryContactName}
        </div>
        <div className={styles.column} title={email}>
          {email && email.length > 20 ? `${email.slice(0, 20)}...` : email}
        </div>
        <div className={styles.column} title={phoneNo}>
          {phoneNo && phoneNo.length > 20
            ? `${phoneNo?.split("#")?.join("-").slice(0, 20)}...`
            : phoneNo?.split("#")?.join("-")}
        </div>
        <div className={styles.column} title={address}>
          {address && address.length > 20
            ? `${address.slice(0, 20)}...`
            : address}
        </div>
      </div>
    </div>
  );
};
