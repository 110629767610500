import styles from "./EmptyContainerStyles.module.scss";
import emptyContainer from "../../../../../assets/images/ordersListing/emptyContainer.svg";
export const EmptyContainer = ({ imgUrl = emptyContainer, title, subText,customStyles }: any) => {

	// console.log("dfdsf",customStyles)
	return (
		<>
			<div className={`${styles.emptyContainer} ${customStyles ? customStyles : null}`}>
				<div>
					<img src={imgUrl} alt='empty' />
				</div>
				{title && <div className={styles.woah}>{title}</div>}
				{subText && <div className={styles.content}>{subText}</div>}
			</div>
		</>
	);
};
