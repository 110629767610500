import styles from "./ImageViewerStyles.module.scss";
import leftArrow from "../../../assets/images/leftArrow.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import zoomInImg from "../../../assets/images/zoomIn.svg";
import zoomOutImg from "../../../assets/images/zoomOut.svg";
import React, { useEffect, useRef } from "react";
export const ImageViewer = ({
  fileName,
  fileSource,
  handleImageViewer,
}: any) => {
  const linkSuggestionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        linkSuggestionRef &&
        linkSuggestionRef.current &&
        !linkSuggestionRef?.current?.contains(event.target)
      ) {
        handleImageViewer(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className={styles.overlay}>
        <div className={styles.videoHeader}>
          <div className={styles.headingContainer} onClick={() => handleImageViewer(false)}>
            <img src={leftArrow} className={styles.leftArrow} alt="left arrow" />
            <div className={styles.heading}>{fileName}</div>
          </div>
        </div>
        <div className={styles.content} ref={linkSuggestionRef}>
            <TransformWrapper
              initialScale={1}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                  <div className={styles.image}>
                    <img src={fileSource} alt="img" />
                  </div>
                  </TransformComponent>
                  <div className={styles.btns}>
                    <div className={styles.zoom} onClick={() => zoomIn()}>
                        <img src={zoomInImg} alt="-" />
                    </div>
                    <div className={styles.zoom} onClick={() => zoomOut()}>
                        <img src={zoomOutImg} alt="+" />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
        </div>
      </div>
    </>
  );
};
