import { useState } from "react";
import styles from "./AdminDashboard.module.scss";
import AdminSupplierListing from "../adminSupplierListing/AdminSupplierListing";
import Button from "../../../common/components/button/Button";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../common/constants/routeConfig";
import { useSelector } from "react-redux";

const AdminAddSupplier = () => {
  const navigate = useNavigate();
  const supplierData = useSelector(
    (state: any) => state?.adminSupplierList?.cardData
  );

  const [supplierDataArr, setSupplierDataArr] = useState(supplierData);

  return (
    <div className={styles.container}>
      {/* <AdminSideNav /> */}
      <div className={styles.rightContainer}>
        <div className={styles.supplierContainer}>
          <div className={styles.heading}>
            <h2>Suppliers List</h2>
            <Button
              handleClick={() => {
                navigate(routeConfig.adminSupplierSetupForm);
              }}
              label="Add Supplier"
              width="120px"
            />
          </div>
          <AdminSupplierListing
            supplierDataArr={supplierDataArr}
            setSupplierDataArr={setSupplierDataArr}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminAddSupplier;
