import React from "react";
// import { useCallback, useEffect, useRef, useState } from "react";
// // import SideNav from "../sideNav/SideNav";
import styles from "../../BulkUpload.module.scss";
import { isCurrUserAdmin } from "../../../../../utils/clientStorageUtils";
import TableRow from "./TableRow";
const Table = ({
  tableData,
  changeEditStatus,
  logoUpload,
  removeImage,
  variantsData,
  categories,
  countriesList,
  measuringUnits,
}: any) => {
  console.log("tableData", tableData);

  const massageTableData = (tableObj: any) => {
    if (tableObj && tableObj.is_deactivated) {
      const deactivateValue = tableObj.is_deactivated.value;
      if (
        deactivateValue === null ||
        deactivateValue === undefined ||
        deactivateValue === ""
      ) {
        tableObj.is_deactivated.value = false;
        return tableObj;
      }
      if (deactivateValue === true || deactivateValue === false) {
        return tableObj;
      }
      if (String(deactivateValue).toLowerCase() === "true") {
        tableObj.is_deactivated.value = true;
        return tableObj;
      } else {
        tableObj.is_deactivated.value = false;
        return tableObj;
      }
    }
  };

  const doesHaveDeactivatedColumn = () => {
    return (
      tableData &&
      tableData.length > 0 &&
      tableData[0].hasOwnProperty("is_deactivated")
    );
  };
  return (
    <div className={styles.tableParent}>
      <table>
        <thead className={styles.theading}>
          <tr>
            <th>
              Product variant title <span className={styles.required}>*</span>
            </th>
            {!isCurrUserAdmin() && <th>Master watermelon product code</th>}
            <th>Description</th>

            <th>Brand</th>
            <th>Certifications</th>
            <th>Specifications</th>
            <th>Tags</th>

            <th>
              Category name <span className={styles.required}>*</span>
            </th>
            <th>
              Subcategory name <span className={styles.required}>*</span>
            </th>
            <th>
              Sub subcategory name <span className={styles.required}>*</span>
            </th>
            <th>
              Country <span className={styles.required}>*</span>
            </th>
            <th>Supplier product code</th>
            <th>HS code</th>
            <th>Is vat included</th>

            <th>
              Default image <span className={styles.required}>*</span>
            </th>

            <th>Gallery (Max: 4)</th>

            <th>Size</th>
            <th>Flavor</th>
            <th>Capacity</th>
            <th>Color</th>
            <th>Scent</th>
            <th>Origin</th>
            <th>Material</th>
            <th>Type</th>
            <th>Cut or from</th>
            {doesHaveDeactivatedColumn() && <th>Is product deactivated</th>}
            {variantsData &&
              variantsData?.length > 0 &&
              variantsData?.map((variant: any, variantIndex: number) => (
                <React.Fragment key={variantIndex}>
                  <th>
                    {`Product option${variantIndex + 1} ordering unit value`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                  <th>
                    {`Product option${variantIndex + 1} ordering unit label`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                  <th>
                    {`Product option${
                      variantIndex + 1
                    } secondary ordering unit value`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                  <th>
                    {`Product option${
                      variantIndex + 1
                    } secondary ordering unit label`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                  <th>
                    {`Product option${variantIndex + 1} unit price`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                  <th>{`Product option${variantIndex + 1} offer`} (0 to 99)</th>

                  <th>
                    {`Product option${variantIndex + 1} min quantity`}
                    {variantIndex === 0 && (
                      <span className={styles.required}>*</span>
                    )}
                  </th>
                </React.Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.map((customer: any, i: any) => {
              return (
                <TableRow
                  variantsData={variantsData}
                  key={i}
                  customer={massageTableData(customer)}
                  i={i}
                  index={i}
                  changeEditStatus={changeEditStatus}
                  logoUpload={logoUpload}
                  removeImage={removeImage}
                  categories={categories}
                  countriesList={countriesList}
                  measuringUnits={measuringUnits}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
