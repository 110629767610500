import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
// import appleLogo from "../../../../assets/images/appleLogo.png";
// import googleLogo from "../../../../assets/images/googleLogo.png";
// import AccountButton from "../../../../common/components/accountButton/AccountButton";
import Button from "../../../../common/components/button/Button";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { constants } from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import LoginBackground from "../../../../components/login/LoginBackground";
import styles from "./signIn.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { saveUser, updateCartId } from "../../../../reducerSlices/loginInSlice";
import BasicSwitch from "../../../../common/components/switch/Switch";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  loginRoutes,
} from "../../../../common/constants/urlConstants";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { setProfileImage } from "../../../../reducerSlices/ProfileImageSlice";
import { setCartValue } from "../../../../reducerSlices/headerSlice";
import { OnBoardingIntro } from "../../../../common/components/CoachMarks/caochMarksHelper";
import { setActiveOutlet } from "../../../../reducerSlices/activeOutletSlice";
// import { OnBoardingIntro } from "../../../../common/components/CoachMarks/caochMarksHelper";
// import { setIsBusinessSkipped } from "../../../../reducerSlices/headerSlice";
import CryptoJS from "crypto-js";

const SignIn = ({ tokenCallBack }: any) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const { loginUserDetails } = useSelector((state: any) => state);
  // const state = useSelector((state: any) => state);

  const [token, setToken] = useState("0");
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("user");
  const [userTypeCheck, setUserTypeCheck] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Enter Email Id")
      .matches(constants.formaValidations.EMAIL_REGEX, "Invalid Email format"),
    password: yup.string().required("Enter password"),
    // .matches(
    //   // constants.formaValidations.PASSWORD_REGEX,
    //   "Password must be 8-14 characters with upper, lower, number, and special character."
    // ),
  });

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleSwitch = () => {
    setUserTypeCheck(!userTypeCheck);
    setUserType(userType === "user" ? "admin" : "user");
    reset({email:"",password:""});
 };

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      isSubmitted && trigger(name);
    },
    [setValue, isSubmitted]
  );

  const signIn = useAxios({
    axiosParams: {
      url:
        userType == "admin" ? loginRoutes.adminSignin : loginRoutes.userSignin,
      data: {
        email: watch("email"),
        password: generateHashedPassword(watch("password")), // watch("password")
      },
    },
    method: APIMethods.post,
  });

  function generateHashedPassword(password: string) {
    const secretKey = process.env.REACT_APP_PASSWORD_HASHING_SECRET_KEY ?? '';
    const iv = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_PASSWORD_HASHING_IV_SECRET_KEY ?? ''
    );
    const encryptedString = CryptoJS.AES.encrypt(password, secretKey, {
      iv: iv,
    });
    return encryptedString.toString();
  }

  const onSubmit = async (data: any) => {
    dispatch(updateIsLoading(true));
    const response: any = await signIn.fetchData();
    // if (response && userType === "admin") {
    //   response["data"] = response?.user;
    // }
    // remove the below condition in future  to allow buyer users to
    // const allowedRoles = ['supplier-admin','supplier-manager','supplier-staff']
    // (userType === "user" ? allowedRoles?.includes(response?.data?.business_details?.[0]?.role_key): true)
    if (response?.data) {
      if (response?.data?.business_details?.[0]?.role_key?.includes("buyer")) {
        dispatch(updateIsLoading(false));
        setToastData({
          message:
            "Please log in to the mobile app to utilize the Buyer functionalities.",
          status: "Fail",
        });
        return;
      }
      localStorage.setItem("isSessionExpired", "false");
      localStorage.setItem(
        "loggedInUser",
        JSON.stringify({
          ...response?.data,
          typeOfUser: [
            userType === "user"
              ? response?.data?.business_details?.[0]?.role_type
              : "admin",
          ],
        })
      );
      //Remove below condition if buyer also logs in
      if (!response?.data?.business_details?.[0]?.role_key?.includes("buyer")) {
        localStorage.setItem("token", response?.accessToken);
        localStorage.setItem("refreshToken", response?.refreshToken);
      }
      // if user is buyer type
      if (response?.data?.business_details?.[0]?.role_key?.includes("buyer")) {
        dispatch(updateIsLoading(false));
        const getDefaultOutlets = response?.data?.business_details?.filter(
          (each_business: any) => each_business?.is_default
        );
        if (getDefaultOutlets?.length) {
          const defaultOutlet = getDefaultOutlets[0];
          localStorage?.setItem(
            "activeOutlet",
            JSON?.stringify({
              id: defaultOutlet?.id,
              value: defaultOutlet?.id,
              cartId: defaultOutlet?.cart_id,
              name: defaultOutlet?.saved_as,
              isDefault: defaultOutlet?.is_default,
              cartCount: defaultOutlet?.cart_count ?? 0,
              businessStoreId: defaultOutlet?.business_store_id,
            })
          );
          dispatch(
            setActiveOutlet({
              id: defaultOutlet?.id,
              value: defaultOutlet?.id,
              cartId: defaultOutlet?.cart_id,
              name: defaultOutlet?.saved_as,
              isDefault: defaultOutlet?.is_default,
              cartCount: defaultOutlet?.cart_count ?? 0,
              businessStoreId: defaultOutlet?.business_store_id,
            })
          );
        }
      }
      //Remove below condition if buyer also logs in
      if (!response?.data?.business_details?.[0]?.role_key?.includes("buyer")) {
        dispatch(
          saveUser({
            ...response?.data,
            typeOfUser: [
              userType === "user"
                ? response?.data?.business_details?.[0]?.role_type
                : "admin",
            ],
            accessToken: response?.accessToken,
          })
        );
        dispatch(updateCartId(response?.data?.business_details?.[0]?.cart_id));
        localStorage.setItem(
          "cartId",
          response?.data?.business_details?.[0]?.cart_id
        );
        dispatch(updateIsLoading(false));
        dispatch(setProfileImage(response?.data?.profile_image));
        dispatch(
          setCartValue(response?.data?.business_details?.[0]?.cart_count)
        );
        localStorage.setItem(
          "cartCount",
          response?.data?.business_details?.[0]?.cart_count
        );
        navigate(routeConfig.supplierDashboard);
        response?.data?.business_details?.[0]?.role_type == "supplier" &&
          response?.data?.business_details?.[0]?.business_status ==
            "approved" &&
          !response?.data?.supplier_db &&
          new OnBoardingIntro(true);
      }

      //           localStorage.setItem("isBusinessSkipped", "true");
      //           dispatch(setIsBusinessSkipped("true"));
    } else {
      // let error = response?.response?.data;
      let errorMessage =
        response?.response?.data?.error_msg ??
        "Oops! Something went wrong. Please retry.";
      // if (error?.error_code == 1015) {
      //   errorMessage = "Please complete the email verification.";
      // }
      setToastData({ message: errorMessage, status: "Fail" });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
      dispatch(updateIsLoading(false));
    }

    if (checked) {
      Cookies.set("email", data?.email, {
        expires: 7,
      });
      Cookies.set("password", data?.password, {
        expires: 7,
      });
    }
  };
  const handleKeyPress = (event: any) => {
    if (
      event.key === "Enter" &&
      getValues()?.email &&
      getValues()?.email?.length > 0 &&
      getValues()?.password &&
      getValues()?.password?.length > 0
    ) {
      if (onSubmit) {
        onSubmit({
          email: getValues().email,
          password: getValues().password,
        });
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [userType]);

  useEffect(() => {
    if (loginUserDetails?.user) {
      localStorage.setItem("token", loginUserDetails?.user?.accessToken);
      tokenCallBack(localStorage.getItem("token"));
      navigate(routeConfig.signup);
      setToken(loginUserDetails?.user?.accessToken);
    }
    if (token === localStorage.getItem("token")) {
      navigate(routeConfig.supplierDashboard);
    }
  }, [loginUserDetails?.user, navigate, token, tokenCallBack]);

  return (
    <>
      <section className={styles.signin}>
        <div className={styles.signin__header}>
          {LOGIN_PAGE_STRINGS.WELCOME_BACK}
          <div className={styles.signin__rightContainer}>
            <div className={styles.heading}>User</div>
            <BasicSwitch
              switchType="dual"
              handleChange={handleSwitch}
              checked={userTypeCheck}
              setChecked={setUserTypeCheck}
            />
            <div className={styles.heading}>Admin</div>
          </div>
        </div>

        <div className={styles.signin__form}>
          <form>
            {/* // <!-- email --> */}
            <div className={`${styles.email} ${styles.formField}`}>
              <div className={styles.formFieldItem}>
                <div className={styles.label}>{LOGIN_PAGE_STRINGS.EMAILID}</div>
                <InputField
                  placeholder={LOGIN_PAGE_STRINGS.EMAIL_PLACEHOLDER}
                  onChange={onChangeField("email")}
                  register={register("email")}
                  errorMessage={errors.email?.message}
                  autoComplete={false}
                />
              </div>
            </div>
            {/* <!-- email --> */}

            {/* <!-- password --> */}
            <div className={`${styles.password} ${styles.formField}`}>
              <div className={styles.formFieldItem}>
                <div className={styles.label}>
                  {LOGIN_PAGE_STRINGS.PASSWORD}
                </div>
                <div className={styles.formFieldPassword} id="password-field">
                  <InputField
                    enterCall={true}
                    handleKeyPress={handleKeyPress}
                    placeholder={LOGIN_PAGE_STRINGS.PASSWORD_PLACEHOLDER}
                    type="password"
                    onChange={onChangeField("password")}
                    register={register("password")}
                    errorMessage={errors.password?.message}
                  />
                </div>
              </div>
            </div>
            {/* <!-- password --> */}

            {/* <!-- Remember me section --> */}
            <div className={styles.rememberSection}>
              <div
                className={styles.checkbox}
                onClick={() => setChecked(!checked)}
              >
                <CheckboxButton
                  checked={checked}
                  handleChange={() => setChecked(!checked)}
                  label={"Remember Me"}
                />
              </div>
              {userType === "user" ? (
                <div
                  className={styles.forgotPassword}
                  id="forgot-password"
                  onClick={() => navigate(routeConfig.forgotPassword)}
                >
                  {LOGIN_PAGE_STRINGS.FORGOT_PASSWORD}
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* // <!-- Remember me section --> */}

            <div className={styles.formField} id="button-signin">
              <Button
                label={LOGIN_PAGE_STRINGS.SIGNIN}
                large={true}
                handleClick={handleSubmit(onSubmit)}
              />
            </div>
            {/* {userType === "user" ? (
              <div>
                <div className={styles.alternateText}>or continue with</div>
                <div className={styles.alternateSignIn}>
                  <AccountButton
                    buttonIcon={googleLogo}
                    buttonLabel="Google"
                  ></AccountButton>
                  <AccountButton
                    buttonIcon={appleLogo}
                    buttonLabel="Apple"
                  ></AccountButton>
                </div>
              </div>
            ) : null} */}
          </form>
          {userType === "user" ? (
            <div className={styles.signin__signup}>
              {LOGIN_PAGE_STRINGS.NO_ACCOUNT}
              <span
                className={styles.link_primary}
                id="register"
                onClick={() => {
                  navigate(routeConfig.signup);
                }}
              >
                {LOGIN_PAGE_STRINGS.SIGNUP}
              </span>
              {/* button to break the app */}
              {/* <button onClick={() => methodDoesNotExist()}>
                Break the app
              </button>
              <button onClick={() => getPrimeNumbers()}>Break the app 2</button> */}
            </div>
          ) : null}
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status == "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
            handleClose={() => {
              setToastData(constants.defaultToastData);
            }}
          />
        ) : (
          <></>
        )}
      </section>
    </>
  );
};
const SignInForm = ({ tokenCallBack }: any) => {
  return (
    <LoginBackground
      form={
        <>
          <SignIn tokenCallBack={tokenCallBack} />
        </>
      }
    ></LoginBackground>
  );
};

export default SignInForm;
