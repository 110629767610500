import React from "react";
import styles from "./backNavigationBtnStyles.module.scss";
import backArrowIcon from "../../../../../assets/images/Arrow-left.svg";

const BackNavigationBtn = ({
  text,
  handleBack,
}: {
  text: string;
  handleBack: () => void;
}) => {
  return (
    <button onClick={handleBack} className={styles.backNavigationBtn}>
      <img src={backArrowIcon} alt="Back" />{" "}
      <p className={styles.backNavigationBtnText}>{text}</p>
    </button>
  );
};

export default BackNavigationBtn;
