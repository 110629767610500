import { createSlice } from "@reduxjs/toolkit";
// import IND from "../assets/images/Flag.svg";

const initialState = {
  active: [
    {
      id: 0,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 3,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 4,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 5,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 6,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 7,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 8,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 9,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 10,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
    {
      id: 31,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Active",
      productStatus: "Active",
    },
  ],
  inReview: [
    {
      id: 1,
      productName: "Naveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 11,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 12,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 13,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 14,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 15,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 16,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 17,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 18,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 19,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
    {
      id: 20,
      productName: "Sample",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],
      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "In Review",
      productStatus: "In Review",
    },
  ],
  rejected: [
    {
      id: 2,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 21,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 22,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 23,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 24,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 25,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 26,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 27,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 28,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 29,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
    {
      id: 30,
      productName: "praveen",
      productBrand: "Fresho",
      productCountryOrigin: {
        name: "Iran",
        value: "Iran",
        label: "Iran",
        icon: "/wm/static/media/IR.698bfdaa8c4867f5824fd1d78af74d3a.svg",
      },
      supplierProductCode: "6944057",
      productDescription: "sdasd",
      productTags: "adas",
      productImages: [
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
        { file: "/wm/static/media/fruit.49207f5329c552375d8a3d35f6f96eb6.svg", name: "fruits" },
      ],

      productCategory: {
        name: "Fruits & Vegetables",
        value: "fruitsandvegetables",
        label: "Fruits & Vegetables",
        subcategories: [
          {
            name: "Fruits",
            value: "fruits",
            label: "Fruits",
            productSection: [
              {
                name: "Fresh Fruits",
                value: "freshfruits",
                label: "Fresh Fruits",
              },
              {
                name: "Organic Fruits",
                value: "organicfruits",
                label: "Organic Fruits",
              },
            ],
          },
          {
            name: "Vegetables",
            value: "vegetables",
            label: "Vegetables",
            productSection: [
              {
                name: "Leafy Vegetables",
                value: "leafyvegetables",
                label: "Leafy Vegetables",
              },
              {
                name: "Root Vegetables",
                value: "rootvegetables",
                label: "Root Vegetables",
              },
            ],
          },
        ],
      },
      productSubCategory: {
        name: "Fruits",
        value: "fruits",
        label: "Fruits",
        productSection: [
          {
            name: "Fresh Fruits",
            value: "freshfruits",
            label: "Fresh Fruits",
          },
          {
            name: "Organic Fruits",
            value: "organicfruits",
            label: "Organic Fruits",
          },
        ],
      },
      productSection: {
        name: "Fresh Fruits",
        value: "freshfruits",
        label: "Fresh Fruits",
      },
      HS: "1234",
      addedProductDetailsWithDiscount: [
        {
          orderingOption: {
            name: "Cartoon",
            label: "Cartoon",
            value: "Cartoon",
          },
          moq: "5",
          equalto: {
            name: "Pieces",
            label: "Pieces",
            value: "Pieces",
          },
          enterNumber: "12",
          orderAmount: "90000",
          off: "10",
          discountedAmount: "81000.00",
        },
      ],
      certifications: ["FDA", "GMP", "ISO 22000", "Halal", "Kosher"],
      isProducts: ["Gluten free", "Organic", "Vegan", "Local", "Halal"],
      productCardState: "Rejected",
      productStatus: "Rejected",
    },
  ],
};
const adminProductApprovalSlice = createSlice({
  name: "adminProductApprovalList",
  initialState,
  reducers: {
    updateCardById: (state, action) => {
      const { id, updatedCardData } = action.payload;

      const findCardById = (cards: any) =>
        cards.map((card: any) =>
          card.id === id ? { ...card, ...updatedCardData } : card
        );

      state.active = findCardById(state.active);
      state.inReview = findCardById(state.inReview);
      state.rejected = findCardById(state.rejected);
    },
  },
});

export const { updateCardById } = adminProductApprovalSlice.actions;

export default adminProductApprovalSlice.reducer;
