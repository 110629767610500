import React from "react";
import styles from "./OutletCard.module.scss";
import RadioButton from "../../../common/components/radio/RadioButton";
import editIcon from "../../../assets/images/editIcon.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import { useLocation } from "react-router-dom";

type SelectAddressProps = {
  addressDetails: any;
  handleMouseOver: (id: string) => void;
  handleMouseLeave: () => void;
  isHoverd: boolean;
  deleteOutlet: (id: string) => void;
  editOutlet: (id: string) => void;
  emiratesList: any;
  businessStatus: string;
  countriesList: any;
  onClick?: any;
  selected?: string;
  preferences?: boolean;
};
const OutletCard = ({
  addressDetails,
  handleMouseOver,
  handleMouseLeave,
  deleteOutlet,
  editOutlet,
  isHoverd,
  businessStatus,
  selected,
  emiratesList,
  countriesList,
  onClick,
  preferences,
}: SelectAddressProps) => {
  const location = useLocation();
  // const [selectedEmirate, setSelectedEmirate] = useState<any>();
  // const [selectedCountry, setSelectedCountry] = useState<any>();
  // useEffect(() => {
  //   if (emiratesList?.length) {
  //     if (businessStatus?.length === 0) {
  //       const emirate: any = emiratesList?.find(
  //         (ele: any) => ele?.id === addressDetails?.emirate?.id
  //       );
  //       setSelectedEmirate(emirate);
  //       const country: any = countriesList?.find(
  //         (ele: any) => ele?.iso_2 === addressDetails?.country?.iso_2
  //       );
  //       setSelectedCountry(country);
  //     } else {
  //       if (addressDetails) {
  //         const emirate: any = emiratesList?.find(
  //           (ele: any) => ele?.id === addressDetails?.address?.emirate?.id
  //         );
  //         setSelectedEmirate(emirate);
  //       }
  //     }

  //     //   console.log(emiratesList, emirate, 'EMIRATE EXISTS');
  //   }
  // }, [emiratesList, addressDetails]);

  // console.log(
  //   businessStatus,
  //   "businessStatus",
  //   addressDetails,
  //   selectedEmirate
  // );

  return (
    <div
      className={`${styles.selectAddressWrapper} ${
        addressDetails?.makeDefault && styles.addressHovered
      }`}
      onMouseEnter={() => {
        handleMouseOver(addressDetails.id);
      }}
      onClick={() => {
        !location?.pathname?.includes("/buyer/setup-business") &&
          onClick &&
          onClick();
      }}
      onMouseLeave={handleMouseLeave}
    >
      {!location?.pathname?.includes("/buyer/setup-business") && (
        <RadioButton
          checked={selected === addressDetails?.id}
          handleChange={(e) => {}}
          value={addressDetails.id}
          inlineStyles={{ padding: "0px" }}
        />
      )}

      <div>
        <p className={styles.addressUserName}>
          {addressDetails?.saved_as}{" "}
          <span
            className={`${styles.badge} ${
              addressDetails.is_default && styles.defaultBadge
            }`}
          >
            {addressDetails.is_default ? "Default" : "Other"}
          </span>
        </p>
        <>
          <span className={styles.addressText}>{addressDetails?.address}</span>,
          <span className={styles.addressText}>{addressDetails?.area}</span>, ,
          <span className={styles.addressText}>
            {addressDetails?.emirate?.length > 0
              ? addressDetails?.emirate
              : addressDetails?.emirate?.name}
          </span>
          , ,
          <span className={styles.addressText}>
            {addressDetails?.country?.length > 0
              ? addressDetails?.country
              : addressDetails?.country?.name}
          </span>
        </>
        <p className={styles.addressText}>
          Primary contact name: {addressDetails?.contact_name}
        </p>
        <p className={styles.addressText}>
          Contact: {addressDetails?.phone_number?.split("#").join("-")}
        </p>
      </div>
      {isHoverd && (
        <div className={styles.addressActions}>
          <button
            type="button"
            className={styles.addressActionBtn}
            onClick={(e: any) => {
              e.stopPropagation();
              editOutlet(addressDetails?.id);
            }}
          >
            <img src={editIcon} alt="edit icon" />
            <div>Edit</div>
          </button>
          {(!addressDetails?.is_default && !addressDetails?.id) ||
            (preferences && !addressDetails?.is_default && (
              <button
                className={styles.addressActionBtn}
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  deleteOutlet(addressDetails?.id);
                }}
              >
                <img src={deleteIcon} alt="delete icon" />
                <div>Delete</div>
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default OutletCard;
