import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { ICheckboxButtonProps } from "../../../models/IForm";
import styles from "./CheckboxButton.module.scss";

const CheckboxContainer = styled("div")({
  "--primaryPink": "#ec187b",
  "--primaryWhite": "#fff",
  "--teritaryBlack": "#1C1919",
  "--urbanistRegular": "UrbanistRegular",

  ".MuiCheckbox-root .MuiSvgIcon-root": {
    fontSize: "1.7rem",
  },

  ".MuiCheckbox-root": {
    display: "flex",
    alignItems: "center",
    transition: "all 0.4s ease-in-out",
    padding: "0px",
    width: "20px",
    color: "#949494",
    "&:hover": {
      "& .MuiSvgIcon-root": {
        fill: "#ec187b",
        background: "transparent",
        borderColor: "#ec187b",
      },
    },
    "&.Mui-checked": {
      color: "#ec187b",
    },
    "& .MuiCheckbox-colorPrimary": {
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38) !important",
    },
    "& .MuiTypography-root": {
      color: "#1c1919",
      fontFamily: "var(--urbanistRegular)",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "0.5px",
    },
  },
  "&.partiallySelected .MuiCheckbox-root": {
    "& .MuiCheckbox-colorPrimary": {
      "&.Mui-checked": {
        "& .MuiSvgIcon-root": {
          fill: "#fff",
        },
        "&:hover": {
          background: "#fff",
        },
      },
    },
    "& .MuiIconButton-label .MuiSvgIcon-root": {
      borderColor: "#ec187b",
      "&:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "2px",
        borderRadius: "6px",
        background: "#ec187b",
        color: "#ec187b !important",
      },
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked:hover": {
      background: "#ec187b",
    },
  },
});

const CheckboxButton = ({
  partiallySelected = false,
  label,
  customClass,
  customLabel,
  errorMessage,
  disabled,
  checked,
  setChecked,
  handleChange,
  register,
  id,
}: ICheckboxButtonProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked && setChecked(event.target.checked);
    handleChange(event);
  };

  return (
    <>
      <CheckboxContainer
        className={`${customClass} ${
          partiallySelected ? `partiallySelected` : `${styles.checkbox}`
        }`}
      >
        <Checkbox
          id={id}
          data-testid="checkbox"
          className={partiallySelected ? "partiallySelected" : ""}
          indeterminate={partiallySelected ? true : false}
          {...register}
          onChange={handleCheckboxChange}
          checked={partiallySelected ? false : checked}
          disabled={disabled ? true : false}
          color="primary"
        />
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </CheckboxContainer>
      {label && (
        <div className={`${styles.remeberMeText} ${customLabel}`}>
          {label && label}
        </div>
      )}
    </>
  );
};

export default CheckboxButton;
