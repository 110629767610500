import { createSlice } from "@reduxjs/toolkit";
import { response } from "../pages/buyer/myCart/data";

const myCartSlice = createSlice({
  name: "myCart",
  initialState: {
    data: response.data,
    cartCount: 0,
  },
  reducers: {
    addItemToFavourite: (state, action) => {
      state.data?.supplier_products?.forEach((supplierProduct: any) => {
        supplierProduct.products.forEach((product: any) => {
          if (product.id === action.payload) {
            product.is_favourite = !product.is_favourite;
          }
        });
      });
    },
    deleteItemAction: (state, action) => {
      state.data?.supplier_products?.forEach((supplierProduct: any) => {
        supplierProduct.products = supplierProduct?.products?.filter(
          (product: any) => {
            return product.id !== action.payload;
          }
        );
      });
      state.data.supplier_products = state.data?.supplier_products?.filter(
        (supplierProduct: any) => {
          return supplierProduct.products.length > 0;
        }
      );
    },
    setCartCount: (state, action) => {
      state.cartCount = action?.payload;
    }

  },
});
export const { deleteItemAction, addItemToFavourite, setCartCount } = myCartSlice.actions;
export default myCartSlice.reducer;
