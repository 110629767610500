import React, {  useEffect, useState } from "react";
import styles from "./productQuantityButton.module.scss";
import increment from "../../images/increment.svg";
import decrement from "../../images/decrement.svg";
import Button from "../../../../../common/components/button/Button";
import { ToastNotification } from "../../../../../common/components/toastNotification/ToastNotification";
import errorIcon from "../../../../../assets/images/errorToast.svg";
import alertIcon from "../../../../../assets/images/alertToast.svg";
import { useDispatch } from "react-redux";
import { getNewCartID } from "../../../../../services/myCart";
import { updateCartId } from "../../../../../reducerSlices/loginInSlice";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import deleteIcon from "../../../../../assets/images/delete.svg";
export interface IProductQuantityButton {
  quantity: number;
  type?: string;
  onChange?: any;
  minQuantity?: number;
  isDeleteIconRequired?:boolean
}
const ProductQuantityButton = ({ quantity, type, onChange, minQuantity = 1, isDeleteIconRequired = false}: IProductQuantityButton) => {
  const [inputVal, setInputVal] = useState<string | number>(quantity);  
  const [toastMsg, setToastMsg] = useState({ message: "",
  icon: "",});
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(
    loggedInUser?.business_details?.[0]?.customer_id
  );
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );
  const cartID = localStorage.getItem("cartId");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '' ||  (/[0-9]+/g.test(e.target.value) &&
      Number(e.target.value) > 0 &&
      Number(e.target.value) <= 999)){
        setInputVal(e.target.value);
      }
      onChange(inputVal)
    }
  const handleDecrement = () => {
    // setInputVal((prev) => {
    //   if(Number(prev) === 1 && type==="details")
    //   return Number(prev) - 1;
    //   if (Number(prev) !== 1) {
    //     return Number(prev) - 1;
    //   } else {
    //     return prev;
    //   }
    // });
    if(Number(inputVal) === 1 && type==="details"){
    onChange(Number(inputVal)-1);return;}
    if(Number(inputVal) !== 1) {
      onChange(Number(inputVal)-1);return;
    }
  };

  const handleIncrement = () => {
    // setInputVal((prev) => Number(prev) + 1);
    onChange(Number(inputVal)+1)
  };
  const dispatch = useDispatch();
  const handleClick = async () => {
    console.log(cartID, "cartId")
    dispatch(updateIsLoading(true));
    if (!cartID) {
      try {
        const newCartIDResponse = await getNewCartID(customerId, businessId);
        if (
          newCartIDResponse?.status === "SUCCESS" &&
          newCartIDResponse?.data?.business_setup_status === "approved"
        ) {
          dispatch(updateCartId(newCartIDResponse?.data?.cart_id));
          localStorage.setItem("cartId", newCartIDResponse?.data?.cart_id);
          // setInputVal(minQuantity);
          dispatch(updateIsLoading(false));
          onChange(minQuantity)
        } else {
          dispatch(updateIsLoading(false));
          setToastMsg({message:"Business Setup is not Approved.", icon: alertIcon});
          return;
        }
      } catch (e) {
        dispatch(updateIsLoading(false));
        setToastMsg({message:"Unable to add product to cart.", icon: errorIcon});
        console.log(e, "userdetails click");
      }
    } else {
      // setInputVal(minQuantity);
      dispatch(updateIsLoading(false));
      onChange(minQuantity)
    }
    // dispatch(updateIsLoading(false));
  };  
  const handleDeleteProductFromCart = () => {
    setInputVal(0);
    onChange(0);
  }
  useEffect(()=>{
    setInputVal(quantity)
  },[quantity])
  // useEffect(()=>{
  //   if(onChange)
  //   onChange(inputVal)
  // },[inputVal])

  return (
    <>
      {inputVal === 0 ? (
        <Button label="Add to cart" handleClick={handleClick} />
      ) : (
        <div className={styles.cartContainer}>
          <div
            className={`${styles.productQuantityButtonWrapper} ${
              type === "details" && styles.border
            }`}
          >
            <button
              className={`${styles.productQuantityButton} ${
                styles.productQuantityDecrement
              } ${type === "details" && styles.borderRight}`}
              onClick={handleDecrement}
            >
              <img src={decrement} alt="decrement" />
            </button>
            <input
              // type="number"
              value={inputVal}
              onChange={handleChange}
              className={`${styles.productQuantityInput}`}
            />
            <button
              className={`${styles.productQuantityButton} ${
                styles.productQuantityIncrement
              } ${type === "details" && styles.borderLeft}`}
              onClick={handleIncrement}
            >
              <img src={increment} alt="increment" />
            </button>
          </div>
          {isDeleteIconRequired && (<div className={styles.delete} onClick={handleDeleteProductFromCart}>
            <img src={deleteIcon} alt="delete" />
          </div>)}
        </div>
      )}
      {toastMsg?.message && (
        <ToastNotification
          icon={toastMsg?.icon}
          content={toastMsg?.message}
          position={"top-right"}
        />
      )}
    </>
  );
};

export default ProductQuantityButton;
