import axios from "axios";
import { useRef } from "react";

export const useCancelToken = () => {
  const tokenRef = useRef<any>();
  function cancelPreviousRequest() {
    if (typeof tokenRef.current != typeof undefined) {
      tokenRef.current.cancel("Operation canceled due to new request.");
    }
  }

  function createCancelToken() {
    cancelPreviousRequest()
    tokenRef.current = axios.CancelToken.source();
  }
  return { cancelTokenRef: tokenRef, createCancelToken };
};
