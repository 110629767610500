import { useState } from "react";

const useToken = () => {
  const getSessionToken: () => string | null = () => {
    const sessionTokenString = localStorage.getItem("token");
    if (sessionTokenString) {
      try {
        const usersessionToken = JSON.parse(sessionTokenString);
        return usersessionToken;
      } catch (e) {
        return sessionTokenString;
      }
    }
    return null;
  };

  const [sessionToken, setSessionToken] = useState<string | null>(
    getSessionToken()
  );

  const saveSessionToken: (userSessionToken: string) => void = (
    userSessionToken: string
  ) => {
    localStorage.setItem("token", JSON.stringify(userSessionToken));
    setSessionToken(userSessionToken);
  };

  //clear the token on logout
  const removeSessionToken: () => void = () => {
    localStorage.removeItem("token");
    setSessionToken(null);
  };

  return {
    saveSessionToken,
    removeSessionToken,
    sessionToken,
    setSessionToken,
  };
};

export default useToken;
