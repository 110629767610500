import React, { useState } from "react";
import styles from "../../BulkUpload.module.scss";
import { CircularProgress } from "@mui/material";
import uploadAction from "../../../../../assets/images/uploadaction.svg";
import Modal from "@mui/material/Modal";
import whiteEye from "../../../../../assets/images/whiteEye.svg";
import whiteTrash from "../../../../../assets/images/whiteTrash.svg";
import cancelAction from "../../../../../assets/images/chevron-left-white.svg";

const UploadImg = ({
  customer,
  index,
  name,
  changeEditStatus,
  throwError,
  removeImage,
  imgIndx
}: any) => {
    const [isHovered, setIsHovered] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState("");
    const [imgfileName, setImgFileName] = useState("");

    const handleMouseEnter = (rowIndex: number) => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    const handleOpenModal = (
        imageSrc: string,
        fileName: string
      ) => {
        setModalImageSrc(imageSrc);
        // setCurrentImageIndex(index);
        // setCurrentImageIdx(idx);
        setModalOpen(true);
        setImgFileName(fileName);
      };
      const handleCloseModal = () => {
        setModalOpen(false);
      };
  return (
    <>
      {customer[name] && (
        <>
          <span
            className={styles.imageBox}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
          >
            <img
              src={customer[name]}
              alt={`Uploaded `}
            />
            {isHovered && (
              <div className={styles.uploadOverlay}>
                <div className={styles.overlayButtons}>
                  <div
                    className={styles.eyeButton}
                    onClick={() =>
                      handleOpenModal(customer[name], customer[`${name}_name`])
                    }
                  >
                    <img src={whiteEye} alt="whiteEyeIcon"></img>
                  </div>
                  <div
                    className={styles.deleteButton}
                    onClick={() => removeImage(name, index, imgIndx)}
                  >
                    <img src={whiteTrash} alt="whitedeleteIcon"></img>
                  </div>
                </div>
              </div>
            )}
            <Modal
              open={
                modalOpen 
              }
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={styles.modal}
            >
              <div className={styles.imageModalBox}>
                <div className={styles.header}>
                  <img src={cancelAction} alt="" onClick={handleCloseModal} />
                  <p>{imgfileName}</p>
                </div>
                {/* <button onClick={handleCloseModal}>close</button> */}
                <img src={modalImageSrc} alt="" />
              </div>
            </Modal>
          </span>
        </>
      )}
      {!customer[name] && 
        <span
        className={`${styles.uploadLabel} ${
          !customer.imgUrlLoaded && styles.pendingImgLoad
        }`}
      >
        {!customer.imgUrlLoaded && (
          <div className={styles.imgLoading}>
            <CircularProgress size={20} color="primary" />
          </div>
        )}

        <label htmlFor={`${index}_${name}`}>
          <img src={uploadAction} alt="upload action" />
        </label>
        <input
          hidden
          type="file"
          name={`${index}_${name}`}
          id={`${index}_${name}`}
          multiple
          disabled={!customer.imgUrlLoaded}
          accept=".jpg, .jpeg, .png"
          onChange={(e) => {
            if (customer.imgUrlLoaded) {
              const files:any = e.target.files;
            //   if (
            //     files &&
            //     files.length > 0 &&
            //     files.length <= 4 &&
            //     customer?.media?.value?.length + files.length <= 4
            //   ) {
                const uploadedImages = Array.from(files);
                // .map((file) =>
                //   URL.createObjectURL(file)
                // );
               console.log(name, "vvv1")
                changeEditStatus("media", index, {val: uploadedImages, name, imgIndx});
             
            }
          }}
        />
      </span>
      }
    
    </>
  );
};

export default UploadImg;
