import React, { useState } from "react";
import styles from "./cartProduct.module.scss";
import product from "../../../images/p1.jpg";
// import chevronDown from "../../../images/chevronDown.svg";
import watermelonOffer from "../../../images/watermelonOffer.svg";
import offer from "../../../images/offer.svg";
import newProduct from "../../../images/newProduct.svg";
import ProductQuantityButton from "../../../components/productQuantityButton";
import deleteIcon from "../../../../../../assets/images/deleteIcon.svg";
import favIconActive from "../../../images/favouriteActiveIcon.svg";
import favouriteIcon from "../../../images/favouriteIcon.svg";
import { useDispatch } from "react-redux";
import {
  addItemToFavourite,
  deleteItemAction,
} from "../../../../../../reducerSlices/myCartSlice";
import {SelectChangeEvent } from "@mui/material";
import SimpleSelect from "../../../components/SimpleSelect";
const CartProduct = ({ productDetails }: { productDetails: any }) => {
  const [quantity, setQuantity] = useState<any>(productDetails?.quantity);
  const dispatch = useDispatch();
  const { title, origin_country, metadata } = productDetails;
  const [age, setAge] = React.useState("1 x 1 Dozen");
  const options = [
    {
      value: "1 x 1 Dozen",
    },
    {
      value: "1 x 1 Other",
    },
  ];
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as any);
  };
  const handleFavouriteClick = () => {
    dispatch(addItemToFavourite(productDetails.id));
  };

  const handleDelete = () => {
    dispatch(deleteItemAction(productDetails.id));
  };
  return (
    <div className={styles.cartProductWrapper}>
      <div className={styles.productImageWrapper}>
        <img src={product} alt="product" className={styles.productImage} />
      </div>
      <div className={styles.productDetails}>
        <div className={styles.productNameQuantityWrapper}>
          <h3 className={styles.productName}>{title}</h3>
          <SimpleSelect 
            value={age}
            handleChange={handleChange}
            options={options}
           />
        </div>
        <div className={styles.productOfferPriceQuantityDetailsWrapper}>
          <div className={styles.productOfferPriceDetails}>
            <div className={styles.productCountryFlagOfferWrapper}>
              <div className={styles.productCountryFlag}>
                <img src={origin_country?.flag} alt="flag" width="22.5" />
                <p className={styles.countryText}>{origin_country?.name} </p>
              </div>
              <div className={styles.seperator}></div>
              <div className={styles.productOffers}>
                <img src={watermelonOffer} alt="watermelonOffer" />
                <img src={offer} alt="offer" />
                <img src={newProduct} alt="newProduct" />
              </div>
            </div>
            <div className={styles.productPrice}>
              AED <span className={styles.productPriceText}>25</span>.00
              <span className={styles.productOriginalPrice}>40.00 AED</span>
            </div>
            {metadata.offer != 0 && (
              <div className={styles.productDiscountText}>
                ({metadata.offer}% OFF)
              </div>
            )}
          </div>
          <div className={styles.productActions}>
            <ProductQuantityButton quantity={quantity} onChange={(count:number)=>setQuantity(count)}/>
            <button className={styles.removeDefault} onClick={handleDelete}>
              <img src={deleteIcon} alt="deleteIcon" />
            </button>
            <button
              className={styles.removeDefault}
              onClick={handleFavouriteClick}
            >
              <img
                alt="favourite"
                src={
                  productDetails.is_favourite ? favIconActive : favouriteIcon
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
