import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import cancel from "../../../../../assets/images/cancelToast.svg";
import filterIcon from "../../../../../assets/images/ordersListing/Filter.svg";
// import searchIcon from "../../../../../assets/images/ordersListing/searchIcon.svg";
import sortIcon from "../../../../../assets/images/ordersListing/sortIcon.svg";
// import search from "../../../../../assets/images/so-search.svg";
import { IOrderDetails } from "../../../../../models/IOrderDetails";
import { addOrdersListPlaced } from "../../../../../reducerSlices/manageOrdersList";
import { setTab } from "../../../../../reducerSlices/selectedTabSlice";
import { useAxios } from "../../../../../services/useAxios";
import PaginationComponent from "../../../../components/pagination/Pagination";
import { buyerAPIEndpoints, constants } from "../../../../constants/constants";
import { EmptyContainer } from "../emptyContainer/EmptyContainer";
import { OrderDetailsCard } from "../orderDetailsCard/OrderDetailsCard";
import styles from "./TabComponentStyles.module.scss";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { SideDrawer } from "../../../../components/SideDrawer/SideDrawer";
import AdminOrdersFilters from "../../../../../pages/admin/adminOrdersListing/components/AdminOrdersFilters";
import loadash from "lodash";
import { setPage } from "../../../../../reducerSlices/selectedPageSlice";
import { formatDate } from "../../../../../utils/dateFormat";
import { setFilterData } from "../../../../../reducerSlices/filtersDataSlice";
import MigratedOrderCard from "../../../../../pages/admin/adminOrdersListingCard/MigratedOrderCard";
import { convertTimeStampToUserTimeZone } from "../../../../../utils/functions";
import PageSearch from "../../../../components/pageSearch/PageSearch";
export const transformMigratedOrdersKeys = (data: any) => {
  return data?.map((item: any) => {
    return {
      price:
        item?.total_cost_amount != null
          ? Number(item?.total_cost_amount)?.toFixed(2)
          : "-",
      orderId: item?.id ?? "-",
      placedOn:
        convertTimeStampToUserTimeZone(item?.migrated_order_created_date) ??
        "-",
      buyerName: item?.buyer_business_name || "-",
      total:
        item?.total_payable_amount != null
          ? Number(item?.total_payable_amount)?.toFixed(2)
          : "-",
      orderType: item?.order_type ?? "-",
      orderPONumber: item?.order_number ?? "-",
      vat:
        item?.vat_amount != null ? Number(item?.vat_amount)?.toFixed(2) : "-",
    };
  });
};
export const TabComponent = () => {
  const itemsPerPage = 10;
  const manageOrdersList = useSelector((state: any) => state?.manageOrdersList);
  const filtrationKeys = useSelector((state: any) => state?.filteredKeys);
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.catalogue ?? 0
  );
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.supplierOrderListingPageNumber
  );
  const [activeTab, setActiveTab] = useState(selectedTab);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [searchText, setSearchText] = useState<any>(null);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [isdrawer, setIsDrawer] = React.useState({
    right: false,
  });
  const [finalYetToConfirm, setFinalYetToConfirm] = useState([]);
  const [finalProcessing, setFinalProcessing] = useState([]);
  const [finalShipped, setFinalShipped] = useState([]);
  const [finalCompleted, setFinalCompleted] = useState([]);
  const [ordersModified, setOrdersModified] = useState([]);
  const [ordersMigrated, setOrdersMigrated] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortValue, setSortValue] = useState("");
  // const [triggerSort, setTriggerSort] = useState(false);
  const [ShowBuyerListInFilter, setShowBuyerListInFilter] = useState([]);
  const [finalQuery, setFinalQuery] = useState<any>();
  // const [triggerApiCall, setTriggerApiCall] = useState(false);
  const selectTab = (index: number) => {
    setActiveTab(index);
    dispatch(setTab({ type: "catalogue", index }));
    setFinalQuery(null);
    dispatch(setFilterData({}));
    setSearchText("");
  };

  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );
  const getCurrentStatus = () => {
    return activeTab === 0
      ? "placed"
      : activeTab === 1
      ? "confirmed"
      : activeTab === 2
      ? "shipped"
      : activeTab === 3
      ? "completed"
      : activeTab === 4
      ? "modified"
      : "placed";
  };
  const getManageOrders = useAxios({
    axiosParams: {
      url: `store/master-order/ware-house-order/${
        loggedInUser &&
        loggedInUser?.business_details?.[0] &&
        loggedInUser?.business_details?.[0]?.business_store_id
      }?page=${currentPage}${searchText ? `&q=${searchText}` : ""}${
        activeTab !== 3
          ? `&status=${getCurrentStatus()}`
          : finalQuery?.paymentStatus?.length > 0
          ? `&status=${finalQuery?.paymentStatus}`
          : `&status=${getCurrentStatus()}`
      }${
        activeTab !== 3 && finalQuery?.paymentStatus?.length
          ? `&payment_status=${finalQuery?.paymentStatus}`
          : ""
      }${
        finalQuery?.placedDate?.length
          ? `&placed_date=${finalQuery?.placedDate}`
          : ""
      }${
        finalQuery?.deliveredDate?.length
          ? `&delivered_date=${finalQuery?.deliveredDate}`
          : ""
      }${
        finalQuery?.supplier?.length
          ? `&customer_id=${finalQuery?.supplier}`
          : ""
      }${
        finalQuery?.buyer?.length
          ? `&buyer_customer_id=${finalQuery?.buyer}`
          : ""
      }${
        finalQuery?.orderValueDropdown?.length
          ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
          : ""
      }${
        finalQuery?.orderValue?.length
          ? `&order_amount_value=${finalQuery?.orderValue}`
          : ""
      }${
        finalQuery?.orderQuantityDropdown?.length
          ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
          : ""
      }${
        finalQuery?.orderQuantity?.length
          ? `&order_quantity_value=${finalQuery?.orderQuantity}`
          : ""
      }${
        sortValue?.length > 0
          ? `${"&sort_by=total"}&sort_order=${sortValue}`
          : ""
      }`,
    },
    method: "GET",
  });
  const getMigratedOrders = useAxios({
    axiosParams: {
      url: `store/migrated_orders/supplier-order-details/${
        loggedInUser &&
        loggedInUser?.business_details?.[0] &&
        loggedInUser?.business_details?.[0]?.business_setup_id
      }?offset=${(currentPage - 1) * 10}&limit=10${searchText && `&q=${searchText}`}${
        sortValue?.length > 0
          ? `${"&sort_by=amount"}&sort_order=${sortValue}`
          : ""
      }`,
    },
    method: "GET",
  });
  // const isContentEmpty = (data: any) => {
  //   return data && data.length === 0;
  // };

  const getManageOrdersList = useCallback(async () => {
    dispatch(updateIsLoading(true));
    if (activeTab === 5) {
      const response: any = await getMigratedOrders.fetchData();
      if (response && response?.status === "SUCCESS") {
        setTotalOrdersCount(response?.data?.count);
        setOrdersMigrated(
          transformMigratedOrdersKeys(response?.data?.order_list)
        );
        dispatch(updateIsLoading(false));
      }
    } else {
      const response: any = await getManageOrders.fetchData();
      if (response && response?.data && response?.data?.orders) {
        dispatch(addOrdersListPlaced(response?.data?.orders));
        setTotalOrdersCount(response?.data?.count);
        dispatch(updateIsLoading(false));
      }
    }
  }, [getManageOrders]);

  const getDebouncedData = loadash.debounce(getManageOrdersList, 400);

  useEffect(() => {
    searchText!==null && getDebouncedData();
    return () => getDebouncedData.cancel();
  }, [searchText]);

  useEffect(() => {
    getManageOrdersList();
    if(searchText?.length<=0)
    setShowSearch(false);
  }, [activeTab, currentPage, finalQuery, sortValue]);

  // useEffect(() => {
  //   getManageOrdersList();
  // }, [searchText]);

  useEffect(() => {
    if (manageOrdersList) {
      switch (activeTab) {
        case 0:
          setFinalYetToConfirm(manageOrdersList);
          break;
        case 1:
          setFinalProcessing(manageOrdersList);
          break;
        case 2:
          setFinalShipped(manageOrdersList);
          break;
        case 3:
          setFinalCompleted(manageOrdersList);
          break;
        case 4:
          setOrdersModified(manageOrdersList);
          break;

        default:
          break;
      }
    }
  }, [activeTab, manageOrdersList]);

  // if (manageOrdersList.length === 0) {
  //   dispatch(updateIsLoading(true));
  // } else {
  //   dispatch(updateIsLoading(false));
  // }

  // const [showCross, setShowCross] = useState(false);
  useEffect(() => {
    setCurrentPage(1);
    dispatch(setPage({ type: "supplierOrderListingPageNumber", index: 1 }));
  }, [activeTab]);
  // const handleSearchSuggestions = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = e.target.value.toLowerCase().trimStart();
  //   setShowCross(inputValue.length > 0);
  //   setSearchText(inputValue);
  //   setCurrentPage(1);
  // };

  const tabsData = [
    {
      id: 0,
      label: constants.ordersListing.YET_TO_CONFIRM,
      content: finalYetToConfirm,
      visibleContent: finalYetToConfirm,
    },
    {
      id: 1,
      label: constants.ordersListing.PROCESSING,
      content: finalProcessing,
      visibleContent: finalProcessing,
    },
    {
      id: 2,
      label: constants.ordersListing.SHIPPED,
      content: finalShipped,
      visibleContent: finalShipped,
    },
    {
      id: 3,
      label: constants.ordersListing.COMPLETED,
      content: finalCompleted,
      visibleContent: finalCompleted,
    },
    {
      id: 4,
      label: constants.ordersListing.MODIFIEDORDER,
      content: ordersModified,
      visibleContent: ordersModified,
    },
    {
      id: 5,
      label: constants.ordersListing.migratedOrders,
      content: ordersMigrated,
      visibleContent: ordersMigrated,
    },
  ];
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "supplierOrderListingPageNumber", index: page }));
  };

  const [showSort, setShowSort] = useState(false);
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const [prevSelected, setPrevSelected] = useState(-1);

  // const getSortedList = useAxios({
  //   axiosParams: {
  //     url: `store/master-order/ware-house-order/${
  //       loggedInUser &&
  //       loggedInUser?.business_details?.[0] &&
  //       loggedInUser?.business_details?.[0]?.business_store_id
  //     }?status=${getCurrentStatus()}&page=${currentPage}${"&sort_by=total"}&sort_order=${sortValue}&${
  //       searchText ? `&q=${searchText}` : ""
  //     }`,
  //   },
  //   method: "GET",
  // });

  // const updateSortedList = async () => {
  //   if (triggerSort) {
  //     dispatch(updateIsLoading(true));
  //     console.log("HERE");
  //     const response: any = await getSortedList.fetchData();
  //     if (response?.status === "SUCCESS") {
  //       if (response && response?.data && response?.data?.orders) {
  //         dispatch(addOrdersListPlaced(response?.data?.orders));
  //         setTotalOrdersCount(response?.data?.count);
  //         dispatch(updateIsLoading(false));
  //       }
  //     } else {
  //       dispatch(updateIsLoading(false));
  //     }
  //     setTriggerSort(false);
  //   }
  // };

  // useEffect(() => {
  //   updateSortedList();
  // }, [sortValue]);

  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("whatsnew");
      },
    },
    {
      id: 1,
      label: constants.ordersListing.SORT_LOW_HIGH,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("asc");
      },
    },
    {
      id: 2,
      label: constants.ordersListing.SORT_HIGH_LOW,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("desc");
      },
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const [showSearch, setShowSearch] = useState(false);
  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchText(value);
    dispatch(setPage({ type: "supplierOrderListingPageNumber", index: 1 }));
  };
  // const handleSearch = () => {
  //   setShowCross(false);
  //   setShowSearch(!showSearch);
  //   setSearchText("");
  // };
  // const handleSearchIcon = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   setShowSearch(!showSearch);
  //   setShowSort(false);
  // };
  // const isClickedOutOfSearch = (event: any) => {
  //   return (
  //     showSearch && inputRef.current && !inputRef.current.contains(event.target)
  //   );
  // };

  const sortContainerRef = useRef<HTMLDivElement>(null);
  // const inputRef = useRef<HTMLInputElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event?.target)
      ) {
        setShowSort(false);
      }
      // if (isClickedOutOfSearch(event) && searchText?.length<=0) {
      //   setShowSearch(false);
      //   setSearchText("");
      // }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSearch]);

  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  //To get buyers list
  const getBuyersList = useAxios({
    axiosParams: {
      url: `${buyerAPIEndpoints.buyerFilters}`,
    },
    method: "GET",
  });

  const getBuyerListData = useCallback(async () => {
    const response: any = await getBuyersList.fetchData();
    if (response?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      const buyerList = response?.data?.invoiceList;
      if (buyerList && buyerList.length > 0) {
        const updatedbuyerList = buyerList.map((buyer: any) => ({
          ...buyer,
          name: buyer.outlet_name,
          label: buyer.outlet_name,
          value: buyer.outlet_name,
        }));
        // console.log(updatedbuyerList, "updatedSupplierList");
        if (updatedbuyerList?.length > 0) {
          setShowBuyerListInFilter(updatedbuyerList);
        }
      }
    }
  }, [getBuyersList]);

  useEffect(() => {
    dispatch(updateIsLoading(true));
    getBuyerListData();
  }, []);

  //To get filtered list
  // const getFilteredList = useAxios({
  //   axiosParams: {
  //     url: `store/master-order/ware-house-order/${
  //       loggedInUser &&
  //       loggedInUser?.business_details?.[0] &&
  //       loggedInUser?.business_details?.[0]?.business_store_id
  //     }?${
  //       activeTab !== 3
  //         ? `status=${getCurrentStatus()}`
  //         : finalQuery?.paymentStatus?.length > 0
  //         ? `status=${finalQuery?.paymentStatus}`
  //         : `status=${getCurrentStatus()}`
  //     }&page=${currentPage}${searchText ? `&q=${searchText}` : ""}${
  //       activeTab !== 3 && finalQuery?.paymentStatus?.length
  //         ? `&payment_status=${finalQuery?.paymentStatus}`
  //         : ""
  //     }${
  //       finalQuery?.placedDate?.length
  //         ? `&placed_date=${finalQuery?.placedDate}`
  //         : ""
  //     }${
  //       finalQuery?.deliveredDate?.length
  //         ? `&delivered_date=${finalQuery?.deliveredDate}`
  //         : ""
  //     }${
  //       finalQuery?.supplier?.length
  //         ? `&customer_id=${finalQuery?.supplier}`
  //         : ""
  //     }${
  //       finalQuery?.buyer?.length
  //         ? `&buyer_customer_id=${finalQuery?.buyer}`
  //         : ""
  //     }${
  //       finalQuery?.orderValueDropdown?.length
  //         ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
  //         : ""
  //     }${
  //       finalQuery?.orderValue?.length
  //         ? `&order_amount_value=${finalQuery?.orderValue}`
  //         : ""
  //     }${
  //       finalQuery?.orderQuantityDropdown?.length
  //         ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
  //         : ""
  //     }${
  //       finalQuery?.orderQuantity?.length
  //         ? `&order_quantity_value=${finalQuery?.orderQuantity}`
  //         : ""
  //     }`,
  //   },
  //   method: "GET",
  // });

  // const fetchData = useCallback(async () => {
  //   if (triggerApiCall) {
  //     dispatch(updateIsLoading(true));
  //     const response: any = await getFilteredList.fetchData();
  //     if (response?.status === "SUCCESS") {
  //       if (response && response?.data && response?.data?.orders) {
  //         dispatch(addOrdersListPlaced(response?.data?.orders));
  //         setTotalOrdersCount(response?.data?.count);
  //       }
  //       dispatch(updateIsLoading(false));
  //     } else {
  //       dispatch(updateIsLoading(false));
  //     }
  //     setTriggerApiCall(false);
  //   }
  // }, [triggerApiCall]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  const onSubmitData = (data: any) => {
    const finalDataLocal: any = {};
    if (data?.actualAmountValue && data?.actualAmount) {
      finalDataLocal["actualAmountValue"] = data?.actualAmountValue;
      finalDataLocal["actualAmountDropdown"] =
        typeof data?.actualAmount != "string"
          ? data?.actualAmount?.value
          : data?.actualAmount;
    }
    if (data?.estimatedAmountValue && data?.estimatedAmount) {
      finalDataLocal["estimatedAmountValue"] = data?.estimatedAmountValue;
      finalDataLocal["estimatedAmountDropdown"] =
        typeof data?.estimatedAmount != "string"
          ? data?.estimatedAmount?.value
          : data?.estimatedAmount;
    }
    if (data?.orderValue && data?.orderValue?.length > 0) {
      finalDataLocal["orderValue"] = data?.orderValue;
    }
    if (data?.orderValueDropdown && data?.orderValueDropdown?.length > 0) {
      finalDataLocal["orderValueDropdown"] = data?.orderValueDropdown
        ?.split(" ")?.[0]
        ?.toLowerCase();
    }
    if (data?.orderQuantity && data?.orderQuantity?.length > 0) {
      finalDataLocal["orderQuantity"] = data?.orderQuantity;
    }
    if (
      data?.orderQuantityDropdown &&
      data?.orderQuantityDropdown?.length > 0
    ) {
      finalDataLocal["orderQuantityDropdown"] = data?.orderQuantityDropdown
        ?.split(" ")?.[0]
        ?.toLowerCase();
    }
    if (data?.buyer && data?.buyer?.length > 0) {
      finalDataLocal["buyer"] = data?.buyer;
    }
    if (data?.supplier && data?.supplier?.length > 0) {
      finalDataLocal["supplier"] = data?.supplier;
    }
    if (data?.placedDate) {
      finalDataLocal["placedDate"] = formatDate(data?.placedDate);
    }
    if (data?.deliveredDate) {
      finalDataLocal["deliveredDate"] = formatDate(data?.deliveredDate);
    }
    let payStatus = "";
    if (data?.paymentStatus) {
      if (data?.paymentStatus?.paid) {
        payStatus += "paid";
      }
      if (data?.paymentStatus?.unpaid) {
        payStatus =
          payStatus?.length > 0 ? payStatus + ",not_paid" : "not_paid";
      }
      finalDataLocal["paymentStatus"] = payStatus;
    }
    if (data?.orderStatus) {
      if (data?.orderStatus?.cancelled) {
        payStatus += "canceled";
      }
      if (data?.orderStatus?.delivered) {
        payStatus =
          payStatus?.length > 0
            ? payStatus + ",order_completed"
            : "order_completed";
      }
      finalDataLocal["paymentStatus"] = payStatus;
    }
    setFinalQuery(finalDataLocal);
    // setTriggerApiCall(true);
  };
  //cleanup function for activeFilters in redux
  useEffect(() => {
    return () => {
      dispatch(setFilterData({}));
    };
  }, []);
  const searchCustomStyles = {
    top: "0px",
  };
  return (
    <>
      {tabsData && tabsData.length > 0 && (
        <div className={styles.container}>
          <div className={styles.tabs}>
            <div className={styles.tabsStyle}>
              <div className={styles.tabList}>
                {tabsData.map((each) => (
                  <div
                    className={styles.tabListItem}
                    onClick={() => selectTab(each?.id)}
                  >
                    <div
                      className={`${styles.label} ${
                        each.id === activeTab && styles.activeLabel
                      }`}
                    >
                      {each.label}
                    </div>
                    {activeTab === each?.id && (
                      <div className={styles.border}></div>
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.tabsBorder}></div>
            </div>
            {
              <div
                className={`${styles.actions} ${showSearch && styles.actions2}`}
              >
                {/* <div className={styles.search}>
                  {showSearch ? (
                    <div className={styles.searchContainer}>
                      <div className={styles.searchIconInput}>
                        <img src={search} alt="searchIcon" height="24px" />
                        <input
                          value={searchText}
                          type="search"
                          placeholder="Search by order id or buyer name"
                          className={styles.searchInput}
                          onChange={handleSearchSuggestions}
                          autoFocus
                          ref={inputRef}
                        />
                      </div>
                      {showCross ? (
                        <div className={styles.cancel} onClick={handleSearch}>
                          <img src={cancel} alt="cancel" />
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className={styles.searchIcon}
                      onClick={(e) => handleSearchIcon(e)}
                    >
                      <img src={searchIcon} alt="search"></img>
                    </div>
                  )}
                </div> */}
                <div>
                    <PageSearch
                      handleSearch={handleSearch}
                      customStyles={searchCustomStyles}
                      customPlaceHolder="Search by invoice no."
                    />
                  </div>
                {
                  <div
                    className={`${styles.icon} ${styles.sort}`}
                    onClick={handleSort}
                    ref={sortContainerRef}
                  >
                    <img src={sortIcon} alt="sort"></img>
                    {showSort && (
                      <div className={styles.sortList}>
                        <div className={styles.sortBy}>
                          {constants.ordersListing.SORT_BY}
                        </div>
                        {sortOptions.map((option, index) => (
                          <div
                            className={`${styles.sortOption} ${
                              index === sortOptions.length - 1 &&
                              styles.lastOption
                            } ${
                              option.id === prevSelected && styles.selectedOpt
                            }`}
                            onClick={() => handleSortingOptions(option.id)}
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
                {activeTab !==5 &&
                  <div
                    ref={filtersRef}
                    className={styles.icon}
                    onClick={() => setIsDrawer({ right: true })}
                  >
                    {filtrationKeys?.filterData?.activeFilters && (
                      <div className={styles.activeFilter}></div>
                    )}
                    <img src={filterIcon} alt="filter"></img>
                  </div>
                }
              </div>
            }
          </div>
          {(activeTab !== 5 &&
            manageOrdersList &&
            manageOrdersList.length === 0) ||
          (activeTab === 5 && (ordersMigrated===undefined || ordersMigrated?.length === 0)) ? (
            <EmptyContainer title={"Woah!"} subText={"No orders"} />
          ) : (
            <div
              className={
                activeTab === 3
                  ? styles.tabsContentCompleted
                  : styles.tabsContent
              }
            >
              {activeTab === 5 ? (
                <div className={styles.header}>
                  <div className={`${styles.migratedOrder} ${styles.labelStyle}`}>
                    {"Order Number"}
                  </div>
                  <div className={`${styles.migratedOrder} ${styles.labelStyle}`}>
                    {"Order Date"}
                  </div>

                  <div className={`${styles.migratedOrder} ${styles.labelStyle}`}>
                    {"Price"}
                  </div>
                  <div className={`${styles.migratedOrder} ${styles.labelStyle}`}>
                    {"VAT"}
                  </div>
                  <div className={`${styles.migratedOrder} ${styles.labelStyle}`}>
                    {"Total"}
                  </div>
                </div>
              ) : (
                <div className={styles.header}>
                  <div className={`${styles.orderID} ${styles.labelStyle}`}>
                    {constants.ordersListing.ORDER_ID}
                  </div>
                  <div className={`${styles.placedOn} ${styles.labelStyle}`}>
                    {constants.ordersListing.PLACED_ON}
                  </div>
                  <div className={`${styles.buyerName} ${styles.labelStyle}`}>
                    {constants.ordersListing.BUYER_NAME}
                  </div>
                  <div className={`${styles.orderValue} ${styles.labelStyle}`}>
                    {constants.ordersListing.ORDER_VALUE}
                  </div>
                  {activeTab === 3 && (
                    <div
                      className={`${styles.actualAmount} ${styles.labelStyle}`}
                    >
                      {constants.ordersListing.ACTUAL_AMOUNT}
                    </div>
                  )}
                  <div className={`${styles.orderItems} ${styles.labelStyle}`}>
                    {constants.ordersListing.ORDER_ITEMS}
                  </div>
                </div>
              )}
              {/* {activeTab === 5 && (
                <div className={styles.tabslistContainer}>
                  <div className={styles.ordersList}>
                    <MigratedOrderCard />
                  </div>
                </div>
              )} */}
              {tabsData &&
                tabsData.length > 0 &&
                tabsData.map((each) => {
                  return (
                    activeTab === each.id && (
                      <div className={styles.tabslistContainer} key={each.id}>
                        {each.visibleContent && (
                          <>
                            <div className={styles.ordersList}>
                              {each.id === 5
                                ? each.visibleContent.map(
                                    (migratedOrder: any) => {
                                      return (
                                        <MigratedOrderCard
                                          order={migratedOrder}
                                        />
                                      );
                                    }
                                  )
                                : each.visibleContent.map(
                                    (order: IOrderDetails) => {
                                      return (
                                        <OrderDetailsCard
                                          type={each?.label}
                                          key={order.id}
                                          icon={order?.icon}
                                          orderId={order?.orderId}
                                          buyerName={order?.buyerName}
                                          paidStatus={order?.paidStatus}
                                          orderStatus={order?.orderStatus}
                                          actualAmount={order?.actualAmount}
                                          orderItems={order?.orderItems}
                                          orderValue={order?.orderValue}
                                          placedOn={order?.placedOn}
                                          activeTab={activeTab}
                                          orderPONumber={order?.orderPONumber}
                                        />
                                      );
                                    }
                                  )}
                            </div>
                            <div className={styles.paginationContainer}>
                              <PaginationComponent
                                count={Math.ceil(
                                  totalOrdersCount / itemsPerPage
                                )}
                                page={currentPage}
                                handlePagination={handlePagination}
                                showFirstButton={true}
                                showLastButton={true}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )
                  );
                })}
            </div>
          )}
        </div>
      )}
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={"Filters"}
          childComponent={
            <AdminOrdersFilters
              overlayCallback={setIsDrawer}
              activeTab={activeTab}
              isAdmin={isAdmin}
              onSubmitData={onSubmitData}
              // ShowSupplierListInFilter={ShowSupplierListInFilter}
              ShowBuyerListInFilter={ShowBuyerListInFilter}
            />
          }
        />
      )}
    </>
  );
};
