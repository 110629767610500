import React from "react";
import { useNavigate } from "react-router-dom";
import telescope from "../../../assets/images/telescope.svg";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../constants/routeConfig";
import styles from "./LoadingScreenStyles.module.scss";
const LoadingScreen = () => {
  // const [userType, setUserType] = React.useState("buyer");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(routeConfig.supplierDashboard);
  };
  return (
    <div className={styles.container}>
      {/* <SideNav userType={userType} setUserType={setUserType} selectedId={constants.navLinksId.marketplace}/> */}
      <div className={styles.rightBodyContainer}>
        <div className={styles.container}>
          <div className={styles.loadingImage}>
            <img src={telescope} alt="telescope" />
          </div>
          <p className={styles.para1}>{constants.loadingScreen.para1}</p>
          <p className={styles.para2}>{constants.loadingScreen.para2}</p>
          <button onClick={handleClick} className={styles.button}>
            {constants.loadingScreen.buttonPlaceholder}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
