import React from "react";
import Button from "../../../../../common/components/button/Button";
import styles from "./editCartHeader.module.scss";
import cartMoving from "../../images/cartMoving.svg";
import ellipse2 from "../../images/ellipse2.svg";
import ellipse1 from "../../images/ellipse1.svg";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../../common/constants/routeConfig";
import { constants } from "../../../../../common/constants/constants";
const EditCartHeader = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.editCartHeaderWrapper}>
      <img src={cartMoving} alt="cart" />
      <div className={styles.textWrapper}>
        <h2 className={styles.textHeader}>Hey there,</h2>
        <p className={styles.text}>
         {constants.myCart.editCartContent}
        </p>
      </div>
      <Button
        secondary
        width="120px"
        label="Edit Cart"
        handleClick={() => {
          navigate(routeConfig.myCart);
        }}
      />
      <img src={ellipse1} alt="ellipse" className={styles.ellipse1} />
      <img src={ellipse2} slot="ellipse" className={styles.ellipse2} />
    </div>
  );
};

export default EditCartHeader;
