import React from "react";
import styles from "./LoginBackground.module.scss";
import logo from "../../assets/images/login/logo.svg";
import { LOGIN_PAGE_STRINGS } from "../../common/constants/stringConstants";

const LoginBackground = ({ form, userType }: any) => {
  return (
    <>
      {/* <section
        className={`${styles.login} ${
          userType === "buyer" && styles.buyerBgImg
        }`}
      > */}
      <section
        className={`${styles.login}`}
      >
        <div className={styles.login__logoSection}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.getStarted}>
            {userType === "buyer" ? (
              <>
                <span>{LOGIN_PAGE_STRINGS.GET_STARTED} </span>
                <span className={styles.getStarted__line1}>
                  {LOGIN_PAGE_STRINGS.GET_STARTED2}
                </span>
              </>
            ) : (
              <>
                <span>{LOGIN_PAGE_STRINGS.START_YOU} </span>
                <span className={styles.getStarted__line1}>
                  {LOGIN_PAGE_STRINGS.SUPPLIER_LOGIN_TEXT}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={styles.login__formSection}>{form}</div>
      </section>
    </>
  );
};

export default LoginBackground;
