import styles from "./ProductAverageRatingSection.module.scss";
import Star from "../../../assets/images/Star.svg";
import emptyStar from "../../../assets/images/emptyStar.svg";
// import ProgressBar from "../progressBar/ProgressBar";

const ProductAverageRatingSection = ({ rating }: any) => {
  return (
    <>
      <div className={styles.averageRatingSection}>
        <div className={styles.averageRating}>
          {/* Rating Summary */}
          <div className={styles.ratingSummary}>
            <div className={styles.ratingSummaryTop}>
              <span className={styles.numericValue}>
                <span className={styles.actualValue}>
                  {Number(rating?.rating).toFixed(1)}
                </span>
                /5
              </span>
              <span className={styles.basedOn}>
                Based on {Number(rating?.total_count)} ratings
              </span>
            </div>
            <div className={styles.ratingSummaryBottom}>
              {Array(5)
                .fill(0)
                .map((each: number, index: number) =>
                  index < Number(rating?.rating) ? (
                    <div>
                      <img src={Star} alt="" />
                    </div>
                  ) : (
                    <div>
                      <img src={emptyStar} alt="" />
                    </div>
                  )
                )}
            </div>
          </div>
          {/* Rating Distribution */}
          {/* <div className={styles.ratingDistribution}>
            <div className={styles.rating}>
              <div>5</div>
              <div>
                <img src={Star} alt="Star Icon" />
              </div>

              <ProgressBar progress={60} />

              <div className={styles.numberOfRatings}>39 ratings</div>
            </div>
            <div className={styles.rating}>
              <div>4</div>
              <div>
                <img src={Star} alt="Star Icon" />
              </div>
              <ProgressBar progress={35} />
              <div className={styles.numberOfRatings}>39 ratings</div>
            </div>
            <div className={styles.rating}>
              <div>3</div>
              <div>
                <img src={Star} alt="Star Icon" />
              </div>
              <ProgressBar progress={20} />
              <div className={styles.numberOfRatings}>39 ratings</div>
            </div>
            <div className={styles.rating}>
              <div>2</div>
              <div>
                <img src={Star} alt="Star Icon" />
              </div>

              <ProgressBar progress={10} />
              <div className={styles.numberOfRatings}>39 ratings</div>
            </div>
            <div className={styles.rating}>
              <div>1</div>
              <div>
                <img src={Star} alt="Star Icon" />
              </div>

              <ProgressBar progress={15} />
              <div className={styles.numberOfRatings}>39 ratings</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProductAverageRatingSection;
