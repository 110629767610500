import styles from "./DownloadManageCatalogue.module.scss";
import Dropdown from "../../../../../common/components/dropdown/Dropdown";
// import dropDownIcon from "../../../../../assets/images/icons-home-dollar.svg";
import { useEffect, useState } from "react";
// import InputField from "../../../../../common/components/formFields/inputField/inputField";
// import Button from "../../../../../common/components/button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import {
  ICategory,
  ISubCategory,
  ISubSubCategory,
} from "../../../../../models/IAddIndividualProductForm";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { useDispatch } from "react-redux";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods, urlConstants } from "../../../../../common/constants/urlConstants";
import InputField from "../../../../../common/components/formFields/inputField/inputField";
import Button from "../../../../../common/components/button/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PaginationComponent from "../../../../../common/components/pagination/Pagination";
import { constants } from "../../../../../common/constants/constants";
import { ToastNotification } from "../../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../../assets/images/errorToast.svg";
import successToast from "../../../../../assets/images/successToast.svg";

const DownloadManageCatalogue = () => {
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [isAdmin, setIsAdmin] = useState(
    loggedInUser?.typeOfUser.includes("admin")
  );
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [subcategories, setSubcategories] = useState<ISubCategory[]>([]);
  const [subSubcategories, setSubSubcategories] = useState<ISubSubCategory[]>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISubCategory>(
    {}
  );
  const [selectedSubSubCategory, setSelectedSubSubCategory] =
    useState<ISubSubCategory>({});
  const [supplierList, setSupplierList] = useState<any>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<any>([]);
  const [productsData, setProductsData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const visibleProducts = productsData?.slice(firstIndex, lastIndex);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const columns: GridColDef[] = [
    {
      field: "Product_variant_title",
      headerName: "Product variant title",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Description",
      headerName: "Description",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Brand",
      headerName: "Brand",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Certifications",
      headerName: "Certifications",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Specifications",
      headerName: "Specifications",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Tags",
      headerName: "Tags",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Category_name",
      headerName: "Category name",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Sub_category_name",
      headerName: "Subcategory name",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Sub_sub_category_name",
      headerName: "Sub subcategory name",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Supplier_product_code",
      headerName: "Supplier product code",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Hs_code",
      headerName: "HS code",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "is_vat_included",
      headerName: "Is vat included",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: APIMethods.get,
  });
  const getSupplierDetails = useAxios({
    axiosParams: {
      url: `store/supplier?page=0`,
    },
    method: APIMethods.get,
  });
  const businessId =
    !isAdmin &&
    loggedInUser &&
    loggedInUser?.business_details?.[0] &&
    loggedInUser?.business_details?.[0]?.business_setup_id;

  const getUrl = (isDownload?: boolean, productName?: string) => {
    return `${isAdmin ? "admin" : "store"}/product/${isDownload ? "download" : "bulk_view"
      }${!isAdmin ? `/${businessId}` : ""}${selectedCategory.id ? `?category_id=${selectedCategory.id}` : ""
      }${selectedSubCategory.id ? `&sub_category_id=${selectedSubCategory.id}` : ""
      }${selectedSubSubCategory.id
        ? `&sub_sub_category_id=${selectedSubSubCategory.id}`
        : ""
      }${selectedCategory.id
        ? selectedSuppliers?.length
          ? `&supplier_id=${selectedSuppliers.map((ele: any) => ele.id) || ""}`
          : ""
        : selectedSuppliers?.length
          ? `?supplier_id=${selectedSuppliers.map((ele: any) => ele.id) || ""}`
          : ""
      }${selectedCategory.id
        ? productName
          ? `&product_name=${productName || ""}`
          : ""
        : productName
          ? `?product_name=${productName || ""}`
          : ""
      }${(selectedCategory.id || productName || selectedSuppliers?.length) ? `&all_records=true` : `?all_records=true`}`;
  };

  const schema = yup.object().shape({
    productCategory: yup.object(),
    productSubCategory: yup.object(),
    productSubSubCategory: yup.object(),
    supplierName: yup.object(),
    productName: yup.string(),
  });
  const { register, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  const getProducts = useAxios({
    axiosParams: {
      url: getUrl(),
    },
    method: APIMethods.get,
  });

  // const downloadProductsData = useAxios({
  //   axiosParams: {
  //     url: getUrl(true),
  //   },
  //   method: APIMethods.get,
  // });
  const getproductsList = async () => {
    setLoading(true);
    try {
      const response: any = await getProducts.fetchData({
        axiosParams: {
          url: getUrl(false, getValues("productName")),
        },
      });
      if (response && response.data) {
        setProductsData(response.data?.product_data);
      } else {
        setProductsData([]);
        setToastData({
          message: "Unable to fetch the products",
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    // getproductsList();
  }, []);

  const getAllCategoriesList = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getCategories?.fetchData();
    dispatch(updateIsLoading(false));
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          label: category.name,
          subcategories: category.sub_categories,
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
    }
  };
  const getSupplierList = async () => {
    dispatch(updateIsLoading(true));

    const response: any = await getSupplierDetails.fetchData();
    dispatch(updateIsLoading(false));
    if (response && response?.status === "SUCCESS") {
      const businessData = response?.data;
      if (businessData && businessData?.length > 0) {
        const updatedBusiness = businessData?.map((element: any) => {
          return {
            ...element,
            id: element?.id,
            label: element?.business_name,
            value: element?.business_name,
            name: element?.business_name,
            outlets: element?.business_stores,
          };
        });
        if (updatedBusiness?.length > 0) {
          setSupplierList(updatedBusiness);
        }
      }
    }
  };
  const getSubCategories = (id: string) => {
    if (id) {
      const selectedCategoryObj = categories?.find(
        (cat: ICategory) => cat.id === id
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.subcategories &&
        selectedCategoryObj?.subcategories?.length > 0
      ) {
        const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
          (category: ICategory) => ({
            ...category,
            value: category?.name,
            productSection: category?.sub_sub_categories,
          })
        );
        setSubcategories(updatedCategoriesData);
      } else {
        setSubcategories([]);
      }
    } else {
      setSubcategories([]);
    }
  };
  const getSubSubCategories = (id: string) => {
    if (id) {
      const selectedCategoryObj = subcategories?.find(
        (cat: ISubCategory) => cat.id === id
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.sub_sub_categories &&
        selectedCategoryObj?.sub_sub_categories?.length > 0
      ) {
        const updatedCategoriesData =
          selectedCategoryObj?.sub_sub_categories?.map(
            (category: ICategory) => ({
              ...category,
              value: category?.name,
            })
          );
        setSubSubcategories(updatedCategoriesData);
      } else {
        setSubSubcategories([]);
      }
    } else {
      setSubSubcategories([]);
    }
  };

  useEffect(() => {
    getAllCategoriesList();
    getSupplierList();
  }, []);

  useEffect(() => {
    if (loggedInUser?.typeOfUser) {
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, []);

  const onChangeField = (name: any) => (text: any) => {
    setValue(name, text);
    if (name === "productCategory") {
      setSelectedCategory(text);
      getSubCategories(text?.id);
    }
    if (name === "productSubCategory") {
      setSelectedSubCategory(text);
      getSubSubCategories(text?.id);
    }
    if (name === "productSubSubCategory") {
      setSelectedSubSubCategory(text);
    }
    if (name === "supplier") {
      setSelectedSuppliers(text);
    }
  };
  const handleDownload = async () => {
    window.location.href = `${urlConstants.BASE_URL}${getUrl(true, getValues("productName"))}`;

    // dispatch(updateIsLoading(true));
    // const response: any = await downloadProductsData.fetchData({
    //   axiosParams: {
    //     url: getUrl(true, getValues("productName")),
    //     responseType: "blob"
    //   },
    // });
    // if (response) {
    //   const url = window.URL.createObjectURL(new Blob([response]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `data.xlsx`);
    //   document.body.appendChild(link);
    //   link.click();
    //   dispatch(updateIsLoading(false));
    // }
  };
  const formSubmit = () => {
    getproductsList();
  };
  return (
    <>
      <div className={styles.reportContainer}>
        <section className={styles.tableFilterContainer}>
          {/* <div className={styles.filterText}>Select</div> */}
          <form
            // className={styles.filteSection}
            onSubmit={handleSubmit(formSubmit)}
          >
            <div className={styles.filteSection}>
              <div className={styles.input}>
                <div className={styles.label}>Product category</div>
                <Dropdown
                  minWidth="100%"
                  label={""}
                  selectedValue={selectedCategory}
                  options={categories}
                  defaultLabel={
                    selectedCategory?.name ?? "Select product category..."
                  }
                  id="productCategory"
                  onChange={(selectedOption: ICategory) => {
                    onChangeField("productCategory")(selectedOption);
                    // handleCategoryClick(selectedOption);
                  }}
                  tabIndex={3}
                  searchField={true}
                />
              </div>
              <div className={styles.input}>
                <div className={styles.label}>Product sub category</div>
                <Dropdown
                  minWidth="100%"
                  label={""}
                  selectedValue={selectedSubCategory}
                  options={subcategories}
                  defaultLabel={
                    selectedSubCategory?.name ?? "Select product sub category..."
                  }
                  id="productSubCategory"
                  onChange={(selectedOption: ICategory) => {
                    onChangeField("productSubCategory")(selectedOption);
                    // handleCategoryClick(selectedOption);
                  }}
                  tabIndex={3}
                  searchField={true}
                />
              </div>
              <div className={styles.input}>
                <div className={styles.label}>Product sub sub category</div>
                <Dropdown
                  minWidth="100%"
                  label={""}
                  selectedValue={selectedSubSubCategory}
                  options={subSubcategories}
                  defaultLabel={
                    selectedSubSubCategory?.name ??
                    "Select product sub sub category..."
                  }
                  id="productSubSubCategory"
                  onChange={(selectedOption: ICategory) => {
                    onChangeField("productSubSubCategory")(selectedOption);
                  }}
                  tabIndex={3}
                  searchField={true}
                />
              </div>
              {isAdmin && (
                <div className={styles.input}>
                  <div className={styles.label}>Supplier Name</div>
                  <Dropdown
                    multiple
                    minWidth="100%"
                    label={""}
                    selectedValue={selectedSuppliers}
                    options={supplierList}
                    defaultLabel={"Select Supplier"}
                    id="supplier"
                    onChange={(selectedOption: ICategory) => {
                      onChangeField("supplier")(selectedOption);
                    }}
                    tabIndex={3}
                    searchField={true}
                    itemStyles={{ maxWidth: 300 }}
                  />
                </div>
              )}
              {/* <div className={styles.input}>
              <div className={styles.label}>Supplier Name</div>
              <Dropdown
                minWidth="100%"
                label={""}
                selectedValue={selectedSuppliers}
                options={supplierList}
                id={"deliveryAddress"}
                isDropdownOutlineRequired={true}
                onChange={onChangeField("supplier")}
                multiple={true}
                defaultLabel="Select supplier"
              />
            </div> */}
              <div className={`${styles.input}`}>
                <div className={styles.label}>Product Name</div>
                <InputField
                  // value={getValues("productName")}
                  maxWidth="100%"
                  placeholder="Enter here..."
                  id="productName"
                  name="productName"
                  onChange={onChangeField("productName")}
                  register={register("productName")}
                  tabIndex={6}
                />
              </div>
              <div className={styles.buttonSection}>
                <div>
                  <Button label="Generate" handleClick={() => { }} width="160px" />
                </div>
                <div>
                  {/* <button
                  className={styles.submit}
                  type="button"
                  onClick={handleDownload}
                  disabled={true}
                > */}
                  <Button label="Download" handleClick={handleDownload} width="160px" customClass={styles.submit} disabled={productsData && productsData?.length === 0} />
                  {/* Download */}
                  {/* </button> */}
                </div>
              </div>
            </div>
          </form>
        </section>
        <section className={styles.tableContainer}>
          <DataGrid
            rows={visibleProducts}
            columns={columns}
            hideFooterPagination
            hideFooter
            getRowId={(row) => row.product_id}
            loading={loading}
            sx={{
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              ".MuiDataGrid-main": {
                backgroundColor: "#fff",
              }
            }}
          />
          <div className={styles.paginationContainer}>
            <PaginationComponent
              count={Math.ceil(productsData.length / itemsPerPage)}
              page={currentPage}
              handlePagination={handlePagination}
            />
          </div>
        </section>
      </div>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};
export default DownloadManageCatalogue;
