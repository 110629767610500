import styles from "./ShareAProduct.module.scss";
import shareEmail from "../../../assets/images/shareIcons/shareEmail.svg";
import shareFacebook from "../../../assets/images/shareIcons/ShareFacebook.svg";
import shareX from "../../../assets/images/shareIcons/twitter-logo-2429.svg";
import shareLinkedIn from "../../../assets/images/shareIcons/ShareLinkedIn.svg";
// import shareMix from "../../../assets/images/shareIcons/shareMix.svg";
// import sharePinterest from "../../../assets/images/shareIcons/sharePinterest.svg";
import shareWhatsapp from "../../../assets/images/shareIcons/shareWhatsapp.svg";
// import shareGoo from "../../../assets/images/shareIcons/shareGoo.svg";
import InputField from "../formFields/inputField/inputField";
import Slider from "react-slick";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {  useState } from "react";
import { Tooltip } from "@mui/material";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
const ShareAProduct = (props: any) => {
  console.log(props?.currentUrl, "props")
  const linkToShare = props?.currentUrl;
  const [copyText, setCopyText] = useState({
    value: props?.currenturl,
    copied: false,
  });

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 500,
  };
  const handleChange = (text: any) => {
    // setCopyText({ ...copyText, value: text })
  };

  const getFacebookPlatForm = (icon: string, title: any) => {
    return (
      <div className={styles.iconContainer}>
        <FacebookShareButton url={linkToShare} hashtag={props?.title} title={props?.title}>
          <div className={styles.icon}>
            <img src={icon} alt="facebook" />
          </div>
          <div className={styles.title}>{title}</div>
        </FacebookShareButton>
      </div>
    );
  };
  const getWhatsappPlatForm = (icon: string, title: any) => {
    return (
      <div className={styles.iconContainer}>
        <WhatsappShareButton
          url={linkToShare}
          title={props?.title ?? "product"}
        >
          <div className={styles.icon}>
            <img src={icon} alt="whatsapp" />
          </div>
          <div className={styles.title}>{title}</div>
        </WhatsappShareButton>
      </div>
    );
  };
  const getTwitterPlatForm = (icon: string, title: any) => {
    return (
      <div className={styles.iconContainer}>
        <TwitterShareButton url={linkToShare} title={props?.title}>
          <div className={styles.icon}>
            <img src={icon} alt="X" />
          </div>
          <div className={styles.title}>{title}</div>
        </TwitterShareButton>
      </div>
    );
  };
  const getEmailPlatForm = (icon: string, title: any, body: string) => {
    return (
      <div className={styles.iconContainer}>
        <EmailShareButton
          url={linkToShare}
          title={"Watermelon"}
          openShareDialogOnClick
          body={body}
          subject="Watermelon product"
        >
          <div className={styles.icon}>
            <img src={icon} alt="email" />
          </div>
          <div className={styles.title}>{title}</div>
        </EmailShareButton>
      </div>
    );
  };
  const getLinkedinPlatform = (icon: string, title: any) => {
    return (
      <div className={styles.iconContainer}>
        <LinkedinShareButton url={linkToShare} title={props?.title}>
          <div className={styles.icon}>
            <img src={icon} alt="linkedin" />
          </div>
          <div className={styles.title}>{title}</div>
        </LinkedinShareButton>
      </div>
    );
  };
  const plateformsToShare = [
    getFacebookPlatForm(shareFacebook, "Facebook"),
    getWhatsappPlatForm(shareWhatsapp, "Whatsapp"),
    getTwitterPlatForm(shareX, "Twitter"),
    getEmailPlatForm(shareEmail, "Email", props?.title),
    getLinkedinPlatform(shareLinkedIn, "LinkedIn"),
  ];

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {plateformsToShare && [...plateformsToShare]}
          </Slider>
        </div>
        <div className={styles.pathContainer}>
          <div className={styles.actionContainer}>
            <div className={styles.input}>
              <InputField
                width="450px"
                onChange={handleChange}
                value={
                  props?.currentUrl?.length > 50
                    ? `${props?.currentUrl?.slice(0, 50)}...`
                    : props?.currentUrl
                }
                removeBorder={true}
                borderRightRemove={true}
                customClass={styles.inputLink}
                borderLeftRemove={true}
              />
            </div>
            <CopyToClipboard
              text={props?.currentUrl}
              onCopy={() => {
                setCopyText((prev) => ({ ...prev, copied: true }));
              }}
            >
              <Tooltip
                onOpen={() => {
                  setCopyText((prev) => ({ ...prev, copied: false }));
                }}
                title={
                  copyText.copied ? "Copied to clipboard" : props?.currentUrl
                }
                arrow
                placement="top"
              >
                <button className={styles.commonButton}>Copy</button>
              </Tooltip>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareAProduct;