import { MenuItem, Select } from "@mui/material";
import React from "react";

const SimpleSelect = ({
  value,
  handleChange,
  options,
  label,
  getOptionLabel,
  customSelectStyles,
}: any) => {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label={label}
      onChange={handleChange}
      sx={{
        fontSize: "12px",
        color: "#ff5976",
        ".MuiSelect-select": {
          padding: "0px 31px 0px 0px",
        },
        ...customSelectStyles,
      }}
    >
      {options.map((option: any) => {
        return (
          <MenuItem
            value={option.value}
            key={getOptionLabel ? getOptionLabel(option) : option.value}
            sx={{
              fontSize: "12px",
            }}
          >
            {getOptionLabel ? getOptionLabel(option) : option.value}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SimpleSelect;
