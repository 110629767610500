import { createSlice } from "@reduxjs/toolkit";
import { IProduct } from "../models/IProductListingCard";

const transformKeys = (item: any, isSearchData: boolean) => {
  // console.log("item", item);

  const getImageUrl = () => {
    let defaultImage = item?.images?.find(
      (item: any) => item?.metadata?.default_image
    );
    if (defaultImage) {
      return defaultImage?.url;
    } else {
      return item?.images?.[0]?.url;
    }
  };
  return {
    productType: isSearchData ? item?.type : item?.product_type, // change key ---> type
    productId: item?.id,
    productTitle: item?.title,
    productSubtitle: item?.product_subtitle, // nhi pata
    productHandle: item?.product_handle, // nhi pata
    productStatus: isSearchData ? item?.status : item?.product_status, // change key ---> status
    productMidCode: item?.product_mid_code, // nhi pata
    productMaterial: item?.product_material, // nhi pata
    productSupplierId: item?.supplier?.id,
    productCountryId: isSearchData
      ? item?.country?.id
      : item?.origin_country?.id, // change key ---> country?.id
    productIsDeactivated: item?.is_deactivated,
    productAvailabilityState: isSearchData
      ? item?.avaliabilty_status
      : item?.product_avaliabilty_status, // change key ---> avaliabilty_status
    productProductCode: item?.product_product_code, // nhi pata
    productWareHouseId: item?.product_ware_house_id, // nhi pata
    productCategoryId: isSearchData
      ? item?.category?.id
      : item?.product_category?.id, // key change --> category?.id
    productCategoryName: isSearchData
      ? item?.category?.name
      : item?.product_category?.name, // key change ---> category?.name
    productSubCategoryId: isSearchData
      ? item?.sub_sub_category?.id
      : item?.product_sub_sub_category?.id, // key change ----> sub_sub_category?.id
    productSubCategoryName: isSearchData
      ? item?.sub_sub_category?.name
      : item?.product_sub_sub_category?.name, // key change ----> sub_sub_category?.name
    countryId: isSearchData ? item?.country?.id : item?.origin_country?.id, // change key ---> country?.id
    countryDisplayName: isSearchData
      ? item?.country?.name
      : item?.origin_country?.name, // change key ---> country?.name
    subSubCategoryId: isSearchData
      ? item?.sub_sub_category?.id
      : item?.product_sub_sub_category?.id, // key change ----> sub_sub_category?.id
    subSubCategoryName: isSearchData
      ? item?.sub_sub_category?.name
      : item?.product_sub_sub_category?.name, // key change ----> sub_sub_category?.name
    iamgeUrl: getImageUrl(),
    countryFlag: isSearchData
      ? item?.country?.flag
      : item?.origin_country?.flag, // key change ----> country?.flag
    orderingOptionUnitName:
      item?.variants &&
      item?.variants &&
      item?.variants[0]?.metadata?.ordering_option_label,
    productBrandName: item?.brand?.name,
    businessName: item?.supplier?.business_name,
  };
};

const manageCatalogueListSlice = createSlice({
  name: "manageCatalogueList",
  initialState: [],
  reducers: {
    addCatalogueListActive: (state, action) => {
      // const transformedPayload = action.payload.map(transformKeys);
      const productsData = action.payload.data != undefined ? action.payload.data : action.payload
      const transformedPayload = productsData?.map((item: any) =>
        transformKeys(item, action?.payload?.isSearch)
      );
      return transformedPayload;
    },
    updateCatalogueProductStatus: (state: any, action) => {
      const updatePayload = state.map((item: IProduct) =>
        item?.productId === action.payload?.productId
          ? { ...item, ...action.payload }
          : item
      );
      return updatePayload;
    },
    removeProduct: (state: any, action) => {
      const latestProducts = state?.filter(
        (item: any) => item?.productId != action?.payload?.productId
      );
      return latestProducts;
    },
  },
});

export const {
  addCatalogueListActive,
  updateCatalogueProductStatus,
  removeProduct,
} = manageCatalogueListSlice.actions;

export default manageCatalogueListSlice.reducer;
