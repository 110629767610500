import { yupResolver } from "@hookform/resolvers/yup";
import loadash, { cloneDeep } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import infoIcon from "../../../../assets/images/icon-error.svg";
import cancelAction from "../../../../assets/images/chevron-left-white.svg";
import whiteEye from "../../../../assets/images/whiteEye.svg";
// import { v4 as uuidv4 } from "uuid";
import whiteTrash from "../../../../assets/images/whiteTrash.svg";
import Button from "../../../../common/components/button/Button";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { constants } from "../../../../common/constants/constants";
import { routeConfig } from "../../../../common/constants/routeConfig";
import {
  APIMethods,
  urlConstants,
  userEndpoints,
} from "../../../../common/constants/urlConstants";
import {
  IBrand,
  ICategory,
  ICountry,
  ISubCategory,
  ISubSubCategory,
} from "../../../../models/IAddIndividualProductForm";
import {
  resetWholeForm,
  setFirstForm,
} from "../../../../reducerSlices/addIndividualProductFormSlice";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { getSingleProductDetails } from "../../../../reducerSlices/productDetailsSlice";
import { useAxios } from "../../../../services/useAxios";
import useFetchCountries from "../../../../services/useCountry";
import styles from "./AddIndividual.module.scss";
import useDidUpdateEffect from "../../../../hooks/useDidUpdateEffect";
import axios from "axios";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import cancel from "../../../../assets/images/cancelToast.svg";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import isEmpty from "lodash/isEmpty";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { LightTooltip } from "./AddIndividual";
import { isImgUrlValid } from "../../../../utils/functions";
import emptyImage from "../../../../assets/images/emptybox.svg";

const AddIndividualPartOne = ({
  setSecondFormShow,
  singleProductDetails,
  isAdmin,
  navFromProductApproval,
  isUpdationMode,
  resetTotal,
  setIsApiDataSet,
}: {
  secondFormShow: boolean;
  setSecondFormShow: Dispatch<SetStateAction<boolean>>;
  singleProductDetails: any;
  isAdmin: boolean;
  navFromProductApproval: boolean;
  isUpdationMode: boolean;
  resetTotal: any;
  setIsApiDataSet: any;
}) => {
  const { countriesList } = useFetchCountries();
  const location = useLocation();
  const { addIndividualProductForm } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const isLinkProduct = location.state?.linkProduct;
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [totalFiles, setTotalFiles] = useState<any[]>(
    addIndividualProductForm?.firstForm?.productImagesUrl || []
  );
  const [defaultproductImages, setDefaultproductImages] = useState<any[]>(
    addIndividualProductForm?.firstForm?.defaultproductImagesUrl || []
  );

  const [brandName] = useState<string>("");
  const [, setBrandApiData] = useState<IBrand[]>([]);

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<ISubCategory | null>({});
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    ICategory[]
  >([]);
  const [, setSelectedProductSection] = useState<ISubSubCategory[]>([]);

  const [selectedSection, setSelectedSection] =
    useState<ISubSubCategory | null>({});
  const [selectedCountry, setSelectedCountry] = useState<ICountry>(
    addIndividualProductForm?.firstForm?.productCountryOrigin || {}
  );
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [suggestedProducts, setSuggestedProducts] = useState<any>([]);
  const [searchProductText, setSearchProductText] = useState<string>("");
  const userData = localStorage.getItem("loggedInUser");
  const loggedInUser = JSON.parse(userData || "");
  const isMasterProduct = location.state?.masterProduct;
  const isOfflineProduct = location.state?.offlineProduct;
  const [selectedSuggestedProduct, setSelectedSuggestedProduct] =
    useState<boolean>(false);
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
  const [previewFile, setPreviewFile] = useState<{
    name: string;
    url: string;
    type?: string;
  } | null>(null);
  const removeUploadedFile = (index: number) => {
    const updatedUploadedFiles = [...totalFiles];
    const updatedProductImagesUrls = [
      ...addIndividualProductForm?.firstForm?.productImagesUrl,
    ];
    updatedProductImagesUrls.splice(index, 1);
    updatedUploadedFiles.splice(index, 1);
    setTotalFiles(updatedUploadedFiles);
    dispatch(
      setFirstForm({
        productImages: updatedUploadedFiles,
        productImagesUrl: updatedProductImagesUrls ?? [],
      })
    );
    setValue("productImages", [...updatedUploadedFiles]);
  };
  const removeDefaultProductImage = () => {
    setDefaultproductImages([]);
    dispatch(
      setFirstForm({ defaultproductImages: [], defaultproductImagesUrl: [] })
    );
    setValue("defaultproductImages", []);
  };

  useEffect(() => {
    if (
      selectedCategory ||
      (singleProductDetails?.categories && singleProductDetails?.categories[0])
    ) {
      const selectedCategoryObj = categories?.find(
        (cat: ICategory) =>
          cat.value ===
          (selectedCategory?.value ||
            (singleProductDetails?.categories &&
              singleProductDetails?.categories[0]?.name))
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.subcategories &&
        selectedCategoryObj?.subcategories?.length > 0
      ) {
        const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
          (category: ICategory) => ({
            ...category,
            value: category?.name,
            productSection: category?.sub_sub_categories,
          })
        );
        setSelectedSubcategories(updatedCategoriesData);
      } else {
        setSelectedSubcategories([]);
      }
    } else {
      setSelectedSubcategories([]);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (selectedSubcategory || singleProductDetails?.subCategory) {
      const selectedSubcategoryObj = selectedSubcategories?.find(
        (subcat: ICategory) =>
          subcat.value === singleProductDetails?.subCategory?.name
      );
      if (selectedSubcategoryObj) {
        if (selectedSubcategoryObj?.productSection) {
          const updatedCategoriesData =
            selectedSubcategoryObj?.productSection?.map(
              (category: ISubCategory) => ({
                ...category,
                value: category?.name,
                productSection: selectedSubcategoryObj?.sub_sub_categories,
              })
            ) as ISubSubCategory[];
          setSelectedProductSection(updatedCategoriesData);
          dispatch(setFirstForm({ productSectionList: updatedCategoriesData }));
        } else {
          setSelectedProductSection([]);
        }
      } else {
        setSelectedProductSection([]);
      }
    }
  }, [selectedSubcategories]);

  const handleCategoryClick = (categoryValue: ICategory) => {
    setSelectedCategory(categoryValue);
    !isEmpty(selectedSubcategory) && setSelectedSubcategory(null);
    !isEmpty(selectedSection) && setSelectedSection(null);
    !isEmpty(selectedSubcategory) &&
      !isEmpty(selectedSection) &&
      dispatch(setFirstForm({ productSubCategory: {}, productSection: {} }));
  };

  const handleSubcategoryClick = (subcategoryValue: ISubCategory) => {
    if (!subcategoryValue) return;
    setSelectedSubcategory(subcategoryValue);
    const selectedSubcategoryObj = selectedSubcategories?.find(
      (subcat: ICategory) => subcat.value === subcategoryValue?.value
    );
    if (selectedSubcategoryObj) {
      if (selectedSubcategoryObj?.productSection) {
        const updatedCategoriesData =
          selectedSubcategoryObj?.productSection?.map(
            (category: ISubCategory) => ({
              ...category,
              value: category?.name,
              productSection: selectedSubcategoryObj?.sub_sub_categories,
            })
          ) as ISubSubCategory[];
        !isEmpty(selectedSection) && setSelectedSection(null);
        dispatch(
          setFirstForm({
            productSectionList: updatedCategoriesData,
            productSection: null,
          })
        );
      } else {
        setSelectedSection(null);
      }
    } else {
      setSelectedSection(null);
    }
  };
  const schema = yup.object().shape({
    productName: yup
      .string()
      .test(
        "Is product selected while link product",
        "Select an existing product to clone",
        (val) => {
          if (isLinkProduct && !singleProductDetails?.cloneProduct) {
            return false;
          } else {
            return true;
          }
        }
      )
      .required("Enter product name"),
    productBrand: yup.string().optional().nullable(),
    productCategory: yup.object().required("Enter product category"),
    productSubCategory: yup.object().required("Enter product subcategory"),
    productSection: yup.object().required("Enter product sub sub category"),
    productCountryOrigin: yup.object().required("Enter country origin"),
    supplierProductCode: yup.string().optional().nullable(),
    productDescription: yup.string().required("Enter product description"),
    productTags: yup.string().optional(),
    hsCode: yup.string().optional().nullable(),
    productImages: yup.mixed().optional(),
    defaultproductImages:
      (isUpdationMode && !isAdmin) ||
      (defaultproductImages && defaultproductImages?.length > 0)
        ? yup.mixed().optional()
        : yup
            .array()
            .min(1, "Upload default product image")
            .max(1, "Upload default product image")
            .required("Upload default product image"),
  });

  const resetForm = () => {
    resetTotal();
    setDefaultproductImages([]);
    setValue("productName", "");
    setValue("productBrand", "");
    setTotalFiles([]);
    setValue("productDescription", "");
    setValue("productTags", "");
    setValue("hsCode", "");
    setValue("supplierProductCode", "");
    setSelectedCountry({
      display_name: "Enter country origin....",
      emirates: [],
      flag: null,
      id: 0,
      iso_2: "",
      iso_3: "",
      name: "Enter country origin....",
      dial_codes: 0,
      region_id: null,
      value: "Enter country origin....",
    });
    setSelectedCategory({});
    setSelectedSubcategory({});
    setSelectedSection({});
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // get the brand name or create the brand name api call starts

  const getBrand = useAxios({
    axiosParams: {
      url: "store/brands",
      data: {
        name: brandName,
      },
    },
    method: "POST",
  });

  const debouncedGetBrandName = loadash.debounce(async () => {
    if (brandName) {
      const response: any = await getBrand?.fetchData();
      setBrandApiData(response?.data && response?.data[0]);
      if (response?.data && response?.data[0]) {
        // setValue("productBrand", response?.data[0]?.name);
        dispatch(setFirstForm({ productBrandApiData: response?.data[0] }));
      }
    }
  }, 1000);

  const [isBrandNameFocused, setIsBrandNameFocused] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);

  // Add an event listener to track focus on the "Product brand" input field
  useEffect(() => {
    const handleBrandNameFocus = () => {
      setIsBrandNameFocused(true);
    };

    const handleBrandNameBlur = () => {
      setIsBrandNameFocused(false);
    };

    const brandNameInput = document.querySelector('input[name="productBrand"]');

    if (brandNameInput) {
      brandNameInput.addEventListener("focus", handleBrandNameFocus);
      brandNameInput.addEventListener("blur", handleBrandNameBlur);
    }

    return () => {
      if (brandNameInput) {
        brandNameInput.removeEventListener("focus", handleBrandNameFocus);
        brandNameInput.removeEventListener("blur", handleBrandNameBlur);
      }
    };
  }, []);
  useEffect(() => {
    if (brandName) {
      debouncedGetBrandName();
    }
  }, [isBrandNameFocused, brandName]);

  // get the brand name or create the brand name api call ends
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      trigger(name);
      if (name !== "watermelonProductCode") {
        if (
          (name === "productCategory" ||
            name === "productSubCategory" ||
            name === "productSection") &&
          text?.id
        ) {
          dispatch(setFirstForm({ [name]: text }));
        }
        if (
          name !== "productCategory" &&
          name !== "productSubCategory" &&
          name !== "productSection"
        ) {
          dispatch(setFirstForm({ [name]: text }));
        }
        if (
          name === "productName" &&
          (!loggedInUser?.typeOfUser.includes("admin") || isLinkProduct)
        ) {
          if (text.length >= 2) {
            dispatch(setFirstForm({ [name]: text }));
            setSearchProductText(text);
          } else {
            setSearchProductText("");
          }
        }
      }
      if (name === "productBrand") {
        // setBrandName(text);
      }
    },
    [dispatch, setValue]
  );

  useEffect(() => {
    if (addIndividualProductForm?.firstForm) {
      reset(addIndividualProductForm?.firstForm, { keepErrors: true });
    }
  }, [addIndividualProductForm, reset]);

  const submit = (data: any) => {
    if (data) {
      const updatedFullData = {
        ...addIndividualProductForm.firstForm,
        ...data,
      };
      dispatch(setFirstForm(updatedFullData));
      setSecondFormShow(true);
    }
  };

  const navigate = useNavigate();

  // get categories api call starts
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: "GET",
  });

  const getAllCategoriesList = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getCategories?.fetchData();
    dispatch(updateIsLoading(false));
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          subcategories: category.sub_categories,
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
    }
  }, [getCategories]);
  // get categories api call ends

  useEffect(() => {
    getAllCategoriesList();
  }, [singleProductDetails]);
  const [selectedCategoriesAttribute, setSeclectedCategoriesAttributes] =
    useState<any>([]);

  useEffect(() => {
    if (
      selectedCategory &&
      selectedCategory?.category_attributes &&
      selectedSubcategory &&
      selectedSubcategory?.sub_category_attributes &&
      selectedSection &&
      selectedSection?.sub_sub_category_attributes
    ) {
      const addValueKey = (attributes: any[]) =>
        attributes.map((attr) => ({ ...attr, value: attr.name }));
      setSeclectedCategoriesAttributes((prevSelectedCategories: any) => [
        ...prevSelectedCategories,
        ...addValueKey(selectedCategory.category_attributes as any),
        ...addValueKey(selectedSubcategory.sub_category_attributes as any),
        ...addValueKey(selectedSection.sub_sub_category_attributes as any),
      ]);
    }
  }, [selectedCategory, selectedSubcategory, selectedSection]);

  useEffect(() => {
    if (selectedCategoriesAttribute.length > 0) {
      dispatch(
        setFirstForm({
          categoriesAttributes: selectedCategoriesAttribute,
        })
      );
    }
  }, [selectedCategoriesAttribute]);

  // get images url api call starts
  let formdata = new FormData();
  let list = totalFiles?.filter((ele: any) => !ele?.url);
  if (list && list.length) {
    list.slice(0, 5)?.forEach((ele: any) => {
      if (ele && !ele.url) {
        formdata.append(`multiplefiles`, ele);
      }
    });
  }

  const getImagesUrl = useAxios({
    axiosParams: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: "store/miniobucket/uploadmultiplefile",
      data: formdata,
    },
    method: "POST",
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const getImagesUrlList = useCallback(async () => {
    let list = totalFiles?.filter((ele: any) => !ele?.url);
    if (list && list?.length > 0) {
      dispatch(updateIsLoading(true));
      const response: any = await getImagesUrl?.fetchData();
      if (response && response?.status === "SUCCESS") {
        const updatedImagesUrl = response?.data?.map((image: string) => ({
          id: null,
          url: image,
        }));
        if (updatedImagesUrl && updatedImagesUrl?.length > 0) {
          let files = cloneDeep(totalFiles.filter((ele: any) => ele?.url));
          if (files) {
            dispatch(
              setFirstForm({
                productImagesUrl: [...files, ...updatedImagesUrl],
              })
            );
            setValue("productImages", [...files, ...updatedImagesUrl]);
          }
        }
        dispatch(updateIsLoading(false));
      }
    }
  }, [dispatch, getImagesUrl]);

  useEffect(() => {
    getImagesUrlList();
  }, [totalFiles]);

  const getDefaultProductImageUrl = async () => {
    let list = defaultproductImages?.filter((ele: any) => !ele?.url);
    let formdata = new FormData();
    // Define headers
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (list && list?.length > 0) {
      dispatch(updateIsLoading(true));
      list.slice(0, 1)?.forEach((ele: any) => {
        if (ele && !ele.url) {
          formdata.append(`multiplefiles`, ele);
        }
      });
      try {
        const defaultImgResponse: any = await axios.post(
          `${urlConstants.BASE_URL}store/miniobucket/uploadmultiplefile`,
          formdata,
          { headers }
        );
        const response = defaultImgResponse?.data;
        if (
          response &&
          (response?.status === "SUCCESS" || response?.status === 200)
        ) {
          const updatedImagesUrl = response?.data?.map((image: string) => ({
            id: null,
            url: image,
          }));
          if (updatedImagesUrl && updatedImagesUrl?.length > 0) {
            let files = cloneDeep(
              defaultproductImages.filter((ele: any) => ele?.url)
            );
            if (files) {
              dispatch(
                setFirstForm({
                  defaultproductImagesUrl: [...files, ...updatedImagesUrl],
                })
              );
              setValue("defaultproductImages", [...files, ...updatedImagesUrl]);
            }
          }
          dispatch(updateIsLoading(false));
        }
      } catch (error) {
        console.log("API Failed", error);
      }
    }
  };
  useEffect(() => {
    getDefaultProductImageUrl();
  }, [defaultproductImages]);
  useDidUpdateEffect(() => {
    // setValue("productName", singleProductDetails?.title);
    // setValue("productBrand", singleProductDetails?.brand?.name);
    // setValue("hsCode", singleProductDetails?.hsCode);
    // setValue("productDescription", singleProductDetails?.description);
    // setValue("supplierProductCode", singleProductDetails?.productCode);
    // setValue(
    //   "productTags",
    //   singleProductDetails?.tags?.map((tag: any) => tag?.value).join(", ")
    // );

    if (singleProductDetails && !addIndividualProductForm?.firstForm?.next) {
      onChangeField("productName")(singleProductDetails?.title);
      onChangeField("productBrand")(singleProductDetails?.brand?.name);
      onChangeField("supplierProductCode")(singleProductDetails?.productCode);
      onChangeField("hsCode")(singleProductDetails?.hsCode);
      onChangeField("productTags")(
        singleProductDetails?.tags?.map((tag: any) => tag?.value).join(", ")
      );
      const currSelectedCategory = categories?.find(
        (el: any) => el.id === singleProductDetails?.category?.id
      );
      if (currSelectedCategory) {
        setSelectedCategory(currSelectedCategory);
        const currSelectedSubCategory =
          currSelectedCategory.subcategories?.find(
            (el: any) => el.id === singleProductDetails.subCategory?.id
          );
        currSelectedSubCategory &&
          setSelectedSubcategory(currSelectedSubCategory);

        if (currSelectedSubCategory) {
          const currSelectedSubSubCategory =
            currSelectedSubCategory.sub_sub_categories?.find(
              (el: any) => el.id === singleProductDetails.subSubCategory?.id
            );
          currSelectedSubSubCategory &&
            setSelectedSection(currSelectedSubSubCategory);
        }
      }
      setSelectedCountry(singleProductDetails?.country);
      onChangeField("productDescription")(singleProductDetails?.description);
      const defaultImg = singleProductDetails?.images?.filter(
        (item: any) => item.metadata?.defaultImage
      );
      const productImgs = singleProductDetails?.images?.filter(
        (item: any) => !item.metadata?.defaultImage
      );
      !totalFiles?.length && setTotalFiles(productImgs ?? []);
      !defaultproductImages?.length &&
        setDefaultproductImages(defaultImg?.slice(0, 1) ?? []);
      dispatch(
        setFirstForm({
          defaultproductImagesUrl: defaultImg?.slice(0, 1),
          productImagesUrl: productImgs,
        })
      );
    }
  }, [singleProductDetails, categories]);
  const { productId } = useParams();
  useEffect(() => {
    if (!productId || !singleProductDetails) {
      reset();
    }
  }, [singleProductDetails, productId]);
  const extractFileNameFromUrl = (url: string) => {
    const filenameSlash = url.split("/");
    const filenameHyphen = filenameSlash[filenameSlash.length - 1].split("-");
    if (filenameHyphen[filenameHyphen?.length - 1]) {
      return filenameHyphen[filenameHyphen?.length - 1];
    }
    return url;
  };
  const getSearchedProducts = useAxios({
    axiosParams: {
      url: `${
        isLinkProduct ? "admin" : "store"
      }/master-product?q=${searchProductText}&page=1&status=published&is_drop_down=true`,
    },
    method: APIMethods.get,
  });
  const masterProductDetails = useAxios({
    axiosParams: {
      url: `${userEndpoints.masterProductDetails}`,
    },
    method: APIMethods.get,
  });

  //It is used to get master product details while cloning a product by supplier
  const getSelectedProductDetails = useCallback(async (id: string) => {
    dispatch(updateIsLoading(true));
    const response: any = await masterProductDetails.fetchData({
      axiosParams: {
        url: `${userEndpoints.masterProductDetails}${id}`,
      },
      method: APIMethods.get,
    });
    dispatch(updateIsLoading(false));

    if (response && response?.data) {
      dispatch(
        getSingleProductDetails({
          ...(response?.data || {}),
          cloneProduct: true,
        })
      );
    }
  }, []);
  const debouncedgetSuggestedProductsList = loadash.debounce(async () => {
    if (searchProductText !== "" && !isUpdationMode) {
      const response: any = await getSearchedProducts?.fetchData();
      setSuggestedProducts(response.data?.products);
    } else {
      setSuggestedProducts([]);
    }
  }, 1000);
  useEffect(() => {
    if (!selectedSuggestedProduct && searchProductText) {
      setSuggestedProducts([]);
      debouncedgetSuggestedProductsList();
    } else {
      setSuggestedProducts([]);
    }
  }, [searchProductText]);

  const handleSuggestedProduct = (id: string) => {
    if (!selectedSuggestedProduct) {
      //  setProductId(productId);
      getSelectedProductDetails(id);
      setSelectedSuggestedProduct(true); // set to true , if limits to 1 otherwise false
      dispatch(
        setFirstForm({
          saveSelectedSuggestedProduct: true,
        })
      );
      setInputFocused(false);
    }
  };

  //this function is used to reset the form data
  const handleResetForm = () => {
    setSelectedSuggestedProduct(false);
    dispatch(getSingleProductDetails(null));
    setIsApiDataSet(false);
    dispatch(
      setFirstForm({
        saveSelectedSuggestedProduct: false,
      })
    );
    resetForm();
  };

  function getCancelNavigationLink() {
    let link: any = "";
    if (isAdmin) {
      if (navFromProductApproval) {
        if (isOfflineProduct || isMasterProduct) {
          if (singleProductDetails?.status === "draft") {
            link = routeConfig.productDetailsPageAdmin?.replace(
              ":productId",
              productId as string
            );
          } else {
            link = routeConfig.adminProductApproval;
          }
        } else {
          link = routeConfig.productDetailsPageAdmin?.replace(
            ":productId",
            productId as string
          );
        }
      } else if (productId) {
        link = routeConfig.productDetailsPageAdminFromCatalogue?.replace(
          ":productId",
          productId as string
        );
      } else if (isLinkProduct) {
        link = -1;
      } else {
        link = routeConfig.adminProductAddCards;
      }
    } else {
      link = productId
        ? `/supplier/catalogue/product-details/${productId}`
        : routeConfig.manageSupplierCatalogue;
    }
    return link;
  }
  const handleModalClose = () => {
    setOpenImagePreview(false);
    setPreviewFile(null);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className={styles.addIndividualForm}
      >
        <div
          className={styles.generalInfoBlock}
          onScroll={() => setShowTooltip(false)}
        >
          <div className={styles.textArea}>
            <ClickAwayListener
              onClickAway={() => {
                setInputFocused(false);
              }}
            >
              <div className={`${styles.input} ${styles.productNameSugg}`}>
                <div className={styles.label}>Product name</div>
                <InputField
                  value={watch("productName")}
                  placeholder="Enter product name..."
                  id="productName"
                  onChange={onChangeField("productName")}
                  register={register("productName")}
                  maxWidth="100%"
                  errorMessage={errors.productName?.message}
                  editForm={
                    isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                  }
                  autoComplete={false}
                  onFocus={() => {
                    (!isAdmin || isLinkProduct) && setInputFocused(true);
                  }}
                  tabIndex={1}
                  icon={
                    addIndividualProductForm?.firstForm
                      ?.saveSelectedSuggestedProduct
                      ? cancel
                      : null
                  }
                  endAdornment
                  handleEndAdorment={handleResetForm}
                  selectedSuggestedProduct={
                    addIndividualProductForm?.firstForm
                      ?.saveSelectedSuggestedProduct
                  }
                />
                {inputFocused &&
                  (!isAdmin || isLinkProduct) &&
                  !singleProductDetails?.id &&
                  suggestedProducts?.length > 0 && (
                    <div className={styles.productNameSuggestionsContaine}>
                      {suggestedProducts?.map(
                        (each_product: any, index: number) => (
                          <div
                            key={each_product.id}
                            onClick={() => {
                              handleSuggestedProduct(each_product.id);
                            }}
                            className={`${styles.productSuggestionItem} ${
                              index !== suggestedProducts.length - 1
                                ? styles.seperator
                                : ""
                            }`}
                          >
                            {each_product.title}
                          </div>
                        )
                      )}
                    </div>
                  )}
              </div>
            </ClickAwayListener>
            <div className={styles.input}>
              <div className={styles.label}>Product brand (Optional)</div>
              <InputField
                value={watch("productBrand")}
                placeholder="Enter product brand name..."
                id="productBrand"
                onChange={onChangeField("productBrand")}
                register={register("productBrand")}
                errorMessage={errors.productBrand?.message}
                maxWidth="100%"
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                tabIndex={2}
              />
            </div>
          </div>
          <div className={styles.textArea}>
            <div className={styles.input}>
              <div className={styles.label}>Product category</div>
              <Dropdown
                minWidth="100%"
                label={""}
                selectedValue={selectedCategory}
                options={categories}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productCategory?.name ??
                  selectedCategory?.name ??
                  "Select product category..."
                }
                id="productCategory"
                onChange={(selectedOption: ICategory) => {
                  onChangeField("productCategory")(selectedOption);
                  handleCategoryClick(selectedOption);
                }}
                errorMessage={errors.productCategory?.message}
                editForm={
                  addIndividualProductForm?.firstForm
                    ?.saveSelectedSuggestedProduct
                    ? true
                    : isUpdationMode
                    ? isAdmin
                      ? false
                      : true
                    : isUpdationMode
                }
                tabIndex={3}
              />
            </div>
            <div className={styles.input}>
              <div className={styles.label}>Product subcategory</div>
              <Dropdown
                selectedValue={selectedSubcategory}
                minWidth="100%"
                label={""}
                options={selectedSubcategories}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productSubCategory
                    ?.name ??
                  selectedSubcategory?.name ??
                  "Select product subcategory..."
                }
                id="productSubCategory"
                onChange={(selectedOption: ISubCategory) => {
                  onChangeField("productSubCategory")(selectedOption);
                  handleSubcategoryClick(selectedOption);
                }}
                errorMessage={errors.productSubCategory?.message}
                editForm={
                  addIndividualProductForm?.firstForm
                    ?.saveSelectedSuggestedProduct
                    ? true
                    : isUpdationMode
                    ? isAdmin
                      ? false
                      : true
                    : isUpdationMode
                }
                tabIndex={4}
              />
            </div>
          </div>
          <div className={styles.textAreaMargin}>
            <div className={styles.input}>
              <div className={styles.label}> Product sub sub category</div>
              <Dropdown
                selectedValue={selectedSection}
                minWidth="100%"
                label={""}
                options={
                  addIndividualProductForm?.firstForm?.productSectionList
                }
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productSection?.name ??
                  selectedSection?.name ??
                  "Select product sub sub category..."
                }
                id="productSection"
                onChange={(selectedOption: ISubSubCategory) => {
                  onChangeField("productSection")(selectedOption);
                  setSelectedSection(selectedOption);
                }}
                errorMessage={errors.productSection?.message}
                editForm={
                  addIndividualProductForm?.firstForm
                    ?.saveSelectedSuggestedProduct
                    ? true
                    : isUpdationMode
                    ? isAdmin
                      ? false
                      : true
                    : isUpdationMode
                }
                tabIndex={5}
              />
            </div>
            <div className={styles.input}>
              <div className={styles.label}>Product country origin</div>
              <Dropdown
                selectedValue={selectedCountry}
                // selectedValue={
                //   singleProductDetails
                //     ? singleProductDetails?.countries
                //     : addIndividualProductForm?.firstForm?.productCountryOrigin ||
                //       selectedCountry
                // }
                minWidth="100%"
                label={""}
                options={countriesList}
                isCountryList={true}
                isCountryOrigin={true}
                defaultLabel={
                  addIndividualProductForm?.firstForm?.productCountryOrigin
                    ?.name ??
                  selectedCountry?.name ??
                  "Enter country origin...."
                }
                id="productCountryOrigin"
                onChange={(selectedOption: ICountry) => {
                  onChangeField("productCountryOrigin")(selectedOption);
                  setSelectedCountry(selectedOption);
                }}
                register={register("productCountryOrigin")}
                errorMessage={errors.productCountryOrigin?.message}
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                tabIndex={5}
              />
            </div>
          </div>
          <div className={styles.textArea}>
            <div className={styles.input}>
              <div className={`${styles.label} ${styles.tootltipLabel}`}>
                <span>Supplier Product Code (Optional)</span>
              </div>
              <InputField
                value={watch("supplierProductCode")}
                // value={addIndividualProductForm?.firstForm?.supplierProductCode}
                maxWidth="100%"
                placeholder="Enter supplier product code..."
                register={register("supplierProductCode")}
                id="supplierProductCode"
                onChange={onChangeField("supplierProductCode")}
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                errorMessage={errors.supplierProductCode?.message}
                tabIndex={5}
              />
            </div>
            <div className={styles.input}>
              <div className={`${styles.label} ${styles.tootltipLabel}`}>
                <span>Product HS code (Optional)</span>
                <span
                  className={styles.tooltipContainer}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <LightTooltip
                    title="HS Code is a standardised international system for classifying traded products. Example: 6204.62.0010 (Men's cotton shirts, long-sleeved)."
                    placement="top"
                    arrow
                    showTooltip={showTooltip}
                  >
                    <img src={infoIcon} alt="Error" />
                  </LightTooltip>
                </span>
              </div>
              <InputField
                value={addIndividualProductForm?.firstForm?.hsCode}
                maxWidth="100%"
                placeholder="Enter product HS code..."
                id="HS"
                register={register("hsCode")}
                onChange={onChangeField("hsCode")}
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                tabIndex={6}
              />
            </div>
          </div>
          {singleProductDetails &&
            singleProductDetails?.watermelonProductCode && (
              <div className={styles.textArea}>
                <div className={styles.input}>
                  <div className={styles.label}>Watermelon Product Code</div>
                  <div className={styles.generateBox}>
                    <InputField
                      value={
                        singleProductDetails &&
                        singleProductDetails?.watermelonProductCode
                      }
                      disabled={true}
                      placeholder="Enter watermelon product code..."
                      id="watermelonProductCode"
                      maxWidth="100%"
                      editForm={true}
                    />
                  </div>
                </div>
                <div className={styles.input}></div>
              </div>
            )}
          <div className={styles.textAreaLast}>
            <div className={styles.input1}>
              <div className={styles.label}>Product description</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productDescription}
                maxWidth="100%"
                height="100%"
                placeholder="Enter here..."
                id="productDescription"
                onChange={onChangeField("productDescription")}
                register={register("productDescription")}
                errorMessage={errors.productDescription?.message}
                isTextarea
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                tabIndex={6}
              />
            </div>
            <div className={styles.input1}>
              <div className={styles.label}>Product tags (Optional)</div>
              <InputField
                value={addIndividualProductForm?.firstForm?.productTags}
                maxWidth="100%"
                height="100%"
                type="text"
                placeholder="Enter Product Tags separated by “,” comma..."
                id="productTags"
                onChange={onChangeField("productTags")}
                register={register("productTags")}
                isTextarea
                // errorMessage={errors.productTags?.message}
                editForm={
                  isUpdationMode ? (isAdmin ? false : false) : isUpdationMode
                }
                tabIndex={6}
              />
            </div>
          </div>
          <div className={styles.multipleUpload}>
            <div className={styles.uploadLabel}>
              Upload product Default Image
            </div>
            <label
              htmlFor="defaultproductImages"
              style={{
                pointerEvents:
                  (isUpdationMode
                    ? isAdmin
                      ? false
                      : false
                    : isUpdationMode) || defaultproductImages?.length >= 1
                    ? "none"
                    : "auto",
                filter:
                  (isUpdationMode
                    ? isAdmin
                      ? false
                      : false
                    : isUpdationMode) || defaultproductImages?.length >= 1
                    ? "grayscale(100%)"
                    : "none",
              }}
            >
              <div className={styles.upperText}>
                Drop file here or <span>Upload</span>
              </div>
              <div className={styles.uploadedText}>
                (Max uploads: 01, Formats: JPG, PNG, JPEG, SVG)
              </div>
            </label>
            <input
              value={[]}
              hidden
              type="file"
              id="defaultproductImages"
              accept=".jpg,.jpeg,.png,.svg"
              multiple
              {...register("defaultproductImages")}
              onChange={(e) => {
                const files = e.target.files;
                if (files && files?.length > 0) {
                  const newUploadedImages = Object.keys(files)
                    .map((key: any) => files[key])
                    .slice(0, 1);
                  if (files[0].size <= 2000000) {
                    if (e?.target?.accept) {
                      if (
                        e?.target?.accept?.includes(
                          newUploadedImages[0]?.type?.split("/")[1]
                        )
                      ) {
                        setDefaultproductImages([...newUploadedImages]);
                        setValue("defaultproductImages", [
                          ...newUploadedImages,
                        ]);
                        dispatch(
                          setFirstForm({
                            defaultproductImages: [...newUploadedImages],
                          })
                        );
                      } else {
                        setToastData({
                          message:
                            "Please upload the appropriate type of images as mentioned",
                          status: "failure",
                        });
                        setTimeout(() => {
                          setToastData(constants.defaultToastData);
                        }, 3000);
                      }
                    } else {
                      setDefaultproductImages([...newUploadedImages]);
                      setValue("defaultproductImages", [...newUploadedImages]);
                      dispatch(
                        setFirstForm({
                          defaultproductImages: [...newUploadedImages],
                        })
                      );
                    }
                  } else {
                    setToastData({
                      message:
                        "The maximum allowed image size is 2MB. Please upload an image of less than 2MB.",
                      status: "failure",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                }
              }}
              className={styles.fileInput}
            />

            {nextButtonClicked && defaultproductImages?.length === 0 && (
              <p className={styles.errorMessage}>
                Upload product default image
              </p>
            )}

            <div
              className={`${styles.uploadedFilesContainer} ${
                (isUpdationMode ? (isAdmin ? false : false) : isUpdationMode)
                  ? styles.disabledMode
                  : null
              }`}
            >
              {defaultproductImages &&
                defaultproductImages?.map((file: any, index) => (
                  <div key={index} className={styles.uploadedFile}>
                    <img
                      src={
                        file?.url
                          ? isImgUrlValid(file?.url)
                            ? file?.url
                            : emptyImage
                          : URL.createObjectURL(file)
                      }
                      alt={`Uploaded ${index}`}
                    />
                    <div className={styles.uploadOverlay}>
                      <div className={styles.overlayButtons}>
                        <div
                          className={styles.eyeButton}
                          onClick={() => {
                            setPreviewFile({
                              name:
                                file?.name ?? extractFileNameFromUrl(file?.url),
                              url: file?.url
                                ? isImgUrlValid(file?.url)
                                  ? file?.url
                                  : emptyImage
                                : URL.createObjectURL(file),
                            });
                            setOpenImagePreview(true);
                          }}
                        >
                          <img src={whiteEye} alt="whiteEyeIcon"></img>
                        </div>
                        <div
                          className={styles.deleteButton}
                          onClick={removeDefaultProductImage}
                        >
                          <img src={whiteTrash} alt="whitedeleteIcon"></img>
                        </div>
                      </div>
                    </div>
                    <div className={styles.imageName} title={file?.url}>
                      {file?.name
                        ? file?.name?.length > 20
                          ? `${file?.name?.slice(0, 20)}...`
                          : file?.name
                        : extractFileNameFromUrl(file?.url)
                        ? extractFileNameFromUrl(file?.url)?.length > 20
                          ? `${extractFileNameFromUrl(file?.url).slice(
                              0,
                              20
                            )}...`
                          : extractFileNameFromUrl(file?.url)
                        : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={`${styles.multipleUpload}  ${
              location?.pathname.includes("/product-update") ||
              (totalFiles && totalFiles.length === 0)
                ? styles.mediaMargin
                : styles.imageBoxMargin
            }`}
          >
            <div className={styles.uploadLabel}>
              Upload product media (Optional)
            </div>
            <label
              htmlFor="productImages"
              style={{
                pointerEvents:
                  (isUpdationMode
                    ? isAdmin
                      ? false
                      : false
                    : isUpdationMode) || totalFiles?.length >= 4
                    ? "none"
                    : "auto",
                filter:
                  (isUpdationMode
                    ? isAdmin
                      ? false
                      : false
                    : isUpdationMode) || totalFiles?.length >= 4
                    ? "grayscale(100%)"
                    : "none",
              }}
            >
              <div className={styles.upperText}>
                Drop file here or <span>Upload</span>
              </div>
              <div className={styles.uploadedText}>
                (Max uploads: 04, Formats: JPG, PNG, JPEG, SVG)
              </div>
            </label>
            <input
              value={[]}
              hidden
              type="file"
              id="productImages"
              accept=".jpg,.jpeg,.png,.svg"
              multiple
              {...register("productImages")}
              onChange={(e) => {
                const files = e.target.files;
                if (files && files?.length > 0) {
                  const newUploadedImages = Object.keys(files)
                    .map((key: any) => files[key])
                    .slice(0, 4 - totalFiles?.length);
                  let isAnyUploadedFileHaveMoreSize: any = [];
                  for (let i = 0; i < files.length; i++) {
                    if (files[0]["size"] > 2000000) {
                      isAnyUploadedFileHaveMoreSize.push(files[0]);
                    }
                  }
                  if (!isAnyUploadedFileHaveMoreSize.length) {
                    if (e?.target?.accept) {
                      if (
                        e?.target?.accept?.includes(
                          newUploadedImages[0]?.type?.split("/")[1]
                        )
                      ) {
                        setTotalFiles((prevTotalFiles: any) => [
                          ...prevTotalFiles,
                          ...newUploadedImages,
                        ]);
                        setValue("productImages", [
                          ...totalFiles,
                          ...newUploadedImages,
                        ]);
                        dispatch(
                          setFirstForm({
                            productImages: [
                              ...totalFiles,
                              ...newUploadedImages,
                            ],
                          })
                        );
                      } else {
                        setToastData({
                          message:
                            "Please upload the appropriate type of images as mentioned",
                          status: "failure",
                        });
                        setTimeout(() => {
                          setToastData(constants.defaultToastData);
                        }, 3000);
                      }
                    } else {
                      setTotalFiles((prevTotalFiles: any) => [
                        ...prevTotalFiles,
                        ...newUploadedImages,
                      ]);

                      setValue("productImages", [
                        ...totalFiles,
                        ...newUploadedImages,
                      ]);
                      dispatch(
                        setFirstForm({
                          productImages: [...totalFiles, ...newUploadedImages],
                        })
                      );
                    }
                  } else {
                    setToastData({
                      message:
                        "The maximum allowed image size is 2MB. Please upload an image of less than 2MB.",
                      status: "failure",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                }
              }}
              className={styles.fileInput}
            />

            {/* {nextButtonClicked && totalFiles?.length === 0 && (
        <p className={styles.errorMessage}>Upload product images</p>
      )} */}

            <div
              className={`${styles.uploadedFilesContainer} ${
                (isUpdationMode ? (isAdmin ? false : false) : isUpdationMode)
                  ? styles.disabledMode
                  : null
              }`}
            >
              {totalFiles &&
                totalFiles.map((file: any, index) => (
                  <div key={index} className={styles.uploadedFile}>
                    <img
                      src={
                        file?.url
                          ? isImgUrlValid(file?.url)
                            ? file?.url
                            : emptyImage
                          : URL.createObjectURL(file)
                      }
                      alt={`Uploaded ${index}`}
                    />
                    <div className={styles.uploadOverlay}>
                      <div className={styles.overlayButtons}>
                        <div
                          className={styles.eyeButton}
                          onClick={() => {
                            setPreviewFile({
                              name:
                                file?.name ?? extractFileNameFromUrl(file?.url),
                              url: file?.url
                                ? isImgUrlValid(file?.url)
                                  ? file?.url
                                  : emptyImage
                                : URL.createObjectURL(file),
                            });
                            setOpenImagePreview(true);
                          }}
                        >
                          <img src={whiteEye} alt="whiteEyeIcon"></img>
                        </div>
                        <div
                          className={styles.deleteButton}
                          onClick={() => removeUploadedFile(index)}
                        >
                          <img src={whiteTrash} alt="whitedeleteIcon"></img>
                        </div>
                      </div>
                    </div>
                    <div className={styles.imageName} title={file?.url}>
                      {file?.name
                        ? file?.name?.length > 20
                          ? `${file?.name?.slice(0, 20)}...`
                          : file?.name
                        : extractFileNameFromUrl(file?.url)
                        ? extractFileNameFromUrl(file?.url)?.length > 20
                          ? `${extractFileNameFromUrl(file?.url).slice(
                              0,
                              20
                            )}...`
                          : extractFileNameFromUrl(file?.url)
                        : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label={"Cancel"}
            large={true}
            customClass={styles.formButtonCancel}
            handleClick={() => {
              dispatch(resetWholeForm());
              navigate(getCancelNavigationLink(), {
                state: {
                  masterProduct: isMasterProduct,
                  offlineProduct: isOfflineProduct,
                },
              });
            }}
          />
          <Button
            label={"Next"}
            large={true}
            customClass={styles.formButton}
            handleClick={() => {
              setNextButtonClicked(true);
              dispatch(
                setFirstForm({
                  next: true,
                })
              );
            }}
          />
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={50000}
          />
        ) : (
          <></>
        )}
      </form>
      <Modal
        className={styles.imageModal}
        open={openImagePreview}
        onClose={handleModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.imageBox}>
          <div className={styles.header} onClick={handleModalClose}>
            <img src={cancelAction} alt="" />
            <p className={styles.overflow} title={previewFile?.name}>
              {previewFile && previewFile?.name?.length > 20
                ? previewFile?.name.slice(0, 40) + `...`
                : previewFile?.name}
            </p>
          </div>
          {previewFile?.type === "application/pdf" ? (
            <p className={styles.noPreview}>No Preview Available</p>
          ) : (
            <img src={previewFile?.url} alt="" />
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddIndividualPartOne;
