import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import accept from "../../../../../assets/images/ordersListing/accept.svg";
import readyToShip from "../../../../../assets/images/ordersListing/readyToShip.svg";
import reject from "../../../../../assets/images/ordersListing/reject.svg";
import { IOrderDetailsCard } from "../../../../../models/IOrderDetailsCard";
import { constants } from "../../../../constants/constants";
import { urlConstants } from "../../../../constants/urlConstants";
import styles from "./OrderDetailsCardStyles.module.scss";
import { useDispatch } from "react-redux";
import { updateOrderList } from "../../../../../reducerSlices/manageOrdersList";
export const OrderDetailsCard = (props: IOrderDetailsCard) => {
  const dispatch = useDispatch();
  const {
    orderId,
    buyerName,
    // icon,
    orderItems,
    orderValue,
    paidStatus,
    placedOn,
    actualAmount,
    orderStatus,
    activeTab,
    type,
    orderPONumber,
    icon,
  } = props;
  const [showActions, setShowActions] = useState(false);
  const [actionComplete, setActionComplete] = useState(false);
  const onMouseEnter = () => {
    setShowActions(true);
  };
  console.log(actualAmount, "actualAmount");

  const onMouseLeave = () => {
    setShowActions(false);
  };

  async function userActionStatus(status: string) {
    try {
      const response: any = await axios.post(
        `${urlConstants.BASE_URL}store/master-order/${orderId}/track-order`,
        {
          status,
        }
      );
      if (
        response &&
        (response?.status === "SUCCESS" || response?.status === 200)
      ) {
        //  supplier orders
        if (status) {
          // setActionComplete(true);
          dispatch(updateOrderList({ orderId }));
        }
      }
    } catch (error) {
      setActionComplete(false);
    }
  }

  const handleAccept = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    await userActionStatus("confirmed");
  };
  const handleReject = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    await userActionStatus("canceled");
  };
  const handleReadyToShip = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    await userActionStatus("shipped");
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.card}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => navigate(`/supplier/manage-orders/${orderId}`)}
      >
        <div
          className={`${styles.details} ${
            actualAmount && styles.completedDetails
          }`}
        >
          <div className={styles.orderID}>
            <div className={styles.icon}>
              <img src={icon} alt="delivered"></img>
            </div>
            <div className={styles.orderDetails}>
              <div className={styles.id}>
                {/* <div className={styles.icon}>
                <img src={icon} alt="icon" />
              </div> */}
                <div className={styles.idNo}>{orderPONumber}</div>
                <div
                  className={`${
                    paidStatus === constants.ordersListing.PAID
                      ? styles.paid
                      : styles.unpaid
                  }`}
                >
                  {paidStatus}
                </div>
              </div>
              {type === constants.ordersListing.COMPLETED && (
                <div className={styles.deliveryStatus}>
                  {orderStatus != "Canceled" ? orderStatus : "Cancelled"}
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.placedOn} ${styles.deliveryStatus}`}>
            {placedOn}
          </div>
          <div className={`${styles.buyerName} ${styles.deliveryStatus}`}>
            {buyerName}
          </div>
          <div className={`${styles.orderValue} ${styles.deliveryStatus}`}>
            AED {orderValue}
          </div>
          {activeTab === 3 && (
            <div className={`${styles.actualAmount} ${styles.deliveryStatus}`}>
              {actualAmount ? `AED ${actualAmount}` : `-`}
            </div>
          )}
          <div
            className={`${
              actualAmount ? styles.orderItems : styles.orderItemsMargin
            } ${styles.deliveryStatus}`}
          >
            {orderItems}
          </div>
        </div>

        {!actionComplete &&
          showActions &&
          type === constants.ordersListing.YET_TO_CONFIRM && (
            <div className={styles.actions}>
              <div className={styles.action} onClick={handleAccept}>
                <img src={accept} alt="accept"></img>
                <div className={styles.actionTxt}>
                  {constants.ordersListing.ACCEPT}
                </div>
              </div>
              <div className={styles.action} onClick={handleReject}>
                <img src={reject} alt="reject"></img>
                <div className={styles.actionTxt}>
                  {constants.ordersListing.REJECT}
                </div>
              </div>
            </div>
          )}
        {!actionComplete &&
          showActions &&
          type === constants.ordersListing.PROCESSING && (
            <div className={styles.actions}>
              <div className={styles.action} onClick={handleReadyToShip}>
                <img src={readyToShip} alt="readyToShip"></img>
                <div className={styles.actionTxt}>
                  {constants.ordersListing.READY_TO_SHIP}
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
