import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../../assets/images/ordersListing/Filter.svg";
import emptyContainer from "../../../assets/images/ordersListing/emptyContainer.svg";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import PageSearch from "../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import {
  buyerAPIEndpoints,
  constants,
} from "../../../common/constants/constants";
import { EmptyContainer } from "../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { adminAddOrdersListPlaced } from "../../../reducerSlices/adminManageOrdersListSlice";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { setTab } from "../../../reducerSlices/selectedTabSlice";
import { useAxios } from "../../../services/useAxios";
import { AdminOrdersListingCard } from "../adminOrdersListingCard/AdminOrdersListingCard";
import styles from "./AdminOrdersListingStyles.module.scss";
import { SideDrawer } from "../../../common/components/SideDrawer/SideDrawer";
import AdminOrdersFilters from "./components/AdminOrdersFilters";
// import { CancelToken } from "axios";
import { formatDate } from "../../../utils/dateFormat";
import { setPage } from "../../../reducerSlices/selectedPageSlice";
import { setFilterData } from "../../../reducerSlices/filtersDataSlice";
import MigratedOrderCard from "../adminOrdersListingCard/MigratedOrderCard";
import { transformMigratedOrdersKeys } from "../../../common/pages/ordersListing/components/tabComponent/TabComponent";
export const AdminOrdersListing = () => {
  const navigate = useNavigate();
  const adminManageOrdersList = useSelector(
    (state: any) => state?.adminManageOrdersList
  );
  const filtrationKeys = useSelector((state: any) => state?.filteredKeys);
  const [isAdmin, setIsAdmin] = useState(false);
  const [finalYetToConfirm, setFinalYetToConfirm] = useState([]);
  // modified orders is hide for this phase
  const [ordersModified, setOrdersModified] = useState([]);
  const [ordersMigrated, setOrdersMigrated] = useState([]);
  const [finalProcessing, setFinalProcessing] = useState([]);
  const [finalShipped, setFinalShipped] = useState([]);
  const [finalCompleted, setFinalCompleted] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [triggerSort, setTriggerSort] = useState(false);
  // const [triggerApiCall, setTriggerApiCall] = useState(false);
  const [ShowSupplierListInFilter, setShowSupplierListInFilter] = useState([]);
  const [ShowBuyerListInFilter, setShowBuyerListInFilter] = useState([]);
  const [finalQuery, setFinalQuery] = useState<any>();
  // const [filteredYetToConfirm, setFilteredYetToConfirm] =
  //   useState(finalYetToConfirm);
  // const [filteredProcessing, setFilteredProcessing] = useState(finalProcessing);
  // const [filteredShipped, setFilteredShipped] = useState(finalShipped);
  // const [filteredCompleted, setFilteredCompleted] = useState(finalCompleted);
  // const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  // const sortByAscAmount = () => {
  //   let filteredData;
  //   if (activeTab === 0) {
  //     filteredData = finalYetToConfirm;
  //     filteredData.sort((a, b) => a.orderValue - b.orderValue);
  //     setFinalYetToConfirm(filteredData);
  //     setFilteredYetToConfirm(filteredData);
  //   } else if (activeTab === 1) {
  //     filteredData = finalProcessing;
  //     filteredData.sort((a, b) => a.orderValue - b.orderValue);
  //     setFinalProcessing(filteredData);
  //     setFilteredProcessing(filteredData);
  //   } else if (activeTab === 2) {
  //     filteredData = finalShipped;
  //     filteredData.sort((a, b) => a.orderValue - b.orderValue);
  //     setFinalShipped(filteredData);
  //     setFilteredShipped(filteredData);
  //   } else {
  //     filteredData = finalCompleted;
  //     filteredData.sort((a, b) => a.orderValue - b.orderValue);
  //     setFinalCompleted(filteredData);
  //     setFilteredCompleted(filteredData);
  //   }
  // };
  // const sortByDescAmount = () => {
  //   let filteredData;
  //   if (activeTab === 0) {
  //     filteredData = finalYetToConfirm;
  //     filteredData.sort((a, b) => b.orderValue - a.orderValue);
  //     setFinalYetToConfirm(filteredData);
  //     setFilteredYetToConfirm(filteredData);
  //   } else if (activeTab === 1) {
  //     filteredData = finalProcessing;
  //     filteredData.sort((a, b) => b.orderValue - a.orderValue);
  //     setFinalProcessing(filteredData);
  //     setFilteredProcessing(filteredData);
  //   } else if (activeTab === 2) {
  //     filteredData = finalShipped;
  //     filteredData.sort((a, b) => b.orderValue - a.orderValue);
  //     setFinalShipped(filteredData);
  //     setFilteredShipped(filteredData);
  //   } else {
  //     filteredData = finalCompleted;
  //     filteredData.sort((a, b) => b.orderValue - a.orderValue);
  //     setFinalCompleted(filteredData);
  //     setFilteredCompleted(filteredData);
  //   }
  // };
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.manage_orders ?? 0
  );
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.adminManageOrdersPageNumber
  );
  const [activeTab, setActiveTab] = useState(selectedTab);
  const [currentPage, setCurrentPage] = useState(selectedPage);

  const dispatch = useDispatch();
  const selectTab = (index: number) => {
    dispatch(setTab({ type: "manage_orders", index }));
    setActiveTab(index);
    setCurrentPage(1);
    dispatch(setPage({ type: "adminManageOrdersPageNumber", index: 1 }));
    setFinalQuery(null);
    dispatch(setFilterData({}));
    setSearchText("");
  };
  const itemsPerPage = 10;
  // const visibleData = (data: any) => {
  //   const lastIndex = currentPage * itemsPerPage;
  //   const firstIndex = lastIndex - itemsPerPage;
  //   const visibleProducts = data.slice(firstIndex, lastIndex);
  //   return visibleProducts;
  // };
  // const handleSearchSuggestions = useCallback(
  //   (value: string, token?: CancelToken) => {
  //     const inputValue = value.toLowerCase().trimStart();
  //     setSearchText(inputValue);
  //     setCurrentPage(1);
  //     dispatch(setPage({ type: "adminManageOrdersPageNumber", index: 1 }));
  //     (inputValue || inputValue === "") &&
  //       setTimeout(() => {
  //         getDataNew();
  //       }, 2000);
  //     // getAdminManageOrdersList(inputValue, token);
  //     // if (inputValue.length > 0) {
  //     //   if (activeTab === 0) {

  //     //     setFinalYetToConfirm(
  //     //       (finalYetToConfirm as any)?.filter((item: any) =>
  //     //         Object.values(item).toString().includes(inputValue)
  //     //       )
  //     //     );
  //     //   } else if (activeTab === 1) {
  //     //     setFinalProcessing(
  //     //       (finalProcessing as any)?.filter((item: any) =>
  //     //         Object.values(item).toString().includes(inputValue)
  //     //       )
  //     //     );
  //     //   } else if (activeTab === 2) {
  //     //     setFinalShipped(
  //     //       (finalShipped as any)?.filter((item: any) =>
  //     //         Object.values(item).toString().includes(inputValue)
  //     //       )
  //     //     );
  //     //   } else {
  //     //     setFinalCompleted(
  //     //       (finalCompleted as any)?.filter((item: any) =>
  //     //         Object.values(item).toString().includes(inputValue)
  //     //       )
  //     //     );
  //     //   }
  //     // } else {
  //     //   if (activeTab === 0) {
  //     //     setFinalYetToConfirm(filteredYetToConfirm);
  //     //   }
  //     //   if (activeTab === 1) {
  //     //     setFinalProcessing(filteredProcessing);
  //     //   }
  //     //   if (activeTab === 2) {
  //     //     setFinalShipped(filteredShipped);
  //     //   }
  //     //   if (activeTab === 3) {
  //     //     setFinalCompleted(filteredCompleted);
  //     //   }
  //     // }
  //     // if (
  //     //   (activeTab === 3 && finalCompleted.length === 0) ||
  //     //   (activeTab === 1 && finalProcessing.length === 0) ||
  //     //   (activeTab === 2 && finalShipped.length === 0) ||
  //     //   (activeTab === 0 && finalYetToConfirm.length === 0)
  //     // ) {
  //     //   setShowEmptyContainer(true);
  //     // } else {
  //     //   setShowEmptyContainer(false);
  //     // }
  //   },
  //   [activeTab]
  // );

  const handleSearchSuggestions = (value: string, token: any) => {
    const inputValue = value.toLowerCase().trimStart();
    setSearchText(inputValue);
    setCurrentPage(1);
    dispatch(setPage({ type: "adminManageOrdersPageNumber", index: 1 }));
  };
  // useEffect(() => {
  //   if (adminManageOrdersList && adminManageOrdersList.length === 0) {
  //     setShowEmptyContainer(true);
  //   }
  // }, [adminManageOrdersList, dispatch]);
  const tabsData = [
    {
      id: 0,
      label: constants.ordersListing.YET_TO_CONFIRM,
      content: finalYetToConfirm,
      visibleContent: finalYetToConfirm,
    },
    {
      id: 1,
      label: constants.ordersListing.PROCESSING,
      content: finalProcessing,
      visibleContent: finalProcessing,
    },
    {
      id: 2,
      label: constants.ordersListing.SHIPPED,
      content: finalShipped,
      visibleContent: finalShipped,
    },
    {
      id: 3,
      label: constants.ordersListing.COMPLETED,
      content: finalCompleted,
      visibleContent: finalCompleted,
    },
    {
      id: 4,
      label: constants.ordersListing.MODIFIEDORDER,
      content: ordersModified,
      visibleContent: ordersModified,
    },
    {
      id: 5,
      label: constants.ordersListing.migratedOrders,
      content: ordersMigrated,
      visibleContent: ordersMigrated,
    },
  ];
  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "adminManageOrdersPageNumber", index: page }));
  };

  //  api
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const getCurrentStatus = () => {
    return activeTab === 0
      ? "placed"
      : activeTab === 1
      ? "confirmed"
      : activeTab === 2
      ? "shipped"
      : activeTab === 3
      ? "completed"
      : activeTab === 4
      ? "modified"
      : "placed";
  };

  // const getStatus = (tab: Number, query: any) => {
  //   console.log(tab, query, 'GETStatus');
  //   if (tab === 3 && query?.length > 0) {
  //     return `status=${query}`;
  //   } else if (tab === 3 && query?.length === 0) {
  //     return `status=${getCurrentStatus()}`;
  //   } else if (tab !== 3) {
  //     return `status=${getCurrentStatus()}`;
  //   }
  // };

  const getAdminManageOrders = useAxios({
    axiosParams: {
      url: `store/master-order/?${
        activeTab === 3 && finalQuery?.paymentStatus?.length > 0
          ? `status=${finalQuery?.paymentStatus}`
          : activeTab === 3 && finalQuery?.paymentStatus?.length === 0
          ? `status=${getCurrentStatus()}`
          : activeTab !== 3
          ? `status=${getCurrentStatus()}`
          : `status=${getCurrentStatus()}`
      }&page=${currentPage}${
        activeTab !== 3 && finalQuery?.paymentStatus?.length
          ? `&payment_status=${finalQuery?.paymentStatus}`
          : ""
      }${
        finalQuery?.placedDate?.length
          ? `&placed_date=${finalQuery?.placedDate}`
          : ""
      }${
        finalQuery?.deliveredDate?.length
          ? `&delivered_date=${finalQuery?.deliveredDate}`
          : ""
      }${
        finalQuery?.supplier?.length
          ? `&customer_id=${finalQuery?.supplier}`
          : ""
      }${
        finalQuery?.buyer?.length
          ? `&buyer_customer_id=${finalQuery?.buyer}`
          : ""
      }${
        finalQuery?.orderValueDropdown?.length
          ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
          : ""
      }${
        finalQuery?.orderValue?.length
          ? `&order_amount_value=${finalQuery?.orderValue}`
          : ""
      }${
        finalQuery?.orderQuantityDropdown?.length
          ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
          : ""
      }${
        finalQuery?.orderQuantity?.length
          ? `&order_quantity_value=${finalQuery?.orderQuantity}`
          : ""
      }${searchText.length > 0 ? `&q=${searchText}` : ""}${
        sortValue?.length > 0 ? `&sort_by=total&sort_order=${sortValue}` : ""
      }`,
      method: "GET",
    },
  });
  const getAdminMigratedOrders = useAxios({
    axiosParams: {
      url: `store/migrated_orders/get-order-details?offset=${
        (currentPage - 1) * 10
      }&limit=10${searchText && `&q=${searchText}`}${
        sortValue?.length > 0
          ? `${"&sort_by=amount"}&sort_order=${sortValue}`
          : ""
      }`,
      method: "GET",
    },
  });
  // const getAdminManageOrdersList = useCallback(
  //   async (searchTerm?: string, cancelToken?: CancelToken) => {
  //     dispatch(updateIsLoading(true));
  //     console.log(finalQuery, "finalQuery");
  //     let response: any;
  //     if (searchTerm) {
  //       console.log(1111);
  //       response = await getAdminManageOrders.fetchData({
  //         axiosParams: {
  //           url: `/store/master-order?${
  //             activeTab !== 3
  //               ? `status=${getCurrentStatus()}`
  //               : finalQuery?.paymentStatus?.length > 0
  //               ? `status=${finalQuery?.paymentStatus}`
  //               : `status=${getCurrentStatus()}`
  //           }&page=${currentPage}${
  //             activeTab !== 3 && finalQuery?.paymentStatus?.length
  //               ? `&payment_status=${finalQuery?.paymentStatus}`
  //               : ""
  //           }${
  //             finalQuery?.placedDate?.length
  //               ? `&placed_date=${finalQuery?.placedDate}`
  //               : ""
  //           }${
  //             finalQuery?.deliveredDate?.length
  //               ? `&delivered_date=${finalQuery?.deliveredDate}`
  //               : ""
  //           }${
  //             finalQuery?.supplier?.length
  //               ? `&customer_id=${finalQuery?.supplier}`
  //               : ""
  //           }${
  //             finalQuery?.buyer?.length
  //               ? `&buyer_customer_id=${finalQuery?.buyer}`
  //               : ""
  //           }${
  //             finalQuery?.orderValueDropdown?.length
  //               ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
  //               : ""
  //           }${
  //             finalQuery?.orderValue?.length
  //               ? `&order_amount_value=${finalQuery?.orderValue}`
  //               : ""
  //           }${
  //             finalQuery?.orderQuantityDropdown?.length
  //               ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
  //               : ""
  //           }${
  //             finalQuery?.orderQuantity?.length
  //               ? `&order_quantity_value=${finalQuery?.orderQuantity}`
  //               : ""
  //           }${searchTerm.length > 0 ? `&q=${searchTerm}` : ""}${
  //             sortValue?.length > 0
  //               ? `&sort_by=total&sort_order=${sortValue}`
  //               : ""
  //           }`,
  //           cancelToken,
  //         },
  //         method: "GET",
  //       });
  //     } else {
  //       console.log(22222);
  //       response = await getAdminManageOrders.fetchData();
  //     }
  //     // response = await getAdminManageOrders.fetchData();
  //     console.log(response, "response");
  //     if (response && response?.data && response?.data?.orders) {
  //       // dispatch(
  //       //   adminAddOrdersListPlaced([
  //       //     {
  //       //       id: "ord_01HCQHSA9CJJ7JDNQYCEYQA8SQ",
  //       //       cart: {
  //       //         id: "cart_01HCQ7QAS84GQN9E40SWQ2NMGD",
  //       //       },
  //       //       buyer: {
  //       //         id: "cus_01HCQ4Y0BY94XDJ8TH05Z6S304",
  //       //         last_name: "Testnamelast",
  //       //         first_name: "Testnamefirst",
  //       //       },
  //       //       items: {
  //       //         count: 2,
  //       //         items: [
  //       //           {
  //       //             id: "item_01HCQG09GKWH7KXME3QN4Y3XA7",
  //       //           },
  //       //           {
  //       //             id: "item_01HCQHRS03YC798NV0HDGBZT70",
  //       //           },
  //       //         ],
  //       //         master_order_id: "ord_01HCQHSA9CJJ7JDNQYCEYQA8SQ",
  //       //       },
  //       //       notes: null,
  //       //       total: "720",
  //       //       outlet: {
  //       //         id: "bs_01HCQ4Y0C6N1MYP63BME2SACMY",
  //       //         email_id: "tarun1@gmail.com",
  //       //         business_name: "test tarun",
  //       //       },
  //       //       rating: null,
  //       //       status: "placed",
  //       //       cart_id: "cart_01HCQ7QAS84GQN9E40SWQ2NMGD",
  //       //       invoice: null,
  //       //       vat_tax: "120",
  //       //       supplier: {
  //       //         id: "cus_01HCQ9N3HYW7K5GNFDZXN5T4TH",
  //       //         last_name: "Kumar",
  //       //         first_name: "Naveen",
  //       //       },
  //       //       sub_total: "600",
  //       //       warehouse: {
  //       //         id: "bs_01HCQ9N3J8KPV5WZH6JTCSE0TG",
  //       //         email_id: "naveenkumars@divami.com",
  //       //         business_name: "Supplier warehouse uday",
  //       //       },
  //       //       canceled_at: "2023-10-14T16:54:49.366+00:00",
  //       //       order_number: "PO-0",
  //       //       order_tracking: {
  //       //         id: "order_track_01HCQHSA9DTCMWFKH9MRWTSXSJ",
  //       //         status: "placed",
  //       //         order_id: "ord_01HCQHSA9CJJ7JDNQYCEYQA8SQ",
  //       //         created_at: "2023-10-14T16:54:49.391663+00:00",
  //       //         updated_at: "2023-10-14T16:54:49.391663+00:00",
  //       //         packed_date: null,
  //       //         placed_date: "2023-10-14T16:54:49.389+00:00",
  //       //         delayed_date: null,
  //       //         cancelled_date: null,
  //       //         confirmed_date: null,
  //       //         delivered_date: null,
  //       //         expected_delivery: null,
  //       //       },
  //       //       payment_status: "not_paid",
  //       //       delivery_charge: "0",
  //       //       fulfillment_status: null,
  //       //       shipping_address: null,
  //       //       billing_address: null,
  //       //     },
  //       //   ])
  //       // );
  //       dispatch(adminAddOrdersListPlaced(response?.data?.orders));

  //       setTotalOrdersCount(response?.data?.count);
  //       // setTimeout(() => {
  //       dispatch(updateIsLoading(false));
  //       // }, 1000);
  //     } else {
  //       dispatch(adminAddOrdersListPlaced([]));
  //       dispatch(updateIsLoading(false));
  //     }
  //   },
  //   [getAdminManageOrders]
  // );

  const getDataNew = async () => {
    dispatch(updateIsLoading(true));
    let response: any;
    if (activeTab === 5) {
      response = await getAdminMigratedOrders.fetchData();
      if (response && response?.status === "SUCCESS") {
        setTotalOrdersCount(response?.data?.count);
        setOrdersMigrated(
          transformMigratedOrdersKeys(response?.data?.order_list)
        );
        dispatch(updateIsLoading(false));
      }
      else {
        dispatch(updateIsLoading(false));
      }
    } else {
      response = await getAdminManageOrders.fetchData();
      if (response && response?.data && response?.data?.orders) {
        dispatch(adminAddOrdersListPlaced(response?.data?.orders));

        setTotalOrdersCount(response?.data?.count);
        // setTimeout(() => {
        dispatch(updateIsLoading(false));
        // }, 1000);
      } else {
        dispatch(adminAddOrdersListPlaced([]));
        dispatch(updateIsLoading(false));
      }
    }
  };

  useEffect(() => {
    getDataNew();
  }, [activeTab, currentPage, searchText, finalQuery]);
  useEffect(() => {
    if (adminManageOrdersList) {
      switch (activeTab) {
        case 0:
          setFinalYetToConfirm(adminManageOrdersList);
          break;
        case 1:
          setFinalProcessing(adminManageOrdersList);
          break;
        case 2:
          setFinalShipped(adminManageOrdersList);
          break;
        case 3:
          setFinalCompleted(adminManageOrdersList);
          break;
        case 4:
          setOrdersModified(adminManageOrdersList);
          break;
        default:
          break;
      }
    }
  }, [adminManageOrdersList, totalOrdersCount]);

  //Sorting starts here
  const [showSort, setShowSort] = useState(false);
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const [prevSelected, setPrevSelected] = useState(-1);

  const getSortedList = useAxios({
    axiosParams: {
      url: `store/master-order?${
        activeTab !== 3
          ? `status=${getCurrentStatus()}`
          : finalQuery?.paymentStatus?.length > 0
          ? `status=${finalQuery?.paymentStatus}`
          : `status=${getCurrentStatus()}`
      }&page=${currentPage}&q=${searchText}${
        activeTab !== 3 && finalQuery?.paymentStatus?.length
          ? `&payment_status=${finalQuery?.paymentStatus}`
          : ""
      }${
        finalQuery?.placedDate?.length
          ? `&placed_date=${finalQuery?.placedDate}`
          : ""
      }${
        finalQuery?.deliveredDate?.length
          ? `&delivered_date=${finalQuery?.deliveredDate}`
          : ""
      }${
        finalQuery?.supplier?.length
          ? `&customer_id=${finalQuery?.supplier}`
          : ""
      }${
        finalQuery?.buyer?.length
          ? `&buyer_customer_id=${finalQuery?.buyer}`
          : ""
      }${
        finalQuery?.orderValueDropdown?.length
          ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
          : ""
      }${
        finalQuery?.orderValue?.length
          ? `&order_amount_value=${finalQuery?.orderValue}`
          : ""
      }${
        finalQuery?.orderQuantityDropdown?.length
          ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
          : ""
      }${
        finalQuery?.orderQuantity?.length
          ? `&order_quantity_value=${finalQuery?.orderQuantity}`
          : ""
      }${"&sort_by=total"}&sort_order=${sortValue}`,
    },
    method: "GET",
  });

  const updateSortedList = async () => {
    if (triggerSort) {
      dispatch(updateIsLoading(true));
      if (activeTab === 5) {
        const response: any = await getAdminMigratedOrders.fetchData();
        if (response?.status === "SUCCESS") {
          if (response && response?.data) {
            setTotalOrdersCount(response?.data?.count);
            setOrdersMigrated(
              transformMigratedOrdersKeys(response?.data?.order_list)
            );
          }
          dispatch(updateIsLoading(false));
        } else {
          dispatch(updateIsLoading(false));
        }
      } else {
        const response: any = await getSortedList.fetchData();
        if (response?.status === "SUCCESS") {
          if (response && response?.data && response?.data?.orders) {
            dispatch(adminAddOrdersListPlaced(response?.data?.orders));
            setTotalOrdersCount(response?.data?.count);
          }
          dispatch(updateIsLoading(false));
        } else {
          dispatch(updateIsLoading(false));
        }
      }
      setTriggerSort(false);
    }
  };

  useEffect(() => {
    updateSortedList();
  }, [sortValue]);

  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {
        setTriggerSort(true);
        setSortValue("whatsnew");
      },
    },
    {
      id: 1,
      label: constants.ordersListing.SORT_LOW_HIGH,
      onClick: () => {
        setTriggerSort(true);
        setSortValue("ASC");
      },
    },
    {
      id: 2,
      label: constants.ordersListing.SORT_HIGH_LOW,
      onClick: () => {
        setTriggerSort(true);
        setSortValue("DESC");
      },
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };

  const [isdrawer, setIsDrawer] = React.useState({
    right: false,
  });
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const myRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const searchCustomStyles = {
    top: "0px",
  };
  useEffect(() => {
    //const tabScroll :HTMLElement | null= document.getElementById("tabScroll");
    //	tabScroll?.scrollTop(0)
    myRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  //Filters starts here
  // const getFilteredList = useAxios({
  //   axiosParams: {
  //     url: `store/master-order?${
  //       activeTab !== 3
  //         ? `status=${getCurrentStatus()}`
  //         : finalQuery?.paymentStatus?.length > 0
  //         ? `status=${finalQuery?.paymentStatus}`
  //         : `status=${getCurrentStatus()}`
  //     }&page=${currentPage}&q=${searchText}${
  //       activeTab !== 3 && finalQuery?.paymentStatus?.length
  //         ? `&payment_status=${finalQuery?.paymentStatus}`
  //         : ""
  //     }${
  //       finalQuery?.placedDate?.length
  //         ? `&placed_date=${finalQuery?.placedDate}`
  //         : ""
  //     }${
  //       finalQuery?.deliveredDate?.length
  //         ? `&delivered_date=${finalQuery?.deliveredDate}`
  //         : ""
  //     }${
  //       finalQuery?.supplier?.length
  //         ? `&customer_id=${finalQuery?.supplier}`
  //         : ""
  //     }${
  //       finalQuery?.buyer?.length
  //         ? `&buyer_customer_id=${finalQuery?.buyer}`
  //         : ""
  //     }${
  //       finalQuery?.orderValueDropdown?.length
  //         ? `&order_amount_key=${finalQuery?.orderValueDropdown}`
  //         : ""
  //     }${
  //       finalQuery?.orderValue?.length
  //         ? `&order_amount_value=${finalQuery?.orderValue}`
  //         : ""
  //     }${
  //       finalQuery?.orderQuantityDropdown?.length
  //         ? `&order_quantity_key=${finalQuery?.orderQuantityDropdown}`
  //         : ""
  //     }${
  //       finalQuery?.orderQuantity?.length
  //         ? `&order_quantity_value=${finalQuery?.orderQuantity}`
  //         : ""
  //     }${
  //       sortValue?.length > 0 ? `&sort_by=total&sort_order=${sortValue}` : ""
  //     }`,
  //   },
  //   method: "GET",
  // });

  // const fetchData = useCallback(async () => {
  //   if (triggerApiCall) {
  //     dispatch(updateIsLoading(true));
  //     const response: any = await getFilteredList.fetchData();
  //     if (response?.status === "SUCCESS") {
  //       if (response && response?.data && response?.data?.orders) {
  //         dispatch(adminAddOrdersListPlaced(response?.data?.orders));
  //         setTotalOrdersCount(response?.data?.count);
  //       }
  //       dispatch(updateIsLoading(false));
  //     } else {
  //       dispatch(updateIsLoading(false));
  //     }
  //     setTriggerApiCall(false);
  //   }
  // }, [triggerApiCall]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  const onSubmitData = (data: any) => {
    const finalDataLocal: any = {};
    if (data?.actualAmountValue && data?.actualAmount) {
      finalDataLocal["actualAmountValue"] = data?.actualAmountValue;
      finalDataLocal["actualAmountDropdown"] =
        typeof data?.actualAmount != "string"
          ? data?.actualAmount?.value
          : data?.actualAmount;
    }
    if (data?.estimatedAmountValue && data?.estimatedAmount) {
      finalDataLocal["estimatedAmountValue"] = data?.estimatedAmountValue;
      finalDataLocal["estimatedAmountDropdown"] =
        typeof data?.estimatedAmount != "string"
          ? data?.estimatedAmount?.value
          : data?.estimatedAmount;
    }
    if (data?.orderValue && data?.orderValue?.length > 0) {
      finalDataLocal["orderValue"] = data?.orderValue;
    }
    if (data?.orderValueDropdown && data?.orderValueDropdown?.length > 0) {
      finalDataLocal["orderValueDropdown"] = data?.orderValueDropdown
        ?.split(" ")?.[0]
        ?.toLowerCase();
    }
    if (data?.orderQuantity && data?.orderQuantity?.length > 0) {
      finalDataLocal["orderQuantity"] = data?.orderQuantity;
    }
    if (
      data?.orderQuantityDropdown &&
      data?.orderQuantityDropdown?.length > 0
    ) {
      finalDataLocal["orderQuantityDropdown"] = data?.orderQuantityDropdown
        ?.split(" ")?.[0]
        ?.toLowerCase();
    }
    if (data?.buyer && data?.buyer?.length > 0) {
      finalDataLocal["buyer"] = data?.buyer;
    }
    if (data?.supplier && data?.supplier?.length > 0) {
      finalDataLocal["supplier"] = data?.supplier;
    }
    if (data?.placedDate) {
      finalDataLocal["placedDate"] = formatDate(data?.placedDate);
    }
    if (data?.deliveredDate) {
      finalDataLocal["deliveredDate"] = formatDate(data?.deliveredDate);
    }
    let payStatus = "";
    if (data?.paymentStatus) {
      if (data?.paymentStatus?.paid) {
        payStatus += "paid";
      }
      if (data?.paymentStatus?.unpaid) {
        payStatus =
          payStatus?.length > 0 ? payStatus + ",not_paid" : "not_paid";
      }
      finalDataLocal["paymentStatus"] = payStatus;
    }
    if (data?.orderStatus) {
      if (data?.orderStatus?.cancelled) {
        payStatus += "canceled";
      }
      if (data?.orderStatus?.delivered) {
        payStatus =
          payStatus?.length > 0
            ? payStatus + ",order_completed"
            : "order_completed";
      }
      finalDataLocal["paymentStatus"] = payStatus;
    }
    // if (isadmin) {
    //   if (data?.outlet && Object.keys(data?.outlet)) {
    //     finalDataLocal["outlet"] = data?.outlet?.value;
    //   }
    //   if (data?.supplier && Object?.keys(data?.supplier)?.length > 0) {
    //     finalDataLocal["supplier"] = data?.supplier?.value;
    //   }
    // }
    setFinalQuery(finalDataLocal);
    setCurrentPage(1);
    // setTriggerApiCall(true);
  };
  //To get supplier list
  const getSupplierList = useAxios({
    axiosParams: {
      url: `${buyerAPIEndpoints.invoiceFilters}`,
    },
    method: "GET",
  });

  const getSupplierListData = useCallback(async () => {
    const response: any = await getSupplierList.fetchData();
    if (response?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      const supplierList = response?.data?.invoiceList;
      if (supplierList && supplierList.length > 0) {
        const updatedSupplierList = supplierList.map((supplier: any) => ({
          ...supplier,
          name: supplier.ware_house_name,
          label: supplier.ware_house_name,
          value: supplier.ware_house_name,
        }));
        if (updatedSupplierList?.length > 0) {
          setShowSupplierListInFilter(updatedSupplierList);
        }
      }
    }
  }, [getSupplierList]);

  //To get buyers list
  const getBuyersList = useAxios({
    axiosParams: {
      url: `${buyerAPIEndpoints.buyerFilters}`,
    },
    method: "GET",
  });

  const getBuyerListData = useCallback(async () => {
    const response: any = await getBuyersList.fetchData();
    if (response?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      const buyerList = response?.data?.invoiceList;
      if (buyerList && buyerList.length > 0) {
        const updatedbuyerList = buyerList.map((buyer: any) => ({
          ...buyer,
          name: buyer.outlet_name,
          label: buyer.outlet_name,
          value: buyer.outlet_name,
        }));
        if (updatedbuyerList?.length > 0) {
          setShowBuyerListInFilter(updatedbuyerList);
        }
      }
    }
  }, [getBuyersList]);

  useEffect(() => {
    dispatch(updateIsLoading(true));
    getSupplierListData();
    getBuyerListData();
  }, []);

  // Clearing filters on tab change
  // useEffect(() => {
  //   setFinalQuery({});
  //   // setTriggerApiCall(false);
  //   dispatch(setFilterData({}));
  // }, [activeTab]);

  //cleanup function for activeFilters in redux
  useEffect(() => {
    return () => {
      dispatch(setFilterData({}));
    };
  }, []);
  // console.log(searchText, "searchText", activeTab);
  // console.log(totalOrdersCount, "totalOrdersCount");
  // console.log(adminManageOrdersList, "adminManageOrdersList");

  // const [orderDeliveryStatus, setOrderDeliveryStatus] = useState({delivered: })
  return (
    <>
      {tabsData && tabsData.length > 0 && (
        <div className={styles.container}>
          <div className={styles.tabs}>
            <div className={styles.tabsStyle}>
              <div className={styles.tabList}>
                {tabsData.map((each) => (
                  <div
                    className={styles.tabListItem}
                    onClick={() => selectTab(each.id)}
                  >
                    <div
                      className={`${styles.label} ${
                        each.id === activeTab && styles.activeLabel
                      }`}
                    >
                      {each.label}
                    </div>
                    {activeTab === each.id && (
                      <div className={styles.border}></div>
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.tabsBorder}></div>
            </div>

            <div className={`${styles.actions} ${styles.actions2}`}>
              {(totalOrdersCount > 0 || searchText.length > 0) && (
                <div>
                  <PageSearch
                    handleSearch={handleSearchSuggestions}
                    customStyles={searchCustomStyles}
                    activeTab={activeTab}
                    customPlaceHolder="Search by order id or buyer name"
                  />
                </div>
              )}
              {
                <div
                  className={`${styles.icon} ${styles.sort}`}
                  onClick={handleSort}
                  ref={sortContainerRef}
                >
                  <img src={sortIcon} alt="sort"></img>
                  {showSort && (
                    <div className={styles.sortList}>
                      <div className={styles.sortBy}>
                        {constants.ordersListing.SORT_BY}
                      </div>
                      {sortOptions.map((option, index) => (
                        <div
                          className={`${styles.sortOption} ${
                            index === sortOptions.length - 1 &&
                            styles.lastOption
                          } ${
                            option.id === prevSelected && styles.selectedOpt
                          }`}
                          onClick={() => handleSortingOptions(option.id)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              }
              {activeTab !== 5 && (
                <div
                  className={`${styles.icon}`}
                  onClick={() => setIsDrawer({ right: true })}
                  data-testid="order-filter-button"
                >
                  {filtrationKeys?.filterData?.activeFilters && (
                    <div className={styles.activeFilter}></div>
                  )}
                  <img src={filterIcon} alt="filter"></img>
                </div>
              )}
            </div>
          </div>
          {(adminManageOrdersList &&
            adminManageOrdersList.length === 0 &&
            activeTab !== 5) ||
          (activeTab === 5 && ordersMigrated?.length === 0) ? (
            <div className={styles.emptyContainer}>
              <div>
                <img src={emptyContainer} alt="empty" />
              </div>
              <div className={styles.woah}>Woah!</div>
              <div className={styles.content}>No orders</div>
            </div>
          ) : (
            <div className={styles.tabsContentCompleted}>
              {activeTab === 5 ? (
                <div className={styles.header}>
                  <div
                    className={`${styles.migratedOrder} ${styles.labelStyle}`}
                  >
                    {"Order Number"}
                  </div>
                  <div
                    className={`${styles.migratedOrder} ${styles.labelStyle}`}
                  >
                    {"Order Date"}
                  </div>

                  <div
                    className={`${styles.migratedOrder} ${styles.labelStyle}`}
                  >
                    {"Price"}
                  </div>
                  <div
                    className={`${styles.migratedOrder} ${styles.labelStyle}`}
                  >
                    {"VAT"}
                  </div>
                  <div
                    className={`${styles.migratedOrder} ${styles.labelStyle}`}
                  >
                    {"Total"}
                  </div>
                </div>
              ) : (
                <div className={styles.header}>
                  <div className={`${styles.orderID} ${styles.labelStyle}`}>
                    {constants.ordersListing.ORDER_ID}
                  </div>
                  <div className={`${styles.buyerName} ${styles.labelStyle}`}>
                    {"Actual Amount"}
                  </div>
                  <div className={`${styles.placedOn} ${styles.labelStyle}`}>
                    {"Order Value"}
                  </div>

                  <div className={`${styles.orderValue} ${styles.labelStyle}`}>
                    {"Address"}
                  </div>
                  <div
                    className={`${styles.actualAmount} ${styles.labelStyle}`}
                  >
                    {"Placed on"}
                  </div>

                  <div
                    className={`${styles.orderItems} ${styles.labelStyle}`}
                  ></div>
                  {activeTab !== 0 && (
                    <div className={`${styles.labelStyle}`}>
                      {"To be delivered"}
                    </div>
                  )}
                </div>
              )}
              {/* {activeTab === 5 && (
                <div className={styles.tabslistContainer}>
                  {" "}
                  <div
                    // key={each.id}
                    className={styles.ordersList}
                    ref={myRef}
                  >
                    <MigratedOrderCard />
                  </div>
                </div>
              )} */}
              {tabsData &&
                tabsData?.length > 0 &&
                tabsData?.map(
                  (each) =>
                    activeTab === each.id && (
                      <div className={styles.tabslistContainer}>
                        <div
                          key={each.id}
                          className={styles.ordersList}
                          ref={myRef}
                        >
                          {each?.visibleContent?.map((order: any) =>
                            each.id === 5 ? (
                              <MigratedOrderCard order={order} />
                            ) : (
                              <AdminOrdersListingCard
                                activeTab={activeTab}
                                type={order?.type}
                                key={order?.orderId}
                                icon={order?.icon}
                                orderId={order.orderPONumber}
                                buyerName={order.buyerName}
                                paidStatus={order.paidStatus}
                                actualAmount={order.actualAmount}
                                orderItems={order.orderItems}
                                orderValue={order.orderValue}
                                placedOn={order.placedOn}
                                area={order.area}
                                address={order.address}
                                toBeDelivered={order.toBeDelivered}
                                deliveredDate={order.deliveredDate}
                                deliveryStatus={order.deliveryStatus}
                                onCardClick={() => {
                                  navigate(
                                    `/admin/manage-admin-orders/order-details/${order.orderId}`
                                  );
                                }}
                                orderType={order.orderType}
                              />
                            )
                          )}
                        </div>
                        <div className={styles.paginationContainer}>
                          <PaginationComponent
                            count={Math.ceil(totalOrdersCount / itemsPerPage)}
                            page={currentPage}
                            handlePagination={handlePagination}
                            showFirstButton={true}
                            showLastButton={true}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
          )}
        </div>
      )}
      {tabsData.length === 0 && (
        <div className={styles.emptyContainer}>
          <EmptyContainer title={"No Data"} />
        </div>
      )}
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={"Filters"}
          childComponent={
            <AdminOrdersFilters
              overlayCallback={setIsDrawer}
              activeTab={activeTab}
              isAdmin={isAdmin}
              onSubmitData={onSubmitData}
              ShowSupplierListInFilter={ShowSupplierListInFilter}
              ShowBuyerListInFilter={ShowBuyerListInFilter}
            />
          }
        />
      )}
    </>
  );
};
