import styles from "./MostPurchasedProduct.module.scss";
import ProgressBar from "../../../common/components/progressBar/ProgressBar";
import footerIcon from "../../../assets/images/footerIcon.svg";
import { IMostPurchasedProductProps } from "../../../models/IMostPurchasedProduct";
import starIcon from "../../../assets/images/Star.svg";

const MostPurchasedProduct = ({ purchasedProductData, userType }: any) => {
  return (
    <>
      <div className={styles.parentContainer}>
        {purchasedProductData &&
          purchasedProductData?.map(
            (data: IMostPurchasedProductProps, index: number) => (
              <div
                className={styles.cardParent}
                key={data?.id}
                data-testid={`productCard-${data?.id}`}
                title="card"
              >
                <div className={styles.productCard}>
                  <div className={styles.imageContainer}>
                    <div className={styles.productImage}>
                      <img
                        src={data?.productImage}
                        alt="productImage"
                        data-testid={`productImage-${data?.id}`}
                      />
                    </div>
                  </div>
                  <div className={styles.progressBarContainer}>
                    <div className={styles.progressBarInfo}>
                      <div className={styles.productName}>
                        {data?.productName}
                      </div>
                      {userType === "supplier" && (
                        <div className={styles.ratingInfo}>
                          <span>{data?.numberOfOrders}</span>Orders{" "}
                          <span>|</span>
                          <img src={starIcon} alt="star" />
                          <span>{data?.rating}/5</span>
                        </div>
                      )}
                    </div>

                    <div className={styles.progressBar}>
                      <ProgressBar
                        progress={data?.purchasedNumber}
                        customClass={styles.progressBarStyles}
                        borderRadius="8px"
                      />
                    </div>
                  </div>
                  {userType === "buyer" && (
                    <div className={styles.requiredSKU}>
                      <span>{data?.sku}</span> SKU Required
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        <div className={styles.footerContainer}>
          <div className={styles.footerIcon}>
            <img src={footerIcon} alt="footerIcon" />
          </div>
          <div className={styles.footerText}>
            Lettuce consumption has been increased by 12% over last 2 weeks.
          </div>
        </div>
      </div>
    </>
  );
};
export default MostPurchasedProduct;
