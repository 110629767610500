// import { constants } from "../../../common/constants/constants";
import {
  IAdminUserOrder,
  IadminUser,
} from "../../../models/IAdminUserListingProps";
import styles from "../adminSupplierListingCard/AdminSupplierListingCard.module.scss";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../../assets/images/delete.svg";
import { useAxios } from "../../../services/useAxios";
import DeletePopup from "../../../common/components/deletePopup";
import { useState } from "react";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import { constants } from "../../../common/constants/constants";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";

export const AdminUserListingCard = ({
  order,
  handleCardClick,
}: IAdminUserOrder) => {
  const {
    businessName,
    firstName,
    lastName,
    email,
    role,
    outletsCount,
    id,
    business_setup_id,
    type,
  } = order;
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  // console.log(order, "order");
  const adminUserId = JSON.parse(localStorage.getItem("loggedInUser") ?? "");
  // console.log(adminUserId, "adminUserId");
  const [toastData, setToastData] = useState<any>(constants.defaultToastData);

  const deleteUser = useAxios({
    axiosParams: {
      url:
        type === "buyer"
          ? `admin/buyer/${business_setup_id}/customer/${id}`
          : `admin/supplier/${business_setup_id}/customer/${id}`,
      data: {
        updated_customer_id: adminUserId && adminUserId?.id,
      },
    },
    method: "DELETE",
  });

  const handleDelete = async () => {
    try {
      const response: any = await deleteUser.fetchData();
      // console.log(response, "response");

      if (response.status === "SUCCESS") {
        setToastData({
          message: "User successfully deleted",
          status: "Success",
        });
      } else {
        setToastData({
          message:
            response?.response?.data?.error_msg ||
            "An error occurred while deleting the user",
          status: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setToastData({
        message: "An error occurred while deleting the user",
        status: "error",
      });
    } finally {
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 3000);
      setOpenDeletePopup(false);
    }
  };

  return (
    <>
      <div
        className={styles.card}
        onClick={() =>
          handleCardClick({
            ...order,
            country: order.country?.value,
            emirates: order.emirates?.value,
          })
        }
      >
        <div className={`${styles.product} ${styles.width1}`}>
          <div
            className={`${styles.productName} ${styles.businessName} ${styles.width}`}
            title={email}
          >
            {/* {businessName
              ?.split(" ")
              .map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" ")} */}
            {email}
          </div>
          {/* <div className={styles.active}>{userType}</div> */}
        </div>
        <div className={`${styles.activeProduct} ${styles.w_15} ${styles.overflow}`}>
          <div className={`${styles.emailID} ${styles.textStyle}`} title={businessName}>
            {businessName ?? "-"}
          </div>
        </div>
        <div className={`${styles.activeProduct} ${styles.w_15}`}>
          <div className={`${styles.emailID} ${styles.textStyle}`}>
            {firstName}
          </div>
        </div>
        <div className={`${styles.activeProduct} ${styles.w_15}`}>
          <div className={`${styles.emailID} ${styles.textStyle}`}>
            {lastName}
          </div>
        </div>
        <div className={`${styles.brandName} ${styles.w_15}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {outletsCount}
          </div>
        </div>
        {/* <div className={`${styles.country} ${styles.w_15}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {address}
          </div>
        </div> */}
        {/* <div className={`${styles.userType} ${styles.w_10}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {userType}
          </div>
        </div> */}
        <div className={`${styles.userType} ${styles.w_10}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {/* {userType === "Buyer" ? "1" : "-"} */}
            {role}
          </div>
        </div>
        <div className={`${styles.userType} ${styles.w_10}`}>
          <div
            className={`${styles.brandName} ${styles.textStyle} ${styles.hiddenOnHover}`}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeletePopup(true);
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </div>
        </div>
      </div>
      {openDeletePopup && <DeletePopup
        title="Delete User"
        popupContent="Are you sure you want to delete this user?"
        handleClose={() => setOpenDeletePopup(false)}
        handleConfirm={handleDelete}
        handleCancel={() => setOpenDeletePopup(false)}
        open={openDeletePopup}
      />}
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};

export const AdminUser = ({ adminUser }: IadminUser) => {
  const { adminId, email, firstName, lastName, phoneNumber } = adminUser;
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.card}
        onClick={() => {
          navigate(`/admin/users/update-admin-form/${adminId}`);
        }}
      >
        <div className={`${styles.product} ${styles.w_40}`}>
          <div className={styles.productName}>{email}</div>
          {/* <div className={styles.active}>{constants.buyersListHeaders.admin}</div> */}
        </div>
        <div className={`${styles.activeProduct} ${styles.w_15}`}>
          <div className={`${styles.emailID} ${styles.textStyle}`}>
            {firstName}
          </div>
        </div>
        <div className={`${styles.quantity} ${styles.w_15}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {lastName}
          </div>
        </div>
        <div className={`${styles.brandName} ${styles.w_15}`}>
          <div className={`${styles.brandName} ${styles.textStyle}`}>
            {phoneNumber}
          </div>
        </div>
        <div className={`${styles.country} ${styles.w_15}`}>
          {/* <div className={`${styles.brandName} ${styles.textStyle}`}>
            {address}
          </div> */}
        </div>
      </div>
    </>
  );
};
