import React from "react";
import styles from "../BulkUpload.module.scss";
const AttributeCell = ({
  currentRow,
  changeEditStatus,
  index,
  isInvalidAttribute,
  fieldName,
}: any) => {
  return (
    <td>
      <div className={styles.column}>
        {!currentRow?.[fieldName]?.editMode &&
        !currentRow?.[fieldName]?.value ? (
          <div
            className={styles.editableField}
            onDoubleClick={() => {
              !isInvalidAttribute && changeEditStatus(fieldName, index, true);
            }}
          >
            <span className={styles.errorTooltip}>-</span>
          </div>
        ) : (
          <div
            className={`${styles.column} ${
              isInvalidAttribute && styles.disabledText
            }`}
          >
            {!currentRow?.[fieldName]?.editMode ? (
              <span
                onDoubleClick={() => {
                  !isInvalidAttribute &&
                    changeEditStatus(fieldName, index, true);
                }}
              >
                {currentRow?.[fieldName]?.value
                  ? currentRow?.[fieldName]?.value
                  : ""}
              </span>
            ) : (
              <div className={styles.editableField}>
                <input
                  type="text"
                  name=""
                  id="supplier"
                  disabled={isInvalidAttribute}
                  value={
                    currentRow?.[fieldName]?.value?.toString().length > 0
                      ? currentRow?.[fieldName]?.value
                      : ""
                  }
                  onChange={(e) =>
                    changeEditStatus(fieldName, index, e.target.value)
                  }
                  onBlur={(e) =>
                    changeEditStatus(fieldName, index, e.target.value, false)
                  }
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      changeEditStatus(fieldName, index, false);
                    }
                  }}
                  autoFocus={currentRow?.[fieldName]?.editMode}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </td>
  );
};

export default AttributeCell;
