import React from "react";
import styles from "../BulkUpload.module.scss";
import iconError from "../../../../assets/images/icon-error.svg";
import { LightTooltip } from "../components/LightTooltip";

type EmptyProductOptionProps = {
  changeEditStatus: any;
  index: number;
  productIndex: number;
  isDuplicateOption: boolean;
  fieldName: string;
  tooltipTitle: string;
  showErr?: boolean;
};
const EmptyProductOption = ({
  changeEditStatus,
  index,
  productIndex,
  isDuplicateOption,
  fieldName,
  tooltipTitle,
  showErr = true,
}: EmptyProductOptionProps) => {
  return (
    <div
      className={styles.editableField}
      onDoubleClick={() =>{
        changeEditStatus(
          fieldName,
          productIndex,
          true
        )
      }
       
      }
    >
      {isDuplicateOption || !showErr ? (
        <span>-</span>
      ) : (
        <span
          className={styles.errorTooltip}
          title={tooltipTitle}
        >
          <LightTooltip
            title={tooltipTitle}
            placement="top"
            arrow
          >
            <img src={iconError} alt="Error" />
          </LightTooltip>
        </span>
      )}
    </div>
  );
};

export default EmptyProductOption;
