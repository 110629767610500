import { routeConfig } from "../../constants/routeConfig";
import BreadCrumb from "../breadCrumb/BreadCrumb";
import BuyerDeliveryDetails from "../buyerDeliveryDetails/BuyerDeliveryDetails";
import styles from "./InvoiceDetails.module.scss";
import { useCallback, useEffect, useState } from "react";
import countryFlag from "../../../assets/images/flag.png";
import ProductCardWithCart from "../productCardWithCart/ProductCardWithCart";
import Button from "../button/Button";
import { useParams } from "react-router-dom";
import { useAxios } from "../../../services/useAxios";
import { APIMethods, adminEndPoints } from "../../constants/urlConstants";
import { transformData } from "./invoiceDataTransformation";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import ContactSupport from "../contactSupport/ContactSupport";
export interface IItems {
  id: number | string;
  productName: string;
  productImage: string;
  outOfstockStatus: boolean;
  liked: boolean;
  offerPercentage: number;
  actualPrice: number;
  offerPrice: number;
  productDeliveryStatus: boolean;
  productQuantity: number;
  supllierType: string;
  unit: string;
  unitValue: number;
  created_at?: string;
  country?: any;
  deliveredQuantity?: number;
  isSpecialpriceApplied?: boolean;
}
export interface IInvoiceDetails {
  invoiceNo: string;
  paidStatus: string;
  details: Array<Object>;
  productSummary: Object;
  items: Array<IItems>;
}
const InvoiceDetails = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const userData = localStorage.getItem("loggedInUser");
  const isBuyer = userData
    ? JSON.parse(userData)?.typeOfUser.includes("buyer")
    : false;
  const [showContactSupport, setShowContactSupport] = useState(false);
  const { invoiceId } = useParams();
  const currentOrderStatus = "delivered";
  const getData = useAxios({
    axiosParams: {
      url: `${adminEndPoints.invoiceListing}/${invoiceId}`,
    },
    method: APIMethods.get,
  });

  const [invoiceData, setinvoiceData] = useState<IInvoiceDetails>();
  const dispatch = useDispatch();
  const getUserDetails = useCallback(async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getData.fetchData();
    dispatch(updateIsLoading(false));
    if (response?.status === "SUCCESS") {
      // console.log("response", response?.data);

      setinvoiceData(response?.data && transformData(response?.data));
    }
  }, [getData]);
  const breadcrumbItems = [
    {
      label: "Manage Invoices",
      link: isAdmin
        ? routeConfig.adminManageInvoices
        : isBuyer
        ? routeConfig.buyerInvoices
        : routeConfig.inVoices,
    },
    { label: invoiceData?.invoiceNo ?? "" },
  ];
  const buyerDetails = invoiceData?.details;
  const orderStatus = {
    invoiceNo: invoiceData?.invoiceNo,
    productSummary: invoiceData?.productSummary,
  };
  const downnloadInvoice = useAxios({
    axiosParams: {
      url: `store/invoice/download_invoice/${invoiceId}`,
      responseType: "arraybuffer",
    },
    method: APIMethods.get,
  });
  const downloadPDF = (data: ArrayBuffer) => {
    // Create a Blob from the binary data
    const blob = new Blob([data], { type: "application/pdf" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${invoiceData?.invoiceNo}.pdf`;

    // Append the link to the document and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const downnloadInvoiceAPI = async () => {
    const response: any = await downnloadInvoice?.fetchData();
    if (response) {
      downloadPDF(response);
    }
  };
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    // dispatch(updateIsLoading(true));
    if (userData != null) {
      getUserDetails();
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  return (
    <>
      <div className={styles.invoiceBlock}>
        <div className={styles.right}>
          {/* <div className={styles.search}>
            <div className={styles.searchContainer}>
              {userType === "supplier" ? (
                <Search
                  placeholder="Search for your supply soulmate"
                  searchSupplier
                ></Search>
              ) : (
                <Header customStyles={searchCustomStyles} />
              )}
            </div>
          </div> */}
          <div className={styles.breadcrumb_nav_block}>
            <div className={styles.breadcrumb}>
              <BreadCrumb items={breadcrumbItems} />
            </div>
            <div className={styles.navBlock}>
              <Button
                label="Download invoice"
                link
                handleClick={downnloadInvoiceAPI}
              ></Button>
              <Button
                label="Contact support"
                link
                handleClick={(event: any) => {
                  event.stopPropagation();
                  setShowContactSupport(!showContactSupport);
                }}
              ></Button>
              {showContactSupport && (
                <ContactSupport
                  setCloseContactSupport={setShowContactSupport}
                ></ContactSupport>
              )}
            </div>
          </div>
          <div
            className={`${styles.contentContainer} ${
              isAdmin ? styles.overridecontentContainer : null
            }`}
          >
            {invoiceData &&
              invoiceData?.items &&
              invoiceData?.items?.length > 0 && (
                <>
                  <div className={styles.invoiceDetailsBlock}>
                    <BuyerDeliveryDetails
                      buyerDetails={buyerDetails}
                      isSupplier={true}
                      paidStatus={
                        invoiceData?.paidStatus !== "not_paid"
                          ? "Paid"
                          : "Unpaid"
                      }
                      isInvoiceDetails={true}
                      currentOrderStatus={currentOrderStatus}
                      invoiceNo={orderStatus?.invoiceNo}
                      productSummary={orderStatus?.productSummary}
                    />
                  </div>
                  <div className={styles.productCards}>
                    <div className={styles.heading}>
                      Items Quantity: {invoiceData?.items.length} items
                    </div>
                    <div className={styles.cards}>
                      {invoiceData?.items &&
                        invoiceData?.items?.length > 0 &&
                        invoiceData?.items?.map((product) => (
                          <ProductCardWithCart
                            key={product.id}
                            supllierType={product.supllierType}
                            delivered={product.productDeliveryStatus}
                            productName={product.productName}
                            productImage={product.productImage}
                            offerPercentage={product.offerPercentage}
                            offerPrice={product.offerPrice}
                            actualPrice={product.actualPrice}
                            liked={product.liked}
                            outOfstockStatus={product.outOfstockStatus}
                            TBC="TBC"
                            countryFlag={countryFlag}
                            countryName="IND"
                            quantity={product.productQuantity}
                            unit={product.unit}
                            unitValue={product.unitValue}
                            created_at={product.created_at}
                            country={product.country}
                            deliveredQuantity={product?.deliveredQuantity}
                            isSpecialpriceApplied={
                              product?.isSpecialpriceApplied
                            }
                          />
                        ))}
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
