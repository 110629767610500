import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import PageSearch from "../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import PopupView from "../../../common/components/popupView/PopupView";
import {
  adminManageCatalogue,
  constants,
} from "../../../common/constants/constants";
import {
  APIMethods,
  adminEndPoints,
  urlConstants,
} from "../../../common/constants/urlConstants";
import * as yup from "yup";
import filterIcon from "../../../assets/images/ordersListing/Filter.svg";
import cancel from "../../../assets/images/cancelToast.svg";
import { AddSupplierPopup } from "../../../common/pages/productsListing/components/addSupplierPopup/AddSupplierPopup";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
// import { addCatalogueListActive } from "../../../reducerSlices/manageCatalogueList";
import { EmptyContainer } from "../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { IAdminCatalogueListingCardData } from "../../../models/IAdminCatalogueListingCardProps";
import { useAxios } from "../../../services/useAxios";
// import { productStatus } from "../../../utils/productUtils";
import { AdminCatalogueListingCard } from "../adminCatalogueListingCard/AdminCatalogueListingCard";
import styles from "./AdminCatalogueListingStyles.module.scss";
import { setPage } from "../../../reducerSlices/selectedPageSlice";
import headerCardStyles from "../../admin/supplierBusinessApprovalProcess/components/approvalCardSupplier/ApprovalCard.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import { useForm } from "react-hook-form";
import {
  ICategory,
  ICountry,
  IEqualTo,
  IOrderingOption,
  ISubCategory,
  ISubSubCategory,
} from "../../../models/IAddIndividualProductForm";
import Button from "../../../common/components/button/Button";
import useFetchCountries from "../../../services/useCountry";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import { resetToastStatus } from "../../../reducerSlices/toastStatusSlice";
import successToast from "../../../assets/images/successToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import axios, { CancelToken } from "axios";
import { setTab } from "../../../reducerSlices/selectedTabSlice";
// import placeholder from "../../../assets/images/placeholder.svg";
import adminDashboardStyles from "../adminDashboard/AdminDashboard.module.scss";
import { routeConfig } from "../../../common/constants/routeConfig";
// import { axiosAPI } from "../../../services/axiosInstance";
import { formatAdminCatalogueResponse } from "../../../utils/functions";

const AdminCatalogueListing = () => {
  const { countriesList } = useFetchCountries();
  const location = useLocation();
  const [showSort, setShowSort] = useState(false);
  const selectedPage: any = useSelector(
    (state: any) => state.selectedPage?.admincataloguePageNumber
  );
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [prevSelected, setPrevSelected] = useState(-1);
  const [finalActive, setFinalActive] =
    useState<IAdminCatalogueListingCardData[]>();
  const [searchText, setSearchText] = useState("");
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const { toastStatus } = useSelector((state: any) => state);
  const itemsPerPage = constants.paginationLimitPerPage;
  const myRef = useRef<null | HTMLTableElement>(null);
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.admin_manage_catalogue ?? 0
  );

  const [activeTab, setActiveTab] = useState(selectedTab);
  const [selectedBrand, setSelectedBrand] = useState<any>({});
  const [showFilter, setShowFilter] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>(
    constants.tempCategory
  );
  const [selectedSubcategory, setSelectedSubcategory] = useState<ISubCategory>(
    {}
  );
  const [selectedCategory, setSelectedCategory] = useState<ICategory>({});
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    ICategory[]
  >([]);
  const [selectedProductSection, setSelectedProductSection] = useState<
    ISubSubCategory[]
  >([]);
  const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
  const [selectedSection, setSelectedSection] = useState<ISubSubCategory>({});
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [selectedMeasuringUnit, setSelectedMeasuringUnit] =
    useState<IOrderingOption>({});
  const filtersRef = useRef<HTMLDivElement>(null);
  const filitersContainerRef = useRef<HTMLDivElement>(null);
  // get categories api call starts
  const getCategories = useAxios({
    axiosParams: {
      url: "store/categories",
    },
    method: "GET",
  });

  const getAllCategoriesList = useCallback(async () => {
    const response: any = await getCategories?.fetchData();
    if (response) {
      const updatedCategoriesData = response?.data?.categories?.map(
        (category: ICategory) => ({
          ...category,
          value: category.name,
          subcategories: category.sub_categories,
        })
      );
      const finalCategoreis = updatedCategoriesData?.filter(
        (item: ICategory) => item?.name !== "Offline Product Category"
      );
      setCategories(finalCategoreis);
    }
  }, [getCategories]);
  // ?page=1&q=orange&status=published
  const getCatlogueProducts = useAxios({
    axiosParams: {
      url: `${
        activeTab === 0
          ? adminEndPoints.getMasterProducts
          : activeTab === 1
          ? adminEndPoints.getSupplierProducts
          : "admin/offline-product"
      }?status=published&page=${currentPage}`,
    },
    method: APIMethods.get,
  });
  const activateDeactivateProduct = useAxios({ axiosParams: {} });
  const tabsData = [
    { id: 0, label: adminManageCatalogue?.masterProducts },
    { id: 1, label: adminManageCatalogue?.supplierProducts },
    { id: 2, label: adminManageCatalogue?.offlineProducts },
  ];

  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "admincataloguePageNumber", index: page }));
  };
  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  const [childCardData, setchildCardData] = useState<{
    id: number | string | undefined;
    show: boolean;
  }>({ id: undefined, show: false });

  const handleShowMenu = (
    index: number | string | undefined,
    show: boolean
  ) => {
    setchildCardData({ id: index, show: show });
  };

  const handleSort = () => {
    setShowSort(!showSort);
  };
  const handleSearch = (value: string, token?: CancelToken) => {
    setCurrentPage(1);
    dispatch(setPage({ type: "admincataloguePageNumber", index: 1 }));
    setSearchText(value);
    (value || value === "") && getCatlogueProductsList(value, token);
  };
  const sortOptions = [
    { id: 0, label: constants.ordersListing.WHATS_NEW, onClick: () => {} },
    { id: 1, label: constants.productListing.SORT_A_Z, onClick: () => {} },
    { id: 2, label: constants.productListing.SORT_Z_A, onClick: () => {} },
  ];

  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };

  const searchCustomStyles = {
    top: "0px",
  };

  const onCardClick = (id: string) => {
    window.location.pathname.includes("/admin/manage-catalogue")
      ? activeTab === 0
        ? navigate(`/admin/manage-catalogue/product-details/${id}`, {
            state: {
              masterProduct: true,
            },
          })
        : activeTab === 2
        ? navigate(`/admin/manage-catalogue/product-details/${id}`, {
            state: {
              offlineProduct: true,
            },
          })
        : navigate(`/admin/manage-catalogue/product-details/${id}`)
      : navigate(`/admin/products-approval/product-details/${id}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getImage = (item: any) => {
    let defaultImage = item?.find((item: any) => item?.metadata !== null);
    if (defaultImage) {
      return defaultImage?.url;
    } else {
      return item?.[0]?.url;
    }
  };
  const getCatalogueProductsOnSearch = async (
    searchQuery: string,
    tab: number
  ) => {
    const url: string =
      tab === 0
        ? adminEndPoints.getMasterProductsAlgolia
        : adminEndPoints.getSupplierAndOfflineProductsAlgolis;
    const body: any = {
      q: searchQuery,
      page: currentPage - 1,
      hitsPerPage: 10,
    };
    if (tab === 1) {
      body.product_type = "online";
    }
    if (tab === 2) {
      body.product_type = "offline";
    }
    const searchApiresponse = await axios
      .post(`${urlConstants?.BASE_URL}${url}`, body)
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error?.response?.data;
      });
    return searchApiresponse;
  };
  const [tabProductType, setTabProductType] = useState<any>("master");
  const getCatlogueProductsList = useCallback(
    async (searchTerm?: string, cancelToken?: CancelToken) => {
      dispatch(updateIsLoading(true));
      let response: any;
      if (searchTerm) {
        response = await getCatalogueProductsOnSearch(searchTerm, activeTab);
      } else {
        response = await getCatlogueProducts.fetchData();
      }
      if (
        response &&
        response?.data &&
        (searchTerm ? response?.data : response?.data?.products)
      ) {
        let data = searchTerm ? response?.data : response?.data?.products;
        data = formatAdminCatalogueResponse(data, searchTerm ? true : false);

        console.log(data, "data");

        setFinalActive(data);
        setTabProductType(
          activeTab === 0 ? "master" : activeTab === 1 ? "online" : "offline"
        );
        setTotalProductCount(
          searchTerm ? response?.count : response?.data?.count
        );
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(false));
      }
    },
    [activeTab, currentPage, searchText]
  );

  const getUrl = (productId: string) =>
    `store/catalogue/updateproduct/${productId}`;

  const handleActivationDeactivation = async (
    index: number,
    productId: string,
    isDeactivated: boolean
  ) => {
    dispatch(updateIsLoading(true));
    const response: any = await activateDeactivateProduct.fetchData({
      axiosParams: {
        url: getUrl(productId),
        data: { is_deactivated: !isDeactivated },
      },
      method: "POST",
    });
    if (response && response.status && response.status === "SUCCESS") {
      const mutatedData = finalActive || [];
      mutatedData[index].productStatus = !isDeactivated
        ? "Deactivated"
        : "Active";
      setFinalActive(mutatedData);
    }
    dispatch(updateIsLoading(false));
  };

  const handleMarkOutOfStock = async (
    index: number,
    productId: string,
    isAvailable: boolean
  ) => {
    dispatch(updateIsLoading(true));
    const response: any = await activateDeactivateProduct.fetchData({
      axiosParams: {
        url: getUrl(productId),
        data: { avaliabilty_status: !isAvailable },
      },
      method: "POST",
    });
    if (response && response.status && response.status === "SUCCESS") {
      const mutatedData = finalActive || [];
      mutatedData[index].isProductAvailable = !isAvailable;
      setFinalActive(mutatedData);
    }
    dispatch(updateIsLoading(false));
  };

  // useEffect(() => {
  //   getDebouncedData();
  //   return () => getDebouncedData.cancel();
  // }, [searchText]);

  const selectTab = (index: number) => {
    dispatch(setTab({ type: "admin_manage_catalogue", index }));
    setActiveTab(index);
    setCurrentPage(1);
  };
  // console.log(tabsData, "tabsData");

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const schema = yup.object({
    productBrand: yup.mixed(),
    category: yup.mixed(),
    subCategory: yup.mixed(),
    productSection: yup.mixed(),
    countryOrigin: yup.mixed(),
    baseUnitSize: yup.mixed(),
    paidCheckBox: yup.mixed(),
    unPaidCheckBox: yup.mixed(),
  });
  const confirmFilters = () => {
    setShowFilter(false);
  };
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const onChangeField = useCallback(
    (name: any) => (text: any) => {
      if (name === "countryName") setValue(name, text);
    },

    [setValue]
  );
  const handleBrandClick = (brandValue: any) => {
    setSelectedBrand(brandValue);
  };

  const handleCategoryClick = (categoryValue: ICategory) => {
    setSelectedCategory(categoryValue);
    setSelectedSubcategories([]);
    setSelectedProductSection([]);
  };

  const handleSubcategoryClick = (subcategoryValue: ISubCategory) => {
    if (!subcategoryValue) return;
    setSelectedSubcategory(subcategoryValue);
    const selectedSubcategoryObj = selectedSubcategories.find(
      (subcat: ICategory) => subcat.value === subcategoryValue?.value
    );
    if (selectedSubcategoryObj) {
      if (selectedSubcategoryObj?.productSection) {
        const updatedCategoriesData =
          selectedSubcategoryObj?.productSection?.map(
            (category: ISubCategory) => ({
              ...category,
              value: category?.name,
              productSection: selectedSubcategoryObj?.sub_sub_categories,
            })
          ) as ISubSubCategory[];
        setSelectedProductSection(updatedCategoriesData);
      } else {
        setSelectedProductSection([]);
      }
    } else {
      setSelectedProductSection([]);
    }
  };
  // get measuring units api call starts
  const measuringUnitsApi = useAxios({
    axiosParams: {
      url: "store/units",
    },
    method: "GET",
  });

  const getMeasuringUnits = useCallback(async () => {
    const response: any = await measuringUnitsApi?.fetchData();
    if (response) {
      const updatedMeasuringUnits = response?.data?.product_units?.map(
        (unit: IEqualTo) => ({
          ...unit,
          value: unit.name,
        })
      );
      setMeasuringUnits(updatedMeasuringUnits);
    }
  }, [measuringUnitsApi]);

  const handleReset = () => {
    setValue("productBrand", null);
    setValue("category", null);
    setValue("subCategory", null);
    setValue("productSection", null);
    setValue("countryOrigin", null);
    setValue("baseUnitSize", null);
    setValue("paidCheckBox", null);
    setValue("unPaidCheckBox", null);

    setSelectedBrand({});
    setSelectedCategory({});
    setSelectedSubcategory({});
    setSelectedSection({});
    setSelectedCountry({});
    setSelectedMeasuringUnit({});
    getCatlogueProductsList();
  };
  // useEffect(() => {
  //   getDebouncedData();
  //   return () => getDebouncedData.cancel();
  // }, [searchText]);
  useEffect(() => {
    getAllCategoriesList();
  }, []);
  useEffect(() => {
    myRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    if (
      toastStatus?.message?.length > 0 &&
      toastStatus?.type === "productCreation"
    ) {
      setToastData({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setToastData(constants.defaultToastData);
      }, 5000);
    }
    if (
      toastStatus?.message?.length > 0 &&
      toastStatus?.type === "productDeleted"
    ) {
      setToastData({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }, [dispatch, toastStatus]);
  useEffect(() => {
    getCatlogueProductsList(searchText);
  }, [activeTab, currentPage]);
  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryObj = categories?.find(
        (cat: ICategory) => cat.value === selectedCategory?.value
      );
      if (
        selectedCategoryObj &&
        selectedCategoryObj?.subcategories &&
        selectedCategoryObj?.subcategories?.length > 0
      ) {
        const updatedCategoriesData = selectedCategoryObj?.subcategories?.map(
          (category: ICategory) => ({
            ...category,
            value: category?.name,
            productSection: category?.sub_sub_categories,
          })
        );
        setSelectedSubcategories(updatedCategoriesData);
      } else {
        setSelectedSubcategories([]);
      }
    } else {
      setSelectedSubcategories([]);
    }
  }, [selectedCategory, categories]);
  useEffect(() => {
    getMeasuringUnits();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // console.log(activeTab, "searchout", selectedTab);
  return (
    <>
      {showPopup && (
        <PopupView
          heading={constants.productListing.ADD_NEW_SUPPLIER}
          childComponent={AddSupplierPopup}
          height={469}
          width={1116}
          handlePopup={handlePopup}
        />
      )}
      <div className={adminDashboardStyles.heading}>
        <div className={adminDashboardStyles.commonHeading}>
          Manage Catalogue
        </div>
        <div className={adminDashboardStyles.addForms}>
          <>
            <div className={styles.addNew}>
              <Button
                label={"Modify bulk product"}
                handleClick={() => {
                  navigate(routeConfig.adminModifyBulkProduct);
                }}
                width="160px"
                secondary
              />
            </div>
            {activeTab === 0 && (
              <Button
                handleClick={() => {
                  navigate(routeConfig.adminProductAddCards, {
                    state: { path: location.pathname },
                  });
                }}
                label="Add new"
                width="120px"
              />
            )}
          </>
        </div>
      </div>
      {tabsData.length > 0 && (
        <div className={styles.container}>
          <div className={styles.searchMain}>
            <div className={styles.tabs}>
              <div className={styles.tabsStyle}>
                <div className={styles.tabList}>
                  {tabsData.map((each) => (
                    <div
                      className={styles.tabListItem}
                      onClick={() => selectTab(each.id)}
                    >
                      <div
                        className={`${styles.label} ${
                          each.id === activeTab && styles.activeLabel
                        }`}
                      >
                        {each.label}
                      </div>
                      {activeTab === each.id && (
                        <div className={styles.border}></div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.hr}></div>
              </div>
              {(totalProductCount > 10 || searchText != "") && (
                <div className={`${styles.actions}`}>
                  <PageSearch
                    activeTab={activeTab}
                    handleSearch={handleSearch}
                    customStyles={searchCustomStyles}
                    customPlaceHolder="Search by product name or category or brand"
                  />
                  <div
                    className={`${styles.icon} ${styles.sort} ${styles.hide}`}
                    onClick={handleSort}
                    ref={sortContainerRef}
                  >
                    <img src={sortIcon} alt="sort"></img>
                    {showSort && (
                      <div className={styles.sortList}>
                        <div className={styles.sortBy}>
                          {constants.ordersListing.SORT_BY}
                        </div>
                        {sortOptions.map((option, index) => (
                          <div
                            className={`${styles.sortOption} ${
                              index === sortOptions.length - 1 &&
                              styles.lastOption
                            } ${
                              option.id === prevSelected && styles.selectedOpt
                            }`}
                            onClick={() => handleSortingOptions(option.id)}
                          >
                            {option?.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${styles.icon} ${styles.hide}`}
                    onClick={handleFilter}
                    ref={filtersRef}
                    data-testId="filterBtn"
                  >
                    <img src={filterIcon} alt="filter"></img>
                  </div>
                </div>
              )}
            </div>
          </div>
          {
            <div className={styles.tabsContent}>
              {finalActive !== undefined &&
                (finalActive?.length > 0 ? (
                  <div className={styles.tabslistContainer}>
                    <div className={`${styles.tableHeader}`}>
                      <div className={styles.details}>
                        <div
                          className={`${headerCardStyles.column} ${
                            styles.businessName
                          } ${styles.headerColor}
                       ${
                         tabsData[activeTab]?.label === "Master products" &&
                         styles.masterProductHeader
                       } 
                       `}
                        >
                          Product Name
                        </div>
                        {tabsData[activeTab]?.label !== "Master products" && (
                          <div
                            className={`${headerCardStyles.column} ${styles.businessName} ${styles.businessNameHeader}`}
                          >
                            {constants.buyersListHeaders.businessName}
                          </div>
                        )}
                        {/* <div
                         className={`${headerCardStyles.column} ${styles.headerColor} ${styles.contactName}`}
                       >
                         Watermelon product code
                       </div> */}
                        <div
                          className={`${headerCardStyles.column} ${styles.headerColor} ${styles.email}`}
                        >
                          Product brand
                        </div>
                        <div
                          className={`${headerCardStyles.column} ${styles.headerColor} ${styles.address}`}
                        >
                          Country Origin
                        </div>
                        <div
                          className={`${headerCardStyles.column} ${styles.headerColor}`}
                        >
                          Category
                        </div>
                      </div>
                    </div>
                    <table className={styles.ordersList} ref={myRef}>
                      {finalActive &&
                        finalActive.map((order: any, index: number) => (
                          <AdminCatalogueListingCard
                            tabLabel={tabsData[activeTab]?.label}
                            key={order.id}
                            id={order.id}
                            icon={order.icon}
                            productName={order.productName}
                            productStatus={order.productStatus}
                            productId={order.productId}
                            productQuantity={order.productQuantity}
                            businessName={order.businessName}
                            brandName={order.brandName}
                            countryFlag={order.countryFlag}
                            countryOrigin={order.countryOrigin}
                            productSubCategory={order.productSubCategory}
                            productCategory={order.productCategory}
                            productCardState={order.productCardState}
                            onCardClick={() => onCardClick(order?.id)}
                            showMenu={childCardData}
                            handleShowMenu={handleShowMenu}
                            iamgeUrl={order.imageUrl}
                            isProductAvailable={order.isProductAvailable}
                            handleActivationDeactivation={() =>
                              handleActivationDeactivation(
                                index,
                                order.id,
                                order.productStatus === "Deactivated"
                              )
                            }
                            handleMarkOutOfStock={() =>
                              handleMarkOutOfStock(
                                index,
                                order.id,
                                order.isProductAvailable
                              )
                            }
                            setFinalActive={setFinalActive}
                            finalActive={finalActive}
                            tabProductType={tabProductType}
                          />
                        ))}
                    </table>
                    <div className={styles.paginationContainer}>
                      <PaginationComponent
                        count={
                          searchText
                            ? Math.ceil(totalProductCount / itemsPerPage - 1)
                            : Math.ceil(totalProductCount / itemsPerPage)
                        }
                        page={currentPage}
                        handlePagination={handlePagination}
                        showFirstButton={true}
                        showLastButton={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyContainer}>
                    <EmptyContainer title={"Woah!"} subText={"No products"} />
                  </div>
                ))}
            </div>
          }
        </div>
      )}
      {showFilter && (
        <div className={styles.overlay}>
          <div className={styles.filters} ref={filitersContainerRef}>
            <div className={styles.header}>
              <div data-teId="heading" className={styles.filtersHeading}>
                Filters
              </div>
              <div className={styles.cancelFilter} onClick={handleFilter}>
                <img src={cancel} alt="cancel"></img>
              </div>
            </div>
            <form
              className={styles.form}
              onSubmit={handleSubmit(confirmFilters)}
            >
              <div className={styles.formInputs}>
                <div className={styles.payment}>
                  <div className={styles.paymentLabel}>Status</div>
                  <div className={styles.paymentInput}>
                    <div className={styles.paid}>
                      <CheckboxButton
                        customClass={styles.checkboxField}
                        customLabel={styles.checkboxLabel}
                        register={register("paidCheckBox")}
                        handleChange={() => {
                          onChangeField("paidCheckBox");
                        }}
                        label="Active"
                      />
                    </div>
                    <div className={styles.paid}>
                      <CheckboxButton
                        customClass={styles.checkboxField}
                        customLabel={styles.checkboxLabel}
                        register={register("unPaidCheckBox")}
                        handleChange={() => {
                          onChangeField("unPaidCheckBox");
                        }}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Dropdown
                    minWidth="400px"
                    label={"Product brand"}
                    register={register("productBrand")}
                    selectedValue={selectedBrand}
                    options={constants.foodBrands}
                    defaultLabel={selectedBrand?.label ?? "Select the brand"}
                    id={"0"}
                    onChange={(selectedOption: any) => {
                      onChangeField("productBrand");
                      handleBrandClick(selectedOption);
                    }}
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    label={"Category"}
                    options={categories}
                    defaultLabel={
                      selectedCategory?.name ?? "Select the category"
                    }
                    id={"1"}
                    register={register("category")}
                    onChange={(selectedOption: ICategory) => {
                      onChangeField("category")(selectedOption);
                      handleCategoryClick(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    label={"Subcategory"}
                    selectedValue={selectedSubcategory}
                    options={selectedSubcategories}
                    defaultLabel={
                      selectedSubcategory?.name ?? "Select the subcategory"
                    }
                    id={"2"}
                    onChange={(selectedOption: ISubCategory) => {
                      onChangeField("subCategory")(selectedOption);
                      handleSubcategoryClick(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedSection}
                    label={"Subsubcategory"}
                    defaultLabel={
                      selectedSection?.name ?? "Select the Sub subcategory"
                    }
                    id={"2"}
                    options={selectedProductSection}
                    register={register("subCategory")}
                    onChange={(selectedOption: ISubSubCategory) => {
                      onChangeField("productSection")(selectedOption);
                      setSelectedSection(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedCountry}
                    label={"Country origin"}
                    options={countriesList}
                    defaultLabel={
                      selectedCountry?.name ?? "Select the country origin"
                    }
                    id={"3"}
                    register={register("countryOrigin")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("productCountryOrigin")(selectedOption);
                      setSelectedCountry(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
                <div>
                  <Dropdown
                    selectedValue={selectedMeasuringUnit}
                    label={"Base unit size"}
                    options={measuringUnits}
                    defaultLabel={
                      selectedMeasuringUnit?.name ?? "Select the size"
                    }
                    id={"4"}
                    register={register("baseUnitSize")}
                    onChange={(selectedOption: any) => {
                      onChangeField("baseUnitSize");
                      setSelectedMeasuringUnit(selectedOption);
                    }}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    disablePortal={true}
                  />
                </div>
              </div>
              <div className={styles.filterActions}>
                <div className={styles.reset} onClick={handleReset}>
                  Reset
                </div>
                <div>
                  <Button
                    label={"Cancel"}
                    handleClick={handleFilter}
                    width="120px"
                    secondary={true}
                  />
                </div>
                <div>
                  <Button
                    label={"Confirm"}
                    handleClick={handleSubmit(confirmFilters)}
                    width="120px"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {toastData?.message ? (
        <ToastNotification
          icon={toastData?.status === "failure" ? errorToast : successToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminCatalogueListing;

// console.log("===========searchApiResponse==================");
// console.log("=====", searchApiResponse);
// console.log("=============================");
// if (searchApiResponse?.status === "SUCCESS") {
// }
// response = await getCatlogueProducts.fetchData({
//   axiosParams: {
//     url: `${
//       activeTab === 0
//         ? adminEndPoints.getMasterProducts
//         : activeTab === 1
//         ? adminEndPoints.getSupplierProducts
//         : "admin/offline-product"
//     }?status=published&page=${currentPage}&q=${searchTerm}`,
//     cancelToken,
//   },
//   method: APIMethods.get,
// });
// console.log("&&&&&,", response);

// data.forEach((ele: any) => {
//   ele["icon"] = ele?.image_url;
//   ele["productName"] = ele?.title;
//   ele["status"] = productStatus(ele?.product_status);
//   ele["productStatus"] = ele?.is_deactivated ? "Deactivated" : "Active";
//   ele["id"] = ele?.id;
//   ele["productId"] = ele?.id;
//   ele["productQuantity"] = ele?.product_variant_metadata?.selected_unit;
//   ele["brandName"] = ele?.brand?.name ?? "-";
//   ele["countryFlag"] = ele?.origin_country?.flag;
//   // ele["countryOrigin"] = ele?.country_flag;
//   ele["countryOrigin"] = ele?.origin_country?.name ?? "-";
//   ele["productSubCategory"] =
//     ele?.product_sub_sub_category?.name ?? "-";
//   ele["productCategory"] = ele?.product_category?.name;
//   ele["productCardState"] = ele?.product_status;
//   ele["isProductAvailable"] = ele?.product_avaliabilty_status;
//   ele["imageUrl"] = getImage(ele?.images) ?? placeholder; // showing  default img , if img is not provided
//   ele["businessName"] = ele?.supplier?.business_name ?? "-";
// });
