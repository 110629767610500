import { IAdminOrdersListingCardProps } from "../../../models/IAdminOrdersListingCardProps";
import styles from "./AdminOrdersListingCardStyles.module.scss";

export const AdminOrdersListingCard = (props: IAdminOrdersListingCardProps) => {
  const {
    orderId,
    buyerName,
    icon,
    orderItems,
    orderValue,
    paidStatus,
    placedOn,
    actualAmount,
    deliveryStatus,
    toBeDelivered,
    area,
    address,
    onCardClick,
    type,
    activeTab,
    orderType,
    deliveredDate,
  } = props;

  return (
    <>
      <div className={styles.card} onClick={onCardClick}>
        <div className={styles.orderInfo}>
          <div className={styles.icon}>
            <img src={icon} alt="icon" />
          </div>
          <div
            className={`${styles.buyerName} ${styles.flex} ${styles.flexColumn}`}
          >
            <div className={`${styles.flex}`}>
              <div className={styles.orderID}>{orderId}</div>
              {orderType === "Offline" && (
                <div className={styles.offlineOrder}>Offline</div>
              )}
            </div>
            <div className={styles.commonTxtStyle}>{buyerName}</div>
          </div>
        </div>
        <div className={styles.amount}>
          <div className={styles.commonTxtStyle}>
            {actualAmount && actualAmount > 0 ? `AED ${actualAmount}` : "-"}
          </div>
          {actualAmount && actualAmount > 0 && (
            <div className={styles.quantity}>
              <div className={styles.commonTxtStyle}>{orderItems} items</div>
              <div className={styles.commonTxtStyle}>|</div>
              <div className={styles.commonTxtStyle}>{paidStatus}</div>
            </div>
          )}
        </div>
        <div className={styles.amount}>
          <div className={styles.commonTxtStyle}>
            {orderValue && orderValue > 0 ? `AED ${orderValue}` : "-"}
          </div>

          <div className={styles.quantity}>
            <div className={styles.commonTxtStyle}>{orderItems} items</div>
            <div className={styles.commonTxtStyle}>|</div>
            <div className={styles.commonTxtStyle}>{paidStatus}</div>
          </div>
        </div>
        <div className={styles.area}>
          <div className={styles.commonTxtStyle}>{area}</div>
          <div className={styles.commonTxtStyle}>{address}</div>
        </div>
        <div className={styles.placedInfo}>
          <div className={styles.commonTxtStyle}>{placedOn}</div>
          <div className={styles.commonTxtStyle}>Placed on</div>
        </div>
        {Number(activeTab) !== 0 && (
          <>
            {type != "completed" &&
            (deliveryStatus === "On time" || deliveryStatus === "Delayed") ? (
              <div className={styles.deliveryStatus}>
                <div className={styles.hr}></div>
                <div
                  className={
                    deliveryStatus === "On time"
                      ? styles.onTime
                      : styles.delayed
                  }
                >
                  {deliveryStatus}
                </div>
                <div className={styles.hr}></div>
              </div>
            ) : (
              <div className={styles.deliveryStatus}>
                <div className={styles.hr}></div>
                {deliveryStatus === "not_fulfilled" && (
                  <div
                    className={
                      deliveryStatus === "not_fulfilled" ? styles.delayed : ""
                    }
                  >
                    Modified
                  </div>
                )}

                <div className={styles.hr}></div>
              </div>
            )}
            <div className={styles.deliveryInfo}>
              {deliveredDate ? (
                <>
                  <div className={styles.commonTxtStyle}>{deliveredDate}</div>
                  <div className={styles.commonTxtStyle}>Delivered</div>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <div className={styles.commonTxtStyle}>{toBeDelivered}</div>
                  <div className={styles.commonTxtStyle}>To be delivered</div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
