import { createSlice } from "@reduxjs/toolkit";

const adminAddBuyerSlice = createSlice({
  name: "adminAddBuyer",
  initialState: {},
  reducers: {
    setSelectedSuggestedItem: (state, action) => {
      return {
        ...state,
        ...action.payload,
        business_id: action.payload?.business_id,
        businessName: action.payload?.business_name,
        emailID: action.payload?.business_email,
        licenseNo: action.payload?.license_number,
        contactName: action.payload?.contact_name,
        phNo: action.payload?.business_phone,
        countryName: action.payload?.country,
        vatNo: action.payload?.vat_number,
        address: action.payload?.address,
        emirates: action.payload?.emirate,
        country: action.payload?.country,
        licenseFile: action.payload?.license_file,
        vatFile: action.payload?.vat_file,
        area: action.payload?.area,
        password: action.payload?.password,
        confirmPassword: action.payload?.confirm_password,
        userType: action.payload?.user_type,
        maxOrderLimit: action.payload?.max_order_limit,
      };
    },
    // clearSelectedSuggestedItem: (state) => {
    //   return null;
    // },
  },
});

export const { setSelectedSuggestedItem } = adminAddBuyerSlice.actions;

export default adminAddBuyerSlice.reducer;
