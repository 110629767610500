import styles from "./Report.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from "../../components/formFields/dropdown/dropdown";
import dropDownIcon from "../../../assets/images/icons-home-dollar.svg";
import RangeDatePicker from "../../components/rangeDatePicker/RangeDatePicker";
import totalOrders from "../../../assets/images/totalOrders.svg";
import totalAmount from "../../../assets/images/TotalAmount.svg";
import downloadIcon from "../../../assets/images/download.svg";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import acceptedIcon from "../../../assets/images/Confirmed.svg";
import orderReviewed from "../../../assets/images/Order-review-recieved.svg";
import deliveredIcon from "../../../assets/images/Delivered.svg";
import progressingIcon from "../../../assets/images/Progressing.svg";
import recievedIcon from "../../../assets/images/Returned.svg";
import shippedIcon from "../../../assets/images/On-the-way.svg";
import PaginationComponent from "../../components/pagination/Pagination";
import { useAxios } from "../../../services/useAxios";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { APIMethods } from "../../constants/urlConstants";
import { ReportsData } from "../../../models/IReports";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { convertTimeStampToUserTimeZone } from "../../../utils/functions";
const Report = () => {
  // const date = new Date();
  const [startChanged, setStartChanged] = useState(false);
  const [endChanged, setEndChanged] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [reportData, setReportData] = useState<ReportsData[]>([]);
  const [startDate, setStartDate] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [endDate, setEndDate] = useState<any>();
  const dispatch = useDispatch();
  const [errorState, setErrorState] = useState<string>("");
  const itemsPerPage = 10;
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [currentReportsData, setCurrentReportsData] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState(
    loggedInUser?.typeOfUser.includes("admin")
  );
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [userType, setUserType] = useState<any>(null);
  const [buisnesses, setBuisnesses] = useState<null | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const downloadURLRef = useRef("");
  const [businessId, setBusinessId] = useState(
    loggedInUser?.business_details &&
      loggedInUser?.business_details?.[0]?.business_store_id
  );
  // const allWarehousesOptions: HeaderDropdownOptions[] = [
  //   {
  //     id: 1,
  //     label: "All Warehouses",
  //     value: "all warehouses",
  //     name: "All Warehouses",
  //   },
  //   {
  //     id: 2,
  //     label: "India",
  //     value: "india",
  //     name: "India",
  //   },
  //   {
  //     id: 3,
  //     label: "USA",
  //     value: "usa",
  //     name: "USA",
  //   },
  //   {
  //     id: 4,
  //     label: "UK",
  //     value: "uk",
  //     name: "UK",
  //   },
  // ];

  // const allOutletsOptions: HeaderDropdownOptions[] = [
  //   {
  //     id: 1,
  //     label: "All Outlets",
  //     value: "all outlets",
  //     name: "All Outlets",
  //   },
  //   {
  //     id: 2,
  //     label: "India",
  //     value: "india",
  //     name: "India",
  //   },
  //   {
  //     id: 3,
  //     label: "USA",
  //     value: "usa",
  //     name: "USA",
  //   },
  //   {
  //     id: 4,
  //     label: "UK",
  //     value: "uk",
  //     name: "UK",
  //   },
  // ];

  function getReportsUrl({ isDownload }: { isDownload?: boolean }) {
    let newUrl = `store/reports/?page=${
      isDownload ? 0 : currentPage
    }&start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(
      endDate
    )}&type=${userType?.value === "buyer" ? "buyer" : "supplier"}`;

    if (userType?.value === "buyer") {
      if (selectedBusiness && selectedBusiness?.id) {
        newUrl += `&buyer_id=${selectedBusiness?.id}`;
      }

      if (selectedBusiness && selectedBusiness?.id && businessId) {
        newUrl += `&outlet_id=${businessId}`;
      }
    } else {
      if (selectedBusiness && selectedBusiness?.id) {
        newUrl += `&supplier_id=${selectedBusiness?.id}`;
      }

      if (selectedBusiness && selectedBusiness?.id && businessId) {
        newUrl += `&ware_house_id=${businessId}`;
      }
    }

    if (isDownload) {
      newUrl += `&is_download=true`;
    }
    return newUrl;
  }
  useEffect(() => {
    if (loggedInUser?.typeOfUser) {
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, []);

  const getIcon = (status: string) => {
    switch (status) {
      case "placed":
        return recievedIcon;
      case "completed":
        return orderReviewed;
      case "delivered":
        return deliveredIcon;
      case "Pending":
        return progressingIcon;
      case "confirmed":
        return acceptedIcon;
      case "shipped":
        return shippedIcon;
      case "canceled":
        return orderReviewed;
      case "modified":
        return progressingIcon;

      default:
        return null;
    }
  };

  const schema = yup.object(
    isAdmin
      ? {
          // userTypeDropdown: yup.string().required("Please select user type"),
        }
      : {
          businessDropdown: yup.string().optional().nullable(),
        }
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    // setValue,
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  function getFormattedDate(date: Date) {
    if (date) {
      const adjustedDate = new Date(date.toLocaleString());
      adjustedDate.setDate(adjustedDate.getDate() + 1);
      return adjustedDate && adjustedDate?.toISOString();
    }
  }
  const getwarehouseReport = useAxios({
    axiosParams: {
      url: isAdmin
        ? getReportsUrl({})
        : `store/reports/${
            userType?.value === "buyer" ? "outlet" : "ware-house"
          }/${businessId}?start_date=${getFormattedDate(
            startDate
          )}&end_date=${getFormattedDate(endDate)}&page=${currentPage}`,
    },
    method: APIMethods.get,
  });

  const downloadReports = useAxios({
    axiosParams: {
      url: isAdmin
        ? `${getReportsUrl({ isDownload: true })}`
        : `store/reports/${
            userType?.value === "buyer" ? "outlet" : "ware-house"
          }-download/${businessId}?start_date=${getFormattedDate(
            startDate
          )}&end_date=${getFormattedDate(endDate)}&page=${0}&email=null`,
    },
    method: APIMethods.get,
  });


// Function to download the report in the form of csv file.
  const getDownloadData = useCallback(async () => {
    if (loggedInUser) {
      dispatch(updateIsLoading(true));
      let response: any;
      if (isAdmin) {
        response = await downloadReports.fetchData({
          axiosParams: {
            url: downloadURLRef.current,
          },
        });
      } else {
        response = await downloadReports.fetchData();
      }
      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const href = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = href;
        anchor.download = "reports.csv";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(href);
        dispatch(updateIsLoading(false));
      }
    }
  }, [downloadReports]);

  const getReport = useCallback(async () => {
    if (loggedInUser) {
      downloadURLRef.current = getReportsUrl({ isDownload: true });
      setIsLoading(true);
      const response: any = await getwarehouseReport.fetchData();
      setIsLoading(false);
      if (response && response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        const reportData = response?.data;
        setCurrentReportsData(reportData);
        const massagedData = reportData?.reports?.map(
          (element: any, index: number) => {
            let companyName = element?.company_name;
            let outletName = element?.outlet_name;
            let supplierName = element?.ware_house_name;
            if (isAdmin) {
              companyName =
                userType?.value === "buyer"
                  ? element.supplier_business_name
                  : element.buyer_business_name;
              outletName =
                userType?.value === "buyer"
                  ? element.outlet_name
                  : element.ware_house_name;
              supplierName = element?.supplier_contact_name;
            }
            return {
              id: index,
              orderId: element?.order_number ?? "-",
              orderDate:
                convertTimeStampToUserTimeZone(element?.order_date) ?? "-",
              Outer: outletName
                ? `${outletName?.charAt(0).toUpperCase()}${outletName?.slice(
                    1
                  )}`
                : "-",
              company: companyName
                ? companyName?.charAt(0).toUpperCase() + companyName?.slice(1)
                : "-",
              supplierName: supplierName
                ? `${supplierName
                    ?.charAt(0)
                    .toUpperCase()}${supplierName?.slice(1)}`
                : "-",
              quantity: element?.delivered_quantity
                ? element?.delivered_quantity
                : element?.quantity,
              category: element?.category ?? "-",
              subCategory: element?.sub_category ?? "-",
              product: element?.product ?? "-",
              uom: element?.metadata?.ordering_option_label ?? "-",
              unitPrice: `${Number(element?.unit_price)?.toFixed(2)} AED`,
              sku:
                `${element?.metadata?.ordering_option_value} x ${element?.metadata?.secondary_ordering_option_value} ${element?.metadata?.secondary_ordering_option_label}` ??
                "-",
              status: {
                icon: getIcon(element?.status),
                text:
                  element?.status?.charAt(0).toUpperCase() +
                    element?.status?.slice(1) ?? "-",
              },
              totalPrice: `${Number(element?.total_price)?.toFixed(2)} AED`,
            };
          }
        );
        setListCount(reportData?.count);
        setReportData(massagedData);
      }
    }
  }, [getwarehouseReport]);

  //Column schema for the report grid 
  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
          <img src={params.row.status.icon} alt="status icon" />
          <span>{params.row.status.text}</span>
        </div>
      ),
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Outer",
      headerName: userType?.value === "buyer" ? "Outlet name" : "Warehouse",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "company",
      headerName: "Company",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "supplierName",
      headerName: "Supplier name",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "subCategory",
      headerName: "Sub category",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "product",
      headerName: "Product",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "uom",
      headerName: "UOM",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "totalPrice",
      headerName: "Total price",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
    },
  ];

  //function to handle pagination for the report grid
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  // const [warehouse, setWarehouse] = useState<HeaderDropdownOptions | null>();
  const [outlets, setOutlets] = useState<[] | null>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<null | any>(null);
  // const handleDropdownChange = (id: string, value: HeaderDropdownOptions) => {
  //   if (id === "businessDropdown") {
  //     setWarehouse(value);
  //     setValue("businessDropdown", value?.value);
  //     setOutlets(null);
  //     setValue("allOutletDropdown", "");
  //   } else {
  //     setOutlets(value);
  //     setValue("allOutletDropdown", value?.value);
  //     setValue("businessDropdown", "");
  //     setWarehouse(null);
  //   }
  // };

  // function to handle start date for the report 
  const handleStartDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      const adjustedDate = new Date(selectedDate.toLocaleString());
      adjustedDate.setDate(adjustedDate.getDate() + 1);
      setStartDate(selectedDate);
      setStartChanged(true);
      setErrorState("");
    } else {
      setStartDate(null);
    }
  };

  // function to handle the end date while generating report
  const handleEndDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      const adjustedDate = new Date(selectedDate.toLocaleString());
      adjustedDate.setDate(adjustedDate.getDate() + 1);
      setEndDate(selectedDate);
      setEndChanged(true);
      setErrorState("");
    } else {
      setEndDate(null);
    }
  };

  //function to submit the form data  which will set the current page and call the function to validate the dates selected
  //This function will generate the report on submit
  const formSubmit = (data: any) => {
    setCurrentPage(1);
    handleDateVallidation();
  };

  // function to handle validation of the selected dates
  const handleDateVallidation = () => {
    if (!startDate || !endDate) {
      setErrorState("Please Select the dates");
      return false;
    } else if (dayjs(endDate).isBefore(startDate, "day")) {
      setErrorState("The end date must be greater than start date");
      return false;
    } else if (!errorState) {
      getReport();
      setErrorState("");
      return true;
    }
  };

  useEffect(() => {
    if (startChanged || endChanged) {
      const table = document.querySelector(".MuiDataGrid-virtualScroller");
      if (table) {
        table.scrollTop = 0;
        table.scrollLeft = 0;
      }
      handleDateVallidation();
    }
  }, [currentPage, errors]);

  //This function is responsible for validating dates and downloading the report as csv file
  const handleDownloadReport = () => {
    if (!startChanged || !endChanged) {
      setErrorState("Please Select the dates");
    } else if (endDate < startDate) {
      setErrorState("The end date must not be lesser than the start date");
    } else if (currentReportsData && currentReportsData?.reports?.length > 0) {
      getDownloadData();
      setErrorState("");
    }
  };

  //function to handle the usertype selected from the dropdown onchange
  const handleChangeUserType = async (selectedOption: any) => {
    setUserType(selectedOption);
    setStartDate(null);
    setEndDate(null);
    setSelectedOutlet(null);
    setSelectedBusiness(null);
  };

  //api to get the list of business for the selected user type - Admin
  const getAdminBusinesses = useAxios({
    axiosParams: {
      url: `store/${userType && userType?.value}?page=0`,
    },
    method: APIMethods.get,
  });

  //api to get list of buyer business details - Supplier
  const getUserBusinesses = useAxios({
    axiosParams: {
      url: `store/supplier/${
        loggedInUser &&
        loggedInUser?.business_details &&
        loggedInUser?.business_details?.[0]?.business_setup_id
      }`,
    },
    method: APIMethods.get,
  });

  const getBusinessesData = useCallback(async () => {
    if (loggedInUser) {
      dispatch(updateIsLoading(true));
      const response: any = isAdmin && (await getAdminBusinesses.fetchData());
      dispatch(updateIsLoading(false));
      if (response && response?.status === "SUCCESS") {
        setErrorState("");
        const businessData = response?.data;
        if (businessData && businessData?.length > 0) {
          const updatedBusiness = businessData?.map((element: any) => {
            return {
              ...element,
              id: element?.id,
              label: element?.business_name,
              value: element?.business_name,
              name: element?.business_name,
              outlets: element?.business_stores,
            };
          });
          if (updatedBusiness.length > 0) {
            setBuisnesses(updatedBusiness);
          }
        }
      }
    }
  }, [getAdminBusinesses]);

  useEffect(() => {
    if (isAdmin && userType) {
      getBusinessesData();
    }
  }, [userType]);

  const getUserBusinessesData = useCallback(async () => {
    if (loggedInUser) {
      const response: any = !isAdmin && (await getUserBusinesses.fetchData());
      if (response && response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        const businessData = [response?.data] as any;
        if (businessData) {
          const updatedBusiness = businessData?.map((element: any) => {
            return {
              ...element,
              id: element?.id,
              label: element?.business_name,
              value: element?.business_name,
              name: element?.business_name,
              outlets: element?.business_stores,
            };
          });
          if (updatedBusiness.length > 0) {
            setBuisnesses(updatedBusiness);
          }
        }
      }
    }
  }, [getUserBusinesses]);

  useEffect(() => {
    if (!isAdmin) {
      getUserBusinessesData();
    }
  }, []);

  useEffect(() => {
    if (isAdmin && selectedBusiness) {
      const updatedOutlets = selectedBusiness?.outlets?.map((element: any) => {
        return {
          ...element,
          id: element?.id,
          label: element?.saved_as,
          value: element?.saved_as,
          name: element?.saved_as,
        };
      });
      setOutlets(updatedOutlets);
    } else if (!isAdmin && selectedBusiness) {
      const updatedOutlets = selectedBusiness?.outlets?.map((element: any) => {
        return {
          ...element,
          id: element?.id,
          label: element?.saved_as,
          value: element?.saved_as,
          name: element?.saved_as,
        };
      });
      setOutlets(updatedOutlets);
    }
  }, [selectedBusiness]);

  useEffect(() => {
    // setSelectedBusiness(null);
    // setSelectedOutlet(null);
  }, [userType]);
  useEffect(() => {
    // setSelectedOutlet(null);
  }, [selectedBusiness]);
  const isInvalidDateRange =
    startChanged &&
    endChanged &&
    new Date(endDate)?.getTime() < new Date(startDate)?.getTime();
  return (
    <>
      <div className={styles.reportContainer}>
        <div className={styles.heading}>
          Spendings Per {""}
          {loggedInUser?.typeOfUser &&
            loggedInUser?.typeOfUser[0] === "supplier" &&
            "Warehouse"}
          {loggedInUser?.typeOfUser &&
            loggedInUser?.typeOfUser[0] === "buyer" &&
            "Outlet"}
          {loggedInUser?.typeOfUser &&
            loggedInUser?.typeOfUser[0] === "admin" &&
            "Outlets & Warehouse"}
        </div>
        <section className={styles.tableFilterContainer}>
          <div className={styles.filterText}>
            Select the{" "}
            {loggedInUser?.typeOfUser &&
              loggedInUser?.typeOfUser[0] === "supplier" &&
              "Warehouse"}
            {loggedInUser?.typeOfUser &&
              loggedInUser?.typeOfUser[0] === "buyer" &&
              "Outlet"}
            {loggedInUser?.typeOfUser &&
              loggedInUser?.typeOfUser[0] === "admin" &&
              "Outlets & Warehouse"}{" "}
            and date range to generate report
          </div>
          <form className={styles.filteSection}>
            {isAdmin && (
              <Dropdown
                id="userTypeDropdown"
                icon={dropDownIcon}
                boxShadow={false}
                selectedValue={userType}
                register={register("userTypeDropdown")}
                onChange={handleChangeUserType}
                minWidth="170px"
                margin="0px"
                label=""
                defaultLabel={userType?.name ?? "Select User Type"}
                options={[
                  {
                    id: 1,
                    label: "Buyers",
                    value: "buyer",
                    name: "Buyers",
                  },
                  {
                    id: 2,
                    label: "Suppliers",
                    value: "supplier",
                    name: "Suppliers",
                  },
                ]}
                isDropdownOutlineRequired={true}
                customClass={styles.customDropdownClass}
                customDropdownStyles={styles.dropDownStyles}
                editForm={!isAdmin}
              />
            )}
            <Dropdown
              id="businessDropdown"
              icon={dropDownIcon}
              defaultValue={selectedBusiness}
              boxShadow={false}
              selectedValue={selectedBusiness}
              register={register("businessDropdown")}
              onChange={(selectedOption: any) => {
                // handleDropdownChange("businessDropdown", selectedOption);
                setSelectedBusiness(selectedOption);
                setStartDate(null);
                setEndDate(null);
                setSelectedOutlet(null);
                if (isAdmin) {
                  // const currBusinessStoreId =
                  //   selectedOption?.business_stores.length === 1
                  //     ? selectedOption?.business_stores[0].id
                  //     : selectedOption?.business_stores.filter((store:any) => store.is_default)?.[0]?.id;
                  // currBusinessStoreId && setBusinessId(currBusinessStoreId);
                }
              }}
              isOutlets
              minWidth="165px"
              margin="0px"
              label=""
              defaultLabel={"Business"}
              options={buisnesses}
              isDropdownOutlineRequired={true}
              customClass={styles.customDropdownClass}
              customDropdownStyles={styles.dropDownStyles}
              // editForm={!isAdmin}
            />

            <Dropdown
              id="allOutletDropdown"
              icon={dropDownIcon}
              register={register("allOutletDropdown")}
              boxShadow={false}
              selectedValue={selectedOutlet}
              onChange={
                (selectedOption: any) => {
                  setSelectedOutlet(selectedOption);
                  if (isAdmin) {
                    setBusinessId(selectedOption?.id);
                  }
                }

                // handleDropdownChange("allOutletDropdown", selectedOption)
              }
              minWidth="190px"
              margin="0px"
              label=""
              options={outlets}
              defaultLabel={
                (loggedInUser?.typeOfUser &&
                  loggedInUser?.typeOfUser[0] === "buyer" &&
                  "Outlet") ||
                (loggedInUser?.typeOfUser &&
                  loggedInUser?.typeOfUser[0] === "supplier" &&
                  "Warehouse") ||
                (loggedInUser?.typeOfUser &&
                  loggedInUser?.typeOfUser[0] === "admin" &&
                  "Outlet/Warehouse")
              }
              isOutlets
              isDropdownOutlineRequired={true}
              customClass={styles.customDropdownClass}
              customDropdownStyles={styles.dropDownStyles}
            />
            <div className={styles.divider}></div>
            {console.log(outlets, "startDate")}
            <div className={styles.date}>
              <RangeDatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                startPlaceholder="Select from date"
                endPlaceholder="Select to date"
                endDate={endDate}
                setEndDate={setEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                isInvalidDateRange={isInvalidDateRange}
                customClass="datePickerCustom"
                disablePast={false}
                startDateDisbaleFuture={true}
                endDateDisbaleFuture={true}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.generateButton}
                onClick={handleSubmit(formSubmit)}
              >
                Generate Report
              </button>
            </div>
            {errorState && (
              <div className={styles.errorMessage}>{errorState}</div>
            )}
          </form>

          <div className={styles.reportInfoContainer}>
            <div className={styles.reportInfo}>
              <div className={styles.totalOrders}>
                <img src={totalOrders} alt="total orders" />
                <div>
                  <div className={styles.typography1}>
                    {currentReportsData && currentReportsData?.count
                      ? currentReportsData?.count
                      : "0"}
                  </div>
                  <div className={styles.typography2}>Total no of orders</div>
                </div>
              </div>
              <div className={styles.totalAmount}>
                <img src={totalAmount} alt="total orders" />
                <div>
                  <div className={styles.typography1}>
                    {currentReportsData && currentReportsData?.sub_total
                      ? `${Number(currentReportsData?.sub_total).toFixed(
                          2
                        )} AED`
                      : "0.00"}
                    <span className={styles.typography2}></span>
                  </div>
                  <div className={styles.typography2}>
                    Total without VAT & Delivery
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.downloaLink}>
              {reportData?.length > 0 && (
                <div
                  className={`${styles.typography2} ${styles.downloadText}`}
                  onClick={handleDownloadReport}
                >
                  <span className={styles.downloadIcon}>
                    <img src={downloadIcon} alt="download" />
                  </span>{" "}
                  Download report
                </div>
              )}
            </div>
          </div>
        </section>
        <section className={styles.tableContainer}>
          <DataGrid
            rows={reportData}
            columns={columns}
            hideFooterPagination
            hideFooter
            loading={isLoading}
            sx={{
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
          />
          <div className={styles.paginationContainer}>
            <PaginationComponent
              count={Math.ceil(listCount / itemsPerPage)}
              handlePagination={handlePagination}
              page={currentPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </div>
        </section>
      </div>
    </>
  );
};
export default Report;
