import React, { useState, useRef, useEffect, useCallback } from "react";
import PageFilter from "../../../common/components/pageFilter/PageFilter";
import PageSearch from "../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import styles from "./InVoices.module.scss";
import { InvoiceCard } from "./components/invoiceCard/InvoiceCard";
import { useAxios } from "../../../services/useAxios";
import {
  APIMethods,
  adminEndPoints,
  // buyerEndpoints,
  userEndpoints,
} from "../../../common/constants/urlConstants";
import { addInvoiceList } from "../../../reducerSlices/supplierInvoiceList";
import { useDispatch, useSelector } from "react-redux";
import { EmptyContainer } from "../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import {
  buyerAPIEndpoints,
  constants,
} from "../../../common/constants/constants";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import { SideDrawer } from "../../../common/components/SideDrawer/SideDrawer";
import filterIcon from "../../../assets/images/ordersListing/Filter.svg";
import { setPage } from "../../../reducerSlices/selectedPageSlice";
import { setFilterData } from "../../../reducerSlices/filtersDataSlice";
// import MigratedInvoiceCard from "./components/invoiceCard/MigratedInvoiceCard";
const InVoicesPage = () => {
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.supplierInvoicesPageNumber
  );
  const filtrationKeys = useSelector((state: any) => state?.filteredKeys);
  const dispatch = useDispatch();
  const { supplierInvoiceList } = useSelector((state: any) => state);
  const userData: any = localStorage.getItem("loggedInUser");
  const scrollTabDataRef = useRef<HTMLDivElement>(null);
  const selectedOutlet = JSON.parse(
    localStorage.getItem("activeOutlet") || "{}"
  );
  const isadmin = userData
    ? JSON.parse(userData)?.typeOfUser.includes("admin")
    : false;
  const isBuyer = userData
    ? JSON.parse(userData)?.typeOfUser.includes("buyer")
    : false;
  // const isSupplier = userData
  //   ? JSON.parse(userData)?.typeOfUser.includes("supplier")
  //   : false;
  // const supplierStoreId =
  //   JSON.parse(userData)?.business_details?.[0]?.business_store_id;
  const [totalListCount, setTotalListCount] = useState(0);
  const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  const [migratedInvoices, setMigratedInvoices] = useState([]);
  // const [warehouse, setWarehousse] = useState();
  // const [buyer, setBuyer] = useState();
  // const [outlet, setOutlet] = useState();
  // const [supplierDropdownValue, setSupplierDropdownValue] = useState<any>({});
  // const [estimatedAmountDropdown, setEstimatedAmountDropdown] = useState(
  //   constants.invoiceFilters.estimatedAmountDropdown[0]
  // );
  // const [actualAmountDropdown, setActualAmountDropdown] = useState(
  //   constants.invoiceFilters.estimatedAmountDropdown[0]
  // );
  const [prevSelected, setPrevSelected] = useState(-1);
  const [showSort, setShowSort] = useState(false);
  const [sortValue, setSortValue] = useState("");
  // const [triggerSort, setTriggerSort] = useState(false);
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const [isdrawer, setIsDrawer] = React.useState({
    right: false,
  });
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [ShowSupplierListInFilter, setShowSupplierListInFilter] = useState([]);
  // const [outletOptions, setOutletOptions] = useState([]);
  const [finalData, setFinalData] = useState<any>({});
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
  const [toggleInvoice, setToggleInvoice] = useState(false);
  const businessDetails = loggedInUser?.business_details?.find(
    (ele: any) => ele.is_default
  );

  const handleToggleInvoice = () => {
    setToggleInvoice((prev) => !prev);
  };
  // const outletsList = useAxios({
  //   axiosParams: {
  //     url: `${buyerEndpoints.outletsList}${businessDetails?.business_setup_id}`,
  //   },
  //   method: APIMethods.get,
  // });
  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchQuery(value);
    dispatch(setPage({ type: "supplierInvoicesPageNumber", index: 1 }));
  };
  // invoices filters starts
  // const supplierSchema = yup.object().shape({
  //   warehouse: yup.object().nullable(),
  //   buyer: yup.object().nullable(),
  //   estimatedAmount: yup.mixed().optional(),
  //   actualAmount: yup.mixed().optional(),
  //   estimatedAmountValue: yup
  //     .string()
  //     .nullable()
  //     .test(
  //       "estimatedAmountValue-test",
  //       "estimated amount  should not be negative",
  //       (estimatedAmountValue) =>
  //         estimatedAmountValue != "" && Number(estimatedAmountValue) < 0
  //           ? false
  //           : true
  //     ),
  //   actualAmountValue: yup
  //     .string()
  //     .nullable()
  //     .test(
  //       "actualAmountValue-test",
  //       "actual amount  should not be negative",
  //       (estimatedAmountValue) =>
  //         estimatedAmountValue != "" && Number(estimatedAmountValue) < 0
  //           ? false
  //           : true
  //     ),
  // });
  // extending with supplier schema
  // const adminSchema = supplierSchema.shape({
  //   outlet: yup.object().nullable(),
  //   supplier: yup.object().nullable(),
  // });
  // const {
  //   register,
  //   setValue,
  //   handleSubmit,
  //   formState: { errors },
  //   watch,
  //   reset,
  // } = useForm({
  //   defaultValues: {
  //     estimatedAmount: estimatedAmountDropdown,
  //     actualAmount: actualAmountDropdown,
  //   },
  //   resolver: yupResolver(isadmin ? adminSchema : supplierSchema),
  // });
  // const onChangeField = React.useCallback(
  //   (name: any) => (text: any) => {
  //     setValue(name, text);
  //     if (name === "outlet") {
  //       // setWarehousse(text);
  //     } else if (name === "supplier") {
  //       setSupplierDropdownValue(text);
  //     } else if (name === "estimatedAmount") {
  //       setEstimatedAmountDropdown(text);
  //     } else if (name === "actualAmount") {
  //       setActualAmountDropdown(text);
  //     }
  //     // else if (isadmin && name === "outlet") {
  //     //   setOutlet(text);
  //     // }
  //     else if (isadmin && name === "supplier") {
  //       setSupplierDropdownValue(text);
  //     }
  //   },
  //   [setValue]
  // );

  //api to fetch suppliers list for filtering the invoices
  const getSupplierList = useAxios({
    axiosParams: {
      url: `${buyerAPIEndpoints.invoiceFilters}`,
    },
    method: APIMethods.get,
  });

  const getSupplierListData = useCallback(async () => {
    const response: any = await getSupplierList.fetchData();
    if (response?.status === "SUCCESS") {
      dispatch(updateIsLoading(false));
      const supplierList = response?.data?.invoiceList;
      if (supplierList && supplierList.length > 0) {
        const updatedSupplierList = supplierList.map((supplier: any) => ({
          ...supplier,
          name: supplier.ware_house_name,
          label: supplier.ware_house_name,
          value: supplier.ware_house_name,
        }));
        if (updatedSupplierList?.length > 0) {
          setShowSupplierListInFilter(updatedSupplierList);
        }
      }
    }
  }, [getSupplierList, selectedOutlet]);

  useEffect(() => {
    dispatch(updateIsLoading(true));
    getSupplierListData();
  }, []);

  // const filterOptions: any = [
  //   // {
  //   //   field: "checkboxGroup",
  //   //   label: "Status",
  //   //   type: "checkBox",
  //   //   group: ["Delivered", "Cancelled", "Returned", "Delivered (modified)"],
  //   // },
  //   // {
  //   //   field: "checkboxGroup",
  //   //   label: "Payment",
  //   //   type: "checkBox",
  //   //   group: ["Paid", "Unpaid"],
  //   // },
  //   // {
  //   //   type: "datePicker",
  //   //   label: "Placed on",
  //   // },
  //   // {
  //   //   type: "datePicker",
  //   //   label: "Delivery date",
  //   // },
  //   // {
  //   //   type: "select",
  //   //   label: "Outlet",
  //   //   options: outletOptions.map((el: any) => ({
  //   //     ...el,
  //   //     name: el.saved_as,
  //   //     value: el.saved_as,
  //   //   })),
  //   //   placeHolder: "Select the warehouse",
  //   //   nameToRegister: "outlet",
  //   //   selectedValue: warehouse,
  //   //   resetFunction: setWarehousse,
  //   // },
  //   // {
  //   //   type: "select",
  //   //   label: "Supplier",
  //   //   options:
  //   //     ShowSupplierListInFilter && ShowSupplierListInFilter.length > 0
  //   //       ? ShowSupplierListInFilter
  //   //       : [],
  //   //   placeHolder: "Select the supplier",
  //   //   nameToRegister: "supplier",
  //   //   selectedValue: supplierDropdownValue,
  //   //   resetFunction: setSupplierDropdownValue,
  //   // },
  //   {
  //     type: "selectAndInput",
  //     label: "Estimated amount",
  //     options: constants.invoiceFilters.estimatedAmountDropdown,
  //     placeHolder: "Enter amount",
  //     error: "",
  //     inputType: "number",
  //     nameToRegisterTextField: "estimatedAmountValue",
  //     nameToRegisterDropdownField: "estimatedAmount",
  //     selectedValue: estimatedAmountDropdown,
  //     resetFunction: setEstimatedAmountDropdown,
  //   },
  //   {
  //     type: "selectAndInput",
  //     label: "Actual amount",
  //     options: constants.invoiceFilters.estimatedAmountDropdown,
  //     placeHolder: "Enter amount",
  //     error: "",
  //     inputType: "number",
  //     nameToRegisterTextField: "actualAmountValue",
  //     nameToRegisterDropdownField: "actualAmount",
  //     selectedValue: actualAmountDropdown,
  //     resetFunction: setActualAmountDropdown,
  //   },
  // ];

  // const adminFilterOptions = [
  //   // {
  //   //   type: "select",
  //   //   label: "Outlet",
  //   //   options: constants.invoiceFilters.outletList,
  //   //   placeHolder: "Select the outlet",
  //   //   nameToRegister: "outlet",
  //   //   selectedValue: outlet,
  //   //   resetFunction: setOutlet,
  //   // },
  //   // {
  //   //   type: "select",
  //   //   label: "Supplier",
  //   //   options: constants.invoiceFilters.suppliersList,
  //   //   placeHolder: "Select the supplier",
  //   //   nameToRegister: "supplier",
  //   //   selectedValue: supplierDropdownValue,
  //   //   resetFunction: setSupplierDropdownValue,
  //   // },
  //   {
  //     type: "select",
  //     label: "Supplier",
  //     options:
  //       ShowSupplierListInFilter && ShowSupplierListInFilter.length > 0
  //         ? ShowSupplierListInFilter
  //         : [],
  //     placeHolder: "Select the supplier",
  //     nameToRegister: "supplier",
  //     selectedValue: supplierDropdownValue,
  //     resetFunction: setSupplierDropdownValue,
  //   },
  //   ...filterOptions,
  // ];
  // const [triggerApiCall, setTriggerApiCall] = useState(false);

  // const getFilteredList = useAxios({
  //   axiosParams: {
  //     url: isadmin
  //       ? `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?page=${currentPage}&take=${9}&search=${searchQuery}${
  //           finalData?.supplier?.length
  //             ? `&isFlag=isSupplier&ware_house_id=${finalData?.supplier}`
  //             : ""
  //         }${
  //           finalData?.estimatedAmountDropdown?.length
  //             ? `&estimate_amount_key=${finalData?.estimatedAmountDropdown}`
  //             : ""
  //         }${
  //           finalData?.estimatedAmountValue?.length
  //             ? `&estimate_amount_value=${Number(
  //                 finalData?.estimatedAmountValue
  //               )}`
  //             : ""
  //         }${
  //           finalData?.actualAmountDropdown?.length
  //             ? `&actual_amount_key=${finalData?.actualAmountDropdown}`
  //             : ""
  //         }${
  //           finalData?.actualAmountValue?.length
  //             ? `&actual_amount_value=${Number(finalData?.actualAmountValue)}`
  //             : ""
  //         }`
  //       : isSupplier
  //       ? `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?isFlag=isSupplier&page=${currentPage}&search=${searchQuery}&ware_house_id=${
  //           loggedInUser?.business_details?.[0]?.business_setup_id
  //         }${
  //           finalData?.estimatedAmountDropdown?.length
  //             ? `&estimate_amount_key=${finalData?.estimatedAmountDropdown}`
  //             : ""
  //         }${
  //           finalData?.estimatedAmountValue?.length
  //             ? `&estimate_amount_value=${Number(
  //                 finalData?.estimatedAmountValue
  //               )}`
  //             : ""
  //         }${
  //           finalData?.actualAmountDropdown?.length
  //             ? `&actual_amount_key=${finalData?.actualAmountDropdown}`
  //             : ""
  //         }
  //         ${
  //           finalData?.actualAmountValue?.length
  //             ? `&actual_amount_value=${Number(finalData?.actualAmountValue)}`
  //             : ""
  //         }`
  //       : `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?isFlag=isBuyer&outlet_id=${
  //           finalData?.supplierDropdownValue?.supplier_id
  //         }&page=${currentPage}&search=${searchQuery}&customer_id=${
  //           selectedOutlet?.businessStoreId
  //         }&estimate_amount_key=${
  //           finalData?.estimatedAmountDropdown
  //         }&estimate_amount_value=${Number(
  //           finalData?.estimatedAmountValue
  //         )}&actual_amount_key=${
  //           finalData?.actualAmountDropdown
  //         }&actual_amount_value=${Number(finalData?.actualAmountValue)}`,
  //   },
  // });

  // const fetchData = useCallback(async () => {
  //   if (triggerApiCall) {
  //     dispatch(updateIsLoading(true));
  //     const response: any = await getFilteredList.fetchData();
  //     if (response?.status === "SUCCESS") {
  //       dispatch(updateIsLoading(false));
  //       const invoiceData = response?.data;
  //       setTotalListCount(invoiceData?.count);
  //       dispatch(addInvoiceList(invoiceData?.invoiceList));
  //     }
  //     setShowEmptyContainer(
  //       response?.status === "FAILURE" ||
  //         response?.data?.invoiceList?.length === 0
  //     );
  //     setTriggerApiCall(false);
  //   }
  // }, [triggerApiCall]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  // console.log(supplierInvoiceList, "supplierInvoiceList");

  //this function is used to apply the filters on invoice list
  const submit = async (data: any) => {
    const finalDataLocal: any = {};
    if (data?.estimatedAmount && data?.estimatedAmount?.length > 0) {
      finalDataLocal["estimatedAmountValue"] = data?.estimatedAmount;
      finalDataLocal["estimatedAmountDropdown"] =
        typeof data?.estimatedAmountDropdown != "string"
          ? data?.estimatedAmountDropdown?.value
          : data?.estimatedAmountDropdown;
    }
    if (data?.actualAmount && data?.actualAmount?.length > 0) {
      finalDataLocal["actualAmountValue"] = data?.actualAmount;
      finalDataLocal["actualAmountDropdown"] =
        typeof data?.actualAmountDropdown != "string"
          ? data?.actualAmountDropdown?.value
          : data?.actualAmountDropdown;
    }
    if (isadmin) {
      if (data?.supplier && Object?.keys(data?.supplier)?.length > 0) {
        finalDataLocal["supplier"] = data?.supplier?.supplier_id;
      }
    }
    if (Object.keys(finalDataLocal)?.length > 0) {
      let newData = { ...finalDataLocal, activeFilters: true };
      setFinalData(newData);
      dispatch(setFilterData(newData));
      setCurrentPage(1);
    } else {
      let newData = { activeFilters: false };
      setFinalData(newData);
      dispatch(setFilterData(newData));
      setCurrentPage(1);
    }
    // console.log(newData, "newData");
    // setTriggerApiCall(true);
    setIsDrawer({ right: false });
  };
  // invoices filters ends
  // console.log(finalData, "finalData");

  // const getSortedList = useAxios({
  //   axiosParams: {
  //     url: isadmin
  //       ? `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?page=${currentPage}&search=${searchQuery}&sort_order=${sortValue}&sort_field=${
  //           sortValue != "whatsnew" ? "price" : ""
  //         }`
  //       : isSupplier
  //       ? `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?isFlag=isSupplier&page=${currentPage}&search=${searchQuery}&ware_house_id=${
  //           loggedInUser?.business_details?.[0]?.business_setup_id
  //         }&sort_order=${sortValue}&sort_field=${
  //           sortValue != "whatsnew" ? "price" : ""
  //         }`
  //       : `${
  //           buyerAPIEndpoints.invoiceFilteredListing
  //         }?isFlag=isBuyer&&page=${currentPage}&search=${searchQuery}&ware_house_id=${supplierStoreId}&sort_order=${sortValue}&sort_field=${
  //           sortValue != "whatsnew" ? "price" : ""
  //         }`,
  //   },
  // });

  // const updateSortedList = async () => {
  //   if (triggerSort) {
  //     dispatch(updateIsLoading(true));
  //     const response: any = await getSortedList.fetchData();
  //     if (response?.status === "SUCCESS") {
  //       dispatch(updateIsLoading(false));
  //       const invoiceData = response?.data;
  //       setTotalListCount(invoiceData?.count);
  //       dispatch(addInvoiceList(invoiceData?.invoiceList));
  //     } else {
  //       dispatch(updateIsLoading(false));
  //     }
  //     setShowEmptyContainer(
  //       response?.status === "FAILURE" ||
  //         response?.data?.invoiceList?.length === 0
  //     );
  //     setTriggerSort(false);
  //   }
  // };

  // useEffect(() => {
  //   updateSortedList();
  // }, [sortValue]);

  //  sorting starts
  const sortOptions = [
    {
      id: 0,
      label: constants.invoiceSort.WHATS_NEW,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("whatsnew");
      },
    },
    {
      id: 1,
      label: constants.invoiceSort.SORT_LOW_HIGH,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("asc");
      },
    },
    {
      id: 2,
      label: constants.invoiceSort.SORT_HIGH_LOW,
      onClick: () => {
        // setTriggerSort(true);
        setSortValue("DESC");
      },
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const handleSort = () => {
    setShowSort(!showSort);
  };
  //  sorting ends
  // const invoiceList = [
  //   {
  //     id: 24,
  //     invoiceNo: "INV-123456",
  //     paidStatus: "PAID",
  //     orderId: "PO -123456",
  //     outlet: "Al Baraha",
  //     supplier: "Delta Foods",
  //     estAmount: "1000.00",
  //     actualAmount: "1000.00",
  //   },
  // ];

  const itemsPerPage = 10;

  //api to fetch the invoice list
  const getData = useAxios({
    axiosParams: {
      url: isadmin
        ? `${
            adminEndPoints.invoiceListing
          }?page=${currentPage}&take=${9}&search=${searchQuery}${
            finalData?.supplier?.length
              ? `&isFlag=isSupplier&ware_house_id=${finalData?.supplier}`
              : ""
          }${
            finalData?.estimatedAmountDropdown?.length
              ? `&estimate_amount_key=${finalData?.estimatedAmountDropdown}`
              : ""
          }${
            finalData?.estimatedAmountValue?.length
              ? `&estimate_amount_value=${Number(
                  finalData?.estimatedAmountValue
                )}`
              : ""
          }${
            finalData?.actualAmountDropdown?.length
              ? `&actual_amount_key=${finalData?.actualAmountDropdown}`
              : ""
          }${
            finalData?.actualAmountValue?.length
              ? `&actual_amount_value=${Number(finalData?.actualAmountValue)}`
              : ""
          }${
            sortValue?.length > 0
              ? `&sort_order=${sortValue}&sort_field=${
                  sortValue != "whatsnew" ? "price" : ""
                }`
              : ""
          }`
        : isBuyer
        ? `${userEndpoints.supplierInvoiceListing}?isFlag=isBuyer&outlet_id=${
            businessDetails?.business_store_id
          }&page=${currentPage}&take=${10}&search=${searchQuery}`
        : `${
            buyerAPIEndpoints.invoiceFilteredListing
          }?isFlag=isSupplier&page=${currentPage}&take=${10}&search=${searchQuery}&ware_house_id=${
            loggedInUser?.business_details?.[0]?.business_setup_id
          }${
            finalData?.estimatedAmountDropdown?.length
              ? `&estimate_amount_key=${finalData?.estimatedAmountDropdown}`
              : ""
          }${
            finalData?.estimatedAmountValue?.length
              ? `&estimate_amount_value=${Number(
                  finalData?.estimatedAmountValue
                )}`
              : ""
          }${
            finalData?.actualAmountDropdown?.length
              ? `&actual_amount_key=${finalData?.actualAmountDropdown}`
              : ""
          }${
            finalData?.actualAmountValue?.length
              ? `&actual_amount_value=${Number(finalData?.actualAmountValue)}`
              : ""
          }${
            sortValue?.length > 0
              ? `&sort_order=${sortValue}&sort_field=${
                  sortValue != "whatsnew" ? "price" : ""
                }`
              : ""
          }`,
    },
    method: APIMethods.get,
  });

  //api to fetch the migrated invoices list
  const getMigratedInvoices = useAxios({
    axiosParams: {
      url: isadmin
        ? `store/migrated_orders/get-invoice-details?offset=${
            (currentPage - 1) * 10
          }&limit=10${searchQuery ? `&q=${searchQuery}` : ``}${
            sortValue && `&sort_by=amount&sort_order=${sortValue}`
          }`
        : `store/migrated_orders/supplier-invoice-details/${
            businessDetails?.business_setup_id
          }?offset=${(currentPage - 1) * 10}&limit=10${
            searchQuery ? `&q=${searchQuery}` : ``
          }${sortValue && `&sort_by=amount&sort_order=${sortValue}`}`,
    },
    method: APIMethods.get,
  });
  const transformKeys = (data: any) => {
    return data?.map((item: any) => {
      return {
        id: item?.id,
        invoiceNo: item?.invoice_number ?? "-",
        orderId: item?.order_id ?? "-",
        supplier: item?.buyer_business_name ?? "-",
        estAmount: +item?.total_cost_amount ?? 0,
        actualAmount: +item?.total_payable_amount ?? 0,
      };
    });
  };
  const getUserDetails = useCallback(async () => {
    if (loggedInUser) {
      let response: any;
      if (toggleInvoice) {
        response = await getMigratedInvoices.fetchData();
        if (response?.status === "SUCCESS") {
          dispatch(updateIsLoading(false));
          const invoiceData = response?.data;
          setTotalListCount(invoiceData?.count);
          setMigratedInvoices(transformKeys(invoiceData?.invoice_list));
        }
        setShowEmptyContainer(
          response?.status === "FAILURE" ||
            response?.data?.invoice_list?.length === 0
        );
      } else {
        response = await getData.fetchData();
        if (response?.status === "SUCCESS") {
          dispatch(updateIsLoading(false));
          const invoiceData = response?.data;
          setTotalListCount(invoiceData?.count);
          dispatch(addInvoiceList(invoiceData?.invoiceList));
        }
        setShowEmptyContainer(
          response?.status === "FAILURE" ||
            response?.data?.invoiceList?.length === 0
        );
      }
    }
  }, [getData, getMigratedInvoices]);

  useEffect(() => {
    // if (!toggleInvoice) {
    dispatch(updateIsLoading(true));
    getUserDetails();
    // }
  }, [currentPage, searchQuery, finalData, sortValue, toggleInvoice]);

  // async function fetchOutletsList() {
  //   const outletList: any = await outletsList.fetchData();
  //   console.log(outletList?.data?.outlets, "outletList");
  //   // setOutletOptions(outletList?.data?.outlets || []);
  // }

  // useEffect(() => {
  //   fetchOutletsList();
  // }, []);

  const [childCardData, setchildCardData] = useState<{
    id: number | undefined;
    show: boolean;
  }>({ id: undefined, show: false });

  const handleShowMenu = (index: number, show: boolean) => {
    setchildCardData({ id: index, show: show });
  };

  useEffect(() => {
    scrollTabDataRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "supplierInvoicesPageNumber", index: page }));
  };
  const searchCustomStyles = {
    top: "0px",
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //cleanup function for activeFilters in redux
  useEffect(() => {
    return () => {
      dispatch(setFilterData({}));
    };
  }, []);

  return (
    <>
      <div className={styles.invoicePage}>
        <div className={styles.invoicePage__tableSection}>
          <div className={styles.invoicePage__headingSection}>
            <div className={styles.header}>
              <h2
                className={`${styles.invoicePage__heading} ${
                  isadmin && styles.setMargin
                }`}
              >
                Manage invoices
              </h2>
              <div
                className={styles.textBtn}
                onClick={() => {
                  handleToggleInvoice();
                }}
              >
                {toggleInvoice ? "View invoices" : "View migrated invoices"}
              </div>
            </div>

            {
              <div
                className={`${styles.invoicePage__tableFunctions}  ${styles.adminSearchContainer}`}
              >
                {<div className={styles.line}></div>}
                {(totalListCount > 0 || searchQuery.length > 0) && (
                  <div>
                    <PageSearch
                      handleSearch={handleSearch}
                      customStyles={searchCustomStyles}
                      customPlaceHolder="Search by invoice no."
                    />
                  </div>
                )}
                {/* remove false to show sort */}
                {
                  <div
                    className={`${styles.icon} ${styles.sort}`}
                    onClick={handleSort}
                    ref={sortContainerRef}
                    data-testid="invoice-sort-button"
                  >
                    <img src={sortIcon} alt="sort"></img>
                    {showSort && (
                      <div
                        className={styles.sortList}
                        data-testid="invoice-sort"
                      >
                        <div className={styles.sortBy}>
                          {constants.ordersListing.SORT_BY}
                        </div>
                        {sortOptions.map((option, index) => (
                          <div
                            className={`${styles.sortOption} ${
                              index === sortOptions.length - 1 &&
                              styles.lastOption
                            } ${
                              option.id === prevSelected && styles.selectedOpt
                            }`}
                            onClick={() => handleSortingOptions(option.id)}
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
                {/* remove false to show filters */}
                {!toggleInvoice &&
                  <div
                    className={`${styles.icon} ${styles.sort} `}
                    onClick={() => setIsDrawer({ right: true })}
                  >
                    {filtrationKeys?.filterData?.activeFilters && (
                      <div className={styles.activeFilter}></div>
                    )}
                    <img src={filterIcon} alt="filter"></img>
                  </div>
                }
              </div>
            }
          </div>

          <>
            {!showEmptyContainer ? (
              <div className={styles.tableContainer}>
                <div className={styles.header}>
                  <div className={`${styles.invoiceNo} ${styles.heading}`}>
                    Invoice no. & status
                  </div>
                  <div className={`${styles.orderId} ${styles.heading}`}>
                    Order ID
                  </div>
                  {!toggleInvoice && (
                    <div className={`${styles.outlet} ${styles.heading}`}>
                      Outlet
                    </div>
                  )}
                  <div className={`${styles.supplier} ${styles.heading}`}>
                    Supplier
                  </div>
                  <div className={`${styles.estAmnt} ${styles.heading}`}>
                    Est. amount
                  </div>
                  <div className={`${styles.actualAmnt} ${styles.heading}`}>
                    Actual amount
                  </div>
                </div>
                <div className={styles.tabslistContainer}>
                  <div
                    ref={scrollTabDataRef}
                    className={`${styles.ordersList} ${
                      isadmin ? styles.overrideOrdersList : null
                    }`}
                  >
                    {toggleInvoice &&
                    migratedInvoices &&
                    migratedInvoices?.length > 0
                      ? migratedInvoices?.map((order: any) => (
                          <InvoiceCard
                            id={order?.id}
                            key={order?.id}
                            uniqueID={order?.id}
                            invoiceNo={order?.invoiceNo}
                            orderId={order?.orderId}
                            outlet={order?.outlet}
                            paidStatus={order?.paidStatus}
                            supplier={order?.supplier}
                            estAmount={order?.estAmount}
                            actualAmount={order?.actualAmount}
                            showMenu={childCardData}
                            handleShowMenu={handleShowMenu}
                            isMigratedInvoices={true}
                          />
                        ))
                      : supplierInvoiceList &&
                        supplierInvoiceList?.length > 0 &&
                        supplierInvoiceList?.map((order: any) => (
                          <InvoiceCard
                            id={order?.id}
                            key={order?.id}
                            uniqueID={order?.id}
                            invoiceNo={order?.invoiceNo}
                            orderId={order?.orderId}
                            outlet={order?.outlet}
                            paidStatus={order?.paidStatus}
                            supplier={order?.supplier}
                            estAmount={order?.estAmount}
                            actualAmount={order?.actualAmount}
                            showMenu={childCardData}
                            handleShowMenu={handleShowMenu}
                            isMigratedInvoices={false}
                          />
                        ))}
                  </div>
                  <div className={styles.paginationContainer}>
                    <PaginationComponent
                      count={Math.ceil(totalListCount / itemsPerPage)}
                      page={currentPage}
                      handlePagination={handlePagination}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.emptyContainer}>
                <EmptyContainer title={"No Data"} />
              </div>
            )}
          </>
        </div>
      </div>
      {isdrawer.right && (
        <SideDrawer
          isdrawer={isdrawer}
          setIsDrawer={setIsDrawer}
          heading={"Filters"}
          childComponent={
            <PageFilter
              key={"invoice_filters"}
              // filterOptions={isadmin ? adminFilterOptions : filterOptions}
              // register={register}
              // onChangeField={onChangeField}
              // errors={errors}
              // handleSubmit={handleSubmit}
              // onSubmitData={onSubmitData}
              // watch={watch}
              // reset={reset}
              overlayCallback={setIsDrawer}
              ShowSupplierListInFilter={ShowSupplierListInFilter}
              submit={(data: any) => submit(data)}
              filtrationKeys={
                filtrationKeys?.filterData?.activeFilters
                  ? filtrationKeys
                  : null
              }
              isadmin={isadmin}
            />
          }
        />
      )}
    </>
  );
};
export default InVoicesPage;
