import styles from "./OverallSummarDetails.module.scss";
import { useEffect, useState } from "react";
import SummaryActionOverlay from "./summaryActionOverlay/SummaryActionOverlay.tsx/SummaryActionOverlay";

const OverallSummaryDetailsKPI = ({
  supplierSummaryDetails,
  buyersummaryDetails,
  userType,
}: any) => {
  const [summaryDetails, setSummaryDetails] = useState<any>([]);
  //setting the correct data for the KPI
  useEffect(() => {
    if (userType === "supplier") {
      setSummaryDetails(supplierSummaryDetails);
    } else {
      setSummaryDetails(buyersummaryDetails);
    }
  }, [userType]);

  return (
    <>
      <div className={styles.parentContainer}>
        <div className={styles.detailsCardContainer}>
          {summaryDetails?.map((each: any, index: number) => (
            <div
              className={styles.detailsCard}
              data-testid={`summaryCard-${index}`}
              key={each.id}
              title="summaryCard"
            >
              <div className={styles.cardImage}>
                <img
                  src={each?.icon}
                  alt="onTimeDeliverIcon"
                  data-testid={`cardIcon-${each.id}`}
                />
              </div>
              {each.id === 0 || each.id === 1 || each.id === 2 ? (
                <div className={styles.amount}>{each?.data}</div>
              ) : false ? (
                <div className={styles.amount} data-testid="Summarydata">
                  {each?.data.toFixed(2)?.split(".")[0]}.
                  <span>{each?.data.toFixed(2)?.split(".")[1]}</span>
                  <span>AED</span>
                </div>
              ) : (
                <div className={styles.amount} data-testid="Summarydata">
                  {each?.data}
                  <span> %</span>
                </div>
              )}
              <div className={styles.cardTitle}>
                {each?.name}
                {userType === "buyer" && !(each.id === 4 || each.id === 5) && (
                  <div
                    className={styles.overlay}
                    data-testid={`overlay-${each.id}`}
                  >
                    <SummaryActionOverlay
                      overlayData={each?.totalData}
                      id={each.id}
                    ></SummaryActionOverlay>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OverallSummaryDetailsKPI;
