import styles from "./IndividualProductCard.module.scss";
import { CategoryCardProps } from "../../../models/ICategoryCard";
import ArrowLeft from "../../../assets/images/Arrow-left.svg";

const IndividualProductCard = ({
  title,
  imgSrc,
  id,
  handleCardClick,
  cardType,
  isModify,
  head,
}: CategoryCardProps) => {
  return (
    <div
      data-testid="category-card"
      className={`${styles.categoryCardContainer} ${
        cardType === "search" && styles.searchCard
      }`}
      onClick={() => handleCardClick && handleCardClick(id)}
    >
      <div className={styles.cardContainer}>
        <div className={`${styles.imgContainer} ${isModify && styles.modify}`}>
          <img src={imgSrc} alt="fresh-food" />
        </div>
        <div className={styles.head}>{head}</div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.hoverContainer}>
        <div className={styles.hoverTitle}>{title}</div>
        <div className={styles.hoverImgContainer}>
          <img src={ArrowLeft} alt="fresh-food" />
        </div>
      </div>
    </div>
  );
};

export default IndividualProductCard;
