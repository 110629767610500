import { constants } from "../../../../common/constants/constants";
import cancel from "../../../../assets/images/cancelToast.svg";
import styles from "./AddOutlet.module.scss";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { Drawer } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import CheckboxButton from "../../../../common/components/checkbox/CheckboxButton";
import MobileNumberField from "../../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { getCountryByDialCode } from "../../../../utils/functions";
import { MARK_AS_DEFAULT } from "../../../../common/constants/constants";
const AddOutletDrawer = ({
  showAddOutlet,
  handleClose,
  countriesList,
  currOutletData,
  outletsList,
  handleAddOutlet,
}: any) => {
  const { address = {} } = currOutletData;
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [checked, setChecked] = useState(false);
  const [mobileNumberCode, setMobileNumberCode] = useState<any>("");

  //outlet schema
  const schema = yup.object({
    newoutletName: yup
      .string()
      .test("check duplicate outlet", "Outlet name already exists", (val) => {
        if (
          currOutletData.saved_as?.toLowerCase()  !== val?.toLowerCase()  &&
          outletsList.find((el: any) => el.saved_as?.toLowerCase()  === val?.toLowerCase())
        ) {
          return false;
        } else {
          return true;
        }
      })
      .required("Enter outlet name"),
    address: yup.string().required("Enter address"),
    area: yup.string().required("Enter area name"),
    emirates: yup.object().required("Select emirate"),
    country: yup.object().required("Select country"),
    makeDefault: yup.boolean().required(),
    contactName: yup.string().required("Enter contact name"),
    countryName: yup.object(),
    phoneNumber: yup.string().required("Enter phone number"),
  });

  const initialValues = {
    newoutletName: currOutletData.saved_as ? currOutletData.saved_as : "",
    address: address.address ? address.address : "",
    area: address.area ? address.area : "",
    country: {},
    emirates: {},
    makeDefault: currOutletData.is_default ? currOutletData.is_default : false,
    contactName: currOutletData?.contact_name || "",
    phoneNumber: currOutletData?.phone_number
      ? currOutletData?.phone_number.split("#")[1]
      : "",
    countryName: {},
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "countryName") {
        setValue(name, text);
      }
      if (name === "country" || name === "emirates") {
        setValue(name, text);
      }
      if (name === "newoutletName") {
        setValue(name, text);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (!currOutletData?.address) {
      setMobileNumberCode(
        countriesList &&
          countriesList.length > 0 &&
          countriesList.filter(
            (country: any) => country?.dial_codes === ("+971" as any)
          )[0]
      );
      setValue(
        "countryName",
        countriesList &&
          countriesList.length > 0 &&
          countriesList.filter(
            (country: any) => country?.dial_codes === ("+971" as any)
          )[0]
      );
    } else {
      setValue(
        "countryName",
        getCountryByDialCode(
          currOutletData?.phone_number.split("#")[0],
          countriesList
        )
      );
      setMobileNumberCode(
        getCountryByDialCode(
          currOutletData?.phone_number.split("#")[0],
          countriesList
        )
      );
    }
  }, [currOutletData, countriesList]);

  useEffect(() => {
    if (currOutletData?.address) {
      let country = null;
      let emiratesList = null;
      if (address?.country) {
        country = countriesList?.find(
          (ele: any) => ele?.iso_2 === address?.country?.iso_2
        );
        if (country) {
          setSelectedCountry(country);
          setSelectedEmirate(
            country?.emirates?.find(
              (ele: any) => ele?.id === address?.emirate?.id
            )
          );
        }
      } else {
        const defaultCountry = countriesList?.find(
          (ele: any) => ele?.iso_2 === "ae"
        );
        if (defaultCountry) {
          const list = defaultCountry?.emirates;
          emiratesList = list.map((item: any) => ({
            ...item,
            value: item?.name,
          }));
          setSelectedCountry(defaultCountry);
          setEmiratesList(emiratesList);
        }
      }
      reset({
        ...initialValues,
        country: country,
        emirates: emiratesList,
      });
      setChecked(currOutletData.is_default);
    }
  }, [countriesList, currOutletData]);

  const submit = (data: any) => {
    const outletDetails = {
      address: {
        area: data.area,
        address: data.address,
        country: data.country,
        emirate: data.emirates,
      },
      saved_as: data.newoutletName,
      is_default: data.makeDefault || false,
      contact_name: data.contactName,
      phone_number: `${(data?.countryName as any)?.dial_codes}#${
        data.phoneNumber
      }`,
      isNew: !Boolean(currOutletData?.address),
    };
    handleAddOutlet(
      outletDetails,
      Boolean(currOutletData?.address),
      currOutletData.index
    );
    handleClose();
  };


  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates =
      selectedOption &&
      selectedOption?.emirates?.map((emirate: ICountry) => ({
        ...emirate,
        value: emirate?.name,
      }));
    setEmiratesList(emirates);
  };
  return (
    <>
      <Drawer anchor={"right"} open={showAddOutlet} hideBackdrop={false}>
        <div className={styles.addoutletContainer}>
          <div className={styles.header}>
            <p className={styles.heading}>
              {!currOutletData?.address
                ? constants.BusinessSetupForm.addOutletHeader
                : constants.BusinessSetupForm.editOutletHeader}
            </p>
            <img
              src={cancel}
              alt="cancel"
              className={styles.cancel}
              onClick={handleClose}
            />
          </div>
          <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
            <div className={styles.form}>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    value={watch("newoutletName")}
                    id="newoutletName"
                    label="Outlet name"
                    placeholder="Enter outlet name"
                    onChange={onChangeField("newoutletName")}
                    register={register("newoutletName")}
                    errorMessage={errors.newoutletName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    value={watch("contactName")}
                    id="contactName"
                    label="Contact name"
                    placeholder="Enter contact name"
                    onChange={onChangeField("contactName")}
                    register={register("contactName")}
                    errorMessage={errors.contactName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    value={watch("phoneNumber")}
                    defaultValue={mobileNumberCode}
                    width="100%"
                    overlayWidth={"300px"}
                    label="Phone number"
                    id={"phoneNumber"}
                    options={countriesList}
                    registerMobileNumber={register("phoneNumber")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phoneNumber")}
                    onCountryChange={(selectedOption: ICountry) => {
                      if (selectedOption) {
                        onChangeField("countryName")(selectedOption);
                        setMobileNumberCode(selectedOption);
                      }
                    }}
                    errorMessage={errors.phoneNumber?.message}
                    mobileNumberField={true}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Address"
                    value={watch("address")}
                    placeholder="Enter address here"
                    onChange={onChangeField("address")}
                    register={register("address")}
                    errorMessage={errors.address?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Area"
                    value={watch("area")}
                    placeholder="Enter area here"
                    onChange={onChangeField("area")}
                    register={register("area")}
                    errorMessage={errors.area?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"country"}
                    label="Country"
                    options={
                      countriesList &&
                      countriesList.filter(
                        (country: ICountry) => country?.iso_2 === "ae"
                      )
                    }
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("country")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("country")(selectedOption);
                      selectedOption && setSelectedCountry(selectedOption);
                      selectedOption && handleCountryClick(selectedOption);
                    }}
                    defaultLabel={
                      (selectedCountry && selectedCountry?.name) ??
                      "Select country"
                    }
                    errorMessage={errors.country?.message}
                    selectedValue={selectedCountry}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"Emirate"}
                    label="Emirate"
                    options={emiratesList}
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("emirates")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("emirates")(selectedOption);
                      selectedOption && setSelectedEmirate(selectedOption);
                    }}
                    defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                    errorMessage={errors.emirates?.message}
                    selectedValue={selectedEmirate}
                  />
                </div>
                <div className={styles.defOutlet}>
                  <div className={styles.checkbox}>
                    <CheckboxButton
                      checked={checked}
                      id="makeDefault"
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setChecked(!checked);
                        onChangeField("makeDefault");
                        setValue("makeDefault", e.target.checked);
                      }}
                      label={MARK_AS_DEFAULT}
                      register={register("makeDefault")}
                      disabled={currOutletData.is_default}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.action}>
              <button
                type="button"
                className={styles.skip}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className={styles.submit}>
                {currOutletData?.address
                  ? constants.BusinessSetupForm.updateButton
                  : constants.BusinessSetupForm.submitButton}
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddOutletDrawer;
