import { useEffect, useState } from "react";
import PageSearch from "../../../../components/pageSearch/PageSearch";
import styles from "./SpecialPricingList.module.scss";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods, adminEndPoints } from "../../../../constants/urlConstants";
import SpecialPricingListCard from "../specialPricingListCard/SpecialPricingListCard";
import PaginationComponent from "../../../../components/pagination/Pagination";
import React from "react";
import { EmptyContainer } from "../../../ordersListing/components/emptyContainer/EmptyContainer";
const SpecialPricingList = ({ setIsEmpty }: any) => {
  const searchCustomStyles = {
    top: "0px",
  };
  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchText(value);
  };
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [userType] = useState(loggedInUser && loggedInUser?.typeOfUser?.[0]);
  const businessId = loggedInUser?.business_details?.[0]?.business_setup_id;
  const [currentPage, setCurrentPage] = useState(1);
  const [specialPriceList, setSpecialPriceList] = useState([]);
  const [specialPriceListCount, setSpecialPriceCount] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const itemsPerPage = 10;
  //api to fetch the special pricing list
  const { fetchData } = useAxios({
    axiosParams: {
      url:
        userType === "supplier"
          ? `store/special_pricing/getsupplierpricing/${businessId}?status=${"accepted"}&limit=${itemsPerPage}&offset=${
              (currentPage - 1) * 10
            }&q=${searchText}`
          : `${
              adminEndPoints?.specialPricing
            }/getallpricing?status=${"accepted"}&limit=${itemsPerPage}&offset=${
              (currentPage - 1) * 10
            }&q=${searchText}`,
    },
    method: APIMethods.get,
  });

  const getList = async () => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await fetchData();
      if (response && response?.data && response?.data?.pricing_details) {
        const fetchedData = response?.data?.pricing_details.map((item: any) => {
          if (item) {
            return {
              id: item?.id,
              buyerBusinessName: item?.buyer_name,
              supplierBusinessName: item?.supplier_name,
              productName: item?.product_name,
              primaryUnitLabel: item?.pv_metadata?.ordering_option_label,
              primaryUnitValue: item?.pv_metadata?.ordering_option_value,
              secondaryUnitLabel:
                item?.pv_metadata?.secondary_ordering_option_label,
              secondaryUnitValue:
                item?.pv_metadata?.secondary_ordering_option_value,
              variantPrice: item?.price ? Number(item?.price)?.toFixed(2) : "-",
              updatedCustomerId: item?.updated_by,
              consumption: item?.consumption,
              specialPricingId: item?.id,
              unit: item?.units,
              moOption: {
                value: item?.consumption_type?.toUpperCase(),
                label: item?.consumption_type?.toUpperCase(),
                name: item?.consumption_type?.toUpperCase(),
              },
              moValue: item?.consumption_amount?.toFixed(2) ?? "1",
              moQuantity: item?.consumption_quantity ?? "1",
              actualAmount: item?.actual_amount,
            };
          }
          return item;
        });
        setSpecialPriceCount(response?.data?.count);
        setSpecialPriceList(fetchedData);
        setIsEmpty(response?.data?.count <= 0);
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(false));
      }
    } catch (e: any) {
      dispatch(updateIsLoading(false));
    }
  };

  useEffect(() => {
    getList();
  }, [searchText, currentPage]);
  useEffect(() => {
    if (isDataUpdated) {
      getList(); //fetching special pricing list whenever the data is updated or deleted
      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div className={styles.border}></div>
          {(specialPriceListCount > 10 || searchText?.length > 0) && (
            <PageSearch
              handleSearch={handleSearch}
              customStyles={searchCustomStyles}
              customPlaceHolder={`Search by product title or buyer name or supplier name`}
            />
          )}
        </div>
        <div className={styles.tableContainer}>
          {specialPriceList && specialPriceList?.length > 0 ? (
            <div className={styles.tableParent}>
              <div className={styles.table}>
                <div className={styles.theading}>
                  <div
                    className={`${styles.cell} ${
                      userType === "supplier" && styles.prodName
                    }`}
                  >
                    Product name
                  </div>
                  <div className={styles.cell}>Buyer business name</div>
                  {userType === "admin" && (
                    <div className={styles.cell}>Supplier business name</div>
                  )}
                  <div className={`${styles.cell} ${styles.width}`}>
                    Options
                  </div>
                  <div className={`${styles.cell} ${styles.width}`}>Price</div>
                  <div className={`${styles.cell} ${styles.width}`}>
                    MOV/MOQ
                  </div>
                  <div className={`${styles.cell} ${styles.width}`}>
                    Order value
                  </div>
                  <div className={styles.cell}></div>
                </div>
                <div className={styles.body}>
                  {specialPriceList?.map((product: any, i: any) => (
                    <SpecialPricingListCard
                      id={product?.id}
                      buyerBusinessName={product?.buyerBusinessName}
                      supplierBusinessName={product?.supplierBusinessName}
                      productName={product?.productName}
                      primaryUnitLabel={product?.primaryUnitLabel}
                      primaryUnitValue={product?.primaryUnitValue}
                      secondaryUnitLabel={product?.secondaryUnitLabel}
                      secondaryUnitValue={product?.secondaryUnitValue}
                      variantPrice={product?.variantPrice}
                      updatedCustomerId={product?.updatedCustomerId}
                      setIsDataUpdated={setIsDataUpdated}
                      moOption={product?.moOption}
                      moValue={product?.moValue}
                      moQuantity={product?.moQuantity}
                      actualAmount={product?.actualAmount}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <EmptyContainer title={"Woah!"} subText={"No products"} />
          )}
        </div>
        <div className={styles.paginationContainer}>
          <PaginationComponent
            count={Math.ceil(specialPriceListCount / itemsPerPage)}
            page={currentPage}
            handlePagination={handlePagination}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </div>
    </>
  );
};
export default SpecialPricingList;
