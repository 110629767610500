import React from "react";
import styles from "./selectDeliveryTime.module.scss";
import RadioButton from "../../../../../../common/components/radio/RadioButton";

type SelectDeliveryTimeProps = {
  timeDetails: any;
  handleMouseOver: (id: string) => void;
  handleMouseLeave: () => void;
  isHoverd: boolean;
  handleSelect: (id: string) => void;
  selected: string;
};
const SelectDeliveryTime = ({
  selected,
  isHoverd,
  timeDetails,
  handleMouseOver,
  handleSelect,
  handleMouseLeave,
}: SelectDeliveryTimeProps) => {
  return (
    <div
      className={`${styles.selectDeliveryTimeWrapper} ${
        isHoverd && styles.timesHovered
      } ${selected === timeDetails.id && styles.timeSelected}`}
      onMouseEnter={() => {
        handleMouseOver(timeDetails.id);
      }}
      onClick={() => {
        handleSelect(timeDetails.id);
      }}
      onMouseLeave={handleMouseLeave}
    >
      <RadioButton
        checked={selected === timeDetails.id}
        handleChange={(e) => {
        }}
        value={timeDetails.id}
      />

      <div className={styles.timeText}>9 AM to 12 PM</div>
    </div>
  );
};

export default SelectDeliveryTime;
