import React from "react";
import styles from "./paymentModeCardLabelValue.module.scss";
const PaymnentModeLabelValue = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className={styles.labelValueWrapper}>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.labelValue}>{value}</p>
    </div>
  );
};

export default PaymnentModeLabelValue;
