import LoginBackground from "../../../../components/login/LoginBackground";
import styles from "./ResetLink.module.scss";
import mailIcon from "../../../../assets/images/login/mailIcon.svg";
import arrowLeft from "../../../../assets/images/Arrow-left.svg";
// import ResetIcon from "../../../../assets/images/login/ResetIcon.svg";
import { LOGIN_PAGE_STRINGS } from "../../../../common/constants/stringConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useState } from "react";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  loginRoutes,
} from "../../../../common/constants/urlConstants";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";

const ResetLinkComp = () => {
  const navigate = useNavigate();
  const [resend, setResend] = useState(false);
  const location = useLocation();
  const emailId = location?.state?.email;
  const dispatch = useDispatch();
  var maskId = (emailId: string) =>
    emailId
      .replaceAll(
        /(?<=.)[^@\n](?=[^@\n]*?[^@\n]@)|(?:(?<=@.)|(?!^)\G(?=[^@\n]*$)).(?=.*[^@\n]\.)/g,
        "*"
      )
      .replaceAll("**", "*");

  const forgotPassword = useAxios({
    axiosParams: {
      url: loginRoutes.forgotPassword,
      data: {
        email: emailId,
      },
    },
    method: APIMethods.post,
  });

  const resendLink = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await forgotPassword?.fetchData();
    if (response?.status === "SUCCESS") {
      setResend(true);
      dispatch(updateIsLoading(false));
    }
  };

  return (
    <>
      <section className={styles.resetLink}>
        <div
          className={styles.resetLink__goback}
          id="back"
          onClick={() => {
            navigate(routeConfig.login);
          }}
        >
          <img src={arrowLeft} alt="arrow" />
          <span>{LOGIN_PAGE_STRINGS.BACK}</span>
        </div>
        <div className={styles.accountVerification__body}>
          <div className={styles.icon}>
            {resend ? (
              <img src={mailIcon} alt="reset" />
            ) : (
              <img src={mailIcon} alt="mail" />
            )}
          </div>
          <div className={styles.headerText}>
            {resend
              ? LOGIN_PAGE_STRINGS.RESET_RESEND_LABEL
              : LOGIN_PAGE_STRINGS.RESET_LINK_LABEL}
          </div>
          <div className={styles.subheaderText}>
            {LOGIN_PAGE_STRINGS.RESET_LINK_TEXT}
            <span className={styles.email}>{maskId(emailId)}</span>
            <br />
            {LOGIN_PAGE_STRINGS.VERIFY_ACCOUNT_TEXT2}
          </div>
          <div className={styles.resendSection}>
            {LOGIN_PAGE_STRINGS.DIDNT_REC_MAIL}
            <span className={styles.link_primary} onClick={resendLink}>
              {LOGIN_PAGE_STRINGS.RESEND}
            </span>
          </div>
        </div>

        <div
          className={styles.accountVerification__footer}
          onClick={() => {
            navigate(routeConfig.forgotPassword, {
              state: { emailId: emailId },
            });
          }}
        >
          <div className={styles.link_primary}>
            {LOGIN_PAGE_STRINGS.CHANGE_EMAIL}
          </div>
        </div>
      </section>
    </>
  );
};

export const ResetLink = () => {
  return (
    <LoginBackground
      form={
        <>
          <ResetLinkComp />
        </>
      }
    ></LoginBackground>
  );
};
