import styles from "./ProductsListingStyles.module.scss";
import { ProductListingTabs } from "./components/productListingTabs/ProductListingTabs";
// import { constants } from "../../constants/constants";

export const ProductsListing = () => {
  return (
    <>
      <div className={styles.ordersListing}>
        <div className={styles.right}>
          <ProductListingTabs />
        </div>
      </div>
    </>
  );
};
