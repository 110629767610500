import { useEffect, useRef, useState } from "react";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
import {
  buyerAPIEndpoints,
  constants,
  preferencesConstants,
} from "../../../../common/constants/constants";
import { useAxios } from "../../../../services/useAxios";
import OutletCard from "../../components/OutletCard";
import styles from "./preferencesListing.module.scss";
import errorToast from "../../../../assets/images/errorToast.svg";
import successToast from "../../../../assets/images/successToast.svg";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import AddOutlet from "../../components/AddOutlet";
import useFetchCountries from "../../../../services/useCountry";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import Button from "../../../../common/components/button/Button";
import DeletePopup from "../../../../common/components/deletePopup";

const PreferencesListing = () => {
  const [hovered, setHovered] = useState("");
  const [outletList, setOutletList] = useState<any>([]);
  const dispatch = useDispatch();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [selectedOutlet, setSelectedOutlet] = useState<any>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [editOutletIndex, setEditOutletIndex] = useState(-1);
  const { countriesList } = useFetchCountries();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const deleteIdRef = useRef<string | null>(null);
  const [showAddOutlet, setShowAddOutlet] = useState<boolean>(false);
  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setEditOutletIndex(-1);
    setShowAddOutlet(false)
  };

  const handleMouseOver = (id: string) => {
    setHovered(id);
  };
  const handleMouseLeave = () => {
    setHovered("");
  };
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") as any);
  const businessId = loggedInUser?.business_details?.[0]?.business_setup_id;

  const getList = useAxios({
    axiosParams: {
      url: buyerAPIEndpoints.outletsList.replace(":businessId", businessId),
    },
    method: "GET",
  });

  const [customerId] = useState(
    loggedInUser &&
      loggedInUser?.business_details &&
      loggedInUser?.business_details?.[0]?.customer_id
  );

  const deleteOutlet = useAxios({
    axiosParams: {
      url: buyerAPIEndpoints.deleteOutlet.replace(":businessId", businessId),
      data: {
        updated_customer_id: customerId,
      },
    },
    method: "DELETE",
  });
  useEffect(() => {
    if (countriesList?.length > 0) {
      const defaultCountry = countriesList?.find(
        (ele: any) => ele?.iso_2 === "ae"
      );
      if (defaultCountry) {
        const list = defaultCountry?.emirates;
        const defaultEmiratesList = list.map((item: any) => ({
          ...item,
          value: item?.name,
        }));
        setEmiratesList(defaultEmiratesList);
      }
    }
  }, [countriesList]);
  const fetchOutlets = async () => {
    try {
      dispatch(updateIsLoading(true));
      let response: any = await getList.fetchData();
      let list = response?.data?.outlets?.map((ele: any) => {
        return {
          ...ele,
          makeDefault: ele?.is_default,
          address: ele.addresses[0]?.address_1,
          area: ele.addresses[0]?.city,
          emirate: ele?.addresses[0]?.emirate?.name,
          country: ele?.addresses[0]?.country?.display_name,
          emirateData: ele?.addresses[0]?.emirate,
          countryData: ele?.addresses[0]?.country,
          contact: ele?.phone_number.split("#").join(" "),
          contactName: ele?.contact_name,
        };
      });
      dispatch(updateIsLoading(false));
      setSelectedId(list?.find((each: any) => each?.is_default)?.id);
      setOutletList(list);
    } catch (error) {
      dispatch(updateIsLoading(false));
      setToastData({ message: "Unable to fetch the outlets.", status: "Fail" });
    }
  };

  const updateDefaultOutlet = useAxios({
    axiosParams: {
      url: buyerAPIEndpoints.editOutlet
        .replace(":businessId", businessId)
        .replace(":storeId", selectedOutlet?.id),
      data: selectedOutlet,
    },
    method: "PUT",
  });

  const handleOutletClick = async (outlet: any) => {
    const payload = {
      id: outlet?.id,
      is_default: true,
      area: outlet?.city,
      address: outlet?.address,
      country: outlet?.countryData,
      emirate: outlet?.emirateData,
      contact_name: outlet?.contact_name,
      saved_as: outlet?.saveOutletAs,
      phone_number: outlet?.phone_number,
      updated_customer_id: loggedInUser?.id,
    };
    setSelectedOutlet(payload);
  };

  const update = async () => {
    dispatch(updateIsLoading(true));
    try {
      let response: any = await updateDefaultOutlet.fetchData();
      if (response?.status) {
        fetchOutlets();
        setToastData({
          message: "Outlet updated successfully.",
          status: "Success",
        });
      } else {
        setToastData({
          message: "Unable to update the outlet.",
          status: "Fail",
        });
      }
      dispatch(updateIsLoading(false));
    } catch (err) {
      dispatch(updateIsLoading(false));
      setToastData({ message: "Unable to update the outlet.", status: "Fail" });
    }
  };
  useEffect(() => {
    if (selectedOutlet && selectedOutlet?.id) {
      update();
    }
  }, [selectedOutlet]);

  useEffect(() => {
    fetchOutlets();
  }, []);
  const editOutlet = (index: number) => {
    setEditOutletIndex(index);
    setShowAddOutlet(true)
  };
  const getEditedOutletData = (outlet: any) => {
    if (outlet) {
      const payload = {
        id: outletList[editOutletIndex]?.id,
        is_default: outlet?.is_default,
        area: outlet?.area,
        address: outlet?.address,
        country: outlet?.country,
        emirate: outlet?.emirate,
        contact_name: outletList[editOutletIndex]?.contact_name,
        saved_as: outlet?.saved_as,
        phone_number: outletList[editOutletIndex]?.phone_number,
        updated_customer_id: outletList[editOutletIndex]?.updated_customer_id,
      };
      setSelectedOutlet(payload);
    }
  };

  const handleDeleteOutlet = (id: string) => {
    deleteIdRef.current = id;
    setShowDeletePopup(true);
  };
  const handleConfirmDelete = async () => {
      setShowDeletePopup(false);
    dispatch(updateIsLoading(true))
    const response: any = await  deleteOutlet.fetchData({
      axiosParams: {
        url: buyerAPIEndpoints.deleteOutlet
          .replace(":businessId", businessId)
          .replace(":outletId", deleteIdRef.current || ""),
      },
    });
    dispatch(updateIsLoading(false));

    if (response.status === "SUCCESS") {
      setToastData({
        message: "Deleted outlet successfully",
        status: "Success",
      });
    } else {
       setToastData({
         message: "Something went wrong",
         status: "Error",
       });
    }
  };
  const handleCancelDelete = () => {
    deleteIdRef.current = null;
    setShowDeletePopup(false);
  };
  return (
    <>
      <section className={styles.preferencesContainer}>
        <div className={styles.header}>{preferencesConstants.headerText}</div>
        <div className={styles.each}>
          <div className={styles.accHeader}>
            {preferencesConstants.outletsHeader}
          </div>
          <div className={styles.outletSection}>
            <div className={styles.label}>
              {preferencesConstants.outletText}
            </div>
            <div className={styles.outlets}>
              {outletList?.map((outlet: any, index: number) => {
                return (
                  <OutletCard
                    key={outlet?.id}
                    isHoverd={hovered === outlet?.id}
                    addressDetails={outlet}
                    handleMouseOver={handleMouseOver}
                    handleMouseLeave={handleMouseLeave}
                    deleteOutlet={handleDeleteOutlet}
                    editOutlet={() => editOutlet(index)}
                    emiratesList={emiratesList}
                    onClick={() => {
                      handleOutletClick(outlet);
                    }}
                    // handleSelect={handleSelectAddress}
                    selected={selectedId}
                    businessStatus={""}
                    countriesList={undefined}
                    preferences={true}
                    
                  />
                );
              })}
            </div>
           {outletList.length > 0 && 
           <div className={styles.addOutletBtnWrapper}>
              <Button
                label="Add Outlet"
                width="120px"
                handleClick={() => {
                  setShowAddOutlet(true);
                }}
              />
            </div>}
          </div>
        </div>
      </section>
      {showAddOutlet && (
        <AddOutlet
          isEditOutlet={editOutletIndex !== -1}
          showAddOutlet={showAddOutlet}
          handleClose={handleClose}
          countriesList={countriesList}
          outletData={getEditedOutletData}
          editOutlet={{
            ...outletList[editOutletIndex],
            emirate: outletList[editOutletIndex]?.emirateData,
          }}
          fetchOutlets={fetchOutlets}
          emiratesList={emiratesList}
          outletNames={outletList.map((el:any) => el.saved_as)}
        />
      )}
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status == "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
        {showDeletePopup && <DeletePopup
          open={showDeletePopup}
          handleClose={() => setShowDeletePopup(false)}
          title="Delete outlet"
          handleCancel={handleCancelDelete}
          handleConfirm={handleConfirmDelete}
          popupContent="Are you sure you want to delete this outlet ?"
        />}
    </>
  );
};

export default PreferencesListing;
