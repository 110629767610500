import { createSlice } from "@reduxjs/toolkit";

const transformKeys = (item: any) => {
  return {
      id: item?.id,
      invoiceNo: item?.invoicenumber ?? "-",
      paidStatus: item?.payment_status ?? "-",
      orderId: item?.orderid ?? "-",
      outlet: item?.outletname ?? "-",
      supplier: item?.warehousename ?? "-",
      estAmount: (+item?.estamount) ?? 0,
      actualAmount: (+item?.actual_amount) ?? 0,
  };
};

const supplierInvoiceListSlice = createSlice({
  name: "supplierInvoiceList",
  initialState: [],
  reducers: {
    addInvoiceList: (state, action) => {
      const transformedPayload = action.payload.map(transformKeys);
      return transformedPayload;
    },
  },
});

export const { addInvoiceList } = supplierInvoiceListSlice.actions;

export default supplierInvoiceListSlice.reducer;
