import styles from "./MarketPlace.module.scss";
import ShopByCategory from "./shopByCategory/ShopByCategory";
// import ProductOnSale from "./productsOnSale/ProductOnSale";
// import ItemViewedPreviously from "./itemViewedPreviously/ItemViewedPreviously";
import FeatureBrandSupply from "./featureBrandSupply/FeatureBrandSupply";
// import banner from "../../../assets/images/banner.svg";
import React, { useEffect, useState } from "react";
import { useAxios } from "../../../services/useAxios";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
export const groupIntoRows = (dataList: any[]) => {
  const itemsPerRow = dataList.length/2 + 1;
  const rows: any[][] = [];
  for (let i = 0; i < dataList.length; i += itemsPerRow) {
    const rowItems = dataList.slice(i, i + itemsPerRow);
    rows.push(rowItems);
  }

  return rows;
};

const MarketPlace = () => {
  const dispatch  = useDispatch()
  const [marketPlaceData, setMarketPlaceData] = useState<any>({
    brands: [],
    product_categories: [],
    suppliers: [],
    products: [],
  });
  const [brandsNSuppliers, setBrandsNSuppliers] = useState<any>();
  console.log(marketPlaceData, brandsNSuppliers);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [businessId] = useState(
    loggedInUser?.business_details?.[0]?.business_setup_id
  );

  const fetchMarketPlaceData = useAxios({
    axiosParams: {
      url: `store/marketplace/${businessId}`,
    },
    method: "GET",
  });
  const getMarketPlace = async () => {
    try {
      dispatch(updateIsLoading(true));
      let response: any = await fetchMarketPlaceData.fetchData();
      console.log(response?.data[0]?.brands, "response");
      let brands = response?.data[0]?.brands?.brands;
      brands = brands?.map((ele: any) => {
        return {
          ...ele,
          brandName: ele.name,
          brandImg: ele.image,
          isBrand: true,
        };
      });
      console.log(brands, "brands");
      let suppliers = response?.data[0]?.suppliers?.suppliers;
      suppliers = suppliers?.map((ele: any) => {
        return {
          ...ele,
          id: ele.customer_id,
          brandName: ele.business_setup_business_name,
          brandImg: ele.image,
          isSupplier: true,
        };
      });
      setBrandsNSuppliers([
        ...brands?.slice(0, 10),
        ...suppliers?.slice(0, 10),
      ]);
      let categories =
        response?.data[0]?.product_categories?.product_categories.filter(
          (ele: any) => ele.handle !== "offline-product-category"
        );
      categories = categories.map((ele: any) => {
        return {
          ...ele,
          label: ele?.name,
          imgSrc: ele.image,
        };
      });
      let data = {
        brands: brands,
        product_categories: groupIntoRows(categories),
        suppliers: response?.suppliers,
        products: response?.products,
      };
      console.log(categories, data, "got data to mark");
      setMarketPlaceData(data);
      dispatch(updateIsLoading(false));
      // dispatch(updateData({ ...data, product_categories: categories }));
    } catch (error) {
      console.log("Could not get response", error);
      dispatch(updateIsLoading(false));
    }
  };
  useEffect(() => {
    getMarketPlace();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.rightBodyContainer}>
        {/* <div className={styles.banner}>
          <img src={banner} alt="banner" />
        </div> */}
        {marketPlaceData?.product_categories?.length > 0 && (
          <ShopByCategory rows={marketPlaceData.product_categories} />
        )}
        {/* <ProductOnSale />
        <ItemViewedPreviously /> */}
        {brandsNSuppliers && <FeatureBrandSupply data={brandsNSuppliers} />}
      </div>
    </div>
  );
};

export default MarketPlace;
