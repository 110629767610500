import React from "react";
import styles from "./paymentModeCard.module.scss";
import onlinePaymentIcon from "../../images/onlinePaymentIcon.svg";
import infoIcon from "../../images/infoIcon.svg";
import codIcon from "../../images/codIcon.svg";
import codOnlineIcon from "../../images/codOnlineIcon.svg";
import {styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
// import PaymnentModeLabelValue from "../paymentModeCardLabelValue";
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const PaymentModeCard = ({
  renderLabelValue,
  paymentMode,
}: {
  renderLabelValue: any;
  paymentMode: string;
}) => {
  const paymentModeImg =
    paymentMode === "COD"
      ? codIcon
      : paymentMode === "online"
      ? onlinePaymentIcon
      : codOnlineIcon;
  const paymentModeText =
    paymentMode === "COD"
      ? "COD Payment"
      : paymentMode === "online"
      ? "Online payment"
      : "Online/ COD Payment";
  return (
    <div className={styles.paymentModeCardWrapper}>
      <div className={styles.paymentModeHeaderWrapper}>
        <img src={paymentModeImg} alt="payment mode" />
        <h3 className={styles.paymentModeHeader}>{paymentModeText}</h3>
        <LightTooltip title={`Supplier only accepts ${paymentMode} payment`} >
        <img src={infoIcon} alt="info icon" />
        </LightTooltip>

      </div>
      {renderLabelValue()}
    </div>
  );
};

export default PaymentModeCard;
