import { useEffect, useState } from "react";
import styles from "./SpecialPricingListCardStyles.module.scss";
import Button from "../../../../components/button/Button";
import { useDispatch } from "react-redux";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods } from "../../../../constants/urlConstants";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { constants } from "../../../../constants/constants";
import DeletePopup from "../../../../components/deletePopup";
import { ToastNotification } from "../../../../components/toastNotification/ToastNotification";
import errorToast from "../../../../../assets/images/errorToast.svg";
import successToast from "../../../../../assets/images/successToast.svg";
import Dropdown from "../../../../components/dropdown/Dropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import * as yup from "yup";
const SpecialPricingListCard = (props: any) => {
  const {
    id,
    productName,
    buyerBusinessName,
    supplierBusinessName,
    primaryUnitValue,
    secondaryUnitLabel,
    secondaryUnitValue,
    variantPrice,
    updatedCustomerId,
    setIsDataUpdated,
    moOption,
    moValue,
    moQuantity,
    actualAmount,
  } = props;

  const [ispriceEdit, setisPriceEdit] = useState(false);
  const [isMoValueEdit, setisMoValueEdit] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [selectedMoOption, setSelectedMoOption] = useState("");

  const dispatch = useDispatch();
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  // Form Schema
  const schema = yup.object().shape({
    id: yup.string(),
    price: ispriceEdit
      ? yup
          .number()
          .positive()
          .moreThan(0, "Enter price greater than 0")
          .typeError("Enter price")
          .test(
            "two-decimal-points",
            "Only two decimal places are allowed.",
            (value) =>
              value?.toLocaleString().split(".")[1]
                ? value?.toLocaleString().split(".")[1].length <= 2
                : true
          )
          .test(
            "price-limitation",
            `Special price should be less than actual product price (${(actualAmount/100)?.toFixed(2)} AED)`,
            (value: any ) =>
              value < (actualAmount/100)?.toFixed(2)
          )
          .required("Enter order amount")
      : yup.number(),
    moOptiob: yup.object(),
    moValue: isMoValueEdit
      ? yup
          .number()
          .positive()
          .moreThan(0, "Enter amount greater than 0")
          .typeError("Enter value")
          .required()
          .when("moOptiob", {
            is: (moOptiob: any) => moOptiob?.value?.toLowerCase() === "mov",
            then: (schema) =>
              schema.test(
                "two-decimal-points",
                "Only two decimal places are allowed.",
                (value) =>
                  value?.toLocaleString().split(".")[1]
                    ? value?.toLocaleString().split(".")[1].length <= 2
                    : true
              ),
            otherwise: (schema) =>
              schema.test(
                "only-numbers",
                "Only numbers are allowed",
                (value) => !value?.toLocaleString().includes(".")
              ),
          })
      : yup.number(),
  });

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  //onchange for price field, mov/moq and order value fields
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue("id", id);
      if (name === "moOptiob") {
        setValue(
          "moValue",
          text?.value?.toLowerCase() === "moq" ? moQuantity : moValue
        );
        setSelectedMoOption(text);
      }
      setValue(name, text);
      trigger(name);
    },
    [setValue, moQuantity,moValue]
  );

  const [userType] = useState(loggedInUser && loggedInUser?.typeOfUser?.[0]);
  
  // Get payload for API request
  const getPayload = () => {
    const consumptionType = (getValues("moOptiob") as any)?.value;
    let data: any = {
      price: getValues("price"),
      consumption_type: consumptionType,
      updated_by: updatedCustomerId,
    };
    if (consumptionType?.toLowerCase() === "moq") {
      data = { ...data, consumption_quantity: getValues("moValue") };
    } else {
      data = { ...data, consumption_amount: getValues("moValue") };
    }
    return data;
  };

  // Axios hook for API requests - updating the special pricing details
  const { fetchData } = useAxios({
    axiosParams: {
      url: `store/special_pricing/edit/${id}`,
      data: getPayload(),
    },
    method: APIMethods.post,
  });

  // Axios hook for API requests - deleting a special pricing record
  const deleteRecord = useAxios({
    axiosParams: {
      url: `store/special_pricing/${id}`,
    },
    method: APIMethods.delete,
  });

  //function to update the special pricing details
  const updatePriceApi = async () => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await fetchData();
      if (response && response?.status === "SUCCESS") {
        console.log("updated success");
        setIsDataUpdated(true);
        dispatch(updateIsLoading(false));
        setToastData({
          message:
            "The selected products have been successfully offered at a special price to the buyer.",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      } else {
        console.log(response, "response?.error_msg");
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        dispatch(updateIsLoading(false));
      }
    } catch (e: any) {
      dispatch(updateIsLoading(false));
      setToastData({ message: "Unable to edit the price.", status: "Fail" });
    }
  };
  const updatePrice = () => {
    if (getValues("price")) {
      updatePriceApi();
    } else {
      // setErrorMessage("Enter price");
    }
  };

  //function to delete the special pricing record
  const deleteProduct = async () => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await deleteRecord?.fetchData();
      if (response && response?.status === "SUCCESS") {
        console.log("updated success");
        setIsDataUpdated(true);
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(false));
      }
    } catch (e: any) {
      dispatch(updateIsLoading(false));
    }
  };
  const handleCancelDelete = () => {
    setShowDeletePopup(false);
  };
  const handleConfirmDelete = () => {
    setShowDeletePopup(false);
    deleteProduct();
  };
  const options = [
    {
      value: "MOV",
      lable: "MOV",
      name: "MOV",
    },
    {
      value: "MOQ",
      lable: "MOQ",
      name: "MOQ",
    },
  ];

  useEffect(() => {
    setValue("price", variantPrice);
    setValue("moOptiob", moOption);
    setValue(
      "moValue",
      moOption?.value?.toLowerCase() === "mov" ? moValue : moQuantity
    );
    setSelectedMoOption(moOption);
    setError("price", {});
    setError("moValue",{});
    setError("moOptiob",{});
  }, [variantPrice]);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  useEffect(() => {
    setSelectedVariantId(id);
  }, [id, isDropdownOpened]);
  return (
    <div className={styles.card}>
      {
        <tr key={id}>
          <td>
            <div
              className={`${styles.column} ${
                userType === "supplier" && styles.prodName
              }`}
              title={productName}
            >
              {productName?.length > 50
                ? productName?.slice(0, 30) + "..."
                : productName}
            </div>
          </td>
          <td>
            <div className={styles.column} title={buyerBusinessName}>
              {buyerBusinessName?.length > 30
                ? buyerBusinessName?.slice(0, 30) + "..."
                : buyerBusinessName}
            </div>
          </td>
          {userType === "admin" && (
            <td>
              <div className={styles.column} title={supplierBusinessName}>
                {supplierBusinessName?.length > 30
                  ? supplierBusinessName?.slice(0, 30) + "..."
                  : supplierBusinessName}
              </div>
            </td>
          )}
          <td className={styles.width}>
            <div
              className={`${styles.column}`}
            >{`${primaryUnitValue} x ${secondaryUnitValue} ${secondaryUnitLabel}`}</div>
          </td>
          <td className={styles.width}>
            <div>
              {!ispriceEdit ? (
                <div
                  className={styles.ellipse}
                  onDoubleClick={() => {
                    setisPriceEdit(true);
                    setSelectedVariantId(id);
                  }}
                  title={id === selectedVariantId ? getValues("price") : variantPrice}
                >
                  {id === selectedVariantId ? getValues("price") : variantPrice}
                </div>
              ) : (
                <>
                  <div className={styles.editableField}>
                    <input
                      type="number"
                      id="supplier"
                      value={watch("price")}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      {...register("price")}
                      onChange={(e: any) =>
                        onChangeField("price")(e.target.value)
                      }
                      onBlur={(e: any) => {
                        setisPriceEdit(false);
                        if (e.target.value?.length === 0) {
                          setValue("price", variantPrice);
                          setError("price", {});
                        }
                      }}
                      autoFocus={ispriceEdit}
                    />
                  </div>
                </>
              )}
              {id === selectedVariantId && errors?.price?.message && (
                <div className={styles.errorMessage}>
                  {errors?.price?.message}
                </div>
              )}
            </div>
          </td>
          <td className={styles.width}>
            <div className={`${styles.editableField}`}>
              <Dropdown
                label={""}
                register={register("moOptiob")}
                options={options}
                id={""}
                onChange={(value: any) => onChangeField("moOptiob")(value)}
                defaultLabel={
                  id === selectedVariantId
                    ? (selectedMoOption as any)?.value
                    : moOption?.value
                }
                selectedValue={id === selectedVariantId
                  ? (selectedMoOption as any)
                  : moOption}
                minWidth={"100px"}
                setIsDropdownOpened={setIsDropdownOpened}
              />
            </div>
          </td>
          <td className={styles.width}>
            <>
              {!isMoValueEdit ? (
                <div
                  className={styles.ellipse}
                  onDoubleClick={() => {
                    setisMoValueEdit(true);
                    setSelectedVariantId(id);
                  }}
                  title={id === selectedVariantId
                    ? getValues("moValue")
                    : (getValues("moOptiob") as any)?.value?.toLowerCase() ===
                      "mov"
                    ? moValue
                    : moQuantity}
                >
                  {id === selectedVariantId
                    ? getValues("moValue")
                    : (getValues("moOptiob") as any)?.value?.toLowerCase() ===
                      "mov"
                    ? moValue
                    : moQuantity}
                </div>
              ) : (
                <>
                  <div className={styles.editableField}>
                    <input
                      type="number"
                      id="supplier"
                      value={watch("moValue")}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      {...register("moValue")}
                      onChange={(e: any) =>
                        onChangeField("moValue")(e.target.value)
                      }
                      onBlur={(e: any) => {
                        setisMoValueEdit(false);
                        if (e.target.value?.length === 0) {
                          setValue(
                            "moValue",
                            (
                              getValues("moOptiob") as any
                            )?.value?.toLowerCase() === "mov"
                              ? moValue
                              : moQuantity
                          );
                          setError("moValue", {});
                        }
                      }}
                      autoFocus={isMoValueEdit}
                    />
                  </div>
                </>
              )}
              {id === selectedVariantId && errors?.moValue?.message && (
                <div className={styles.errorMessage}>
                  {errors?.moValue?.message}
                </div>
              )}
            </>
          </td>
          <td>
            <div className={styles.actions}>
              <Button
                label={"Update"}
                handleClick={updatePrice}
                link
                disabled={
                  (errors?.moValue?.message
                    ? errors?.moValue?.message?.length > 0
                    : false) ||
                  (errors?.price?.message
                    ? errors?.price?.message?.length > 0
                    : false)
                }
              />
              <Button
                label={"Delete"}
                handleClick={() => setShowDeletePopup(true)}
                link
              />
            </div>
          </td>
        </tr>
      }
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          open={showDeletePopup}
          handleClose={() => setShowDeletePopup(false)}
          title="Delete Special Pricing"
          handleCancel={handleCancelDelete}
          handleConfirm={handleConfirmDelete}
          popupContent="Are you sure you want to remove the special price for the buyer?"
        />
      )}
    </div>
  );
};

export default SpecialPricingListCard;
