/**
 * please add the required fields in the arrey of objects and pass them as props.
 * if required refer the TabComponent component and add required fields in another elseif condition if have time update to switch case.
 * please make the compoenent generic mostly.
 * for array of objects refer invoices list page compoentn.
 */
import Dropdown from "../../../common/components/dropdown/Dropdown";
// import CheckboxButton from "../checkbox/CheckboxButton";
// import DropdownTextField from "../formFields/dropdown-text_Field/DropdownTextField";
import Button from "./../button/Button";
import styles from "./PageFilter.module.scss";
// import { IPageFilterProps } from "../../../models/IpageFilterProps";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { constants } from "../../constants/constants";
import { useForm } from "react-hook-form";
import DropdownTextField from "../formFields/dropdown-text_Field/DropdownTextField";
function PageFilter({
  overlayCallback,
  ShowSupplierListInFilter,
  submit,
  filtrationKeys,
  isadmin,
}: any) {
  const [supplier, setSupplier] = useState<any>();
  const [estimatedAmountDropdown, setEstimatedAmountDropdown] = useState<any>(
    constants.invoiceFilters.estimatedAmountDropdown[0]
  );
  const [actualAmountDropdown, setActualAmountDropdown] = useState<any>(
    constants.invoiceFilters.actualAmountDropdown[0]
  );
  // const onSubmit = (data: any) => {
  //   props?.onSubmitData && props?.onSubmitData(data);
  //   props?.overlayCallback && props?.overlayCallback({ right: false });
  //   resetForm();
  // };
  // const resetForm = () => {
  //   props?.filterOptions?.map((item: any) => {
  //     if (item?.type === "select") {
  //       item?.resetFunction(null);
  //     } else if (item?.type === "selectAndInput") {
  //       item?.resetFunction(item?.options[0]);
  //     }
  //   });
  //   props?.reset && props?.reset();
  // };

  const schema = yup.object({
    supplier: yup.object().nullable(),
    actualAmount: yup
      .string()
      .nullable()
      .test(
        "actual-amount-test",
        "Actual amount should not be negative",
        (actualAmount) =>
          actualAmount != "" && Number(actualAmount) < 0 ? false : true
      ),
    estimatedAmount: yup
      .string()
      .nullable()
      .test(
        "estimated-amount-test",
        "Estimated amount should not be negative",
        (estimatedAmount) =>
          estimatedAmount != "" && Number(estimatedAmount) < 0 ? false : true
      ),
    estimatedAmountDropdown: yup.mixed().optional(),
    actualAmountDropdown: yup.mixed().optional(),
  });
  const defaultValues = {
    estimatedAmountDropdown:
      constants.invoiceFilters.estimatedAmountDropdown[0],
    actualAmountDropdown: constants.invoiceFilters.actualAmountDropdown[0],
    estimatedAmount: "",
    actualAmount: "",
  };
  const {
    register,
    formState: { errors },
    // handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  });
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "estimatedAmountDropdown") {
        // setValue('estimatedAmountDropdown', text)
        setEstimatedAmountDropdown(text);
      } else if (name === "actualAmountDropdown") {
        // setValue('actualAmountDropdown', text)
        setActualAmountDropdown(text);
      }
    },
    [setValue]
  );
  const submitClick = () => {
    const data = getValues();
    submit(data);
  };

  const handleReset = () => {
    reset();
    setSupplier(null);
    setEstimatedAmountDropdown(
      constants.invoiceFilters.estimatedAmountDropdown[0]
    );
    setActualAmountDropdown(constants.invoiceFilters.actualAmountDropdown[0]);
  };
  const getSupplier = (id: string) => {
    if (ShowSupplierListInFilter?.length > 0) {
      return ShowSupplierListInFilter?.find(
        (supplier: any) => supplier?.supplier_id === id
      );
    }
  };

  useEffect(() => {
    if (filtrationKeys?.filterData?.activeFilters) {
      if (filtrationKeys?.filterData?.estimatedAmountValue?.length > 0) {
        setValue(
          "estimatedAmount",
          filtrationKeys?.filterData?.estimatedAmountValue
        );
        let estDropdown =
          constants.invoiceFilters.estimatedAmountDropdown?.find(
            (item: any) =>
              item.value === filtrationKeys?.filterData?.estimatedAmountDropdown
          );
        setEstimatedAmountDropdown(estDropdown);
        setValue('estimatedAmountDropdown', estDropdown);
      }
      if (filtrationKeys?.filterData?.actualAmountValue?.length > 0) {
        setValue("actualAmount", filtrationKeys?.filterData?.actualAmountValue);
        let actDropdown = constants.invoiceFilters.actualAmountDropdown?.find(
          (item: any) =>
            item.value === filtrationKeys?.filterData?.actualAmountDropdown
        );
        setActualAmountDropdown(actDropdown);
        setValue('actualAmountDropdown', actDropdown);
      }
      if (isadmin) {
        if (filtrationKeys?.filterData?.supplier?.length > 0) {
          let supplierId = getSupplier(filtrationKeys?.filterData?.supplier);
          setSupplier(supplierId);
        }
      }
    }
  }, [filtrationKeys]);

  return (
    <div className={styles.body}>
      <div className={styles.form}>
        {/* {props.filterOptions.map((item, ind: number) => {
          if (item.type === "checkBox") {
            return (
              <div className={styles.status}>
                <div className={styles.statusCheckbox}>
                  <CheckboxButton
                    partiallySelected={true}
                    key={`filter_${ind}`}
                  />
                  <div className={styles.statusLabel}>{item.label}</div>
                </div>
                <div className={styles.statusInputs}>
                  <div className={styles.column}>
                    {item?.group?.map((element: string) => {
                      return (
                        <div className={styles.statusOption}>
                          <CheckboxButton
                            register={
                              props?.register &&
                              props?.register(item?.nameToRegister)
                            }
                            handleChange={(e: any) => e}
                          />
                          <div>{element}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          } else if (item.type === "select") {
            return (
              <div className={styles.buyer}>
                <Dropdown
                  key={`filter_${ind}${item?.selectedValue?.value}`}
                  selectedValue={item?.selectedValue}
                  defaultValue={item?.selectedValue}
                  id={item?.label}
                  label={item?.label}
                  options={item?.options}
                  defaultLabel={item?.selectedValue?.name ?? item?.placeHolder}
                  minWidth="100%"
                  isDropdownOutlineRequired={true}
                  register={
                    props?.register && props?.register(item?.nameToRegister)
                  }
                  onChange={
                    props?.onChangeField &&
                    props?.onChangeField(item?.nameToRegister)
                  }
                  errorMessage={
                    props?.errors?.[`${item?.nameToRegister}`]?.message
                  }
                />
              </div>
            );
          } else if (item.type === "selectAndInput") {
            return (
              <div className={styles.buyer}>
                <DropdownTextField
                  minWidth={"100%"}
                  value={
                    props?.watch &&
                    props?.watch(`${item?.nameToRegisterTextField}`)
                  }
                  key={`filter_${ind}`}
                  registerDropdown={
                    props?.register &&
                    props?.register(item?.nameToRegisterDropdownField)
                  }
                  isCurrenctReq={true}
                  id={item?.label}
                  label={item?.label}
                  dropdownList={item?.options}
                  textFieldPlaceholder={item.placeHolder}
                  registerTextField={
                    props?.register &&
                    props?.register(item?.nameToRegisterTextField)
                  }
                  selectedDropdownValue={item?.selectedValue}
                  onTextFieldChange={
                    props?.onChangeField &&
                    props?.onChangeField(item?.nameToRegisterTextField)
                  }
                  errorMessage={
                    props?.errors?.[`${item?.nameToRegisterTextField}`]?.message
                  }
                  textFieldType={item?.inputType}
                  onDropdownChange={
                    props?.onChangeField &&
                    props?.onChangeField(item?.nameToRegisterDropdownField)
                  }
                />
              </div>
            );
          }
        })} */}
        {isadmin && (
          <div className={styles.buyer} data-testid="supplier-dropdown">
            <Dropdown
              key={`dropdown_${supplier?.name}`}
              customDropdownStyles={styles.dropDownMargin}
              id={"supplier"}
              label="supplier"
              options={
                ShowSupplierListInFilter ??
                ShowSupplierListInFilter?.ShowSupplierListInFilter
              }
              minWidth="100%"
              isDropdownOutlineRequired={true}
              register={register("supplier")}
              onChange={(selectedOption: any) => {
                onChangeField("supplier")(selectedOption);
                setSupplier(selectedOption);
              }}
              defaultLabel={supplier?.name ?? "Select the supplier"}
              selectedValue={supplier}
              defaultValue={supplier}
              errorMessage={errors.supplier?.message as any}
              searchField={true}
            />
          </div>
        )}
        <div className={styles.buyer}>
          <DropdownTextField
            minWidth={"100%"}
            id={"EstimatedAmount"}
            label="Estimated amount"
            dropdownList={constants.invoiceFilters.estimatedAmountDropdown}
            textFieldPlaceholder={"Enter price"}
            isCurrenctReq={true}
            registerDropdown={register("estimatedAmountDropdown")}
            onDropdownChange={onChangeField("estimatedAmountDropdown")}
            selectedDropdownValue={estimatedAmountDropdown}
            registerTextField={register("estimatedAmount")}
            onTextFieldChange={onChangeField("estimatedAmount")}
            value={watch("estimatedAmount")}
            errorMessage={errors.estimatedAmount?.message}
            textFieldType={"number"}
          />
        </div>
        <div className={styles.buyer}>
          <DropdownTextField
            minWidth={"100%"}
            id={"ActualAmount"}
            label="Actual amount"
            dropdownList={constants.invoiceFilters.estimatedAmountDropdown}
            registerDropdown={register("actualAmountDropdown")}
            onDropdownChange={onChangeField("actualAmountDropdown")}
            selectedDropdownValue={actualAmountDropdown}
            textFieldPlaceholder={"Enter price"}
            isCurrenctReq={true}
            registerTextField={register("actualAmount")}
            onTextFieldChange={onChangeField("actualAmount")}
            value={watch("actualAmount")}
            errorMessage={errors.actualAmount?.message}
            textFieldType={"number"}
          />
        </div>
      </div>
      <div className={styles.filterActions}>
        <div className={styles.reset} onClick={() => handleReset()}>
          Reset
        </div>
        <div>
          <Button
            label={"Cancel"}
            handleClick={() => {
              // resetForm();
              overlayCallback && overlayCallback({ right: false });
            }}
            width="120px"
            secondary={true}
          />
        </div>
        <div>
          <Button
            label={"Confirm"}
            handleClick={() => submitClick()}
            width="120px"
          />
        </div>
      </div>
    </div>
  );
}

export default PageFilter;
