import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./PageSearch.module.scss";
import searchImage from "../../../assets/images/so-search.svg";
import searchIcon from "../../../assets/images/ordersListing/searchIcon.svg";
import cancelIcon from "../../../assets/images/cancelToast.svg";
import { IPageSearchProps } from "../../../models/IpageSearchProps";
import _debounce from "lodash/debounce";
import { useCancelToken } from "../../../hooks/useCacelToken";

const PageSearch = ({
  customStyles,
  handleSearch,
  customPlaceHolder,
  activeTab
}: IPageSearchProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  let { cancelTokenRef, createCancelToken } = useCancelToken();
  const [searchValue, setSearchValue] = useState<undefined | string>(undefined);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value?.trimStart());
    // handleSearch(e.target.value.trimStart());
  };
  const handleSearchIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowSearch(!showSearch);
  };
  const handleCancel = () => {
    setSearchValue("");
    setShowSearch(!showSearch);
    handleSearch("");
  };
  const debouncedSearch = useCallback(
    _debounce((searchTerm: string) => {
      createCancelToken();
      handleSearch(searchTerm, cancelTokenRef.current.token);
    }, 400),
    [activeTab]
  );

  useEffect(() => {
    if (typeof searchValue !== "undefined") {
      debouncedSearch(searchValue);
    }
  }, [searchValue, debouncedSearch]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showSearch &&
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        inputRef.current.value === ""
      ) {
        setShowSearch(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSearch]);
  return (
    <div
      className={`${styles.actions} ${showSearch && styles.actions2}`}
      style={customStyles && { ...customStyles }}
    >
      <div className={styles.search}>
        {showSearch ? (
          <div className={styles.searchContainer}>
            <div className={styles.searchIconInput}>
              <img src={searchImage} alt="searchIcon" height="24px" />
              <input
                value={searchValue}
                type="search"
                placeholder={
                  customPlaceHolder?.length ? customPlaceHolder : "Search..."
                }
                className={styles.searchInput}
                onChange={(e: any) => handleOnChange(e)}
                autoFocus
                ref={inputRef}
              />
            </div>
            {searchValue && searchValue?.length > 0 && (
              <div className={styles.cancel} onClick={handleCancel}>
                <img src={cancelIcon} alt="cancel" />
              </div>
            )}
          </div>
        ) : (
          <div
            className={styles.searchIcon}
            onClick={(e) => handleSearchIcon(e)}
          >
            <img src={searchIcon} alt="search"></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSearch;
