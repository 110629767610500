// Search.js
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../../assets/images/clock.svg";
import close from "../../../assets/images/close.svg";
import gobalSearchIcon from "../../../assets/images/global-search.svg";
import { SearchProps } from "../../../models/ISearch";
import {
  setSearchHistory,
  setSearchValue,
} from "../../../reducerSlices/headerSlice";
import styles from "./Search.module.scss";
import noDataImage from "../../../assets/images/noDataFound.svg";
import crossIcon from "../../../assets/images/cancelToast.svg";
import { ISearchSuggestion } from "../../../models/ISearchSuggestion";
// import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { useAxios } from "../../../services/useAxios";
import { buyerEndpoints } from "../../constants/urlConstants";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import {  createSearchParams, useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import { CancelToken } from "axios";
import { useCancelToken } from "../../../hooks/useCacelToken";

const Search = ({
  placeholder,
  showDropdown,
  searchDropdownOptions,
  searchDropdownValue,
  setSearchDropdownValue,
  handleCategoryDropdownChange,
  customStyles,
  searchSupplier,
  showSeperator,
}: SearchProps) => {
  const navigate = useNavigate();
  // const location = useLocation();
  const { activeOutlet } = useSelector((state: any) => state);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(loggedInUser?.id);
  const searchValue = useSelector((state: any) => state?.header?.searchValue);

  // const [businessId, setBusinessId] = useState<string>("");
  // const [customerId, setCustomerId] = useState<string>("");
  const [, setIsAdmin] = useState(false);
  let {cancelTokenRef,createCancelToken} = useCancelToken();


  const dispatch = useDispatch();
  const searchHistory = useSelector(
    (state: any) => state?.header?.searchHistory
  );
  const searchSuggestions = useSelector(
    (state: any) => state?.header?.searchSuggestions
  );

  const [originalSearchSuggestions, setOriginalSearchSuggestions] =
    useState(searchSuggestions);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchHaveValue, setSearchHaveValue] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showError, setShowError] = useState(false);

  const handleSearchSuggestions = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(e.target.value));
    const inputValue = e.target.value.toLowerCase().trimStart();
    if (inputValue.length > 0) {
      setSearchHaveValue(true);
      // setFilteredSuggestions(
      //   (originalSearchSuggestions as any)?.filter((item: ISearchSuggestion) =>
      //     item.label.toLowerCase().includes(inputValue)
      //   )
      // );
    }
    if (inputValue.length === 0) {
      setSearchHaveValue(false);
      // setFilteredSuggestions([]);
    }
  };

  const handleGlobalSearch = () => {
    if (searchValue && searchValue.trim().length > 0) {
      // addToSearchHistory(searchValue);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const handleSuggestedBoxClick = (item: ISearchSuggestion) => {
    dispatch(setSearchValue(item?.label));
    setSearchHaveValue(true);
    setTimeout(() => {
      setInputFocused(false);
    }, 1);
    addToSearchHistory(item);
    setShowError(false);
    navigate(routeConfig.buyerProductDetails.replace(":productId", item?.id));
  };

  const handleSearch = () => {
    setInputFocused(false);
    setSearchHaveValue(false);
    dispatch(setSearchValue(""));
  };

  const addToSearchHistory = (item: ISearchSuggestion) => {
    if (
      item?.label?.trim() !== "" &&
      searchHistory &&
      !searchHistory.some((item: ISearchSuggestion) => item.label === item.label)
    ) {
      const newHistoryItem = {
        id: item.id,
        label: item?.label,
        categoryType: item?.categoryType || searchDropdownValue?.value || "All Categories",
      };
      dispatch(setSearchHistory([...searchHistory, newHistoryItem]));
    }
  };

  const handleRemoveHistoryItem = (idToRemove: string) => {
    const updatedHistory = searchHistory.filter(
      (item: ISearchSuggestion) => item.id !== idToRemove
    );
    dispatch(setSearchHistory(updatedHistory));
  };

  const fetchProductslist = useAxios({
    axiosParams: {
      url: `${buyerEndpoints.getFilteredProducts
        .replace(":customerId", customerId)
        .replace(":customerBusinessId", activeOutlet?.id)}?q=${searchValue}`,
    },
    method: "GET",
  });
  const fetchProductslistData = async (cancelToken?:CancelToken) => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await fetchProductslist.fetchData({
        axiosParams:{
          url:`${buyerEndpoints.getFilteredProducts
            .replace(":customerId", customerId)
            .replace(":customerBusinessId", activeOutlet?.id)}?q=${searchValue}`,
          cancelToken
        }
      });
      if (response && response?.status === "SUCCESS") {
        const originalSearchSuggestions = response?.data?.products?.map(
          (each_product: any) => ({
            id: each_product?.id,
            label: each_product?.title,
            categoryType: each_product?.category_name,
          })
        );
        setOriginalSearchSuggestions([...originalSearchSuggestions]);
        setFilteredSuggestions(
          (originalSearchSuggestions as any)?.filter(
            (item: ISearchSuggestion) =>
              item?.label?.toLowerCase()?.includes(searchValue)
          )
        );
      }
      dispatch(updateIsLoading(false));
    } catch (error) {
      dispatch(updateIsLoading(false));
      console.log(`${error} error`);
      throw error;
    }
  };
  const handleSeeAllResults = () => {
    navigate({
      pathname:routeConfig.ProductsListAndFiltersPage,
      search:createSearchParams({'q':[searchValue]})?.toString()
    });
    if (
      window.location.pathname.includes(routeConfig.ProductsListAndFiltersPage)
    ) {
      window.location.reload();
    }
  };

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
  }, [searchHistory]);

  useEffect(() => {
    const storedSearchHistory = localStorage.getItem("searchHistory");
    if (storedSearchHistory) {
      dispatch(setSearchHistory(JSON.parse(storedSearchHistory)));
    }
  }, []);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchContainerRef &&
        searchContainerRef.current &&
        !searchContainerRef?.current?.contains(event.target)
      ) {
        setInputFocused(false);
        setShowError(false);
        setSearchHaveValue(false);
        dispatch(setSearchValue(""));
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      // setBusinessId(loggedInUser?.business_details?.[0]?.business_setup_id);
      // setCustomerId(loggedInUser?.business_details?.[0]?.customer_id);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);


  useEffect(() => {
    if (searchValue && searchValue.trim().length > 0 && activeOutlet?.id) {
      createCancelToken();
      fetchProductslistData(cancelTokenRef?.current?.token);
    }
  }, [searchValue, activeOutlet?.id]);

  console.log(originalSearchSuggestions);
  return (
    <div
      className={`${searchSupplier ? styles.parentModified : styles.parent} ${
        inputFocused && styles.focusedParent
      } ${customStyles} ${
        searchHistory.length > 0 ? "" : styles.parent_radius
      }`}
      ref={searchContainerRef}
    >
      {showDropdown ? (
        <div className={styles.dropdownContainer}>
          {/* as of now not implementing (Naveen) */}
          {/* <SearchDropdown /> */}
        </div>
      ) : null}
      <div className={styles.container}>
        <input
          required
          type="search"
          value={searchValue ?? ""}
          onFocus={() => setInputFocused(true)}
          placeholder={placeholder != "" ? placeholder : "Search"}
          onChange={handleSearchSuggestions}
          autoFocus={searchValue ?? true}
        />

        <div
          className={styles.globalSearch}
          onClick={() => {
            handleGlobalSearch();
            !searchHaveValue && handleSearch();
          }}
        >
          <img src={gobalSearchIcon} alt="global-search" />
        </div>

        {searchHaveValue && searchValue ? (
          <div className={styles.cancelSearch} onClick={handleSearch}>
            <img src={crossIcon} alt="cancel" />
          </div>
        ) : null}
      </div>
      {showError && (
        <div className={styles.errorContainer}>
          <span className={styles.errorText}>Please enter a search term</span>
        </div>
      )}

      {inputFocused && (
        <div
          className={`${styles.searchSuggestionsContainer} ${
            searchHistory.length ? "" : styles.searchSuggestionsChanged
          }`}
        >
          {!searchHaveValue ? (
            searchHistory &&
            searchHistory?.length > 0 &&
            searchHistory.map((item: any) => (
              <div
                className={styles.suggestedContainer}
                key={item.id}
                onClick={() => {
                  handleSuggestedBoxClick(item);
                }}
              >
                <div className={styles.suggestedBox}>
                  <div className={styles.leftContainer}>
                    <div className={styles.iconContainer}>
                      <img src={clock} alt="clock" />
                    </div>
                    <div className={styles.suggestedText}>
                      <span>{item.label}</span>
                      {` in ${item.categoryType}`}
                    </div>
                  </div>
                  <div
                    className={styles.closeAction}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveHistoryItem(item.id);
                    }}
                  >
                    <img src={close} alt="close" />
                  </div>
                </div>
                <div className={styles.seperator}></div>
              </div>
            ))
          ) : filteredSuggestions && filteredSuggestions?.length > 0 ? (
            filteredSuggestions
              ?.slice(0, 5)
              .map((item: ISearchSuggestion) => (
                <div
                  className={styles.suggestedContainer}
                  key={item.id}
                  onClick={() => handleSuggestedBoxClick(item)}
                >
                  <div className={styles.suggestedBox}>
                    <div className={styles.leftContainer}>
                      <div className={styles.suggestedText}>
                        <span>{item.label}</span>
                        {` in ${item.categoryType}`}
                      </div>
                    </div>
                  </div>
                  <div className={styles.seperator}></div>
                </div>
              ))
          ) : (
            <div className={styles.noDataFound}>
              <img src={noDataImage} alt="no Data" />
              <div className={styles.noDataFoundText}>No result found!</div>
            </div>
          )}
          {searchHaveValue &&
            filteredSuggestions &&
            filteredSuggestions?.length > 5 && (
              <div className={styles.suggestedContainer} onClick={handleSeeAllResults}>
                <div className={`${styles.suggestedBox} ${styles.seeAll}`}>
                  {`see all results for "${searchValue}"`}
                </div>
                <div className={styles.seperator}></div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default Search;
