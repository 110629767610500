import { useEffect, useState } from "react";
import VideoCard from "../../../common/components/videoCard/VideoCard";
import {
  buyerOnboardingCards,
  constants,
  howtoSectionData,
} from "../../../common/constants/constants";
import { IVideoCard } from "../../../models/IVideoCard";
import styles from "./DashboardStyles.module.scss";
import { useAxios } from "../../../services/useAxios";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import BlogsAndArticles from "../../../common/components/blogsAndArticles/BlogsAndArticles";
import Button from "../../../common/components/button/Button";
import { OnboardingDashboardCard } from "../../../common/components/onboardingDashboardCard/OnboardingDashboardCard";
import { IOnboardingDashboardCard } from "../../../models/IOnboardingDashboardCard";
export const DashBoard = () => {
  const dispatch = useDispatch();
  const [blogsArticlesData, setBlogsArticlesData] = useState<any>([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const getBlogs = useAxios({
    axiosParams: {
      url: "store/blogs",
    },
    method: "GET",
  });
  const getBlogsApiData = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getBlogs.fetchData();
    if (response && response?.status === "SUCCESS") {
      setBlogsArticlesData(response?.data?.result);
      dispatch(updateIsLoading(false));
    }
  };
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const getCardValue = () => {
    if (width > 1000 && width < 1290) {
      return 2;
    } else if (width > 1290 && width < 1450) {
      return 3;
    } else if (width > 1450 && width < 2000) {
      return 4;
    } else {
      return 5;
    }
  };

  const handleBlogNavigation = () => {
    if (true) {
      window.open("https://www.watermelon.market/blog/", "_blank");
    }
  };
  useEffect(() => {
    getBlogsApiData();
  }, []);
  return (
    <>
      <div className={styles.right}>
        <p className={styles.greetings}>
          Hi {loggedInUser?.first_name ?? "User"}, Welcome aboard
        </p>
        <section className={styles.onboardingCards}>
          {buyerOnboardingCards?.map((each: IOnboardingDashboardCard) => (
            <OnboardingDashboardCard
              icon={each?.icon}
              iconOnHover={each?.iconOnHover}
              isCardSupplier={each?.isCardSupplier}
              state={each?.state} // "Default"
              type={each?.type}
              onClick={each?.onClick}
            />
          ))}
        </section>
        <section className={styles.howToSection}>
          <div className={styles.cardHeader}>
            <div className={styles.heading}>
              {constants.onboardingDashboard.howTo}
            </div>
            <Button
              link
              label={constants.onboardingDashboard.viewAll}
              handleClick={() => {}}
              customClass={styles.viewAll}
            />
          </div>
          <div className={styles.howToSectionCards}>
            {howtoSectionData?.map(
              (data: IVideoCard, index: number) =>
                index < getCardValue() && (
                  <VideoCard
                    id={data?.id}
                    displayImg={data?.displayImg}
                    videoTitle={data?.videoTitle}
                    videoDuration={data?.videoDuration}
                    videoSource={data?.videoSource}
                  />
                )
            )}
          </div>
        </section>
        <section className={styles.blogsAndArticlesSection}>
          <div className={styles.cardHeader}>
            <div className={styles.heading}>
              {constants.onboardingDashboard.blogsAndArticles}
            </div>
            <Button
              link
              label={constants.onboardingDashboard.viewAll}
              handleClick={handleBlogNavigation}
              customClass={styles.viewAll}
            />
          </div>
          <div className={styles.blogsAndArticlesCards}>
            {blogsArticlesData &&
              blogsArticlesData.map(
                (data: any, index: number) =>
                  index < getCardValue() && (
                    <BlogsAndArticles
                      id={data?.id}
                      title={data?.description}
                      author={data?.author_name}
                      img={data?.image}
                      url={data?.url}
                    />
                  )
              )}
          </div>
        </section>
      </div>
    </>
  );
};
