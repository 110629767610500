import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = null;

export const camelCaseKeys = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelCaseKeys);
  }

  return Object.keys(obj).reduce((acc: any, key: string) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
      letter.toUpperCase()
    );
    acc[camelCaseKey] = camelCaseKeys(obj[key]);
    return acc;
  }, {});
};

const productDetailsSlice = createSlice({
  name: "singleProductDetails",
  initialState,
  reducers: {
    getSingleProductDetails: (state: any, action: PayloadAction<any>) => {
      if (action.payload) {
        const transformedPayload = camelCaseKeys(action.payload);
        return transformedPayload;
      }
      return state;
    },
    updateSingleProductStatus: (state: any, action: any) => {
      if (action.payload) {
        return {
          ...state,
          ...camelCaseKeys(action.payload),
        };
      } else {
        return state;
      }
    },
    resetProductDetails: () => {
      return initialState;
    },
  },
});

export const {
  getSingleProductDetails,
  resetProductDetails,
  updateSingleProductStatus,
} = productDetailsSlice.actions;

export default productDetailsSlice.reducer;
