import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../common/components/button/Button";
import { routeConfig } from "../../../common/constants/routeConfig";
import { AdminUserOrderProps } from "../../../models/IAdminUserListingProps";
import AdminUserListing from "../adminUserListing/adminUserLisiting";
import styles from "./AdminDashboard.module.scss";
const AdminAddUser = () => {
  const navigate = useNavigate();
  const buyerData = useSelector(
    (state: any) => state?.adminBuyerList?.cardData
  );
  const supplierData = useSelector(
    (state: any) => state?.adminSupplierList?.cardData
  );
  const [buyerDataArr, setBuyerDataArr] =
    useState<AdminUserOrderProps[]>(buyerData);
  return (
    <div className={styles.container}>
      <div className={styles.rightContainer}>
        <div className={styles.supplierContainer}>
          <div className={styles.heading}>
            <div className={styles.commonHeading}>Users List</div>
            <div className={styles.addForms}>
              {/* for 21 october */}
              <Button
                handleClick={() => {
                  navigate(routeConfig.adminLevelForm);
                }}
                label="Add Admin"
                width="120px"
                secondary={true}
              />
              {/* for 21 october */}
              <Button
                handleClick={() => {
                  navigate(routeConfig.adminSupplierSetupForm);
                }}
                label="Add Supplier"
                width="120px"
                secondary={true}
              />
              <Button
                handleClick={() => {
                  navigate(routeConfig.adminBuyerSetupForm);
                }}
                label="Add Buyer"
                width="120px"
                secondary={true}
              />
            </div>
          </div>
          <AdminUserListing
            buyerDataArr={[...buyerDataArr, ...supplierData]}
            setBuyerDataArr={setBuyerDataArr}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminAddUser;
