import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthProvider";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";

Sentry.init({
  environment: `${process.env.REACT_APP_ENV_NAME}`,
  dsn: "https://a53d79dd4f7a4f8a93fca968bcd83f68@o4507271418675200.ingest.us.sentry.io/4507287297785856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.(?:dev|qa|uat|app)\.watermelon\.market/,
    /^https:\/\/yourserver\.io\/api/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
