import React from "react";
import styles from "./UserSwitch.module.scss";
import { styled } from "@mui/system";

const TabsContainer = styled("div")({
  "--primaryPink": "#ec187b",
  "--teritaryGray1": "#383434",
  "--primaryWhite": "#fff",
  height: "22px",
  borderRadius: "4px",
  margin: "10px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  background: "var(--primaryWhite)",
});

const TabItem = styled("div")({
  transition: "all ease 0.3s",
  minHeight: "20px",
  border: "none",
  color: "var(--teritaryGray1)",
  textAlign: "center",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  margin: "1px",
  flex: "1",
  borderRadius: "4px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "var(--primaryPink)",
    color: "var(--primaryWhite)",
  },
});

const UserSwitch = ({ userType, setUserType }: any) => {
  const selectedTab = userType === "buyer" ? 1 : 2;
  const handleTabChange = (newValue: number) => {
    setUserType(newValue === 1 ? "buyer" : "supplier");
  };
  return (
    <TabsContainer className={styles.userswitchContainer}>
      <TabItem
        onClick={() => handleTabChange(1)}
        className={selectedTab === 1 ? styles.selected : ""}
      >
        Buyer
      </TabItem>
      <TabItem
        onClick={() => handleTabChange(2)}
        className={selectedTab === 2 ? styles.selected : ""}
      >
        Supplier
      </TabItem>
    </TabsContainer>
  );
};

export default UserSwitch;
