import {
  buyerAPIEndpoints,
  constants,
} from "../../../common/constants/constants";
import cancel from "../../../assets/images/cancelToast.svg";
import styles from "./AddOutlet.module.scss";
import InputField from "../../../common/components/formFields/inputField/inputField";
import { Drawer } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { APIMethods } from "../../../common/constants/urlConstants";
import { useAxios } from "../../../services/useAxios";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { useDispatch } from "react-redux";
import { MARK_AS_DEFAULT } from "../../../common/constants/constants";

const AddOutlet = ({
  showAddOutlet,
  handleClose,
  countriesList,
  emiratesList,
  outletData,
  editOutlet,
  outletNames,
  isEditOutlet = false,
  fetchOutlets,
  businessSetupForm,
}: any) => {
  const dispatch = useDispatch();
  const [mobileNumberCode, setMobileNumberCode] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [selectedEmirateSub, setSelectedEmirateSub] = useState<any>();
  // const [defaultCountryFlag, setDefaultCountryFlag] = useState<any>();
  // const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [checked, setChecked] = useState(false);
  const [currentUser] = useState(
    JSON.parse(localStorage?.getItem("loggedInUser") as any)
  );
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") as any);
  const businessDetails = loggedInUser?.business_details.filter(
    (el: any) => el.is_default
  )[0];

  const createOutlet = useAxios({
    axiosParams: {
      url: buyerAPIEndpoints.addOutlet.replace(
        ":buyerId",
        businessDetails?.business_setup_id
      ),
    },
    method: APIMethods.post,
  });

  const schema = yup
    .object({
      contactName: yup.string().required("Enter contact name"),
      countryName: yup.object().required("Select country"),
      phNo: yup
        .number()
        .positive()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      newoutletName: yup
        .string()
        .test("check duplicate outlet", "Outlet name already exists", (val) => {
          if (
            editOutlet?.saved_as?.toLowerCase() !== val?.toLocaleLowerCase() &&
            outletNames?.find(
              (el: any) => el?.toLowerCase() === val?.toLowerCase()
            )
          ) {
            return false;
          } else {
            return true;
          }
        })
        .required("Enter outlet name"),
      address: yup.string().required("Enter address"),
      area: yup.string().required("Enter area name"),
      emirates: yup.object().required("Select emirates"),
      country: yup.object().required("select country"),
      is_default: yup.boolean().required(),
    })
    .required();

  const {
    register,
    formState: { errors },
    // formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    // control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const values = getValues();

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "countryName") {
        setValue(name, text);
      }
      if (name === "country" || name === "emirates") {
        setValue(name, text);
      }
      if (name === "newoutletName") {
        setValue(name, text);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (!isEditOutlet) {
      setMobileNumberCode(
        countriesList &&
          countriesList.length > 0 &&
          countriesList.filter(
            (country: any) => country?.dial_codes === ("+971" as any)
          )[0]
      );
      setValue(
        "countryName",
        countriesList &&
          countriesList.length > 0 &&
          countriesList.filter(
            (country: any) => country?.dial_codes === ("+971" as any)
          )[0]
      );
    }
  }, [isEditOutlet, countriesList]);

  useEffect(() => {
    if (currentUser?.business_details?.[0]?.business_setup_id && !isEditOutlet) {
      let country = null;
      if (businessDetails?.address?.country) {
        country = countriesList?.find(
          (ele: any) => ele?.iso_2 === businessDetails?.address?.country?.iso_2
        );
        if (country) {
          setSelectedCountry(country);
          setValue("country", country);
        }
      } else {
        const defaultCountry = countriesList?.find(
          (ele: any) => ele?.iso_2 === "ae"
        );
        if (defaultCountry) {
          setSelectedCountry(defaultCountry);
          // setEmiratesList(defaultEmiratesList);
          // setSelectedLocations(listWithValue);
          setValue("country", defaultCountry);
          // setSelectedEmirate(null);
        }
      }
    }
  }, [countriesList]);

  const handleReset = () => {
    setValue("address", "");
    setValue("area", "");
    setValue("emirates", "");
    setSelectedEmirateSub(null);
    setValue("country", "");
    setValue("newoutletName", "");
    setValue("is_default", false);
    setChecked(false);
    // setEditingOutlet(false);
    setValue("phNo", "" as any);
    setValue("countryName", "");
    setValue("contactName", "");
    setMobileNumberCode(null);
  };

  const handleAddOutlet = async (data: any) => {
    const payload = {
      contact_name: data?.contactName,
      phone_number: `${(data?.countryName as any)?.dial_codes}#${data?.phNo}`,
      saved_as: data?.newoutletName,
      address: data?.address,
      area: data?.area,
      emirate: {
        id: (data?.emirates as any)?.id,
      },
      country: {
        iso_2: (data?.country as any)?.iso_2,
      },
      is_default: checked,
      customer_id: businessDetails?.customer_id,
    };
    dispatch(updateIsLoading(true));
    const response: any = await createOutlet.fetchData({
      axiosParams: {
        url: buyerAPIEndpoints.addOutlet.replace(
          ":buyerId",
          businessDetails?.business_setup_id
        ),
        data: payload,
      },
    });
    dispatch(updateIsLoading(false));
    if (response.status === "SUCCESS") {
      setToastData({
        message: "Outlet created successfully",
        status: "Success",
      });
    } else {
      setToastData({
        message: "Something went wrong",
        status: "Success",
      });
    }
    setTimeout(() => {
      handleClose();
    }, 1000);
    fetchOutlets();
  };

  const submit = () => {
    let outletValues = {
      contact_name: values?.contactName,
      phone_number: `${(values?.countryName as any)?.dial_codes}#${
        values?.phNo
      }`,
      saved_as: values?.newoutletName,
      address: values?.address,
      area: values?.area,
      emirate: values?.emirates,
      country: values?.country,
      is_default: checked,
      id:  editOutlet?.id,
    };
    outletData(outletValues);
    setToastData({
      message: "Outlet updated",
      status: "Success",
    });
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 3000);
    handleClose();
    return;
  };

  useEffect(() => {
    if (editOutlet?.address) {
      // setEditingOutlet(!editingOutlet);
      setValue("address", editOutlet?.address);
      setValue("area", editOutlet?.area);
      setValue("newoutletName", editOutlet?.saved_as);
      setValue("is_default", editOutlet?.is_default);
      setValue("phNo", editOutlet?.phone_number?.split("#")[1]);
      setValue("contactName", editOutlet?.contact_name);
      setChecked(editOutlet?.is_default);
      if (countriesList?.length > 0) {
        const countryFlag = countriesList?.find(
          (item: any) =>
            item?.dial_codes === editOutlet?.phone_number?.split("#")[0]
        );
        if (countryFlag) {
          setMobileNumberCode(countryFlag);
        }
        const currentCountryDetails = editOutlet?.countryData || editOutlet?.country
        if (currentCountryDetails) {
          const country = countriesList?.find(
            (ele: any) => ele?.iso_2 === currentCountryDetails?.iso_2
          );
          if (country) {
            setSelectedCountry(country);
            setValue("country", country);
          }
        }
        if (emiratesList?.length > 0) {
          const emirate: any = emiratesList?.find(
            (ele: any) => ele?.id === editOutlet?.emirate?.id
          );
          if (emirate) {
            setSelectedEmirateSub(emirate);
            setValue("emirates", emirate);
          }
        }
      }
    }
  }, [isEditOutlet, countriesList, editOutlet]);

  const skipAdd = (e: any) => {
    e.preventDefault();
    handleReset();
    handleClose();
  };
  return (
    <>
      <Drawer
        anchor={"right"}
        open={showAddOutlet || isEditOutlet}
        onClose={() => handleClose}
        hideBackdrop={false}
      >
        <div className={styles.addoutletContainer}>
          <div className={styles.header}>
            <p className={styles.heading}>
              {isEditOutlet
                ? constants.BusinessSetupForm.editOutletHeader
                : constants.BusinessSetupForm.addOutletHeader}
            </p>
            <img
              src={cancel}
              alt="cancel"
              className={styles.cancel}
              onClick={(e) => skipAdd(e)}
            />
          </div>
          <form
            className={styles.bottom}
            onSubmit={handleSubmit(isEditOutlet || businessSetupForm ? submit : handleAddOutlet)}
          >
            <div className={styles.form}>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    value={watch("newoutletName")}
                    id="newoutletName"
                    label="Outlet name"
                    placeholder="Enter outlet name"
                    onChange={onChangeField("newoutletName")}
                    register={register("newoutletName")}
                    errorMessage={errors.newoutletName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    id="contactName"
                    label="Contact person name"
                    placeholder="Enter contact person name here"
                    onChange={onChangeField("contactName")}
                    register={register("contactName")}
                    // errorMessage={errors.contactName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      mobileNumberCode
                      // ? mobileNumberCode
                      // : countriesList &&
                      //   countriesList.length > 0 &&
                      //   countriesList.filter(
                      //     (country: { dial_codes: any }) =>
                      //       country?.dial_codes == ("+971" as any)
                      //   )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    label="Phone number"
                    id={"phnNo"}
                    options={countriesList}
                    registerMobileNumber={register("phNo")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      if (selectedOption) {
                        onChangeField("countryName")(selectedOption);
                        setMobileNumberCode(selectedOption);
                      }
                    }}
                    // errorMessage={errors.phNo?.message}
                    mobileNumberField={true}
                  />
                </div>

                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Address"
                    value={watch("address")}
                    placeholder="Enter address here"
                    onChange={onChangeField("address")}
                    register={register("address")}
                    errorMessage={errors.address?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    minWidth="250px"
                    label="Area"
                    value={watch("area")}
                    placeholder="Enter area here"
                    onChange={onChangeField("area")}
                    register={register("area")}
                    errorMessage={errors.area?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"country"}
                    label="Country"
                    // value ={watch('area')}
                    // options={countriesList}
                    options={
                      countriesList &&
                      countriesList.filter(
                        (country: ICountry) => country?.iso_2 === "ae"
                      )
                    }
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("country")}
                    onChange={(selectedOption: ICountry) => {
                      selectedOption && onChangeField("country")(selectedOption);
                      selectedOption && setSelectedCountry(selectedOption);
                      // handleCountryClick(selectedOption);
                    }}
                    defaultLabel={selectedCountry?.name ?? "Select country"}
                    errorMessage={errors.country?.message}
                    selectedValue={selectedCountry}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"Emirate"}
                    label="Emirate"
                    options={emiratesList}
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("emirates")}
                    onChange={(selectedOption: ICountry) => {
                      if (selectedOption) {
                        onChangeField("emirates")(selectedOption);
                        setSelectedEmirateSub(selectedOption);
                      }
                    }}
                    defaultLabel={
                      selectedEmirateSub
                        ? selectedEmirateSub?.name
                        : "Select emirate"
                    }
                    errorMessage={errors.emirates?.message}
                    selectedValue={selectedEmirateSub}
                  />
                </div>
                <div className={styles.defOutlet}>
                  <div
                    className={styles.checkbox}
                    // onClick={() => setChecked(!checked)}
                    // onClick={() => onChangeField("is_default")}
                  >
                    <CheckboxButton
                      checked={checked ?? false}
                      // id="makeDefault"
                      disabled={!businessSetupForm && editOutlet.is_default}
                      handleChange={() => {
                        setChecked(!checked);
                        onChangeField("is_default");
                      }}
                      label={MARK_AS_DEFAULT}
                      register={register("is_default")}
                      errorMessage={errors.is_default?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.action}>
              <button className={styles.skip} onClick={(e) => skipAdd(e)}>
                Cancel
              </button>
              <button className={styles.submit}>
                {isEditOutlet
                  ? constants.BusinessSetupForm.updateButton
                  : constants.BusinessSetupForm.submitButton}
              </button>
            </div>
          </form>
        </div>
      </Drawer>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddOutlet;
