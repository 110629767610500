import React, { useState, useEffect } from "react";
import paperclip from "../../../assets/images/paperclip.svg";
import paperclipBlack from "../../../assets/images/paperclipBlack.svg";
import trash from "../../../assets/images/trash.svg";
import { IFileuploadCard } from "../../../models/IFileUploadCard";
import styles from "./FileUploadCard.module.scss";
import whiteTrash from "../../../assets/images/whiteTrash.svg";
import whiteEye from "../../../assets/images/formFieldIcons/eyeOpen.svg";
import downloadIcon from "../../../assets/images/download.svg";
import cancelAction from "../../../assets/images/chevron-left-white.svg";
import Modal from "@mui/material/Modal";
import { saveAs } from "file-saver";
import Backdrop from "@mui/material/Backdrop";
import { constants } from "../../constants/constants";
import errorToast from "../../../assets/images/errorToast.svg";
import { ToastNotification } from "../toastNotification/ToastNotification";

import pdfPlaceHolderImage from "../../../assets/images/pdfPlaceholderImage.png";
export default function FileUploadCard(props: IFileuploadCard) {
  // State variables
  const [fileName, setFileName] = useState("");
  const [imageSrcUrl, setImageSrcUrl] = useState<string | undefined>(
    props?.imageUrl
  );
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);

  const [multipleFilesList, setMultipleFilesList] = useState<string[]>([]);
  const [fileDeleted, setFileDeleted] = useState(false);
  function extractFilenameFromURL(url: string) {
    const lastSlashIndex = url?.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      const afterSlash = url?.substring(lastSlashIndex + 1);
      const timestampEndIndex = afterSlash?.indexOf("-") + 1;
      if (timestampEndIndex !== -1) {
        const filename = afterSlash?.substring(timestampEndIndex);
        setFileName(filename);
      } else {
        setFileName("");
      }
    } else {
      setFileName("");
    }
  }
  useEffect(() => {
    if (props?.imageUrl) {
      extractFilenameFromURL(props?.imageUrl);
    }
  }, [props?.imageUrl]);
  // Function to handle file input change
  function displayFile(e: any) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        if (props.isMultipleFiles !== true) {
          if (props?.accept) {
            if (props?.accept?.includes(selectedFile?.type?.split("/")[1])) {
              props?.setFile(selectedFile);
              setImageSrcUrl(reader?.result as string);
              props.onChange({
                file: selectedFile,
                value: reader.result,
              });
            } else {
              setToastData({
                message: `Please upload the appropriate type of file(${props?.accept})`,
                status: "failure",
              });
              setTimeout(() => {
                setToastData(constants.defaultToastData);
              }, 5000);
            }
          } else {
            props?.setFile(selectedFile);
            setImageSrcUrl(reader?.result as string);
            props.onChange({
              file: selectedFile,
              value: reader.result,
            });
          }
        } else {
          if (props?.accept) {
            if (props?.accept?.includes(selectedFile?.type?.split("/")[1])) {
              props.onChange((prev: any) => [...prev, selectedFile.name]);
              props?.setFile("");
              setMultipleFilesList((prev) => [...prev, selectedFile.name]);
            } else {
              setToastData({
                message: `Please upload the appropriate type of file(${props?.accept})`,
                status: "failure",
              });
              setTimeout(() => {
                setToastData(constants.defaultToastData);
              }, 5000);
            }
          } else {
            props.onChange((prev: any) => prev);
            props?.setFile("");
            setMultipleFilesList((prev) => prev);
          }
        }
        setFileDeleted(false);
      };
    }
  }

  // Function to delete a single file
  function deleteSingleFile() {
    props?.setFile("");
    setFileDeleted(true);
    setImageSrcUrl("");
    if (props?.setImageUrl) {
      props?.setImageUrl("");
    }
  }

  // Function to delete a file from the multipleFilesList
  function deleteFile(file: any) {
    setMultipleFilesList(multipleFilesList.filter((item) => item !== file));
  }

  // Effect to set initial values based on defaultValue
  useEffect(() => {
    if (props.defaultValue) {
      if (!props.isMultipleFiles) {
        setFileName(props.defaultValue?.value);
        props.setFile(props.defaultValue?.value);
      } else {
        setMultipleFilesList([props.defaultValue?.file]);
      }
    }
  }, [props.defaultValue]);

  // Function to handle drop event
  function handleDrop(e: any) {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        if (props.isMultipleFiles !== true) {
          props?.setFile(reader.result as string);
          props.onChange({
            file: selectedFile,
            value: reader.result,
          });
        } else {
          props?.setFile("");
          props.onChange((prev: any) => [...prev, selectedFile.name]);
          setMultipleFilesList((prev) => [...prev, selectedFile.name]);
        }
      };
    }
  }

  // Function to handle drag over event
  function handleDragOver(e: any) {
    e.preventDefault();
  }
  const extractFileNameFromUrl = (url: string) => {
    const filenameSlash = url?.split("/");
    const filenameHyphen = filenameSlash[filenameSlash?.length - 1]?.split("-");
    if (filenameHyphen[filenameHyphen?.length - 1]) {
      return filenameHyphen[filenameHyphen?.length - 1];
    }
    return url;
  };
  const downloadFile = () => {
    if (props?.file != "") {
      saveAs(props?.file as any, fileName);
    } else if (props?.imageUrl != "") {
      saveAs(
        props?.imageUrl as any,
        extractFileNameFromUrl(props?.imageUrl as string)
      );
    }
  };
  useEffect(() => {
    setImageSrcUrl(props?.imageUrl);
  }, [props?.imageUrl]);
  return (
    <>
      <div
        style={{ width: `${props.width ? props.width : "400px"}` }}
        className={`${styles.uploadCard} ${
          props?.editForm && !(props?.imageUrl || imageSrcUrl)? styles.grayScale : ""
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {/* Display image and details when an image is uploaded */}
        {!fileDeleted && (props?.imageUrl || imageSrcUrl) && (
          <>
            <div className={`${styles.selectedFile} ${props?.editForm ? styles.pointerEventsNone : ""}`}>
              <img
                src={
                  props?.file?.type === "application/pdf"
                    ? pdfPlaceHolderImage
                    : imageSrcUrl || props.imageUrl
                }
                alt="file"
                width="398px"
                height="147px"
              ></img>
            </div>
            <div className={styles.fileDetails}>
              <div className={styles.file}>
                <div>
                  <img src={paperclip} alt="file icon"></img>
                </div>
                <div className={styles.fileName}>
                  {props?.defaultValue
                    ? props?.defaultValue?.file?.name
                    : fileName.length > 20
                    ? fileName.slice(0, 20) + `...`
                    : fileName}
                </div>
              </div>
              {!props.hoverButtons && (
                <div className={styles.iconContainer}>
                  <div
                    className={styles.eyeButton}
                    onClick={handleModalOpen}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={whiteEye} alt="whiteEyeIcon"></img>
                  </div>
                  <div
                    className={styles.downloadBtn}
                    onClick={downloadFile}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={downloadIcon} alt="downloadIcon"></img>
                  </div>
                  <div
                    className={`${styles.trashIcon} ${
                      props.disable ? styles.disableMode : null
                    }  ${props?.editForm ? styles.editFormStyles : ''}`}
                    data-testId="trash"
                    onClick={props.disable ? () => {} : deleteSingleFile}
                  >
                    <img src={trash} alt="deleteIcon"></img>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {/* Display file input when no image is uploaded or when fileDeleted is true */}
        {(!props?.imageUrl || props?.imageUrl?.length === 0 || fileDeleted) && (
          <label
            className={`${styles.content} ${props?.editForm ? styles.editFormStyles : ""}`}
            htmlFor={`files-${props.id}`}
            tabIndex={props?.tabIndex ?? -1}
          >
            Drop file here or
            <div className={styles.uploadButton}>upload</div>
            <input
              type="file"
              accept={props.accept}
              id={`files-${props.id}`}
              data-testId="input"
              style={{ display: "none" }}
              {...props.register}
              onChange={(e) => {
                // displayFile(e)
                const files = e.target.files;
                if (files && files?.length > 0) {
                  if (files[0].size <= 5000000) {
                    displayFile(e)
                  } else {
                    setToastData({
                      message:
                        "The maximum allowed file size is 5MB.",
                      status: "failure",
                    });
                    setTimeout(() => {
                      setToastData(constants.defaultToastData);
                    }, 3000);
                  }
                }
              }}
            />
          </label>
        )}

        {/* Display overlay buttons */}
        {props?.file && props?.hoverButtons && (
          <div className={styles.uploadOverlay}>
            <div className={styles.overlayButtons}>
              <div className={styles.eyeButton}>
                <img src={whiteEye} alt="whiteEyeIcon"></img>
              </div>
              <div
                className={styles.deleteButton}
                data-testId="trash"
                onClick={props.disable ? () => {} : deleteSingleFile}
              >
                <img src={whiteTrash} alt="whitedeleteIcon"></img>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Display error message */}
      {(props.file === "" || (props.imageUrl === "" && !fileDeleted)) && (
        <div className={styles.errorMessage}>{props.error}</div>
      )}

      {/* Display multiple files list */}
      {props.isMultipleFiles === true && multipleFilesList.length > 0 && (
        <div>
          <div className={styles.attachments}>Added Attachments</div>
          <div className={styles.multipleFiles}>
            {multipleFilesList.map((item, index) => {
              return (
                <div key={index} className={styles.multipleFileDetails}>
                  <div className={styles.file}>
                    <div className={styles.fileIcon}>
                      <img src={paperclipBlack} alt="file icon"></img>
                    </div>
                    <div
                      className={styles.fileName}
                      data-testid="uploaded-file"
                    >
                      {item}
                    </div>
                  </div>
                  <div
                    className={styles.trashIcon}
                    data-testId="trash"
                    onClick={() => deleteFile(item)}
                  >
                    <img src={trash} alt="deleteIcon"></img>
                  </div>
                  {index !== multipleFilesList.length - 1 && (
                    <div className={styles.divider}> </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <Modal
        className={styles.imageModal}
        open={open}
        onClose={handleModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.imageBox}>
          <div className={styles.header} onClick={handleModalClose}>
            <img src={cancelAction} alt="" />
            <p className={styles.overflow} title={fileName}>
              {props?.defaultValue
                ? props?.defaultValue?.file?.name
                : fileName.length > 20
                ? fileName.slice(0, 40) + `...`
                : fileName}
            </p>
          </div>
          {imageSrcUrl ||
          (props?.file && props?.file?.type === "application/pdf") ? (
            props?.file?.type === "application/pdf" ? (
              <div>
                <p className={styles.pdfType}>
                  <img
                    className={`${
                      props?.file?.type === "application/pdf"
                        ? styles.pdfImg
                        : null
                    }`}
                    src={pdfPlaceHolderImage}
                    alt="pdf type"
                  />
                </p>
              </div>
            ) : (
              <img src={imageSrcUrl} alt="" />
            )
          ) : (
            <p className={styles.noPreview}>No Preview Available</p>
          )}
        </div>
      </Modal>
      {toastData?.message ? (
        <ToastNotification
          icon={errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </>
  );
}
