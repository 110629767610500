import { useEffect, useState, useRef } from "react";
import filterIcon from "../../../../../assets/images/ordersListing/Filter.svg";
import sortIcon from "../../../../../assets/images/ordersListing/sortIcon.svg";
import PageSearch from "../../../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../../../common/components/pagination/Pagination";
import { constants } from "../../../../../common/constants/constants";
import {
  ApprovalCardProps,
  BuyerBusinessApprovalDataProps,
} from "../../../../../models/IBusinessApprovalProcess";
import { ApprovalCardSupplier } from "../../../supplierBusinessApprovalProcess/components/approvalCardSupplier/ApprovalCard";
import { ApprovalCardBuyer } from "../approvalCard/ApprovalCard";
import headerCardStyles from "../approvalCard/ApprovalCard.module.scss";
import styles from "./Tabs.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "../../../../../reducerSlices/selectedTabSlice";
import { useAxios } from "../../../../../services/useAxios";
import {
  APIMethods,
  adminEndPoints,
} from "../../../../../common/constants/urlConstants";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { EmptyContainer } from "../../../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { setPage } from "../../../../../reducerSlices/selectedPageSlice";

export const TabComponent = ({ cardType }: BuyerBusinessApprovalDataProps) => {
  const [searchValue, setSearchValue] = useState("");
  const selectedTab = useSelector((state: any) => state.selectedTab[cardType]);
  const [activeTab, setActiveTab] = useState(selectedTab);
  const  selectedPage: any = useSelector(
    (state: any) => state.selectedPage
  );
  const [currentPage, setCurrentPage] = useState(
    cardType === "buyer"
      ? selectedPage?.buyerApprovalPageNumber
      : selectedPage.supplierApprovalPageNumber
  );
  const itemsPerPage = 10;
  const [data, setData] = useState<{
    data: ApprovalCardProps[];
    count: number;
  } | null>(null);
  const dispatch = useDispatch();
  const myRef = useRef<null | HTMLDivElement>(null);

  const TAB_LIST = [
    { key: "review", value: "Review" },
    // commented for this phase 1
    // { key: "pending", value: "Pending Document" },
    { key: "approved", value: "Approved" },
    { key: "rejected", value: "Rejected" },
  ];
  const isBuyer = cardType === "buyer";

  // &take=${constants.paginationLimitPerPage}
  const { fetchData } = useAxios({
    axiosParams: {
      url: `${
        isBuyer
          ? adminEndPoints.getBuyersListForAdmin
          : adminEndPoints.getSuppliersListForAdmin}?page=${currentPage}&status=${TAB_LIST[activeTab].key}${
        searchValue.length > 2 ? `&q=${searchValue}` : ""
      }`,
    },
    method: APIMethods.get,
  });

  const getDefaultOutletContactName = (businessStores:any[]):string => {
    if(businessStores?.length > 0){
      const defaultStore = businessStores?.find((eachStore:any)=> eachStore?.is_default);
      console.log("stores de",defaultStore);

      if(defaultStore && Object.keys(defaultStore)?.length > 0){
        return defaultStore?.contact_name ?? '-';
      }
      return businessStores?.[0]?.contact_name ?? '-';
    }
    return '-';
  }

  const getData = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await fetchData();
    if (
      response &&
      response?.data
      //  &&
      // response?.data[isBuyer ? "buyers" : "suppliers"]
    ) {
      const fetchedData = response?.data;
      // [
      //   isBuyer ? "buyers" : "suppliers"
      // ]
      // ?.filter((item: any) => item?.id);
      fetchedData &&
        fetchedData.forEach((item: any) => {
          item.id = item?.id ?? "-";
          item.logo = "";
          item.businessName = item?.businessName?.length
            ? item?.businessName
            : item?.business_name?.length
            ? item?.business_name
            : "-";
          item.primaryContactName =
            cardType === "buyer"
              ? getDefaultOutletContactName(item?.business_stores)
              : item?.business_stores?.[0]?.contact_name ?? "-";
          item.email = item?.email_id ?? "-";
          item.address = item?.address?.address ?? "-";
          item.phoneNo = item?.business_stores?.[0]?.phone_number ?? "-";
          item.status = TAB_LIST[activeTab]?.value;
          item.customerId = item?.customerId ?? "";
        });
      // fetchedData &&
      //   fetchedData.forEach((item: any) => {
      //     item.id = item?.business_id ?? "";
      //     item.logo = "";
      //     item.businessName = item?.business_name ?? "";
      //     item.primaryContactName = item?.contact_name ?? "";
      //     item.email = item?.business_email ?? "";
      //     item.address = item?.address ?? "";
      //     item.phoneNo = item?.business_phone ?? "";
      //     item.status = TAB_LIST[activeTab]?.value;
      //     item.customerId = item?.customer?.id;
      //   });
      setData({ data: fetchedData, count: response?.count ?? 10 });
      dispatch(updateIsLoading(false));
    } else {
      setData({ data: [], count: 0 });
      dispatch(updateIsLoading(false));
    }
  };

  const selectTab = (index: number) => {
    dispatch(setTab({ type: cardType, index }));
    setActiveTab(index);
    // setSearchValue("");
    setCurrentPage(1);
    if(cardType === "buyer"){
      dispatch(setPage({type:'buyerApprovalPageNumber', index:1}));
    }
    else{
      dispatch(setPage({type:'supplierApprovalPageNumber', index:1}));
    }
  };

  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
    if(cardType === "buyer"){
      dispatch(setPage({type:'buyerApprovalPageNumber', index:page}));
    }
    else{
      dispatch(setPage({type:'supplierApprovalPageNumber', index:page}));
    }
  };

  useEffect(() => {
    myRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const searchCustomStyles = {
    top: "0px",
  };

  useEffect(() => {
    getData();
  }, [activeTab, currentPage, searchValue]);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div className={styles.tabsStyle}>
          <div className={styles.tabList}>
            {TAB_LIST.map((tab, index) => (
              <div
                className={styles.tabListItem}
                onClick={() => selectTab(index)}
              >
                <div
                  className={`${styles.label} ${
                    index === activeTab && styles.activeLabel
                  }`}
                >
                  {tab.value}
                </div>
                {activeTab === index && <div className={styles.border} />}
              </div>
            ))}
          </div>
          <div className={styles.hr} />
        </div>
        <div className={`${styles.actions} ${styles.actions2}`}>
          <PageSearch
            handleSearch={(value: any) => {
              setCurrentPage(1);
              setSearchValue(value);
              if (cardType === "buyer") {
                dispatch(
                  setPage({ type: "buyerApprovalPageNumber", index: 1 })
                );
              } else {
                dispatch(
                  setPage({
                    type: "supplierApprovalPageNumber",
                    index: 1,
                  })
                );
              }
            }}
            customStyles={searchCustomStyles}
            customPlaceHolder="Search by business name or contact name or email id"
          />
          <div
            className={`${styles.icon} ${styles.sort} ${styles.hide}`}
            onClick={() => {}}
          >
            <img src={sortIcon} alt="sort" />
          </div>
          <div className={`${styles.icon} ${styles.hide}`} onClick={() => {}}>
            <img src={filterIcon} alt="filter" />
          </div>
        </div>
      </div>
      {data && data?.data?.length > 0 && (
        <>
          <div
            className={`${headerCardStyles.approvalCard} ${styles.tableHeader}`}
          >
            <div className={`${headerCardStyles.logo} ${styles.headerLogo}`} />
            <div className={headerCardStyles.details}>
              <div
                className={`${headerCardStyles.column} ${styles.businessName} ${styles.headerColor}`}
              >
                {constants.buyersListHeaders.businessName}
              </div>
              <div
                className={`${headerCardStyles.column} ${styles.headerColor} ${styles.contactName}`}
              >
                {constants.buyersListHeaders.primaryContactName}
              </div>
              <div
                className={`${headerCardStyles.column} ${styles.headerColor} ${styles.email}`}
              >
                {constants.buyersListHeaders.email}
              </div>
              <div
                className={`${headerCardStyles.column} ${styles.headerColor}`}
              >
                {constants.buyersListHeaders.phoneNO}
              </div>
              <div
                className={`${headerCardStyles.column} ${styles.headerColor} ${styles.address}`}
              >
                {constants.buyersListHeaders.address}
              </div>
            </div>
          </div>
          <div className={styles.tabsContent} ref={myRef}>
            {data &&
              data?.data.length > 0 &&
              data?.data?.map((item: any) => {
                const addressDetails =
                  item.business_stores && item.business_stores[0]?.address;
                const address =
                  addressDetails?.address || addressDetails?.area
                    ? `${addressDetails?.address || ""}, ${
                        addressDetails?.area || ""
                      }`
                    : "-";
                return cardType === "buyer" ? (
                  <ApprovalCardBuyer
                    businessName={item.businessName}
                    primaryContactName={item.primaryContactName}
                    email={item.email}
                    address={address}
                    status={item.status}
                    id={item.id}
                    logo={item.logo}
                    phoneNo={item.phoneNo}
                    customerId={item.customerId}
                  />
                ) : (
                  <ApprovalCardSupplier
                    businessName={item.businessName}
                    primaryContactName={item.primaryContactName}
                    email={item.email}
                    address={address}
                    status={item.status}
                    id={item.id}
                    logo={item.logo}
                    phoneNo={item.phoneNo}
                    customerId={item.customerId}
                  />
                );
              })}
          </div>
          <div className={styles.paginationContainer}>
            <PaginationComponent
              count={Math.ceil(data?.count / itemsPerPage)}
              page={currentPage}
              handlePagination={handlePagination}
              showFirstButton={true}
              showLastButton={true}
            />
          </div>
        </>
      )}
      {data && data.data.length === 0 && (
        <div className={styles.emptyContainer}>
          <EmptyContainer
            title={"Woah!"}
            subText={cardType === "buyer" ? "No buyers" : "No suppliers"}
          />
        </div>
      )}
    </div>
  );
};
