import { useEffect, useRef, useState } from "react";
import styles from "./ProductsListAndFiltersStyles.module.scss";
import sortIcon from "../../../../assets/images/lowerTriangle.svg";
import telescope from "../../../../assets/images/telescope.svg";
import { constants, sortOptions } from "../../../../common/constants/constants";
import Filters from "../subCategoryItemsList/components/filters/Filters";
import { useAxios } from "../../../../services/useAxios";
import {
  APIMethods,
  buyerEndpoints,
  urlConstants,
} from "../../../../common/constants/urlConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ProductCardWithCart from "../../../../common/components/productCardWithCart/ProductCardWithCart";
import { routeConfig } from "../../../../common/constants/routeConfig";
import { useCancelToken } from "../../../../hooks/useCacelToken";
import { CancelToken } from "axios";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import Breadcrumb from "../../../../common/components/breadCrumb/BreadCrumb";
import { EmptyContainer } from "../../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { IFilterList } from "../../../../models/IMarketplaceFiltersProps";
import { capitalizeIfAlphabetic, transformFilterValue } from "../../../../utils/filters";
const ProductsListAndFilters = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const { activeOutlet } = useSelector((state: any) => state);
  const itemsPerPage = 9;
  const [showSort, setShowSort] = useState(false);
  const sortContainerRef = useRef<HTMLDivElement>(null);
  // const [navFromSearchResults, setNavFromSearchResults] =
  //   useState<boolean>(false); // true - nav from  viewall page , false - nav from searchreasults
  const [prevSelected, setPrevSelected] = useState(0);
  const [loggedInUser] = useState(
    JSON.parse(localStorage.getItem("loggedInUser") as any)
  );
  const [customerId] = useState(loggedInUser?.id);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryParameters, setQueryParameters] = useState<any>({}); //for api url
  const [url, setUrl] = useState<string>("");
  const [products, setProducts] = useState<any[]>([]);
  const [totalProductsCount, setTotalProductsCount] = useState<number>(0);
  const [filtersList, setFilteredList] = useState<IFilterList[]>([]);
  const [params, setParams] = useSearchParams(); // for browser url;
  const  categoryId = params.getAll("category_ids").join(',');
  const  categoryName = params.getAll("categoryName").join(',');
  // const  subCategoryId = params.getAll("sub_category_ids").join(',');
  const  subCategoryName = params.getAll("subCategoryName").join(',');
  // const  subSubCategoryId = params.getAll("sub_sub_categories_ids").join(',');
  // const {
  //   categoryId,
  //   categoryName,
  //   subCategoryId,
  //   subCategoryName,
  //   subSubCategoryId,
  // } = Object.entries(params);
  const breadcrumbItems = [
    { label: "Marketplace", link: routeConfig.marketPlace },
    {
      label: `${categoryName}`,
      link: `${routeConfig?.subCategoryItemsListPage?.replace(
        ":categoryId",
        categoryId?.toString()
      )}?${createSearchParams({ categoryName: [categoryName] })?.toString()}`,
    },
    { label: `${subCategoryName}` },
  ];
  let { cancelTokenRef, createCancelToken } = useCancelToken();
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const handlePagination = (e: any, page: number) => {
    setCurrentPage(page);
    setQueryParameters({
      ...queryParameters,
      offset: `${(page - 1) * itemsPerPage}`,
    });
  };
  const getProductImageUrl = (images: any[]) => {
    if (images?.length > 0) {
      const defaultImgs = images?.filter(
        (eachImage) => eachImage?.metadata?.default_image
      );
      if (defaultImgs?.length > 0) return defaultImgs?.[0]?.url;
      else return images?.[0]?.url;
    }
    return null;
  };
  const transformVariantToBundleOptions = (variants: any[]) => {
    if (variants?.length) {
      const options = variants?.map((variant: any) => ({
        id: variant?.id,
        value: variant?.id,
        name: `${variant?.metadata?.ordering_option_value} x ${variant?.metadata?.secondary_ordering_option_value} ${variant?.metadata?.secondary_ordering_option_label}`,
      }));
      return options;
    }
    return [];
  };
  const getProductsForSearchApi = useAxios({
    axiosParams: {
      url,
    },
    method: APIMethods.get,
  });
  const getProductsList = async (cancelToken?: CancelToken) => {
    dispatch(updateIsLoading(true));
    const response: any = await getProductsForSearchApi?.fetchData({
      axiosParams: {
        url,
        cancelToken,
      },
    });
    if (response && response?.status === "SUCCESS") {
      setProducts(response?.data?.products);
      setTotalProductsCount(response?.data?.count);
      dispatch(updateIsLoading(false));
    }
  };
  const getDefaultFiltersAPI = useAxios({
    axiosParams: {
      url: `${buyerEndpoints?.getDefaultFilters?.replace(
        ":customerBusinessId",
        activeOutlet?.id
      )}`,
    },
    method: APIMethods.get,
  });
  
  const isFilterApplied = (filterId:string,filterName:string):boolean => {
    if(filterName === "prices"){
      const [minPrice,maxPrice] = transformFilterValue(filterName)?.split('-');
      const minValue = params.get(minPrice) || '';
      const maxValue = params.get(maxPrice) || '';
      const [minValueFromAPI, maxValueFromAPI] = filterId?.split('-')
      if(minValue && maxValue && (Number(minValueFromAPI) >= Number(minValue) && Number(maxValueFromAPI) <= Number(maxValue)))
        return true;
    }
    else{
      const existingFilters = params.get(transformFilterValue(filterName))?.split(',')
      if(existingFilters && existingFilters?.length > 0 && existingFilters?.includes(filterId))
        return true;
    }
    return false;
  }
  const getDefaultFiltersList = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await getDefaultFiltersAPI?.fetchData();
    if (response && response?.status === "SUCCESS") {
      const data = response?.data?.[0];
      const filtersData: IFilterList[] = [];
      for (let filterName in data) {
        if (filterName !== "attributes") {
          const item: any = { name: filterName };
          item["options"] = data[filterName]?.values?.map((each: any) => ({
            id: each?.id,
            label: capitalizeIfAlphabetic(each?.name?.trim()),
            isChecked: isFilterApplied(each?.id,filterName),
          }));
          item["count"] = data[filterName]?.count;
          item["searchValue"] = "";
          filtersData?.push(item);
        }
        //  list of custom attributes
        else {
          if (data?.[filterName]?.count) {
            for (let customAttribute of data?.["attributes"]?.values) {
              if (customAttribute?.count) {
                const item: any = { name: customAttribute?.attribute_name };
                item["options"] = customAttribute?.values?.map((each: any) => ({
                  id: each?.id,
                  label: capitalizeIfAlphabetic(each?.name?.trim()),
                  isChecked: isFilterApplied(each?.id,customAttribute?.attribute_name),
                }));
                item["count"] = customAttribute?.count;
                item["searchValue"] = "";
                filtersData?.push(item);
              }
            }
          }
        }
      }
      setFilteredList(filtersData);
      dispatch(updateIsLoading(false));
    }
  };
  const getUpdatedUrl = (BaseUrl: string) => {
    const urlObject = new URL(BaseUrl);
    const searchParams = urlObject.searchParams;
    const latestParams:any = {}
    const  categoryName = params.getAll("categoryName").join(',');
    const  subCategoryName = params.getAll("subCategoryName").join(',');
    if(categoryName && categoryName?.length > 0){
      latestParams['categoryName'] = categoryName;
    }
    if(subCategoryName && subCategoryName?.length > 0){
      latestParams['subCategoryName'] = subCategoryName;
    }
    for (let key in queryParameters) {
      if (Array?.isArray(queryParameters[key])) {
        queryParameters[key]?.length > 0 &&
          searchParams.set(key, queryParameters[key]?.join(","));
      } else {
        typeof queryParameters[key] == "boolean" || queryParameters[key] == "0"
          ? searchParams.set(key, queryParameters[key])
          : queryParameters[key] && searchParams.set(key, queryParameters[key]);
      }
    }
    const finalParams = Object.fromEntries(searchParams);
    const keysToExclude = ['avaliabilty_status','limit','offset']
    for(let finalKey in finalParams){
      if(!keysToExclude?.includes(finalKey)){
        latestParams[finalKey] = finalParams[finalKey]
      }
    }
    setParams({...latestParams})
    const updatedUrl = decodeURIComponent(urlObject.toString()); //decode the URL
    return updatedUrl;
  };
  // useEffect(() => {
  //   // nav from view all page so category ids are available
  //   if (!navFromSearchResults) {
  //     if (categoryId && subCategoryId && subSubCategoryId) {
  //       setQueryParameters({
  //         ...queryParameters,
  //         category_ids: [categoryId],
  //         sub_category_ids: [subCategoryId],
  //         sub_sub_categories_ids: [subSubCategoryId],
  //       });
  //     }
  //   } else {
  //     const searchQuery = params.get('q');
  //     if (searchQuery && searchQuery?.length > 0) {
  //       setQueryParameters({
  //         ...queryParameters,
  //         q: searchQuery,
  //       });
  //     }
  //   }
  // }, [navFromSearchResults]);

  // whenever query parameters changed appending parameters to url
  useEffect(() => {
    if (activeOutlet?.id && customerId) {
      const filterBaseUrl = `${
        urlConstants.BASE_URL
      }${buyerEndpoints.getFilteredProducts
        ?.replace(":customerId", customerId)
        ?.replace(":customerBusinessId", activeOutlet?.id)}?offset=${
        (currentPage - 1) * itemsPerPage
      }&limit=${itemsPerPage}&avaliabilty_status=true`;
      if (!params.get('q')) {
        if (Object.keys(queryParameters)?.length > 0) {
          setUrl(getUpdatedUrl(filterBaseUrl));
        }
      } else {
        setUrl(getUpdatedUrl(filterBaseUrl));
      }
    }
  }, [queryParameters]);
  useEffect(() => {
    if (activeOutlet?.id) {
      getDefaultFiltersList();
    }
  }, [activeOutlet?.id]);
  //  whenever any filter is checked then query parameters change ,
  //  it leads to url change then api hit for new products
  useEffect(() => {
    if (url && activeOutlet?.id && customerId) {
      createCancelToken();
      getProductsList(cancelTokenRef.current.token);
    }
  }, [url]);
  useEffect(() => {
    const urlParams = Object.fromEntries(params);
    const initialParams:any = {}
    for(let key in urlParams){
      if(key === "categoryName" || key === "subCategoryName"){
        continue;
      }
      else{
        let value:any;
        if(key === 'min_price' || key === "max_price"){
          value = params.get(key);
        }
        else{
          value =  params.get(key)?.split(',');
        }
        initialParams[transformFilterValue(key)] = value
      }
    }
    setQueryParameters({...queryParameters,...initialParams});
    // if (params.get('q')) {
    //   setNavFromSearchResults(true);
    // }
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {params.get('q') && totalProductsCount ? (
          <div className={styles.searchText}>
            Showing {totalProductsCount} results of{" "}
            <span>{params.get('q')}</span>
          </div>
        ) : null}
        {!params.get('q') && categoryName && subCategoryName? <Breadcrumb items={breadcrumbItems} /> : null}

        {products?.length > 0 && (
          <div
            className={`${styles.sort}`}
            onClick={handleSort}
            ref={sortContainerRef}
            data-testid="invoice-sort-button"
          >
            <div className={styles.selectedSort}>
              <div className={styles.content}>
                <div className={styles.sortByText}>Sort by:</div>
                <div className={styles.selected}>
                  {sortOptions[prevSelected]?.label}
                </div>
              </div>
              <div className="icon">
                <img src={sortIcon} alt="sort"></img>
              </div>
            </div>
            {showSort && (
              <div className={styles.sortList} data-testid="invoice-sort">
                <div className={styles.sortBy}>
                  {constants.ordersListing.SORT_BY}
                </div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${styles.sortOption} ${
                      index === sortOptions.length - 1 && styles.lastOption
                    } ${option.id === prevSelected && styles.selectedOpt}`}
                    onClick={() => handleSortingOptions(option.id)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`${styles.contentWrapper}`}>
        <section className={styles.filters}>
          <Filters
            filtersList={filtersList}
            setFilteredList={setFilteredList}
            setQueryParameters={setQueryParameters}
            queryParameters={queryParameters}
            setCurrentPage={setCurrentPage}
          />
        </section>
        {products?.length > 0 ? (
          <section className={styles.productsContainer}>
            <div className={styles.products}>
              {products?.map((product) => (
                <ProductCardWithCart
                  key={product?.id}
                  id={product?.id}
                  created_at={product?.created_at}
                  supllierType={"online"}
                  productName={product?.title}
                  productImage={getProductImageUrl(product?.images)}
                  country={{
                    flag: product?.country_flag,
                    name: product?.country_display_name,
                  }}
                  liked={product?.is_favorite}
                  supplierName={product?.business_name}
                  outOfstockStatus={!product?.avaliabilty_status}
                  bundleOptions={transformVariantToBundleOptions(
                    product?.variants
                  )}
                  variants={product?.variants}
                  handleClick={() => {
                    navigate(
                      routeConfig?.buyerProductDetails?.replace(
                        ":productId",
                        product?.id
                      )
                    );
                  }}
                />
              ))}
            </div>
            <div className={styles.paginationContainer}>
              <PaginationComponent
                count={Math.ceil(totalProductsCount / itemsPerPage)}
                page={currentPage}
                handlePagination={handlePagination}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          </section>
        ) : (
          <div className={styles.emptyContainer}>
            <EmptyContainer
              imgUrl={telescope}
              title={"Oops!"}
              subText={"No products available"}
              customStyles={styles.customStyles}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProductsListAndFilters;
