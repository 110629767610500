import CustomAccordion from "../../../../../../common/components/accordion/Accordion";
import CheckboxButton from "../../../../../../common/components/checkbox/CheckboxButton";
import filterSearch from "../../../../../../assets/images/filterSearch.svg";
import styles from "./FiltersStyles.module.scss";
import { useState } from "react";
import FilterSearchUsingAlphabets from "../../../../../../common/components/filterSearchUsingAlphabets/FilterSearchUsingAlphabets";
import {
  IMarketplaceFiltersProps,
  MinMaxValues,
} from "../../../../../../models/IMarketplaceFiltersProps";
import { ClickAwayListener } from "@mui/material";
import { useAxios } from "../../../../../../services/useAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  APIMethods,
  buyerEndpoints,
  urlConstants,
} from "../../../../../../common/constants/urlConstants";
import { updateIsLoading } from "../../../../../../reducerSlices/loaderSlice";
import { capitalizeIfAlphabetic, findMinAndMaxValues, sortFilters, transformFilterValue } from "../../../../../../utils/filters";

const Filters = ({
  filtersList,
  setFilteredList,
  setQueryParameters,
  queryParameters,
  setCurrentPage,
}: IMarketplaceFiltersProps) => {
  const dispatch = useDispatch();
  const { activeOutlet } = useSelector((state: any) => state);
  const [prices, setPrices] = useState<any[]>([]);
  const [showMoreFilterValues, setShowMoreFilterValues] = useState<any>({});
  const filteredArrayBasedonSearch = (
    searchValue: string,
    arr: any[]
  ): any[] => {
    if (searchValue === "") return arr;
    const filterOptions = arr?.filter((each: any) =>
      each?.label?.includes(searchValue)
    );
    return filterOptions;
  };





  const getDefaultFiltersAPI = useAxios({
    axiosParams: {
      url: `${buyerEndpoints?.getDefaultFilters?.replace(
        ":customerBusinessId",
        activeOutlet?.id
      )}`,
    },
    method: APIMethods.get,
  });

  const handleShowMore = async (filterName: string, open?: boolean) => {
    const latestFilters = filtersList?.map((each_filter: any) =>
      each_filter?.name === filterName
        ? { ...each_filter, showMore: open }
        : { ...each_filter, showMore: false }
    );
    if (open) {
      console.log("ebebbeb");
      const baseUrl = `${
        urlConstants.BASE_URL
      }${buyerEndpoints?.getDefaultFilters?.replace(
        ":customerBusinessId",
        activeOutlet?.id
      )}`;
      const urlObject = new URL(baseUrl);
      const searchParams = urlObject.searchParams;
      searchParams.set(`${filterName}_limit`, "false");
      const updatedUrl = decodeURIComponent(urlObject.toString()); //decode the URL
      dispatch(updateIsLoading(true));
      const response: any = await getDefaultFiltersAPI?.fetchData({
        axiosParams: {
          url: updatedUrl,
        },
        method: APIMethods.get,
      });
      if (response && response?.status === "SUCCESS") {
        const data = response?.data?.[0];
        for (let filterFromApi in data) {
          if (filterFromApi !== "attributes") {
            if(filterFromApi === filterName){
              const item: any = { name: filterName };
              item["totalOptions"] = data[filterName]?.values?.map(
                (each: any, index: number) => {
                  let currentCheckedStatus = false;
                  for(let existFilter of filtersList){
                    if(existFilter?.name === filterName && existFilter?.options?.length){
                      for(let option of existFilter?.options){
                        if(option?.id === each?.id){
                          currentCheckedStatus = option?.isChecked;
                        }
                      }
                    }
                  }
                  return {
                    id: index + 1,
                    supplierId: each?.id,
                    supplierName: capitalizeIfAlphabetic(each?.name?.trim()),
                    quantity: 0,
                    isChecked: currentCheckedStatus,
                  };
                }
              );
              setShowMoreFilterValues({ ...item });
              break;
            }
          }
          //  list of custom attributes
          else {
            console.log("else",data?.[filterFromApi]?.count);
            if (data?.[filterFromApi]?.count) {
              for (let customAttribute of data?.[filterFromApi]?.values) {
                if (customAttribute?.count && customAttribute?.attribute_name === filterName) {
                  const item: any = { name: customAttribute?.attribute_name };
                  item["totalOptions"] = customAttribute?.values?.map(
                    (each: any, index: number) => {
                      let currentCheckedStatus = false;
                      for(let existFilter of filtersList){
                        if(existFilter?.name === filterName && existFilter?.options?.length){
                          for(let option of existFilter?.options){
                            if(option?.id === each?.id){
                              currentCheckedStatus = option?.isChecked;
                            }
                          }
                        }
                      }
                      return {
                        id: index + 1,
                        supplierId: each?.id,
                        supplierName: capitalizeIfAlphabetic(each?.name?.trim()),
                        quantity: 0,
                        isChecked: currentCheckedStatus,
                      };
                    }
                  );
                  setShowMoreFilterValues({ ...item });
                  break;
                }
              }
            }
          }
        }
      }
      dispatch(updateIsLoading(false));
    } else {
      setShowMoreFilterValues({});
    }
    setFilteredList([...latestFilters]);
  };

  const handleIsChecked = (
    item: any,
    filterItems: any[],
    filterName: string
  ) => {
    setCurrentPage(1);
    const latestOptions = filterItems?.map((each_item: any) => {
      if (each_item?.id === item?.id)
        return { ...item, isChecked: !item?.isChecked };
      return each_item;
    });
    const latestFilters = filtersList?.map((each_filter: any) => {
      if (each_filter?.name === filterName)
        return { ...each_filter, options: latestOptions };
      return each_filter;
    });
    const queryValue = queryParameters[transformFilterValue(filterName)];
    if (queryValue && Array?.isArray(queryValue)) {
      let uniqueIds: any[] = [];
      if (queryValue?.includes(item?.id)) {
        uniqueIds = queryValue?.filter((id: string) => id != item?.id);
      } else {
        uniqueIds = [...queryValue, item?.id];
      }
      queryParameters[transformFilterValue(filterName)] = [...uniqueIds];
      setQueryParameters({ ...queryParameters });
    } else {
      if (filterName === "prices") {
        if (prices?.includes(item?.id)) {
          const latestPrices = prices?.filter((id: string) => id != item?.id);
          if (latestPrices?.length > 0) {
            const result: MinMaxValues = findMinAndMaxValues(latestPrices);
            setQueryParameters({
              ...queryParameters,
              min_price: result?.minLeft,
              max_price: result?.maxRight,
            });
            setPrices([...latestPrices]);
          } else {
            setQueryParameters({
              ...queryParameters,
              min_price: "",
              max_price: "",
            });
            setPrices([]);
          }
        } else {
          const latestPrices = [...prices, item?.id];
          const result: MinMaxValues = findMinAndMaxValues(latestPrices);
          setQueryParameters({
            ...queryParameters,
            min_price: result?.minLeft,
            max_price: result?.maxRight,
          });
          setPrices([...latestPrices]);
        }
      } else {
        setQueryParameters({
          ...queryParameters,
          [transformFilterValue(filterName)]: [item?.id],
        });
      }
    }
    setFilteredList(latestFilters);
  };
  const getChildren = (
    filterName: string,
    searchValue: string,
    filterItems: any[],
    remainingCount: number,
    showMore: boolean,
    prices: any[],
    setPrices: any
  ) => (
    <div className={styles.body}>
      <div className={styles.filterSearch}>
        <div className={styles.icon}>
          <img src={filterSearch} alt="search" />
        </div>
        <input
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => {
            const latestFilters = filtersList?.map((each_filter: any) =>
              each_filter?.name === filterName
                ? { ...each_filter, searchValue: e?.target?.value }
                : { ...each_filter }
            );
            setFilteredList([...latestFilters]);
          }}
        />
      </div>
      <div className={styles.filterOptions}>
        {filteredArrayBasedonSearch(searchValue, filterItems)?.map(
          (item: any) => (
            <div className={styles.filterItem}>
              <CheckboxButton
                checked={item?.isChecked}
                handleChange={() => {
                  handleIsChecked(item, filterItems, filterName);
                }}
              />
              <span className={styles.filterLabel}>{`${item?.label}`}</span>
            </div>
          )
        )}
      </div>
      {remainingCount > 0 && !showMore && (
        <div
          className={styles.remainingCount}
          onClick={() => handleShowMore(filterName, true)}
        >
          <p className={styles.count}>{`+${remainingCount} more`}</p>
          <div className={styles.line}></div>
        </div>
      )}
      {showMore && showMoreFilterValues?.totalOptions?.length > 0 && (
        <ClickAwayListener
          onClickAway={() => handleShowMore(filterName, false)}
        >
          <div className={styles.totalOptions}>
            <FilterSearchUsingAlphabets
              handleClose={() => handleShowMore(filterName, false)}
              suppliersArray={sortFilters(showMoreFilterValues?.totalOptions)}
              filterName={showMoreFilterValues?.name}
              limitedOptions={filtersList}
              setLimitedOptions={setFilteredList}
              setShowMoreFilterValues={setShowMoreFilterValues}
              setCurrentPage={setCurrentPage}
              setQueryParameters={setQueryParameters}
              queryParameters={queryParameters}
              prices={prices}
              setPrices={setPrices}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );

  console.log("ffsgsfgiii", queryParameters);
  // temporarly hide filters for region (backend is not ready)
  return filtersList?.length > 0 ? (
    <div className={styles.filterTypes}>
      {filtersList?.map(
        (filterSection: any, index: number) =>
          filterSection?.options?.length > 0 &&
          filterSection?.name != "region" && (
            <CustomAccordion
              key={index?.toString()}
              title={filterSection?.name}
              children={getChildren(
                filterSection?.name,
                filterSection?.searchValue,
                filterSection?.options,
                filterSection?.count - filterSection?.options?.length,
                filterSection?.showMore,
                prices,
                setPrices
              )}
              isFilters={true}
            />
          )
      )}
    </div>
  ) : (
    <></>
  );
};

export default Filters;
